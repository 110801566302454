import React, {useEffect, useMemo, useState} from "react";
import BitSet from "bitset";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import IndicatorCreate from "./IndicatorCreate";
import {getDimensionLabel, getDimensionValueLabel, MARGINAL_DIMENSION_KEY} from "../../../utils/dataset";
import {getDimensionValueFromIdx} from "../../table/multi-viewer-tmp/utils";
import {getStrFromIndex} from "./utils";

const mapStateToProps = ({config, app, node}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  defaultLanguage: app.language,
  languages: app.languages,
  nodeId: node.nodeId,
  nodeCode: node.code
});

const IndicatorCreateJsonStatWrapper = ({
  indicatorsBaseUrl,
  defaultLanguage,
  languages,
  nodeId,
  nodeCode,

  isOpen,
  onClose,
  datasetId,
  dataset,
  territoryDim,
  timeDim,
  criteria,
  tableLayout,
  indicators,
  indicatorPreview,
  additionalDatasets,

  onPreviewFetch,
  onPreviewReset,
  onPublish
}) => {
  const {t} = useTranslation();

  const [worker] = useState(() => new Worker("./workers/getMVTableSupportStructuresWorker.js"));
  const [tableSupportStructures, setTableSupportStructures] = useState(null);
  const [combinations, setCombinations] = useState(null);
  const [variables, setVariables] = useState(null);
  const [variablesDataset, setVariablesDataset] = useState(null);

  const years = useMemo(() => {
    return dataset?.dimension?.[timeDim]?.category?.index || [];
  }, [dataset, timeDim]);

  useEffect(() => {
    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, [worker]);

  useEffect(() => {
    if (dataset && tableLayout) {
      worker.onmessage = event => {
        setTableSupportStructures({
          ...event.data,
          valorizedCols: new BitSet(event.data.valorizedColsArr)
        });
      };
      worker.onerror = () => {
        setTableSupportStructures(null);
      };
      worker.postMessage({
        jsonStat: dataset,
        layout: tableLayout,
        isPreview: false,
        removeEmptyLines: true
      });
    } else {
      setTableSupportStructures(null);
      setCombinations(null);
      setVariables(null);
      setVariablesDataset(null);
    }
  }, [worker, dataset, tableLayout]);

  useEffect(() => {
    if (dataset && tableSupportStructures) {
      const {dimValuesMap, dimSpanMap, valorizedCols} = tableSupportStructures;

      const combinations = [];
      const variables = {};
      const variableDatasetMap = {};

      const variableDatasetList = [];
      let c = 0;
      for (let b of valorizedCols) {
        const idx = c;
        if (b) {
          let isIndicator = false;
          let combination = {};
          let variableDatasetId = `${nodeCode},${datasetId}`;
          tableLayout.cols.forEach(dim => {
            const dimValue = getDimensionValueFromIdx(dim, idx, dimValuesMap, dimSpanMap);
            if (dim !== MARGINAL_DIMENSION_KEY) {
              combination[dim] = dimValue;
            } else if ((dataset.extension.marginalvalues[dimValue].label || "").length === 0) {
              combination = {
                ...combination,
                ...dataset.extension.marginalvalues[dimValue].dimensionvalues
              };
              variableDatasetId = dataset.extension.marginalvalues[dimValue].datasetid;
            } else {
              isIndicator = true;
            }
          });
          if (!isIndicator) {
            combinations.push(combination);
            variableDatasetList.push(variableDatasetId);
          }
        }
        c++;
      }

      const letters = [];
      combinations.forEach((combination, idx) => {
        const noTimeCombinationStr = Object.keys(combination)
          .filter(dim => dim !== timeDim)
          .map(dim => combination[dim])
          .join(",");
        if (!letters.includes(noTimeCombinationStr)) {
          letters.push(noTimeCombinationStr);
        }
        const variable = combination[timeDim] + getStrFromIndex(letters.indexOf(noTimeCombinationStr));
        variables[variable] = combination;
        variableDatasetMap[variable] = variableDatasetList[idx];
      });

      setCombinations(combinations);
      setVariables(variables);
      setVariablesDataset(variableDatasetMap);
    }
  }, [tableSupportStructures, datasetId, nodeCode, dataset, tableLayout, timeDim]);

  return (
    dataset && (
      <IndicatorCreate
        indicatorsBaseUrl={indicatorsBaseUrl}
        defaultLanguage={defaultLanguage}
        languages={languages}
        nodeId={nodeId}
        nodeCode={nodeCode}
        isOpen={isOpen}
        onClose={onClose}
        datasetId={datasetId}
        territoryDim={territoryDim}
        timeDim={timeDim}
        criteria={criteria}
        indicators={indicators}
        indicatorPreview={indicatorPreview}
        additionalDatasets={additionalDatasets}
        years={years}
        combinations={combinations}
        variables={variables}
        variablesDataset={variablesDataset}
        getDimLabel={(datasetId, dim) => getDimensionLabel(dataset, datasetId, dim)}
        getDimValueLabel={(datasetId, dimId, valueId) => getDimensionValueLabel(dataset, datasetId, dimId, valueId, t)}
        onPreviewFetch={onPreviewFetch}
        onPreviewReset={onPreviewReset}
        onPublish={onPublish}
      />
    )
  );
};

export default compose(connect(mapStateToProps))(IndicatorCreateJsonStatWrapper);
