import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import ExportButton from "./ExportButton";
import {
  getFormattedDimensionLabel,
  getFormattedDimensionValueLabel,
  getMarginalDimensions,
  MARGINAL_DIMENSION_KEY
} from "../../utils/dataset";

const mapStateToProps = state => ({
  exportConfig: state.hub.hub.exportConfig,
  exportSettings: state.appConfig.exportSettings,
  modulesConfig: state.app.modulesConfig
});

const ExportButtonJsonStatWrapper = ({
  exportConfig,
  exportSettings,
  modulesConfig,
  formats,
  jsonStat,
  viewerIdx,
  showAsBottomNavigation,
  icon,
  isTableVisible,
  isMapVisible,
  isChartVisible,
  tableLayout,
  mapId,
  mapContainerId,
  mapLayout,
  mapSettings,
  chartId,
  chartContainerId,
  chartLayout,
  chartSettings,
  labelFormat,
  datasetTitle,
  shapefilesUrl,
  role,
  buttonStyle,
  className,
  disabled,
  getDimensionsCombinationCount,
  getIsDatasetExceedingLimit,
  submitDownload,
  submitMetadataDownload,
  localizedTimePeriodFormatMapExternal,
  ...props
}) => {
  const getLabels = (layout, filter, filterValue, dimIdx, t) => {
    let dimLabel;
    let valueLabel;
    let isLast;
    let hasIndicatorNote = false;

    if (filter !== MARGINAL_DIMENSION_KEY) {
      dimLabel = getFormattedDimensionLabel(jsonStat, null, filter, labelFormat, t);
      valueLabel = getFormattedDimensionValueLabel(
        jsonStat,
        null,
        filter,
        filterValue,
        labelFormat,
        t,
        localizedTimePeriodFormatMapExternal
      );
      isLast = dimIdx === layout.filters.length - 1;
    } else {
      const marginal = jsonStat.extension.marginalvalues[filterValue];
      if (marginal.label) {
        hasIndicatorNote = true;
        dimLabel = getFormattedDimensionLabel(jsonStat, null, filter, labelFormat, t);
        valueLabel = marginal.label + "*";
        isLast = dimIdx === layout.filters.length - 1;
      } else {
        getMarginalDimensions(jsonStat, marginal.datasetid).forEach((dim, marginalDimIdx, arr) => {
          dimLabel = getFormattedDimensionLabel(jsonStat, marginal.datasetid, dim, labelFormat, t);
          valueLabel = getFormattedDimensionValueLabel(
            jsonStat,
            marginal.datasetid,
            dim,
            marginal.dimensionvalues[dim],
            labelFormat,
            t,
            localizedTimePeriodFormatMapExternal
          );
          isLast = dimIdx === layout.filters.length - 1 && marginalDimIdx === arr.length - 1;
        });
      }
    }

    return {dimLabel, valueLabel, isLast, hasIndicatorNote};
  };

  return (
    jsonStat && (
      <ExportButton
        {...props}
        exportConfig={exportConfig}
        exportSettings={exportSettings}
        modulesConfig={modulesConfig}
        showAsBottomNavigation={showAsBottomNavigation}
        icon={icon}
        formats={formats}
        viewerIdx={viewerIdx}
        isTableVisible={isTableVisible}
        isMapVisible={isMapVisible}
        isChartVisible={isChartVisible}
        tableLayout={tableLayout}
        mapId={mapId}
        mapContainerId={mapContainerId}
        mapLayout={mapLayout}
        mapSettings={mapSettings}
        chartId={chartId}
        chartContainerId={chartContainerId}
        chartLayout={chartLayout}
        chartSettings={chartSettings}
        labelFormat={labelFormat}
        datasetTitle={datasetTitle}
        getDimensionsCombinationCount={getDimensionsCombinationCount}
        getIsDatasetExceedingLimit={getIsDatasetExceedingLimit}
        submitDownload={submitDownload}
        shapefilesUrl={shapefilesUrl}
        role={role}
        buttonStyle={buttonStyle}
        submitMetadataDownload={submitMetadataDownload}
        className={className}
        disabled={disabled}
        getDimensionSize={dimensionId => jsonStat.size[jsonStat.id.indexOf(dimensionId)]}
        getLabels={getLabels}
      />
    )
  );
};

export default compose(connect(mapStateToProps))(ExportButtonJsonStatWrapper);
