import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import moment from "moment";
import {useSelector} from "react-redux";
import Call from "../../hocs/call";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import CustomEmpty from "../custom-empty";
import {isChartLayoutCartesianByType} from "../data-viewer/constant";
import {useItemContainerFilters} from "../item-containers/ItemContainerFilters";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../temporal-dim-order-selector/constants";
import {useViewDataset} from "./useViewDataset";
import ViewItemBody from "./ViewItemBody";
import ViewItemHeading from "./ViewItemHeading";
import {modulesConfigSelector} from "../../state/app/appSelectors";
import useLanguages from "../../state/hooks/useLanguages";
import {hubNodesSelector} from "../../state/hub/hubSelectors";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_PERIODS,
  getCriteriaArrayFromObject,
  getCriteriaObjectFromArray,
  getFilteredCodelistsListFromCriteria,
  getFilteredCriteriaFromLayout
} from "../../utils/criteria";
import {DASHBOARD_ELEM_ENABLE_FILTERS_KEY} from "../../utils/dashboards";
import {
  generateLocalizedTimePeriodFormatMapFromExtras,
  getDimensionsInfo,
  getSVPFilteredChartLayout,
  getSVPFilteredMapLayout,
  getSVPFilteredTableLayout,
  getUpdatedLayout
} from "../../utils/dataset";
import {FREQ_ANNUAL, getMinAndMax, getTimeValuesFromRange, SUPPORTED_FREQ_VALUES} from "../../utils/timePeriodAndFreq";
import {getMappedTreeDeptFirst, getNodes, getTreeFromArray} from "../../utils/tree";
import {getChartSettingsFromViewTemplateLayouts, getMapSettingsFromViewTemplateLayouts} from "../../utils/viewTemplate";
import {getNodeExtras, handleStyle} from "./utils";

declare global {
  interface Window {
    LMap: any;
  }
}

interface ViewItemProps {
  itemContainerElem: ItemViewTemplateDto;
  viewIdx: string;
  minItemContainerWidth: number;
  itemContainer: ItemContainerDto;
  mapId: string;
  chartId: string;
}

const OptimizedViewItem = ({
  itemContainerElem,
  viewIdx,
  itemContainer,
  minItemContainerWidth,
  mapId,
  chartId
}: ViewItemProps) => {
  const {t} = useLanguages();
  const chartRef = useRef();

  const nodes = useSelector(hubNodesSelector);
  const [nodeExtras, setNodeExtras] = useState(null);
  const [localizedTimePeriodFormatMap, setLocalizedTimePeriodFormatMap] = useState({});
  const modulesConfig = useSelector(modulesConfigSelector);

  const pageFilterValue = useItemContainerFilters();

  const filterDim = useMemo(() => itemContainerElem?.filterDimension, [itemContainerElem?.filterDimension]);

  const [view, setView] = useState<ViewTemplateDto & {initialLayout?: any; initialCriteria?: any}>(null);
  const [layoutObj, setLayoutObj] = useState(null);
  const [layout, setLayout] = useState(null);
  const [criteria, setCriteria] = useState(null);

  const {call, request, loading, data, error} = useViewDataset(
    itemContainerElem,
    itemContainer.type,
    itemContainer.identifier,
    modulesConfig.configs
  );

  useEffect(() => {
    let view: ViewTemplateDto & {initialLayout?: any; initialCriteria?: any} = _.cloneDeep(
      itemContainerElem.viewTemplate
    );

    const viewCriteria = getCriteriaObjectFromArray(view.criteria);
    const parsedViewLayouts = JSON.parse(view.layouts);

    const dimensionValues = (view.allPartialOptimizedInfo?.dimensionValues || []).filter(
      (dim: any) => (dim?.values ?? []).length > 0 || (dim?.hierarchies ?? []).length > 0
    );

    let codelistLists: {[key: string]: string[]} = {};
    const codelistTrees: {[key: string]: any[]} = {};
    const codelistMaps: {[key: string]: {[key: string]: string}} = {};

    dimensionValues.forEach((dimension: any) => {
      const values: any[] = [];
      const ids: string[] = [];
      const map: {[key: string]: string} = {};

      const dimensionHasHcl = (dimension.hierarchies || []).length > 0 && (dimension.hierarchyId ?? "").length > 0;
      if (dimensionHasHcl) {
        const hierarchyId = dimension.hierarchyId;
        const hierarchyValues = dimension.hierarchiesValues[hierarchyId];
        const nodes = getNodes(hierarchyValues, "hierarchyCodes", () => true);
        nodes.forEach(node => {
          map[node.id] = node.name;
          if (node.isSelectable) {
            ids.push(node.id);
          }
        });
        codelistTrees[dimension.id] = getMappedTreeDeptFirst(hierarchyValues, "hierarchyCodes", node => ({
          ...node,
          hierarchyCodes: undefined,
          children: node.hierarchyCodes,
          label: `[${node.id}] ${node.name}`
        }));
      } else {
        (dimension.values || []).forEach((code: any) => {
          values.push({
            ...code,
            label: `[${code.id}] ${code.name}`
          });
          map[code.id] = code.name;
          if (code.isSelectable) {
            ids.push(code.id);
          }
        });
        codelistTrees[dimension.id] = getTreeFromArray(values, "parentId", "children");
      }

      codelistLists[dimension.id] = ids;
      codelistMaps[dimension.id] = map;
    });

    const dimensionsIds = dimensionValues.map((dim: any) => dim.id);

    const freqDim =
      view.allPartialOptimizedInfo?.freqDimension && dimensionsIds.includes(view.allPartialOptimizedInfo.freqDimension)
        ? view.allPartialOptimizedInfo.freqDimension
        : null;
    const timeDim =
      view.allPartialOptimizedInfo?.timeDimension && dimensionsIds.includes(view.allPartialOptimizedInfo.timeDimension)
        ? view.allPartialOptimizedInfo.timeDimension
        : null;
    const territoryDim =
      view.allPartialOptimizedInfo?.territorialDimension &&
      dimensionsIds.includes(view.allPartialOptimizedInfo.territorialDimension)
        ? view.allPartialOptimizedInfo.territorialDimension
        : null;

    const hasLastNPeriods = timeDim ? viewCriteria[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS : false;

    if (timeDim) {
      const timeCodelist = dimensionValues.find(({id}: any) => id === timeDim);
      const freqs = freqDim && codelistLists[freqDim] ? codelistLists[freqDim] : [FREQ_ANNUAL];
      const freq = SUPPORTED_FREQ_VALUES.find(freq => freqs.includes(freq)) || FREQ_ANNUAL;
      const viewNode = (nodes ?? []).find(({nodeId}) => nodeId === itemContainerElem.nodeId);
      const {min, max} = getMinAndMax(timeCodelist, freq, viewNode);

      codelistTrees[timeDim] = [];
      codelistLists[timeDim] = getTimeValuesFromRange(freqs, moment(min), moment(max));
      codelistMaps[timeDim] = {};
    }

    codelistLists = getFilteredCodelistsListFromCriteria(codelistLists, viewCriteria, timeDim, freqDim);

    let layout = null;
    if (view.defaultView === "table") {
      layout = getSVPFilteredTableLayout(
        parsedViewLayouts.tableLayout,
        codelistLists,
        timeDim,
        freqDim,
        territoryDim,
        hasLastNPeriods
      );
    } else if (view.defaultView === "map") {
      layout = getSVPFilteredMapLayout(
        parsedViewLayouts.mapLayout,
        codelistLists,
        timeDim,
        freqDim,
        territoryDim,
        hasLastNPeriods
      );
    } else {
      if (isChartLayoutCartesianByType(view.defaultView)) {
        if (parsedViewLayouts.chartLayoutCartesian) {
          layout = getSVPFilteredChartLayout(
            parsedViewLayouts.chartLayoutCartesian,
            codelistLists,
            timeDim,
            freqDim,
            territoryDim,
            hasLastNPeriods
          );
        } else {
          layout = getSVPFilteredChartLayout(
            parsedViewLayouts.chartLayout,
            codelistLists,
            timeDim,
            freqDim,
            territoryDim,
            hasLastNPeriods
          );
        }
      } else {
        if (parsedViewLayouts.chartLayoutRadial) {
          layout = getSVPFilteredChartLayout(
            parsedViewLayouts.chartLayoutRadial,
            codelistLists,
            timeDim,
            freqDim,
            territoryDim,
            hasLastNPeriods
          );
        } else {
          layout = getSVPFilteredChartLayout(
            parsedViewLayouts.chartLayout,
            codelistLists,
            timeDim,
            freqDim,
            territoryDim,
            hasLastNPeriods
          );
        }
      }
    }

    if (hasLastNPeriods) {
      const timeDimValues = codelistLists[timeDim];
      const lastTimeDimValue = timeDimValues[timeDimValues.length - 1];

      if (layout && (layout?.filters || []).includes(timeDim)) {
        layout.filtersValue[timeDim] = lastTimeDimValue;
      } else if (layout && (layout.primaryDim || []).includes(timeDim)) {
        layout.primaryDimValues = timeDimValues;
      } else if (layout && (layout.secondaryDim || []).includes(timeDim)) {
        layout.secondaryDimValues = timeDimValues;
      }
    }

    const filteredCriteria = getFilteredCriteriaFromLayout(viewCriteria, dimensionValues, layout, timeDim);

    view = {
      ...view,
      initialLayout: layout,
      initialCriteria: filteredCriteria,
      allPartialOptimizedInfo: null,
      isOptimized: true,
      dimensionValues: dimensionValues,
      dimensionsInfo: getDimensionsInfo(dimensionValues, codelistLists),
      freqDim: freqDim,
      timeDim: timeDim,
      hasLastNPeriods: hasLastNPeriods,
      territoryDim: territoryDim,
      codelistTrees: codelistTrees,
      codelistLists: codelistLists,
      codelistMaps: codelistMaps
    };

    const layoutObj = {
      layout: layout,
      labelFormat: parsedViewLayouts.labelFormat,
      temporalDimOrder: parsedViewLayouts.temporalDimOrder,
      showTrend: parsedViewLayouts.showTrend,
      showCyclical: parsedViewLayouts.showCyclical,
      tableEmptyChar: parsedViewLayouts.tableEmptyChar,
      chartSettings: getChartSettingsFromViewTemplateLayouts(parsedViewLayouts),
      mapSettings: getMapSettingsFromViewTemplateLayouts(parsedViewLayouts),
      detailLevel:
        parsedViewLayouts.detailLevel !== null && parsedViewLayouts.detailLevel !== undefined
          ? parsedViewLayouts.detailLevel
          : parsedViewLayouts.mapDetailLevel !== null && parsedViewLayouts.mapDetailLevel !== undefined
            ? parsedViewLayouts.mapDetailLevel
            : null
    };

    const nodeExtras = getNodeExtras(nodes, view);
    setNodeExtras(nodeExtras);

    const localizedTimePeriodFormatMap = generateLocalizedTimePeriodFormatMapFromExtras(nodeExtras);
    setLocalizedTimePeriodFormatMap(localizedTimePeriodFormatMap);

    setView(view);
    setLayoutObj(layoutObj);
    setLayout(layout);
    setCriteria(filteredCriteria);
  }, [itemContainerElem, nodes]);

  useEffect(() => {
    if (view && filterDim) {
      setLayout(prevLayout => {
        let newLayout = _.cloneDeep(prevLayout);

        if (pageFilterValue) {
          if ((newLayout.filters || []).includes(filterDim)) {
            newLayout.filtersValue[filterDim] = pageFilterValue;
          } else if ((newLayout.primaryDim || []).includes(filterDim)) {
            newLayout.primaryDimValues = [pageFilterValue];
          } else if ((newLayout.secondaryDim || []).includes(filterDim)) {
            newLayout.secondaryDimValues = [pageFilterValue];
          }
        } else if (!itemContainerElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY]) {
          newLayout = view.initialLayout;
        } else {
          if (view.defaultView === "table") {
            newLayout = getSVPFilteredTableLayout(
              prevLayout,
              view.codelistLists,
              view.timeDim,
              view.freqDim,
              view.territoryDim,
              view.hasLastNPeriods
            );
          } else if (view.defaultView === "map") {
            newLayout = getSVPFilteredMapLayout(
              prevLayout,
              view.codelistLists,
              view.timeDim,
              view.freqDim,
              view.territoryDim,
              view.hasLastNPeriods
            );
          } else {
            newLayout = getSVPFilteredChartLayout(
              prevLayout,
              view.codelistLists,
              view.timeDim,
              view.freqDim,
              view.territoryDim,
              view.hasLastNPeriods
            );
          }
        }

        setCriteria(prevCriteria => {
          const newCriteria = getFilteredCriteriaFromLayout(
            prevCriteria,
            view.dimensionValues,
            newLayout,
            view.timeDim
          );

          if (pageFilterValue) {
            newCriteria[filterDim] = {
              ...newCriteria[filterDim],
              id: filterDim,
              type: CRITERIA_FILTER_TYPE_CODES,
              filterValues: [pageFilterValue]
            };
          }

          return newCriteria;
        });

        return newLayout;
      });
    }
  }, [itemContainerElem, view, filterDim, pageFilterValue]);

  useEffect(() => {
    handleStyle(itemContainer.id, viewIdx, itemContainerElem, minItemContainerWidth);
  }, [itemContainer.id, viewIdx, itemContainerElem, minItemContainerWidth, data]);

  try {
    const invertedDims =
      layoutObj?.temporalDimOrder &&
      layoutObj.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC &&
      layout?.filters &&
      !layout.filters.includes(view.timeDim)
        ? [view.timeDim]
        : null;

    return (
      <Call
        cb={criteria => {
          if (!filterDim || !pageFilterValue || criteria?.[filterDim]?.filterValues?.[0] === pageFilterValue) {
            call({
              ...request,
              data: getCriteriaArrayFromObject(criteria),
              clearCache: true
            });
          }
        }}
        cbParam={criteria}
        disabled={criteria === null}
      >
        {loading ? (
          <CustomEmpty text={t("components.itemContainer.fetching") + "..."} image={<CircularProgress />} />
        ) : error ? (
          <CustomEmpty text={t("components.itemContainer.fetchingDatasetError")} />
        ) : !data ? (
          <CustomEmpty text={""} />
        ) : (data?.id || []).length === 0 ? (
          <CustomEmpty text={t("components.itemContainer.emptyView")} />
        ) : layoutObj ? (
          <Fragment>
            <ViewItemHeading
              isOptimized={true}
              itemContainer={itemContainer}
              item={itemContainerElem}
              viewIdx={viewIdx}
              view={view}
              defaultTitle={data.label}
              jsonStat={data}
              criteria={criteria}
              layout={layout}
              layoutObj={layoutObj}
              mapId={mapId}
              chartId={chartId}
              chartRef={chartRef}
              filterDim={filterDim}
              pageFilterValue={pageFilterValue}
              onFilterSet={({dimension, value}) => {
                const newLayout = getUpdatedLayout(dimension, value, layout);
                setLayout(newLayout);
                setCriteria(getFilteredCriteriaFromLayout(criteria, view.dimensionValues, newLayout, view.timeDim));
              }}
              downloadFormats={nodeExtras?.DownloadFormats || []}
              localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMap}
            />
            <ViewItemBody
              itemContainer={itemContainer}
              item={itemContainerElem}
              jsonStat={data}
              layout={layout}
              hierarchyOnlyAttributes={nodeExtras?.HierarchyOnlyAttributes || []}
              hideHierarchyOnlyRows={nodeExtras?.HideHierarchyOnlyRows || false}
              invertedDims={invertedDims}
              layoutObj={layoutObj}
              viewIdx={viewIdx}
              minItemContainerWidth={minItemContainerWidth}
              mapId={mapId}
              chartId={chartId}
              chartRef={chartRef}
              localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMap}
            />
          </Fragment>
        ) : (
          <span />
        )}
      </Call>
    );
  } catch (e) {
    return <CustomEmpty text={t("components.itemContainer.genericError")} />;
  }
};

export default OptimizedViewItem;
