import React from "react";
import {Box, useTheme} from "@mui/material";
import {Link} from "react-router-dom";

const linkStyle = {
  cursor: "default",
  borderRadius: "4px",
  fontSize: "16px",
  height: "100%",
  width: "100%",
  "& > a": {
    outlineOffset: "-1px"
  }
};

const linkStyleEnabled = {
  cursor: "pointer",
  "& > a": {
    color: theme => theme.palette.text.primary,
    textDecoration: "none"
  },
  "&:hover": {
    background: "#f5f5f5"
  },
  "&:hover > a": {
    textDecoration: "underline"
  }
};

const linkStyleDisabled = {
  color: "rgba(0, 0, 0, 0.42)"
};

const CustomLink = ({
  to = null,
  text = null,
  textStyle = {},
  subText = null,
  subTextStyle = {},
  icon = null,
  image = null,
  imageStyle = {},
  onClick = undefined,
  disabled = false
}) => {
  const theme = useTheme();

  const linkContent = (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
      className={"custom-link__content"}
    >
      {image && (
        <Box
          sx={{
            "& > img": {
              height: "100%",
              width: "100%",
              objectFit: "cover"
            }
          }}
          style={imageStyle}
          className={"custom-link__content__image"}
        >
          <img src={image} alt="" />
        </Box>
      )}
      <Box
        sx={{
          height: "100%",
          width: "100%",
          padding: "8px",
          display: "flex",
          alignItems: "center"
        }}
        style={textStyle}
        className={"custom-link__content__icon-and-text"}
      >
        {icon && (
          <Box
            sx={{
              marginRight: "8px",
              display: "flex",
              color: theme => theme.palette.text.primary
            }}
            className={"custom-link__content__icon-and-text__icon"}
          >
            {icon}
          </Box>
        )}
        {text && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              color: theme => theme.palette.text.primary
            }}
            className={"custom-link__content__icon-and-text__text"}
          >
            {text}
          </Box>
        )}
      </Box>
      <Box style={subTextStyle} className={"custom-link__content__sub-text"}>
        {subText}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={[linkStyle, disabled ? linkStyleDisabled : linkStyleEnabled]}
      className={"custom-link " + (disabled ? "custom-link--disabled" : "custom-link--enabled")}
    >
      {disabled ? (
        linkContent
      ) : to ? (
        <Link to={to} onClick={onClick}>
          {linkContent}
        </Link>
      ) : (
        <Box
          component="button"
          sx={{
            color: theme.palette.text.primary,
            backgroundColor: "transparent",
            font: "inherit",
            padding: "4px 8px",
            border: "none",
            cursor: "pointer",
            letterSpacing: "inherit",
            "&:hover": {
              textDecoration: "underline"
            }
          }}
          onClick={onClick}
        >
          {linkContent}
        </Box>
      )}
    </Box>
  );
};

export default CustomLink;
