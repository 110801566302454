import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Call from "../../../hocs/call";
import ChartSettingsColors from "../../chart-settings-forms/Colors";
import ChartSettingsGeneral from "../../chart-settings-forms/General";
import ChartSettingsLayout from "../../chart-settings-forms/Layout";
import ChartCombinationsWrapper from "../../chart/ChartCombinationsWrapper";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import DatasetMergedFiltersCombinationsWrapper from "../../dataset-merged-filters/DatasetMergedFiltersCombinationsWrapper";
import MapSettingsSettings from "../../map-settings-form/Settings";
import MapCombionationsWrapper from "../../map/multi-viewer/MapCombionationsWrapper";
import TablePaginated from "../../table/multi-viewer";
import {
  fetchDatasetMVPDatasetPageSize,
  fetchDatasetMVPMapDataset,
  fetchDatasetMVPMapTerritories,
  fetchDatasetMVPTableChartDataset,
  fetchDatasetMVPTerritorialClassifications,
  fetchDatasetMVPTerritorialClassificationsValues,
  setDatasetMVPChartSettings,
  setDatasetMVPDatasetMapFilters,
  setDatasetMVPDatasetTableFilters,
  setDatasetMVPHtmlGeneratingTime,
  setDatasetMVPMapSettings,
  setDatasetMVPTerritorialClassificationsConfig,
  setDatasetMVPViewerChartType,
  submitDatasetMVPChartLayout,
  submitDatasetMVPMapLayout
} from "../../../state/dataset/multi-viewer-plus/actions";
import {getFilteredCriteriaFromLayout} from "../../../utils/criteria";
import {getDimensionLabelFromCombinations, getDimensionValueLabelFromCombinations} from "../../../utils/dataset";
import {getNode} from "../../../utils/tree";
import {getTimeDimValueFromLayout} from "../../map/utils";

const $ = window.jQuery;

const mapStateToProps = ({config, appConfig, hub, dataset}) => ({
  optimizedDataRetrieverBaseUrl: config.externalServices?.optimizedDataRetriever,
  geoglossaryBaseUrl: config.externalServices?.geoglossary,
  maxTerritoriesCount: appConfig.chartConfig.maxTerritoryValueCount,
  datasetUuid: dataset.multiViewerPlus.datasetUuid,
  dimensions: dataset.multiViewerPlus.dimensions,
  dimensionLabels: dataset.multiViewerPlus.dimensionLabels,
  dimensionMap: dataset.multiViewerPlus.dimensionMap,
  territoryDimCodelist: dataset.multiViewerPlus.territoryDimCodelist,
  territoryDim: dataset.multiViewerPlus.territoryDim,
  timeDim: dataset.multiViewerPlus.timeDim,
  colCount: dataset.multiViewerPlus.colCount,
  isFetchingCols: dataset.multiViewerPlus.isFetchingCols,
  cols: dataset.multiViewerPlus.cols,
  rowCount: dataset.multiViewerPlus.rowCount,
  rows: dataset.multiViewerPlus.rows,
  seriesAttributeMap: dataset.multiViewerPlus.seriesAttributeMap,
  observationAttributeMap: dataset.multiViewerPlus.observationAttributeMap,
  tableFilters: dataset.multiViewerPlus.tableFilters,
  tableChartDatasetFetchEnabled: dataset.multiViewerPlus.tableChartDatasetFetchEnabled,
  tableChartDatasetFetchError: dataset.multiViewerPlus.tableChartDatasetFetchError,
  tableChartDataset: dataset.multiViewerPlus.tableChartDataset,
  tableChartDatasetSize: dataset.multiViewerPlus.tableChartDatasetSize,
  mapFilters: dataset.multiViewerPlus.mapFilters,
  mapDatasetFetchEnabled: dataset.multiViewerPlus.mapDatasetFetchEnabled,
  mapDatasetFetchPending: dataset.multiViewerPlus.mapDatasetFetchPending,
  mapDatasetFetchError: dataset.multiViewerPlus.mapDatasetFetchError,
  mapTerritories: dataset.multiViewerPlus.mapTerritories,
  mapDataset: dataset.multiViewerPlus.mapDataset,
  isEmptyData: dataset.multiViewerPlus.isEmptyData,
  isTableVisible: dataset.multiViewerPlus.isTableVisible,
  isMapVisible: dataset.multiViewerPlus.isMapVisible,
  isChartVisible: dataset.multiViewerPlus.isChartVisible,
  chartType: dataset.multiViewerPlus.chartType,
  tableLayout: dataset.multiViewerPlus.tableLayout,
  mapLayout: dataset.multiViewerPlus.mapLayout,
  chartLayout: dataset.multiViewerPlus.chartLayout,
  labelFormat: dataset.multiViewerPlus.labelFormat,
  areCriteriaApplied: dataset.multiViewerPlus.areCriteriaApplied,
  criteria: dataset.multiViewerPlus.criteria,
  decimalSeparator: dataset.multiViewerPlus.decimalSeparator,
  roundingStrategy: dataset.multiViewerPlus.roundingStrategy,
  decimalPlaces: dataset.multiViewerPlus.decimalPlaces,
  tableEmptyChar: dataset.multiViewerPlus.tableEmptyChar,
  chartSettings: dataset.multiViewerPlus.chartSettings,
  mapSettings: dataset.multiViewerPlus.mapSettings,
  detailLevelTree: dataset.multiViewerPlus.detailLevelTree,
  detailLevel: dataset.multiViewerPlus.detailLevel,
  allTerritoriesSelected: dataset.multiViewerPlus.allTerritoriesSelected,
  territorialClassifications: dataset.multiViewerPlus.territorialClassifications,
  territorialClassificationsConfig: dataset.multiViewerPlus.territorialClassificationsConfig,
  territorialClassificationsFetched: dataset.multiViewerPlus.territorialClassificationsFetched,
  territorialClassificationsValues: dataset.multiViewerPlus.territorialClassificationsValues,
  synthesisAndVariabilityMeasures: dataset.multiViewerPlus.synthesisAndVariabilityMeasures,
  showArithmeticMean: dataset.multiViewerPlus.showArithmeticMean,
  showStandardDeviation: dataset.multiViewerPlus.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewerPlus.showCoefficientOfVariation,
  geometriesYear: dataset.multiViewerPlus.geometriesYear,
  geometriesYears: dataset.multiViewerPlus.geometriesYears
});

const mapDispatchToProps = dispatch => ({
  fetchDatasetPageSize: ({nodeId, baseUrl}) => dispatch(fetchDatasetMVPDatasetPageSize(nodeId, baseUrl)),
  onTableFiltersSet: filters => dispatch(setDatasetMVPDatasetTableFilters(filters)),
  onMapFiltersSet: filters => dispatch(setDatasetMVPDatasetMapFilters(filters)),
  fetchTableChartDataset: (
    nodeId,
    datasetId,
    datasetTitle,
    columns,
    criteria,
    territoryDim,
    detailLevelNameIdentifier,
    allTerritoriesSelected,
    tableFilters,
    mapFilters,
    offset,
    baseUrl
  ) =>
    dispatch(
      fetchDatasetMVPTableChartDataset(
        nodeId,
        datasetId,
        datasetTitle,
        columns,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        tableFilters,
        mapFilters,
        offset,
        baseUrl
      )
    ),
  fetchMapTerritories: (
    nodeId,
    datasetId,
    criteria,
    territoryDim,
    detailLevelNameIdentifier,
    allTerritoriesSelected,
    dimensions,
    baseUrl
  ) =>
    dispatch(
      fetchDatasetMVPMapTerritories(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        dimensions,
        baseUrl
      )
    ),
  fetchMapDataset: (
    nodeId,
    datasetId,
    datasetTitle,
    columns,
    criteria,
    territoryDim,
    detailLevelNameIdentifier,
    allTerritoriesSelected,
    baseUrl
  ) =>
    dispatch(
      fetchDatasetMVPMapDataset(
        nodeId,
        datasetId,
        datasetTitle,
        columns,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        baseUrl
      )
    ),
  onChartSettingsSet: chartSetting => dispatch(setDatasetMVPChartSettings(chartSetting)),
  onChartLayoutSet: layout => dispatch(submitDatasetMVPChartLayout(layout)),
  onChartTypeSet: type => dispatch(setDatasetMVPViewerChartType(type)),
  onMapSettingsSet: mapSettings => dispatch(setDatasetMVPMapSettings(mapSettings)),
  onMapLayoutSet: layout => dispatch(submitDatasetMVPMapLayout(layout)),
  fetchTerritorialClassifications: (detailLevel, baseUrl) =>
    dispatch(fetchDatasetMVPTerritorialClassifications(detailLevel, baseUrl)),
  setTerritorialClassificationsConfig: territorialClassificationsConfig =>
    dispatch(setDatasetMVPTerritorialClassificationsConfig(territorialClassificationsConfig)),
  fetchTerritorialClassificationsValues: (nodeId, criteria, territoryDim, classifications, year, baseUrl) =>
    dispatch(
      fetchDatasetMVPTerritorialClassificationsValues(nodeId, criteria, territoryDim, classifications, year, baseUrl)
    ),
  onTimeSet: time => dispatch(setDatasetMVPHtmlGeneratingTime(time))
});

const handleStyle = mapId => {
  const viewerHeaderHeight = $("#data-viewer__viewer__header").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers").height(`calc(100% - ${viewerHeaderHeight}px)`);

  const mapSettingsWidth = $("#data-viewer__viewer__viewers__map__settings").outerWidth(true) || 0;
  $("#data-viewer__viewer__viewers__map__filters").css({maxWidth: `calc(100% - ${mapSettingsWidth}px)`});

  const mapFiltersAndSettingsHeight =
    $("#data-viewer__viewer__viewers__map__filters-settings-container").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers__map__viewer").height(`calc(100% - ${mapFiltersAndSettingsHeight}px)`);

  const chartSettingsWidth = $("#data-viewer__viewer__viewers__chart__settings").outerWidth(true) || 0;
  $("#data-viewer__viewer__viewers__chart__filters").css({maxWidth: `calc(100% - ${chartSettingsWidth}px)`});

  const chartFiltersAndSettingsHeight =
    $("#data-viewer__viewer__viewers__chart__filters-settings-container").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers__chart__viewer").height(`calc(100% - ${chartFiltersAndSettingsHeight}px)`);

  if (window.LMap && mapId) {
    window.LMap.handleViewportChange(mapId);
  }
};

const getDimensionValues = (dimension, timeDim, territoryDim, rows, cols) => {
  return dimension === timeDim
    ? [...new Set(cols.map(col => col[timeDim]))]
    : dimension === territoryDim
    ? rows.map(row => row[territoryDim])
    : [];
};

function MultiViewerPlusViewer(props) {
  const {
    nodeId,
    datasetId,
    datasetTitle,
    chartId,
    mapId,

    optimizedDataRetrieverBaseUrl,
    geoglossaryBaseUrl,
    maxTerritoriesCount,
    datasetUuid,
    dimensions,
    dimensionLabels,
    dimensionMap,
    territoryDimCodelist,
    territoryDim,
    timeDim,
    colCount,
    isFetchingCols,
    cols,
    rowCount,
    rows,
    seriesAttributeMap,
    observationAttributeMap,
    tableFilters,
    tableChartDatasetFetchEnabled,
    tableChartDatasetFetchError,
    tableChartDataset,
    tableChartDatasetSize,
    mapFilters,
    mapDatasetFetchEnabled,
    mapDatasetFetchPending,
    mapDatasetFetchError,
    mapTerritories,
    mapDataset,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    chartType,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    areCriteriaApplied,
    criteria,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    detailLevelTree,
    detailLevel,
    allTerritoriesSelected,
    synthesisAndVariabilityMeasures,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    territorialClassifications,
    territorialClassificationsConfig,
    territorialClassificationsFetched,
    territorialClassificationsValues,
    geometriesYear: geometriesYearConfig,
    geometriesYears: geometriesYearsConfig,

    fetchDatasetPageSize,
    onTableFiltersSet,
    onMapFiltersSet,
    fetchTableChartDataset,
    fetchMapTerritories,
    fetchMapDataset,
    onChartSettingsSet,
    onChartLayoutSet,
    onChartTypeSet,
    onMapSettingsSet,
    onMapLayoutSet,
    setTerritorialClassificationsConfig,
    fetchTerritorialClassifications,
    fetchTerritorialClassificationsValues,
    onTimeSet
  } = props;

  const {t} = useTranslation();

  const [isChartSettingVisible, setChartSettingsVisibility] = useState(false);
  const [chartSettingsTabId, setChartSettingsTabId] = useState(null);
  const [tmpChartSettings, setTmpChartSettings] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);
  const [tmpChartType, setTmpChartType] = useState(null);

  const [isMapSettingVisible, setMapSettingsVisibility] = useState(false);
  const [tmpMapSettings, setTmpMapSettings] = useState(null);
  const [isMapSettingsValid, setMapSettingsValidity] = useState(true);
  const [isMapRendering, setIsMapRendering] = useState(true);

  const [selectedTerritoryId, setSelectedTerritoryId] = useState(null);
  useEffect(() => {
    if (cols === null) {
      setSelectedTerritoryId(null);
    }
  }, [cols]);

  const detailLevelSuffix = useMemo(
    () => getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix ?? "",
    [detailLevelTree, detailLevel]
  );

  const detailLevelNameIdentifier = useMemo(
    () => getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.nameIdentifier ?? null,
    [detailLevelTree, detailLevel]
  );

  const referenceYear = useMemo(
    () => getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.referenceYear ?? null,
    [detailLevelTree, detailLevel]
  );

  const generalizationLevels = useMemo(
    () => getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.generalizationLevels ?? [],
    [detailLevelTree, detailLevel]
  );

  const filteredTerritoryIds = useMemo(() => {
    const hasTableFilters = !!(
      tableFilters?.idTextFilter ||
      tableFilters?.labelTextFilter ||
      tableFilters?.observationFilters
    );
    return hasTableFilters && rows ? rows.map(row => row[territoryDim]) : null;
  }, [rows, territoryDim, tableFilters]);

  const dataArithmeticMeans = useMemo(() => {
    return synthesisAndVariabilityMeasures
      ? Object.entries(synthesisAndVariabilityMeasures).reduce((acc, [k, v]) => ({...acc, [k]: v.avg}), {})
      : null;
  }, [synthesisAndVariabilityMeasures]);

  const dimensionIds = useMemo(() => (dimensions || []).map(({id}) => id), [dimensions]);

  const geometriesYear = useMemo(() => {
    if (referenceYear) {
      return referenceYear;
    } else {
      const timeVal = getTimeDimValueFromLayout(timeDim, mapLayout);
      return geometriesYearsConfig?.[timeVal] ?? geometriesYearConfig ?? timeVal;
    }
  }, [mapLayout, timeDim, referenceYear, geometriesYearsConfig, geometriesYearConfig]);

  useEffect(() => {
    const func = () => handleStyle(mapId);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [mapId]);

  useEffect(() => {
    handleStyle(mapId);
    window.dispatchEvent(new Event("resize"));
  }, [mapId, mapDataset, isTableVisible, isMapVisible, isChartVisible]);

  const handleFetchTableChartDataset = useCallback(
    offset => {
      if (tableChartDatasetFetchEnabled) {
        fetchTableChartDataset(
          nodeId,
          datasetId,
          datasetTitle,
          dimensionIds,
          criteria,
          territoryDim,
          detailLevelNameIdentifier,
          allTerritoriesSelected,
          tableFilters,
          mapFilters,
          offset,
          optimizedDataRetrieverBaseUrl
        );
      }
    },
    [
      tableChartDatasetFetchEnabled,
      nodeId,
      datasetId,
      datasetTitle,
      dimensionIds,
      criteria,
      territoryDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      tableFilters,
      mapFilters,
      optimizedDataRetrieverBaseUrl,
      fetchTableChartDataset
    ]
  );

  const handleFetchMapTerritories = useCallback(
    ({criteria, mapLayout}) => {
      const mapCriteria = getFilteredCriteriaFromLayout(criteria, dimensions, mapLayout, timeDim);
      fetchMapTerritories(
        nodeId,
        datasetId,
        mapCriteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        [territoryDim],
        optimizedDataRetrieverBaseUrl
      );
    },
    [
      nodeId,
      datasetId,
      dimensions,
      territoryDim,
      timeDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      optimizedDataRetrieverBaseUrl,
      fetchMapTerritories
    ]
  );

  const handleFetchMapDataset = useCallback(
    ({criteria, mapLayout}) => {
      const mapCriteria = getFilteredCriteriaFromLayout(criteria, dimensions, mapLayout, timeDim);
      fetchMapDataset(
        nodeId,
        datasetId,
        datasetTitle,
        dimensionIds,
        mapCriteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        optimizedDataRetrieverBaseUrl
      );
    },
    [
      nodeId,
      datasetId,
      datasetTitle,
      dimensions,
      dimensionIds,
      territoryDim,
      timeDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      optimizedDataRetrieverBaseUrl,
      fetchMapDataset
    ]
  );

  const handleMapSelect = useCallback(
    territoryValues => {
      if ((territoryValues || []).length > 0) {
        onMapFiltersSet(territoryValues);
      } else {
        onMapFiltersSet(null);
      }
    },
    [onMapFiltersSet]
  );

  const handleMapClick = useCallback((geometry, isActive, isSelected) => {
    if (!geometry) {
      setSelectedTerritoryId(null);
    } else if (geometry && isActive) {
      const territoryId = geometry.territoryId;
      setSelectedTerritoryId(isSelected ? territoryId : null);
    }
  }, []);

  const handleTableRowClick = useCallback((territoryVal, isActive) => {
    setSelectedTerritoryId(isActive ? territoryVal : null);
  }, []);

  const onMapRenderStart = useCallback(() => {
    setIsMapRendering(true);
  }, []);

  const onMapRenderFinish = useCallback(() => {
    setIsMapRendering(false);
  }, []);

  const handleTerritorialClassificationsFetch = useCallback(() => {
    fetchTerritorialClassifications(detailLevel, geoglossaryBaseUrl);
  }, [detailLevel, geoglossaryBaseUrl, fetchTerritorialClassifications]);

  const handleTerritorialClassificationsValuesFetch = useCallback(
    classifications => {
      fetchTerritorialClassificationsValues(nodeId, criteria, territoryDim, classifications, null, geoglossaryBaseUrl);
    },
    [nodeId, criteria, territoryDim, geoglossaryBaseUrl, fetchTerritorialClassificationsValues]
  );

  const onTablePageGenerated = useCallback(
    ({rows, timings}) => {
      const territoryValues = rows.map(row => row[territoryDim]).slice(0, maxTerritoriesCount);
      onChartLayoutSet({
        ...chartLayout,
        primaryDimValues: chartLayout.primaryDim.includes(territoryDim)
          ? territoryValues
          : chartLayout.primaryDimValues,
        secondaryDimValues: chartLayout.secondaryDim.includes(territoryDim)
          ? territoryValues
          : chartLayout.secondaryDimValues
      });
      onTimeSet(Math.round(timings * 100) / 100);
    },
    [chartLayout, territoryDim, maxTerritoriesCount, onChartLayoutSet, onTimeSet]
  );

  const handleChartSettingsOpen = () => {
    setTmpChartSettings(chartSettings);
    setTmpChartLayout(chartLayout);
    setTmpChartType(chartType);
    setChartSettingsTabId("layout");
    setChartSettingsVisibility(true);
  };

  const handleChartSettingsClose = () => {
    setChartSettingsVisibility(false);
  };

  const handleChartSettingsSubmit = () => {
    setChartSettingsVisibility(false);
    onChartSettingsSet(tmpChartSettings);
    onChartLayoutSet(tmpChartLayout);
    onChartTypeSet(tmpChartType);
  };

  const handleMapSettingsOpen = settings => {
    setTmpMapSettings(settings);
    setMapSettingsValidity(true);
    setMapSettingsVisibility(true);
  };

  const handleMapSettingsClose = () => {
    setMapSettingsVisibility(false);
  };

  const handleMapSettingsSubmit = () => {
    setMapSettingsVisibility(false);
    onMapSettingsSet(tmpMapSettings);
  };

  const leftPanelWidth = isMapVisible || isChartVisible ? (isTableVisible ? "60%" : "100%") : "0%";
  const rightPanelWidth = `calc(100% - ${leftPanelWidth})`;

  const leftPanelTopHeight = isMapVisible ? (isChartVisible ? "60%" : "100%") : "0%";
  const leftPanelBottomHeight = `calc(100% - ${leftPanelTopHeight})`;

  return (
    <Fragment>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          padding: "8px"
        }}
      >
        {!areCriteriaApplied ? (
          <CustomEmpty text={t("scenes.dataViewer.errors.applyCriteria")} />
        ) : colCount === null ? (
          <CustomEmpty text={""} />
        ) : isFetchingCols ? (
          <CustomEmpty text={t("scenes.dataViewer.messages.fetchingCols") + "..."} image={<CircularProgress />} />
        ) : cols === null ? (
          <CustomEmpty text={t("scenes.dataViewer.errors.applyRestrictiveCriteria")} />
        ) : (
          <Call
            cb={fetchDatasetPageSize}
            cbParam={{
              nodeId: nodeId,
              baseUrl: optimizedDataRetrieverBaseUrl
            }}
            disabled={tableChartDatasetSize !== null}
          >
            <Call
              cb={handleFetchMapTerritories}
              cbParam={{
                criteria: criteria,
                mapLayout: mapLayout
              }}
              disabled={!datasetUuid || !mapDatasetFetchEnabled || !isMapVisible || !cols}
            >
              <Call
                cb={handleFetchMapDataset}
                cbParam={{
                  criteria: criteria,
                  mapLayout: mapLayout
                }}
                disabled={
                  !datasetUuid || !mapDatasetFetchEnabled || !isMapVisible || !mapLayout || !cols || !mapTerritories
                }
              >
                <Box
                  id="data-viewer__viewer__viewers"
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative"
                  }}
                >
                  <Fragment>
                    {(isMapVisible || isChartVisible) && (
                      <Box sx={{display: "inline-block", verticalAlign: "top", height: "100%", width: leftPanelWidth}}>
                        {isMapVisible && (
                          <Box sx={{height: leftPanelTopHeight, width: "100%", padding: "8px"}}>
                            <Grid
                              container
                              id="data-viewer__viewer__viewers__map__filters-settings-container"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{marginBottom: "4px"}}
                              wrap="nowrap"
                              style={{visibility: mapTerritories !== null ? "visible" : "hidden"}}
                            >
                              <Grid item id="data-viewer__viewer__viewers__map__filters">
                                {!mapDatasetFetchError && (
                                  <DatasetMergedFiltersCombinationsWrapper
                                    cols={cols}
                                    layout={mapLayout}
                                    timeDim={timeDim}
                                    dimensionLabels={dimensionLabels}
                                    dimensionMap={dimensionMap}
                                    seriesAttributeMap={seriesAttributeMap}
                                    dimensionsAttributes={tableChartDataset?.dimensionsAttributes}
                                    onChange={onMapLayoutSet}
                                  />
                                )}
                              </Grid>
                              <Grid item id="data-viewer__viewer__viewers__map__settings" sx={{height: "30px"}}>
                                {!isMapRendering && (
                                  <Tooltip title={t("scenes.dataViewer.actions.mapSettings.tooltip")}>
                                    <IconButton
                                      aria-label={t("scenes.dataViewer.actions.mapSettings.ariaLabel")}
                                      size={"small"}
                                      onClick={() => handleMapSettingsOpen(mapSettings)}
                                    >
                                      <SettingsIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                            <Box
                              id="data-viewer__viewer__viewers__map__viewer"
                              sx={{
                                width: "100%",
                                height: "100%",
                                position: "relative"
                              }}
                            >
                              {mapDatasetFetchError ? (
                                <CustomEmpty
                                  text={t("scenes.dataViewer.multiViewerPlus.map.error")}
                                  image={<ErrorIcon />}
                                />
                              ) : mapTerritories === null || mapDatasetFetchPending ? (
                                <CustomEmpty
                                  text={t("scenes.dataViewer.multiViewerPlus.map.fetching") + "..."}
                                  image={<CircularProgress />}
                                />
                              ) : mapDataset !== null ? (
                                <MapCombionationsWrapper
                                  mapId={mapId}
                                  nodeId={nodeId}
                                  dimensionIds={dimensionIds}
                                  dimensionMap={dimensionMap}
                                  territoryDim={territoryDim}
                                  territories={mapTerritories}
                                  seriesAttributeMap={seriesAttributeMap}
                                  observationAttributeMap={observationAttributeMap}
                                  geometriesYear={geometriesYear}
                                  layout={mapLayout}
                                  getFilterTree={null}
                                  data={mapDataset}
                                  decimalSeparator={decimalSeparator}
                                  roundingStrategy={roundingStrategy}
                                  decimalPlaces={decimalPlaces}
                                  detailLevel={detailLevel}
                                  territoryDimCodelist={territoryDimCodelist}
                                  initialBaseLayer={mapSettings.baseLayer}
                                  defaultSettings={{
                                    isLegendCollapsed: mapSettings.isLegendCollapsed,
                                    opacity: mapSettings.opacity
                                  }}
                                  settings={{
                                    classificationMethod: mapSettings.classificationMethod,
                                    paletteStartColor: mapSettings.paletteStartColor,
                                    paletteEndColor: mapSettings.paletteEndColor,
                                    paletteCardinality: mapSettings.paletteCardinality,
                                    customIntervals: mapSettings.customIntervals
                                  }}
                                  setSettings={onMapSettingsSet}
                                  showSelection
                                  disableSettings
                                  onDataClick={handleMapClick}
                                  onDataSelect={handleMapSelect}
                                  selectedTerritoryId={selectedTerritoryId}
                                  filteredTerritoryIds={filteredTerritoryIds}
                                  onSettingsShow={handleMapSettingsOpen}
                                  onRenderStart={onMapRenderStart}
                                  onRenderFinish={onMapRenderFinish}
                                  showSingleGeometry
                                  generalizationLevels={generalizationLevels}
                                />
                              ) : (
                                <CustomEmpty>{""}</CustomEmpty>
                              )}
                            </Box>
                          </Box>
                        )}
                        {isChartVisible && (
                          <Box sx={{height: leftPanelBottomHeight, width: "100%", padding: "8px"}}>
                            <Call
                              cb={handleFetchTableChartDataset}
                              cbParam={0}
                              disabled={isTableVisible || tableChartDataset !== null}
                            >
                              <Grid
                                container
                                id="data-viewer__viewer__viewers__chart__filters-settings-container"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{marginBottom: "4px"}}
                                style={{visibility: rows !== null ? "visible" : "hidden"}}
                              >
                                <Grid item id="data-viewer__viewer__viewers__chart__filters">
                                  {!tableChartDatasetFetchError && (
                                    <DatasetMergedFiltersCombinationsWrapper
                                      cols={cols}
                                      layout={chartLayout}
                                      timeDim={timeDim}
                                      dimensionLabels={dimensionLabels}
                                      dimensionMap={dimensionMap}
                                      seriesAttributeMap={seriesAttributeMap}
                                      dimensionsAttributes={tableChartDataset?.dimensionsAttributes}
                                      onChange={onChartLayoutSet}
                                    />
                                  )}
                                </Grid>
                                <Grid item id="data-viewer__viewer__viewers__chart__settings" sx={{height: "30px"}}>
                                  <Tooltip title={t("scenes.dataViewer.actions.chartSettings.tooltip")}>
                                    <IconButton
                                      aria-label={t("scenes.dataViewer.actions.chartSettings.ariaLabel")}
                                      size={"small"}
                                      onClick={handleChartSettingsOpen}
                                    >
                                      <SettingsIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              <Box
                                id="data-viewer__viewer__viewers__chart__viewer"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  position: "relative"
                                }}
                              >
                                {tableChartDatasetFetchError ? (
                                  <CustomEmpty
                                    text={t("scenes.dataViewer.multiViewerPlus.chart.error")}
                                    image={<ErrorIcon />}
                                  />
                                ) : rows === null ? (
                                  <CustomEmpty
                                    text={t("scenes.dataViewer.multiViewerPlus.chart.fetching") + "..."}
                                    image={<CircularProgress />}
                                  />
                                ) : tableChartDatasetSize !== null ? (
                                  <ChartCombinationsWrapper
                                    chartId={chartId}
                                    type={chartType}
                                    dataset={tableChartDataset}
                                    seriesAttributeMap={seriesAttributeMap}
                                    observationAttributeMap={observationAttributeMap}
                                    timeDim={timeDim}
                                    territoryDim={territoryDim}
                                    dimensions={dimensionIds}
                                    dimensionLabels={dimensionLabels}
                                    dimensionMap={dimensionMap}
                                    layout={chartLayout}
                                    labelFormat={labelFormat}
                                    decimalSeparator={decimalSeparator}
                                    roundingStrategy={roundingStrategy}
                                    decimalPlaces={decimalPlaces}
                                    chartSettings={chartSettings}
                                    showArithmeticMean={showArithmeticMean}
                                    dataArithmeticMeans={dataArithmeticMeans}
                                    arithmeticMeansDimensions={tableLayout?.cols}
                                  />
                                ) : (
                                  <CustomEmpty>{""}</CustomEmpty>
                                )}
                              </Box>
                            </Call>
                          </Box>
                        )}
                      </Box>
                    )}
                    {isTableVisible && (
                      <Box sx={{display: "inline-block", verticalAlign: "top", height: "100%", width: rightPanelWidth}}>
                        <Box
                          id="data-viewer__viewer__viewers__table__viewer"
                          sx={{
                            width: "100%",
                            height: "100%",
                            position: "relative"
                          }}
                          style={{height: "100%", width: "100%", padding: 8}}
                        >
                          {tableChartDatasetFetchError ? (
                            <CustomEmpty
                              text={t("scenes.dataViewer.multiViewerPlus.table.error")}
                              image={<ErrorIcon />}
                            />
                          ) : rows === null ? (
                            <CustomEmpty
                              text={t("scenes.dataViewer.multiViewerPlus.table.fetching") + "..."}
                              image={<CircularProgress />}
                            />
                          ) : tableChartDatasetSize !== null ? (
                            <TablePaginated
                              dimensions={dimensions}
                              dimensionLabels={dimensionLabels}
                              dimensionMap={dimensionMap}
                              territoryDim={territoryDim}
                              timeDim={timeDim}
                              layout={tableLayout}
                              colCount={colCount}
                              cols={cols}
                              rowCount={rowCount}
                              rows={rows}
                              seriesAttributeMap={seriesAttributeMap}
                              observationAttributeMap={observationAttributeMap}
                              data={tableChartDataset}
                              dataSize={tableChartDatasetSize}
                              fetchData={handleFetchTableChartDataset}
                              filters={tableFilters}
                              onFilter={onTableFiltersSet}
                              decimalSeparator={decimalSeparator}
                              roundingStrategy={roundingStrategy}
                              decimalPlaces={decimalPlaces}
                              emptyChar={tableEmptyChar}
                              detailLevelSuffix={detailLevelSuffix}
                              selectedTerritoryId={selectedTerritoryId}
                              onRowClick={handleTableRowClick}
                              synthesisAndVariabilityMeasures={synthesisAndVariabilityMeasures}
                              showArithmeticMean={showArithmeticMean}
                              showStandardDeviation={showStandardDeviation}
                              showCoefficientOfVariation={showCoefficientOfVariation}
                              territorialClassifications={territorialClassifications}
                              fetchTerritorialClassifications={handleTerritorialClassificationsFetch}
                              territorialClassificationsConfig={territorialClassificationsConfig}
                              setTerritorialClassificationsConfig={setTerritorialClassificationsConfig}
                              territorialClassificationsFetched={territorialClassificationsFetched}
                              territorialClassificationsValues={territorialClassificationsValues}
                              fetchTerritorialClassificationsValues={handleTerritorialClassificationsValuesFetch}
                              onPageGenerated={onTablePageGenerated}
                            />
                          ) : (
                            <CustomEmpty>{""}</CustomEmpty>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Fragment>
                </Box>
              </Call>
            </Call>
          </Call>
        )}
      </Card>

      <Dialog open={isChartSettingVisible} maxWidth="md" fullWidth onClose={handleChartSettingsClose}>
        <CustomDialogTitle onClose={handleChartSettingsClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.chartSettings.title")}
        </CustomDialogTitle>
        <DialogContent sx={{height: "500px"}}>
          <Box sx={{height: "48px"}}>
            <Tabs
              value={chartSettingsTabId}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => setChartSettingsTabId(newValue)}
            >
              <Tab value={"layout"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.layout")} />
              <Tab value={"general"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.general")} />
              <Tab value={"colors"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.colors")} />
            </Tabs>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 64px)",
              marginTop: "16px",
              paddingRight: "4px",
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            {chartSettingsTabId === "layout" && (
              <ChartSettingsLayout
                layout={tmpChartLayout}
                onLayoutSet={setTmpChartLayout}
                type={tmpChartType}
                onTypeSet={setTmpChartType}
                getDimLabel={dim => getDimensionLabelFromCombinations(dim, dimensionLabels)}
              />
            )}
            {chartSettingsTabId === "general" && (
              <ChartSettingsGeneral settings={tmpChartSettings} onSettingsSet={setTmpChartSettings} />
            )}
            {chartSettingsTabId === "colors" && (
              <ChartSettingsColors
                settings={tmpChartSettings}
                dimensions={[...(chartLayout?.primaryDim || []), ...(chartLayout?.secondaryDim || [])]}
                onSettingsSet={setTmpChartSettings}
                dataHasDimension={dim => dimensionIds.includes(dim)}
                dataHasDimensionValue={(dim, dimVal) => dimensionMap?.[dim]?.[dimVal] !== undefined}
                getDimLabel={dim => getDimensionLabelFromCombinations(dim, dimensionLabels)}
                getDimValueLabel={(dimId, valueId) =>
                  getDimensionValueLabelFromCombinations(dimId, valueId, dimensionMap)
                }
                getDimensionValues={dim => getDimensionValues(dim, timeDim, territoryDim, rows, cols)}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChartSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button autoFocus onClick={handleChartSettingsSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isMapSettingVisible} maxWidth="md" fullWidth onClose={handleMapSettingsClose}>
        <CustomDialogTitle onClose={handleMapSettingsClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.mapSettings.title")}
        </CustomDialogTitle>
        <DialogContent>
          <MapSettingsSettings
            mapId={mapId}
            settings={tmpMapSettings}
            onSettingsSet={setTmpMapSettings}
            setSettingsValidity={setMapSettingsValidity}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMapSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button autoFocus onClick={handleMapSettingsSubmit} color="primary" disabled={!isMapSettingsValid}>
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewerPlusViewer);
