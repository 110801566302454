import React, {useEffect, useMemo, useState} from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import {getDatasetSize} from "../../utils/dataset";

const getUpdatedLayoutFromSelected = (layout, selected) => ({
  ...layout,
  cols: (layout?.cols || []).filter(dim => !selected.includes(dim)),
  rows: (layout?.rows || []).filter(dim => !selected.includes(dim)),
  sections: (layout?.sections || []).filter(dim => !selected.includes(dim)),
  filters: [...(layout?.filters || []), ...selected]
});

const TableLayoutLight = ({open, onClose, onSubmit, onLayoutOpen, layout, dimensionsInfo, limit}) => {
  const {t} = useTranslation();

  const [selected, setSelected] = useState(null);

  const dimensions = useMemo(
    () =>
      [...(layout?.cols || []), ...(layout?.rows || []), ...(layout?.sections || [])].sort(
        (a, b) => dimensionsInfo?.[b]?.size - dimensionsInfo?.[a]?.size
      ),
    [layout, dimensionsInfo]
  );

  useEffect(() => {
    if (open) {
      setSelected(prevVal => {
        if (prevVal !== null) {
          return prevVal;
        } else {
          const newSelected = [];
          dimensions.forEach(dim => {
            const newLayout = getUpdatedLayoutFromSelected(layout, newSelected);
            if (getDatasetSize(dimensionsInfo, newLayout) > limit) {
              newSelected.push(dim);
            }
          });
          return newSelected;
        }
      });
    }
    return () => {
      setSelected(null);
    };
  }, [open, dimensions, dimensionsInfo, layout, limit]);

  if (!layout || !selected) {
    return null;
  }

  const newLayout = getUpdatedLayoutFromSelected(layout, selected);
  const datasetSize = getDatasetSize(dimensionsInfo, newLayout);

  return (
    <Dialog open={open} maxWidth="md" onClose={onClose}>
      <CustomDialogTitle onClose={onClose}>{t("components.tableLayoutLight.title")}</CustomDialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {(dimensions || []).map((dim, idx) => (
            <Grid key={idx} item xs={12} container spacing={1} alignItems="center">
              <Grid item>
                <Checkbox
                  style={{padding: 6}}
                  checked={selected.includes(dim)}
                  onChange={(ev, checked) =>
                    checked ? setSelected([...selected, dim]) : setSelected(selected.filter(sel => sel !== dim))
                  }
                />
              </Grid>
              <Grid item>{`${dimensionsInfo?.[dim]?.label} (${dimensionsInfo?.[dim]?.size})`}</Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            {onLayoutOpen && (
              <Button
                onClick={() => {
                  onClose();
                  onLayoutOpen();
                }}
                endIcon={<LaunchIcon />}
              >
                {t("components.tableLayoutLight.buttons.openAdvancedMode")}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
            <Button
              onClick={() => {
                onClose();
                onSubmit(newLayout);
              }}
              disabled={limit && datasetSize > limit}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TableLayoutLight;
