import {configureStore} from "@reduxjs/toolkit";
import i18next from "i18next";
import _ from "lodash";
import {initReactI18next} from "react-i18next";
import {
  getAppConfigUrl,
  getCustomTranslationUrl,
  getDashboardConfigUrl,
  getHubLanguagesUrl,
  getInitConfigUrl,
  getMapLayersConfigUrl,
  getModulesConfigUrl
} from "./serverApi/urls";
import {init as initAction} from "./state/rootActions";
import rootReducer from "./state/rootReducer";
import a11yMiddleware from "./middlewares/a11y-middleware/middleware";
import actionDecoratorMiddlewareFactory from "./middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import actionTransformerMiddleware from "./middlewares/action-transformer/middleware";
import configMiddleware from "./middlewares/config/configMiddleware";
import detailLevelMiddleware from "./middlewares/detail-level-middleware/middleware";
import externalServiceRedirectMiddleware from "./middlewares/external-service-redirect-middleware/middleware";
import fetchStructureHandlerMiddleware from "./middlewares/fetch-structure-handler/middleware";
import fileSaveMiddleware from "./middlewares/file-save/middleware";
import i18nMiddleware from "./middlewares/i18n-middleware/middleware";
import multiViewerPlusMiddleware from "./middlewares/multi-viewer-plus/middleware";
import multiViewerMiddlewareFactory from "./middlewares/multi-viewer/middlewareFactory";
import nodeMiddleware from "./middlewares/node/middleware";
import persistenceMiddleware from "./middlewares/persistence/middleware";
import requestSpinnerMiddlewareFactory from "./middlewares/request-spinner/requestSpinnerMiddlewareFactory";
import requestMiddlewareFactory from "./middlewares/request/requestMiddlewareFactory";
import singleViewerMiddlewareFactory from "./middlewares/single-viewer/middlewareFactory";
import userMiddlewareFactory from "./middlewares/user/userMiddlewareFactory";
import {showTranslatedGenericErrorFactory, showTranslatedManagedServerErrorFactory} from "./utils/other";
import {isValidIntegerInInclusiveRange} from "./utils/validator";
import themeConfig from "./theme-config/config.json";

const getThemeAppConfig = appConfig => {
  const newAppConfig = {};

  Object.keys(themeConfig.appConfig).forEach(key => {
    if (themeConfig.configsToMerge.includes(key)) {
      newAppConfig[key] = {
        ...themeConfig.appConfig[key],
        ...(appConfig?.[key] || {})
      };
    } else if (
      typeof themeConfig.appConfig[key] === "object" &&
      !Array.isArray(themeConfig.appConfig[key]) &&
      themeConfig.appConfig[key] !== null
    ) {
      newAppConfig[key] = {};
      Object.keys(themeConfig.appConfig[key]).forEach(subKey => {
        if (themeConfig.configsToMerge.includes(subKey)) {
          newAppConfig[key][subKey] = {
            ...themeConfig.appConfig[key][subKey],
            ...(appConfig?.[key]?.[subKey] || {})
          };
        } else {
          newAppConfig[key][subKey] =
            appConfig?.[key]?.[subKey] !== undefined ? appConfig[key][subKey] : themeConfig.appConfig[key][subKey];
        }
      });
    } else {
      newAppConfig[key] = appConfig?.[key] !== undefined ? appConfig[key] : themeConfig.appConfig[key];
    }
  });

  return newAppConfig;
};

const $ = window.jQuery;

const getRandomParam = () => "?random=" + Math.floor(Math.random() * 16777215).toString(16);

const configFiles = [
  {
    url: getAppConfigUrl(),
    param: "appConfig"
  },
  {
    url: getDashboardConfigUrl(),
    param: "dashboardFilterConfig"
  },
  {
    url: getMapLayersConfigUrl(),
    param: "mapLayersConfig"
  },
  {
    url: getModulesConfigUrl(),
    param: "modulesConfig"
  }
];

const showError = text => {
  $(".preloader__spinner").hide();

  if (text) {
    $(".preloader__error-text").text(text);
  }

  $(".preloader__error").show();
};

const getUrlWithFinalSlash = url => (url.endsWith("/") ? url : url + "/");

const init = async cb => {
  try {
    const configs = {
      themeConfig: {...themeConfig, appConfig: undefined}
    };

    // fetch config.json
    const initResponse = await fetch(`./${getInitConfigUrl()}${getRandomParam()}`);
    const initResponseParsed = await initResponse.json();

    const {baseURL: origBaseURL, externalServices} = initResponseParsed;

    // handling API urls
    const baseURL = getUrlWithFinalSlash(origBaseURL);
    configs.externalServices = _.mapValues(externalServices, getUrlWithFinalSlash);

    // fetch config files
    for (const config of configFiles) {
      const response = await fetch(`./${config.url}${getRandomParam()}`);
      const responseParsed =
        response.ok && response.headers.get("content-type").includes("application/json") ? await response.json() : null;
      configs[config.param] = responseParsed;
    }

    // fetch supported languages
    const langsResponse = await fetch(`${baseURL}${getHubLanguagesUrl()}`);
    const langsRespondeParsed = await langsResponse.json();

    // available translations
    const resources = {};

    // fetch translations for supported languages
    for (const lang of langsRespondeParsed) {
      const response = await fetch(`./i18n/${lang}.json${getRandomParam()}`);
      const responseParsed =
        response.ok && response.headers.get("content-type").includes("application/json") ? await response.json() : null;
      if (responseParsed) {
        resources[lang] = {translation: responseParsed};
      }
    }

    // filtering supported languages based on available translations
    const supportedLanguages = langsRespondeParsed.filter(
      lang => resources[lang] !== undefined && resources[lang] !== null
    );

    if (supportedLanguages.length === 0) {
      // no available translations
      console.error(
        "Unable to find translation file for at least one configured language. Please check app configuration and translations file."
      );
      showError("Error initializing application.");
      return;
    }

    // fetch custom translations for supported languages
    for (const lang of supportedLanguages) {
      const response = await fetch(`./${getCustomTranslationUrl(lang)}${getRandomParam()}`);
      const responseParsed =
        response.ok && response.headers.get("content-type").includes("application/json") ? await response.json() : {};
      _.merge(resources[lang].translation, responseParsed);
    }

    // handling app languages
    const urlLanguage = window.location.hash.split("/")[1];
    const defaultLanguage =
      urlLanguage && supportedLanguages.includes(urlLanguage) ? urlLanguage : supportedLanguages[0] || "en";

    // init i18next
    await i18next.use(initReactI18next).init({
      lng: defaultLanguage,
      resources,
      returnEmptyString: false,
      interpolation: {
        escapeValue: false
      },
      compatibilityJSON: "v3",
      missingInterpolationHandler: () => ""
    });

    // enabled modules
    const enabledModules = (configs?.modulesConfig?.modules || []).filter(({enabled}) => enabled).map(({id}) => id);

    // handling module reducers
    const moduleReducerMap = {};
    for (const moduleId of enabledModules) {
      await import(`./modules/${moduleId}/state/reducer`)
        .then(response => (moduleReducerMap[moduleId] = response.default))
        .catch(() => null);
    }

    // handling module middlewares
    const moduleMiddlewareList = [];
    for (const moduleId of enabledModules) {
      await import(`./modules/${moduleId}/state/middleware`)
        .then(response => moduleMiddlewareList.push(response.default))
        .catch(() => null);
    }

    // create Redux store
    const store = configureStore({
      reducer: {
        ...moduleReducerMap,
        ...rootReducer
      },
      middleware: () => {
        return [
          actionDecoratorMiddlewareFactory(i18next.t.bind(i18next)),
          actionTransformerMiddleware,
          userMiddlewareFactory(i18next.t.bind(i18next)),
          externalServiceRedirectMiddleware,
          requestMiddlewareFactory({
            onGenericError: showTranslatedGenericErrorFactory(i18next.t.bind(i18next)),
            onManagedServerError: showTranslatedManagedServerErrorFactory(i18next.t.bind(i18next))
          }),
          requestSpinnerMiddlewareFactory(i18next.t.bind(i18next)),
          fileSaveMiddleware,
          persistenceMiddleware,
          i18nMiddleware,
          a11yMiddleware,
          detailLevelMiddleware,
          configMiddleware,
          nodeMiddleware,
          fetchStructureHandlerMiddleware,
          singleViewerMiddlewareFactory(i18next.t.bind(i18next)),
          multiViewerMiddlewareFactory(i18next.t.bind(i18next)),
          multiViewerPlusMiddleware,
          ...moduleMiddlewareList
        ];
      }
    });

    // fetch module config files
    const moduleConfigList = await Promise.all(
      enabledModules.map(moduleId => import(`./modules/${moduleId}/config.js`))
    );

    // module configs
    const modulesConfig = {
      modules: [],
      configs: {},
      placeholders: {},
      hubRoutes: [],
      nodeRoutes: [],
      hubPermissions: [],
      nodePermissions: [],
      tableActionFactories: {}
    };
    enabledModules.forEach((moduleId, key) => {
      modulesConfig.modules.push(moduleId);

      modulesConfig.configs[moduleId] = configs.modulesConfig.modules.find(({id}) => id === moduleId).config || null;

      (moduleConfigList[key]?.placeholders || []).forEach(({placeholder, component, fallback}) => {
        if (!modulesConfig.placeholders[placeholder]) {
          modulesConfig.placeholders[placeholder] = [];
        }
        modulesConfig.placeholders[placeholder].push({
          id: moduleId,
          component: component,
          fallback: fallback
        });
      });

      (moduleConfigList[key]?.hubRoutes || []).forEach(
        ({route, component, fallback, hideAppBar, hideFooter, containerMaxWidth}) => {
          modulesConfig.hubRoutes.push({
            id: moduleId,
            route: route,
            component: component,
            fallback: fallback,
            hideAppBar: hideAppBar,
            hideFooter: hideFooter,
            containerMaxWidth: containerMaxWidth
          });
        }
      );

      (moduleConfigList[key]?.nodeRoutes || []).forEach(({route, component, fallback, hideAppBar, hideFooter}) => {
        modulesConfig.nodeRoutes.push({
          id: moduleId,
          route: route,
          component: component,
          fallback: fallback,
          hideAppBar: hideAppBar,
          hideFooter: hideFooter
        });
      });

      if (moduleConfigList[key].getHubPermissions) {
        moduleConfigList[key].getHubPermissions(i18next.t.bind(i18next)).forEach(permission => {
          modulesConfig.hubPermissions.push(permission);
        });
      }

      if (moduleConfigList[key].getNodePermissions) {
        moduleConfigList[key].getNodePermissions(i18next.t.bind(i18next)).forEach(permission => {
          modulesConfig.nodePermissions.push(permission);
        });
      }

      if (moduleConfigList[key].getTableActions) {
        moduleConfigList[key]
          .getTableActions(store.getState, store.dispatch, i18next.t.bind(i18next))
          .forEach(({placeholder, getAction}) => {
            if (!modulesConfig.tableActionFactories[placeholder]) {
              modulesConfig.tableActionFactories[placeholder] = [];
            }
            modulesConfig.tableActionFactories[placeholder].push(getAction);
          });
      }
    });
    modulesConfig.hubPermissions = _.uniqBy(modulesConfig.hubPermissions, "id");
    modulesConfig.nodePermissions = _.uniqBy(modulesConfig.nodePermissions, "id");

    configs.modulesConfig = modulesConfig;

    // handling detail level from url
    let detailLevelParam;
    const split = window.location.hash.split("t=");
    if (split && split.length === 2 && split[1].length > 0) {
      const split2 = split[1].split("&");
      if (split2[0] && split2[0].length > 0) {
        detailLevelParam = decodeURIComponent(split2[0]);
      }
    }
    if (isValidIntegerInInclusiveRange(detailLevelParam)) {
      detailLevelParam = parseInt(detailLevelParam, 10);
    } else {
      detailLevelParam = null;
    }

    // handling theme config
    configs.appConfig = getThemeAppConfig(configs.appConfig);

    // handling PWA manifest
    if ((configs.appConfig.pwaManifestPath || "").length > 0) {
      document.getElementById("manifest").href = configs.appConfig.pwaManifestPath;
    }

    // dispatch init action to start the application
    store.dispatch(initAction(baseURL, supportedLanguages, defaultLanguage, detailLevelParam, configs));
    cb(store);
  } catch (error) {
    showError();
  }
};

export default init;
