import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import {goToHome} from "../../links";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true});
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
            overflow: "hidden",
            fontSize: "24px",
            color: "white"
          }}
        >
          <div>
            <Grid
              container
              justifyContent="center"
              sx={{
                marginBottom: theme => theme.spacing(1),
                fontSize: "38px"
              }}
            >
              <Grid item>
                <ErrorOutlineIcon fontSize="inherit" />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>Something went wrong</Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              sx={theme => ({
                marginTop: theme.spacing(2),
                textDecoration: "underline",
                cursor: "pointer",
                color: theme.palette.secondary.main
              })}
            >
              <Grid item>
                <div
                  onClick={() => {
                    goToHome();
                    window.location.reload();
                  }}
                >
                  Go to home
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
