import React from "react";
import {Box} from "@mui/material";

const TabPanel = ({value, selected, children, ...other}) => (
  <Box role="tabpanel" hidden={value !== selected} {...other}>
    {value === selected && children}
  </Box>
);

export default TabPanel;
