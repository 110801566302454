import React, {Fragment, useEffect, useState} from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../../hocs/call";
import CustomEmpty from "../../custom-empty";
import MultiViewerHeader from "./Header";
import MultiViewerSidebar from "./Sidebar";
import MultiViewerViewer from "./Viewer";
import {
  fetchDatasetMVDataset,
  handleDatasetMVDetailLevels,
  hideDatasetMVDownloadWarning,
  hideDatasetMVUnavailableViewWarning
} from "../../../state/dataset/multi-viewer/actions";

const $ = window.jQuery;

const mapStateToProps = ({config, app, catalog, detailLevel, dataset}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  defaultLanguage: app.language,
  languages: app.languages,
  datasetMap: (catalog.uncategorizedDatasets || []).reduce(
    (acc, v) => ({...acc, [v.identifier]: v}),
    catalog.datasetMap || {}
  ),
  catalogDetailLevelTree: catalog?.detailLevelTree,
  nodeDetailLevel: detailLevel.detailLevel,
  datasetUuid: dataset.multiViewer.datasetUuid,
  notCompatibleDataset: dataset.multiViewer.notCompatibleDataset,
  territoryDim: dataset.multiViewer.territoryDim,
  timeDim: dataset.multiViewer.timeDim,
  criteria: dataset.multiViewer.criteria,
  isFetchDatasetDisabled: dataset.multiViewer.isFetchDatasetDisabled,
  isDownloadWarningVisible: dataset.multiViewer.isDownloadWarningVisible,
  isUnavailableViewWarningVisible: dataset.multiViewer.isUnavailableViewWarningVisible,
  datasetDetailLevelTree: dataset.multiViewer.detailLevelTree,
  datasetDetailLevel: dataset.multiViewer.detailLevel,
  indicators: dataset.multiViewer.indicators,
  additionalDatasets: dataset.multiViewer.additionalDatasets,
  geoglossaryBaseUrl: config.externalServices?.geoglossary
});

const mapDispatchToProps = dispatch => ({
  handleDetailLevels: ({
    nodeId,
    datasetId,
    catalogDetailLevelTree,
    datasetDetailLevels,
    datasetDetailLevel,
    nodeDetailLevel,
    baseUrl
  }) =>
    dispatch(
      handleDatasetMVDetailLevels(
        nodeId,
        datasetId,
        catalogDetailLevelTree,
        datasetDetailLevels,
        datasetDetailLevel,
        nodeDetailLevel,
        baseUrl
      )
    ),
  onDatasetFetch: ({
    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    criteria,
    timeDim,
    territoryDim,
    indicators,
    additionalDatasets,
    defaultLanguage,
    languages,
    indicatorsBaseUrl
  }) =>
    dispatch(
      fetchDatasetMVDataset(
        nodeId,
        nodeCode,
        datasetId,
        datasetTitle,
        criteria,
        timeDim,
        territoryDim,
        indicators,
        additionalDatasets,
        defaultLanguage,
        languages,
        indicatorsBaseUrl
      )
    ),
  onDownloadWarningHide: () => dispatch(hideDatasetMVDownloadWarning()),
  onUnavailableViewHide: () => dispatch(hideDatasetMVUnavailableViewWarning())
});

const handleStyle = () => {
  const headerHeight = document.getElementById("data-viewer__header")?.offsetHeight || 0;
  $("#data-viewer__sidebar-viewer-container").height(`calc(100% - ${headerHeight}px)`);

  const sidebarWidth = document.getElementById("data-viewer__sidebar")?.offsetWidth || 0;
  $("#data-viewer__viewer").width(`calc(100% - ${sidebarWidth}px)`);
};

function MultiViewer(props) {
  const {
    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    nodeExtras,

    indicatorsBaseUrl,
    defaultLanguage,
    languages,
    datasetMap,
    catalogDetailLevelTree,
    nodeDetailLevel,
    datasetUuid,
    notCompatibleDataset,
    territoryDim,
    timeDim,
    criteria,
    isFetchDatasetDisabled,
    isDownloadWarningVisible,
    isUnavailableViewWarningVisible,
    datasetDetailLevelTree,
    datasetDetailLevel,
    indicators,
    additionalDatasets,

    handleDetailLevels,
    onDatasetFetch,
    onDownloadWarningHide,
    onUnavailableViewHide,

    geoglossaryBaseUrl
  } = props;

  const {t} = useTranslation();

  const [chartId] = useState("chart__" + uuidv4());

  const [mapId] = useState("map__" + uuidv4());

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  return (
    <Fragment>
      <Box
        id="data-viewer"
        sx={{
          width: "100%",
          height: "100%",
          overflow: "auto"
        }}
        className={`data-viewer__multi-viewer`}
      >
        <Call
          cb={handleDetailLevels}
          cbParam={{
            nodeId: nodeId,
            datasetId: datasetId,
            catalogDetailLevelTree: catalogDetailLevelTree,
            datasetDetailLevels: datasetMap[datasetId]?.detailsLevels,
            datasetDetailLevel: datasetDetailLevel,
            nodeDetailLevel: nodeDetailLevel,
            baseUrl: geoglossaryBaseUrl
          }}
          disabled={!datasetUuid || datasetDetailLevelTree !== null}
        >
          <Call
            cb={onDatasetFetch}
            cbParam={{
              nodeId: nodeId,
              nodeCode: nodeCode,
              datasetId: datasetId,
              criteria: criteria,
              datasetTitle: datasetTitle,
              timeDim: timeDim,
              territoryDim: territoryDim,
              indicators: indicators,
              additionalDatasets: additionalDatasets,
              defaultLanguage: defaultLanguage,
              languages: languages,
              indicatorsBaseUrl: indicatorsBaseUrl
            }}
            disabled={!datasetUuid || criteria === null || datasetDetailLevelTree === null || isFetchDatasetDisabled}
          >
            {notCompatibleDataset ? (
              <CustomEmpty
                id="data-viewer__error"
                text={t("scenes.dataViewer.multiViewer.errors.notCompatibleData")}
                image={<ErrorIcon />}
              />
            ) : (
              <Fragment>
                <Box
                  id="data-viewer__header"
                  sx={{
                    width: "100%",
                    padding: "4px 16px 12px"
                  }}
                >
                  <MultiViewerHeader
                    nodeId={nodeId}
                    nodeCode={nodeCode}
                    nodeExtras={nodeExtras}
                    datasetId={datasetId}
                    datasetTitle={datasetTitle}
                    viewId={viewId}
                    attachedFiles={attachedFiles}
                    chartId={chartId}
                    mapId={mapId}
                    onRender={handleStyle}
                    datasetMap={datasetMap}
                  />
                </Box>
                <Box
                  id="data-viewer__sidebar-viewer-container"
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    padding: "0 16px 16px",
                    minHeight: "400px",
                    minWidth: "560px"
                  }}
                >
                  <Box id="data-viewer__sidebar" sx={{height: "100%"}}>
                    <MultiViewerSidebar
                      nodeId={nodeId}
                      nodeCode={nodeCode}
                      nodeExtras={nodeExtras}
                      datasetId={datasetId}
                      datasetTitle={datasetTitle}
                      chartId={chartId}
                      mapId={mapId}
                      datasetMap={datasetMap}
                    />
                  </Box>
                  <Box id="data-viewer__viewer" sx={{height: "100%"}}>
                    <MultiViewerViewer nodeId={nodeId} nodeExtras={nodeExtras} chartId={chartId} mapId={mapId} />
                  </Box>
                </Box>
              </Fragment>
            )}
          </Call>
        </Call>
      </Box>

      <Dialog open={isDownloadWarningVisible} maxWidth="md" onClose={onDownloadWarningHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.downloadFormat.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onDownloadWarningHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isUnavailableViewWarningVisible} maxWidth="md" onClose={onUnavailableViewHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.unavailableView.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onUnavailableViewHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewer);
