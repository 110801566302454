import React, {forwardRef, Fragment, useEffect, useImperativeHandle} from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import Call from "../../../hocs/call";
import UserErrors from "../../user-errors";
import {clearUserEditUser, fetchUserEditUser, submitUserEditUser} from "../../../state/user/userActions";
import {localizeI18nObj} from "../../../utils/i18n";

const fieldStyle = {
  marginTop: theme => theme.spacing(1),
  "& .MuiOutlinedInput-input": {
    padding: "18.5px 14px"
  }
};

const Form = forwardRef(({hub, types, language, languages, config, onSubmit, onCancel}, ref) => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    watch,
    setValue
  } = useForm({
    defaultValues: {
      type: types?.[0]?.id,
      organization: "",
      ...config
    }
  });

  const {t} = useTranslation();

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        const data = {
          ...val,
          isActive: config.isActive,
          type: val.type !== "null" ? val.type : null
        };

        onSubmit(data);
        f(data);
      })();
    },
    cancel(f) {
      onCancel();
      f();
    }
  }));

  useEffect(() => {
    register("type");
  }, [register]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth sx={fieldStyle}>
          <TextField
            {...register("email", {
              required: t("commons.validation.required")
            })}
            disabled
            name="email"
            label={t("scenes.usersSettings.userSettings.fields.email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            variant="outlined"
            required
          />
        </FormControl>
      </Grid>
      {types && types.length > 0 && (
        <Grid item xs={12}>
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              name="type"
              select
              label={t("scenes.usersSettings.userSettings.fields.type.label")}
              variant="outlined"
              onChange={e => setValue("type", e.target.value)}
              value={watch("type") || ""}
              helperText={errors.type?.message}
              error={!!errors.type}
              required
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {types.map(({id, label}) => (
                <MenuItem value={id} key={id}>
                  {localizeI18nObj(label, language, languages)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            {...register("firstName", {
              required: t("commons.validation.required")
            })}
            name="firstName"
            label={t("scenes.usersSettings.userSettings.fields.firstName")}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            variant="outlined"
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            {...register("lastName", {
              required: t("commons.validation.required")
            })}
            name="lastName"
            label={t("scenes.usersSettings.userSettings.fields.lastName")}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            variant="outlined"
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            {...register("organization")}
            name="organization"
            label={t("scenes.usersSettings.userSettings.fields.organization")}
            error={!!errors.organization}
            helperText={errors.organization?.message}
            variant="outlined"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
});

const mapStateToProps = state => ({
  hub: state.hub,
  appConfig: state.appConfig,
  language: state.app.language,
  languages: state.app.languages,
  userId: state.user.userId,
  config: state.user.config,
  userErrors: state.user.userErrors
});

const mapDispatchToProps = dispatch => ({
  fetchConfig: userId => dispatch(fetchUserEditUser(userId)),
  submitConfig: (userId, config) => dispatch(submitUserEditUser(userId, config)),
  clearConfig: () => dispatch(clearUserEditUser())
});

const EditUserForm = (
  {config, userId, fetchConfig, submitConfig, clearConfig, userErrors, hub, appConfig, languages, language},
  ref
) => (
  <Call cb={fetchConfig} cbParam={userId}>
    {config ? (
      <Fragment>
        <UserErrors errors={userErrors} />
        <Form
          config={config}
          ref={ref}
          onSubmit={config => submitConfig(userId, config)}
          onCancel={clearConfig}
          hub={hub}
          types={appConfig.user.typeOptions}
          language={language}
          languages={languages}
        />
      </Fragment>
    ) : (
      <span />
    )}
  </Call>
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(EditUserForm);
