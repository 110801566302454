import React, {useMemo} from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import {getDimensionAttributeMap, getFormattedDimensionValueLabel} from "../../utils/dataset";

const filterElementStyle = {
  display: "inline-block",
  verticalAlign: "middle"
};

const DatasetStaticFilters = ({datasetId, values, jsonStat, labelFormat, localizedTimePeriodFormatMapExternal}) => {
  const {t} = useTranslation();

  const dimAttributeMap = useMemo(
    () => (jsonStat && (jsonStat?.id || []).length > 0 ? getDimensionAttributeMap(jsonStat, t) : null),
    [jsonStat, t]
  );

  return (
    <Box sx={{fontSize: "14px", color: theme => theme.palette.text.primary}}>
      {(values || []).map(({dim, dimLabel, value, valueLabel}, idx, arr) => (
        <Box
          component="span"
          key={idx}
          sx={{
            display: "inline-block",
            verticalAlign: "middle"
          }}
        >
          <Box component="span" sx={filterElementStyle}>
            <b>{dimLabel}</b>:&nbsp;
          </Box>
          <Box component="span" sx={filterElementStyle}>
            <i>{valueLabel}</i>
          </Box>
          {jsonStat && (jsonStat?.id || []).length > 0 && (
            <Box component="span" sx={filterElementStyle}>
              <DatasetFilterAttributeIcon
                dimension={dim}
                dimensionValues={[value]}
                datasetDimAttributeMap={dimAttributeMap?.[datasetId] || {}}
                labelFormat={labelFormat}
                getDimValueLabel={dimVal =>
                  getFormattedDimensionValueLabel(
                    jsonStat,
                    datasetId,
                    dim,
                    dimVal,
                    labelFormat,
                    undefined,
                    localizedTimePeriodFormatMapExternal
                  )
                }
              />
            </Box>
          )}
          <Box component="span" sx={filterElementStyle}>
            {idx < arr.length - 1 ? "," : ""}&nbsp;
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default DatasetStaticFilters;
