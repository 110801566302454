import React, {useEffect, useState} from "react";
import CountryLanguage from "@ladjs/country-language";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useSelector} from "react-redux";
import ButtonSelect from "../button-select";
import {themeConfigShowLangsAsTextSelector} from "../../state/app/appSelectors";

const getLanguageName = (lang, t) => {
  const translations = t => ({
    it: t("commons.languages.it"),
    en: t("commons.languages.en"),
    ar: t("commons.languages.ar"),
    fr: t("commons.languages.fr"),
    pt: t("commons.languages.pt")
  });

  return translations(t)[lang] ?? lang;
};

const I18nInputAdornmentSelect = ({
  value,
  onChange,
  languages,
  ariaLabel,
  tooltip,
  customLangToCountry,
  showAsIcon
}) => {
  const {t} = useTranslation();
  const showLangsAsText = useSelector(themeConfigShowLangsAsTextSelector);

  const [langToCountry, setLangToCountry] = useState(null);

  useEffect(() => {
    let res = {};

    languages.forEach(langCode =>
      CountryLanguage.getLanguageCountries(langCode, (err, countries) => {
        if (!err) {
          res[langCode] =
            customLangToCountry[langCode] ||
            countries.find(({code_2}) => code_2.toLowerCase() === langCode)?.code_2.toLowerCase() ||
            countries[0]?.code_2?.toLowerCase();
        }
      })
    );

    setLangToCountry(res);
  }, [languages, customLangToCountry]);

  if (!langToCountry) {
    return null;
  }

  const iconValue = showLangsAsText ? (
    <span>{getLanguageName(value, t)}</span>
  ) : langToCountry[value] ? (
    <span className={`fi fi-${langToCountry[value]}`} style={{width: 24}} />
  ) : (
    <span>{value}</span>
  );

  return (
    <ButtonSelect
      value={showLangsAsText || !showAsIcon ? iconValue : undefined}
      icon={showLangsAsText || !showAsIcon ? undefined : iconValue}
      onChange={onChange}
      ariaLabel={ariaLabel}
      tooltip={tooltip}
    >
      {languages.map(lang =>
        showLangsAsText ? (
          <span key={lang} data-value={lang} aria-label={lang}>
            {getLanguageName(lang, t)}
          </span>
        ) : langToCountry[lang] ? (
          <span key={lang} data-value={lang} aria-label={lang} className={`fi fi-${langToCountry[lang]}`} />
        ) : (
          <span key={lang} data-value={lang} aria-label={lang}>
            {(lang || "").toUpperCase()}
          </span>
        )
      )}
    </ButtonSelect>
  );
};

export default connect(state => ({
  languages: state.app.languages,
  customLangToCountry: state.appConfig.customLangToCountry
}))(I18nInputAdornmentSelect);
