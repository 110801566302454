import React, {useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import CustomDialogTitle from "../../custom-dialog-title";
import MaterialTable from "../../material-table";
import {localizeI18nObj} from "../../../utils/i18n";

const mapStateToProps = ({app, dataset}) => ({
  defaultLanguage: app.language,
  languages: app.languages
});

const IndicatorListDialog = ({defaultLanguage, languages, isOpen, onClose, indicators, onDelete}) => {
  const {t} = useTranslation();

  const [indicatorIdx, setIndicatorIdx] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete(indicatorIdx);
    setIndicatorIdx(null);
  };

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <CustomDialogTitle onClose={handleClose}>{t("components.indicatorDialogs.list.title")}</CustomDialogTitle>
      <DialogContent sx={{height: "480px"}}>
        <MaterialTable
          columns={[
            {
              field: "title",
              title: t("components.indicatorDialogs.list.columns.title"),
              render: ({title}) => localizeI18nObj(title, defaultLanguage, languages)
            },
            // {
            //   field: "formula",
            //   title: _t("components.indicatorDialogs.list.columns.formula"),
            //   render: ({formula}) => formula.join(""),
            //   customFilterAndSearch: (str, {formula}) => (formula.join("") || "").toLowerCase().includes((str || "").toLowerCase())
            // },
            {
              field: "year",
              title: t("components.indicatorDialogs.list.columns.year")
            }
          ]}
          data={(indicators || []).map((indicator, idx) => ({...indicator, idx: idx}))}
          actions={[
            {
              icon: <DeleteIcon />,
              tooltip: t("components.indicatorDialogs.list.actions.delete"),
              onClick: (_, {idx}) => {
                setIndicatorIdx(idx);
              }
            }
          ]}
        />
        <Dialog open={indicatorIdx !== null} onClose={() => setIndicatorIdx(null)} maxWidth="xs">
          <CustomDialogTitle onClose={() => setIndicatorIdx(null)}>
            {t("components.indicatorDialogs.list.dialogs.deleteIndicator.title")}
          </CustomDialogTitle>
          <DialogContent>{t("components.indicatorDialogs.list.dialogs.deleteIndicator.content")}</DialogContent>
          <DialogActions>
            <Button onClick={() => setIndicatorIdx(null)} autoFocus>
              {t("commons.confirm.cancel")}
            </Button>
            <Button onClick={handleDelete}>{t("commons.confirm.confirm")}</Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("commons.confirm.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(IndicatorListDialog);
