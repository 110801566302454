import React, {Fragment, useState} from "react";
import FolderIcon from "@mui/icons-material/Folder";
import {Box} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {compose} from "redux";
import {getDatasetsInternalUrl, goToDatasets} from "../../links";
import CardsGrid from "../cards-grid";
import CategoriesLists from "../categories-lists";
import CustomBreadcrumbs from "../custom-breadcrumbs";
import DetailLevelSelect from "../detail-level-select";
import Footer from "../footer";
import Header from "../header";
import Page from "../page";
import {getPageTitle} from "../../utils/other";

const mapStateToProps = state => ({
  baseURL: state.config.baseURL,
  filteredCatalog: state.detailLevel.filteredCatalog
});

const Categories = ({t, baseURL, filteredCatalog, hub, nodeCode, node, catalog, isDefault}) => {
  const location = useLocation();

  const [isListMode] = useState(
    hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase()).showCategoryLevels === 5
  );

  const [categorySchemeTab, setCategorySchemeTab] = useState(0);

  const nodeMinimalInfo = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());
  const nodeExtras = nodeMinimalInfo?.extras || [];
  const hideLabelOnCategoryWithImage = JSON.parse(
    nodeExtras.find(({key}) => key === "HideLabelOnCategoryWithImage")?.value || "[]"
  );

  const uncategorizedCategory = {
    id: "uncategorized",
    label: t("commons.catalog.uncategorized"),
    childrenCategories: [],
    datasetIdentifiers: filteredCatalog?.uncategorizedDatasets.map(({identifier}) => identifier)
  };

  const categories = filteredCatalog
    ? filteredCatalog.categoryGroups.length > 1
      ? categorySchemeTab === "uncategorized"
        ? [uncategorizedCategory]
        : filteredCatalog.categoryGroups[categorySchemeTab].categories.map(c => ({
            ...c,
            image: c.image ? baseURL + c.image : undefined
          }))
      : filteredCatalog.uncategorizedDatasets.length > 0
        ? filteredCatalog.categoryGroups[0]?.categories
            .map(c => ({
              ...c,
              image: c.image ? baseURL + c.image : undefined
            }))
            .concat([uncategorizedCategory]) || [uncategorizedCategory]
        : filteredCatalog.categoryGroups[0]?.categories.map(c => ({
            ...c,
            image: c.image ? baseURL + c.image : undefined
          })) || []
    : [];

  const categoriesWithImages = (categories || []).find(({image}) => !!image) !== undefined;

  return (
    (categories || []).length > 0 && (
      <Fragment>
        <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.main")}
        </HashLink>
        <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.footer")}
        </HashLink>
        <Page title={getPageTitle([t("scenes.categories.title"), nodeMinimalInfo?.name, hub?.hub?.name], t)}>
          <Header isDefault={isDefault} />
          <Box
            component="main"
            id="main"
            sx={{
              width: "100%",
              minHeight: "100%",
              backgroundColor: "#f5f5f5",
              display: "flex",
              flexDirection: "column"
            }}
            className={` categories`}
          >
            <Container>
              <CustomBreadcrumbs sx={{marginTop: "24px"}}>{[{label: t("scenes.categories.title")}]}</CustomBreadcrumbs>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "28px",
                  fontWeight: "300px",
                  letterSpacing: "0px",
                  paddingBottom: "8px",
                  color: theme => theme.palette.text.primary
                }}
              >
                {t("scenes.categories.title")}
              </Typography>
              {catalog.detailLevels.length > 0 && (
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    padding: "8px 4px"
                  }}
                >
                  <Grid item>
                    <Typography>{t("components.results.detailLevelSelectTitle")}</Typography>
                  </Grid>
                  <Grid item>
                    <DetailLevelSelect onChange={() => setCategorySchemeTab(0)} />
                  </Grid>
                </Grid>
              )}
              <Box
                component="nav"
                sx={{
                  paddingTop: "8px"
                }}
                aria-label={t("scenes.categories.ariaLabel")}
              >
                {filteredCatalog.hasCategorySchemes && (
                  <Tabs
                    value={categorySchemeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(_, tab) => setCategorySchemeTab(tab)}
                    sx={{
                      marginBottom: theme => theme.spacing(2)
                    }}
                  >
                    {filteredCatalog.categoryGroups.map(({id, label}, index) => (
                      <Tab id={id} key={index} value={index} label={label} tabIndex={0} />
                    ))}
                    {filteredCatalog.uncategorizedDatasets?.length > 0 && (
                      <Tab
                        key="uncategorized"
                        value="uncategorized"
                        label={t("commons.catalog.uncategorized")}
                        tabIndex={0}
                      />
                    )}
                  </Tabs>
                )}
                {isListMode ? (
                  <CategoriesLists
                    categories={categories}
                    onClick={categoryPath =>
                      goToDatasets(
                        node.code,
                        categoryPath[0] === "uncategorized"
                          ? ["uncategorized"]
                          : filteredCatalog.hasCategorySchemes
                            ? [filteredCatalog.categoryGroups[categorySchemeTab].id, ...categoryPath]
                            : categoryPath
                      )
                    }
                  />
                ) : (
                  <CardsGrid
                    list={categories}
                    getHref={category =>
                      getDatasetsInternalUrl(
                        node?.code,
                        category.id === "uncategorized"
                          ? ["uncategorized"]
                          : filteredCatalog.hasCategorySchemes
                            ? [filteredCatalog.categoryGroups[categorySchemeTab].id, category.id]
                            : [category.id]
                      )
                    }
                    imageStyle={{
                      maxHeight: undefined
                    }}
                    hideLabelOnElemWithImage={hideLabelOnCategoryWithImage}
                    icon={<FolderIcon />}
                    xs={categoriesWithImages ? 4 : 12}
                    sm={categoriesWithImages ? 4 : 12}
                    md={categoriesWithImages ? 3 : 6}
                    lg={categoriesWithImages ? 2 : 4}
                    xl={categoriesWithImages ? 2 : 4}
                  />
                )}
              </Box>
            </Container>
            <Footer />
          </Box>
        </Page>
      </Fragment>
    )
  );
};

export default compose(withTranslation(), connect(mapStateToProps))(Categories);
