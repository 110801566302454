import React, {Fragment} from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const PageSection = ({t, sectiontitle, rightActions, children, ...props}) => (
  <Box {...props}>
    {(sectiontitle || rightActions) && (
      <Fragment>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="h4"
              gutterBottom
              margin={4}
              sx={{fontSize: 34, color: theme => theme.palette.text.primary}}
            >
              {sectiontitle}
            </Typography>
          </Grid>
          <Grid item>{rightActions}</Grid>
        </Grid>
        <Divider />
      </Fragment>
    )}
    {children}
  </Box>
);

export default PageSection;
