import {createSelector} from "@reduxjs/toolkit";
import rootSelector from "../rootSelector";

export const appSelector = createSelector(rootSelector, state => state.app);
export const languageSelector = createSelector(appSelector, app => app.language);
export const languagesSelector = createSelector(appSelector, app => app.languages);
export const modulesConfigSelector = createSelector(appSelector, app => app.modulesConfig);

export const themeConfigSelector = createSelector(appSelector, app => app.themeConfig);
export const themeConfigShowLangsAsTextSelector = createSelector(themeConfigSelector, config => config.showLangsAsText);
