import {Fragment, useCallback, useState} from "react";
import styled from "@emotion/styled";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import {Box, Divider, Drawer, Grid, IconButton, Tooltip} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import {useTranslation} from "react-i18next";
import {getCustomPageInternalUrl, getNodeDashboardsInternalUrl, getNodeInternalUrl} from "../../links";
import {ConfigNodeMenuId} from "../../model/item-containers-models/configNodeMenuId";
import CategoriesTree from "../categories-tree";
import CustomLink from "../custom-link";
import DetailLevelSelect from "../detail-level-select";
import ModulesPlaceholder from "../modules-placeholder";
import {localizeI18nObj} from "../../utils/i18n";
import {getCustomPagesInMenu} from "../../utils/itemContainers";
import {showDashboardsButton} from "./utils";

const Accordion = styled(MuiAccordion)({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: 0
    }
  },
  expanded: {}
});

const AccordionSummary = styled(MuiAccordionSummary)({
  root: {
    fontSize: 16,
    minHeight: 48,
    margin: "0 4px",
    padding: "0 4px",
    "&$expanded": {
      minHeight: 48
    },
    "&:hover": {
      backgroundColor: "#f5f5f5"
    },
    "&:focus": {
      outline: "-webkit-focus-ring-color auto 1px",
      outlineOffset: -1
    },
    "&:focus:hover": {
      backgroundColor: "#f5f5f5"
    },
    "&:focus:not(:hover)": {
      backgroundColor: "#ffffff"
    }
  },
  content: {
    margin: 12,
    "&$expanded": {
      margin: 12
    }
  },
  expanded: {},
  expandIcon: {
    transition: "none",
    margin: 0
  }
});

const AccordionDetails = styled(MuiAccordionDetails)({
  root: {
    display: "block"
  }
});

const navigationDrawerLinkStyle = {
  margin: "1px 4px",
  "& div.custom-link--disabled": {
    padding: "4px 8px"
  },
  "& div.custom-link--enabled > a > div": {
    padding: "4px 8px"
  }
};

const isCategoriesExpandedDefault = true;
const isDetailLevelsExpandedDefault = true;

const NavigationDrawerButton = ({
  selectedCategoryPath,
  selectedDataset,
  title,
  nodes,
  noNode,
  catalog,
  node,
  isA11y,
  filteredCatalog,
  defaultLanguage,
  languages,
  modulesConfig,
  DrawerIcon,
  drawerButtonProps
}) => {
  const {t} = useTranslation();

  const [isNavigationDrawerOpen, setisNavigationDrawerOpen] = useState(false);
  const [isCategoriesExpanded, setisCategoriesExpanded] = useState(isCategoriesExpandedDefault);
  const [isDetailLevelsExpanded, setisDetailLevelsExpanded] = useState(isDetailLevelsExpandedDefault);

  const onNavigationDrawerOpen = useCallback(() => {
    setisNavigationDrawerOpen(true);
    setisCategoriesExpanded(isCategoriesExpandedDefault);
    setisDetailLevelsExpanded(isDetailLevelsExpandedDefault);
  }, []);

  const onNavigationDrawerClose = useCallback(() => {
    setisNavigationDrawerOpen(false);
    setisCategoriesExpanded(isCategoriesExpandedDefault);
    setisDetailLevelsExpanded(isDetailLevelsExpandedDefault);
  }, []);

  const handleCategoriesExpand = useCallback(() => {
    setisCategoriesExpanded(prevVal => !prevVal);
  }, []);

  const handleDetailLevelsExpand = useCallback(() => {
    setisDetailLevelsExpanded(prevVal => !prevVal);
  }, []);

  const nodeSidenavCustomPages = getCustomPagesInMenu(node?.itemContainers?.customPages, ConfigNodeMenuId.SIDENAV);

  return (
    <Fragment>
      <Grid item className="navigation-drawer-btn">
        <Tooltip title={t("components.header.actions.openNavigationDrawer.title")}>
          <IconButton
            color="inherit"
            onClick={onNavigationDrawerOpen}
            aria-label={t("components.header.actions.openNavigationDrawer.ariaLabel")}
            {...drawerButtonProps}
          >
            {DrawerIcon ? <DrawerIcon /> : <MenuIcon />}
          </IconButton>
        </Tooltip>
      </Grid>
      <Drawer
        anchor="left"
        open={isNavigationDrawerOpen}
        PaperProps={{
          sx: {
            width: "520px",
            maxWidth: "100%"
          }
        }}
        onClose={onNavigationDrawerClose}
      >
        <nav id="navigation-drawer" aria-label={t("components.header.navigationDrawer.ariaLabel")}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 16px"
            }}
          >
            <Box
              component="span"
              sx={{
                fontSize: "20px",
                fontWeight: "500px",
                padding: "16px 0"
              }}
            >
              {title || node?.code}
            </Box>
            <Tooltip title={t("components.header.actions.closeNavigationDrawer.title")}>
              <IconButton
                onClick={onNavigationDrawerClose}
                aria-label={t("components.header.actions.closeNavigationDrawer.ariaLabel")}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider sx={{margin: "0 8px 8px"}} />
          {node &&
            (nodes || []).length > 1 &&
            window.location.hash.toLowerCase().includes(node.code.toLowerCase()) &&
            window.location.hash
              .toLowerCase()
              .split("/")
              .filter(str => (str || "").length > 0)
              .slice(-1)[0] !== node.code.toLowerCase() && (
              <Box sx={navigationDrawerLinkStyle}>
                <CustomLink
                  to={getNodeInternalUrl(node?.code)}
                  text={t("components.header.navigationDrawer.backToNodeHome")}
                  icon={<HomeIcon />}
                />
              </Box>
            )}
          {showDashboardsButton(modulesConfig, isA11y, noNode, node) && (
            <Box sx={navigationDrawerLinkStyle}>
              <CustomLink
                to={getNodeDashboardsInternalUrl(node?.code)}
                text={t("components.header.navigationDrawer.goToDashboard")}
                icon={<DashboardIcon />}
              />
            </Box>
          )}
          <ModulesPlaceholder
            id="navigation-drawer-placeholder"
            sx={navigationDrawerLinkStyle}
            moduleFallback={<Box sx={navigationDrawerLinkStyle} style={{height: 48}} />}
          />
          {nodeSidenavCustomPages?.length > 0 &&
            nodeSidenavCustomPages?.map((v, idx) => (
              <Box key={idx} paddingLeft="12px">
                <CustomLink
                  key={v.id}
                  to={getCustomPageInternalUrl(v.id, node?.code)}
                  text={localizeI18nObj(v.title, defaultLanguage, languages) || ""}
                />
              </Box>
            ))}
          {catalog && (
            <Fragment>
              {catalog.detailLevels.length > 0 && (
                <Accordion expanded={isDetailLevelsExpanded} onChange={handleDetailLevelsExpand}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {t("components.header.navigationDrawer.detailLevelSelectTitle")}
                  </AccordionSummary>
                  <AccordionDetails>
                    <DetailLevelSelect variant="outlined" />
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion expanded={isCategoriesExpanded} onChange={handleCategoriesExpand}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t("components.header.navigationDrawer.categoriesTitle")}
                </AccordionSummary>
                <AccordionDetails>
                  <CategoriesTree
                    selectedCategoryPath={selectedCategoryPath}
                    selectedDataset={selectedDataset}
                    catalog={filteredCatalog}
                    node={node}
                    onClose={onNavigationDrawerClose}
                  />
                </AccordionDetails>
              </Accordion>
            </Fragment>
          )}
        </nav>
      </Drawer>
    </Fragment>
  );
};

export default NavigationDrawerButton;
