const themeOverrides = {
  components: {
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          padding: "6px 12px 8px"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          lineHeight: "1.75",
          minWidth: "160px"
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard"
      },
      styleOverrides: {
        root: {
          "& .MuiTextField-root": {
            marginTop: "8px"
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          "& .MuiCheckbox-root": {
            marginRight: "4px"
          },
          "& .MuiCheckbox-root + .MuiStack-root > .MuiFormControlLabel-asterisk": {
            display: "none"
          }
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    }
  }
};

export const DEFAULT_THEME = {
  palette: {
    text: {
      primary: "#00295a"
    },
    primary: {
      main: "#00295a",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#f8a81e",
      contrastText: "#00295a"
    }
  },
  ...themeOverrides
};

export const DEFAULT_ALL1_THEME = {
  palette: {
    text: {
      primary: "#000000"
    },
    primary: {
      main: "#000000",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#000000",
      contrastText: "#ffffff"
    }
  },
  ...themeOverrides
};
