import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../../label-format-selector/constants";
import {getDimensionLabelFromCombinations, getDimensionValueLabelFromCombinations} from "../../../utils/dataset";
import {getFormattedValue} from "../../../utils/formatters";

const $ = window.jQuery;

export const PAGE_COL_COUNT = 30;
export const PAGE_ROW_COUNT = 50;

const filterIcon =
  "<svg " +
  'viewBox="0 0 24 24" ' +
  'height="20px" ' +
  'width="20px" ' +
  'fill="currentColor" ' +
  'style="transform: scale(0.9);" ' +
  ">" +
  "<g>" +
  '<path d="M0,0h24 M24,24H0" fill="none"/>' +
  '<path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/>' +
  '<path d="M0,0h24v24H0V0z" fill="none"/>' +
  "</g>" +
  "</svg>";

const settingsIcon =
  "<svg " +
  'viewBox="0 0 24 24" ' +
  'height="20px" ' +
  'width="20px" ' +
  'fill="currentColor" ' +
  'style="transform: scale(0.9);" ' +
  ">" +
  "<g>" +
  '<path d="M0,0h24v24H0V0z" fill="none"/>' +
  '<path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/>' +
  "</g>" +
  "</svg>";

const closeIcon =
  "<svg " +
  'viewBox="0 0 24 24" ' +
  'height="20px" ' +
  'width="20px" ' +
  'fill="currentColor" ' +
  ">" +
  '<path d="M0 0h24v24H0z" fill="none"/>' +
  '<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>' +
  "</svg>";

const TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT = 2;

const generateTableHeadRightPaddingHtml = () => `<th class="c c-rb"></th>`;

const generateTableHeadHtml = (
  uuid,
  dimensionMap,
  dimensionLabels,
  territoryDim,
  timeDim,
  layout,
  cols,
  data,
  filters,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  synthesisAndVariabilityMeasures,
  showArithmeticMean,
  showStandardDeviation,
  showCoefficientOfVariation,
  territorialClassificationsConfig,
  hiddenDimensionValueLabels,
  t
) => {
  const hiddenLabels = (hiddenDimensionValueLabels || []).map(label => label.toLowerCase());

  const getTextWidthEl = window
    .jQuery("<span/>")
    .css({visibility: "hidden"})
    .appendTo(`#paginated-table__${uuid}`)
    .get(0);

  let thead = `<thead class="table-head">`;

  thead += '<tr id="h-0">';

  /** territory label **/

  const territoryDimLabel = getDimensionLabelFromCombinations(territoryDim, dimensionLabels);

  $(getTextWidthEl).addClass("c ch");
  $(`<span>${territoryDimLabel}<span/>`).appendTo(getTextWidthEl);
  const territoryDimLabelWidth = $(getTextWidthEl).innerWidth();
  $(getTextWidthEl).removeClass().empty();

  thead +=
    `<th ` +
    `class="c ch cl0" ` +
    `rowspan="2" ` +
    `colspan="2" ` +
    `style="min-width: ${territoryDimLabelWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 24 + "px"}" ` +
    `>` +
    territoryDimLabel +
    `<div class="table-icon terr-class-config">${settingsIcon}</div>` +
    `</th>`;

  /** territorial classifications **/

  territorialClassificationsConfig.forEach(({label, format}) => {
    $(getTextWidthEl).addClass("c csh");
    $(`<span>${label}<span/>`).appendTo(getTextWidthEl);
    const labelWidth = $(getTextWidthEl).innerWidth();
    $(getTextWidthEl).removeClass().empty();

    thead +=
      `<th ` +
      `class="c csh cl0" ` +
      `rowspan="2" ` +
      `colspan="${format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH ? 2 : 1}" ` +
      `style="min-width: ${labelWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 24 + "px"}" ` +
      `>` +
      label +
      `</th>`;
  });

  /** time dim values **/

  const timeValues = [...new Set(cols.map(col => col[timeDim]))];
  const timeValueSpans = cols.reduce(
    (acc, col) => ({
      ...acc,
      [col[timeDim]]: (acc[col[timeDim]] ?? 0) + 1
    }),
    {}
  );

  timeValues.forEach(timeVal => {
    const timeValLabel = getDimensionValueLabelFromCombinations(timeDim, timeVal, dimensionMap);

    $(getTextWidthEl).addClass("c csh");
    $(`<span>${timeValLabel}<span/>`).appendTo(getTextWidthEl);
    const timeValLabelWidth = $(getTextWidthEl).innerWidth();
    $(getTextWidthEl).removeClass().empty();

    const attr =
      (data?.dimensionsAttributes?.[timeDim]?.[timeVal] ?? []).length > 0
        ? `<span id="${timeDim}:${timeVal}" class="ct ctsh">(*)</span>`
        : "";

    thead +=
      `<th ` +
      `class="c csh ${attr.length > 0 ? "ca" : ""}" ` +
      `colspan="${timeValueSpans[timeVal]}" ` +
      `style="min-width: ${timeValLabelWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 24 + "px"}" ` +
      `>` +
      timeValLabel +
      attr +
      `</th>`;
  });

  thead += generateTableHeadRightPaddingHtml();
  thead += "</tr>";

  thead += '<tr id="h-1">';

  /** merged dim values **/

  const mergedDims = layout.cols.filter(col => col !== timeDim);

  cols.forEach((col, colIdx) => {
    let cellText = "";
    let cellWithAttr = false;
    mergedDims.forEach(dim => {
      const dimWithAttr = (data?.dimensionsAttributes?.[dim]?.[col[dim]] ?? []).length > 0;
      const valLabel = getDimensionValueLabelFromCombinations(dim, col[dim], dimensionMap);

      if (!hiddenLabels.includes(valLabel.toLowerCase()) || dimWithAttr) {
        cellText += valLabel;
        if (dimWithAttr) {
          cellText += `<span id="${dim}:${col[dim]}" class="ct ctsh">(*)</span>`;
          cellWithAttr = true;
        }
        cellText += "<br/>";
      }
    });

    thead += `<th id="c-${colIdx}-dims-${mergedDims.join(",")}" class="c csh ${cellWithAttr ? "ca" : ""}" >`;
    thead += cellText;
    thead += "</th>";
  });

  thead += generateTableHeadRightPaddingHtml();
  thead += "</tr>";

  /** icons row **/

  thead += `<tr id="hh">`;

  thead += `<th class="c ch ch-icons ch-icons-terr-id">`;
  thead += "&nbsp;"; // needed to handle cell height
  thead += `<div class="table-icons">`;
  thead +=
    `<div class="table-icon filter-dim-id ${filters?.idTextFilter ? "table-icon--selected" : ""}" >` +
    filterIcon +
    `</div>`;
  thead += "</div>";
  thead += "</th>";

  thead += `<th class="c ch ch-icons ch-icons-terr-label">`;
  thead += "&nbsp;"; // needed to handle cell height
  thead += `<div class="table-icons">`;
  thead +=
    `<div class="table-icon filter-dim-label ${filters?.labelTextFilter ? "table-icon--selected" : ""}">` +
    filterIcon +
    `</div>`;
  thead += "</div>";
  thead += "</th>";

  territorialClassificationsConfig.forEach(({id, format}) => {
    thead +=
      `<th ` +
      `class="c csh csh-icons ch-icons-terr-class-values" ` +
      `colspan="${format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH ? 2 : 1}" ` +
      `>`;
    thead += `<div class="table-icons">`;
    thead += `<div id="c-level-${id}" class="table-icon terr-class-level-remove">` + closeIcon + `</div>`;
    thead += "</div>";
    thead += "</th>";
  });

  cols.forEach((_, colIdx) => {
    thead += `<th class="c csh csh-icons ${colIdx === 0 ? "csh-icons--first" : ""}" >`;
    thead += "&nbsp;"; // needed to handle cell height

    thead += `<div class="table-icons">`;
    thead +=
      `<div ` +
      `id="c-${colIdx}" ` +
      `class="table-icon col-filter-obs ${filters?.observationFilters?.[colIdx] ? "table-icon--selected" : ""}" ` +
      `>` +
      filterIcon +
      `</div>`;
    thead += "</div>";
    thead += "</th>";
  });

  thead += generateTableHeadRightPaddingHtml();
  thead += "</tr>";

  $(getTextWidthEl).remove();

  const terrClassColSpan = territorialClassificationsConfig.reduce(
    (acc, {format}) => acc + (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH ? 2 : 1),
    0
  );

  if (synthesisAndVariabilityMeasures && showArithmeticMean) {
    thead += `<tr id="arithmetic-mean" class="indicators">`;
    thead +=
      `<th class="c ci-h" colspan="2">` +
      t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.label") +
      ":" +
      `</th>`;
    thead += terrClassColSpan > 0 ? `<th class="c ci-v" colspan=${terrClassColSpan}></th>` : "";
    cols.forEach(col => {
      const colKey = layout.cols.map(dim => col[dim]).join("+");
      thead +=
        `<th class="c ci-v" > ` +
        getFormattedValue(
          synthesisAndVariabilityMeasures?.[colKey]?.avg ?? "",
          decimalSeparator,
          decimalPlaces,
          "",
          roundingStrategy
        ) +
        `</th>`;
    });
    thead += generateTableBodyRightPaddingHtml();
    thead += "</tr>";
  }

  if (synthesisAndVariabilityMeasures && showStandardDeviation) {
    thead += `<tr id="standard-deviation" class="indicators">`;
    thead +=
      `<th class="c ci-h" colspan="2">` +
      t("commons.measuresOfSynthesisAndVariability.values.standardDeviation.label") +
      ":" +
      `</th>`;
    thead += terrClassColSpan > 0 ? `<th class="c ci-v" colspan=${terrClassColSpan}></th>` : "";
    cols.forEach(col => {
      const colKey = layout.cols.map(dim => col[dim]).join("+");
      thead +=
        `<th class="c ci-v" > ` +
        getFormattedValue(
          synthesisAndVariabilityMeasures?.[colKey]?.stdDev ?? "",
          decimalSeparator,
          decimalPlaces,
          "",
          roundingStrategy
        ) +
        `</th>`;
    });
    thead += generateTableBodyRightPaddingHtml();
    thead += "</tr>";
  }

  if (synthesisAndVariabilityMeasures && showCoefficientOfVariation) {
    thead += `<tr id="coefficient-of-variation" class="indicators">`;
    thead +=
      `<th class="c ci-h" colspan="2">` +
      t("commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation.label") +
      ":" +
      `</th>`;
    thead += terrClassColSpan > 0 ? `<th class="c ci-v" colspan=${terrClassColSpan}></th>` : "";
    cols.forEach(col => {
      const colKey = layout.cols.map(dim => col[dim]).join("+");
      thead +=
        `<th class="c ci-v" > ` +
        getFormattedValue(
          synthesisAndVariabilityMeasures?.[colKey]?.coeffVar ?? "",
          decimalSeparator,
          decimalPlaces,
          "",
          roundingStrategy
        ) +
        `</th>`;
    });
    thead += generateTableBodyRightPaddingHtml();
    thead += "</tr>";
  }

  thead += "</thead>";

  return thead;
};

const generateTableBodyRightPaddingHtml = () => `<td class="c c-rb"></td>`;

const generateTableBodyHtml = (
  dimensions,
  dimensionMap,
  territoryDim,
  detailLevelSuffix,
  rows,
  cols,
  data,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  emptyChar,
  selectedTerritory,
  territorialClassificationsConfig,
  territorialClassificationsValues
) => {
  let tbody = "<tbody>";

  rows.forEach((row, rowIdx) => {
    let trow =
      `<tr ` +
      `id="r-${row[territoryDim]}" ` +
      `class="body__row ${row[territoryDim] === selectedTerritory ? "body__row--selected" : ""}" ` +
      `>`;

    const val = row[territoryDim];
    const valLabel = getDimensionValueLabelFromCombinations(territoryDim, val, dimensionMap);

    const attr =
      (data?.dimensionsAttributes?.[territoryDim]?.[val] ?? []).length > 0
        ? `<span id="${territoryDim}:${val}" class="ct ctsh">(*)</span>`
        : "";

    trow += `<th class="c csh" style="white-space: nowrap;">${val.replace(detailLevelSuffix ?? "", "")}</th>`;
    trow += `<th class="c csh ${attr.length > 0 ? "ca" : ""}">${valLabel}${attr}</th>`;

    territorialClassificationsConfig.forEach(({id, format}) => {
      const idCell = `<td class="c">${territorialClassificationsValues?.[val]?.[id]?.id ?? ""}</td>`;
      const nameCell = `<td class="c">${territorialClassificationsValues?.[val]?.[id]?.label ?? ""}</td>`;

      if (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME) {
        trow += nameCell;
      } else if (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID) {
        trow += idCell;
      } else if (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH) {
        trow += idCell;
        trow += nameCell;
      }
    });

    cols.forEach((col, colIdx) => {
      const cell = {...col, ...row};
      const valueKey = dimensions.map(dim => cell[dim]).join("+");
      const value = data?.values?.[valueKey]?.value ?? null;

      const attr =
        (data?.values?.[valueKey]?.attributes ?? []).length > 0
          ? `<span id="${valueKey}" class="ct ctd">(*)</span>`
          : "";

      trow +=
        `<td ` +
        `id="r-${rowIdx}-c-${colIdx}" ` +
        `class="c ${attr.length > 0 ? "ca" : ""}" ` +
        `>` +
        attr +
        getFormattedValue(value, decimalSeparator, decimalPlaces, emptyChar, roundingStrategy) +
        `</td>`;
    });

    trow += generateTableBodyRightPaddingHtml();
    trow += "</tr>";

    tbody += trow;
  });

  const terrClassColSpan = territorialClassificationsConfig.reduce(
    (acc, {format}) => acc + (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH ? 2 : 1),
    0
  );

  tbody += `<tr><td class="c c-bb" colspan="${2 + terrClassColSpan + cols.length}"></td></tr>`;

  tbody += "</tbody>";

  return tbody;
};

export const generateTableHtml = (
  uuid,
  dimensions,
  dimensionMap,
  dimensionLabels,
  territoryDim,
  timeDim,
  layout,
  allRows,
  allCols,
  rowIdx,
  colIdx,
  pageRowCount,
  pageColCount,
  data,
  filters,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  emptyChar,
  detailLevelSuffix,
  selectedTerritory,
  synthesisAndVariabilityMeasures,
  showArithmeticMean,
  showStandardDeviation,
  showCoefficientOfVariation,
  territorialClassificationsConfig,
  territorialClassificationsValues,
  hiddenDimensionValueLabels,
  t,
  onPageGenerated
) => {
  if (!data) {
    return "";
  }

  const t0 = performance.now();

  const cols = allCols.slice(colIdx, colIdx + pageColCount);
  const rows = allRows.slice(rowIdx, rowIdx + pageRowCount);

  let table = `<table id="${uuid}">`;

  table += generateTableHeadHtml(
    uuid,
    dimensionMap,
    dimensionLabels,
    territoryDim,
    timeDim,
    layout,
    cols,
    data,
    filters,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    synthesisAndVariabilityMeasures,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    territorialClassificationsConfig,
    hiddenDimensionValueLabels,
    t
  );

  table += generateTableBodyHtml(
    dimensions,
    dimensionMap,
    territoryDim,
    detailLevelSuffix,
    rows,
    cols,
    data,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    emptyChar,
    selectedTerritory,
    territorialClassificationsConfig,
    territorialClassificationsValues
  );

  table += "</table>";

  table += '<div id="paginated-table__tooltip__attribute" class="ctt"></div>';
  table += '<div id="paginated-table__tooltip__merged-header" class="ctt"></div>';

  const t1 = performance.now();

  if (onPageGenerated) {
    onPageGenerated({
      rowIdx: rowIdx,
      rows: rows,
      colIdx: colIdx,
      cols: cols,
      timings: t1 - t0
    });
  }

  return table;
};
