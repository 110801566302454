import moment from "moment";
import {
  FREQ_ANNUAL,
  FREQ_DAILY,
  FREQ_MONTHLY,
  FREQ_QUARTERLY,
  FREQ_SEMESTER,
  getFreqValueFromTimeValue,
  getPeriodsFromYear,
  getTimeValuesByFreq,
  getTimeValuesFromRange,
  SUPPORTED_FREQ_VALUES
} from "./timePeriodAndFreq";

export const CRITERIA_FILTER_TYPE_CODES = "CodeValues";
export const CRITERIA_FILTER_TYPE_STRING = "StringValues";
export const CRITERIA_FILTER_TYPE_RANGE = "TimeRange";
export const CRITERIA_FILTER_TYPE_PERIODS = "TimePeriod";
export const criteriaFilterTypes = [
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_STRING,
  CRITERIA_FILTER_TYPE_RANGE,
  CRITERIA_FILTER_TYPE_PERIODS
];

export const CRITERIA_FILTER_TYPE_EMPTY = "EmptyValue";

export const getCriteriaArrayFromObject = criteriaObj => {
  const criteriaArr = [];
  Object.keys(criteriaObj).forEach(key => {
    if (criteriaObj[key] !== null && criteriaObj[key] !== undefined) {
      criteriaArr.push({
        ...criteriaObj[key],
        filterValues: (criteriaObj[key]?.filterValues || []).sort()
      });
    }
  });

  return criteriaArr.sort();
};

export const getCriteriaObjectFromArray = criteriaArr => {
  let criteriaObj = {};
  (criteriaArr || []).forEach(dimensionCriteria => {
    criteriaObj = {
      ...criteriaObj,
      [dimensionCriteria.id]: dimensionCriteria
    };
  });

  return criteriaObj;
};

export const getFilteredCriteriaFromLayout = (criteria, dimensions, layout, timeDim) => {
  if (!criteria) {
    return null;
  } else if (!dimensions || !layout) {
    return criteria;
  }

  const newCriteria = {};

  const {
    filters = [],
    filtersValue = {},
    primaryDim = [],
    primaryDimValues = [],
    secondaryDim = [],
    secondaryDimValues = []
  } = layout;

  dimensions
    .map(({id}) => id)
    .forEach(dim => {
      if (!timeDim || dim !== timeDim) {
        if (filters.includes(dim)) {
          newCriteria[dim] = {
            id: dim,
            filterValues: [filtersValue[dim]],
            hierarchyId: criteria[dim]?.hierarchyId
          };
        } else if (primaryDim.includes(dim)) {
          newCriteria[dim] = {
            id: dim,
            filterValues: primaryDimValues,
            hierarchyId: criteria[dim]?.hierarchyId
          };
        } else if (secondaryDim.includes(dim)) {
          newCriteria[dim] = {
            id: dim,
            filterValues: secondaryDimValues,
            hierarchyId: criteria[dim]?.hierarchyId
          };
        } else if ((criteria[dim]?.filterValues || []).length > 0 || (criteria[dim]?.hierarchyId || "") !== "") {
          newCriteria[dim] = criteria[dim];
        }
      } else {
        const getRangeFromTimeValues = values => {
          const getStartAndEnd = timeValue => {
            const freqValue = getFreqValueFromTimeValue(timeValue);

            let start, end;
            if (!timeValue) {
              start = null;
              end = null;
            } else if (freqValue === FREQ_ANNUAL) {
              start = timeValue + "-01-01";
              end = timeValue + "-12-31";
            } else if (freqValue === FREQ_SEMESTER) {
              const year = timeValue.split("-")?.[0];
              const s = timeValue.split("-")?.[1];
              start = getPeriodsFromYear(year)[FREQ_SEMESTER][s].start;
              end = getPeriodsFromYear(year)[FREQ_SEMESTER][s].end;
            } else if (freqValue === FREQ_QUARTERLY) {
              const year = timeValue.split("-")?.[0];
              const q = timeValue.split("-")?.[1];
              start = getPeriodsFromYear(year)[FREQ_QUARTERLY][q].start;
              end = getPeriodsFromYear(year)[FREQ_QUARTERLY][q].end;
            } else if (freqValue === FREQ_MONTHLY) {
              const year = timeValue.split("-")?.[0];
              const m = timeValue.split("-")?.[1];
              start = getPeriodsFromYear(year)[FREQ_MONTHLY][m].start;
              end = getPeriodsFromYear(year)[FREQ_MONTHLY][m].end;
            } else if (freqValue === FREQ_DAILY) {
              start = timeValue;
              end = timeValue;
            } else {
              start = timeValue;
              end = timeValue;
            }

            return {
              start: start,
              end: end
            };
          };

          const sortedValues = [...values].sort((a, b) => moment(a).diff(b));
          const from = getStartAndEnd(sortedValues[0]).start;
          const to = getStartAndEnd(sortedValues[sortedValues.length - 1]).end;

          return {
            id: timeDim,
            type: CRITERIA_FILTER_TYPE_RANGE,
            from: from,
            to: to
          };
        };

        if (filters.includes(dim)) {
          newCriteria[dim] = getRangeFromTimeValues([filtersValue[dim]]);
        } else if (primaryDim.includes(dim)) {
          newCriteria[dim] = getRangeFromTimeValues(primaryDimValues);
        } else if (secondaryDim.includes(dim)) {
          newCriteria[dim] = getRangeFromTimeValues(secondaryDimValues);
        } else {
          newCriteria[dim] = criteria[dim];
        }
      }
    });

  return newCriteria;
};

export const getFilteredCodelistsListFromCriteria = (codelistLists, criteria, timeDim, freqDim) => {
  if (codelistLists === null) {
    return {};
  }

  if (Object.keys(criteria).length === 0) {
    return codelistLists;
  }

  const dimensions = Object.keys(codelistLists);
  const filteredCodelistsList = {};

  dimensions.forEach(dim => {
    if (dim !== timeDim) {
      if ((criteria[dim]?.filterValues || []).length > 0) {
        filteredCodelistsList[dim] = criteria[dim].filterValues;
      } else {
        filteredCodelistsList[dim] = codelistLists[dim];
      }
    } else {
      const freqs = freqDim && filteredCodelistsList[freqDim] ? filteredCodelistsList[freqDim] : [FREQ_ANNUAL];
      const freq = SUPPORTED_FREQ_VALUES.find(freq => freqs.includes(freq)) || FREQ_ANNUAL;

      if (criteria[timeDim]?.from && criteria[timeDim]?.to) {
        filteredCodelistsList[timeDim] = getTimeValuesFromRange(
          freqs,
          moment(criteria[timeDim].from),
          moment(criteria[timeDim].to)
        );
      } else if (criteria[timeDim]?.period) {
        const timeDimValuesByFreq = getTimeValuesByFreq(codelistLists[timeDim], freq);
        const timeDimValuesByFreqFiltered = timeDimValuesByFreq.slice(criteria[timeDim].period * -1);
        const from = timeDimValuesByFreqFiltered[0];
        const to = timeDimValuesByFreqFiltered[timeDimValuesByFreqFiltered.length - 1];
        filteredCodelistsList[timeDim] = getTimeValuesFromRange(freqs, from, to);
      } else {
        filteredCodelistsList[timeDim] = codelistLists[timeDim];
      }
    }
  });

  return filteredCodelistsList;
};
