import React, {useEffect} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useLocation} from "react-router-dom";
import CustomEmpty from "../custom-empty";

const ExternalLogin = () => {
  const location = useLocation();

  useEffect(() => {
    const loginCode = new URLSearchParams(location.search).get("code");
    if (loginCode) {
      localStorage.setItem("loginCode", loginCode);
    }

    let new_window = window.open(window.location.href, "_self");
    new_window.close();
  }, [location.search]);

  return (
    <CustomEmpty text="Loading..." image={<CircularProgress style={{color: "white"}} />} style={{color: "white"}} />
  );
};

export default ExternalLogin;
