import React from "react";
import {Box, useTheme} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import ComponentVariantLoader from "../component-variant-loader";

const mapStateToProps = state => ({
  baseURL: state.config.baseURL
});

const AppInfo = ({t, baseURL, hub}) => {
  const theme = useTheme();
  const styles = {
    root: {
      width: "480px"
    },
    logoContainer: {
      height: "64px",
      background: theme.palette.primary.main,
      padding: "16px",
      marginBottom: "16px"
    },
    logo: {
      height: "32px",
      display: "block",
      margin: "auto"
    }
  };

  return (
    <Box sx={styles.root}>
      {hub.headerLogoURL && (
        <Box sx={styles.logoContainer}>
          <Box component="img" src={baseURL + hub.headerLogoURL} alt="" sx={styles.logo} />
        </Box>
      )}
      <ComponentVariantLoader componentId="app-info" fallback={t("components.appInfo.loading") + "..."} hub={hub} />
    </Box>
  );
};

export default compose(withTranslation(), connect(mapStateToProps))(AppInfo);
