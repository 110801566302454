import {Grid} from "@mui/material";
import UserSelect from "../user-select";

const UserSettingsButton = ({nodes, disabled}) => (
  <Grid item className="user-settings-btn">
    <UserSelect nodes={nodes} disabled={disabled} />
  </Grid>
);

export default UserSettingsButton;
