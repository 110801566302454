import React, {useState} from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  Tooltip
} from "@mui/material";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../../../custom-dialog-title";
import I18nTextField from "../../../i18n-text-field";
import {Title} from "./ImportFormView";

export interface TitleChangeDialogProps {
  importData: Title[];
  open: boolean;
  title: string;
  onClose: () => void;
  onApply: (d: Title[]) => void;
}

const TitleChangeDialog = ({importData = [], open, title, onClose, onApply}: TitleChangeDialogProps) => {
  const {t} = useTranslation();
  const [titles, setTitles] = useState<Title[]>(importData);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <CustomDialogTitle onClose={onClose}>
        {t("components.nodeImportForm.titleChangeDialog.title", {
          obj: title === "DashboardTittle" ? "dashboard" : "view"
        })}
      </CustomDialogTitle>
      <DialogContent style={{height: "550px"}}>
        <DialogContentText>{t("components.nodeImportForm.titleChangeDialog.header")}</DialogContentText>
        <Grid container direction="row" alignItems="center">
          <Grid style={{width: "95%"}}>
            {importData.map((obj, index: number) => (
              <Box
                key={obj.id}
                display={titles[index].show ? "flex" : "none"}
                alignItems={"center"}
                sx={{marginBottom: theme => theme.spacing(2)}}
              >
                <FormControl fullWidth>
                  <I18nTextField
                    defaultLanguage={obj.tittle?.en && obj.tittle?.it ? undefined : obj.tittle?.en ? "en" : "it"}
                    fullWidth
                    label={t("components.itemContainerBuilder.title")}
                    value={titles[index].tittle}
                    variant="outlined"
                    required
                    onChange={(value: {[key: string]: string}) =>
                      setTitles(prevTitles => prevTitles.map((v, i) => (i === index ? {...v, tittle: value} : v)))
                    }
                  />
                </FormControl>
                <Tooltip
                  title={
                    obj.tittle?.en && obj.tittle?.it
                      ? t("components.nodeImportForm.titleChangeDialog.tooltip.both")
                      : obj.tittle?.en
                      ? t("components.nodeImportForm.titleChangeDialog.tooltip.en")
                      : t("components.nodeImportForm.titleChangeDialog.tooltip.it")
                  }
                >
                  <InfoIcon fontSize="small" style={{margin: "0 0 -4px 8px"}} />
                </Tooltip>
              </Box>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} type="button">
          {t("commons.confirm.close")}
        </Button>
        <Button
          onClick={() => {
            onClose();
            onApply(titles);
          }}
        >
          {t("commons.confirm.apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TitleChangeDialog;
