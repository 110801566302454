import {DatasetData} from "../../model/IDatasetData";
import {ItemContainerType} from "../../model/item-containers-models/itemContainerType";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import useApi from "../../state/api/useApi";
import {fetchItemContainerViewDataset} from "../../state/itemContainer/itemContainerActions";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {itemContainerTypeToServiceNameMap} from "../../utils/itemContainers";

export const useViewDataset = (
  itemViewTemplate: ItemViewTemplateDto,
  itemContainerType: ItemContainerType,
  widgetId: string,
  configs: any
) => {
  const coreDataRetrieverUrl =
    (configs[itemContainerTypeToServiceNameMap[itemContainerType]]?.coreDataRetrieverUrl ?? "").length > 0
      ? configs[itemContainerTypeToServiceNameMap[itemContainerType]].coreDataRetrieverUrl
      : undefined;

  return useApi<DatasetData>(
    coreDataRetrieverUrl
      ? fetchItemContainerViewDataset(itemViewTemplate, widgetId, itemContainerType, coreDataRetrieverUrl)
      : external(
          fetchItemContainerViewDataset(itemViewTemplate, widgetId, itemContainerType),
          itemContainerTypeToServiceNameMap[itemContainerType]
        ),
    {
      cacheAction: true,
      getCacheKey: req => req.url + req.data.map(criteria => criteria.id + criteria.filterValues.join(",")).join("/")
    }
  );
};
