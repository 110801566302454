import {Reducer} from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import {CHART_TYPE_BAR} from "../../../components/chart/constants";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../../../components/label-format-selector/constants";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC} from "../../../components/temporal-dim-order-selector/constants";
import {ICategoryProvider} from "../../../model/ICategoryProvider";
import {IDataset} from "../../../model/IDataset";
import {LocalCategoryProvider} from "../../../model/LocalCategoryProvider";
import {
  Criteria,
  CRITERIA_SELECTION_TYPE_PARTIAL,
  GENERATING_HTML_TIME_KEY,
  OBSERVATION_COUNT_KEY,
  SERVER_TIMINGS_KEY,
  ViewerMode
} from "../constants";
import {
  DATASET_MV_ADDITIONAL_DATASET_CATALOG_FETCH,
  DATASET_MV_ADDITIONAL_DATASET_CHANGE,
  DATASET_MV_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET,
  DATASET_MV_ADDITIONAL_DATASET_CRITERIA_SET,
  DATASET_MV_ADDITIONAL_DATASET_DELETE,
  DATASET_MV_ADDITIONAL_DATASET_LIST_VISIBILITY_SET,
  DATASET_MV_ADDITIONAL_DATASET_STRUCTURE_FETCH,
  DATASET_MV_ADDITIONAL_DATASET_SUBMIT,
  DATASET_MV_CHART_LAYOUT_SUBMIT,
  DATASET_MV_CHART_LAYOUT_TERRITORY_DIM_VALUES_SET,
  DATASET_MV_CHART_SETTINGS_SET,
  DATASET_MV_CRITERIA_ALERT_HIDE,
  DATASET_MV_CRITERIA_HIDE,
  DATASET_MV_CRITERIA_OBS_COUNT_WARNING_HIDE,
  DATASET_MV_CRITERIA_SHOW,
  DATASET_MV_DATASET_FETCH_ENABLE,
  DATASET_MV_DATASET_FETCH_ERROR,
  DATASET_MV_DATASET_FETCH_INIT,
  DATASET_MV_DATASET_FETCH_SUCCESS,
  DATASET_MV_DETAIL_LEVELS_FETCH,
  DATASET_MV_DETAIL_LEVELS_SET,
  DATASET_MV_DOWNLOAD_SUBMIT,
  DATASET_MV_DOWNLOAD_WARNING_HIDE,
  DATASET_MV_HTML_GENERATING_TIME_SET,
  DATASET_MV_INDICATOR_ARITHMETIC_MEAN_VALUES_SET,
  DATASET_MV_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET,
  DATASET_MV_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET,
  DATASET_MV_INDICATOR_CREATE_VISIBILITY_SET,
  DATASET_MV_INDICATOR_DELETE,
  DATASET_MV_INDICATOR_LIST_VISIBILITY_SET,
  DATASET_MV_INDICATOR_PREVIEW_FETCH,
  DATASET_MV_INDICATOR_PREVIEW_RESET,
  DATASET_MV_INDICATOR_PUBLISH,
  DATASET_MV_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET,
  DATASET_MV_INDICATOR_WARNING_HIDE,
  DATASET_MV_MAP_LAYOUT_SUBMIT,
  DATASET_MV_MAP_SETTINGS_SET,
  DATASET_MV_SDMX_QUERY_FETCH,
  DATASET_MV_SDMX_QUERY_HIDE,
  DATASET_MV_SDMX_QUERY_SHOW,
  DATASET_MV_STATE_BACKUP,
  DATASET_MV_STRUCTURE_CODELIST_FETCH,
  DATASET_MV_STRUCTURE_CODELIST_FULL_FETCH,
  DATASET_MV_STRUCTURE_CODELIST_FULL_HIDE,
  DATASET_MV_STRUCTURE_CRITERIA_SET,
  DATASET_MV_STRUCTURE_FETCH_ERROR,
  DATASET_MV_STRUCTURE_FETCH_INIT,
  DATASET_MV_STRUCTURE_FETCH_SUCCESS,
  DATASET_MV_TERRITORIAL_CLASSIFICATIONS_CONFIG_SET,
  DATASET_MV_TERRITORIAL_CLASSIFICATIONS_FETCH,
  DATASET_MV_TERRITORIAL_CLASSIFICATIONS_VALUES_FETCH,
  DATASET_MV_TERRITORY_HIDE,
  DATASET_MV_TERRITORY_LAST_YEAR_FETCH,
  DATASET_MV_TERRITORY_SHOW,
  DATASET_MV_TERRITORY_SUBMIT,
  DATASET_MV_TERRITORY_TERRITORIES_FETCH,
  DATASET_MV_UNAVAILABLE_VIEW_WARNING_HIDE,
  DATASET_MV_VIEW_ERROR_HIDE,
  DATASET_MV_VIEW_TEMPLATE_HIDE,
  DATASET_MV_VIEW_TEMPLATE_SHOW,
  DATASET_MV_VIEW_TEMPLATE_SUBMIT,
  DATASET_MV_VIEWER_CHART_TYPE_SET,
  DATASET_MV_VIEWER_CHART_VISIBILITY_SET,
  DATASET_MV_VIEWER_MAP_VISIBILITY_SET,
  DATASET_MV_VIEWER_TABLE_VISIBILITY_SET
} from "./actions";
import {AdditionalDataset, DatasetMultiViewerState, getViewTemplateBackupFromMVState, Indicator} from "./constants";
import {
  getActionExtras,
  getAppConfig,
  getCurrentNodeConfig
} from "../../../middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../../middlewares/request/requestActions";
import {getDetailLevelFromTree, getDetailLevelTree, getFilteredCatalog} from "../../../utils/catalog";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE,
  getCriteriaObjectFromArray
} from "../../../utils/criteria";
import {TABLE_EMPTY_CHAR_DEFAULT} from "../../../utils/formatters";
import {localizeI18nObj} from "../../../utils/i18n";
import {isStrCaseInsensitiveEquals} from "../../../utils/other";
import {getFreqValueFromCriteria, getMinAndMax} from "../../../utils/timePeriodAndFreq";
import {getTreeFromArray} from "../../../utils/tree";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getChartSettingsFromViewTemplateLayouts,
  getMapSettingsFromViewTemplateLayouts
} from "../../../utils/viewTemplate";

const defaultIsTableVisible = true;
const defaultIsMapVisible = true;
const defaultIsChartVisible = false;

const initialState = {
  datasetUuid: null,
  notCompatibleDataset: false,
  dataset: null,
  isFirstFetch: false,
  datasetFetchStart: false,
  datasetFetchError: false,
  isPartialData: false,
  isEmptyData: false,
  isTooBigData: false,
  isPointData: false,
  latAttributeId: null,
  longAttributeId: null,
  srid: null,
  isTooLongQuery: false,
  tableColCount: null,
  mapPointCount: null,
  isCriteriaVisible: false,
  isCriteriaAlertVisible: false,
  isObsCountWarningVisible: false,
  dimensions: null,
  territoryDimCodelist: null,
  territoryDim: null,
  timeDim: null,
  freqDim: null,
  pointDim: null,
  measureDim: null,
  isTableVisible: defaultIsTableVisible,
  isMapVisible: defaultIsMapVisible,
  isChartVisible: defaultIsChartVisible,
  chartType: CHART_TYPE_BAR,
  view: null,
  template: null,
  hasViewLayout: false,
  hasTemplateLayout: false,
  tableLayout: null,
  mapLayout: null,
  chartLayout: null,
  tableFilterTree: null,
  mapFilterTree: null,
  chartFilterTree: null,
  timePeriodsByFreq: null,
  labelFormat: null,
  temporalDimOrder: null,
  criteria: {},
  initialCriteria: {},
  decimalSeparator: null,
  roundingStrategy: null,
  decimalPlaces: null,
  tableEmptyChar: TABLE_EMPTY_CHAR_DEFAULT,
  chartSettings: {},
  mapSettings: {},
  codelists: null,
  codelistsLength: null,
  codelistFetchError: false,
  criteriaObsCount: null,
  timings: null,
  isFetchDatasetDisabled: true,
  isDownloadWarningVisible: false,
  isUnavailableViewWarningVisible: false,
  isViewVisible: false,
  isViewErrorVisible: false,
  viewErrorMessage: null,
  isTemplateVisible: false,
  isQueryVisible: false,
  structureQuery: null,
  dataQuery: null,
  isTerritoryVisible: false,
  detailLevelTree: null,
  detailLevel: null,
  territoryTree: null,
  territories: null,
  lastTerritoryYear: null,
  territorialClassifications: null,
  territorialClassificationsConfig: [],
  territorialClassificationsFetched: [],
  territorialClassificationsValues: null,
  isIndicatorCreateVisible: false,
  isIndicatorListVisible: false,
  indicators: [],
  indicatorPreview: null,
  missingIndicators: null,
  showArithmeticMean: false,
  arithmeticMeans: null,
  arithmeticMeanDims: null,
  showStandardDeviation: false,
  showCoefficientOfVariation: false,
  isAdditionalDatasetCreateVisible: false,
  isAdditionalDatasetListVisible: false,
  additionalDatasets: [],
  additionalDatasetCatalog: null,
  additionalDataset: null,
  datasetCacheInfo: null,
  missingFilterValues: null,
  geometriesYear: null,
  geometriesYears: null
};

const datasetMVReducer: Reducer<DatasetMultiViewerState, any> = (state = initialState, action) => {
  switch (action.type) {
    case DATASET_MV_STATE_BACKUP: {
      if (state.datasetUuid !== null && state.datasetUuid === action.datasetUuid && state.dataset !== null) {
        sessionStorage.setItem(state.datasetUuid, JSON.stringify(getViewTemplateBackupFromMVState(state)));
      }
      return state;
    }
    case DATASET_MV_STRUCTURE_FETCH_INIT: {
      return initialState;
    }
    case DATASET_MV_STRUCTURE_FETCH_SUCCESS: {
      const {language, languages} = getActionExtras(action);

      const VIEW_KEY = "view";
      const TEMPLATE_KEY = "template";

      const {datasetUuid, structure, viewId} = action.payload;

      const dimensions = structure?.criteria || [];

      const latAttributeId = structure?.pointDataInfo?.latitudeField || null;
      const longAttributeId = structure?.pointDataInfo?.longitudeField || null;
      const srid = structure?.pointDataInfo?.srid || null;

      const isPointData = latAttributeId !== null && longAttributeId !== null && srid !== null;

      const territoryDim = structure?.territorialDimension || null;
      const timeDim = structure?.timeDimension || null;
      const freqDim = structure?.freqDimension || null;
      const measureDim = structure?.measureDimension || null;

      const territoryDimCodelist = territoryDim
        ? dimensions.find((dim: any) => dim.id === territoryDim)?.extra?.DataStructureRef || null
        : null;

      const hasView = structure[VIEW_KEY]?.mode === ViewerMode.MultiViewer;
      const view = hasView
        ? {
            ...structure[VIEW_KEY],
            layouts: JSON.parse(structure[VIEW_KEY].layouts)
          }
        : null;

      const hasTemplate = structure[TEMPLATE_KEY]?.mode === ViewerMode.MultiViewer;
      const template = hasTemplate
        ? {
            ...structure[TEMPLATE_KEY],
            layouts: JSON.parse(structure[TEMPLATE_KEY].layouts)
          }
        : null;

      let sessionBackup;
      const storageItem = sessionStorage.getItem(datasetUuid);
      if (storageItem && storageItem.length > 0) {
        sessionBackup = JSON.parse(storageItem || "{}");
        sessionBackup.layouts = JSON.parse(sessionBackup?.layouts || "{}");
        sessionBackup = sessionBackup.mode === ViewerMode.MultiViewer ? sessionBackup : null;

        sessionStorage.removeItem(datasetUuid);
      }

      let viewTemplate = sessionBackup || view || template || null;
      let viewTemplateLayouts = null;

      if (viewTemplate) {
        viewTemplate = {
          ...viewTemplate,
          decimalSeparator: hasTemplate ? template.decimalSeparator : null,
          roundingStrategy: hasTemplate ? template.roundingStrategy : null,
          decimalNumber: hasTemplate ? template.decimalNumber : null
        };
        viewTemplateLayouts = {
          ...(template?.layouts || {}),
          ...(view?.layouts || {}),
          ...(sessionBackup?.layouts || {})
        };
      }

      const criteria = getCriteriaObjectFromArray(viewTemplate?.criteria);
      const isTerritoryVisible = (territoryDim && !viewTemplate) || false;

      return {
        ...state,
        datasetUuid: datasetUuid,
        isFirstFetch: true,
        hasViewLayout: hasView,
        hasTemplateLayout: hasTemplate,
        isPointData: isPointData,
        latAttributeId: latAttributeId,
        longAttributeId: longAttributeId,
        srid: srid,
        isTableVisible:
          viewTemplateLayouts &&
          viewTemplateLayouts?.isTableVisible !== null &&
          viewTemplateLayouts?.isTableVisible !== undefined
            ? viewTemplateLayouts.isTableVisible
            : state.isTableVisible,
        isMapVisible:
          viewTemplateLayouts &&
          viewTemplateLayouts?.isMapVisible !== null &&
          viewTemplateLayouts?.isMapVisible !== undefined
            ? viewTemplateLayouts.isMapVisible
            : state.isMapVisible,
        isChartVisible:
          viewTemplateLayouts &&
          viewTemplateLayouts?.isChartVisible !== null &&
          viewTemplateLayouts?.isChartVisible !== undefined
            ? viewTemplateLayouts.isChartVisible
            : state.isChartVisible,
        chartType:
          viewTemplateLayouts && viewTemplateLayouts?.chartType !== null && viewTemplateLayouts?.chartType !== undefined
            ? viewTemplateLayouts.chartType
            : state.chartType,
        view: view,
        template: template,
        dimensions: dimensions,
        territoryDimCodelist: territoryDimCodelist,
        territoryDim: territoryDim,
        timeDim: timeDim,
        freqDim: freqDim,
        measureDim: measureDim,
        detailLevel: viewTemplateLayouts
          ? viewTemplateLayouts.detailLevel !== null && viewTemplateLayouts.detailLevel !== undefined
            ? viewTemplateLayouts.detailLevel
            : viewTemplateLayouts.mapDetailLevel !== null && viewTemplateLayouts.mapDetailLevel !== undefined
              ? viewTemplateLayouts.mapDetailLevel
              : null
          : state.detailLevel,
        territories: viewTemplateLayouts ? viewTemplateLayouts.territories : null,
        territorialClassificationsConfig:
          viewTemplateLayouts && viewTemplateLayouts.territorialClassificationsConfig
            ? viewTemplateLayouts.territorialClassificationsConfig
            : [],
        codelistsLength: state.codelistsLength ? state.codelistsLength : dimensions.map(() => null),
        isTerritoryVisible: isTerritoryVisible,
        isUnavailableViewWarningVisible: viewId !== null && !structure?.[VIEW_KEY],
        tableLayout: viewTemplateLayouts && viewTemplateLayouts?.tableLayout ? viewTemplateLayouts.tableLayout : null,
        mapLayout:
          (territoryDim || isPointData) && viewTemplateLayouts && viewTemplateLayouts?.mapLayout
            ? viewTemplateLayouts.mapLayout
            : null,
        chartLayout: viewTemplateLayouts && viewTemplateLayouts?.chartLayout ? viewTemplateLayouts.chartLayout : null,
        labelFormat: viewTemplateLayouts?.labelFormat || LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
        temporalDimOrder:
          viewTemplateLayouts?.temporalDimOrder || structure.temporalDimOrder || TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
        criteria: criteria,
        initialCriteria: criteria,
        decimalSeparator:
          Object.keys(viewTemplate?.decimalSeparator || {}).length > 0
            ? localizeI18nObj(viewTemplate.decimalSeparator, language, languages)
            : structure.decimalSeparator,
        roundingStrategy:
          viewTemplate?.roundingStrategy !== undefined ? viewTemplate?.roundingStrategy : structure.roundingStrategy,
        decimalPlaces:
          viewTemplate?.decimalNumber !== null &&
          viewTemplate?.decimalNumber !== undefined &&
          viewTemplate?.decimalNumber !== -1
            ? viewTemplate?.decimalNumber
            : structure.decimalPlaces,
        tableEmptyChar:
          viewTemplateLayouts?.tableEmptyChar !== null && viewTemplateLayouts?.tableEmptyChar !== undefined
            ? viewTemplateLayouts?.tableEmptyChar
            : structure.emptyCellPlaceHolder,
        chartSettings: {
          ...getAppConfig(action).chartDefaultSettings,
          ...getChartSettingsFromViewTemplateLayouts(viewTemplateLayouts)
        },
        mapSettings: {
          ...getAppConfig(action).mapDefaultSettings,
          ...getMapSettingsFromViewTemplateLayouts(viewTemplateLayouts)
        },
        isFetchDatasetDisabled: isTerritoryVisible,
        indicators:
          viewTemplate?.indicatorsDefinition !== null && viewTemplate?.indicatorsDefinition !== undefined
            ? JSON.parse(viewTemplate.indicatorsDefinition)
            : [],
        showArithmeticMean: !!viewTemplateLayouts?.showArithmeticMean,
        showStandardDeviation: !!viewTemplateLayouts?.showStandardDeviation,
        showCoefficientOfVariation: !!viewTemplateLayouts?.showCoefficientOfVariation,
        additionalDatasets:
          viewTemplate?.additionalDatasets !== null && viewTemplate?.additionalDatasets !== undefined
            ? JSON.parse(viewTemplate.additionalDatasets)
            : [],
        geometriesYear: structure?.geometriesYear || null,
        geometriesYears: structure?.geometriesYears || null
      };
    }
    case DATASET_MV_STRUCTURE_FETCH_ERROR: {
      return {
        ...initialState
      };
    }
    case DATASET_MV_DETAIL_LEVELS_SET: {
      const isMultiViewerCompatible =
        state.timeDim !== null &&
        ((state.territoryDim !== null && (action.payload.detailLevelTree || []).length > 0) || state.isPointData);

      return isMultiViewerCompatible
        ? {
            ...state,
            notCompatibleDataset: false,
            detailLevelTree: action.payload.detailLevelTree,
            detailLevel: action.payload.detailLevel,
            territorialClassifications: null,
            territorialClassificationsFetched: [],
            territorialClassificationsValues: null
          }
        : {
            ...initialState,
            notCompatibleDataset: true
          };
    }
    case DATASET_MV_DATASET_FETCH_ENABLE: {
      const tooMuchObs =
        state.criteriaObsCount !== null &&
        action.payload.maxObservations !== undefined &&
        state.criteriaObsCount > action.payload.maxObservations;

      return {
        ...state,
        isFetchDatasetDisabled: tooMuchObs,
        criteriaObsCount: tooMuchObs ? state.criteriaObsCount : null,
        isObsCountWarningVisible: tooMuchObs
      };
    }
    case DATASET_MV_DATASET_FETCH_INIT: {
      return {
        ...state,
        isFetchDatasetDisabled: true,
        datasetFetchStart: true,
        datasetFetchError: false
      };
    }
    case DATASET_MV_DATASET_FETCH_SUCCESS: {
      if (action.payload.worker) {
        action.payload.worker.terminate();
      }

      const isResponseValid = !action.payload.isResponseNotValid;

      const tableLayout = isResponseValid ? action.payload.tableLayout : state.tableLayout;
      const mapLayout = isResponseValid ? action.payload.mapLayout : state.mapLayout;
      const chartLayout = isResponseValid ? action.payload.chartLayout : state.chartLayout;

      let missingIndicators = null;
      if (isResponseValid && state.indicators.length > 0) {
        const jsonStatIndicators = Object.values(action.payload.response?.extension?.marginalvalues || {})
          .map((value: any) => value.label || null)
          .filter((label: string) => label !== null);
        missingIndicators = state.indicators
          .map((indicator: Indicator) =>
            localizeI18nObj(indicator.title, getActionExtras(action).language, getActionExtras(action).languages)
          )
          .filter((label: string) => !jsonStatIndicators.includes(label))
          .map((label: string) => `"${label}"`);
      }

      let removeLastAD = false;
      if (!isResponseValid && state.isAdditionalDatasetCreateVisible) {
        removeLastAD = true;
      }

      let removeLastInd = false;
      if (!isResponseValid && state.isIndicatorCreateVisible) {
        removeLastInd = true;
      }

      return {
        ...state,
        dataset: isResponseValid ? action.payload.response : state.dataset,
        isFirstFetch: false,
        datasetFetchStart: false,
        datasetFetchError: false,

        isTooBigData: action.payload.isTooBigData,
        isEmptyData: action.payload.isEmptyData,
        isPartialData: action.payload.isPartialData,
        tableColCount: action.payload.tableColCount,
        mapPointCount: action.payload.mapPointCount,

        isPointData: action.payload.isPointData,
        pointDim: action.payload.pointDim,

        isCriteriaVisible: state.isCriteriaVisible && !isResponseValid,
        isCriteriaAlertVisible: !isResponseValid,

        isTerritoryVisible: state.isTerritoryVisible && !isResponseValid,

        tableLayout: tableLayout,
        mapLayout: mapLayout,
        chartLayout: chartLayout,

        tableFilterTree: isResponseValid ? action.payload.tableFilterTree : state.tableFilterTree,
        mapFilterTree: isResponseValid ? action.payload.mapFilterTree : state.mapFilterTree,
        chartFilterTree: isResponseValid ? action.payload.chartFilterTree : state.chartFilterTree,
        timePeriodsByFreq: isResponseValid ? action.payload.timePeriodsByFreq : state.timePeriodsByFreq,

        initialCriteria: isResponseValid ? state.criteria : state.initialCriteria,

        codelists: isResponseValid ? null : state.codelists,

        timings: {
          [OBSERVATION_COUNT_KEY]: action.payload.response?.value
            ? Object.keys(action.payload.response.value).length
            : null,
          [SERVER_TIMINGS_KEY]: action.payload.responseHeaders.backendtimers
            ? JSON.parse(action.payload.responseHeaders.backendtimers)
            : null
        },

        structureQuery: null,
        dataQuery: null,

        isIndicatorCreateVisible: isResponseValid ? false : state.isIndicatorCreateVisible,
        indicators: removeLastInd ? state.indicators.slice(0, -1) : state.indicators,
        missingIndicators: missingIndicators && missingIndicators.length > 0 ? missingIndicators : null,

        isAdditionalDatasetCreateVisible: isResponseValid ? false : state.isAdditionalDatasetCreateVisible,
        additionalDatasets: removeLastAD ? state.additionalDatasets.slice(0, -1) : state.additionalDatasets,
        additionalDatasetCatalog: isResponseValid ? null : state.additionalDatasetCatalog,
        additionalDataset: isResponseValid ? null : state.additionalDataset
      };
    }
    case DATASET_MV_DATASET_FETCH_ERROR: {
      const isPayloadTooLarge = action.payload.statusCode === 413;
      const isTooLongQuery = action.payload.statusCode === 414;

      return {
        ...state,
        dataset: null,
        datasetFetchStart: false,
        datasetFetchError: true,
        isPartialData: false,
        isEmptyData: false,
        isTooBigData: isPayloadTooLarge,
        isTooLongQuery: isTooLongQuery,
        isCriteriaAlertVisible: isPayloadTooLarge || isTooLongQuery
      };
    }
    case DATASET_MV_TERRITORIAL_CLASSIFICATIONS_CONFIG_SET: {
      return {
        ...state,
        territorialClassificationsConfig: action.payload.territorialClassificationsConfig
      };
    }
    case DATASET_MV_CRITERIA_SHOW: {
      return {
        ...state,
        isCriteriaVisible: true
      };
    }
    case DATASET_MV_CRITERIA_HIDE: {
      return {
        ...state,
        isCriteriaVisible: false,
        criteria: state.initialCriteria,
        codelists: null
      };
    }
    case DATASET_MV_TERRITORY_SHOW: {
      return {
        ...state,
        isTerritoryVisible: true,
        lastTerritoryYear: null
      };
    }
    case DATASET_MV_TERRITORY_HIDE: {
      return {
        ...state,
        isTerritoryVisible: false,
        lastTerritoryYear: null
      };
    }
    case DATASET_MV_TERRITORY_SUBMIT: {
      return {
        ...state,
        isTerritoryVisible: false,
        initialCriteria: action.payload.criteria,
        criteria: action.payload.criteria,
        detailLevel: action.payload.detailLevel,
        territories: action.payload.territories,
        isFetchDatasetDisabled: false,
        territorialClassifications: null,
        territorialClassificationsConfig: [],
        territorialClassificationsFetched: [],
        territorialClassificationsValues: null
      };
    }
    case DATASET_MV_TERRITORY_LAST_YEAR_FETCH: {
      return {
        ...state,
        lastTerritoryYear: action.payload.lastTerritoryYear
      };
    }
    case DATASET_MV_CRITERIA_ALERT_HIDE: {
      return {
        ...state,
        isCriteriaAlertVisible: false
      };
    }
    case DATASET_MV_CRITERIA_OBS_COUNT_WARNING_HIDE: {
      return {
        ...state,
        isObsCountWarningVisible: false
      };
    }
    case DATASET_MV_STRUCTURE_CRITERIA_SET: {
      return {
        ...state,
        criteria: action.criteria,
        criteriaObsCount: null
      };
    }
    case DATASET_MV_VIEWER_TABLE_VISIBILITY_SET: {
      return {
        ...state,
        isTableVisible: action.isVisible
      };
    }
    case DATASET_MV_VIEWER_MAP_VISIBILITY_SET: {
      return {
        ...state,
        isMapVisible: action.isVisible
      };
    }
    case DATASET_MV_VIEWER_CHART_VISIBILITY_SET: {
      return {
        ...state,
        isChartVisible: action.isVisible
      };
    }
    case DATASET_MV_VIEWER_CHART_TYPE_SET: {
      return {
        ...state,
        chartType: action.chartType
      };
    }
    case DATASET_MV_MAP_LAYOUT_SUBMIT: {
      return {
        ...state,
        mapLayout: action.layout
      };
    }
    case DATASET_MV_CHART_LAYOUT_SUBMIT: {
      return {
        ...state,
        chartLayout: action.layout
      };
    }
    case DATASET_MV_CHART_LAYOUT_TERRITORY_DIM_VALUES_SET: {
      const territoryDim = state.isPointData ? state.pointDim : state.territoryDim;
      const primaryDim = state.chartLayout.primaryDim?.[0] || null;
      const secondaryDim = state.chartLayout.secondaryDim?.[0] || null;

      let newChartLayout = state.chartLayout;

      if (territoryDim && primaryDim && secondaryDim) {
        newChartLayout = {
          ...state.chartLayout,
          primaryDimValues:
            primaryDim === territoryDim
              ? action.territoryDimValues
              : state.dataset.dimension[primaryDim].category.index,
          secondaryDimValues:
            secondaryDim === territoryDim
              ? action.territoryDimValues
              : state.dataset.dimension[secondaryDim].category.index
        };
      }

      return {
        ...state,
        chartLayout: newChartLayout
      };
    }
    case DATASET_MV_CHART_SETTINGS_SET: {
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          ...action.chartSettings
        }
      };
    }
    case DATASET_MV_MAP_SETTINGS_SET: {
      return {
        ...state,
        mapSettings: {
          ...state.mapSettings,
          ...action.mapSettings
        }
      };
    }
    case DATASET_MV_DOWNLOAD_WARNING_HIDE: {
      return {
        ...state,
        isDownloadWarningVisible: false
      };
    }
    case DATASET_MV_UNAVAILABLE_VIEW_WARNING_HIDE: {
      return {
        ...state,
        isUnavailableViewWarningVisible: false
      };
    }
    case DATASET_MV_HTML_GENERATING_TIME_SET: {
      return {
        ...state,
        timings: {
          ...state.timings,
          [GENERATING_HTML_TIME_KEY]: action.time
        }
      };
    }
    case DATASET_MV_VIEW_TEMPLATE_SHOW: {
      return {
        ...state,
        isViewVisible: action.isView ? true : state.isViewVisible,
        isTemplateVisible: !action.isView ? true : state.isTemplateVisible
      };
    }
    case DATASET_MV_VIEW_TEMPLATE_HIDE: {
      return {
        ...state,
        isViewVisible: false,
        isTemplateVisible: false
      };
    }
    case DATASET_MV_VIEW_ERROR_HIDE: {
      return {
        ...state,
        isViewErrorVisible: false,
        viewErrorMessage: null
      };
    }
    case DATASET_MV_SDMX_QUERY_SHOW: {
      return {
        ...state,
        isQueryVisible: true
      };
    }
    case DATASET_MV_SDMX_QUERY_HIDE: {
      return {
        ...state,
        isQueryVisible: false
      };
    }
    case DATASET_MV_INDICATOR_CREATE_VISIBILITY_SET:
      return {
        ...state,
        isIndicatorCreateVisible: action.isVisible
      };
    case DATASET_MV_INDICATOR_LIST_VISIBILITY_SET:
      return {
        ...state,
        isIndicatorListVisible: action.isVisible
      };
    case DATASET_MV_INDICATOR_PREVIEW_RESET:
      return {
        ...state,
        indicatorPreview: null
      };
    case DATASET_MV_INDICATOR_PUBLISH:
      return {
        ...state,
        indicators: [...state.indicators, action.indicator],
        isFetchDatasetDisabled: false
      };
    case DATASET_MV_INDICATOR_DELETE:
      const newIndicators = [...state.indicators];
      newIndicators.splice(action.indicatorIdx, 1);

      return {
        ...state,
        indicators: newIndicators,
        isFetchDatasetDisabled: false
      };
    case DATASET_MV_INDICATOR_WARNING_HIDE:
      return {
        ...state,
        missingIndicators: null
      };
    case DATASET_MV_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET:
      return {
        ...state,
        showArithmeticMean: action.visible
      };
    case DATASET_MV_INDICATOR_ARITHMETIC_MEAN_VALUES_SET:
      return {
        ...state,
        arithmeticMeans: action.arithmeticMeans,
        arithmeticMeanDims: action.arithmeticMeanDims
      };
    case DATASET_MV_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET:
      return {
        ...state,
        showStandardDeviation: action.visible
      };
    case DATASET_MV_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET:
      return {
        ...state,
        showCoefficientOfVariation: action.visible
      };
    case DATASET_MV_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET:
      return {
        ...state,
        isAdditionalDatasetCreateVisible: action.isVisible,
        additionalDatasetCatalog: null,
        additionalDataset: null
      };
    case DATASET_MV_ADDITIONAL_DATASET_LIST_VISIBILITY_SET:
      return {
        ...state,
        isAdditionalDatasetListVisible: action.isVisible
      };
    case DATASET_MV_ADDITIONAL_DATASET_CHANGE: {
      const isDatasetChanged =
        (action.additionalDataset?.nodeCode &&
          state.additionalDataset?.nodeCode !== action.additionalDataset.nodeCode) ||
        (action.additionalDataset?.datasetId &&
          state.additionalDataset?.datasetId !== action.additionalDataset.datasetId);

      return {
        ...state,
        additionalDataset: isDatasetChanged
          ? {
              ...action.additionalDataset
            }
          : {
              ...state.additionalDataset,
              ...action.additionalDataset
            }
      };
    }
    case DATASET_MV_ADDITIONAL_DATASET_SUBMIT:
      return {
        ...state,
        additionalDatasets:
          state.additionalDataset !== null
            ? [...state.additionalDatasets, state.additionalDataset]
            : state.additionalDatasets,
        isFetchDatasetDisabled: false
      };
    case DATASET_MV_ADDITIONAL_DATASET_DELETE:
      const newAdditionalDatasets = [...state.additionalDatasets];
      newAdditionalDatasets.splice(action.datasetIdx, 1);

      return {
        ...state,
        additionalDatasets: newAdditionalDatasets,
        isFetchDatasetDisabled: false
      };
    case DATASET_MV_ADDITIONAL_DATASET_CRITERIA_SET: {
      const newAdditionalDatasets = [...state.additionalDatasets];
      newAdditionalDatasets[action.datasetIdx].criteria = action.criteria;

      return {
        ...state,
        additionalDatasets: newAdditionalDatasets
      };
    }
    case DATASET_MV_STRUCTURE_CODELIST_FULL_HIDE: {
      return {
        ...state,
        missingFilterValues: null
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case DATASET_MV_STRUCTURE_CODELIST_FETCH: {
          return {
            ...state,
            criteriaObsCount: null,
            codelistFetchError: false
          };
        }
        case DATASET_MV_TERRITORY_TERRITORIES_FETCH: {
          return {
            ...state,
            territoryTree: null
          };
        }
        case DATASET_MV_ADDITIONAL_DATASET_CATALOG_FETCH: {
          return {
            ...state,
            additionalDatasetCatalog: null
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DATASET_MV_DETAIL_LEVELS_FETCH: {
          const {t} = getActionExtras(action);

          const detailLevelTree = getDetailLevelTree(action.payload.response?.catalogCategoryLayers || [], t);
          const detailLevel = getDetailLevelFromTree(detailLevelTree, [
            action.payload.extra.datasetDetailLevel,
            action.payload.extra.nodeDetailLevel
          ]);

          const isMultiViewerCompatible =
            state.timeDim !== null &&
            ((state.territoryDim !== null && (detailLevelTree || []).length > 0) || state.isPointData);

          return isMultiViewerCompatible
            ? {
                ...state,
                notCompatibleDataset: false,
                detailLevelTree: detailLevelTree,
                detailLevel: detailLevel,
                territorialClassifications: null,
                territorialClassificationsFetched: [],
                territorialClassificationsValues: null
              }
            : {
                ...initialState,
                notCompatibleDataset: true
              };
        }
        case DATASET_MV_TERRITORIAL_CLASSIFICATIONS_FETCH: {
          return {
            ...state,
            territorialClassifications: action.payload.response ?? []
          };
        }
        case DATASET_MV_TERRITORIAL_CLASSIFICATIONS_VALUES_FETCH: {
          return {
            ...state,
            territorialClassificationsFetched: [
              ...state.territorialClassificationsFetched,
              ...action.payload.extra.layers
            ],
            territorialClassificationsValues: _.merge(
              {},
              state.territorialClassificationsValues,
              action.payload.response
            )
          };
        }
        case DATASET_MV_STRUCTURE_CODELIST_FETCH: {
          let isMainDataset = false;

          const isCreatingAdditionalDataset =
            isStrCaseInsensitiveEquals(state.additionalDataset?.nodeCode, action.payload.extra.nodeCode) &&
            isStrCaseInsensitiveEquals(state.additionalDataset?.datasetId, action.payload.extra.datasetId);

          const additionalDatasetToUpdateIdx = state.additionalDatasets.findIndex(
            (ad: AdditionalDataset) =>
              isStrCaseInsensitiveEquals(ad.nodeCode, action.payload.extra.nodeCode) &&
              isStrCaseInsensitiveEquals(ad.datasetId, action.payload.extra.datasetId)
          );

          let dimensions: any[] | null;
          let timeDim: string | null;
          let freqDim: string | null;
          let codelistsLength: (number | null)[] | null;
          let criteria: {[key: string]: Criteria};

          if (isCreatingAdditionalDataset && state.additionalDataset) {
            dimensions = state.additionalDataset.dimensions;
            timeDim = state.additionalDataset.timeDim;
            freqDim = state.additionalDataset.freqDim;
            codelistsLength = state.additionalDataset.codelistsLength;
            criteria = state.additionalDataset.criteria;
          } else if (additionalDatasetToUpdateIdx !== -1 && state.additionalDatasets[additionalDatasetToUpdateIdx]) {
            dimensions = state.additionalDatasets[additionalDatasetToUpdateIdx].dimensions;
            timeDim = state.additionalDatasets[additionalDatasetToUpdateIdx].timeDim;
            freqDim = state.additionalDatasets[additionalDatasetToUpdateIdx].freqDim;
            codelistsLength = state.additionalDatasets[additionalDatasetToUpdateIdx].codelistsLength;
            criteria = state.additionalDatasets[additionalDatasetToUpdateIdx].criteria;
          } else {
            isMainDataset = true;

            dimensions = state.dimensions;
            timeDim = state.timeDim;
            freqDim = state.freqDim;
            codelistsLength = state.codelistsLength;
            criteria = state.criteria;
          }

          const newCodelists: {[key: string]: any[]} = {};
          const newCodelistLength = codelistsLength ? [...codelistsLength] : (dimensions || []).map(() => null);
          const newCriteria: {[key: string]: Criteria} = _.cloneDeep(criteria);

          (dimensions || []).forEach((dimension, idx) => {
            const codelist = action.payload.response.criteria.find(({id}: any) => id === dimension.id);

            if (codelist) {
              let length = 0;
              const values = (codelist.values || []).map((code: any) => {
                if (code.isSelectable) {
                  length++;
                }
                return {
                  ...code,
                  label: `[${code.id}] ${code.name}`
                };
              });

              newCodelists[dimension.id] = getTreeFromArray(values, "parentId", "children");

              if (!timeDim || dimension.id !== timeDim) {
                newCodelistLength[idx] = length;

                if (!newCriteria[dimension.id] && values.length === 1) {
                  newCriteria[dimension.id] = {
                    id: dimension.id,
                    type: CRITERIA_FILTER_TYPE_CODES,
                    filterValues: [values[0].id],
                    period: undefined,
                    from: undefined,
                    to: undefined
                  };
                }
              } else if (!newCriteria[timeDim]) {
                if (
                  freqDim &&
                  isValidIntegerInInclusiveRange(action.payload.extra.defaultLastNPeriods, 1) &&
                  action.payload.extra.type === CRITERIA_SELECTION_TYPE_PARTIAL
                ) {
                  newCriteria[timeDim] = {
                    id: timeDim,
                    type: CRITERIA_FILTER_TYPE_PERIODS,
                    period: action.payload.extra.defaultLastNPeriods,
                    filterValues: undefined,
                    from: undefined,
                    to: undefined
                  };
                } else {
                  const {min, max} = getMinAndMax(values, action.payload.extra.freq, getCurrentNodeConfig(action));

                  newCriteria[timeDim] = {
                    id: timeDim,
                    type: CRITERIA_FILTER_TYPE_RANGE,
                    from: min,
                    to: max,
                    filterValues: undefined,
                    period: undefined
                  };
                }
              }
            }
          });

          const newAdditionalDataset: AdditionalDataset | null = _.cloneDeep(state.additionalDataset);
          if (isCreatingAdditionalDataset && newAdditionalDataset) {
            newAdditionalDataset.criteria = newCriteria;
            newAdditionalDataset.codelists = newCodelists;
            newAdditionalDataset.codelistsLength = newCodelistLength;
          }

          const newAdditionalDatasets: AdditionalDataset[] = _.cloneDeep(state.additionalDatasets);
          if (additionalDatasetToUpdateIdx !== -1 && newAdditionalDatasets[additionalDatasetToUpdateIdx]) {
            newAdditionalDatasets[additionalDatasetToUpdateIdx].criteria = newCriteria;
            newAdditionalDatasets[additionalDatasetToUpdateIdx].codelists = newCodelists;
            newAdditionalDatasets[additionalDatasetToUpdateIdx].codelistsLength = newCodelistLength;
          }

          return {
            ...state,
            criteria: isMainDataset ? newCriteria : state.criteria,
            codelists: isMainDataset ? newCodelists : state.codelists,
            codelistsLength: isMainDataset ? newCodelistLength : state.codelistsLength,
            additionalDataset: newAdditionalDataset,
            additionalDatasets: newAdditionalDatasets
          };
        }
        case DATASET_MV_TERRITORY_TERRITORIES_FETCH: {
          const territoryCodelist = action.payload.response.criteria[0] || {};
          const territoryCodelistValue = territoryCodelist.values ? territoryCodelist.values : [];

          const territoryTree = getTreeFromArray(territoryCodelistValue || [], "parentId", "children");

          return {
            ...state,
            territoryTree: territoryTree
          };
        }
        case DATASET_MV_TERRITORY_LAST_YEAR_FETCH: {
          const dimensionCodelist = action.payload.response.criteria[0] || {};
          const freq = getFreqValueFromCriteria(state.criteria, state.freqDim);
          const {max} = getMinAndMax(dimensionCodelist.values, freq, getCurrentNodeConfig(action));

          return {
            ...state,
            lastTerritoryYear: max ? moment(max).format("YYYY") : null
          };
        }
        case DATASET_MV_VIEW_TEMPLATE_SUBMIT: {
          return action.payload.extra.isView
            ? {
                ...state,
                isViewVisible: false,
                isTemplateVisible: false
              }
            : {
                ...initialState
              };
        }
        case DATASET_MV_SDMX_QUERY_FETCH: {
          return {
            ...state,
            structureQuery: action.payload.response?.structureUrl || null,
            dataQuery: action.payload.response?.dataflowUrl || null
          };
        }
        case DATASET_MV_INDICATOR_PREVIEW_FETCH: {
          return {
            ...state,
            indicatorPreview: (action.payload.response?.id || []).length > 0 ? action.payload.response : ""
          };
        }
        case DATASET_MV_ADDITIONAL_DATASET_CATALOG_FETCH: {
          const t = getActionExtras(action)?.t;

          const catalog = new LocalCategoryProvider(
            action.payload.response.categoryGroups,
            action.payload.response.datasetMap,
            action.payload.response.datasetUncategorized,
            action.payload.extra.selectedNodeId,
            action.payload.response.catalogLayers,
            t
          );

          const allDatasetIds = [
            action.payload.extra.mainDatasetId,
            ...state.additionalDatasets.map(({datasetId}) => datasetId)
          ];
          const filter = (datasetId: string, dataset: IDataset) =>
            !!(
              !allDatasetIds.includes(datasetId) &&
              state.detailLevel !== null &&
              (dataset.detailsLevels || []).includes(state.detailLevel)
            );

          const filteredCatalog: ICategoryProvider = getFilteredCatalog(catalog, filter);

          return {
            ...state,
            additionalDatasetCatalog: filteredCatalog
          };
        }
        case DATASET_MV_ADDITIONAL_DATASET_STRUCTURE_FETCH: {
          return {
            ...state,
            additionalDataset: {
              ...state.additionalDataset,
              dimensions: action.payload.response?.criteria || [],
              timeDim: action.payload.response.timeDimension,
              freqDim: action.payload.response.freqDimension,
              territoryDim: action.payload.response.territorialDimension,
              codelists: null,
              codelistsLength: null,
              criteria: {},
              detailLevels:
                state.additionalDatasetCatalog && state.additionalDataset
                  ? state.additionalDatasetCatalog.datasetMap[state.additionalDataset.datasetId]
                    ? state.additionalDatasetCatalog.datasetMap[state.additionalDataset.datasetId].detailsLevels
                    : state.additionalDatasetCatalog.uncategorizedDatasets.find(
                        (dataset: any) => dataset.identifier === state.additionalDataset?.datasetId
                      )?.detailsLevels || []
                  : [],
              optimizedData: action.payload.response.optimizedData
            }
          };
        }
        case DATASET_MV_STRUCTURE_CODELIST_FULL_FETCH: {
          const codelist = (action.payload.response.criteria[0] || {}).values || [];
          return {
            ...state,
            missingFilterValues: codelist.filter((code: any) =>
              action.payload.extra.missingFilterValueIds.includes(code.id)
            )
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DATASET_MV_DETAIL_LEVELS_FETCH: {
          return {
            ...state,
            detailLevelTree: [],
            detailLevel: null
          };
        }
        case DATASET_MV_TERRITORIAL_CLASSIFICATIONS_FETCH: {
          return {
            ...state,
            territorialClassifications: []
          };
        }
        case DATASET_MV_STRUCTURE_CODELIST_FETCH: {
          const isTooLongQuery = action.payload.statusCode === 414;

          return {
            ...state,
            isTooLongQuery: isTooLongQuery,
            isCriteriaAlertVisible: isTooLongQuery,
            codelistFetchError: true
          };
        }
        case DATASET_MV_DOWNLOAD_SUBMIT: {
          return {
            ...state,
            isDownloadWarningVisible: action.payload.statusCode === 406
          };
        }
        case DATASET_MV_VIEW_TEMPLATE_SUBMIT: {
          return {
            ...state,
            isViewErrorVisible: !!(
              action.payload.extra.isView &&
              action.payload.statusCode === 409 &&
              action.payload.response
            ),
            viewErrorMessage: action.payload.response
          };
        }
        case DATASET_MV_SDMX_QUERY_FETCH: {
          return {
            ...state,
            isQueryVisible: false,
            structureQuery: null,
            dataQuery: null
          };
        }
        case DATASET_MV_INDICATOR_PREVIEW_FETCH: {
          return {
            ...state,
            indicatorPreview: ""
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default datasetMVReducer;
