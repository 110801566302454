import {ViewerMode} from "../../state/dataset/constants";
import {setDatasetViewerMode} from "../../state/dataset/datasetActions";
import {
  clearDatasetMVPState,
  DATASET_MVP_ADDITIONAL_DATASET_SUBMIT,
  DATASET_MVP_INDICATOR_PUBLISH
} from "../../state/dataset/multi-viewer-plus/actions";

const multiViewerPlusMiddleware =
  ({dispatch}) =>
  next =>
  action => {
    const res = next(action);
    if (action.type === DATASET_MVP_INDICATOR_PUBLISH || action.type === DATASET_MVP_ADDITIONAL_DATASET_SUBMIT) {
      dispatch(setDatasetViewerMode(ViewerMode.MultiViewer));
      dispatch(clearDatasetMVPState());
    }

    return res;
  };

export default multiViewerPlusMiddleware;
