import React from "react";
import Grid from "@mui/material/Grid";
import SanitizedHTML from "../sanitized-html";
import {getAttributeLabel, getAttributeValueLabel} from "../../utils/dataset";
import {Box} from "@mui/material";

const DimensionAttributeList = ({
  dimension,
  dimValuesAttributeList,
  labelFormat,
  style,
  getDimValueLabel,
  textColor
}) => (
  <Box style={{...style}}>
    <Grid container spacing={2}>
      {(dimValuesAttributeList || []).map(({dimVal, attributes}) => (
        <Grid item key={dimVal} xs={12}>
          <Grid container spacing={1}>
            {dimension && (dimValuesAttributeList || []).length > 1 && (
              <Grid item key={dimVal} xs={12}>
                <b>
                  <u>{getDimValueLabel(dimVal) + ":"}</u>
                </b>
              </Grid>
            )}
            {(attributes || []).map((attribute, idx) => (
              <Grid item key={dimVal + idx} xs={12}>
                <SanitizedHTML
                  html={`- <b>${getAttributeLabel(attribute, labelFormat)}</b>: ${getAttributeValueLabel(
                    attribute,
                    labelFormat
                  )}`}
                  allowTarget
                  sx={{
                    color: textColor
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default DimensionAttributeList;
