import {
  getCreateTemplateUrl,
  getCreateViewUrl,
  getDatasetCustomDownloadUrl,
  getDatasetDownloadUrl,
  getDatasetSdmxQueryUrl,
  getDatasetStructureCodelistsUrl,
  getDatasetStructureCodelistUrl,
  getDatasetUrl
} from "../../../serverApi/urls";
import {Criteria} from "../constants";
import {initRequest, RequestMethod} from "../../../middlewares/request/requestActions";
import {getCriteriaArrayFromObject} from "../../../utils/criteria";
import {
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  downloadFormats,
  getCustomExportRequestBody
} from "../../../utils/download";

export const DATASET_SVP_STATE_BACKUP = "dataset/svp/state/backup";

export const DATASET_SVP_STRUCTURE_FETCH_INIT = "dataset/svp/structure/fetch/init";
export const DATASET_SVP_STRUCTURE_FETCH_SUCCESS = "dataset/svp/structure/fetch/success";
export const DATASET_SVP_STRUCTURE_FETCH_ERROR = "dataset/svp/structure/fetch/error";

export const DATASET_SVP_CODELISTS_FETCH = "dataset/svp/codelists/fetch";

export const DATASET_SVP_DATASET_FETCH_ENABLE = "dataset/svp/dataset/fetch/enable";
export const DATASET_SVP_DATASET_FETCH = "dataset/svp/dataset/fetch";

export const DATASET_SVP_CRITERIA_SHOW = "dataset/svp/criteria/show";
export const DATASET_SVP_CRITERIA_HIDE = "dataset/svp/criteria/hide";
export const DATASET_SVP_CRITERIA_ALERT_HIDE = "dataset/svp/criteria/alert/hide";
export const DATASET_SVP_STRUCTURE_CRITERIA_SET = "dataset/svp/structure/criteria/set";

export const DATASET_SVP_VIEWER_SET = "dataset/svp/viewer/set";

export const DATASET_SVP_LAYOUT_SHOW = "dataset/svp/layout/show";
export const DATASET_SVP_LAYOUT_HIDE = "dataset/svp/layout/hide";
export const DATASET_SVP_TABLE_LAYOUT_SUBMIT = "dataset/svp/table/layout/submit";
export const DATASET_SVP_MAP_LAYOUT_SUBMIT = "dataset/svp/map/layout/submit";
export const DATASET_SVP_MAP_DETAIL_LEVEL_SET = "dataset/svp/map/detail/level/set";
export const DATASET_SVP_CHART_LAYOUT_SUBMIT = "dataset/svp/chart/layout/submit";

export const DATASET_SVP_LABEL_FORMAT_SET = "dataset/svp/label/format/set";
export const DATASET_SVP_VARIATION_SET = "dataset/svp/variation/set";
export const DATASET_SVP_TEMPORAL_DIM_ORDER_SET = "dataset/svp/temporalDimOrder/set";

export const DATASET_SVP_TABLE_SETTINGS_SET = "dataset/svp/table/settings/set";

export const DATASET_SVP_CHART_SETTINGS_SET = "dataset/svp/chart/settings/set";
export const DATASET_SVP_MAP_SETTINGS_SET = "dataset/svp/map/settings/set";

export const DATASET_SVP_DOWNLOAD_SUBMIT = "dataset/svp/download/submit";
export const DATASET_SVP_DOWNLOAD_WARNING_HIDE = "dataset/svp/download/warning/hide";

export const DATASET_SVP_VIEW_TEMPLATE_SHOW = "dataset/svp/view/template/show";
export const DATASET_SVP_VIEW_TEMPLATE_HIDE = "dataset/svp/view/template/hide";
export const DATASET_SVP_VIEW_TEMPLATE_SUBMIT = "dataset/svp/view/template/submit";
export const DATASET_SVP_VIEW_ERROR_HIDE = "dataset/svp/view/error/hide";

export const DATASET_SVP_UNAVAILABLE_VIEW_WARNING_HIDE = "dataset/svp/unavailable/view/warning/hide";

export const DATASET_SVP_HTML_GENERATING_TIME_SET = "dataset/svp/html/generating/time/set";

export const DATASET_SVP_SDMX_QUERY_SHOW = "dataset/svp/sdmx/query/show";
export const DATASET_SVP_SDMX_QUERY_HIDE = "dataset/svp/sdmx/query/hide";
export const DATASET_SVP_SDMX_QUERY_FETCH = "dataset/svp/sdmx/query/fetch";

export const DATASET_SVP_STRUCTURE_CODELIST_FULL_FETCH = "dataset/svp/structure/codelist/full/fetch";
export const DATASET_SVP_STRUCTURE_CODELIST_FULL_HIDE = "dataset/svp/structure/codelist/full/hide";

export const DATASET_SVP_SET_FULLSCREEN = "dataset/svp/set/fullscreen";

export const DATASET_SVP_HIERARCHY_SET = "dataset/svp/hierarchy/set";

export const backupDatasetSVPState = (datasetUuid: string) => ({
  type: DATASET_SVP_STATE_BACKUP,
  datasetUuid
});

export const fetchDatasetSVPStructureInit = () => ({
  type: DATASET_SVP_STRUCTURE_FETCH_INIT
});

export const fetchDatasetSVPStructureSuccess = (datasetUuid: string, structure: any, viewId: string | null) => ({
  type: DATASET_SVP_STRUCTURE_FETCH_SUCCESS,
  payload: {
    datasetUuid,
    structure,
    viewId
  }
});

export const fetchDatasetSVPStructureError = () => ({
  type: DATASET_SVP_STRUCTURE_FETCH_ERROR
});

export const fetchDatasetSVPCodelists = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  freq: string,
  defaultLastNPeriods: number
) => {
  return initRequest(
    DATASET_SVP_CODELISTS_FETCH,
    getDatasetStructureCodelistsUrl(nodeId, datasetId),
    undefined,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingCodelists")
    }),
    {
      nodeCode: nodeCode,
      datasetId: datasetId,
      defaultLastNPeriods: defaultLastNPeriods,
      freq: freq
    }
  );
};

export const enableDatasetSVPDatasetFetch = (limit?: number) => ({
  type: DATASET_SVP_DATASET_FETCH_ENABLE,
  limit
});

export const fetchDatasetSVPDataset = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria},
  lastNObservations: number | null
) =>
  initRequest(
    DATASET_SVP_DATASET_FETCH,
    getDatasetUrl(nodeId, datasetId, lastNObservations),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDataset", {title: datasetTitle})
    }),
    undefined,
    undefined,
    (statusCode: number) => statusCode === 413 || statusCode === 414
  );

export const showDatasetSVPCriteria = (initialCriteriaDimension?: string) => ({
  type: DATASET_SVP_CRITERIA_SHOW,
  initialCriteriaDimension
});

export const hideDatasetSVPCriteria = () => ({
  type: DATASET_SVP_CRITERIA_HIDE
});

export const hideDatasetSVPCriteriaAlert = () => ({
  type: DATASET_SVP_CRITERIA_ALERT_HIDE
});

export const setDatasetSVPStructureCriteria = (criteria: {[key: string]: Criteria}) => ({
  type: DATASET_SVP_STRUCTURE_CRITERIA_SET,
  criteria
});

export const setDatasetSVPViewer = (viewerIdx: number) => ({
  type: DATASET_SVP_VIEWER_SET,
  viewerIdx
});

export const showDatasetSVPLayout = () => ({
  type: DATASET_SVP_LAYOUT_SHOW
});

export const hideDatasetSVPLayout = () => ({
  type: DATASET_SVP_LAYOUT_HIDE
});

export const submitDatasetSVPTableLayout = (layout: any, enableFetch?: boolean) => ({
  type: DATASET_SVP_TABLE_LAYOUT_SUBMIT,
  layout,
  enableFetch
});

export const submitDatasetSVPMapLayout = (layout: any, enableFetch?: boolean) => ({
  type: DATASET_SVP_MAP_LAYOUT_SUBMIT,
  layout,
  enableFetch
});

export const setDatasetSVPMapDetailLevel = (detailLevel: number) => ({
  type: DATASET_SVP_MAP_DETAIL_LEVEL_SET,
  detailLevel
});

export const submitDatasetSVPChartLayout = (layout: any, enableFetch?: boolean) => ({
  type: DATASET_SVP_CHART_LAYOUT_SUBMIT,
  layout,
  enableFetch
});

export const setDatasetSVPLabelFormat = (labelFormat: string) => ({
  type: DATASET_SVP_LABEL_FORMAT_SET,
  labelFormat
});

export const setDatasetSVPVariation = (variation: {[key: string]: boolean}) => ({
  type: DATASET_SVP_VARIATION_SET,
  variation
});

export const setDatasetSVPTemporalDimOrder = (temporalDimOrder: string) => ({
  type: DATASET_SVP_TEMPORAL_DIM_ORDER_SET,
  temporalDimOrder
});

export const setDatasetSVPTableSettings = (tableSettings: any) => ({
  type: DATASET_SVP_TABLE_SETTINGS_SET,
  tableSettings
});

export const setDatasetSVPChartSettings = (chartSettings: any) => ({
  type: DATASET_SVP_CHART_SETTINGS_SET,
  chartSettings
});

export const setDatasetSVPMapSettings = (mapSettings: any) => ({
  type: DATASET_SVP_MAP_SETTINGS_SET,
  mapSettings
});

export const submitDatasetSVPDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria},
  lastNObservations: number | null,
  layout: any,
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any
) => {
  let url, body;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetCustomDownloadUrl(nodeId, format, zipped, lastNObservations);
    body = getCustomExportRequestBody(
      nodeId,
      datasetId,
      datasetTitle,
      criteria,
      layout,
      params,
      defaultLanguage,
      languages,
      t
    );
  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped, lastNObservations);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL ? "application/vnd.ms-excel" : "text/plain;charset=utf-8";

  return initRequest(
    DATASET_SVP_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: extension,
        type: type
      }
    },
    undefined,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

export const hideDatasetSVPDownloadWarning = () => ({
  type: DATASET_SVP_DOWNLOAD_WARNING_HIDE
});

export const showDatasetSVPViewTemplate = (isView: boolean) => ({
  type: DATASET_SVP_VIEW_TEMPLATE_SHOW,
  isView
});

export const hideDatasetSVPViewTemplate = (isView: boolean) => ({
  type: DATASET_SVP_VIEW_TEMPLATE_HIDE,
  isView
});

export const submitDatasetSVPViewTemplate = (nodeId: number, viewTemplate: any, isView: boolean) =>
  initRequest(
    DATASET_SVP_VIEW_TEMPLATE_SUBMIT,
    isView ? getCreateViewUrl(nodeId) : getCreateTemplateUrl(nodeId),
    RequestMethod.POST,
    viewTemplate,
    t => ({
      onStart: isView ? t("scenes.dataset.actions.savingView") : t("scenes.dataset.actions.savingTemplate")
    }),
    {
      isView: isView
    },
    undefined,
    (statusCode: number) => statusCode === 409
  );

export const hideDatasetSVPViewError = () => ({
  type: DATASET_SVP_VIEW_ERROR_HIDE
});

export const hideDatasetSVPUnavailableViewWarning = () => ({
  type: DATASET_SVP_UNAVAILABLE_VIEW_WARNING_HIDE
});

export const setDatasetSVPHtmlGeneratingTime = (time: number) => ({
  type: DATASET_SVP_HTML_GENERATING_TIME_SET,
  time
});

export const showDatasetSVPSdmxQuery = () => ({
  type: DATASET_SVP_SDMX_QUERY_SHOW
});

export const hideDatasetSVPSdmxQuery = () => ({
  type: DATASET_SVP_SDMX_QUERY_HIDE
});

export const fetchDatasetSVPSdmxQuery = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  lastNObservations: number | null,
  datasetTitle: string
) =>
  initRequest(
    DATASET_SVP_SDMX_QUERY_FETCH,
    getDatasetSdmxQueryUrl(nodeId, datasetId, lastNObservations),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDatasetSdmxQuery", {title: datasetTitle})
    })
  );

export const fetchDatasetSVPStructureCodelistFull = (
  nodeId: number,
  datasetId: string,
  dimensionId: string,
  missingFilterValueIds: string[]
) =>
  initRequest(
    DATASET_SVP_STRUCTURE_CODELIST_FULL_FETCH,
    getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, true),
    undefined,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingCodelist")
    }),
    {
      missingFilterValueIds: missingFilterValueIds
    }
  );

export const hideDatasetSVPStructureCodelistFull = () => ({
  type: DATASET_SVP_STRUCTURE_CODELIST_FULL_HIDE
});

export const setDatasetSVPFullScreen = (isFullscreen: boolean) => ({
  type: DATASET_SVP_SET_FULLSCREEN,
  isFullscreen
});

export const setDatasetSVPHierarchy = (dimensionId: string, hierarchyId: string, tabId: number) => ({
  type: DATASET_SVP_HIERARCHY_SET,
  dimensionId,
  hierarchyId,
  tabId
});
