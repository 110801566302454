import React, {useMemo} from "react";
import {Checkbox, FormControlLabel, Grid, MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Selector from "../selector";
import {useItemContainerBuilder} from "./useItemContainerBuilder";
import {getDimensionsFromLayout} from "../../utils/dataset";
import {
  ITEMCONTAINER_ELEM_ENABLE_FILTERS_KEY,
  ITEMCONTAINER_ELEM_SHOW_TITLE_KEY,
  ITEMCONTAINER_ELEM_VALUE_KEY
} from "../../utils/itemContainers";

const inputStyle = {
  width: "100%",
  "& > div": {
    width: "100%"
  },
  "& input": {
    padding: "12px",
    height: "20px"
  }
};

const formLabelStyle = {
  marginLeft: "0px",
  marginRight: "0px"
};

export default function ViewTemplateItem({
  col,
  rowIdx,
  colIdx,
  onShowTitleChange,
  onEnableFiltersChange,
  fetchViews,
  onViewReset
}) {
  const initialValue = col?.viewTemplate?.title?.en ? col.viewTemplate?.title.en : col.viewTemplate?.title.it;
  const {handleFilterDimensionChange, asDashboard} = useItemContainerBuilder();
  const {t} = useTranslation();

  const layout = useMemo(() => {
    const layoutsObj = JSON.parse(col?.viewTemplate?.layouts ?? "{}");
    return (
      layoutsObj?.tableLayout ||
      layoutsObj?.mapLayout ||
      layoutsObj?.chartLayoutCartesian ||
      layoutsObj?.chartLayoutRadial ||
      layoutsObj?.chartLayout ||
      {}
    );
  }, [col?.viewTemplate?.layouts]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: 8
        }}
      >
        <Selector
          value={col[ITEMCONTAINER_ELEM_VALUE_KEY] ? col[ITEMCONTAINER_ELEM_VALUE_KEY] : null}
          render={() => (col[ITEMCONTAINER_ELEM_VALUE_KEY] != null ? initialValue : "")}
          sx={inputStyle}
          selectTitle={t("components.itemContainerBuilder.actions.selectView")}
          onSelect={() => {
            fetchViews(rowIdx, colIdx);
          }}
          resetTitle={t("components.itemContainerBuilder.actions.deselectView")}
          onReset={() => {
            onViewReset(rowIdx, colIdx, col[ITEMCONTAINER_ELEM_VALUE_KEY]);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            style={{
              marginRight: 8
            }}
          >
            <FormControlLabel
              sx={formLabelStyle}
              label={
                <Typography
                  style={{
                    fontSize: 14
                  }}
                >
                  {t("components.itemContainerBuilder.actions.showTitle") + ":"}
                </Typography>
              }
              labelPlacement="start"
              control={
                <Checkbox
                  style={{
                    padding: 5
                  }}
                  checked={col[ITEMCONTAINER_ELEM_SHOW_TITLE_KEY]}
                  onChange={(ev, checked) => onShowTitleChange(rowIdx, colIdx, checked)}
                />
              }
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              sx={formLabelStyle}
              label={
                <Typography
                  style={{
                    fontSize: 14
                  }}
                >
                  {t("components.itemContainerBuilder.actions.enableFilters") + ":"}
                </Typography>
              }
              labelPlacement="start"
              style={{
                fontSize: 14
              }}
              control={
                <Checkbox
                  style={{
                    padding: 5
                  }}
                  checked={col[ITEMCONTAINER_ELEM_ENABLE_FILTERS_KEY]}
                  onChange={(ev, checked) => onEnableFiltersChange(rowIdx, colIdx, checked)}
                />
              }
            />
          </Grid>
        </Grid>
        {asDashboard && (
          <>
            <Grid item xs={12} style={{marginTop: 8}}>
              {t("components.dashboardBuilder.filterDimension.label") + ":"}
            </Grid>
            <Grid item xs={12}>
              <Select
                value={col.filterDimension || ""}
                placeholder={t("components.dashboardBuilder.filterDimension.noOne")}
                sx={inputStyle}
                variant="outlined"
                onChange={({target}) => handleFilterDimensionChange(rowIdx, colIdx, target.value as string)}
              >
                <MenuItem key={-1} value={""}>
                  {t("components.map.layers.none")}
                </MenuItem>
                {getDimensionsFromLayout(layout).map((dim, idx) => (
                  <MenuItem key={idx} value={dim}>
                    {dim}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
