import React, {useEffect, useState} from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import SanitizedHTML from "../sanitized-html";

export const ERROR_SNACKBAR_SEVERITY_ERROR = "error";
export const ERROR_SNACKBAR_SEVERITY_WARNING = "warning";
export const ERROR_SNACKBAR_SEVERITY_SUCCESS = "success";

const ErrorSnackbar = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState(null);

  useEffect(() => {
    if (!isInitialized) {
      window.error = {
        show: (message, severity) => {
          setIsVisible(true);
          setMessage(message);
          setSeverity(severity);
        }
      };
      setIsInitialized(true);
    }
  }, [isInitialized]);

  return (
    <Snackbar
      open={isVisible}
      autoHideDuration={5000}
      onClose={() => {
        setIsVisible(false);
      }}
      TransitionProps={{
        onExited: () => {
          setMessage(null);
          setSeverity(null);
        }
      }}
      ClickAwayListenerProps={{
        onClickAway: () => {}
      }}
      anchorOrigin={{vertical: "top", horizontal: "center"}}
    >
      <Alert severity={severity || ERROR_SNACKBAR_SEVERITY_ERROR}>
        <SanitizedHTML component="span" html={message} />
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
