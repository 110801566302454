import ZoomInIcon from "@mui/icons-material/ZoomIn";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";

const ChartZoomIn = ({id = undefined, sx = {}, color = "primary", onClick}) => {
  const {t} = useTranslation();

  return (
    <Tooltip title={t("components.chart.zoomIn.title")}>
      <IconButton id={id} sx={sx} aria-label={t("components.chart.zoomIn.label")} onClick={onClick}>
        <ZoomInIcon color={color} />
      </IconButton>
    </Tooltip>
  );
};

export default ChartZoomIn;
