import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {compose} from "redux";
import IndicatorCreate from "./IndicatorCreate";
import {getDimensionLabelFromCombinations, getDimensionValueLabelFromCombinations} from "../../../utils/dataset";
import {getStrFromIndex} from "./utils";

const mapStateToProps = ({config, app, node}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  defaultLanguage: app.language,
  languages: app.languages,
  nodeId: node.nodeId,
  nodeCode: node.code
});

const IndicatorCreateCombinationsWrapper = ({
  indicatorsBaseUrl,
  defaultLanguage,
  languages,
  nodeId,
  nodeCode,

  isOpen,
  onClose,
  datasetId,
  combinations,
  territoryDim,
  timeDim,
  dimensionMap,
  dimensionLabels,
  criteria,
  indicators,
  indicatorPreview,
  additionalDatasets,

  onPreviewFetch,
  onPreviewReset,
  onPublish
}) => {
  const [variables, setVariables] = useState(null);
  const [variablesDataset, setVariablesDataset] = useState(null);

  const years = useMemo(() => {
    let orderedYears = [];
    if (dimensionMap && timeDim && dimensionMap[timeDim]) {
      orderedYears = _.clone(Object.keys(dimensionMap[timeDim])).sort();
    }
    return orderedYears;
  }, [dimensionMap, timeDim]);

  useEffect(() => {
    if (combinations) {
      const variableDatasetId = `${nodeCode},${datasetId}`;

      const variables = {};
      const variableDatasetMap = {};

      const letters = [];
      combinations.forEach((combination, idx) => {
        const noTimeCombinationStr = Object.keys(combination)
          .filter(dim => dim !== timeDim)
          .map(dim => combination[dim])
          .join(",");
        if (!letters.includes(noTimeCombinationStr)) {
          letters.push(noTimeCombinationStr);
        }
        const variable = combination[timeDim] + getStrFromIndex(letters.indexOf(noTimeCombinationStr));
        variables[variable] = combination;
        variableDatasetMap[variable] = variableDatasetId;
      });

      setVariables(variables);
      setVariablesDataset(variableDatasetMap);
    }
  }, [combinations, nodeCode, datasetId, timeDim]);

  return (
    combinations && (
      <IndicatorCreate
        indicatorsBaseUrl={indicatorsBaseUrl}
        defaultLanguage={defaultLanguage}
        languages={languages}
        nodeId={nodeId}
        nodeCode={nodeCode}
        isOpen={isOpen}
        onClose={onClose}
        datasetId={datasetId}
        territoryDim={territoryDim}
        timeDim={timeDim}
        criteria={criteria}
        indicators={indicators}
        indicatorPreview={indicatorPreview}
        additionalDatasets={additionalDatasets}
        years={years}
        combinations={combinations}
        variables={variables}
        variablesDataset={variablesDataset}
        getDimLabel={(datasetId, dim) => getDimensionLabelFromCombinations(dim, dimensionLabels)}
        getDimValueLabel={(datasetId, dimId, valueId) =>
          getDimensionValueLabelFromCombinations(dimId, valueId, dimensionMap)
        }
        onPreviewFetch={onPreviewFetch}
        onPreviewReset={onPreviewReset}
        onPublish={onPublish}
      />
    )
  );
};

export default compose(connect(mapStateToProps))(IndicatorCreateCombinationsWrapper);
