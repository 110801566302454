import {NodeDashboardSharePermission} from "../modules/dashboard/constants";
import {UserPermissions, UserRoles, UserState} from "../state/user/userReducer";

export type UserChecker = (user: UserState, nodeId?: number) => boolean;
export type UserNodeChecker = (user: UserState, nodeId: number) => boolean;

const getPermissionStr = (permission: UserPermissions, nodeId: number) => `${permission}_SingleNode_${nodeId}`;
export const getGenericPermissionStr = (permission: string, nodeId: number) => `${permission}_SingleNode_${nodeId}`;

export const getIsUserAuthenticated: UserChecker = user => user && user.isAuthenticated;

export const canDisplayAppSettingsForm: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canCreateNode: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canSortNodes: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canDisplayNodesSettingsForm: UserChecker = user =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    !!user.permissions.find(p => p.startsWith(`${UserPermissions.ManageCache}_SingleNode`)) ||
    !!user.permissions.find(p => p.startsWith(`${UserPermissions.ManageTemplate}_SingleNode`)) ||
    !!user.permissions.find(p => p.startsWith(`${UserPermissions.ManageConfig}_SingleNode`)));

export const canDisplayUsersSettingsForm: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canDisplayCacheSettingForm: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageCache, nodeId)));

export const canDisplayTemplatesSettingsForm: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageTemplate, nodeId)));

export const canSaveTemplate: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageTemplate, nodeId)));

export const canEditNode: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageConfig, nodeId)));

export const canDisplayUserViews: UserChecker = user => getIsUserAuthenticated(user);

export const canSaveAsView: UserChecker = user => getIsUserAuthenticated(user);

export const canClearServerCache: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canGetQueryLog: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canDisplayPermissionsSettingsForm: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canManagePersonalCustomPages: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canManageCustomPages: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canManageFilters: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canManageNodeCustomPages: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageConfig, nodeId)));

export const canManagePersonalDashboards: UserChecker = user =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    !!user.permissions.find(p => p.startsWith(`${UserPermissions.ManageConfig}_SingleNode`)) ||
    !!user.permissions.find(p => p.startsWith(`${NodeDashboardSharePermission}_SingleNode`)));

export const canManageAppDashboards: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canManageAppCustomPages: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canManageNodeDashboards: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageConfig, nodeId)));

export const canDeleteNodes: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);

export const canViewTimesLog: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageConfig, nodeId)));

export const canManageCache: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageCache, nodeId)));

export const canViewTemplateOrAnnotationIcon: UserNodeChecker = (user, nodeId) =>
  getIsUserAuthenticated(user) &&
  (user.roles.includes(UserRoles.Administrator) ||
    user.permissions.includes(getPermissionStr(UserPermissions.ManageTemplate, nodeId)));

export const canUseDatasetTools: UserChecker = user => getIsUserAuthenticated(user);

export const canSetMachineToMachinePassword: UserChecker = user =>
  getIsUserAuthenticated(user) && user.roles.includes(UserRoles.Administrator);
