import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@mui/icons-material/GetApp";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import {Box, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {NodeVisibility} from "../../../model/IHubMinimalNode.d.ts";
import CustomDialogTitle from "../../custom-dialog-title";
import MaterialTable from "../../material-table/index.jsx";
import ModulesPlaceholder from "../../modules-placeholder/index.jsx";
import SettingsDialog from "../../settings-dialog";
import AdvancedConfigurationForm from "./advanced-configuration-form/index.tsx";
import CacheSettingsForm from "./cache-setting-form/index";
import DashboardsSettingsForm from "./dashboards-settings-form";
import MergedNodeSettingsForm from "./merged-nodes-setting-form";
import NodeExportForm from "./node-export-form";
import NodeImportDialog from "./node-import-form";
import NodeSettingsForm from "./node-settings-form";
import PermissionsSettingsForm from "./permissions-settings-form";
import TemplatesSettingsForm from "./templates-settings-form";
import {
  clearNodesConfigNodes,
  closeNodesConfig,
  deleteNodesConfigNode,
  fetchNodesConfigNodes,
  sendNodesConfigNodesOrder,
  setNodesConfigMergedNodeDialogVisibility,
  setNodesConfigMergedNodeId,
  setNodesConfigNodeId,
  setNodesConfigNodeVisibility,
  submitNodeConfigsNodeDashboards
} from "../../../state/nodesConfig/nodesConfigActions";
import {localizeI18nObj} from "../../../utils/i18n";
import {
  canCreateNode,
  canDeleteNodes,
  canDisplayCacheSettingForm,
  canDisplayPermissionsSettingsForm,
  canDisplayTemplatesSettingsForm,
  canEditNode,
  canManageNodeDashboards,
  canSortNodes
} from "../../../utils/user";
import "./style.css";

const mapStateToProps = state => ({
  config: state.nodesConfig.nodes,
  isNodeVisible: state.nodesConfig.isNodeVisible,
  nodeId: state.nodesConfig.nodeId,
  isMergedNodeDialogVisible: state.nodesConfig.isMergedNodeDialogVisible,
  mergedNodeId: state.nodesConfig.mergedNodeId,
  user: state.user,
  language: state.app.language,
  languages: state.app.languages,
  modulesConfig: state.app.modulesConfig
});

const mapDispatchToProps = dispatch => ({
  setNodeVisibility: visible => dispatch(setNodesConfigNodeVisibility(visible)),
  setNodeId: nodeId => dispatch(setNodesConfigNodeId(nodeId)),
  fetchConfig: () => dispatch(fetchNodesConfigNodes()),
  sendOrders: orderedIds => dispatch(sendNodesConfigNodesOrder(orderedIds)),
  deleteNode: nodeId => dispatch(deleteNodesConfigNode(nodeId)),
  clearConfig: () => dispatch(clearNodesConfigNodes()),
  onClose: () => dispatch(closeNodesConfig()),
  setMergedNodeId: nodeId => dispatch(setNodesConfigMergedNodeId(nodeId)),
  setMergedNodeDialogVisibility: visible => dispatch(setNodesConfigMergedNodeDialogVisibility(visible)),
  submitNodeDashboards: (nodeId, data) => dispatch(submitNodeConfigsNodeDashboards(nodeId, data))
});

const NodesSettingsForm = (
  {
    defaultNodeOpen,
    config,
    isNodeVisible,
    nodeId,
    setNodeVisibility,
    setNodeId,
    fetchConfig,
    sendOrders,
    deleteNode,
    clearConfig,
    nodes,
    user,
    onClose,
    language,
    languages,
    modulesConfig,
    onNodesClose,
    isMergedNodeDialogVisible,
    mergedNodeId,
    setMergedNodeId,
    setMergedNodeDialogVisibility,
    submitNodeDashboards
  },
  ref
) => {
  const [needConfig, setNeedConfig] = useState(true);
  const {t} = useTranslation();

  const theme = useTheme();

  const buttonActionStyle = {
    ...theme.typography.button
  };

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig();
    }
  }, [config, needConfig, setNeedConfig, fetchConfig]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      clearConfig();
      onClose();
      f();
    }
  }));

  const [nodeDeleteFormNodeId, setNodeDeleteFormNodeId] = useState(null);
  const [cacheFormNodeId, setCacheFormNodeId] = useState(null);
  const [isCacheFormVisible, setCacheFormVisibility] = useState(false);
  const [templatesNodeId, setTemplatesNodeId] = useState(null);
  const [permissionsNodeId, setPermissionsNodeId] = useState(null);
  const [dashboardsNodeId, setDashboardsNodeId] = useState(null);
  const [isImportDialog, setImportDialog] = useState(false);
  const [exportNodeFormNodeId, setExportNodeFormNodeId] = useState(null);
  const [additionalSettingsNodeId, setAdditionalSettingsNodeId] = useState(null);

  const exportRef = useRef();
  const nodeFormRef = useRef();
  const cacheFormRef = useRef();
  const templatesRef = useRef();
  const permissionsRef = useRef();
  const dashboardsRef = useRef();
  const mergedNodeFormRef = useRef();

  const allowedNodes = (config || []).filter(
    ({nodeId}) =>
      canCreateNode(user) ||
      canSortNodes(user) ||
      canDisplayCacheSettingForm(user, nodeId) ||
      canDisplayTemplatesSettingsForm(user, nodeId) ||
      canDisplayPermissionsSettingsForm(user) ||
      canEditNode(user, nodeId) ||
      canDeleteNodes(user)
  );
  const orderedNodes = (allowedNodes || []).sort((a, b) => a.order - b.order);
  const orderedNodesIds = orderedNodes.map(({nodeId}) => nodeId);

  const moveUp = rowIndex =>
    sendOrders([
      ...orderedNodesIds.slice(0, rowIndex - 1),
      orderedNodesIds[rowIndex],
      orderedNodesIds[rowIndex - 1],
      ...orderedNodesIds.slice(rowIndex + 1)
    ]);

  const moveDown = rowIndex =>
    sendOrders([
      ...orderedNodesIds.slice(0, rowIndex),
      orderedNodesIds[rowIndex + 1],
      orderedNodesIds[rowIndex],
      ...orderedNodesIds.slice(rowIndex + 2)
    ]);

  const handleEditNode = (nodeId, type) => {
    if (type === "SDMX-REST") {
      setMergedNodeDialogVisibility(false);
      setMergedNodeId(null);
      setNodeId(nodeId);
      setNodeVisibility(true);
    } else {
      setNodeId(null);
      setNodeVisibility(false);
      setMergedNodeDialogVisibility(true);
      setMergedNodeId(nodeId);
    }
    setCacheFormVisibility(false);
  };

  return (
    config && (
      <Fragment>
        <Box className="nodes-settings-form__table" sx={{height: "100%"}} id="nodes-table">
          <MaterialTable
            idKey="nodeId"
            rightActions={[
              canCreateNode(user)
                ? {
                    id: "nodes-table__right-actions__import-node",
                    label: t("scenes.nodesSettings.importNode"),
                    startIcon: <AddIcon />,
                    onClick: () => {
                      setImportDialog(true);
                    }
                  }
                : null,
              canCreateNode(user)
                ? {
                    id: "nodes-table__right-actions__create-node",
                    label: t("scenes.nodesSettings.createNode"),
                    startIcon: <AddIcon />,
                    onClick: () => {
                      setNodeId(null);
                      setNodeVisibility(true);
                      setCacheFormVisibility(false);
                    }
                  }
                : null,
              canCreateNode(user)
                ? {
                    id: "nodes-table__right-actions__create-merged-node",
                    label: t("scenes.nodesSettings.createMergedNode"),
                    startIcon: <AddIcon />,
                    onClick: () => {
                      setMergedNodeId(null);
                      setMergedNodeDialogVisibility(true);
                      setCacheFormVisibility(false);
                    }
                  }
                : null
            ]}
            columns={[
              {
                field: "code",
                title: t("scenes.nodesSettings.table.columns.nodeCode"),
                getClassName: () => "nodes-table__columns__nodeCode"
              },
              {
                field: "title",
                title: t("scenes.nodesSettings.table.columns.nodeName"),
                render: ({title}) => localizeI18nObj(title, language, languages),
                getClassName: () => "nodes-table__columns__nodeName"
              },
              {
                field: "active",
                title: t("scenes.nodesSettings.table.columns.isNodeActive.title"),
                render: ({active}) =>
                  active
                    ? t("scenes.nodesSettings.table.columns.isNodeActive.values.true")
                    : t("scenes.nodesSettings.table.columns.isNodeActive.values.false"),
                getClassName: ({active}) =>
                  `nodes-table__columns__isNodeActive ${active ? "nodes-table__columns__isNodeActive--true" : "nodes-table__columns__isNodeActive--false"}`
              },
              {
                field: "visible",
                title: t("scenes.nodesSettings.table.columns.isNodeVisible.title"),
                render: ({visible}) =>
                  visible === NodeVisibility.Yes
                    ? t("scenes.nodesSettings.table.columns.isNodeVisible.values.true")
                    : t("scenes.nodesSettings.table.columns.isNodeVisible.values.false"),
                getClassName: ({visible}) =>
                  `nodes-table__columns__isNodeVisible ${visible ? "nodes-table__columns__isNodeVisible--true" : "nodes-table__columns__isNodeVisible--false"}`
              },
              {
                field: "default",
                title: t("scenes.nodesSettings.table.columns.isNodeDefault.title"),
                render: node =>
                  node.default
                    ? t("scenes.nodesSettings.table.columns.isNodeDefault.values.true")
                    : t("scenes.nodesSettings.table.columns.isNodeDefault.values.false"),
                getClassName: node =>
                  `nodes-table__columns__isNodeDefault ${node.default ? "nodes-table__columns__isNodeDefault--true" : "nodes-table__columns__isNodeDefault--false"}`
              }
            ]}
            data={orderedNodes}
            actions={[
              (tableData, index) =>
                canSortNodes(user)
                  ? {
                      className: "nodes-table__actions__node-order-move-up",
                      icon: <ArrowDropUpIcon />,
                      tooltip: t("scenes.nodesSettings.table.actions.nodeOrderMoveUp"),
                      onClick: (_, tableData, index) => moveUp(index), // tableData.id is the rowIndex
                      disabled: index === 0
                    }
                  : null,
              (tableData, index) =>
                canSortNodes(user)
                  ? {
                      className: "nodes-table__actions__node-order-move-down",
                      icon: <ArrowDropDownIcon />,
                      tooltip: t("scenes.nodesSettings.table.actions.nodeOrderMoveDown"),
                      onClick: (_, tableData, index) => moveDown(index), // tableData.id is the rowIndex
                      disabled: index === config.length - 1
                    }
                  : null,
              ...(modulesConfig.tableActionFactories?.["node-list"] || []).map(actionFactory =>
                actionFactory({sx: buttonActionStyle})
              ),
              ({nodeId, type}) =>
                canDisplayCacheSettingForm(user, nodeId)
                  ? {
                      className: "nodes-table__actions__node-dataflows-cache",
                      icon: (
                        <Box sx={buttonActionStyle}>
                          {t("scenes.nodesSettings.table.actions.nodeDataflowsCache.label")}
                        </Box>
                      ),
                      tooltip: t("scenes.nodesSettings.table.actions.nodeDataflowsCache.tooltip"),
                      onClick: (_, {nodeId}) => {
                        setCacheFormNodeId(nodeId);
                        setNodeVisibility(false);
                        setCacheFormVisibility(true);
                      },
                      disabled: type !== "SDMX-REST"
                    }
                  : null,
              ({nodeId}) =>
                canDisplayTemplatesSettingsForm(user, nodeId)
                  ? {
                      className: "nodes-table__actions__node-templates",
                      icon: (
                        <Box sx={buttonActionStyle}>{t("scenes.nodesSettings.table.actions.nodeTemplates.label")}</Box>
                      ),
                      tooltip: t("scenes.nodesSettings.table.actions.nodeTemplates.tooltip"),
                      onClick: (_, {nodeId}) => {
                        setTemplatesNodeId(nodeId);
                      }
                    }
                  : null,
              canDisplayPermissionsSettingsForm(user)
                ? {
                    className: "nodes-table__actions__node-permissions",
                    icon: <PersonIcon />,
                    tooltip: t("scenes.nodesSettings.table.actions.nodePermissions"),
                    onClick: (_, {nodeId}) => {
                      setPermissionsNodeId(nodeId);
                    }
                  }
                : null,
              ({nodeId}) =>
                modulesConfig.modules.includes("dashboard") && canManageNodeDashboards(user, nodeId)
                  ? {
                      className: "nodes-table__actions__node-dashboards",
                      icon: <DashboardIcon />,
                      tooltip: t("scenes.nodesSettings.table.actions.nodeDashboards"),
                      onClick: (_, {nodeId}) => {
                        setDashboardsNodeId(nodeId);
                      }
                    }
                  : null,
              ({nodeId}) =>
                canEditNode(user, nodeId)
                  ? {
                      className: "nodes-table__actions__edit-node",
                      icon: <EditIcon />,
                      tooltip: t("scenes.nodesSettings.table.actions.editNode"),
                      onClick: (_, {nodeId, type}) => {
                        handleEditNode(nodeId, type);
                      }
                    }
                  : null,
              ({type, nodeId}) =>
                canEditNode(user, nodeId)
                  ? {
                      className: "nodes-table__actions__advanced-configuration",
                      icon: <SettingsIcon />,
                      tooltip: t("scenes.nodesSettings.table.actions.advancedConfiguration"),
                      onClick: (_, {nodeId}) => {
                        setAdditionalSettingsNodeId(nodeId);
                      },
                      disabled: type !== "SDMX-REST"
                    }
                  : null,
              canDeleteNodes(user)
                ? {
                    className: "nodes-table__actions__delete-node",
                    icon: <DeleteIcon />,
                    tooltip: t("scenes.nodesSettings.table.actions.deleteNode"),
                    onClick: (_, {nodeId}) => setNodeDeleteFormNodeId(nodeId)
                  }
                : null,
              ({type}) => ({
                className: "nodes-table__actions__export-node",
                icon: <GetAppIcon />,
                tooltip: t("scenes.nodesSettings.table.actions.exportNode"),
                onClick: (_, {nodeId}) => setExportNodeFormNodeId(nodeId),
                disabled: type !== "SDMX-REST"
              })
            ]}
          />
        </Box>
        <SettingsDialog
          title={
            nodeId === null ? t("scenes.nodesSettings.modals.createNode") : t("scenes.nodesSettings.modals.editNode")
          }
          open={isNodeVisible}
          onClose={() => {
            if (nodeFormRef.current) {
              nodeFormRef.current.cancel(() => {
                setNodeVisibility(false);
                setNodeId(null);
              });
            } else {
              setNodeVisibility(false);
              setNodeId(null);
            }
          }}
          onSubmit={() => {
            if (nodeFormRef.current) {
              nodeFormRef.current.submit(() => {
                // setNodeVisibility(false);
                // setNodeId(null);
              });
            } else {
              // setNodeVisibility(false);
              // setNodeId(null);
            }
          }}
          hasSubmit
        >
          <NodeSettingsForm ref={nodeFormRef} nodeId={nodeId} />
        </SettingsDialog>

        <SettingsDialog
          title={
            mergedNodeId === null
              ? t("scenes.nodesSettings.modals.createMergedNode")
              : t("scenes.nodesSettings.modals.editMergedNode")
          }
          open={isMergedNodeDialogVisible}
          onClose={() => {
            if (mergedNodeFormRef.current) {
              mergedNodeFormRef.current.cancel(() => {
                setMergedNodeDialogVisibility(false);
                setMergedNodeId(null);
              });
            } else {
              setMergedNodeDialogVisibility(false);
              setMergedNodeId(null);
            }
          }}
          onSubmit={() => {
            if (mergedNodeFormRef.current) {
              mergedNodeFormRef.current.submit(() => {});
            }
          }}
          hasSubmit
        >
          <MergedNodeSettingsForm ref={mergedNodeFormRef} nodeId={mergedNodeId} />
        </SettingsDialog>

        {isImportDialog && (
          <NodeImportDialog open={isImportDialog} onClose={() => setImportDialog(false)} nodes={allowedNodes} />
        )}

        <SettingsDialog
          title={t("scenes.nodesSettings.modals.nodeCache", {
            nodeCode: config.find(node => node.nodeId === cacheFormNodeId)?.code
          })}
          open={isCacheFormVisible}
          onClose={() => {
            if (cacheFormRef.current) {
              cacheFormRef.current.cancel(() => {
                setCacheFormVisibility(false);
                setCacheFormNodeId(null);
              });
            } else {
              setCacheFormVisibility(false);
              setCacheFormNodeId(null);
            }
          }}
          onSubmit={() => {
            if (cacheFormRef.current) {
              cacheFormRef.current.submit(() => {
                setCacheFormVisibility(false);
                setCacheFormNodeId(null);
              });
            } else {
              setCacheFormVisibility(false);
              setCacheFormNodeId(null);
            }
          }}
          false
        >
          <CacheSettingsForm ref={cacheFormRef} nodeId={cacheFormNodeId} />
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.nodesSettings.modals.nodeTemplates", {
            nodeCode: config.find(node => node.nodeId === templatesNodeId)?.code
          })}
          open={templatesNodeId !== null}
          onClose={() => {
            if (templatesRef.current) {
              templatesRef.current.cancel(() => setTemplatesNodeId(null));
            } else {
              setTemplatesNodeId(null);
            }
          }}
        >
          <TemplatesSettingsForm
            ref={templatesRef}
            nodeId={templatesNodeId}
            nodes={config}
            onTemplatesClose={() => {
              setTemplatesNodeId(null);
              onNodesClose();
            }}
          />
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.nodesSettings.modals.nodePermissions", {
            nodeCode: config.find(node => node.nodeId === permissionsNodeId)?.code
          })}
          open={permissionsNodeId !== null}
          onClose={() => {
            if (permissionsRef.current) {
              permissionsRef.current.cancel(() => {
                setPermissionsNodeId(null);
              });
            } else {
              setPermissionsNodeId(null);
            }
          }}
          onSubmit={() => {
            if (permissionsRef.current) {
              permissionsRef.current.submit(() => {
                setPermissionsNodeId(null);
              });
            } else {
              setPermissionsNodeId(null);
            }
          }}
          hasSubmit
        >
          <PermissionsSettingsForm ref={permissionsRef} nodeId={permissionsNodeId} />
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.nodesSettings.modals.nodeDashboards", {
            nodeCode: config.find(node => node.nodeId === dashboardsNodeId)?.code
          })}
          open={modulesConfig.modules.includes("dashboard") && dashboardsNodeId !== null}
          onClose={() => {
            if (dashboardsRef.current) {
              dashboardsRef.current.destroy(() => {
                setDashboardsNodeId(null);
              });
            } else {
              setDashboardsNodeId(null);
            }
          }}
          onSubmit={() => {
            if (dashboardsRef.current) {
              dashboardsRef.current.submit(data => {
                submitNodeDashboards(dashboardsNodeId, data);
                setDashboardsNodeId(null);
              });
            }
          }}
          hasSubmit
        >
          <DashboardsSettingsForm ref={dashboardsRef} nodeId={dashboardsNodeId} />
        </SettingsDialog>

        {exportNodeFormNodeId && (
          <NodeExportForm
            ref={exportRef}
            nodeCode={config.find(node => node.nodeId === exportNodeFormNodeId)?.code}
            onClose={() => {
              if (exportRef.current) {
                exportRef.current.cancel(() => {
                  setExportNodeFormNodeId(null);
                });
              } else {
                setExportNodeFormNodeId(null);
              }
            }}
          />
        )}

        {additionalSettingsNodeId && (
          <AdvancedConfigurationForm
            nodeId={additionalSettingsNodeId}
            onClose={() => setAdditionalSettingsNodeId(null)}
          />
        )}

        <Dialog
          maxWidth="xs"
          open={nodeDeleteFormNodeId !== null}
          onClose={() => {
            setNodeDeleteFormNodeId(null);
            setCacheFormNodeId(null);
          }}
        >
          <CustomDialogTitle
            onClose={() => {
              setNodeDeleteFormNodeId(null);
              setCacheFormNodeId(null);
            }}
          >
            {t("scenes.nodesSettings.modals.nodeDelete.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.nodesSettings.modals.nodeDelete.content")}</DialogContent>
          <DialogActions>
            <Button
              id="nodesSettings_modals_nodeDelete_cancel_btn"
              autoFocus
              onClick={() => {
                setNodeDeleteFormNodeId(null);
                setCacheFormNodeId(null);
              }}
            >
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="nodesSettings_modals_nodeDelete_confirm_btn"
              onClick={() => {
                deleteNode(nodeDeleteFormNodeId);
                setNodeDeleteFormNodeId(null);
                setCacheFormNodeId(null);
              }}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        <ModulesPlaceholder id="node-settings-actions-dialog" />
      </Fragment>
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(NodesSettingsForm);
