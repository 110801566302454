import React, {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ConfigHubsDto} from "../../../model/item-containers-models/configHubsDto";
import {ConfigNodeFormInfo} from "../../../model/item-containers-models/configNodeFormInfo";
import {ConfigNodeMenuId} from "../../../model/item-containers-models/configNodeMenuId";
import {ConfigNodesDto} from "../../../model/item-containers-models/configNodesDto";
import {ItemContainerDto} from "../../../model/item-containers-models/itemContainerDto";
import MaterialTable from "../../material-table";
import CustomPageNodeSettingsFormDialog from "./CustomPageNodeSettingsFormDialog";
import {hubNodesSelector, hubSelector} from "../../../state/hub/hubSelectors";
import {changeOtherConfigItemContainer} from "../../../state/otherConfig/otherConfigActions";
import {currentItemContainerSelector} from "../../../state/otherConfig/otherConfigSelectors";

const CustomPageNodeSettings = () => {
  const nodes = useSelector(hubNodesSelector);
  const hub = useSelector(hubSelector);
  const itemContainer = useSelector(currentItemContainerSelector);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const addNodeConfig = (configNode: ConfigNodesDto) => {
    const newCustomPage: ItemContainerDto = {
      ...itemContainer
    };

    if (configNode.nodeId === -1) {
      const configHub: ConfigHubsDto = {
        ...configNode,
        hubId: hub["hubId"]
      };
      delete configHub["nodeId"];
      newCustomPage.configHubs = [configHub];
    } else {
      newCustomPage.configNodes = (itemContainer.configNodes || []).concat(configNode);
    }

    dispatch(changeOtherConfigItemContainer(newCustomPage));
  };

  const changeNodeConfig = (cfg: ConfigNodeFormInfo) => {
    setInitialValue(cfg);
    setAddingConfiguration(true);
  };

  const editNodeConfig = (configNode: ConfigNodesDto) => {
    const newCustomPage: ItemContainerDto = {
      ...itemContainer
    };

    if (configNode.nodeId === -1) {
      const configHub: ConfigHubsDto = {
        ...configNode,
        hubId: hub["hubId"]
      };
      delete configHub["nodeId"];
      newCustomPage.configHubs = [configHub];
    } else {
      newCustomPage.configNodes = itemContainer.configNodes.map(node =>
        node.nodeId !== configNode.nodeId ? node : configNode
      );
    }
    dispatch(changeOtherConfigItemContainer(newCustomPage));
  };

  const removeNodeConfig = (nodeId: number) => {
    const newCustomPage: ItemContainerDto = {
      ...itemContainer,
      configNodes: (itemContainer.configNodes || []).filter(v => v.nodeId !== nodeId),
      configHubs: nodeId === undefined ? [] : itemContainer.configHubs
    };
    dispatch(changeOtherConfigItemContainer(newCustomPage));
  };
  const [isAddingConfiguration, setAddingConfiguration] = useState(false);

  const [initialValue, setInitialValue] = useState<ConfigNodeFormInfo | null>(null);

  const getNodeTitle = data => {
    if (data.nodeId && data.nodeId !== -1) {
      const node = nodes.find(n => n.nodeId === data.nodeId);
      return node.name || node.nodeId;
    } else {
      return "Hub";
    }
  };

  const data: ConfigNodeFormInfo[] = (itemContainer.configNodes || [])
    .concat(itemContainer.configHubs || [])
    .map(v => ({
      ...v,
      sidenavOrder: v.configMenus?.find(m => m.menuId === ConfigNodeMenuId.SIDENAV)?.order,
      sidenavLabel: v.configMenus?.find(m => m.menuId === ConfigNodeMenuId.SIDENAV)?.label,
      centralOrder: v.configMenus?.find(m => m.menuId === ConfigNodeMenuId.CENTRAL)?.order,
      centralLabel: v.configMenus?.find(m => m.menuId === ConfigNodeMenuId.CENTRAL)?.label
    }));

  return (
    <Box sx={{height: "480px"}}>
      <MaterialTable
        rightActions={[
          {
            label: t("components.customPageNodeSettings.actions.add"),
            startIcon: <AddIcon />,
            onClick: () => {
              setInitialValue(null);
              setAddingConfiguration(true);
            },
            disabled:
              (itemContainer?.configNodes || []).concat(itemContainer?.configHubs || []).length >= nodes.length + 1
          }
        ]}
        columns={[
          {
            field: "name",
            title: t("components.customPageNodeSettings.table.columns.nodeName"),
            render: data => getNodeTitle(data)
          },
          {
            field: "isHomePage",
            title: t("components.customPageNodeSettings.table.columns.isHomePage"),
            render: ({isHomePage}) =>
              isHomePage
                ? t("scenes.nodesSettings.table.columns.isNodeActive.values.true")
                : t("scenes.nodesSettings.table.columns.isNodeActive.values.false")
          },
          {
            field: "sidenavOrder",
            title: t("components.customPageNodeSettings.table.columns.sidenav"),
            render: ({sidenavOrder}) => (sidenavOrder !== undefined ? sidenavOrder : "")
          },
          {
            field: "centralOrder",
            title: t("components.customPageNodeSettings.table.columns.central"),
            render: ({centralOrder}) => (centralOrder !== undefined ? centralOrder : "")
          }
        ]}
        data={data}
        actions={[
          (_, cfg: ConfigNodeFormInfo) => ({
            icon: <EditIcon />,
            tooltip: t("components.customPageNodeSettings.actions.edit"),
            onClick: () => changeNodeConfig(cfg)
          }),
          () => ({
            icon: <DeleteIcon />,
            tooltip: t("components.customPageNodeSettings.actions.delete"),
            onClick: (_, {nodeId}) => removeNodeConfig(nodeId)
          })
        ]}
      />

      {isAddingConfiguration && (
        <CustomPageNodeSettingsFormDialog
          initialValues={initialValue}
          open={isAddingConfiguration}
          onClose={() => setAddingConfiguration(false)}
          onSubmit={initialValue ? editNodeConfig : addNodeConfig}
        />
      )}
    </Box>
  );
};

export default CustomPageNodeSettings;
