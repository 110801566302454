import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import UserErrors from "../../user-errors";
import {changeUserPassword} from "../../../state/user/userActions";

const Form = forwardRef(({onSubmit}, ref) => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    watch
  } = useForm();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);

  const {t} = useTranslation();

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        onSubmit(val);
        f(val);
      })();
    },
    cancel(f) {
      f();
    }
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            {...register("oldPassword")}
            name="oldPassword"
            label={t("components.userSelect.changePasswordModal.fields.oldPassword")}
            error={!!errors.oldPassword}
            helperText={errors.oldPassword?.message}
            variant="outlined"
            type={showOldPassword ? undefined : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            {...register("newPassword", {
              required: t("commons.validation.required")
            })}
            name="newPassword"
            label={t("components.userSelect.changePasswordModal.fields.newPassword")}
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
            variant="outlined"
            required
            type={showNewPassword ? undefined : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            inputProps={{
              autoComplete: "new-password"
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            {...register("newPasswordConfirm", {
              required: t("commons.validation.required"),
              validate: val =>
                val === watch("newPassword") ||
                t("components.userSelect.changePasswordModal.validation.newPasswordMustBeEqual")
            })}
            name="newPasswordConfirm"
            label={t("components.userSelect.changePasswordModal.fields.newPasswordConfirm")}
            error={!!errors.newPasswordConfirm}
            helperText={errors.newPasswordConfirm?.message}
            variant="outlined"
            required
            type={showNewPasswordConfirm ? undefined : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowNewPasswordConfirm(!showNewPasswordConfirm)}>
                    {showNewPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            inputProps={{
              autoComplete: "new-password"
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
});

const mapStateToProps = state => ({
  userErrors: state.usersConfig.userErrors
});

const mapDispatchToProps = dispatch => ({
  sendConfig: config => dispatch(changeUserPassword(config.oldPassword, config.newPassword))
});

const UserChangePasswordForm = ({sendConfig, userErrors}, ref) => {
  return (
    <Fragment>
      <UserErrors errors={userErrors} />
      <Form ref={ref} onSubmit={sendConfig} />
    </Fragment>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(UserChangePasswordForm);
