import React, {useCallback, useMemo} from "react";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import DatasetMergedFilters from "./DatasetMergedFilters";
import {
  getAttributeValueLabelFromCombinations,
  getDimensionLabelFromCombinations,
  getDimensionValueLabelFromCombinations
} from "../../utils/dataset";

const DatasetMergedFiltersCombinationsWrapper = ({
  cols,
  layout,
  timeDim,
  dimensionLabels,
  dimensionMap,
  seriesAttributeMap,
  dimensionsAttributes,
  onChange
}) => {
  const dimensions = useMemo(() => {
    if ((layout?.filters || []).length === 0) {
      return [];
    } else if (layout.filters.includes(timeDim)) {
      return [timeDim, ...layout.filters.filter(dim => dim !== timeDim)];
    } else {
      return layout.filters;
    }
  }, [layout, timeDim]);

  const combinations = useMemo(() => {
    if ((dimensions || []).length === 0) {
      return [];
    } else {
      const newCombinations = [];
      const alreadyAdded = {};
      (cols || []).forEach(col => {
        const key = dimensions.map(dim => col[dim]).join("+");
        if (alreadyAdded[key] !== true) {
          const values = [];
          const filterValues = {};
          dimensions.forEach(dim => {
            values.push({
              dim: dim,
              dimLabel: getDimensionLabelFromCombinations(dim, dimensionLabels),
              value: col[dim],
              valueLabel: getDimensionValueLabelFromCombinations(dim, col[dim], dimensionMap)
            });
            filterValues[dim] = col[dim];
          });
          alreadyAdded[key] = true;
          newCombinations.push({
            values: values,
            filtersValue: filterValues
          });
        }
      });
      return newCombinations;
    }
  }, [dimensions, cols, dimensionLabels, dimensionMap]);

  const dimensionAttributeMap = useMemo(() => {
    let attributeMap = {};
    if (seriesAttributeMap && dimensionsAttributes) {
      Object.keys(dimensionsAttributes).forEach(dim => {
        const dimValuesAttributes = dimensionsAttributes[dim] || {};
        let dimAttributeMap = {};
        Object.keys(dimValuesAttributes).forEach(dimValue => {
          const attributes = dimValuesAttributes[dimValue] || [];
          let dimAttributes = [];
          attributes.forEach(({id, value}) => {
            const {attrLabel, valueLabel} = getAttributeValueLabelFromCombinations(id, value, seriesAttributeMap);
            dimAttributes.push({
              id: id,
              valueId: value,
              label: attrLabel,
              valueLabel: valueLabel
            });
          });
          dimAttributeMap[dimValue] = dimAttributes;
        });
        attributeMap[dim] = dimAttributeMap;
      });
    }
    return attributeMap;
  }, [dimensionsAttributes, seriesAttributeMap]);

  const getAttributeIcon = useCallback(
    (dim, value, disabledTooltip) => {
      return (
        <DatasetFilterAttributeIcon
          dimension={dim}
          dimensionValues={[value]}
          datasetDimAttributeMap={dimensionAttributeMap}
          disabled
          disabledTooltip={disabledTooltip}
          getDimValueLabel={dimVal => getDimensionValueLabelFromCombinations(dim, dimVal, dimensionMap)}
        />
      );
    },
    [dimensionAttributeMap, dimensionMap]
  );

  const combination = dimensions.map(dim => ({
    dim: dim,
    dimLabel: getDimensionLabelFromCombinations(dim, dimensionLabels),
    value: layout.filtersValue[dim],
    valueLabel: getDimensionValueLabelFromCombinations(dim, layout.filtersValue[dim], dimensionMap)
  }));

  return (
    layout &&
    dimensionMap && (
      <DatasetMergedFilters
        layout={layout}
        dimensions={dimensions}
        combination={combination}
        combinations={combinations}
        getAttributeIcon={getAttributeIcon}
        onChange={onChange}
      />
    )
  );
};

export default DatasetMergedFiltersCombinationsWrapper;
