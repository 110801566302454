import {Box, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {goToNode} from "../../links";
import {NodeVisibility} from "../../model/IHubMinimalNode.d.ts";
import ButtonSelect from "../button-select";

const NodeSelector = ({isDefaultUniqueNode, node, nodes, fontSize}) => {
  const {t} = useTranslation();
  if (!isDefaultUniqueNode) {
    return (
      <Grid item className="node-selector">
        <ButtonSelect
          value={
            <Box
              component="span"
              sx={{
                fontSize: fontSize ? fontSize : "20px",
                fontWeight: "500px",
                textTransform: "none",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {node?.name || node?.code}
            </Box>
          }
          onChange={code => goToNode(code.toLowerCase())}
          tooltip={node?.name}
          buttonStyle={{maxWidth: "100%"}}
          ariaLabel={t("components.header.actions.nodeSelector.ariaLabel")}
        >
          {nodes
            .filter(({visible}) => visible !== NodeVisibility.No)
            .sort((a, b) => a.order - b.order)
            .map(({code, name}, i) => (
              <span key={i} data-value={code}>
                {name || code}
              </span>
            ))}
        </ButtonSelect>
      </Grid>
    );
  } else {
    return null;
  }
};

export default NodeSelector;
