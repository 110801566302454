import {PayloadAction} from "@reduxjs/toolkit";
import _ from "lodash";
import {Reducer} from "redux";
import {ViewerMode} from "../dataset/constants";
import {
  OTHER_CONFIG_DASHBOARD_TO_SHARE_CLEAR,
  OTHER_CONFIG_DASHBOARD_TO_SHARE_FETCH,
  OTHER_CONFIG_ITEMCONTAINER_CHANGE,
  OTHER_CONFIG_ITEMCONTAINER_CREATE,
  OTHER_CONFIG_ITEMCONTAINER_CREATE_SUBMIT,
  OTHER_CONFIG_ITEMCONTAINER_DATASET_CLEAR,
  OTHER_CONFIG_ITEMCONTAINER_DELETE,
  OTHER_CONFIG_ITEMCONTAINER_HIDE,
  OTHER_CONFIG_ITEMCONTAINER_UPDATE,
  OTHER_CONFIG_ITEMCONTAINER_UPDATE_SUBMIT,
  OTHER_CONFIG_ITEMCONTAINER_VIEW_FETCH,
  OTHER_CONFIG_ITEMCONTAINER_VIEWS_FETCH,
  OTHER_CONFIG_ITEMCONTAINERS_CLEAR,
  OTHER_CONFIG_ITEMCONTAINERS_FETCH,
  OTHER_CONFIG_VIEW_DELETE,
  OTHER_CONFIG_VIEWS_CLEAR,
  OTHER_CONFIG_VIEWS_FETCH
} from "./otherConfigActions";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";

export type OtherConfigState = {
  views: any[] | null;
  needDashboards: boolean;
  dashboards: any[] | null;
  dashboard: any | null;
  dashboardViews: any[] | null;
  dashboardJsonStats: any;
  dashboardLayoutObjs: any;
  dashboardFilterTrees: any;
  dashboardTimePeriodsByFreq: any;
  needItemContainers: boolean;
  itemContainers: any[] | null;
  itemContainer: any | null;
  dashboardToShare: any | null;
  itemContainerViews: any[] | null;
  itemContainerJsonStats: any;
};

const otherConfigReducer: Reducer<OtherConfigState, PayloadAction<any>> = (
  state = {
    views: null,
    needDashboards: false,
    dashboards: null,
    dashboard: null,
    dashboardViews: null,
    dashboardJsonStats: null,
    dashboardLayoutObjs: null,
    dashboardFilterTrees: null,
    dashboardTimePeriodsByFreq: null,
    needItemContainers: false,
    itemContainers: null,
    itemContainer: null,
    dashboardToShare: null,
    itemContainerViews: null,
    itemContainerJsonStats: null
  },
  action
) => {
  switch (action.type) {
    case OTHER_CONFIG_VIEWS_CLEAR: {
      return {
        ...state,
        views: null
      };
    }
    case OTHER_CONFIG_ITEMCONTAINERS_CLEAR: {
      return {
        ...state,
        itemContainers: null
      };
    }
    case OTHER_CONFIG_ITEMCONTAINER_CREATE: {
      return {
        ...state,
        itemContainer: action.payload.itemContainer
      };
    }
    case OTHER_CONFIG_ITEMCONTAINER_CHANGE: {
      return {
        ...state,
        itemContainer: action.payload.itemContainer
      };
    }
    case OTHER_CONFIG_ITEMCONTAINER_HIDE: {
      return {
        ...state,
        itemContainer: null
      };
    }
    case OTHER_CONFIG_ITEMCONTAINER_DATASET_CLEAR: {
      return {
        ...state,
        itemContainerJsonStats: null
      };
    }
    case OTHER_CONFIG_DASHBOARD_TO_SHARE_CLEAR: {
      return {
        ...state,
        dashboardToShare: null
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case OTHER_CONFIG_ITEMCONTAINERS_FETCH: {
          return {
            ...state,
            needItemContainers: false
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case OTHER_CONFIG_VIEWS_FETCH: {
          return {
            ...state,
            views: (action.payload.response || []).map((v: any) => ({
              ...v,
              datasetId: v.datasetId ? v.datasetId.split("+").join(",") : undefined
            }))
          };
        }
        case OTHER_CONFIG_ITEMCONTAINER_VIEWS_FETCH: {
          return {
            ...state,
            itemContainerViews: (action.payload.response || [])
              .filter((v: any) => (v?.mode || ViewerMode.SingleViewer) !== ViewerMode.MultiViewer)
              .map((v: any) => ({
                ...v,
                datasetId: v.datasetId ? v.datasetId.split("+").join(",") : undefined
              }))
          };
        }
        case OTHER_CONFIG_ITEMCONTAINER_VIEW_FETCH: {
          const newItem: any = _.cloneDeep(state.itemContainer.item);
          newItem[action.payload.extra.rowIdx][action.payload.extra.colIdx].viewTemplate = action.payload.response;
          return {
            ...state,
            itemContainer: {
              ...state.itemContainer,
              item: newItem
            }
          };
        }
        case OTHER_CONFIG_VIEW_DELETE: {
          return {
            ...state,
            views: null
          };
        }
        case OTHER_CONFIG_ITEMCONTAINERS_FETCH: {
          return {
            ...state,
            itemContainers: action.payload.response
          };
        }
        case OTHER_CONFIG_ITEMCONTAINER_UPDATE: {
          const itemContainer = action.payload.response?.itemContainer;
          return itemContainer
            ? {
                ...state,
                itemContainer: {
                  ...itemContainer,
                  item: itemContainer.item ? JSON.parse(JSON.stringify(itemContainer.item)) : {},
                  filterLevels: itemContainer.filterLevels ? JSON.parse(itemContainer.filterLevels) : {}
                }
              }
            : state;
        }
        case OTHER_CONFIG_DASHBOARD_TO_SHARE_FETCH: {
          return {
            ...state,
            dashboardToShare: action.payload.response,
            itemContainer: null
          };
        }
        case OTHER_CONFIG_ITEMCONTAINER_DELETE:
        case OTHER_CONFIG_ITEMCONTAINER_CREATE_SUBMIT:
        case OTHER_CONFIG_ITEMCONTAINER_UPDATE_SUBMIT: {
          return {
            ...state,
            needItemContainers: true
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR:
      switch (action.payload.label) {
        case OTHER_CONFIG_ITEMCONTAINERS_FETCH: {
          return {
            ...state,
            itemContainers: action.payload.statusCode === 404 ? [] : null
          };
        }
        default:
          return state;
      }
    default:
      return state;
  }
};

export default otherConfigReducer;
