import React, {useEffect, useState} from "react";
import {Button, DialogActions, FormControl, FormGroup, TextField} from "@mui/material";
import {Controller, FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {IHubNode} from "../../../../model/IHubNode";
import {DownloadNodeDto} from "../../../../model/wshub-models/downloadNodeDto";
import {ImportDashboardDto} from "../../../../model/wshub-models/importDashboardDto";
import {ImportViewDto} from "../../../../model/wshub-models/importViewDto";
import {ObjectTitle} from "../../../../model/wshub-models/objectTitle";
import {Roles} from "../../../../model/wshub-models/roles";
import {UserData} from "../../../../model/wshub-models/userData";
import {getNodeImportUrl, getUsersConfigGetUrl} from "../../../../serverApi/urls";
import ObjectContainer from "./ObjectContainer";
import TitleChangeDialog from "./TitleChangeDialog";
import useApi from "../../../../state/api/useApi";
import {fetchHub} from "../../../../state/hub/hubActions";
import {fetchNodesConfigNodes} from "../../../../state/nodesConfig/nodesConfigActions";
import {external} from "../../../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../../../middlewares/request/requestActions";

export interface Title extends ObjectTitle {
  show?: boolean;
}

interface ImportViewForm {
  NodeCode: string;
  UserView: number;
  UserDashboards: number;
  IncludeNode?: boolean;
  IncludeTemplate?: boolean;
  IncludeView?: boolean;
  IncludeDashboards?: boolean;
  ViewTittle?: Title[];
  DashboardTittle?: Title[];
}

export interface ImportFormViewProps {
  data: DownloadNodeDto;
  file: File;
  password: string;
  onClose: () => void;
  nodes: IHubNode[];
}

const ImportFormView = ({data, file, password, onClose, nodes}: ImportFormViewProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [titlesToChange, setTitlesToChange] = useState<["DashboardTittle" | "ViewTittle", Title[]] | null>(null);

  const getTitlesToChange = (importObj: ImportDashboardDto[] & ImportViewDto[]): Title[] =>
    importObj
      .filter(v => v.wasSended || !!v.duplicateTittle)
      .map((v: ImportDashboardDto & ImportViewDto) => ({
        id: importObj[0]?.viewTemplateId ? v.viewTemplateId : v.itemContainerId,
        tittle: v.duplicateTittle || v.title,
        show: !v.duplicateTittle
      }));

  const {data: dtbUsers} = useApi<UserData[]>(initRequest("UsersList", getUsersConfigGetUrl(), RequestMethod.GET), {
    invokeImmediately: true
  });
  const {
    call,
    request,
    data: importData,
    clear
  } = useApi<DownloadNodeDto>(
    external(
      initRequest("importNode", getNodeImportUrl(), RequestMethod.POST, undefined, t => ({
        onStart: t("components.nodeImportForm.wizard.secondStep.importingMessage")
      })),
      "dashboard"
    ),
    {
      onSuccess: data => {
        if (data.wasImported) {
          onClose();
          dispatch(fetchHub());
          dispatch(fetchNodesConfigNodes());
        } else {
          const viewsWithTitleToChange = getTitlesToChange(data.view);
          const dashboardsWithTitleToChange = getTitlesToChange(data.dashboard);
          ImportViewForm.register("DashboardTittle", {
            value: dashboardsWithTitleToChange
          });
          ImportViewForm.register("ViewTittle", {
            value: viewsWithTitleToChange
          });
        }
      }
    }
  );

  const allUsers = (dtbUsers || []).filter(user => user.isActive);
  const adminUsers = allUsers.filter(user => user.roles.find(role => role === Roles.Administrator));

  const ImportViewForm = useForm<ImportViewForm>({
    defaultValues: {
      NodeCode: data?.node.code,
      IncludeDashboards: false,
      IncludeView: false,
      IncludeTemplate: false,
      UserView: allUsers && allUsers[0]?.userId,
      UserDashboards: adminUsers && adminUsers[0]?.userId
    }
  });

  useEffect(() => {
    return () => clear();
  }, [clear]);

  const submitHandler: SubmitHandler<ImportViewForm> = formFields => {
    const validationFormData = new FormData();
    for (const key in formFields) {
      if (formFields.hasOwnProperty(key)) {
        if (key === "ViewTittle" || key === "DashboardTittle")
          validationFormData.append(key, JSON.stringify(formFields[key]));
        else formFields[key] !== undefined && validationFormData.append(key, formFields[key]);
      }
    }
    validationFormData.append("File", file);
    validationFormData.append("Password", password);
    validationFormData.append("ToBeImported", "true");
    call({...request, data: validationFormData});
  };

  return (
    <FormProvider {...ImportViewForm}>
      <form onSubmit={ImportViewForm.handleSubmit(submitHandler)}>
        <FormControl>
          <Controller
            rules={{
              required: {
                value: true,
                message: t("commons.validation.required")
              },
              validate: nodeCode =>
                nodes.some(node => node.code === nodeCode)
                  ? t("components.nodeImportForm.titleChangeDialog.validation.node")
                  : true
            }}
            control={ImportViewForm.control}
            name="NodeCode"
            render={({field}) => (
              <TextField
                {...field}
                helperText={ImportViewForm.formState.errors.NodeCode?.message}
                variant="outlined"
                error={!!ImportViewForm.formState.errors.NodeCode}
                label="ID *"
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            )}
          />
        </FormControl>
        {(!!data.template?.length || !!data.view?.length || !!data?.dashboard.length) && (
          <FormGroup>
            <h3>{t("components.nodeImportForm.wizard.secondStep.selectionText")}</h3>
            <ObjectContainer
              users={undefined}
              data={data.template}
              importData={importData?.template}
              objectType={"template"}
              setTitlesToChange={setTitlesToChange}
            ></ObjectContainer>
            <ObjectContainer
              users={allUsers}
              data={data.view}
              importData={importData?.view}
              objectType={"view"}
              setTitlesToChange={setTitlesToChange}
            ></ObjectContainer>
            <ObjectContainer
              users={adminUsers}
              data={data.dashboard}
              importData={importData?.dashboard}
              objectType={"dashboard"}
              setTitlesToChange={setTitlesToChange}
            ></ObjectContainer>
          </FormGroup>
        )}
        <DialogActions>
          <Button onClick={onClose} type="button">
            {t("commons.confirm.close")}
          </Button>
          <Button type="submit">{t("components.nodeImportForm.wizard.secondStep.importButton")}</Button>
        </DialogActions>
        {titlesToChange && (
          <TitleChangeDialog
            open={!!titlesToChange}
            title={titlesToChange[0]}
            onApply={d => ImportViewForm.setValue(titlesToChange[0], d)}
            importData={titlesToChange[1]}
            onClose={() => setTitlesToChange(null)}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default ImportFormView;
