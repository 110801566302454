import _ from "lodash";
import {getViewerTypeFromIdx} from "../../../components/data-viewer/constant";
import {RoundingMode} from "../../../model/wshub-models/roundingMode";
import {ChartSettings, Criteria, MapSettings, ViewerMode} from "../constants";
import {CRITERIA_FILTER_TYPE_CODES, getCriteriaArrayFromObject} from "../../../utils/criteria";
import {
  getSVPFilteredChartLayout,
  getSVPFilteredMapLayout,
  getSVPFilteredTableLayout,
  getSVPInitialChartLayout,
  getSVPInitialMapLayout,
  getSVPInitialTableLayout
} from "../../../utils/dataset";
import {getNodes, renameTreeChildrenKey} from "../../../utils/tree";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

export type DatasetSingleViewerPlusState = {
  datasetUuid: string | null;
  dataset: any | null;
  isFetchStarted: boolean;
  isFetchFailed: boolean;
  isPartialData: boolean;
  isEmptyData: boolean;
  isTooBigData: boolean;
  isTooLongQuery: boolean;
  isCriteriaVisible: boolean;
  initialCriteriaDimension: string | null;
  isCriteriaAlertVisible: boolean;
  dimensions: any[] | null;
  dimensionsInfo: {[key: string]: any} | {};
  territoryDim: string | null;
  timeDim: string | null;
  freqDim: string | null;
  viewerIdx: number | null;
  isViewerHoverVisible: boolean;
  view: any | null;
  template: any | null;
  hasViewLayout: boolean;
  hasTemplateLayout: boolean;
  hasAnnotationLayout: boolean;
  isLayoutVisible: boolean;
  tableLayoutPartial: any | null;
  mapLayoutPartial: any | null;
  chartLayoutPartialCartesian: any | null;
  chartLayoutPartialRadial: any | null;
  tableLayout: any | null;
  mapLayout: any | null;
  chartLayoutCartesian: any | null;
  chartLayoutRadial: any | null;
  labelFormat: string | null;
  customLabelFormats: {[key: string]: string} | {};
  temporalDimOrder: string | null;
  showTrend: boolean;
  showCyclical: boolean;
  splitHeaderCells: boolean;
  splitSideCells: boolean;
  areCriteriaApplied: boolean;
  criteria: {[key: string]: Criteria} | {};
  initialCriteria: {[key: string]: Criteria} | {};
  decimalSeparator: string | null;
  roundingStrategy: RoundingMode | null;
  decimalPlaces: number | null;
  tableEmptyChar: string;
  chartSettings: ChartSettings;
  mapSettings: MapSettings;
  enableCriteria: boolean;
  enableLayout: boolean;
  enableVariation: boolean;
  codelistTrees: {[key: string]: any[]} | null;
  codelistLists: {[key: string]: any[]} | null;
  hierarchicalCodelists: {[key: string]: {[key: string]: any}} | null;
  codelistFilteredLists: {[key: string]: any[]} | null;
  codelistMaps: {[key: string]: {[key: string]: string}} | null;
  codelistsLength: (number | null)[] | null;
  codelistFetchError: boolean;
  timings: any | null;
  isFetchDatasetDisabled: boolean;
  isDownloadWarningVisible: boolean;
  isUnavailableViewWarningVisible: boolean;
  isViewVisible: boolean;
  isViewErrorVisible: boolean;
  viewErrorMessage: string | null;
  isTemplateVisible: boolean;
  isQueryVisible: boolean;
  structureQuery: string | null;
  dataQuery: string | null;
  detailLevel: number | null;
  isFullscreen: boolean;
  isTableEnabled: boolean;
  isMapEnabled: boolean;
  isChartEnabled: boolean;
  tableLockedDimensions: string[] | null;
  graphLockedDimensions: string[] | null;
  missingFilterValues: any[] | null;
  lastNObservations: number | null;
  attributesAsDim: string[] | null;
};

export const getViewTemplateBackupFromState = (state: DatasetSingleViewerPlusState) => ({
  mode: ViewerMode.SingleViewer,
  defaultView: getViewerTypeFromIdx(state.viewerIdx),
  criteria: getCriteriaArrayFromObject(state.criteria),
  layouts: JSON.stringify({
    detailLevel: state.detailLevel,
    labelFormat: state.labelFormat,
    temporalDimOrder: state.temporalDimOrder,
    showTrend: state.showTrend,
    showCyclical: state.showCyclical,
    tableLayout: state.tableLayout,
    tableEmptyChar: state.tableEmptyChar,
    mapLayout: state.mapLayout,
    ...getViewTemplateLayoutsFromMapSettings(state.mapSettings),
    chartLayoutCartesian: state.chartLayoutCartesian,
    chartLayoutRadial: state.chartLayoutRadial,
    ...getViewTemplateLayoutsFromChartSettings(state.chartSettings)
  })
});

export const getCompleteTableLayout = (
  state: DatasetSingleViewerPlusState,
  dimensionIds: string[],
  codelistLists: {[key: string]: any[]},
  hasLastNPeriods: boolean,
  limit: number
) => {
  const layout = state.tableLayoutPartial || state.tableLayout;

  return layout
    ? getSVPFilteredTableLayout(
        layout,
        codelistLists,
        state.timeDim,
        state.freqDim,
        state.territoryDim,
        hasLastNPeriods
      )
    : getSVPInitialTableLayout(dimensionIds, codelistLists, state.timeDim, state.freqDim, state.territoryDim, limit);
};

export const getCompleteMapLayout = (
  state: DatasetSingleViewerPlusState,
  dimensionIds: string[],
  codelistLists: {[key: string]: any[]},
  hasLastNPeriods: boolean,
  showSingleGeometry: boolean
) => {
  if (state.territoryDim === null || (codelistLists[state.territoryDim] || []).length === 0) {
    return null;
  }
  if (!showSingleGeometry && (codelistLists[state.territoryDim] || []).length === 1) {
    return null;
  }

  const layout = state.mapLayoutPartial || state.mapLayout;

  return layout
    ? getSVPFilteredMapLayout(layout, codelistLists, state.timeDim, state.freqDim, state.territoryDim, hasLastNPeriods)
    : getSVPInitialMapLayout(dimensionIds, codelistLists, state.timeDim, state.freqDim, state.territoryDim);
};

export const getCompleteChartLayoutCartesian = (
  state: DatasetSingleViewerPlusState,
  dimensionIds: string[],
  codelistLists: {[key: string]: any[]},
  hasLastNPeriods: boolean
) => {
  const layout = state.chartLayoutPartialCartesian || state.chartLayoutCartesian;

  return layout
    ? getSVPFilteredChartLayout(
        layout,
        codelistLists,
        state.timeDim,
        state.freqDim,
        state.territoryDim,
        hasLastNPeriods
      )
    : getSVPInitialChartLayout(dimensionIds, codelistLists, state.timeDim);
};

export const getCompleteChartLayoutRadial = (
  state: DatasetSingleViewerPlusState,
  dimensionIds: string[],
  codelistLists: {[key: string]: any[]},
  hasLastNPeriods: boolean
) => {
  const layout = state.chartLayoutPartialRadial || state.chartLayoutRadial;

  return layout
    ? getSVPFilteredChartLayout(
        layout,
        codelistLists,
        state.timeDim,
        state.freqDim,
        state.territoryDim,
        hasLastNPeriods
      )
    : getSVPInitialChartLayout(dimensionIds, codelistLists, state.timeDim);
};

export const getUpdatedSVPStateDataOnHierarchySelect = (
  state: DatasetSingleViewerPlusState,
  dimensionId: string,
  hierarchyId: string,
  hierarchyValues: any,
  tabId: number,
  selectedKeys: string[]
) => {
  const newCodelistLists: {[key: string]: string[]} = _.cloneDeep(state.codelistLists) || {};
  const newCodelistsLength: (number | null)[] = _.cloneDeep(state.codelistsLength) || [];
  const newCodelistTrees: {[key: string]: any[]} = _.cloneDeep(state.codelistTrees) || {};
  const newCodelistMaps: {[key: string]: {[key: string]: string}} = _.cloneDeep(state.codelistMaps) || {};
  const newCriteria: {[key: string]: Criteria} = _.cloneDeep(state.criteria);

  let length = 0;
  let codelistTree = [];
  const map: {[key: string]: string} = {};
  const ids: string[] = [];
  let selectedHierarchy: string = null;
  if (hierarchyValues && hierarchyId.length > 0) {
    const hierarchy = hierarchyValues[hierarchyId];
    if (hierarchy) {
      selectedHierarchy = hierarchyId;
      codelistTree = renameTreeChildrenKey(hierarchy, "hierarchyCodes", "children", null);
      const nodes = getNodes(hierarchy, "hierarchyCodes", () => true);
      nodes.forEach(node => {
        if (node.isSelectable) {
          length++;
          ids.push(node.id);
        }
        map[node.id] = node.name;
      });
      newCriteria[dimensionId] = {
        id: dimensionId,
        type: CRITERIA_FILTER_TYPE_CODES,
        filterValues: selectedKeys || [],
        period: undefined,
        from: undefined,
        to: undefined,
        hierarchyId: selectedHierarchy
      };
    }
  }

  newCodelistLists[dimensionId] = ids;
  newCodelistTrees[dimensionId] = codelistTree;
  newCodelistsLength[tabId] = length;
  newCodelistMaps[dimensionId] = map;

  return {
    codelistLists: newCodelistLists,
    codelistTrees: newCodelistTrees,
    codelistsLength: newCodelistsLength,
    codelistMaps: newCodelistMaps,
    criteria: newCriteria
  };
};
