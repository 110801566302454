import React, {Fragment, useMemo} from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import PublishIcon from "@mui/icons-material/Publish";
import {Box, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {addSpinnerMessage, markSpinnerMessage} from "../../state/spinner/spinnerActions";

const FileInputText = ({label, value, onChange, accept = "image/*, video/*", ...props}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const id = useMemo(() => uuidv4(), []);

  return (
    <Fragment>
      <Box
        component="input"
        accept={accept}
        sx={{display: "none"}}
        id={id}
        type="file"
        onChange={e => {
          const uuid = uuidv4();
          dispatch(addSpinnerMessage(uuid, t("components.fileInputLocal.messages.upload.start")));

          const file = e.target.files[0];
          const reader = new FileReader();
          reader.onload = e => {
            const content = e.target.result;
            onChange(content);
          };
          reader.onloadend = e => {
            dispatch(markSpinnerMessage(uuid));
          };
          reader.readAsText(file);
        }}
        disabled={!!value}
      />
      <TextField
        variant="outlined"
        label={label}
        value={value || ""}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                marginRight: theme => theme.spacing(2)
              }}
              position="start"
            >
              <Tooltip title={t("components.fileInputLocal.upload.title")}>
                <label htmlFor={id}>
                  <Button startIcon={<PublishIcon />} variant="outlined" disabled={!!value} component="span">
                    {t("components.fileInputLocal.upload.label")}
                  </Button>
                </label>
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position="end">
              <Tooltip title={t("components.fileInputLocal.reset.title")}>
                <IconButton
                  onClick={() => {
                    if (onChange) {
                      onChange("");
                    }
                    const input = document.getElementById(id);
                    input.value = null;
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        {...props}
      />
    </Fragment>
  );
};

export default FileInputText;
