import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import {Box, IconButton, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ItemContainerBuilderCol} from "./ItemContainerBuilderCol";
import {useItemContainerBuilder} from "./useItemContainerBuilder";

const MAX_VIEW_PER_ROW = 4;

const rowActionStyle = {
  // width: "48px"
};

export const ItemContainerBuilderRow = ({row, rowIdx}) => {
  const {t} = useTranslation();
  const {handleRowRemove, handleColAdd, getRowItemProps, getRowMoveCursorProps, getColContainerProps} =
    useItemContainerBuilder();

  return (
    <Box
      style={{flexGrow: 1}}
      sx={{
        width: "100%"
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: "100%"
        }}
        {...getRowItemProps(rowIdx)}
      >
        {row.map((col, idx) => {
          return (
            <React.Fragment key={idx}>
              <div style={{display: "flex", width: "100%"}} {...getColContainerProps(rowIdx, idx)}>
                <ItemContainerBuilderCol rowIdx={rowIdx} colIdx={idx} col={col} />
              </div>
            </React.Fragment>
          );
        })}
        <Box
          style={{flexGrow: 1}}
          sx={{
            display: "flex",
            verticalAlign: "middle",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <Tooltip title={t("components.itemContainerBuilder.actions.addColumn")}>
            <span>
              <IconButton
                sx={rowActionStyle}
                onClick={() => handleColAdd(rowIdx)}
                disabled={row.length === MAX_VIEW_PER_ROW}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t("components.itemContainerBuilder.actions.removeRow")}>
            <IconButton sx={rowActionStyle} onClick={() => handleRowRemove(rowIdx)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("components.itemContainerBuilder.actions.moveRow")}>
            <IconButton {...getRowMoveCursorProps(rowIdx)}>
              <OpenWithIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};
