import React, {useState} from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import copy from "copy-to-clipboard";
import {useTranslation} from "react-i18next";

const Query = ({title, query}) => {
  const [isCopiedVisible, setCopiedVisibility] = useState(false);

  const {t} = useTranslation();

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: theme => theme.spacing(3)
      }}
    >
      <CardHeader
        sx={{
          fontSize: "17px",
          fontWeight: "bold",
          padding: "0 0 16px 0"
        }}
        title={title}
        disableTypography
      />
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid
          id="query-container"
          item
          xs={12}
          sx={{
            overflowWrap: "break-word",
            wordBreak: "break-all"
          }}
        >
          {query}
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              copy(query);
              setCopiedVisibility(prevVal => !prevVal);
            }}
          >
            {t("commons.confirm.copy")}
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={isCopiedVisible} autoHideDuration={2000} onClose={() => setCopiedVisibility(prevVal => !prevVal)}>
        <Alert severity="success" onClose={() => setCopiedVisibility(prevVal => !prevVal)}>
          {t("components.query.alert.queryCopied.label")}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default Query;
