import {ItemContainersMinimalInfoDto} from "../model/item-containers-models/itemContainersMinimalInfoDto";
import {ItemContainerTypes} from "../model/item-containers-models/itemContainerType";

export const ITEMCONTAINER_ELEM_TYPE_KEY = "type";
export const ITEMCONTAINER_ELEM_VALUE_KEY = "id";
export const ITEMCONTAINER_ELEM_WIDTH_KEY = "widthPercentage";
export const ITEMCONTAINER_ELEM_SHOW_TITLE_KEY = "showTitle";
export const ITEMCONTAINER_ELEM_ENABLE_FILTERS_KEY = "enableFilters";
export const ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY = "filterDimension";

export const ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW = "itemViewTemplateDto";
export const ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT = "itemRichTextDto";
export const ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY = "itemCategoryTemplateDto";

export const emptyItemContainerElem = {
  [ITEMCONTAINER_ELEM_TYPE_KEY]: null,
  [ITEMCONTAINER_ELEM_VALUE_KEY]: null,
  [ITEMCONTAINER_ELEM_WIDTH_KEY]: 100,
  [ITEMCONTAINER_ELEM_SHOW_TITLE_KEY]: true,
  [ITEMCONTAINER_ELEM_ENABLE_FILTERS_KEY]: false,
  [ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY]: null
};

export const getViewIdxFromRowAndColItemContainer = (rowIdx: number | string, colIdx: number | string) =>
  `${rowIdx}x${colIdx}`;

export const getFilterDimensionId = (layout, filterDimensionIds) => {
  const dimArraysKey = ["rows", "cols", "filters", "sections", "primaryDim", "secondaryDim", "territoryDim"];
  let viewDims = [];

  dimArraysKey.forEach(key => (viewDims = viewDims.concat(layout[key] || [])));

  return filterDimensionIds.find(dimId => viewDims.includes(dimId)) || null;
};

export const getCustomPagesInMenu = (itemContainers: ItemContainersMinimalInfoDto[], configNodeMenuId: string) => {
  const getConfigNodeMenu = (v: ItemContainersMinimalInfoDto) =>
    v.configNodes.find(c => c.itemContainerId === v.id).configMenus.find(m => m.menuId === configNodeMenuId);
  return (itemContainers || [])
    .filter(getConfigNodeMenu)
    .map(v => ({
      ...v,
      order: getConfigNodeMenu(v).order || 0,
      title:
        !getConfigNodeMenu(v)?.label || Object.values(getConfigNodeMenu(v)?.label).every(val => val === "")
          ? v.title
          : getConfigNodeMenu(v)?.label
    }))
    .sort((a, b) => a.order - b.order)
    .map(({order, ...rest}) => rest);
};

export const itemContainerTypeToServiceNameMap: Record<keyof typeof ItemContainerTypes, string> = {
  CustomPage: "customPages",
  Dashboard: "dashboard",
  Widget: "view-widgets",
  WidgetDashboard: "dashboard-widgets"
};
