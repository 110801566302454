import {PayloadAction} from "@reduxjs/toolkit";
import {Reducer} from "redux";
import {DASHBOARDS_OPTIMIZED_DATASET_FETCH} from "./dashboardActions";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {
  DASHBOARD_VIEW_STATUS_EMPTY_DATASET,
  DASHBOARD_VIEW_STATUS_REQUEST_ERROR,
  DASHBOARD_VIEW_STATUS_REQUEST_START
} from "../../utils/dashboards";

export type DashboardState = {
  jsonStats: any;
};

const initialState = {
  jsonStats: null
};

const dashboardReducer: Reducer<DashboardState, PayloadAction<any>> = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case DASHBOARDS_OPTIMIZED_DATASET_FETCH: {
          return {
            ...state,
            jsonStats: {
              ...state.jsonStats,
              [action.payload.extra.dashboardId]: {
                ...state.jsonStats?.[action.payload.extra.dashboardId],
                [action.payload.extra.viewIdx]: DASHBOARD_VIEW_STATUS_REQUEST_START
              }
            }
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DASHBOARDS_OPTIMIZED_DATASET_FETCH: {
          return {
            ...state,
            jsonStats: {
              ...state.jsonStats,
              [action.payload.extra.dashboardId]: {
                ...state.jsonStats?.[action.payload.extra.dashboardId],
                [action.payload.extra.viewIdx]:
                  (action.payload.response?.id || []).length > 0
                    ? action.payload.response
                    : DASHBOARD_VIEW_STATUS_EMPTY_DATASET
              }
            }
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DASHBOARDS_OPTIMIZED_DATASET_FETCH: {
          return {
            ...state,
            jsonStats: {
              ...state.jsonStats,
              [action.payload.extra.dashboardId]: {
                ...state.jsonStats?.[action.payload.extra.dashboardId],
                [action.payload.extra.viewIdx]: DASHBOARD_VIEW_STATUS_REQUEST_ERROR
              }
            }
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default dashboardReducer;
