import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import CustomEmpty from "../../custom-empty";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../../label-format-selector/constants";

const TerritorialClassificationsForm = ({territorialClassifications, config, setConfig}) => {
  const {t} = useTranslation();
  return (
    <div style={{maxHeight: 480}}>
      {!territorialClassifications ? (
        <CustomEmpty style={{height: 80}}>{t("components.table.territorialClassificationsForm.fetching")}</CustomEmpty>
      ) : (territorialClassifications || []).length === 0 ? (
        <CustomEmpty style={{height: 80}}>{t("components.table.territorialClassificationsForm.noValues")}</CustomEmpty>
      ) : (
        (territorialClassifications || []).map(({id, name, hasId}, idx) => {
          const currentTerrClassConfig = (config ?? []).find(c => c.id === id) ?? null;
          return (
            <Grid container spacing={1} key={idx}>
              <Grid item>
                <Checkbox
                  checked={currentTerrClassConfig !== null}
                  onChange={ev => {
                    if (ev.target.checked === true) {
                      const newConfig = _.cloneDeep(config);
                      newConfig.push({
                        id: id,
                        label: name,
                        format: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
                      });
                      const territorialClassificationIds = (territorialClassifications ?? []).map(({id}) => id);
                      newConfig.sort(
                        ({id: idA}, {id: idB}) =>
                          territorialClassificationIds.indexOf(idA) - territorialClassificationIds.indexOf(idB)
                      );
                      setConfig(newConfig);
                    } else {
                      setConfig((config ?? []).filter(({id: level}) => level !== id));
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  height: "42px",
                  lineHeight: "42px",
                  marginRight: "8px"
                }}
              >
                {name}
              </Grid>
              {currentTerrClassConfig !== null && (
                <Grid item>
                  <Select
                    value={currentTerrClassConfig?.format ?? LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}
                    onChange={ev => {
                      const newConfig = _.cloneDeep(config);
                      const elemToEdit = newConfig.find(val => val.id === id);
                      elemToEdit.format = ev.target.value;
                      setConfig(newConfig);
                    }}
                    disabled={hasId === false}
                  >
                    <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}>
                      {t("components.table.territorialClassificationsForm.labelFormat.name")}
                    </MenuItem>
                    <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}>
                      {t("components.table.territorialClassificationsForm.labelFormat.id")}
                    </MenuItem>
                    <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}>
                      {t("components.table.territorialClassificationsForm.labelFormat.both")}
                    </MenuItem>
                  </Select>
                </Grid>
              )}
            </Grid>
          );
        })
      )}
    </div>
  );
};

export default TerritorialClassificationsForm;
