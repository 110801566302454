import React, {Fragment} from "react";
import {Box} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {withTranslation} from "react-i18next";

export const FONT_SIZE_SELECTOR_FONT_SIZE_SM = "sm";
export const FONT_SIZE_SELECTOR_FONT_SIZE_MD = "md";
export const FONT_SIZE_SELECTOR_FONT_SIZE_LG = "lg";

const fontSizeIconStyle = {
  width: "24px",
  height: "24px",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

const fontSizeIconSmStyle = {fontSize: "14px"};
const fontSizeIconMdStyle = {fontSize: "16px"};
const fontSizeIconLgStyle = {fontSize: "20px"};
const fontSizeSelectedStyle = {boxShadow: "inset 0 -2px 0 0 rgba(0, 0, 0, .54)"};

const getFontSizeIcon = styleClasses => <Box sx={styleClasses}>A</Box>;

const FontSizeSelector = ({t, fontSize, setFontSize}) => (
  <Fragment>
    <Tooltip title={t("components.fontSizeSelector.small.tooltip")}>
      <IconButton
        onClick={() => setFontSize(FONT_SIZE_SELECTOR_FONT_SIZE_SM)}
        aria-label={t("components.fontSizeSelector.small.tooltip")}
      >
        {getFontSizeIcon([
          fontSizeIconStyle,
          fontSizeIconSmStyle,
          fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_SM && fontSizeSelectedStyle
        ])}
      </IconButton>
    </Tooltip>
    <Tooltip title={t("components.fontSizeSelector.normal.tooltip")}>
      <IconButton
        onClick={() => setFontSize(FONT_SIZE_SELECTOR_FONT_SIZE_MD)}
        aria-label={t("components.fontSizeSelector.normal.tooltip")}
      >
        {getFontSizeIcon([
          fontSizeIconStyle,
          fontSizeIconMdStyle,
          fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_MD && fontSizeSelectedStyle
        ])}
      </IconButton>
    </Tooltip>
    <Tooltip title={t("components.fontSizeSelector.big.tooltip")}>
      <IconButton
        onClick={() => setFontSize(FONT_SIZE_SELECTOR_FONT_SIZE_LG)}
        aria-label={t("components.fontSizeSelector.big.tooltip")}
      >
        {getFontSizeIcon([
          fontSizeIconStyle,
          fontSizeIconLgStyle,
          fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_LG && fontSizeSelectedStyle
        ])}
      </IconButton>
    </Tooltip>
  </Fragment>
);

export default withTranslation()(FontSizeSelector);
