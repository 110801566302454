import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Checkbox, FormControlLabel, Grid, Paper, useTheme} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import {merge} from "lodash";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomEmpty from "../../custom-empty";
import FileInput from "../../file-input";
import FileInputText from "../../file-input-text";
import FormLabelWithTooltip from "../../form-label-with-tooltip";
import I18nHtmlEditor from "../../i18n-html-editor";
import I18nInputAdornmentSelect from "../../i18n-input-adornment-select";
import I18nTextField from "../../i18n-text-field";
import {getMapLayerOptions} from "../../map/constants";
import SanitizedHTML from "../../sanitized-html";
import TabPanel from "../../tab-panel";
import CustomColorsForm from "../custom-colors-form/CustomColorsForm";
import {
  addHubConfigDashboardsDashboard,
  clearAllHubConfigDashboards,
  clearHubConfig,
  clearHubConfigDashboards,
  closeHubConfig,
  fetchAllHubConfigDashboards,
  fetchHubConfig,
  fetchHubConfigDashboards,
  removeHubConfigDashboardsDashboard,
  sendHubConfig,
  sendHubConfigDashboardsOrders
} from "../../../state/hubConfig/hubConfigActions";
import {localizeI18nObj} from "../../../utils/i18n";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";

const mapStateToProps = state => ({
  config: state.hubConfig.hub,
  user: state.user,
  dashboards: state.hubConfig.hubDashboards,
  allDashboards: state.hubConfig.allDashboards,
  mapLayersConfig: state.maps.mapLayersConfig,
  appLanguage: state.app.language,
  appLanguages: state.app.languages,
  modulesConfig: state.app.modulesConfig
});

const mapDispatchToProps = dispatch => ({
  clearConfig: () => dispatch(clearHubConfig()),
  fetchConfig: () => dispatch(fetchHubConfig()),
  sendConfig: config => dispatch(sendHubConfig(config)),
  fetchDashboards: () => dispatch(fetchHubConfigDashboards()),
  fetchAllDashboards: () => dispatch(fetchAllHubConfigDashboards()),
  clearDashboards: () => dispatch(clearHubConfigDashboards()),
  clearAllDashboards: () => dispatch(clearAllHubConfigDashboards()),
  addDashboard: dashboardId => dispatch(addHubConfigDashboardsDashboard(dashboardId)),
  removeDashboard: dashboardId => dispatch(removeHubConfigDashboardsDashboard(dashboardId)),
  sendDashboardsOrders: orderedDashboarIds => dispatch(sendHubConfigDashboardsOrders(orderedDashboarIds)),
  onClose: () => dispatch(closeHubConfig())
});

const Form = forwardRef(
  (
    {
      config,
      onSubmit,
      onCancel,
      user,
      dashboards,
      allDashboards,
      mapLayersConfig,
      appLanguage,
      appLanguages,
      modulesConfig,
      fetchDashboards,
      fetchAllDashboards,
      clearDashboards,
      clearAllDashboards,
      addDashboard,
      removeDashboard,
      sendDashboardsOrders
    },
    ref
  ) => {
    const {t} = useTranslation();
    const tabsRef = useRef();
    const theme = useTheme();
    const dashboardsManagerRef = useRef();

    const [tab, setTab] = useState("general");

    const [footerLang, setFooterLang] = useState(appLanguage);

    const defaultCustomColors = {
      isEnabled: false,
      palette: {
        text: {
          primary: theme.palette.text.primary
        },
        primary: {
          main: theme.palette.primary.main,
          contrastText: theme.palette.primary.contrastText
        },
        secondary: {
          main: theme.palette.secondary.main,
          contrastText: theme.palette.secondary.contrastText
        }
      }
    };

    const getCustomColorsFromExtra = config => {
      const customColorsValue = JSON.parse(config.extra.find(({key}) => key === "CustomColors")?.value || "{}");
      return merge(defaultCustomColors, customColorsValue);
    };

    const configExtras = config.extras ? JSON.parse(config.extras) : {};

    const appSettingsForm = useForm({
      defaultValues: {
        ...config,
        mapLayer: configExtras.MapLayer,
        treePageSize: configExtras.TreePageSize,
        headerLogoSmallURL: configExtras.HeaderLogoSmallURL,
        headerLogoAlt: configExtras.HeaderLogoAlt,
        headerLogoHref: configExtras.HeaderLogoHref,
        headerLogoTitle: configExtras.headerLogoTitle,
        disableArtefactsCache: configExtras.DisableArtefactsCache || false,
        customColors: config?.extra?.find(({key}) => key === "CustomColors")
          ? getCustomColorsFromExtra(config)
          : defaultCustomColors,
        footer: config?.extra?.find(({key}) => key === "Footer")
          ? JSON.parse(config.extra.find(({key}) => key === "Footer")?.value || "{}")
          : {}
      }
    });

    const {
      register,
      formState: {errors},
      handleSubmit,
      watch,
      setValue
    } = appSettingsForm;

    useImperativeHandle(ref, () => ({
      submit(f) {
        handleSubmit(val => {
          const data = {
            ...config,
            ...val,
            mapLayer: undefined,
            treePageSize: undefined,
            headerLogoSmallURL: undefined,
            headerLogoAlt: undefined,
            headerLogoHref: undefined,
            headerLogoTitle: undefined,
            disableArtefactsCache: undefined,
            customColors: undefined,
            footer: undefined
          };

          const extras = {
            ...JSON.parse(data.extras || "{}"),
            MapLayer: val.mapLayer,
            TreePageSize: val.treePageSize,
            HeaderLogoSmallURL: val.headerLogoSmallURL,
            HeaderLogoAlt: val.headerLogoAlt,
            HeaderLogoHref: val.headerLogoHref,
            headerLogoTitle: val.headerLogoTitle,
            DisableArtefactsCache: val.disableArtefactsCache
          };

          if (data.extra?.find(({key}) => key === "CustomColors")) {
            data.extra.find(({key}) => key === "CustomColors").value = JSON.stringify(val.customColors);
          } else {
            data.extra = data.extra || [];
            data.extra.push({
              key: "CustomColors",
              value: JSON.stringify(val.customColors),
              isPublic: true
            });
          }

          if (data.extra?.find(({key}) => key === "Footer")) {
            data.extra.find(({key}) => key === "Footer").value = JSON.stringify(val.footer);
          } else {
            data.extra = data.extra || [];
            data.extra.push({
              key: "Footer",
              value: JSON.stringify(val.footer),
              isPublic: true
            });
          }

          const clearedExtras = Object.keys(extras)
            .filter(k => extras[k] !== null && extras[k] !== undefined)
            .reduce((a, k) => ({...a, [k]: extras[k]}), {});

          data.extras = JSON.stringify(clearedExtras);

          onSubmit(data);
          f(data);
          if (dashboardsManagerRef.current) {
            dashboardsManagerRef.current.destroy();
          }
        })();

        const firstFieldWithErrors = Object.keys(errors)[0];
        if (firstFieldWithErrors) {
          switch (firstFieldWithErrors) {
            case "title":
            case "slogan":
            case "supportedLanguages":
            case "defaultLanguage":
            case "maxObservationsAfterCriteria":
            case "maxCells":
            case "treePageSize":
            case "backgroundMediaURL":
            case "logoURL":
            case "headerLogoURL": {
              setTab("general");
              break;
            }
            case "description": {
              setTab("infos");
              break;
            }
            case "footer": {
              setTab("footer");
              break;
            }
            case "disclaimer": {
              setTab("users");
              break;
            }
            case "mapLayer": {
              setTab("map");
              break;
            }
            case "cache": {
              setTab("cache");
              break;
            }
            default: {
            }
          }
        }
      },
      cancel(f) {
        onCancel();
        f();
        if (dashboardsManagerRef.current) {
          dashboardsManagerRef.current.destroy();
        }
      }
    }));

    /* custom register */
    useEffect(() => {
      register("title");
      register("slogan");
      register("supportedLanguages", {
        validate: val => val.length > 0 || t("commons.validation.required")
      });
      register("defaultLanguage", {
        required: t("commons.validation.required")
      });
      register("maxObservationsAfterCriteria", {
        required: t("commons.validation.required"),
        min: {
          value: 1,
          message: t("commons.validation.positiveInteger")
        }
      });
      register("maxCells", {
        required: t("commons.validation.required"),
        min: {
          value: 1,
          message: t("commons.validation.positiveInteger")
        }
      });
      register("treePageSize", {
        validate: val => !val || isValidIntegerInInclusiveRange(val, 1) || t("commons.validation.positiveInteger")
      });
      register("backgroundMediaURL");
      register("logoURL");
      register("headerLogoURL");
      register("headerLogoSmallURL");
      register("headerLogoAlt");
      register("headerLogoHref");
      register("headerLogoTitle");
      register("description");
      register("footer");
      register("disclaimer");
      register("mapLayer", {
        required: t("commons.validation.required")
      });
      register("disableArtefactsCache");
      register("customColors");
    }, [register, t]);

    const defaultLanguage = watch("defaultLanguage");
    const supportedLanguages = watch("supportedLanguages");

    const fieldStyle = {
      marginTop: theme.spacing(3)
    };

    return (
      <Fragment>
        <Box
          sx={{
            height: "100%"
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab}
            onChange={(_, tab) => {
              setTab(tab);
            }}
          >
            <Tab value="general" label={t("scenes.appSettings.tabs.general")} />
            <Tab value="infos" label={t("scenes.appSettings.tabs.information")} />
            <Tab value="footer" label={t("scenes.appSettings.tabs.footer")} />
            <Tab value="users" label={t("scenes.appSettings.tabs.users")} />
            <Tab value="map" label={t("scenes.appSettings.tabs.map")} />
            <Tab value="colors" label={t("scenes.appSettings.tabs.colors")} />
            <Tab value="cache" label={t("scenes.appSettings.tabs.cache")} />
          </Tabs>
          <Box
            ref={tabsRef}
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100% - 56px)",
              marginTop: "8px",
              padding: "0 4px"
            }}
          >
            <TabPanel value="general" selected={tab}>
              <FormControl fullWidth sx={fieldStyle}>
                <I18nTextField
                  name="title"
                  label={t("scenes.appSettings.fields.title.label")}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  variant="outlined"
                  value={watch("title") || {}}
                  onChange={value => setValue("title", value)}
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <I18nTextField
                  name="slogan"
                  label={t("scenes.appSettings.fields.slogan.label")}
                  variant="outlined"
                  value={watch("slogan") || {}}
                  onChange={value => setValue("slogan", value)}
                />
              </FormControl>
              <Autocomplete
                multiple
                variant="outlined"
                freeSolo
                options={[]}
                value={supportedLanguages || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({index})} />)
                }
                required
                onChange={(e, val) => {
                  setValue("supportedLanguages", val);
                  if (defaultLanguage && !val.includes(defaultLanguage)) {
                    setValue("defaultLanguage", "");
                  }
                }}
                renderInput={params => (
                  <FormControl fullWidth sx={fieldStyle}>
                    <TextField
                      label={t("scenes.appSettings.fields.supportedLanguages.label")}
                      {...params}
                      variant="outlined"
                      placeholder={t("scenes.appSettings.fields.supportedLanguages.placeholder")}
                      error={!!errors.supportedLanguages}
                      helperText={
                        errors.supportedLanguages?.message || (
                          <Fragment>
                            {t("scenes.appSettings.fields.supportedLanguages.helper.text")}
                            <a
                              href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{marginLeft: 4}}
                            >
                              {t("scenes.appSettings.fields.supportedLanguages.helper.linkText")}
                            </a>
                          </Fragment>
                        )
                      }
                    />
                  </FormControl>
                )}
              />
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  name="defaultLanguage"
                  select
                  required
                  label={t("scenes.appSettings.fields.defaultLanguage.label")}
                  onChange={e => setValue("defaultLanguage", e.target.value)}
                  value={watch("defaultLanguage") || ""}
                  error={!!errors.defaultLanguage}
                  helperText={errors.defaultLanguage?.message || t("scenes.appSettings.fields.defaultLanguage.helper")}
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  {supportedLanguages.map((val, index) => (
                    <MenuItem key={index} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  name="maxObservationsAfterCriteria"
                  label={t("scenes.appSettings.fields.maxObservationsAfterCriteria.label")}
                  required
                  type="number"
                  error={!!errors.maxObservationsAfterCriteria}
                  helperText={errors.maxObservationsAfterCriteria?.message}
                  variant="outlined"
                  value={watch("maxObservationsAfterCriteria") || ""}
                  onChange={({target}) => setValue("maxObservationsAfterCriteria", target.value)}
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  name="maxCells"
                  label={t("scenes.appSettings.fields.maxCells.label")}
                  required
                  error={!!errors.maxCells}
                  helperText={errors.maxCells?.message}
                  variant="outlined"
                  type="number"
                  value={watch("maxCells") || ""}
                  onChange={({target}) => setValue("maxCells", target.value)}
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  name="treePageSize"
                  label={t("scenes.appSettings.fields.treePageSize.label")}
                  error={!!errors.treePageSize}
                  helperText={errors.treePageSize?.message}
                  variant="outlined"
                  type="number"
                  value={watch("treePageSize") || ""}
                  onChange={({target}) => setValue("treePageSize", target.value)}
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FileInput
                  label={t("scenes.appSettings.fields.backgroundMediaURL.label")}
                  value={watch("backgroundMediaURL")}
                  onChange={value => setValue("backgroundMediaURL", value)}
                  enableDownload
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FileInput
                  label={t("scenes.appSettings.fields.logoURL.label")}
                  value={watch("logoURL")}
                  onChange={value => setValue("logoURL", value)}
                  enableDownload
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FileInput
                  label={t("scenes.appSettings.fields.headerLogoURL.label")}
                  value={watch("headerLogoURL")}
                  onChange={value => setValue("headerLogoURL", value)}
                  enableDownload
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FileInput
                  label={t("scenes.appSettings.fields.headerLogoSmallURL.label")}
                  value={watch("headerLogoSmallURL")}
                  onChange={value => setValue("headerLogoSmallURL", value)}
                  enableDownload
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <I18nTextField
                  name="headerLogoAlt"
                  label={
                    <FormLabelWithTooltip tooltip={t("scenes.appSettings.fields.headerLogoAlt.tooltip")}>
                      {t("scenes.appSettings.fields.headerLogoAlt.label")}
                    </FormLabelWithTooltip>
                  }
                  error={!!errors.headerLogoAlt}
                  helperText={errors.headerLogoAlt?.message}
                  variant="outlined"
                  value={watch("headerLogoAlt") || {}}
                  onChange={value => setValue("headerLogoAlt", value)}
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  name="headerLogoHref"
                  label={t("scenes.appSettings.fields.headerLogoHref.label")}
                  error={!!errors.headerLogoHref}
                  helperText={errors.headerLogoHref?.message}
                  variant="outlined"
                  value={watch("headerLogoHref") || ""}
                  onChange={({target}) => setValue("headerLogoHref", target.value)}
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <I18nTextField
                  name="headerLogoTitle"
                  label={t("scenes.appSettings.fields.headerLogoTitle.label")}
                  error={!!errors.headerLogoTitle}
                  helperText={errors.headerLogoTitle?.message}
                  variant="outlined"
                  value={watch("headerLogoTitle") || ""}
                  onChange={value => setValue("headerLogoTitle", value)}
                />
              </FormControl>
            </TabPanel>
            <TabPanel value="infos" selected={tab}>
              <I18nHtmlEditor value={watch("description")} onChange={val => setValue("description", val)} />
            </TabPanel>
            <TabPanel value="footer" selected={tab}>
              <Grid container display={"flex"} justifyContent={"flex-end"}>
                <Grid item sx={{mb: "4px"}}>
                  <I18nInputAdornmentSelect value={footerLang} onChange={lang => setFooterLang(lang)} />
                </Grid>
              </Grid>
              <Paper variant="outlined" sx={{padding: "16px"}}>
                <Grid container rowSpacing={3} direction={"column"}>
                  <Grid item>
                    <FormControl fullWidth>
                      <FileInputText
                        key={footerLang}
                        label={t("scenes.appSettings.fields.footer.label")}
                        value={watch("footer")[footerLang]}
                        onChange={value => setValue(`footer.${footerLang}`, value)}
                        accept=".html"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>{t("scenes.appSettings.fields.footer.preview.label")}:</Grid>
                  <Grid item>
                    <Box>
                      {(watch("footer")[footerLang] ?? "").length > 0 ? (
                        <SanitizedHTML html={watch("footer")[footerLang]} allowTarget />
                      ) : (
                        <CustomEmpty sx={{height: "64px"}} text={t("scenes.appSettings.fields.footer.preview.empty")} />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </TabPanel>
            <TabPanel value="users" selected={tab}>
              <Fragment>
                <Box sx={fieldStyle}>{t("scenes.appSettings.fields.disclaimer.label")}:</Box>
                <I18nHtmlEditor value={watch("disclaimer")} onChange={val => setValue("disclaimer", val)} />
              </Fragment>
            </TabPanel>
            <TabPanel value="map" selected={tab}>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  name="mapLayer"
                  select
                  required
                  label={t("scenes.nodeSettings.fields.mapLayer.label")}
                  onChange={e => setValue("mapLayer", e.target.value)}
                  value={watch("mapLayer") || ""}
                  error={!!errors.mapLayer}
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  {getMapLayerOptions(t).map(({value, label}, idx) => (
                    <MenuItem key={idx} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                  {(mapLayersConfig || []).map(({id, name}, idx) => (
                    <MenuItem key={idx} value={id}>
                      {name ? localizeI18nObj(name, appLanguage, appLanguages) : id}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </TabPanel>
            <TabPanel value="colors" selected={tab}>
              <FormProvider {...appSettingsForm}>
                <CustomColorsForm />
              </FormProvider>
            </TabPanel>
            <TabPanel value="cache" selected={tab}>
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.nodeSettings.fields.disableArtefactsCache.label")}
                  control={
                    <Checkbox
                      name="disableArtefactsCache"
                      required
                      checked={watch("disableArtefactsCache") || false}
                      onChange={(e, value) => setValue("disableArtefactsCache", value)}
                    />
                  }
                />
              </FormControl>
            </TabPanel>
          </Box>
        </Box>
      </Fragment>
    );
  }
);

const AppSettingsForm = (
  {
    config,
    fetchConfig,
    sendConfig,
    clearConfig,
    user,
    dashboards,
    allDashboards,
    mapLayersConfig,
    appLanguage,
    appLanguages,
    modulesConfig,
    fetchDashboards,
    fetchAllDashboards,
    clearDashboards,
    clearAllDashboards,
    addDashboard,
    removeDashboard,
    sendDashboardsOrders,
    onClose
  },
  ref
) => {
  const [needConfig, setNeedConfig] = useState(true);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig();
    }
  }, [config, needConfig, setNeedConfig, fetchConfig]);

  return (
    config && (
      <Form
        config={config}
        ref={ref}
        onSubmit={sendConfig}
        onCancel={() => {
          clearConfig();
          onClose();
        }}
        user={user}
        dashboards={dashboards}
        allDashboards={allDashboards}
        mapLayersConfig={mapLayersConfig}
        appLanguage={appLanguage}
        appLanguages={appLanguages}
        modulesConfig={modulesConfig}
        fetchDashboards={fetchDashboards}
        fetchAllDashboards={fetchAllDashboards}
        clearDashboards={clearDashboards}
        clearAllDashboards={clearAllDashboards}
        addDashboard={addDashboard}
        removeDashboard={removeDashboard}
        sendDashboardsOrders={sendDashboardsOrders}
      />
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(AppSettingsForm);
