import {getUserErrorsTranslations} from "../constants/getUserErrorsTranslations";

export const showGenericError = () => {
  if (window && window.error) {
    window.error.show("An error occurred while contacting the server.");
  } else {
    console.error("An error occurred while contacting the server.");
  }
};

export const showTranslatedGenericErrorFactory = t => () => {
  if (window && window.error) {
    window.error.show(t("errors.generic"));
  } else {
    console.error(t("errors.generic"));
  }
};

export const showTranslatedManagedServerErrorFactory = t => (_, __, responseData) => {
  const message = getUserErrorsTranslations(t)[responseData?.managedErrorCode] ?? t("errors.generic");
  if (window && window.error) {
    window.error.show(message);
  } else {
    console.error(message);
  }
};

export const getCombinationArrays = arrays => {
  if (!arrays || arrays.length === 0) {
    return [];
  }

  const ret = [];
  const max = arrays.length - 1;

  function helper(arr, i) {
    for (let j = 0, l = arrays[i].length; j < l; j++) {
      const a = arr.slice(0); // clone arr
      a.push(arrays[i][j]);
      if (i === max) {
        ret.push(a);
      } else {
        helper(a, i + 1);
      }
    }
  }

  helper([], 0);
  return ret;
};

export const getNthValorizedElementIndexInBooleanArray = (array, n) => {
  let count = 0;
  let res = 0;
  let lastIdx = 0;
  let found = false;

  array.forEach((el, idx) => {
    if (el === true) {
      count++;
      lastIdx = idx;
      if (count === n + 1) {
        res = idx;
        found = true;
      }
    }
  });

  return found ? res : lastIdx + 1;
};

export const getDatasetStorageKey = dataset => {
  return (
    (dataset.nodeCode || "").toLowerCase() +
    "+" +
    ((dataset.categoryPath || []).join() || "").toLowerCase() +
    "+" +
    (dataset.datasetId || "").toLowerCase() +
    "+" +
    (dataset.viewId || "").toLowerCase()
  );
};

const getColorLuminanceFromRgba = rgbaColor => {
  const rgbaArr = rgbaColor.substring(5, rgbaColor.length - 1).split(", ");
  const rgbArr = [
    (1 - rgbaArr[3]) * 255 + rgbaArr[3] * rgbaArr[0],
    (1 - rgbaArr[3]) * 255 + rgbaArr[3] * rgbaArr[1],
    (1 - rgbaArr[3]) * 255 + rgbaArr[3] * rgbaArr[2]
  ];

  const getL = c => {
    c = c / 255;
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  };

  const rL = getL(rgbArr[0]);
  const gL = getL(rgbArr[1]);
  const bL = getL(rgbArr[2]);

  return rL * 0.2126 + gL * 0.7152 + bL * 0.0722;
};

// computing font color to have a correct contrast ratio for readability based on https://stackoverflow.com/a/3943023/10488412
export const getLightOrDarkColorBasedOnContrastRatio = (rgbaColor, rgbaLightColor, rgbaDarkColor) => {
  const colorL = getColorLuminanceFromRgba(rgbaColor);
  const lightColorL = rgbaLightColor ? getColorLuminanceFromRgba(rgbaLightColor) : 1;
  const darkColorL = rgbaDarkColor ? getColorLuminanceFromRgba(rgbaDarkColor) : 0;

  return (colorL + 0.05) / (darkColorL + 0.05) > (lightColorL + 0.05) / (colorL + 0.05)
    ? rgbaDarkColor || "rgba(0, 0, 0, 1)"
    : rgbaLightColor || "rgba(255, 255, 255, 1)";
};

export const getFlatArray = arrays => {
  let array;
  if (Array.prototype.flat) {
    array = arrays.flat();
  } else {
    array = [];
    arrays.forEach(arr => (array = array.concat(arr)));
  }

  return array;
};

export const getPageTitle = (list, t) => {
  const valorizedList = list.filter(el => el !== null && el !== undefined);
  const title = valorizedList.join(" | ");

  return title.length > 0 ? title : t ? t("scenes.hub.title") : "Data Browser";
};

export const areElementsOverlap = (el1Id, el2Id) => {
  const el1 = document.getElementById(el1Id);
  const el2 = document.getElementById(el2Id);

  const domRect1 = el1.getBoundingClientRect();
  const domRect2 = el2.getBoundingClientRect();

  return !(
    domRect1.top > domRect2.bottom ||
    domRect1.right < domRect2.left ||
    domRect1.bottom < domRect2.top ||
    domRect1.left > domRect2.right
  );
};

export const isStrCaseInsensitiveEquals = (str1, str2) => (str1 || "").toLowerCase() === (str2 || "").toLowerCase();

export const getClonedCanvas = $oldCanvas => {
  const oldCanvas = $oldCanvas.get(0);

  // create a new canvas
  const newCanvas = document.createElement("canvas");
  const context = newCanvas.getContext("2d");

  // set dimensions
  newCanvas.width = oldCanvas.width;
  newCanvas.height = oldCanvas.height;
  newCanvas.style.width = oldCanvas.offsetWidth + "px";
  newCanvas.style.height = oldCanvas.offsetHeight + "px";

  // apply the old canvas to the new one
  context.drawImage(oldCanvas, 0, 0);

  // return the new canvas
  return window.jQuery(newCanvas);
};

/**
 *
 * @param {boolean} isEnteringFullscreen
 * @returns {void}
 */
export const toggleFullscreen = isEnteringFullscreen => {
  const appContainer = document.getElementById("app-container");
  appContainer.style.visibility = isEnteringFullscreen ? "hidden" : "";
  const fullscreenContainer = document.getElementById("fullscreen-container");
  fullscreenContainer.style.display = isEnteringFullscreen ? "block" : "none";
};
