import React, {Fragment} from "react";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {compose} from "redux";
import {goToHome} from "../../links";
import CustomEmpty from "../custom-empty";
import Footer from "../footer";
import Header from "../header";
import Page from "../page";
import {getPageTitle} from "../../utils/other";

const mapStateToProps = state => ({
  hub: state.hub,
  baseURL: state.config.baseURL
});

const Error = ({t, hub}) => {
  const location = useLocation();

  return (
    <Fragment>
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page
        title={getPageTitle(
          [t("scenes.error.title") === "scenes.error.title" ? "Errore" : t("scenes.error.title"), hub?.hub?.name],
          t
        )}
      >
        <Header emptyHeader />
        <Box
          component="main"
          id="main"
          sx={{
            backgroundColor: "#f5f5f5",
            width: "100%",
            height: "100%",
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            "& #footer": {
              paddingTop: "0px"
            }
          }}
        >
          <CustomEmpty
            text={
              <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item id="error-page__text__main">
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      color: theme => theme.palette.primary.main,
                      fontSize: "48px"
                    }}
                  >
                    {t("scenes.error.text.main") === "scenes.error.text.main"
                      ? "Si è verificato un errore."
                      : t("scenes.error.text.main")}
                  </Typography>
                </Grid>
                <Grid item id="error-page__text__secondary">
                  <Typography variant="subtitle1" align="center" sx={{color: theme => theme.palette.primary.main}}>
                    {t("scenes.error.text.secondary") === "scenes.error.text.secondary"
                      ? "L'URL richiesto non è stato trovato su questo server."
                      : t("scenes.error.text.secondary")}
                  </Typography>
                </Grid>
                <Grid item id="error-page__button">
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{marginTop: "48px"}}
                    onClick={() => {
                      goToHome();
                      window.location.reload();
                    }}
                  >
                    {t("scenes.error.button") === "scenes.error.button"
                      ? "Vai alla homepage"
                      : t("scenes.error.button")}
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <Footer />
        </Box>
      </Page>
    </Fragment>
  );
};

export default compose(withTranslation(), connect(mapStateToProps))(Error);
