import React, {Fragment, useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import {SketchPicker} from "react-color";
import {withTranslation} from "react-i18next";
import InfiniteScrollTable from "../infinite-scroll-table";
import Selector from "../selector";

export const CHART_COLORS_ALL_DIMENSION_VALUES_KEY = "ALL_ITEMS";
const getAllDimValuesLabel = t => t("components.chartSettings.colors.allDimValues.label");

const classes = {
  root: {
    width: "100%",
    height: "100%"
  },
  title: {
    width: "calc(100% - 80px)",
    marginBottom: "8px"
  },
  scrollableRows: {
    height: "calc(100% - 53px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  rows: {
    marginBottom: "16px"
  },
  rowDimension: {
    fontSize: "14px",
    lineHeight: "48px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  item: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "calc(100% - 80px)"
  },
  itemLabel: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "calc(50% - 8px)",
    height: "48px",
    lineHeight: "48px",
    marginRight: "8px",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  itemColor: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "calc(50% - 8px)",
    height: "32px",
    marginRight: "8px"
  },
  rowActions: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "80px"
  }
};

const getClearedColors = (colors, dataHasDimension, dataHasDimensionValue) => {
  const newColors = {};

  Object.keys(colors || {}).forEach(dimKey => {
    if (dataHasDimension(dimKey)) {
      Object.keys(colors[dimKey]).forEach(dimValKey => {
        if (dataHasDimensionValue(dimKey, dimValKey) || dimValKey === CHART_COLORS_ALL_DIMENSION_VALUES_KEY) {
          if (colors[dimKey][dimValKey]) {
            newColors[dimKey] = {...newColors[dimKey]};
            newColors[dimKey][dimValKey] = colors[dimKey][dimValKey];
          }
        }
      });
    }
  });

  return newColors;
};

const Row = ({t, dimension, items, onColorEdit, onColorRemove, getDimLabel, getDimValueLabel}) => (
  <Grid container spacing={2} sx={{marginTop: 0}}>
    <Grid item xs={4}>
      <Tooltip title={getDimLabel(dimension)}>
        <Box sx={classes.rowDimension}>{getDimLabel(dimension)}</Box>
      </Tooltip>
    </Grid>
    <Grid item xs={8}>
      {Object.keys(items)
        .map((item, idx) => {
          const label = getDimValueLabel(dimension, item)
            ? getDimValueLabel(dimension, item)
            : item === CHART_COLORS_ALL_DIMENSION_VALUES_KEY
            ? getAllDimValuesLabel(t)
            : item;

          return (
            <Fragment key={idx}>
              <Box sx={classes.item}>
                <Tooltip title={label}>
                  <Box sx={classes.itemLabel}>{label}</Box>
                </Tooltip>
                <Box
                  sx={classes.itemColor}
                  style={{
                    background: items[item],
                    border: `1px solid ${items[item]}`,
                    borderRadius: "4px"
                  }}
                />
              </Box>
              <Box sx={classes.rowActions}>
                <Tooltip title={t("components.chartSettings.colors.row.actions.edit.title")}>
                  <IconButton color="primary" onClick={() => onColorEdit(dimension, item, items[item])}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("components.chartSettings.colors.row.actions.delete.title")}>
                  <IconButton color="primary" onClick={() => onColorRemove(dimension, item)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Fragment>
          );
        })
        .sort((a, b) => {
          if (a === CHART_COLORS_ALL_DIMENSION_VALUES_KEY) {
            return 1;
          } else if (b === CHART_COLORS_ALL_DIMENSION_VALUES_KEY) {
            return -1;
          } else {
            return 0;
          }
        })}
    </Grid>
  </Grid>
);

const defaultColor = "rgba(0, 0, 0, 0.1)";

const selectorStyle = {
  width: "100%"
};

function ChartSettingsColors(props) {
  const {
    t,
    settings,
    dimensions,
    onSettingsSet,
    dimensionValuesFilter,
    dataHasDimension,
    dataHasDimensionValue,
    getDimLabel,
    getDimValueLabel,
    getDimensionValues
  } = props;

  const [colors, setColors] = useState(false);

  const [isAddColorVisible, setAddColorVisibility] = useState(false);

  const [newDim, setNewDim] = useState(null);
  const [newDimVal, setNewDimVal] = useState(null);
  const [newColor, setNewColor] = useState(defaultColor);

  const [data, setData] = useState(null);
  const [isDimension, setIsDimension] = useState(true);

  const [isColorPickerVisible, setColorPickerVisibility] = useState(false);

  const [tmpDim, setTmpDim] = useState(null);
  const [tmpDimVal, setTmpDimVal] = useState(null);
  const [tmpColor, setTmpColor] = useState(null);

  useEffect(() => {
    setColors(getClearedColors(settings.colors, dataHasDimension, dataHasDimensionValue));
  }, [settings, dataHasDimension, dataHasDimensionValue]);

  const handleAddColorClose = () => {
    setAddColorVisibility(false);

    setNewDim(null);
    setNewDimVal(null);
    setNewColor(defaultColor);
  };

  const handleAddColorSubmit = () => {
    setAddColorVisibility(false);

    const newColors = _.cloneDeep(colors);
    newColors[newDim] = {...newColors[newDim]};
    newColors[newDim][newDimVal] = newColor;
    onSettingsSet({...settings, colors: newColors});

    setNewDim(null);
    setNewDimVal(null);
    setNewColor(defaultColor);
  };

  const handleColorRemove = (dim, dimVal) => {
    const newColors = _.cloneDeep(colors);
    newColors[dim][dimVal] = undefined;
    onSettingsSet({...settings, colors: getClearedColors(newColors, dataHasDimension, dataHasDimensionValue)});
  };

  const handleColorEdit = (dim, dimVal, color) => {
    setTmpDim(dim);
    setTmpDimVal(dimVal);
    setTmpColor(color);
    setColorPickerVisibility(true);
  };

  const handleColorPickerClose = () => {
    setTmpDim(null);
    setTmpDimVal(null);
    setTmpColor(null);
    setColorPickerVisibility(false);
  };

  return (
    <Fragment>
      <Box sx={classes.root}>
        <Box sx={classes.title}>
          <Grid container spacing={2} sx={{marginTop: 0, fontSize: 14}}>
            <Grid item xs={4}>
              <Grid container justifyContent="center">
                {t("components.chartSettings.colors.dimension.label")}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="center">
                {t("components.chartSettings.colors.dimensionValue.label")}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="center">
                {t("components.chartSettings.colors.color.label")}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={classes.scrollableRows}>
          <Box sx={classes.rows}>
            {Object.keys(colors).map((dimension, idx) => (
              <Fragment key={idx}>
                <Row
                  t={t}
                  dimension={dimension}
                  items={colors[dimension]}
                  onColorEdit={handleColorEdit}
                  onColorRemove={handleColorRemove}
                  getDimLabel={getDimLabel}
                  getDimValueLabel={getDimValueLabel}
                  s
                />
                <Divider />
              </Fragment>
            ))}
          </Box>
          <Grid container justifyContent="center">
            <Button endIcon={<AddIcon />} onClick={() => setAddColorVisibility(true)}>
              {t("components.chartSettings.colors.actions.addColor.label")}
            </Button>
          </Grid>
        </Box>
      </Box>

      <Dialog open={isAddColorVisible} onClose={handleAddColorClose} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container>
            <Grid item container spacing={2} sx={{marginBottom: "16px", fontSize: 14}}>
              <Grid item xs={4}>
                <Grid container justifyContent="center">
                  {t("components.chartSettings.colors.dimension.label")}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container justifyContent="center">
                  {t("components.chartSettings.colors.dimensionValue.label")}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container justifyContent="center">
                  {t("components.chartSettings.colors.color.label")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={4}>
                <Selector
                  value={newDim}
                  render={dim => (dim ? getDimLabel(dim) || dim : "")}
                  selectTitle={t("components.chartSettings.colors.addColor.selectDimension.title")}
                  onSelect={() => {
                    setIsDimension(true);
                    setData(
                      dimensions.map(dim => ({
                        id: dim,
                        label: getDimLabel(dim) || dim
                      }))
                    );
                  }}
                  resetTitle={t("components.chartSettings.colors.addColor.resetDimension.title")}
                  onReset={() => {
                    setNewDim(null);
                    setNewDimVal(null);
                  }}
                  sx={selectorStyle}
                />
              </Grid>
              <Grid item xs={4}>
                {newDim && (
                  <Selector
                    value={newDimVal}
                    render={dimVal =>
                      dimVal
                        ? getDimValueLabel(newDim, dimVal)
                          ? getDimValueLabel(newDim, dimVal)
                          : dimVal === CHART_COLORS_ALL_DIMENSION_VALUES_KEY
                          ? getAllDimValuesLabel(t)
                          : dimVal
                        : ""
                    }
                    selectTitle={t("components.chartSettings.colors.addColor.selectDimensionValue.title")}
                    onSelect={() => {
                      setIsDimension(false);
                      const data = [
                        {
                          id: CHART_COLORS_ALL_DIMENSION_VALUES_KEY,
                          label: getAllDimValuesLabel(t)
                        }
                      ];
                      getDimensionValues(newDim)
                        .filter(dimVal => !dimensionValuesFilter || dimensionValuesFilter(newDim, dimVal))
                        .forEach(dimVal =>
                          data.push({
                            id: dimVal,
                            label: getDimValueLabel(newDim, dimVal) || dimVal
                          })
                        );
                      setData(data);
                    }}
                    resetTitle={t("components.chartSettings.colors.addColor.resetDimensionValue.title")}
                    onReset={() => setNewDimVal(null)}
                    sx={selectorStyle}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {newDimVal && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: newColor,
                      border: `1px solid ${newColor}`,
                      borderRadius: "4px",
                      cursor: "pointer"
                    }}
                    onClick={() => setColorPickerVisibility(true)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddColorClose}>{t("commons.confirm.cancel")}</Button>
          <Button onClick={handleAddColorSubmit} disabled={!newDim || !newDimVal || !newColor}>
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={data !== null} onClose={() => setData(null)} fullWidth maxWidth="md">
        <DialogContent>
          <InfiniteScrollTable
            data={data}
            getRowKey={({id}) => id}
            showHeader={false}
            columns={[
              {
                title: "",
                dataIndex: "label",
                render: (_, {label}) => label,
                minWidth: 100
              }
            ]}
            onRowClick={({id}) => {
              if (isDimension) {
                if (id !== newDim) {
                  setNewDim(id);
                  setNewDimVal(null);
                }
              } else {
                if (id !== newDimVal) {
                  setNewDimVal(id);
                }
              }
              setData(null);
            }}
            height={400}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setData(null)} color="primary">
            {t("commons.confirm.cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isColorPickerVisible} onClose={handleColorPickerClose} maxWidth="sm">
        <DialogContent sx={{padding: "0px"}}>
          <SketchPicker
            color={tmpColor || newColor}
            onChange={({rgb}) => {
              const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
              if (tmpDim && tmpDimVal && tmpColor) {
                setTmpColor(rgba);
                const newColors = _.cloneDeep(colors);
                newColors[tmpDim][tmpDimVal] = rgba;
                onSettingsSet({...settings, colors: newColors});
              } else {
                setNewColor(rgba);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleColorPickerClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default withTranslation()(ChartSettingsColors);
