import {getDatasetUrl} from "../../serverApi/urls";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {getCriteriaArrayFromObject} from "../../utils/criteria";

export const DASHBOARDS_OPTIMIZED_DATASET_FETCH = "dashboards/optimizedDataset/fetch";

export const fetchDashboardsOptimizedDataset = (
  dashboardId: number,
  viewIdx: string,
  nodeId: number,
  datasetId: string,
  criteria: any,
  lastNObservations: number | null
) =>
  initRequest(
    DASHBOARDS_OPTIMIZED_DATASET_FETCH,
    getDatasetUrl(nodeId, datasetId, lastNObservations),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    undefined,
    {
      dashboardId: dashboardId,
      viewIdx: viewIdx
    },
    undefined,
    (statusCode: number) => statusCode === 413 || statusCode === 414,
    true
  );
