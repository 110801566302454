import React, {Fragment} from "react";
import {Box, Typography} from "@mui/material";

const WidgetViewer = ({widgetUrl, datasetTitle}) => {
  return (
    <Fragment>
      <Typography
        variant={"h1"}
        sx={{
          fontSize: "28px",
          fontWeight: "300px",
          letterSpacing: "0px",
          padding: "16px 16px 8px 16px"
        }}
      >
        {datasetTitle}
      </Typography>
      <Box
        component={"iframe"}
        title={"WidgetViewer"}
        src={widgetUrl}
        sx={{
          border: 0,
          width: "100%",
          height: "100%"
        }}
      />
    </Fragment>
  );
};

export default WidgetViewer;
