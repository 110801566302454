import React, {useEffect} from "react";
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {IHubMinimalNode} from "../../../model/IHubMinimalNode";
import {ConfigNodeFormInfo} from "../../../model/item-containers-models/configNodeFormInfo";
import {ConfigMenuDto} from "../../../model/item-containers-models/configNodeMenuDto";
import {ConfigNodeMenuId} from "../../../model/item-containers-models/configNodeMenuId";
import CustomDialogTitle from "../../custom-dialog-title";
import I18nTextField from "../../i18n-text-field";
import {hubNodesSelector} from "../../../state/hub/hubSelectors";
import {currentItemContainerSelector} from "../../../state/otherConfig/otherConfigSelectors";

interface CustomPageNodeSettingsFormDialogProps {
  open: boolean;
  onClose: () => void;
  initialValues?: ConfigNodeFormInfo | null;
  onSubmit: (v: ConfigNodeFormInfo) => void;
}

interface CustomPageNodeSettingsForm extends ConfigNodeFormInfo {
  menus: {
    sidenav: {active: boolean; order: number; label: Record<string, string>};
    central: {active: boolean; order: number; label: Record<string, string>};
  };
}

const CustomPageNodeSettingsFormDialog = ({
  open,
  onClose,
  initialValues,
  onSubmit
}: CustomPageNodeSettingsFormDialogProps) => {
  const {t} = useTranslation();
  const sidenavMenu = initialValues?.configMenus?.find(v => v.menuId === ConfigNodeMenuId.SIDENAV);
  const centralMenu = initialValues?.configMenus?.find(v => v.menuId === ConfigNodeMenuId.CENTRAL);
  const itemContainer = useSelector(currentItemContainerSelector);
  const nodes = useSelector(hubNodesSelector);
  let hubList = [{nodeId: -1, name: "Hub"} as IHubMinimalNode];
  let nodeList = [...nodes];

  const getConfigurableNodes = () => {
    if (!initialValues) {
      if (itemContainer.configHubs && itemContainer.configHubs.length > 0) {
        hubList = hubList.filter(node => node.nodeId !== -1);
      }
      if (itemContainer.configNodes) {
        nodeList = nodeList.filter(node => !itemContainer.configNodes.some(n => n.nodeId === node.nodeId));
      }
    }
    return hubList.concat(nodeList);
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
    setValue
  } = useForm<CustomPageNodeSettingsForm>({
    defaultValues: {
      nodeId: initialValues?.nodeId || getConfigurableNodes()[0]?.nodeId || -1,
      isHomePage: initialValues?.isHomePage || false,
      itemContainerId: itemContainer.id || null,
      menus: {
        sidenav: {
          active: !!sidenavMenu,
          order: sidenavMenu?.order || 0,
          label: initialValues?.sidenavLabel || {}
        },
        central: {
          active: !!centralMenu,
          order: centralMenu?.order || 0,
          label: initialValues?.centralLabel || {}
        }
      }
    }
  });
  const submitHandler: SubmitHandler<CustomPageNodeSettingsForm> = data => {
    const configMenus: ConfigMenuDto[] = Object.entries(data.menus)
      .filter(([_, value]) => value.active)
      .map(([key, value]) => ({
        menuId: {
          sidenav: ConfigNodeMenuId.SIDENAV,
          central: ConfigNodeMenuId.CENTRAL
        }[key],
        order: value.order,
        label: {
          sidenav: data.menus.sidenav.label,
          central: data.menus.central.label
        }[key],
        configNodeId: data.nodeId
      }));
    onSubmit({
      nodeId: data.nodeId,
      isHomePage: data.isHomePage,
      centralOrder: data.menus.central.order,
      centralLabel: data.menus.central.label,
      sidenavOrder: data.menus.sidenav.order,
      sidenavLabel: data.menus.sidenav.label,
      itemContainerId: itemContainer.id,
      configMenus: configMenus
    });
    onClose();
  };
  const sidenavMenuActive = watch("menus.sidenav.active");
  const centralMenuActive = watch("menus.central.active");
  const watchNodeId = watch("nodeId");

  useEffect(() => {
    if (watchNodeId === -1) {
      setValue("menus.sidenav.active", false);
      setValue("menus.central.active", false);
    }
  }, [setValue, watchNodeId]);

  return (
    <Dialog open={open} onClose={onClose} max-Width={"sm"} fullWidth>
      <form onSubmit={handleSubmit(submitHandler)}>
        <CustomDialogTitle onClose={onClose}>
          {initialValues
            ? t("components.customPageNodeSettings.modal.title.edit")
            : t("components.customPageNodeSettings.modal.title.add")}
        </CustomDialogTitle>
        <DialogContent>
          <FormControl
            variant="outlined"
            sx={{
              margin: "1em 0",
              minWidth: 120
            }}
          >
            <InputLabel id="nodeId">{t(`components.customPageNodeSettingsForm.select.nodeSelector.label`)}</InputLabel>
            <Controller
              render={({field: {onChange, value}}) => (
                <Select
                  onChange={e => onChange(e.target.value as number)}
                  value={value}
                  labelId="nodeId"
                  label="Node"
                  disabled={!!initialValues}
                >
                  {getConfigurableNodes().map(v => (
                    <MenuItem key={v.nodeId} value={v.nodeId}>
                      {v.name ? v.name : v.nodeId}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              rules={{required: true}}
              name="nodeId"
            />
          </FormControl>
          <FormGroup>
            <Paper
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: theme => theme.spacing(2)
              }}
            >
              <CardContent>
                <FormControlLabel
                  label={t(`components.customPageNodeSettingsForm.checkBox.isHomepage`)}
                  control={
                    <Controller
                      render={({field}) => <Checkbox {...field} checked={watch("isHomePage")} />}
                      name="isHomePage"
                      control={control}
                    />
                  }
                />
              </CardContent>
            </Paper>
            <Paper
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: theme => theme.spacing(2)
              }}
            >
              <CardHeader subheader={t("components.customPageNodeSettingsForm.paper.header")} />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: theme => theme.spacing(0)
                }}
              >
                <FormControlLabel
                  label={t(`components.customPageNodeSettingsForm.checkBox.sidenav`)}
                  control={
                    <Controller
                      render={({field}) => (
                        <Checkbox {...field} disabled={watchNodeId === -1} checked={watch("menus.sidenav.active")} />
                      )}
                      name="menus.sidenav.active"
                      control={control}
                    />
                  }
                />
                {sidenavMenuActive && (
                  <FormGroup aria-describedby="sidenav">
                    <Controller
                      render={({field}) => (
                        <TextField
                          {...field}
                          error={!!errors.menus?.sidenav}
                          type="number"
                          variant="outlined"
                          label={t(`components.customPageNodeSettingsForm.select.orderSelector.label`)}
                        />
                      )}
                      control={control}
                      rules={{
                        required: true,
                        min: {value: 0, message: "Errore"}
                      }}
                      name="menus.sidenav.order"
                    />
                    <Box my={1} />
                    <Controller
                      render={({field}) => (
                        <I18nTextField
                          {...field}
                          fullWidth
                          label={t("components.customPageNodeSettingsForm.label.sidenav")}
                          variant="outlined"
                        />
                      )}
                      control={control}
                      name="menus.sidenav.label"
                    ></Controller>
                  </FormGroup>
                )}
                <FormControlLabel
                  label={t(`components.customPageNodeSettingsForm.checkBox.central`)}
                  control={
                    <Controller
                      render={({field}) => (
                        <Checkbox {...field} disabled={watchNodeId === -1} checked={watch("menus.central.active")} />
                      )}
                      name="menus.central.active"
                      control={control}
                    />
                  }
                />
                {centralMenuActive && (
                  <FormGroup aria-describedby="central">
                    <Controller
                      render={({field}) => (
                        <TextField
                          {...field}
                          error={errors.menus?.central !== undefined}
                          type="number"
                          variant="outlined"
                          label={t(`components.customPageNodeSettingsForm.select.orderSelector.label`)}
                        />
                      )}
                      control={control}
                      rules={{
                        required: true,
                        min: {value: 0, message: "Errore"}
                      }}
                      name="menus.central.order"
                    />
                    <Box my={1} />
                    <Controller
                      render={({field}) => (
                        <I18nTextField
                          {...field}
                          fullWidth
                          label={t("components.customPageNodeSettingsForm.label.central")}
                          variant="outlined"
                        />
                      )}
                      control={control}
                      name="menus.central.label"
                    ></Controller>
                  </FormGroup>
                )}
              </CardContent>
            </Paper>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} type="button">
            {t("commons.confirm.close")}
          </Button>
          <Button type="submit">{t("commons.confirm.save")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomPageNodeSettingsFormDialog;
