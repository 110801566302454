import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import {Box, FormControlLabel, Grid, GridSize, Link, Paper, Radio, RadioGroup} from "@mui/material";
import {useSelector} from "react-redux";
import {CategoryModelView} from "../../model/item-containers-models/categoryModelView";
import CategoryTitleSection from "./CategoryTitleSection";
import {configSelector} from "../../state/config/configSelectors";

interface CatalogPortionPreviewProps {
  childrenCategories: CategoryModelView[];
  setInitialCategory: (id: string) => void;
  partialPath: CategoryModelView[];
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

interface CategoryRadioProps {
  hasImage: boolean;
  categoryImage: string;
  categoryTitle: string;
  id: string;
}

function CategoryRadio({hasImage, categoryImage, categoryTitle, ...props}: CategoryRadioProps) {
  const baseUrl = useSelector(configSelector).baseURL;

  return (
    <Radio
      className="item_container_category_preview__radio"
      sx={{
        width: "100%",
        height: "100%",
        padding: 0
      }}
      disableRipple
      checkedIcon={
        hasImage ? (
          <Box
            className="item_container_category_preview__radio__item-with-image--checked"
            component={"img"}
            src={baseUrl + categoryImage}
            alt="selected category"
            sx={{
              borderRadius: "4px",
              width: "100%",
              transform: "scale(1.1)"
            }}
          />
        ) : (
          <Paper
            className="item_container_category_preview__radio__item--checked"
            sx={{
              width: "100%",
              height: "100%",
              padding: "16px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <FolderIcon sx={{marginRight: "8px"}} />
            <Link sx={{textDecoration: "underline", fontSize: 20}}>{categoryTitle}</Link>
          </Paper>
        )
      }
      icon={
        hasImage ? (
          <Box
            className="item_container_category_preview__radio__item-with-image"
            component={"img"}
            src={baseUrl + categoryImage}
            alt="unselected category"
            sx={{
              borderRadius: "4px",
              width: "100%"
            }}
          />
        ) : (
          <Paper
            className="item_container_category_preview__radio__item"
            sx={{
              width: "100%",
              height: "100%",
              padding: "16px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <FolderIcon sx={{marginRight: "8px"}} />
            <Link sx={{textDecoration: "none", fontSize: 20}}>{categoryTitle}</Link>
          </Paper>
        )
      }
      {...props}
    />
  );
}

interface CategoryRadioContainerProps {
  allCategoriesHaveImage: boolean;
  categoryRadioProps: CategoryRadioProps[];
  render: (prop: CategoryRadioProps) => React.ReactNode;
  columnDimensionsConfig: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

const CategoryRadioContainer = ({
  allCategoriesHaveImage,
  categoryRadioProps,
  render,
  columnDimensionsConfig
}: CategoryRadioContainerProps) =>
  allCategoriesHaveImage ? (
    <Box
      className="item_container_category_preview_image_container"
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(10px, 1fr))"
      gap={"8px"}
      sx={{
        "& > *": {
          maxWidth: "150px"
        }
      }}
    >
      {categoryRadioProps.map(radioProps => (
        <React.Fragment key={radioProps.categoryTitle}>{render(radioProps)}</React.Fragment>
      ))}
    </Box>
  ) : (
    <Grid container direction="row" wrap="wrap" spacing={2} className="item_container_category_preview_grid_container">
      {categoryRadioProps.map(radioProps => (
        <Grid
          className="item_container_category_preview_grid_item"
          item
          key={radioProps.categoryTitle}
          xs={(columnDimensionsConfig.xs as GridSize) || 4}
          sm={(columnDimensionsConfig.sm as GridSize) || 4}
          md={(columnDimensionsConfig.md as GridSize) || 4}
          lg={(columnDimensionsConfig.lg as GridSize) || 4}
          xl={(columnDimensionsConfig.xl as GridSize) || 4}
        >
          {render(radioProps)}
        </Grid>
      ))}
    </Grid>
  );

const CatalogPortionPreview = ({
  childrenCategories,
  setInitialCategory,
  partialPath,
  xs,
  sm,
  md,
  lg,
  xl
}: CatalogPortionPreviewProps) => {
  const allCategoriesHaveImage = childrenCategories.every(c => !!c.image);
  const imageUrl = partialPath?.[0]?.image;
  const description = partialPath?.[0]?.description;
  const label = partialPath?.[0]?.label;

  return (
    <Box>
      <RadioGroup
        defaultValue={childrenCategories[0].id.toString()}
        style={{
          padding: 0,
          display: "block"
        }}
        className="item_container_category_preview__radio-group"
      >
        <CategoryRadioContainer
          allCategoriesHaveImage={allCategoriesHaveImage}
          categoryRadioProps={childrenCategories.map(c => ({
            hasImage: allCategoriesHaveImage,
            categoryImage: c.image,
            categoryTitle: c.label,
            id: c.id
          }))}
          columnDimensionsConfig={{xs, sm, md, lg, xl}}
          render={c => (
            <FormControlLabel
              value={c.id.toString()}
              sx={{
                width: "100%",
                height: "100%",
                flex: 1
              }}
              onChange={(e: any) => setInitialCategory(e.target.value)}
              label=""
              control={
                <CategoryRadio
                  id={c.id}
                  hasImage={allCategoriesHaveImage}
                  categoryImage={c?.categoryImage}
                  categoryTitle={c.categoryTitle}
                />
              }
            />
          )}
        />
      </RadioGroup>
      <CategoryTitleSection imageUrl={imageUrl} label={label} description={description} />
    </Box>
  );
};

export default CatalogPortionPreview;
