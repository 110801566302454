import React from "react";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import {Box, useTheme} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {withTranslation} from "react-i18next";
import {CHART_TYPE_PYRAMID} from "../chart/constants";
import {viewersFactory} from "../data-viewer/constant";

const dimValueStyle = {
  width: "calc(50% - 32px)",
  fontSize: "16px",
  padding: "16px"
};

function ChartSettingsLayout(props) {
  const {t, layout, onLayoutSet, type, onTypeSet, getDimLabel} = props;

  const theme = useTheme();
  const fieldStyle = {
    marginBottom: theme.spacing(3)
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      <FormControl sx={fieldStyle} fullWidth>
        <TextField
          select
          label={t("components.chartSettings.layout.chartType.label")}
          variant="outlined"
          value={type}
          onChange={ev => onTypeSet(ev.target.value)}
          SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
        >
          {viewersFactory(t)
            .slice(2)
            .filter(({type}) => type !== CHART_TYPE_PYRAMID)
            .map(({type, title}, idx) => (
              <MenuItem key={idx} value={type}>
                {title}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
      {layout.primaryDim[0] && layout.secondaryDim[0] && (
        <Paper
          variant="outlined"
          sx={{
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
            "& :last-child": {
              marginBottom: "0px"
            }
          }}
        >
          <Grid container sx={fieldStyle}>
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                width: "calc(50% - 36px)"
              }}
            >
              {t("components.chartSettings.layout.primaryDim.label")}
            </Box>
            <Box sx={{width: "72px"}} />
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                width: "calc(50% - 36px)"
              }}
            >
              {t("components.chartSettings.layout.secondaryDim.label")}
            </Box>
          </Grid>
          <Grid container sx={fieldStyle}>
            <Paper variant="outlined" sx={dimValueStyle}>
              {getDimLabel(layout.primaryDim[0])}
            </Paper>
            <Box
              sx={{
                margin: "0 8px",
                height: "56px",
                lineHeight: "56px"
              }}
            >
              <IconButton
                onClick={() =>
                  onLayoutSet({
                    ...layout,
                    primaryDim: layout.secondaryDim,
                    primaryDimValues: layout.secondaryDimValues,
                    secondaryDim: layout.primaryDim,
                    secondaryDimValues: layout.primaryDimValues
                  })
                }
              >
                <SwapHorizIcon />
              </IconButton>
            </Box>
            <Paper variant="outlined" sx={dimValueStyle}>
              {getDimLabel(layout.secondaryDim[0])}
            </Paper>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default withTranslation()(ChartSettingsLayout);
