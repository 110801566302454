import _ from "lodash";
import {getViewerTypeFromIdx} from "../../../components/data-viewer/constant";
import {RoundingMode} from "../../../model/wshub-models/roundingMode";
import {ChartSettings, Criteria, MapSettings, ViewerMode} from "../constants";
import {CRITERIA_FILTER_TYPE_CODES, getCriteriaArrayFromObject} from "../../../utils/criteria";
import {getNodes, renameTreeChildrenKey} from "../../../utils/tree";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

export type DatasetSingleViewerState = {
  datasetUuid: string | null;
  dataset: any | null;
  isFirstFetch: boolean;
  datasetFetchStart: boolean;
  datasetFetchError: boolean;
  isPartialData: boolean;
  isEmptyData: boolean;
  isTooBigData: boolean;
  isTooLongQuery: boolean;
  isCriteriaVisible: boolean;
  isCriteriaAlertVisible: boolean;
  isObsCountWarningVisible: boolean;
  dimensions: any[] | null;
  dimensionsInfo: {[key: string]: any} | {};
  territoryDim: string | null;
  timeDim: string | null;
  freqDim: string | null;
  viewerIdx: number | null;
  view: any | null;
  template: any | null;
  hasViewLayout: boolean;
  hasTemplateLayout: boolean;
  hasAnnotationLayout: boolean;
  isLayoutVisible: boolean;
  tableLayout: any | null;
  mapLayout: any | null;
  chartLayoutCartesian: any | null;
  chartLayoutRadial: any | null;
  tableDefaultFiltersValue: {[key: string]: string} | null;
  chartDefaultFiltersValue: {[key: string]: string} | null;
  tableFilterTree: any | null;
  mapFilterTree: any | null;
  chartFilterTreeCartesian: any | null;
  chartFilterTreeRadial: any | null;
  timePeriodsByFreq: any | null;
  labelFormat: string | null;
  customLabelFormats: {[key: string]: string} | {};
  temporalDimOrder: string | null;
  showTrend: boolean;
  showCyclical: boolean;
  splitHeaderCells: boolean;
  splitSideCells: boolean;
  criteria: {[key: string]: Criteria} | {};
  initialCriteria: {[key: string]: Criteria} | {};
  decimalSeparator: string | null;
  roundingStrategy: RoundingMode | null;
  decimalPlaces: number | null;
  tableEmptyChar: string;
  chartSettings: ChartSettings;
  mapSettings: MapSettings;
  enableCriteria: boolean;
  enableLayout: boolean;
  enableVariation: boolean;
  codelists: {[key: string]: any[]} | null;
  hierarchicalCodelists: {[key: string]: {[key: string]: any}} | null;
  codelistsLength: (number | null)[] | null;
  codelistFetchError: boolean;
  criteriaObsCount: number | null;
  timings: any | null;
  isFetchDatasetDisabled: boolean;
  isDownloadWarningVisible: boolean;
  isUnavailableViewWarningVisible: boolean;
  isViewVisible: boolean;
  isViewErrorVisible: boolean;
  viewErrorMessage: string | null;
  isTemplateVisible: boolean;
  isQueryVisible: boolean;
  structureQuery: string | null;
  dataQuery: string | null;
  detailLevel: number | null;
  isFullscreen: boolean;
  isTableEnabled: boolean;
  isMapEnabled: boolean;
  isChartEnabled: boolean;
  tableLockedDimensions: string[] | null;
  graphLockedDimensions: string[] | null;
  missingFilterValues: any[] | null;
  checkDatasetSize: boolean;
  lastNObservations: number | null;
  attributesAsDim: string[] | null;
};

export const getViewTemplateBackupFromState = (state: DatasetSingleViewerState) => ({
  mode: ViewerMode.SingleViewer,
  defaultView: getViewerTypeFromIdx(state.viewerIdx),
  criteria: getCriteriaArrayFromObject(state.criteria),
  layouts: JSON.stringify({
    detailLevel: state.detailLevel,
    labelFormat: state.labelFormat,
    temporalDimOrder: state.temporalDimOrder,
    showTrend: state.showTrend,
    showCyclical: state.showCyclical,
    tableLayout: state.tableLayout,
    tableEmptyChar: state.tableEmptyChar,
    mapLayout: state.mapLayout,
    ...getViewTemplateLayoutsFromMapSettings(state.mapSettings),
    chartLayoutCartesian: state.chartLayoutCartesian,
    chartLayoutRadial: state.chartLayoutRadial,
    ...getViewTemplateLayoutsFromChartSettings(state.chartSettings)
  })
});

export const getUpdatedSVStateDataOnHierarchySelect = (
  state: DatasetSingleViewerState,
  dimensionId: string,
  hierarchyId: string,
  hierarchyValues: any,
  tabId: number,
  selectedKeys: string[]
) => {
  const newCodelistsLength: (number | null)[] = _.cloneDeep(state.codelistsLength) || [];
  const newCodelists: {[key: string]: any[]} = _.cloneDeep(state.codelists) || {};
  const newCriteria: {[key: string]: Criteria} = _.cloneDeep(state.criteria);

  let length = 0;
  let codelist = [];
  let selectedHierarchy: string = null;
  if (hierarchyValues && hierarchyId.length > 0) {
    const hierarchy = hierarchyValues[hierarchyId];
    if (hierarchy) {
      selectedHierarchy = hierarchyId;
      codelist = renameTreeChildrenKey(hierarchy, "hierarchyCodes", "children", null);
      const nodes = getNodes(hierarchy, "hierarchyCodes", node => node.isSelectable);
      length = nodes.length;
    }
  }

  newCriteria[dimensionId] = {
    id: dimensionId,
    type: CRITERIA_FILTER_TYPE_CODES,
    filterValues: selectedKeys || [],
    period: undefined,
    from: undefined,
    to: undefined,
    hierarchyId: selectedHierarchy
  };

  newCodelists[dimensionId] = codelist;
  newCodelistsLength[tabId] = length;

  return {
    codelists: newCodelists,
    codelistsLength: newCodelistsLength,
    criteria: newCriteria
  };
};
