import SearchIcon from "@mui/icons-material/Search";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {openSearchDialog} from "../../state/search-dialog/searchDialogActions";

const SearchButton = ({noNode, node, catalog}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  if (!noNode && catalog && !catalog.isEmpty) {
    return (
      <Grid item className="search-btn">
        <Tooltip title={t("components.header.actions.search.title")}>
          <IconButton
            id="header_search_button_btn"
            color="inherit"
            onClick={() => dispatch(openSearchDialog())}
            aria-label={t("components.header.actions.search.ariaLabel")}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    );
  } else {
    return null;
  }
};

export default SearchButton;
