import {createSelector} from "@reduxjs/toolkit";
import rootSelector from "../rootSelector";

const hubStateSelector = createSelector(rootSelector, v => v?.hub);

export const hubSelector = createSelector(hubStateSelector, v => v?.hub);
export const hubNodesSelector = createSelector(hubStateSelector, v => v?.nodes);

export const hubExtraSelector = createSelector(hubSelector, v => v?.extra);
export const hubFooterSelector = createSelector(hubExtraSelector, v =>
  (v || [])?.find(({key}) => key === "Footer") ? JSON.parse(v.find(({key}) => key === "Footer")?.value || "{}") : {}
);

const hubItemContainersSelector = createSelector(hubSelector, v => v?.itemContainers);
export const hubDashboardsSelector = createSelector(
  hubItemContainersSelector,
  itemContainers => itemContainers?.dashboard || []
);
export const hubCustomPagesSelector = createSelector(
  hubItemContainersSelector,
  itemContainers => itemContainers?.customPages || []
);
export const hubCustomHomePageIdSelector = createSelector(
  hubCustomPagesSelector,
  hubCustomPages =>
    hubCustomPages
      ?.map(v => v.configHubs)
      .reduce((acc, cur) => acc.concat(cur), [])
      .find(v => v.isHomePage)?.itemContainerId
);
