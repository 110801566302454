import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import CustomEmpty from "../custom-empty";
import Chart from "./Chart";
import {CHART_TYPE_PYRAMID, getChartType} from "./constants";
import {
  getAttributeValueLabelFromCombinations,
  getDimensionLabelFromCombinations,
  getDimensionValueLabelFromCombinations
} from "../../utils/dataset";

const getFilterDims = (dimensions, chartLayout) =>
  dimensions.filter(dim => chartLayout.secondaryDim.includes(dim) || chartLayout.filters.includes(dim));

const getData = (
  chartType,
  dataset,
  layout,
  dimensions,
  dimensionLabels,
  dimensionMap,
  observationAttributeMap,
  seriesAttributeMap
) => {
  const datasetValues = dataset.values;
  const datasetDimensionsAttributes = dataset.dimensionsAttributes;

  const {
    primaryDim: primaryDimArr,
    primaryDimValues,
    secondaryDim: secondaryDimArr,
    secondaryDimValues,
    filtersValue
  } = layout;

  const primaryDim = primaryDimArr[0];
  const primaryDimensionAttributes = datasetDimensionsAttributes ? datasetDimensionsAttributes[primaryDim] : null;

  if (chartType === CHART_TYPE_PYRAMID) {
    primaryDimValues.reverse();
  }

  const secondaryDim = secondaryDimArr[0];
  const secondaryDimensionAttributes = datasetDimensionsAttributes ? datasetDimensionsAttributes[secondaryDim] : null;

  let datasets = [];

  const getDataset = (secondaryDimValue, secDimIdx) => {
    const datasets = [];

    const getDataAndAttributes = filtersValue => {
      const data = [];
      const dataAttributes = [];

      let secondaryDimAttributes = null;
      if (secondaryDimensionAttributes) {
        const secondaryDimValueAttributes = secondaryDimensionAttributes[secondaryDimValue];
        if (secondaryDimValueAttributes && secondaryDimValueAttributes.length) {
          secondaryDimAttributes = [];
          secondaryDimValueAttributes.forEach(({id, value}) => {
            const {attrLabel, valueLabel} = getAttributeValueLabelFromCombinations(id, value, seriesAttributeMap);
            secondaryDimAttributes.push({
              id: id,
              valueId: value,
              label: attrLabel,
              valueLabel: valueLabel
            });
          });
        }
      }

      primaryDimValues.forEach((primaryDimValue, _) => {
        let dimValueArray = dimensions.map(dim => {
          if (dim === primaryDim) {
            return primaryDimValue;
          } else if (secondaryDim && dim === secondaryDim) {
            return secondaryDimValue;
          } else if (filtersValue[dim]) {
            return filtersValue[dim];
          } else {
            return null;
          }
        });

        if (dimValueArray) {
          const valueKey = dimValueArray.join("+");
          const obsValueAttribute = datasetValues[valueKey];
          const obsValue = obsValueAttribute ? obsValueAttribute.value : null;
          const value = obsValue !== null && obsValue !== "" && !isNaN(obsValue) ? Number(obsValue) : null;

          if (value !== null) {
            if (
              secondaryDim &&
              chartType === CHART_TYPE_PYRAMID &&
              secondaryDimValues.length === 2 &&
              secDimIdx === 0
            ) {
              data.push(-value);
            } else {
              data.push(value);
            }
          } else {
            data.push(null);
          }

          let observation = null;
          const observationAttributes = obsValueAttribute ? obsValueAttribute.attributes : null;
          if (observationAttributes !== null && observationAttributes.length) {
            observation = [];
            observationAttributes.forEach(({id, value}) => {
              const {attrLabel, valueLabel} = getAttributeValueLabelFromCombinations(
                id,
                value,
                observationAttributeMap
              );
              observation.push({
                id: id,
                valueId: value,
                label: attrLabel,
                valueLabel: valueLabel
              });
            });
          }

          let primaryDimAttributes = null;
          if (primaryDimensionAttributes) {
            const primDimValueAttributes = primaryDimensionAttributes[primaryDimValue];
            if (primDimValueAttributes && primDimValueAttributes.length) {
              primaryDimAttributes = [];
              primDimValueAttributes.forEach(({id, value}) => {
                const {attrLabel, valueLabel} = getAttributeValueLabelFromCombinations(id, value, seriesAttributeMap);
                primaryDimAttributes.push({
                  id: id,
                  valueId: value,
                  label: attrLabel,
                  valueLabel: valueLabel
                });
              });
            }
          }

          dataAttributes.push({
            observation: observation,
            primaryDim: primaryDimAttributes,
            secondaryDim: secondaryDimAttributes
          });
        } else {
          data.push(null);
          dataAttributes.push({
            observation: null,
            primaryDim: null,
            secondaryDim: null
          });
        }
      });

      return {
        data: data,
        dataAttributes: dataAttributes
      };
    };

    const filtersValueFull = {...filtersValue};

    if (secondaryDim) {
      filtersValueFull[secondaryDim] = secondaryDimValue;
    }

    const {data, dataAttributes} = getDataAndAttributes(filtersValueFull);
    const label = secondaryDim
      ? getDimensionValueLabelFromCombinations(secondaryDim, secondaryDimValue, dimensionMap)
      : "";

    datasets.push({
      type: getChartType(chartType),
      data: data,
      label: label,
      dimValue: secondaryDimValue,
      dataAttributes: dataAttributes,
      filtersValue: filtersValueFull
    });
    return datasets;
  };

  if (secondaryDimValues && secondaryDimValues.length > 0) {
    secondaryDimValues.forEach((secondaryDimValue, secDimIdx) => {
      datasets = datasets.concat(getDataset(secondaryDimValue, secDimIdx));
    });
  } else {
    datasets = datasets.concat(getDataset());
  }

  const primaryDimInfo = {
    valuesInfo: [],
    label: getDimensionLabelFromCombinations(primaryDim, dimensionLabels)
  };

  primaryDimValues.forEach((value, _) => {
    primaryDimInfo.valuesInfo.push({
      label: getDimensionValueLabelFromCombinations(primaryDim, value, dimensionMap),
      dimValue: value
    });
  });

  return {
    primaryDimInfo: primaryDimInfo,
    datasets: datasets,
    filterDims: getFilterDims(dimensions, layout)
  };
};

const getArithmeticMeans = (arithmeticMeansDimensions, dataArithmeticMeans, arithmeticMeanDims) => {
  let arithmeticMeans = {};
  const keyArrLength = arithmeticMeanDims.length;
  Object.keys(dataArithmeticMeans).forEach(dataKey => {
    const dataKeyArr = dataKey.split("+");
    let chartKeyArr = new Array(keyArrLength);
    dataKeyArr.forEach((k, idx) => {
      const dataDim = arithmeticMeansDimensions[idx];
      const index = arithmeticMeanDims.indexOf(dataDim);
      chartKeyArr[index] = k;
    });
    const chartKey = chartKeyArr.join("+");
    arithmeticMeans[chartKey] = dataArithmeticMeans[dataKey];
  });

  return arithmeticMeans;
};

const ChartCombinationsWrapper = props => {
  const {
    chartId,
    type,
    dataset,
    seriesAttributeMap,
    observationAttributeMap,
    dimensions,
    dimensionLabels,
    dimensionMap,
    layout,
    disableWheelZoom,
    labelFormat,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    chartSettings,
    showArithmeticMean,
    dataArithmeticMeans,
    arithmeticMeansDimensions
  } = props;

  const {t} = useTranslation();

  const data = useMemo(() => {
    return dataset
      ? getData(
          type,
          dataset,
          layout,
          dimensions,
          dimensionLabels,
          dimensionMap,
          observationAttributeMap,
          seriesAttributeMap
        )
      : null;
  }, [dataset, dimensionLabels, dimensionMap, dimensions, layout, observationAttributeMap, seriesAttributeMap, type]);

  const arithmeticMeans = useMemo(() => {
    return data && arithmeticMeansDimensions && dataArithmeticMeans
      ? getArithmeticMeans(arithmeticMeansDimensions, dataArithmeticMeans, data.filterDims)
      : null;
  }, [arithmeticMeansDimensions, data, dataArithmeticMeans]);

  return data == null ? (
    <CustomEmpty text={t("components.chart.loading") + "..."} />
  ) : (
    <Chart
      chartId={chartId}
      chartData={data}
      type={type}
      layout={layout}
      disableWheelZoom={disableWheelZoom}
      labelFormat={labelFormat}
      decimalSeparator={decimalSeparator}
      roundingStrategy={roundingStrategy}
      decimalPlaces={decimalPlaces}
      chartSettings={chartSettings}
      showTrend={false}
      showCyclical={false}
      showArithmeticMean={showArithmeticMean}
      arithmeticMeanDims={data?.filterDims}
      arithmeticMeans={arithmeticMeans}
    />
  );
};

export default ChartCombinationsWrapper;
