import React, {useEffect, useRef} from "react";
import {Box} from "@mui/material";
import Card from "@mui/material/Card";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemRichTextDto} from "../../model/item-containers-models/itemRichTextDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import CustomEmpty from "../custom-empty";
import ItemContainerCategoriesList from "../item-container-categories-list";
import {itemIsCategory, itemIsText, itemIsView} from "../item-containers";
import SanitizedHTML from "../sanitized-html";
import FullscreenProvider from "./FullscreenContext";
import OptimizedViewItem from "./OtimizedViewItem";
import ViewItem from "./ViewItem";
import {appConfigStateSelector} from "../../state/appConfig/appConfigSelectors";
import useLanguages from "../../state/hooks/useLanguages";
import {
  getViewIdxFromRowAndColItemContainer,
  ITEMCONTAINER_ELEM_TYPE_KEY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW,
  ITEMCONTAINER_ELEM_WIDTH_KEY
} from "../../utils/itemContainers";
import {handleStyle} from "./utils";

const VIEW_FIXED_HEIGHT = 580;

interface ItemContainerColProps {
  itemContainer: ItemContainerDto;
  itemContainerElem: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto;
  rowIdx: number;
  colIdx: number;
}

function ItemContainerCol({itemContainer, rowIdx, colIdx, itemContainerElem}: ItemContainerColProps) {
  const [searchParams] = useSearchParams();
  const {t, localizeI18nObj} = useLanguages();

  const appConfig = useSelector(appConfigStateSelector);

  const itemContainerId = itemContainer.id;
  const minItemContainerWidth = appConfig.minItemContainerWidth;

  const itemIdx = getViewIdxFromRowAndColItemContainer(rowIdx, colIdx);

  const mapId = useRef("map__" + uuidv4());
  const chartId = useRef("chart__" + uuidv4());

  useEffect(() => {
    const func = () =>
      handleStyle(
        itemContainerId,
        itemIdx,
        itemContainerElem,
        minItemContainerWidth,
        itemContainer.type === "Widget" &&
          itemIsView(itemContainerElem) &&
          itemContainerElem.viewTemplate.defaultView === "map"
          ? mapId
          : null
      );
    window.addEventListener("resize", func);
    return () => {
      window.removeEventListener("resize", func);
    };
  }, [itemContainerId, itemIdx, itemContainerElem, minItemContainerWidth, itemContainer.type, mapId]);

  useEffect(() => {
    handleStyle(itemContainerId, itemIdx, itemContainerElem, minItemContainerWidth);
  }, [itemContainerId, itemIdx, itemContainerElem, minItemContainerWidth]);

  return (
    <Box
      id={`itemContainer_${itemContainerId}__col__${itemIdx}`}
      className="itemContainer_col"
      sx={{
        display: "inline-block",
        verticalAlign: "top",
        padding: "8px",
        width: `${window.innerWidth > minItemContainerWidth ? itemContainerElem[ITEMCONTAINER_ELEM_WIDTH_KEY] : 100}%`
      }}
    >
      <FullscreenProvider item={itemContainerElem} itemIdx={itemIdx}>
        <Card
          id={`itemContainer_${itemContainerId}__view-container__${itemIdx}`}
          className="itemContainer_view-container"
          sx={{
            position: "relative",
            padding: "16px",
            background: "#FFFFFF !important",
            width: "100%",
            height: itemIsView(itemContainerElem)
              ? itemContainer.type === "Widget"
                ? searchParams.get("showTitle") === "true"
                  ? "91vh"
                  : "97vh"
                : VIEW_FIXED_HEIGHT
              : "auto",
            minHeight: itemIsView(itemContainerElem) ? VIEW_FIXED_HEIGHT : "auto",
            overflowY:
              itemContainerElem[ITEMCONTAINER_ELEM_TYPE_KEY] === ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW ? "hidden" : "auto",
            overflowX: "hidden"
          }}
        >
          {itemIsText(itemContainerElem) ? (
            <SanitizedHTML html={localizeI18nObj(itemContainerElem.data)} allowTarget />
          ) : itemIsCategory(itemContainerElem) ? (
            (itemContainerElem.catalog?.categoryGroups ?? []).length > 0 ? (
              <ItemContainerCategoriesList item={itemContainerElem} />
            ) : (
              <CustomEmpty text={t("components.itemContainer.missingCategory")} />
            )
          ) : itemIsView(itemContainerElem) ? (
            itemContainerElem.viewTemplate ? (
              itemContainerElem.viewTemplate.allPartialOptimizedInfo &&
              (itemContainerElem.viewTemplate?.notCodedDimensions ?? []).length === 0 ? (
                <OptimizedViewItem
                  itemContainer={itemContainer}
                  itemContainerElem={itemContainerElem}
                  viewIdx={itemIdx}
                  minItemContainerWidth={minItemContainerWidth}
                  mapId={mapId.current}
                  chartId={chartId.current}
                />
              ) : (
                <ViewItem
                  itemContainer={itemContainer}
                  itemContainerElem={itemContainerElem}
                  viewIdx={itemIdx}
                  minItemContainerWidth={minItemContainerWidth}
                  mapId={mapId.current}
                  chartId={chartId.current}
                />
              )
            ) : (
              <CustomEmpty text={t("components.itemContainer.missingView")} />
            )
          ) : (
            <span />
          )}
        </Card>
      </FullscreenProvider>
    </Box>
  );
}

export default ItemContainerCol;
