import {getHubCustomPagesUrl, getHubDashboardsUrl, getHubMinimalInfoUrl} from "../../serverApi/urls";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

export const HUB_FETCH = "hub/fetch";
export const HUB_CLEAR = "hub/clear";
export const HUB_FETCH_CUSTOM_ITEM_CONTAINERS = "hub/fetchItemContainerConfigs";

export const fetchHub = () =>
  initRequest(HUB_FETCH, getHubMinimalInfoUrl(), undefined, undefined, t => ({
    onStart: t("domains.hub.messages.fetch.start")
  }));

export const clearHub = () => ({
  type: HUB_CLEAR
});

export const fetchHubItemContainers = (type: "dashboard" | "customPages") =>
  external(
    initRequest(
      HUB_FETCH_CUSTOM_ITEM_CONTAINERS,
      type === "dashboard" ? getHubDashboardsUrl() : getHubCustomPagesUrl(),
      RequestMethod.GET,
      undefined,
      t => ({
        onStart:
          type === "dashboard"
            ? t("domains.hub.messages.dashboards.fetchMinimalInfo.start")
            : t("domains.hub.messages.customPages.fetchMinimalInfo.start")
      }),
      {type},
      undefined,
      undefined,
      true
    ),
    type
  );
