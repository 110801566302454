import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import {DragDropContext} from "react-beautiful-dnd";
import {withTranslation} from "react-i18next";
import {ERROR_SNACKBAR_SEVERITY_WARNING} from "../error-snackbar/ErrorSnackbar";
import LayoutSection from "../layout-section";

const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const [removed] = source.splice(droppableSource.index, 1);
  destination.splice(droppableDestination.index, 0, removed);
};

const swap = (sourceArr, destinationArr, droppableSource) => {
  const [removedSrc] = sourceArr.splice(droppableSource.index, 1);
  const [removedDst] = destinationArr.splice(0, 1, removedSrc);
  sourceArr.splice(sourceArr.length, 0, removedDst);
};

const onDragEnd = (result, dimensions, setDimensions, lockedDimensions, t) => {
  const {source, destination} = result;

  // dropped outside the list
  if (!destination) {
    return;
  }

  const newDimensions = JSON.parse(JSON.stringify(dimensions));

  if (source.droppableId === destination.droppableId) {
    reorder(newDimensions[source.droppableId], source.index, destination.index);
  } else {
    const srcDimension = newDimensions[source.droppableId][source.index];

    if (lockedDimensions.includes(srcDimension)) {
      window.error.show(t("components.tableLayout.drag.alert"));
      return;
    }

    if (
      (source.droppableId === "secondaryDim" && destination.droppableId === "filters") ||
      (source.droppableId === "filters" &&
        destination.droppableId === "secondaryDim" &&
        newDimensions[destination.droppableId].length === 0)
    ) {
      move(newDimensions[source.droppableId], newDimensions[destination.droppableId], source, destination);
    } else {
      const swapDimension = newDimensions[destination.droppableId][0];

      if (lockedDimensions.includes(swapDimension)) {
        window.error.show(t("components.tableLayout.drag.alert"), ERROR_SNACKBAR_SEVERITY_WARNING);
        return;
      }

      swap(newDimensions[source.droppableId], newDimensions[destination.droppableId], source);
    }
  }

  setDimensions(newDimensions);
};

const ChartLayout = ({t, layout, dimensionsInfo, lockedDimensions, setLayout, alertText}) => {
  const {primaryDim, secondaryDim, filters} = layout;

  const layoutLockedDimensions = lockedDimensions || [];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      <DragDropContext onDragEnd={result => onDragEnd(result, layout, setLayout, layoutLockedDimensions, t)}>
        <Grid container sx={{height: "100%"}}>
          {alertText && (
            <Grid item xs={12}>
              <Alert severity="warning" sx={{marginBottom: "8px"}}>
                {alertText}
              </Alert>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            sx={{
              paddingRight: "8px",
              height: "480px"
            }}
          >
            <LayoutSection
              id="filters"
              title={t("components.chartLayout.filters.title")}
              Icon={<FilterListIcon />}
              dimensions={filters}
              dimensionsInfo={dimensionsInfo}
              lockedDimensions={layoutLockedDimensions}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" sx={{height: "100%"}}>
              <Grid
                item
                xs={6}
                sx={{
                  paddingLeft: "8px",
                  paddingBottom: "8px",
                  height: "240px",
                  maxWidth: "100%",
                  "&:last-child": {
                    paddingBottom: "0px"
                  }
                }}
              >
                <LayoutSection
                  id="primaryDim"
                  title={t("components.chartLayout.primaryDim.title")}
                  Icon={<ViewCompactIcon />}
                  dimensions={primaryDim}
                  dimensionsInfo={dimensionsInfo}
                  disabled
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  paddingLeft: "8px",
                  paddingBottom: "8px",
                  height: "240px",
                  maxWidth: "100%",
                  "&:last-child": {
                    paddingBottom: "0px"
                  }
                }}
              >
                <LayoutSection
                  id="secondaryDim"
                  title={t("components.chartLayout.secondaryDim.title")}
                  Icon={<ViewStreamIcon />}
                  dimensions={secondaryDim}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DragDropContext>
    </Box>
  );
};

export default withTranslation()(ChartLayout);
