import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import ExportButton from "./ExportButton";
import {getDimensionLabelFromCombinations, getDimensionValueLabelFromCombinations} from "../../utils/dataset";

const mapStateToProps = state => ({
  exportConfig: state.hub.hub.exportConfig,
  exportSettings: state.appConfig.exportSettings,
  modulesConfig: state.app.modulesConfig
});

const ExportButtonCombinationsWrapper = ({
  exportConfig,
  exportSettings,
  modulesConfig,
  dimensionLabels,
  dimensionMap,
  formats,
  viewerIdx,
  showAsBottomNavigation,
  icon,
  isTableVisible,
  isMapVisible,
  isChartVisible,
  tableLayout,
  mapId,
  mapContainerId,
  mapLayout,
  mapSettings,
  chartId,
  chartContainerId,
  chartLayout,
  chartSettings,
  labelFormat,
  datasetTitle,
  shapefilesUrl,
  role,
  buttonStyle,
  className,
  disabled,
  getDimensionsCombinationCount,
  getIsDatasetExceedingLimit,
  submitDownload,
  submitMetadataDownload,
  ...props
}) => {
  const getLabels = (layout, filter, filterValue, dimIdx, t) => {
    const dimLabel = getDimensionLabelFromCombinations(filter, dimensionLabels);
    const valueLabel = getDimensionValueLabelFromCombinations(filter, filterValue, dimensionMap);
    const isLast = dimIdx === layout.filters.length - 1;
    const hasIndicatorNote = false;

    return {dimLabel, valueLabel, isLast, hasIndicatorNote};
  };

  return (
    dimensionMap && (
      <ExportButton
        {...props}
        exportConfig={exportConfig}
        exportSettings={exportSettings}
        modulesConfig={modulesConfig}
        showAsBottomNavigation={showAsBottomNavigation}
        icon={icon}
        formats={formats}
        viewerIdx={viewerIdx}
        isTableVisible={isTableVisible}
        isMapVisible={isMapVisible}
        isChartVisible={isChartVisible}
        tableLayout={tableLayout}
        mapId={mapId}
        mapContainerId={mapContainerId}
        mapLayout={mapLayout}
        mapSettings={mapSettings}
        chartId={chartId}
        chartContainerId={chartContainerId}
        chartLayout={chartLayout}
        chartSettings={chartSettings}
        labelFormat={labelFormat}
        datasetTitle={datasetTitle}
        getDimensionsCombinationCount={getDimensionsCombinationCount}
        getIsDatasetExceedingLimit={getIsDatasetExceedingLimit}
        submitDownload={submitDownload}
        shapefilesUrl={shapefilesUrl}
        role={role}
        buttonStyle={buttonStyle}
        submitMetadataDownload={submitMetadataDownload}
        className={className}
        disabled={disabled}
        getDimensionSize={dimensionId => Object.keys(dimensionMap[dimensionId]).length}
        getLabels={getLabels}
        hideLabelSelectorFormat={true}
      />
    )
  );
};

export default compose(connect(mapStateToProps))(ExportButtonCombinationsWrapper);
