import DashboardIcon from "@mui/icons-material/Dashboard";
import {Button, Grid, IconButton, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {goToNodeDashboards} from "../../links";
import {showDashboardsButton} from "./utils";

const DashboardButton = ({modulesConfig, isA11y, noNode, node, showAsText}) => {
  const {t} = useTranslation();
  if (showDashboardsButton(modulesConfig, isA11y, noNode, node)) {
    return (
      <Grid item className="dashboards-btn">
        <Tooltip title={t("components.header.actions.dashboard.title")}>
          {showAsText ? (
            <Button
              id="header_dashboard_text_button_btn"
              variant="text"
              color="inherit"
              onClick={() => goToNodeDashboards(node.code)}
              aria-label={t("components.header.actions.dashboard.ariaLabel")}
            >
              {t("components.header.actions.dashboard.label")}
            </Button>
          ) : (
            <IconButton
              id="header_dashboard_icon_button_btn"
              onClick={() => goToNodeDashboards(node.code)}
              color="inherit"
              aria-label={t("components.header.actions.dashboard.ariaLabel")}
            >
              <DashboardIcon />
            </IconButton>
          )}
        </Tooltip>
      </Grid>
    );
  } else {
    return null;
  }
};

export default DashboardButton;
