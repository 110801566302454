/**
 * Custom Pages API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ItemContainerType = "CustomPage" | "Dashboard" | "Widget" | "WidgetDashboard";

export const ItemContainerTypes = {
  CustomPage: "CustomPage" as ItemContainerType,
  Dashboard: "Dashboard" as ItemContainerType,
  Widget: "Widget" as ItemContainerType,
  WidgetDashboard: "WidgetDashboard" as ItemContainerType
};
