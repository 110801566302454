import React from "react";
import {Box, CardHeader} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {withTranslation} from "react-i18next";
import {
  AUTOMATIC,
  CHART_DATA_LABEL_TYPE_NONE,
  CHART_DATA_LABEL_TYPE_VALUE,
  CHART_LEGEND_POSITION_BOTTOM,
  CHART_LEGEND_POSITION_lEFT,
  CHART_LEGEND_POSITION_RIGHT,
  CHART_LEGEND_POSITION_TOP,
  DEFAULT,
  FROM_ZERO
} from "../chart/constants";
import I18nTextField from "../i18n-text-field";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../label-format-selector/constants";
import {
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC
} from "../temporal-dim-order-selector/constants";
import themeConfig from "../../theme-config/config.json";

const fieldStyle = {
  margin: "8px 0"
};

const paperStyle = {
  padding: "16px"
};

const cardHeaderStyle = {
  padding: "0 0 8px 0"
};

function ChartSettingsGeneral(props) {
  const {t, commonSettings, setCommonSettings, settings, onSettingsSet} = props;

  const {
    stacked,
    legendPosition,
    showAxesLabel,
    customizeCategoryAxis,
    categoryAxisLabel,
    valueAxisLabel,
    dataLabelType,
    extremitiesCartesianAxesGraphs
  } = settings;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        "& > *": {
          marginBottom: "16px"
        },
        "& *:last-child": {
          marginBottom: 0
        }
      }}
    >
      {setCommonSettings && (
        <Paper variant="outlined" sx={paperStyle}>
          <CardHeader subheader={t("components.chartSettings.header.common")} sx={cardHeaderStyle} />
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              select
              fullWidth
              label={t("components.temporalDimOrderSelector.title")}
              value={commonSettings.temporalDimOrder || ""}
              onChange={ev => setCommonSettings({...commonSettings, temporalDimOrder: ev.target.value})}
              variant="outlined"
            >
              <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC}>
                {t("components.temporalDimOrderSelector.values.asc")}
              </MenuItem>
              <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC}>
                {t("components.temporalDimOrderSelector.values.desc")}
              </MenuItem>
            </TextField>
          </FormControl>
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              select
              fullWidth
              label={t("components.labelFormatSelector.labelFormat.title")}
              value={commonSettings.labelFormat || ""}
              onChange={ev => setCommonSettings({...commonSettings, labelFormat: ev.target.value})}
              variant="outlined"
            >
              <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}>
                {t("components.labelFormatSelector.labelFormat.values.name")}
              </MenuItem>
              <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}>
                {t("components.labelFormatSelector.labelFormat.values.id")}
              </MenuItem>
              <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}>
                {t("components.labelFormatSelector.labelFormat.values.both")}
              </MenuItem>
            </TextField>
          </FormControl>
        </Paper>
      )}
      <Paper variant="outlined" sx={paperStyle}>
        <CardHeader subheader={t("components.chartSettings.header.general")} sx={cardHeaderStyle} />
        <FormControl fullWidth sx={fieldStyle}>
          <FormControlLabel
            label={t("components.chartSettings.general.isStacked.label")}
            control={
              <Checkbox checked={stacked} onChange={(e, value) => onSettingsSet({...settings, stacked: value})} />
            }
          />
        </FormControl>
        <FormControl fullWidth sx={fieldStyle}>
          <TextField
            select
            fullWidth
            label={t("components.chartSettings.general.legendPosition.label")}
            value={legendPosition}
            variant="outlined"
            onChange={ev => onSettingsSet({...settings, legendPosition: ev.target.value})}
          >
            <MenuItem value={CHART_LEGEND_POSITION_TOP}>
              {t("components.chartSettings.general.legendPosition.values.top")}
            </MenuItem>
            <MenuItem value={CHART_LEGEND_POSITION_RIGHT}>
              {t("components.chartSettings.general.legendPosition.values.right")}
            </MenuItem>
            <MenuItem value={CHART_LEGEND_POSITION_BOTTOM}>
              {t("components.chartSettings.general.legendPosition.values.bottom")}
            </MenuItem>
            <MenuItem value={CHART_LEGEND_POSITION_lEFT}>
              {t("components.chartSettings.general.legendPosition.values.left")}
            </MenuItem>
          </TextField>
        </FormControl>
        <FormControl fullWidth sx={fieldStyle}>
          <FormControlLabel
            label={t("components.chartSettings.general.dataLabelType.label")}
            control={
              <Checkbox
                checked={dataLabelType === CHART_DATA_LABEL_TYPE_VALUE}
                onChange={(e, value) => {
                  onSettingsSet({
                    ...settings,
                    dataLabelType: value ? CHART_DATA_LABEL_TYPE_VALUE : CHART_DATA_LABEL_TYPE_NONE
                  });
                }}
              />
            }
          />
        </FormControl>
        <FormControl fullWidth sx={fieldStyle}>
          <FormControlLabel
            label={t("components.chartSettings.general.showAxesLabel.label")}
            control={
              <Checkbox
                checked={showAxesLabel}
                onChange={(e, value) => onSettingsSet({...settings, showAxesLabel: value})}
              />
            }
          />
        </FormControl>
        {showAxesLabel && (
          <Paper variant="outlined" sx={paperStyle}>
            <FormControl fullWidth sx={fieldStyle}>
              <I18nTextField
                label={t("components.chartSettings.general.valueAxisLabel.label")}
                variant="outlined"
                value={valueAxisLabel}
                onChange={value => onSettingsSet({...settings, valueAxisLabel: value})}
              />
            </FormControl>
            <FormControl fullWidth sx={fieldStyle}>
              <FormControlLabel
                label={t("components.chartSettings.general.customizeCategoryAxis.label")}
                control={
                  <Checkbox
                    checked={customizeCategoryAxis}
                    onChange={(e, value) => onSettingsSet({...settings, customizeCategoryAxis: value})}
                  />
                }
              />
            </FormControl>
            {customizeCategoryAxis && (
              <FormControl fullWidth sx={fieldStyle}>
                <I18nTextField
                  label={t("components.chartSettings.general.categoryAxisLabel.label")}
                  variant="outlined"
                  value={categoryAxisLabel}
                  onChange={value => onSettingsSet({...settings, categoryAxisLabel: value})}
                />
              </FormControl>
            )}
          </Paper>
        )}
        {!themeConfig.enableOptimization && (
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              select
              fullWidth
              label={t("components.chartSettings.general.extremitiesCartesianAxesGraphs.label")}
              value={extremitiesCartesianAxesGraphs || DEFAULT}
              variant="outlined"
              onChange={ev => onSettingsSet({...settings, extremitiesCartesianAxesGraphs: ev.target.value})}
            >
              <MenuItem value={DEFAULT}>
                {t("components.chartSettings.general.extremitiesCartesianAxesGraphs.values.default")}
              </MenuItem>
              <MenuItem value={AUTOMATIC}>
                {t("components.chartSettings.general.extremitiesCartesianAxesGraphs.values.automatic")}
              </MenuItem>
              <MenuItem value={FROM_ZERO}>
                {t("components.chartSettings.general.extremitiesCartesianAxesGraphs.values.fromZero")}
              </MenuItem>
            </TextField>
          </FormControl>
        )}
      </Paper>
    </Box>
  );
}

export default withTranslation()(ChartSettingsGeneral);
