import React, {Fragment, useEffect, useState} from "react";
import {Box} from "@mui/material";
import Helmet from "react-helmet";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import DataViewer from "../data-viewer";
import Footer from "../footer";
import Header from "../header";
import ModulesPlaceholder from "../modules-placeholder";
import Page from "../page";
import WidgetViewer from "../widget-viewer";
import {getPageTitle} from "../../utils/other";

const Data = ({
  hub,
  node,
  isDefault,
  catalog,
  categoryPath,
  isAccessible,
  datasetId,
  datasetTitle,
  viewId,
  attachedFiles,
  datasetType,
  dashboardUrl
}) => {
  const location = useLocation();
  const {t} = useTranslation();

  const [nodeExtras, setNodeExtras] = useState(null);
  const [hubExtras, setHubExtras] = useState(null);

  useEffect(() => {
    if (node?.extras) {
      const newNodeExtras = {};
      node.extras.forEach(({key, value}) => {
        try {
          newNodeExtras[key] = JSON.parse(value);
        } catch (e) {
          newNodeExtras[key] = value;
        }
      });
      setNodeExtras(newNodeExtras);
    }
  }, [node]);

  useEffect(() => {
    const newHubExtras = JSON.parse(hub.hub.extras || "{}");
    setHubExtras(newHubExtras);
  }, [hub]);

  const params = new URLSearchParams(location.search);
  params.set("accessible", "true");
  params.set("datasetId", datasetId);
  const paramsStr = params.toString();
  const path = `/${window.language}/${node?.code.toLowerCase()}/${categoryPath.join("/")}`;

  return (
    <Fragment>
      <Helmet>
        <body dataset-id={`${datasetId.replaceAll(/[,.]/g, "-")}`} />
      </Helmet>
      {!isAccessible && (
        <a
          href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
          target="_self"
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.accessible")}
        </a>
      )}
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page title={getPageTitle([datasetTitle], t)}>
        <Header
          isDefault={isDefault}
          selectedCategoryPath={categoryPath}
          selectedDataset={datasetId}
          getCustomA11yPath={isA11y => {
            if (isA11y) {
              return `/${window.language}/${node?.code.toLowerCase()}/categories/${categoryPath.join("/")}`;
            } else {
              return false;
            }
          }}
          getAdditionalA11yUrlParams={isA11y => {
            if (isA11y) {
              return {datasetId};
            } else {
              return false;
            }
          }}
        />
        <Box
          id="main"
          component="main"
          sx={{
            backgroundColor: "#f5f5f5",
            width: "100%",
            height: "100%",
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            "& #footer": {
              paddingTop: 0
            }
          }}
          className={` data`}
        >
          {datasetType === "dashboard" ? (
            <WidgetViewer widgetUrl={dashboardUrl} datasetTitle={datasetTitle} />
          ) : (
            <DataViewer
              nodeId={node?.nodeId}
              nodeCode={node?.code}
              categoryPath={categoryPath}
              isAccessible={isAccessible}
              datasetId={datasetId}
              datasetTitle={datasetTitle}
              viewId={viewId}
              attachedFiles={attachedFiles}
              hubExtras={hubExtras}
              nodeExtras={nodeExtras}
            />
          )}
          <Footer />
          <ModulesPlaceholder id="data-main-page" />
        </Box>
      </Page>
    </Fragment>
  );
};

export default Data;
