import React, {useRef} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {Box} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";

const SearchInput = ({query = "", onSubmit, placeholder, onChange, noAutocomplete}) => {
  const input = useRef(null);
  const {t} = useTranslation();

  return (
    <Box
      sx={theme => ({
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#f5f5f5",
        borderColor: theme.palette.primary.main,
        borderWidth: "1px",
        position: "relative",
        height: "35px"
      })}
      style={{width: 320}}
    >
      <label htmlFor="search-input" style={{display: "none"}}>
        {t("components.searchInput.label")}
      </label>
      <TextField
        id="search-input"
        fullWidth
        size="small"
        variant="outlined"
        placeholder={placeholder || t("components.searchInput.placeholder")}
        defaultValue={query}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          inputRef: input
        }}
        onChange={onChange}
        onKeyDown={e => {
          if (e.key === "Enter" && input.current.value && input.current.value.length > 0) {
            e.preventDefault();
            onSubmit(input.current.value);
          }
        }}
      />
    </Box>
  );
};

export default SearchInput;
