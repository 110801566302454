import React from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import CustomDialogTitle from "../custom-dialog-title";
import MaterialTable from "../material-table";
import {languageSelector, languagesSelector} from "../../state/app/appSelectors";
import {hubNodesSelector} from "../../state/hub/hubSelectors";
import {itemContainerViewsSelector} from "../../state/otherConfig/otherConfigSelectors";
import {userSelector} from "../../state/user/userSelectors";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../utils/i18n";

export function ViewSelectionDialog({handleViewSet, onClose, open}) {
  const {t} = useTranslation();
  const defaultLanguage = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const nodes = useSelector(hubNodesSelector);
  const views = useSelector(itemContainerViewsSelector);
  const user = useSelector(userSelector);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <CustomDialogTitle onClose={onClose}>{t("components.itemContainerBuilder.modals.views.title")}</CustomDialogTitle>
      <DialogContent sx={{height: "480px"}}>
        <MaterialTable
          data={(views || [])
            .filter(({userId}) => userId === user.userId)
            .filter(({nodeId}) => nodes.find(node => node.nodeId === nodeId))}
          columns={[
            {
              field: "nodeId",
              title: t("components.itemContainerBuilder.modals.views.columns.node"),
              render: ({nodeId}) => nodes.find(node => node.nodeId === nodeId).code,
              customFilterAndSearch: (str, {nodeId}) =>
                nodes
                  .find(node => node.nodeId === nodeId)
                  .code.toLowerCase()
                  .includes(str.toLowerCase())
            },
            {
              field: "datasetId",
              title: t("scenes.viewsSettings.table.columns.viewDatasetId")
            },
            {
              field: "title",
              title: t("scenes.viewsSettings.table.columns.viewTitle"),
              render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
              customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
            }
          ]}
          onRowClick={(_, rowData) => {
            handleViewSet(rowData);
            onClose();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
