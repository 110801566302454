import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import App from "./App";
import init from "./init";
import "./utils/customMoment";
import "./a11yStyle.css";
import "./style.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "@fontsource/do-hyeon/index.css";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/index.css";

init(store => {
  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
});
