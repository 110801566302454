import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {Box, FormControl, Grid, MenuItem, TextField, Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import CustomEmpty from "../../custom-empty";
import I18nTextField from "../../i18n-text-field";
import {
  INDICATOR_AVERAGE_ANNUAL_GROWTH_RATE,
  INDICATOR_AVERAGE_ANNUAL_PERCENTAGE_VARIATION,
  INDICATOR_PERCENTAGE_VARIATION,
  YEAR_SELECT_WIDTH
} from "./constants";
import {validateI18nObj} from "../../../utils/i18n";
import {getIndicatorDefinition} from "./utils";

const VariationAndGrowthRates = (
  {dataset, years, timeDim, combinations, variables, variablesDataset, getDimLabel, getDimValueLabel},
  ref
) => {
  const {t} = useTranslation();

  const {
    register,
    formState: {errors},
    handleSubmit,
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      title: {},
      year: "",
      indicator: "",
      statistic: "",
      startYear: "",
      endYear: ""
    }
  });

  const [reversedYearValues, setReversedYearValues] = useState(null);
  const [statistics, setStatistics] = useState([]);
  const [indicators, setIndicators] = useState(null);
  const [startYearValues, setStartYearValues] = useState([]);
  const [endYearValues, setEndYearValues] = useState([]);

  const handleStatisticChange = useCallback(
    (selectedStatistic, statistics) => {
      if (selectedStatistic && statistics) {
        setValue("statistic", selectedStatistic);
        const values = selectedStatistic.split("@");
        const datasetId = values[0];
        const statisticKey = values[1];
        const statistic = statistics[datasetId][statisticKey];
        setStartYearValues(statistic.years);
        setEndYearValues(statistic.years);
        const l = statistic.years.length;
        setValue("startYear", statistic.years[l - 2]);
        setValue("endYear", statistic.years[l - 1]);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (variablesDataset && variables) {
      const datasets = [...new Set(Object.values(variablesDataset))];

      const allStatistics = {};
      datasets.forEach(datasetId => {
        allStatistics[datasetId] = {};
      });

      Object.keys(variables || {}).forEach(key => {
        const variable = variables[key];
        const timeValue = variable[timeDim];
        const datasetId = variablesDataset[key];
        const noTimeCombinationArray = [];
        const combinationLabelArray = [];
        const dimArray = [];

        Object.keys(variable)
          .filter(dim => dim !== timeDim)
          .forEach(dim => {
            dimArray.push(dim);
            noTimeCombinationArray.push(variable[dim]);
            const label = getDimValueLabel(datasetId, dim, variable[dim]);
            combinationLabelArray.push(label);
          });

        const noTimeCombinationStr = noTimeCombinationArray.join(",");
        const datasetStatistics = allStatistics[datasetId];
        if (!(noTimeCombinationStr in datasetStatistics)) {
          datasetStatistics[noTimeCombinationStr] = {
            dimensionsLabels: combinationLabelArray,
            dimensions: dimArray,
            years: [timeValue],
            // variables: [key],
            yearVariable: {}
          };
        } else {
          datasetStatistics[noTimeCombinationStr].years.push(timeValue);
          // datasetStatistics[noTimeCombinationStr].variables.push(key);
        }
        datasetStatistics[noTimeCombinationStr].yearVariable[timeValue] = key;
      });

      // Sort years
      Object.values(allStatistics).forEach(datasetStatistics => {
        Object.values(datasetStatistics).forEach(statistic => {
          statistic.years.sort();
        });
      });

      setStatistics(allStatistics);
    }
  }, [variablesDataset, variables, timeDim, dataset, setStatistics, t, getDimValueLabel]);

  useEffect(() => {
    if ((Object.keys(statistics) || []).length > 0) {
      const datasetId = Object.keys(statistics)[0];
      const combination = Object.keys(statistics[datasetId])[0];
      handleStatisticChange(`${datasetId}@${combination}`, statistics);
    }
  }, [statistics, handleStatisticChange]);

  useEffect(() => {
    setReversedYearValues(years.slice().reverse());
  }, [years, setReversedYearValues]);

  useEffect(() => {
    if ((reversedYearValues || []).length > 0) {
      setValue("year", reversedYearValues[0]);
    }
  }, [reversedYearValues, setValue]);

  useEffect(() => {
    const indicators = [
      {
        value: INDICATOR_PERCENTAGE_VARIATION,
        label: t("components.indicatorDialogs.create.form.fields.indicator.percentageVariation.label"),
        tooltip: t("components.indicatorDialogs.create.form.fields.indicator.percentageVariation.tooltip")
      },
      {
        value: INDICATOR_AVERAGE_ANNUAL_PERCENTAGE_VARIATION,
        label: t("components.indicatorDialogs.create.form.fields.indicator.avgAnnualPercentageVariation.label"),
        tooltip: t("components.indicatorDialogs.create.form.fields.indicator.avgAnnualPercentageVariation.tooltip")
      },
      {
        value: INDICATOR_AVERAGE_ANNUAL_GROWTH_RATE,
        label: t("components.indicatorDialogs.create.form.fields.indicator.avgAnnualGrowthRate.label"),
        tooltip: t("components.indicatorDialogs.create.form.fields.indicator.avgAnnualGrowthRate.tooltip")
      }
    ];
    setIndicators(indicators);
  }, [setIndicators, t]);

  useEffect(() => {
    if ((indicators || []).length > 0) {
      setValue("indicator", indicators[0].value);
    }
  }, [indicators, setValue]);

  useEffect(() => {
    register("title", {
      validate: val => validateI18nObj(val) || t("commons.validation.requiredAnyLanguage")
    });
    register("year", {
      required: t("commons.validation.required")
    });
    register("indicator", {
      required: t("commons.validation.required")
    });
    register("statistic", {
      required: t("commons.validation.required")
    });
    register("startYear", {
      required: t("commons.validation.required")
    });
    register("endYear", {
      required: t("commons.validation.required")
    });
  }, [register, t]);

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(variationAndGrowthRatesFormData => {
        const indicator = getIndicatorDefinition(variationAndGrowthRatesFormData, statistics);
        f(indicator);
      })();
    },
    cancel(f) {
      f();
    }
  }));

  if (!combinations) {
    return (
      <CustomEmpty text={t("components.indicatorDialogs.create.form.loading") + "..."} image={<CircularProgress />} />
    );
  }

  return (
    <Box sx={{height: "calc(100% - 148px)"}}>
      <Grid container spacing={2} sx={{marginBottom: "16px"}}>
        <Grid item sx={{width: `calc(100% - ${YEAR_SELECT_WIDTH}px)`}}>
          <FormControl fullWidth>
            <I18nTextField
              name="title"
              label={t("components.indicatorDialogs.create.form.fields.title.label")}
              value={watch("title") || {}}
              onChange={value => setValue("title", value)}
              error={!!errors.title}
              helperText={errors.title?.message}
              required
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>{" "}
        `${YEAR_SELECT_WIDTH}px`
        <Grid item sx={{width: `${YEAR_SELECT_WIDTH}px`}}>
          <FormControl fullWidth>
            <TextField
              select
              name="year"
              label={t("components.indicatorDialogs.create.form.fields.year.label")}
              value={watch("year")}
              onChange={ev => setValue("year", ev.target.value)}
              error={!!errors.year}
              helperText={errors.year?.message}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(reversedYearValues || []).map(v => (
                <MenuItem value={v} key={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{marginBottom: "16px"}}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              name="indicator"
              label={t("components.indicatorDialogs.create.form.fields.indicator.label")}
              value={watch("indicator")}
              onChange={ev => setValue("indicator", ev.target.value)}
              error={!!errors.indicator}
              helperText={errors.indicator?.message}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(indicators || []).map((indicator, idx) => (
                <MenuItem
                  key={idx}
                  value={indicator.value}
                  sx={{
                    paddingTop: "0px",
                    paddingBottom: "0px"
                  }}
                >
                  <Tooltip placement="top" title={indicator.tooltip}>
                    <Box
                      component="span"
                      sx={{
                        width: "100%",
                        paddingTop: "6px",
                        paddingBottom: "6px"
                      }}
                    >
                      {indicator.label}
                    </Box>
                  </Tooltip>
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{marginBottom: "16px"}}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              name="statistic"
              label={t("components.indicatorDialogs.create.form.fields.statistic.label")}
              value={watch("statistic")}
              onChange={ev => handleStatisticChange(ev.target.value, statistics)}
              error={!!errors.statistic}
              helperText={errors.statistic?.message}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(Object.keys(statistics) || []).map(datasetId => {
                const combinations = statistics[datasetId];
                return (Object.keys(combinations) || []).map(c => {
                  const key = `${datasetId}@${c}`;
                  return (
                    <MenuItem key={key} value={key}>
                      <Tooltip
                        key={`Tooltip_${key}`}
                        title={
                          <Box sx={{fontSize: "13px"}}>
                            {combinations[c].dimensions.map((dim, idx) => (
                              <Box
                                key={idx}
                                sx={{
                                  marginBottom: "4px",
                                  fontWeight: "normal"
                                }}
                              >
                                <b>{getDimLabel(datasetId, dim)}</b>: <i>{combinations[c].dimensionsLabels[idx]}</i>
                              </Box>
                            ))}
                          </Box>
                        }
                      >
                        <div>{combinations[c].dimensionsLabels.join(", ")}</div>
                      </Tooltip>
                    </MenuItem>
                  );
                });
              })}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              select
              name="startYear"
              label={t("components.indicatorDialogs.create.form.fields.startYear.label")}
              value={watch("startYear")}
              onChange={ev => setValue("startYear", ev.target.value)}
              error={getValues("endYear") <= getValues("startYear")}
              helperText={
                getValues("endYear") <= getValues("startYear")
                  ? t("components.indicatorDialogs.create.form.fields.indicator.variation.years.helperText")
                  : null
              }
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(startYearValues || []).map(v => (
                <MenuItem value={v} key={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              select
              name="endYear"
              label={t("components.indicatorDialogs.create.form.fields.endYear.label")}
              value={watch("endYear")}
              onChange={ev => setValue("endYear", ev.target.value)}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(endYearValues || []).map(v => (
                <MenuItem value={v} key={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default forwardRef(VariationAndGrowthRates);
