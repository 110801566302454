import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../../hocs/call";
import SingleViewerPlusFooter from "./Footer";
import SingleViewerPlusHeader from "./Header";
import SingleViewerPlusSidebar from "./Sidebar";
import SingleViewerPlusViewer from "./Viewer";
import {
  backupDatasetSVPState,
  fetchDatasetSVPCodelists,
  hideDatasetSVPDownloadWarning,
  hideDatasetSVPUnavailableViewWarning,
  setDatasetSVPFullScreen
} from "../../../state/dataset/single-viewer-plus/actions";
import {getDatasetStorageKey} from "../../../utils/other";

const $ = window.jQuery;

const mapStateToProps = ({catalog, dataset}) => ({
  datasetMap: (catalog.uncategorizedDatasets || []).reduce(
    (acc, v) => ({...acc, [v.identifier]: v}),
    catalog.datasetMap || {}
  ),
  datasetUuid: dataset.singleViewerPlus.datasetUuid,
  dimensions: dataset.singleViewerPlus.dimensions,
  codelistTrees: dataset.singleViewerPlus.codelistTrees,
  isDownloadWarningVisible: dataset.singleViewerPlus.isDownloadWarningVisible,
  isUnavailableViewWarningVisible: dataset.singleViewerPlus.isUnavailableViewWarningVisible,
  isFullscreen: dataset.singleViewerPlus.isFullscreen
});

const mapDispatchToProps = dispatch => ({
  fetchCodelists: ({nodeId, nodeCode, datasetId, freq, defaultLastNPeriods}) =>
    dispatch(fetchDatasetSVPCodelists(nodeId, nodeCode, datasetId, freq, defaultLastNPeriods)),
  onDownloadWarningHide: () => dispatch(hideDatasetSVPDownloadWarning()),
  onUnavailableViewHide: () => dispatch(hideDatasetSVPUnavailableViewWarning()),
  onSetFullscreen: isFullscreen => dispatch(setDatasetSVPFullScreen(isFullscreen)),
  onDatasetBackup: datasetUuid => dispatch(backupDatasetSVPState(datasetUuid))
});

const handleStyle = () => {
  const headerHeight = document.getElementById("data-viewer__header")?.offsetHeight || 0;
  const footerHeight = document.getElementById("data-viewer__footer")?.offsetHeight || 0;
  $("#data-viewer__sidebar-viewer-container").height(`calc(100% - ${headerHeight + footerHeight}px)`);

  const sidebarWidth = document.getElementById("data-viewer__sidebar")?.offsetWidth || 0;
  $("#data-viewer__viewer").width(`calc(100% - ${sidebarWidth}px)`);
};

function SingleViewerPlus(props) {
  const {
    nodeId,
    nodeCode,
    categoryPath,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    nodeExtras,

    datasetMap,
    datasetUuid,
    dimensions,
    codelistTrees,
    isDownloadWarningVisible,
    isUnavailableViewWarningVisible,
    isFullscreen,

    fetchCodelists,
    onDownloadWarningHide,
    onUnavailableViewHide,
    onSetFullscreen,
    onDatasetBackup
  } = props;

  const {t} = useTranslation();

  const [chartId] = useState("chart__" + uuidv4());

  const [mapId] = useState("map__" + uuidv4());

  useEffect(() => {
    return () => {
      const storedDatasets = JSON.parse(sessionStorage.getItem("datasets") || "[]");
      if (datasetUuid && storedDatasets.map(getDatasetStorageKey).includes(datasetUuid)) {
        onDatasetBackup(datasetUuid);
      }
    };
  }, [datasetUuid, onDatasetBackup]);

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  const onToggleFullScreen = useCallback(() => {
    const isEnteringFullscreen = !isFullscreen;

    const appContainer = document.getElementById("app-container");
    appContainer.style.visibility = isEnteringFullscreen ? "hidden" : "";
    const fullscreenContainer = document.getElementById("fullscreen-container");
    fullscreenContainer.style.display = isEnteringFullscreen ? "block" : "none";

    onSetFullscreen(isEnteringFullscreen);
  }, [isFullscreen, onSetFullscreen]);

  return (
    <Fragment>
      {createPortal(
        <Box
          id="data-viewer"
          sx={{
            width: "100%",
            height: "100%"
          }}
          className={`data-viewer__single-viewer-plus`}
        >
          <Call
            cb={fetchCodelists}
            cbParam={{
              nodeId,
              nodeCode,
              datasetId,
              freq: null,
              defaultLastNPeriods: null
            }}
            disabled={dimensions === null || codelistTrees !== null}
          >
            <Box
              id="data-viewer__header"
              sx={{width: "100%"}}
              style={{padding: isFullscreen ? "0 0 16px 0" : "4px 16px 12px"}}
            >
              <SingleViewerPlusHeader
                nodeId={nodeId}
                nodeCode={nodeCode}
                nodeExtras={nodeExtras}
                datasetId={datasetId}
                datasetTitle={datasetTitle}
                viewId={viewId}
                attachedFiles={attachedFiles}
                chartId={chartId}
                mapId={mapId}
                datasetMap={datasetMap}
                onRender={handleStyle}
                onToggleFullScreen={onToggleFullScreen}
              />
            </Box>
            <Box
              id="data-viewer__sidebar-viewer-container"
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                minHeight: "400px",
                minWidth: "560px"
              }}
              style={{padding: isFullscreen ? "0" : "0 16px"}}
            >
              <Box id="data-viewer__sidebar" sx={{height: "100%"}} style={{display: isFullscreen ? "none" : "block"}}>
                <SingleViewerPlusSidebar
                  nodeId={nodeId}
                  nodeCode={nodeCode}
                  nodeExtras={nodeExtras}
                  datasetId={datasetId}
                  datasetMap={datasetMap}
                />
              </Box>
              <Box id="data-viewer__viewer" sx={{height: "100%"}}>
                <SingleViewerPlusViewer
                  nodeId={nodeId}
                  nodeCode={nodeCode}
                  nodeExtras={nodeExtras}
                  datasetId={datasetId}
                  datasetTitle={datasetTitle}
                  chartId={chartId}
                  mapId={mapId}
                />
              </Box>
            </Box>
            <Box
              id="data-viewer__footer"
              sx={{
                width: "100%",
                overflowX: "auto",
                overflowY: "hidden",
                padding: "8px"
              }}
              style={{display: isFullscreen ? "none" : "block"}}
            >
              <SingleViewerPlusFooter
                dataset={{
                  nodeCode: nodeCode,
                  categoryPath: categoryPath,
                  datasetId: datasetId,
                  datasetTitle: datasetTitle,
                  viewId: viewId
                }}
                onDatasetsChange={handleStyle}
              />
            </Box>
          </Call>
        </Box>,
        !isFullscreen
          ? document.getElementById("data-viewer-container")
          : document.getElementById("fullscreen-container")
      )}

      <Dialog open={isDownloadWarningVisible} maxWidth="md" onClose={onDownloadWarningHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.downloadFormat.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onDownloadWarningHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isUnavailableViewWarningVisible} maxWidth="md" onClose={onUnavailableViewHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.unavailableView.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onUnavailableViewHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleViewerPlus);
