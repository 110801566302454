import {createSelector} from "@reduxjs/toolkit";
import rootSelector from "../rootSelector";

export const nodeSelector = createSelector(rootSelector, v => v.node);
export const nodeCodeSelector = createSelector(nodeSelector, n => n?.code);
export const nodeNameSelector = createSelector(nodeSelector, n => n?.name);
export const nodeItemContainersSelector = createSelector(nodeSelector, n => n?.itemContainers);
export const nodeCustomPagesSelector = createSelector(
  nodeItemContainersSelector,
  itemContainers => itemContainers?.customPages || []
);
export const nodeDashboardsSelector = createSelector(
  nodeItemContainersSelector,
  itemContainers => itemContainers?.dashboard || []
);
export const nodeCustomHomePageIdSelector = (nodeId: number) =>
  createSelector(
    nodeCustomPagesSelector,
    nodeCustomPages =>
      nodeCustomPages
        ?.map(v => v.configNodes)
        .reduce((acc, cur) => acc.concat(cur), [])
        .find(v => v.nodeId === nodeId && v.isHomePage)?.itemContainerId
  );
