import React, {forwardRef, Fragment, useImperativeHandle} from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import UserErrors from "../../user-errors";
import {sendUserResetPasswordEmail} from "../../../state/user/userActions";

const Form = forwardRef(({onSubmit}, ref) => {
  const {
    register,
    formState: {errors},
    handleSubmit
  } = useForm();

  const {t} = useTranslation();

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        onSubmit(val);
        f(val);
      })();
    },
    cancel(f) {
      f();
    }
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            {...register("email", {
              required: t("commons.validation.required")
            })}
            style={{marginBottom: 8}}
            name="email"
            label={t("components.userLoginDialog.recoverPasswordModal.fields.email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            variant="outlined"
            required
          />
        </FormControl>
      </Grid>
    </Grid>
  );
});

const mapStateToProps = state => ({
  userErrors: state.usersConfig.userErrors
});

const mapDispatchToProps = dispatch => ({
  sendConfig: config => dispatch(sendUserResetPasswordEmail(config.email))
});

const UserRecoverPasswordForm = ({sendConfig, userErrors}, ref) => {
  return (
    <Fragment>
      <UserErrors errors={userErrors} />
      <Form ref={ref} onSubmit={sendConfig} />
    </Fragment>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(UserRecoverPasswordForm);
