import React, {useState} from "react";
import {Dialog, DialogContent, Step, StepLabel, Stepper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DownloadNodeDto} from "../../../../model/wshub-models/downloadNodeDto";
import CustomDialogTitle from "../../../custom-dialog-title";
import FileValidationForm, {FileValidationFormProps} from "./FileValidationForm";
import ImportFormView, {ImportFormViewProps} from "./ImportFormView";

const steps = [
  {
    render: (props: FileValidationFormProps) => <FileValidationForm {...props} />
  },
  {
    render: (props: ImportFormViewProps) => <ImportFormView {...props} />
  }
] as const;

const NodeImportDialog = ({open, onClose, nodes}) => {
  const {t} = useTranslation();
  const [data, setData] = useState<{data: DownloadNodeDto; password: string; file: File} | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const step = steps[activeStep];

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <CustomDialogTitle onClose={onClose}>{t("components.nodeImportForm.wizard.title")}</CustomDialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>{t("components.nodeImportForm.wizard.firstStep.title")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("components.nodeImportForm.wizard.secondStep.title")}</StepLabel>
          </Step>
        </Stepper>
        {step.render({
          onSuccessfulValidation: data => {
            setData(data);
            setActiveStep(1);
          },
          onClose: onClose,
          nodes: nodes,
          ...data
        })}
      </DialogContent>
    </Dialog>
  );
};

export default NodeImportDialog;
