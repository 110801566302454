import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const AreaIcon = props => (
  <SvgIcon {...props}>
    <path d="M 2 20 C 2 20 22 20 22 20 C 22 20 22 4.08 22 4.08 C 21.58 4.28 20.69 5.19 20.32 5.56 C 20.32 5.56 18.08 7.69 18.08 7.69 C 17.82 7.95 16.82 8.94 16.56 9.04 C 16.4 9.1 16.17 9.09 16 9.09 C 16 9.09 13.6 9.09 13.6 9.09 C 12.89 9.1 12.65 9.63 12.24 10.15 C 12.24 10.15 10.54 12.29 10.54 12.29 C 9.99 12.99 9.88 13.43 8.96 13.44 C 8.96 13.44 6.8 13.44 6.8 13.44 C 6.64 13.44 6.39 13.43 6.24 13.48 C 5.89 13.62 5.26 14.58 5.01 14.91 C 5.01 14.91 2.85 17.7 2.85 17.7 C 2.41 18.27 2 18.6 2 19.34 C 2 19.34 2 20 2 20 Z" />
  </SvgIcon>
);

export default AreaIcon;
