import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const InfoTooltip = ({children}) => {
  return children ? (
    <Tooltip title={children} placement="top">
      <IconButton
        aria-label="info"
        sx={{
          color: theme => theme.palette.primary.main,
          padding: 0,
          transform: "translateY(-1px)"
        }}
      >
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton
      aria-label="info"
      sx={{
        color: theme => theme.palette.primary.main,
        padding: 0,
        transform: "translateY(-1px)"
      }}
    >
      <InfoIcon fontSize="small" />
    </IconButton>
  );
};

export default InfoTooltip;
