import React, {useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import {connect} from "react-redux";

const $ = window.jQuery;

const mapStateToProps = state => ({
  messages: state.spinner.messages
});

const FullscreenDialog = ({messages, dispatch, children, maxWidth, sx, ...props}) => {
  const rootStyle = {
    "& .MuiDialog-paperFullScreen": {
      maxWidth: maxWidth ? `${maxWidth}px !important` : undefined
    }
  };

  useEffect(() => {
    return () => {
      if ($(".MuiDialog-paperFullScreen").length <= 1) {
        document.body.classList.remove("body--hidden-scrollbar");
      }
    };
  }, []);

  return (
    <Dialog
      {...props}
      sx={{...rootStyle, ...sx}}
      PaperProps={{
        sx: {
          width: "95%",
          height: "95%"
        }
      }}
      fullScreen
      disableEscapeKeyDown={(messages || []).length > 0}
      TransitionProps={{
        onEnter: () => {
          document.body.classList.add("body--hidden-scrollbar");
        },
        onExit: () => {
          if ($(".MuiDialog-paperFullScreen").length <= 1) {
            document.body.classList.remove("body--hidden-scrollbar");
          }
        }
      }}
    >
      {children}
    </Dialog>
  );
};

export default connect(mapStateToProps)(FullscreenDialog);
