import {
  INDICATOR_AVERAGE_ANNUAL_GROWTH_RATE,
  INDICATOR_AVERAGE_ANNUAL_PERCENTAGE_VARIATION,
  INDICATOR_PERCENTAGE_VARIATION
} from "./constants";

export const getIndicatorDefinition = (variationAndGrowthRatesFormData, statistics) => {
  const values = variationAndGrowthRatesFormData.statistic.split("@");
  const datasetId = values[0];
  const statisticKey = values[1];
  const statistic = statistics[datasetId][statisticKey];
  const dimensionsValues = statisticKey.split(",");
  const variable = {};
  for (let i = 0; i < dimensionsValues.length; i++) {
    variable[statistic.dimensions[i]] = dimensionsValues[i];
  }

  const variables = {};
  const startYear = variationAndGrowthRatesFormData.startYear;
  const startYearName = statistic.yearVariable[startYear];
  const startVariable = {
    ...variable,
    TIME_PERIOD: startYear
  };
  variables[startYearName] = startVariable;

  const endYear = variationAndGrowthRatesFormData.endYear;
  const endYearName = statistic.yearVariable[endYear];
  const endVariable = {
    ...variable,
    TIME_PERIOD: endYear
  };
  variables[endYearName] = endVariable;

  let formula = "";
  switch (variationAndGrowthRatesFormData.indicator) {
    default:
    case INDICATOR_PERCENTAGE_VARIATION:
      formula = `([${endYearName}]-[${startYearName}])/[${startYearName}]*100`;
      break;
    case INDICATOR_AVERAGE_ANNUAL_PERCENTAGE_VARIATION:
      formula = `([${endYearName}]-[${startYearName}])/[${startYearName}]/(${endYear}-${startYear})*100`;
      break;
    case INDICATOR_AVERAGE_ANNUAL_GROWTH_RATE:
      formula = `@@LOG([${endYearName}]/[${startYearName}])/(${endYear}-${startYear})*100`;
      break;
  }

  const indicator = {
    title: variationAndGrowthRatesFormData.title,
    year: variationAndGrowthRatesFormData.year,
    formula: formula.split(""),
    variables: variables
  };

  return indicator;
};

export const getStrFromIndex = index => {
  let res = "";
  while (index >= 0) {
    res = String.fromCharCode((index % 26) + 65) + res;
    index = Math.floor(index / 26) - 1;
  }
  return res;
};
