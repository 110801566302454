import React, {Fragment} from "react";
import {Box} from "@mui/material";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import CustomEmpty from "../custom-empty";
import Footer from "../footer";
import Header from "../header";
import ModuleLoader from "../module-loader";
import Page from "../page";
import {getPageTitle} from "../../utils/other";

const mapStateToProps = state => ({
  node: state.node
});

function ModulePage(props) {
  const {
    moduleId,
    moduleComponent,
    moduleFallback,
    hideAppBar,
    hideFooter,
    isDefault,
    containerMaxWidth = "lg",
    node
  } = props;

  const {t} = useTranslation();
  const location = useLocation();
  const params = useParams();

  return (
    <Fragment>
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page title={getPageTitle([], t)}>
        <Box
          id={`module-page__${moduleId}`}
          key={moduleId}
          sx={{
            backgroundColor: "#f5f5f5",
            width: "100%",
            height: "100%",
            padding: "0 !important"
          }}
        >
          {!hideAppBar && <Header isDefault={node ? isDefault : undefined} />}
          <Box
            id="main"
            component={"main"}
            sx={{
              width: "100%",
              minHeight: "100%",
              backgroundColor: "#f5f5f5",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Container maxWidth={containerMaxWidth}>
              <div id={`${node ? "node" : "hub"}__${moduleId}`}>
                <ModuleLoader
                  id={moduleId}
                  component={moduleComponent}
                  fallback={
                    <CustomEmpty
                      text={moduleFallback || t("components.modulePage.suspense.text") + "..."}
                      style={{padding: 48}}
                    />
                  }
                  urlParams={params}
                />
              </div>
            </Container>
            {!hideFooter && <Footer />}
          </Box>
        </Box>
      </Page>
    </Fragment>
  );
}

export default connect(mapStateToProps)(ModulePage);
