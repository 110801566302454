import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import {withTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import {getAttributeLabel, getAttributeValueLabel} from "../../utils/dataset";

const AttributeDialog = ({t, isOpen, onClose, obsAttributes, dimAttributes, labelFormat}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <CustomDialogTitle onClose={onClose}>{t("components.map.dialogs.attributes.title")}</CustomDialogTitle>
    <DialogContent>
      <Grid
        container
        spacing={3}
        sx={{
          display: "table",
          width: "calc(100% + 24px)",
          margin: "-8px -12px"
        }}
      >
        {obsAttributes && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold"
                }}
              >
                {t("components.map.dialogs.attributes.content.obsAttributes.title")}
              </Grid>
              {obsAttributes.map((attribute, idx) => (
                <Grid item key={idx} xs={12}>
                  <b>{getAttributeLabel(attribute, labelFormat)}</b>: {getAttributeValueLabel(attribute, labelFormat)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {dimAttributes && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold"
                }}
              >
                {t("components.map.dialogs.attributes.content.dimAttributes.title")}
              </Grid>
              {dimAttributes.map((attribute, idx) => (
                <Grid item key={idx} xs={12}>
                  <b>{getAttributeLabel(attribute, labelFormat)}</b>: {getAttributeValueLabel(attribute, labelFormat)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
    </DialogActions>
  </Dialog>
);

export default withTranslation()(AttributeDialog);
