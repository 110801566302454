import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import ItemContainers from "../components/item-containers";
import {hubDashboardsSelector} from "../state/hub/hubSelectors";
import {nodeDashboardsSelector} from "../state/node/nodeSelectors";

const mapStateToProps = state => ({
  node: state.node
});

const ItemContainersDomain = ({itemContainerId: itemContainerIdFromProps, nodeCode, node, ...props}) => {
  const {id: itemContainerIdFromUrl} = useParams();

  const itemContainerId = itemContainerIdFromProps || itemContainerIdFromUrl || null;

  const hubDashboards = useSelector(hubDashboardsSelector);
  const nodeDashboards = useSelector(nodeDashboardsSelector);

  const getDashboardsToShow = () => {
    if (nodeCode) {
      const showHubDashboards = JSON.parse(
        (node?.extras || []).find(({key}) => key === "ShowHubDashboards")?.value ?? "false"
      );
      if (
        nodeDashboards.uncategorizedDashboards &&
        nodeDashboards.uncategorizedDashboards.length > 0 &&
        hubDashboards.uncategorizedDashboards &&
        hubDashboards.uncategorizedDashboards.length > 0 &&
        showHubDashboards
      ) {
        const dash = _.uniqBy(
          hubDashboards.uncategorizedDashboards.concat(nodeDashboards.uncategorizedDashboards),
          "id"
        );
        const dashboardsToShow = {
          ...nodeDashboards,
          uncategorizedDashboards: dash
        };
        return dashboardsToShow;
      } else {
        return nodeDashboards;
      }
    } else {
      return hubDashboards;
    }
  };

  return (
    <ItemContainers
      {...props}
      itemContainerId={itemContainerId}
      itemContainers={itemContainerId !== null ? [] : getDashboardsToShow()}
    />
  );
};

export default connect(mapStateToProps)(ItemContainersDomain);
