import React, {useCallback, useEffect, useState} from "react";
import {ArrowUpward} from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {Box} from "@mui/material";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";
import {useTranslation} from "react-i18next";

const scrollTop = () => {
  window.scroll({top: 0, left: 0, behavior: "smooth"});
};

const Page = ({title = null, id = undefined, children}) => {
  const {t} = useTranslation();

  const [fabVisiblity, setFabVisibility] = useState(false);
  const [isOldBrowserWarningVisible, setIsOldBrowserWarningVisible] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 50) {
      setFabVisibility(true);
    } else {
      setFabVisibility(false);
    }
  }, []);

  useEffect(() => {
    // Internet Explorer 6-11
    const isIE = !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    setIsOldBrowserWarningVisible(isIE || isEdge);

    window.scroll({top: 0, left: 0});
    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    handleScroll();
  });

  useEffect(() => {
    if (title && title.length > 0) {
      document.title = title;
    }
  }, [title]);

  const closeWarning = useCallback(() => {
    setIsOldBrowserWarningVisible(false);
  }, []);

  return (
    <Box
      id={id}
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      {isOldBrowserWarningVisible && (
        <Box
          sx={{
            width: "100%",
            height: "100%"
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              This browser in not completely supported, please consider to install the new version of{" "}
              <a
                href="https://www.microsoft.com/edge"
                target="_blank"
                rel="noopener noreferrer"
                style={{color: "white"}}
              >
                Microsoft Edge
              </a>
            </Grid>
            <Grid item>
              <IconButton onClick={closeWarning} sx={{color: "white", padding: "7px"}}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      )}
      <Zoom in={fabVisiblity}>
        <Fab
          sx={{
            position: "fixed",
            right: "8px",
            bottom: "55px",
            height: "50px",
            width: "50px",
            zIndex: theme => theme.zIndex.appBar
          }}
          color="secondary"
          onClick={scrollTop}
          aria-label={t("components.page.actions.goBackToTop")}
        >
          <ArrowUpward />
        </Fab>
      </Zoom>
      <Box
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Page;
