import {PayloadAction} from "@reduxjs/toolkit";
import {Reducer} from "redux";
import {DETAIL_LEVEL_FILTERED_CATALOG_SET, DETAIL_LEVEL_SET} from "./detailLevelActions";

export type DetailLevelState = {
  detailLevel: any | null;
  filteredCatalog: any | null;
};

const detailLevelReducer: Reducer<DetailLevelState, PayloadAction<any>> = (
  state = {
    detailLevel: null,
    filteredCatalog: null
  },
  action
) => {
  switch (action.type) {
    case DETAIL_LEVEL_SET:
      return {
        ...state,
        detailLevel: action.payload.detailLevel
      };
    case DETAIL_LEVEL_FILTERED_CATALOG_SET:
      return {
        ...state,
        filteredCatalog: action.payload.filteredCatalog
      };
    default:
      return state;
  }
};

export default detailLevelReducer;
