import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import CustomEmpty from "../custom-empty";
import MultiViewer from "./multi-viewer";
import MultiViewerPlus from "./multi-viewer-plus";
import SingleViewer from "./single-viewer";
import SingleViewerPlus from "./single-viewer-plus";
import {ViewerMode} from "../../state/dataset/constants";

const viewerContainerStyle = {
  width: "100%",
  height: "100%",
  overflow: "auto"
};

const mapStateToProps = ({dataset}) => ({
  viewerMode: dataset.commons.viewerMode
});

const DataViewer = ({
  nodeId,
  nodeCode,
  categoryPath,
  datasetId,
  datasetTitle,
  viewId,
  attachedFiles,
  nodeExtras,

  viewerMode
}) => {
  const {t} = useTranslation();

  return (
    <Box id="data-viewer-container" sx={viewerContainerStyle}>
      {viewerMode === ViewerMode.SingleViewer ? (
        <SingleViewer
          nodeId={nodeId}
          nodeCode={nodeCode}
          categoryPath={categoryPath}
          datasetId={datasetId}
          datasetTitle={datasetTitle}
          viewId={viewId}
          attachedFiles={attachedFiles}
          nodeExtras={nodeExtras}
        />
      ) : viewerMode === ViewerMode.SingleViewerPlus ? (
        <SingleViewerPlus
          nodeId={nodeId}
          nodeCode={nodeCode}
          categoryPath={categoryPath}
          datasetId={datasetId}
          datasetTitle={datasetTitle}
          viewId={viewId}
          attachedFiles={attachedFiles}
          nodeExtras={nodeExtras}
        />
      ) : viewerMode === ViewerMode.MultiViewer ? (
        <MultiViewer
          nodeId={nodeId}
          nodeCode={nodeCode}
          datasetId={datasetId}
          datasetTitle={datasetTitle}
          viewId={viewId}
          attachedFiles={attachedFiles}
          nodeExtras={nodeExtras}
        />
      ) : viewerMode === ViewerMode.MultiViewerPlus ? (
        <MultiViewerPlus
          nodeId={nodeId}
          nodeCode={nodeCode}
          datasetId={datasetId}
          datasetTitle={datasetTitle}
          viewId={viewId}
          attachedFiles={attachedFiles}
          nodeExtras={nodeExtras}
        />
      ) : viewerMode === ViewerMode.Error ? (
        <CustomEmpty text={t("scenes.dataViewer.errors.fetchStructure")} image={<ErrorIcon />} />
      ) : null}
    </Box>
  );
};

export default connect(mapStateToProps)(DataViewer);
