import {getGeometryUrl} from "../../serverApi/urls";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

export const MAP_GEOMETRY_FETCH = "map/geometries/fetch";
export const MAP_GEOMETRY_SET = "map/geometries/set";
export const MAP_GEOMETRY_CHANGED_STATUS_SET = "map/geometries/changed/status/set";
export const MAP_GEOMETRY_CLEAR = "map/geometries/clear";
export const MAP_NO_GEOMETRY_WARNING_HIDE = "map/no/geometries/warning/hide";

export const fetchMapGeometry = (
  mapStateUuid: string,
  nodeId: number,
  territoryDimValues: string[],
  format: string,
  baseUrl: string,
  detailLevel?: number | null,
  timeDimValue?: string | null,
  territoryDimCodelist?: string | null,
  extent?: number[] | null,
  generalizationLevel?: number | null,
  showSpinner?: boolean
) => {
  return initRequest(
    MAP_GEOMETRY_FETCH,
    getGeometryUrl(nodeId, format, detailLevel, timeDimValue, territoryDimCodelist, generalizationLevel),
    RequestMethod.POST,
    {
      geometryIds: territoryDimValues,
      boundingBox: extent
    },
    showSpinner === true ? t => ({onStart: t("components.map.messages.fetchingGeometries")}) : undefined,
    {
      mapStateUuid
    },
    baseUrl,
    (statusCode: number) => statusCode === 404
  );
};

export const setMapGeometry = (mapStateUuid: string, geometries: any) => ({
  type: MAP_GEOMETRY_SET,
  payload: {
    mapStateUuid,
    geometries
  }
});

export const setMapGeometryChangedStatus = (mapStateUuid: string, changed: boolean) => ({
  type: MAP_GEOMETRY_CHANGED_STATUS_SET,
  payload: {
    mapStateUuid,
    changed
  }
});

export const clearMapGeometry = (mapStateUuid: string) => ({
  type: MAP_GEOMETRY_CLEAR,
  payload: {
    mapStateUuid
  }
});

export const hideMapNoGeometryWarning = (mapStateUuid: string) => ({
  type: MAP_NO_GEOMETRY_WARNING_HIDE,
  payload: {
    mapStateUuid
  }
});
