import {Grid} from "@mui/material";
import AppLanguageSelect from "../app-language-select";

const LanguageButton = () => (
  <Grid item className="language-btn">
    <AppLanguageSelect />
  </Grid>
);

export default LanguageButton;
