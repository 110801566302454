import {v4 as uuidv4} from "uuid";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../request/requestActions";
import {
  DATASET_MV_DATASET_FETCH,
  fetchDatasetMVDatasetError,
  fetchDatasetMVDatasetInit,
  fetchDatasetMVDatasetSuccess
} from "../../state/dataset/multi-viewer/actions";
import {addSpinnerMessage, markSpinnerMessage} from "../../state/spinner/spinnerActions";
import {
  getFilteredChartLayout,
  getFilteredMapLayout,
  getFilteredTableLayout,
  getInitialMapLayout,
  getJsonStatTableSize,
  getMultiViewerInitialChartLayout,
  getMultiViewerInitialTableLayout,
  getMultiViewerTableColCount,
  getPointDataInitialChartLayout,
  getPointDataInitialMapLayout,
  getPointDataInitialTableLayout,
  MAX_ALLOWED_CELLS
} from "../../utils/dataset";
import {getNode} from "../../utils/tree";

const multiViewerMiddlewareFactory =
  t =>
  ({dispatch, getState}) =>
  next =>
  action => {
    const result = next(action);

    if (action?.payload?.label === DATASET_MV_DATASET_FETCH) {
      if (action.type === REQUEST_INIT) {
        dispatch(fetchDatasetMVDatasetInit());
      } else if (action.type === REQUEST_SUCCESS) {
        const appConfig = getState()?.appConfig || {};
        const datasetMVState = getState()?.dataset?.multiViewer || {};
        const hubState = getState()?.hub || {};

        const spinnerUuid = uuidv4();
        dispatch(
          addSpinnerMessage(
            spinnerUuid,
            t("middlewares.fetchDatasetAsyncHandlerMiddlewareFactory.spinners.layoutHandling")
          )
        );

        const {response, extra} = action.payload;

        let territoryDim = datasetMVState.territoryDim;
        let timeDim = datasetMVState.timeDim;
        let measureDim = datasetMVState.measureDim;

        let isPointData = datasetMVState.isPointData;
        let pointDim = datasetMVState.pointDim;

        if (isPointData) {
          const datasetId = response?.extension?.datasets?.[0] || null;

          const latitudeId = datasetMVState.latAttributeId;
          const longitudeId = datasetMVState.longAttributeId;
          const latAttribute =
            (response?.extension?.attributes?.[datasetId]?.series || []).find(({id}) => id === latitudeId) || null;
          const longAttribute =
            (response?.extension?.attributes?.[datasetId]?.series || []).find(({id}) => id === longitudeId) || null;
          const latAttachedDims = latAttribute?.relationship?.dimensions || [];
          const longAttachedDims = longAttribute?.relationship?.dimensions || [];
          if (
            latAttachedDims.length === 1 &&
            longAttachedDims.length === 1 &&
            latAttachedDims[0] === longAttachedDims[0]
          ) {
            pointDim = latAttachedDims[0];
          } else {
            isPointData = false;
            pointDim = null;
          }
        } else {
          pointDim = null;
        }

        let cellCount;
        if (!isPointData) {
          cellCount = getJsonStatTableSize(response);
        } else {
          const rows = [pointDim];
          const cols = [timeDim];
          if (measureDim) {
            cols.push(measureDim);
          }
          const layout = {
            rows: rows,
            cols: cols
          };
          cellCount = getJsonStatTableSize(response, layout);
        }

        const isTooBigData = cellCount > (hubState.hub.maxCells || MAX_ALLOWED_CELLS); // TODO: consider empty cells in this count
        const isEmptyData = extra.status === 204 || (response?.id || "").length === 0;
        const isPartialData = extra.status === 206;

        let isResponseNotValid = !response || isTooBigData || isEmptyData;

        let tableColCount = null;
        let mapPointCount = null;

        let tableLayout = null;
        let mapLayout = null;
        let chartLayout = null;

        if (!isResponseNotValid) {
          if (datasetMVState.tableLayout) {
            tableLayout = getFilteredTableLayout(datasetMVState.tableLayout, response, true, isPointData);
          } else {
            tableLayout = isPointData
              ? getPointDataInitialTableLayout(response, pointDim, territoryDim, timeDim, measureDim)
              : getMultiViewerInitialTableLayout(response, territoryDim, timeDim);
          }

          const colCount = getMultiViewerTableColCount(response, tableLayout);
          if (colCount > appConfig.tableConfig.maxColCount) {
            tableColCount = colCount;
            isResponseNotValid = true;
            tableLayout = null;
          }
        }

        if (
          !isResponseNotValid &&
          ((territoryDim && response.id.includes(territoryDim)) || (pointDim && response.id.includes(pointDim))) &&
          (datasetMVState.detailLevelTree || []).length > 0
        ) {
          const pointCount =
            isPointData && pointDim && response.id.includes(pointDim)
              ? response.size[response.id.indexOf(pointDim)]
              : null;
          if (pointCount !== null && pointCount > appConfig.mapConfig.maxPointCount) {
            mapPointCount = pointCount;
            isResponseNotValid = true;
            mapLayout = null;
          } else {
            if (datasetMVState.mapLayout) {
              mapLayout = getFilteredMapLayout(datasetMVState.mapLayout, response, isPointData);
            } else {
              mapLayout = isPointData
                ? getPointDataInitialMapLayout(response, pointDim, timeDim, measureDim)
                : getInitialMapLayout(response, territoryDim);
            }
          }
        }

        if (!isResponseNotValid) {
          if (datasetMVState.chartLayout) {
            chartLayout = getFilteredChartLayout(datasetMVState.chartLayout, response, isPointData);
          } else {
            chartLayout = isPointData
              ? getPointDataInitialChartLayout(
                  response,
                  pointDim,
                  timeDim,
                  measureDim,
                  appConfig.chartConfig.maxTerritoryValueCount
                )
              : getMultiViewerInitialChartLayout(
                  response,
                  territoryDim,
                  timeDim,
                  appConfig.chartConfig.maxTerritoryValueCount
                );
          }
        }

        if (!isResponseNotValid && !isPointData) {
          const territoryDimLabel =
            getNode(datasetMVState.detailLevelTree, "layers", ({id}) => id === datasetMVState.detailLevel)?.label ||
            null;
          if (territoryDimLabel) {
            response.dimension[territoryDim].label = territoryDimLabel;
          }
        }

        const myWorker = new Worker("./workers/fetchDatasetMVAsyncHandlerWorker.js");
        myWorker.onmessage = event => {
          const {
            tableLayout,
            tableFilterTree,
            mapLayout,
            mapFilterTree,
            chartLayout,
            chartFilterTree,
            timePeriodsByFreq
          } = event.data;
          dispatch(
            fetchDatasetMVDatasetSuccess(
              response,
              extra.status,
              extra.responseHeaders,
              isPointData,
              pointDim,
              cellCount,
              isTooBigData,
              isEmptyData,
              isPartialData,
              tableColCount,
              mapPointCount,
              isResponseNotValid,
              tableLayout,
              tableFilterTree,
              mapLayout,
              mapFilterTree,
              chartLayout,
              chartFilterTree,
              timePeriodsByFreq,
              myWorker
            )
          );
          dispatch(markSpinnerMessage(spinnerUuid));
        };
        myWorker.onerror = () => {
          dispatch(fetchDatasetMVDatasetError());
          dispatch(markSpinnerMessage(spinnerUuid, true));
          window.error.show(t("middlewares.fetchDatasetAsyncHandlerMiddlewareFactory.feedback.layoutHandlingError"));
        };
        myWorker.postMessage({
          response,
          tableLayout: tableLayout,
          mapLayout: mapLayout,
          chartLayout: chartLayout,
          criteria: datasetMVState.criteria,
          isFirstFetch: datasetMVState.isFirstFetch,
          isMultiViewer: true
        });
      } else if (action.type === REQUEST_ERROR) {
        dispatch(fetchDatasetMVDatasetError(action.payload.statusCode));
      }
    }

    return result;
  };

export default multiViewerMiddlewareFactory;
