export const showDashboardsButton = (modulesConfig, isA11y, noNode, node) => {
  if (modulesConfig.modules.includes("dashboard") && (!isA11y || !noNode)) {
    return (
      (
        node?.itemContainers?.dashboard?.uncategorizedDashboards?.concat(
          node?.itemContainers?.dashboard?.categorizedDashboards
        ) ?? []
      ).length > 0
    );
  } else {
    return false;
  }
};
