import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import BitSet from "bitset";
import {sanitize} from "dompurify";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../../label-format-selector/constants";
import SanitizedHTML from "../../sanitized-html";
import DimensionFilterForm from "./DimensionFilterForm";
import ObservationFilterForm from "./ObservationFilterForm";
import Scrollbars from "./Scrollbars";
import {
  getAttributeLabel,
  getAttributeValueLabel,
  getCoordinatesArrayFromDataIdx,
  getLocalizedTimePeriodFormatMap,
  VARIATION_DIMENSION_KEY
} from "../../../utils/dataset";
import {getPreviewTableHtml, getTableHtml} from "./utils";
import "./style.css";

const $ = window.jQuery;

export const JSONSTAT_TABLE_FONT_SIZE_SM = "s";
export const JSONSTAT_TABLE_FONT_SIZE_MD = "m";
export const JSONSTAT_TABLE_FONT_SIZE_LG = "l";

const COLS_PER_PAGE = 30;
const ROWS_PER_PAGE = 50;

const SLIDER_SAFETY_MARGIN_PERCENTAGE = 30;

let isFirstRender = false;
let isFiltering = false;

const isElementVerticallyInContainer = (element, container) => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom;
};

const isElementHorizontallyInContainer = (element, container) => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return elementRect.left >= containerRect.left && elementRect.right <= containerRect.right;
};

const valueSorter = (a, b, asc) => {
  if (a === b) {
    return 0;
  } else if (a === null || a === undefined || a === "") {
    return 1;
  } else if (b === null || b === undefined || b === "") {
    return -1;
  } else {
    return asc ? a - b : b - a;
  }
};

function Table(props) {
  const {
    jsonStat,
    layout,
    labelFormat = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
    fontSize = JSONSTAT_TABLE_FONT_SIZE_MD,
    isFullscreen,
    isPreview = false,
    removeEmptyLines = !isPreview,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    emptyChar,
    disableWheelZoom = false,
    showTrend = false,
    showCyclical = false,
    sortable = false,
    filterable = false,
    onFilter,
    scrollToLastRow = false,
    scrollToLastCol = false,
    invertedDims,
    hierarchyOnlyAttributes,
    hideHierarchyOnlyRows,
    onPageGenerationComplete,
    onStructureGenerationComplete,
    splitHeaderCells,
    splitSideCells,
    attributesAsDim,
    localizedTimePeriodFormatMapExternal
  } = props;

  const {t} = useTranslation();

  const [uuid] = useState(uuidv4());

  const [tableSupportStructures, setTableSupportStructures] = useState(null);
  const [htmlTable, setHtmlTable] = useState("");

  const [row, setRow] = useState(null);
  const [col, setCol] = useState(null);

  const [visibleRowCount, setVisibleRowCount] = useState(1);
  const [visibleColCount, setVisibleColCount] = useState(1);

  const [order, setOrder] = useState([null, true]); // [colIdx, isOrderedAscending]
  const [filters, setFilters] = useState({}); // map with colIdx as key and filterStr as value
  const [filterColIdx, setFilterColIdx] = useState(null);
  const [filterDimId, setFilterDimId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isHorizontalScrollbarVisible, setHorizontalScrollbarVisibility] = useState(false);
  const [isVerticalScrollbarVisible, setVerticalScrollbarVisibility] = useState(false);

  const [attributes, setAttributes] = useState(null);

  const [worker] = useState(() => new Worker("./workers/getSVTableSupportStructuresWorker.js"));

  const localizedTimePeriodFormatMap = useMemo(
    () => localizedTimePeriodFormatMapExternal ?? getLocalizedTimePeriodFormatMap(),
    [localizedTimePeriodFormatMapExternal]
  );

  useEffect(() => {
    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, [worker]);

  useEffect(() => {
    return () => {
      if (onFilter) {
        onFilter(null);
      }
    };
  }, [onFilter]);

  const handleObservationFilterOpen = useCallback((colIdx, anchorEl) => {
    setAnchorEl(anchorEl);
    setFilterColIdx(colIdx);
  }, []);

  const handleDimensionFilterOpen = useCallback((dimId, anchorEl) => {
    setAnchorEl(anchorEl);
    setFilterDimId(dimId);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorEl(null);
    setFilterColIdx(null);
    setFilterDimId(null);
  }, []);

  const handleFilterApply = useCallback(
    filter => {
      handleFilterClose();

      const newFilters = _.cloneDeep(filters);
      if (filterColIdx !== null) {
        newFilters[filterColIdx] = filter;
      }
      if (filterDimId !== null) {
        newFilters[filterDimId] = filter;
      }
      setFilters(newFilters);

      isFiltering = true;
      setRow(0);
    },
    [filters, filterColIdx, filterDimId, handleFilterClose]
  );

  const handleFilterRemove = useCallback(() => {
    handleFilterClose();

    if ((filterColIdx !== null && filters[filterColIdx]) || (filterDimId !== null && filters[filterDimId])) {
      const newFilters = _.cloneDeep(filters);
      if (filterColIdx !== null) {
        delete newFilters[filterColIdx];
      }
      if (filterDimId !== null) {
        delete newFilters[filterDimId];
      }
      setFilters(newFilters);
      isFiltering = true;
      setRow(0);
    }
  }, [filters, filterColIdx, filterDimId, handleFilterClose]);

  const handleStyle = useCallback(
    isResizing => {
      if (!isPreview && tableSupportStructures) {
        if (!isResizing) {
          const $attributeTooltip = $(`#jsonstat-table__${uuid} #jsonstat-table__tooltip__attribute`);
          const $mergedHeaderTooltip = $(`#jsonstat-table__${uuid} #jsonstat-table__tooltip__merged-header`);

          /** attribute's tooltip handling **/
          $(`#jsonstat-table__${uuid} .ca .ct`)
            .hover(
              function () {
                $mergedHeaderTooltip.css({visibility: "hidden"});

                const {
                  jsonStat,
                  obsAttributeMap,
                  dimAttributeMap,
                  seriesAttributeMap,
                  spreadAttributes,
                  obsAttributes
                } = tableSupportStructures;

                const $elem = $(this).get(0);
                const rect = $elem.getBoundingClientRect();

                const attributes = [];
                if ($elem.className.includes("ctd")) {
                  const dataIdx = $elem.id;

                  (obsAttributeMap?.[dataIdx] ?? [])
                    .filter(attr => !(obsAttributes || []).find(({id}) => id === attr.id))
                    .forEach(attr => attributes.push(attr));

                  const coordinates = getCoordinatesArrayFromDataIdx(dataIdx, jsonStat.size);
                  spreadAttributes.forEach(attr => {
                    const dims = [];
                    attr.dims.forEach(dim => {
                      const dimIdx = jsonStat.id.indexOf(dim);
                      const dimValIdx = coordinates[dimIdx];
                      const dimVal = jsonStat.dimension[dim].category.index[dimValIdx];
                      dims.push(dimVal);
                    });
                    if (dims.length > 0 && seriesAttributeMap[attr.id][dims.join()]) {
                      attributes.push(seriesAttributeMap[attr.id][dims.join()]);
                    }
                  });
                } else {
                  const datasetId = $elem.id.split(":")[0];
                  const dim = $elem.id.split(":")[1];
                  const dimVal = $elem.id.split(":")[2];
                  (dimAttributeMap[datasetId][dim][dimVal] ?? []).forEach(attr => attributes.push(attr));
                }

                const ATTRIBUTE_HEIGHT = 18;

                $attributeTooltip.empty();
                attributes.forEach(attribute => {
                  $attributeTooltip.append(
                    $(
                      `<li class="cttt"><b>${getAttributeLabel(attribute, labelFormat)}</b>: ${getAttributeValueLabel(
                        attribute,
                        labelFormat
                      )}</li>`
                    )
                  );
                });

                const left =
                  rect.x < window.innerWidth / 4 ? rect.right + 16 : rect.left - $attributeTooltip.innerWidth() - 16;

                $attributeTooltip.css({
                  visibility: "visible",
                  top: rect.top - ATTRIBUTE_HEIGHT * attributes.length - 30,
                  left: left
                });
              },
              function () {
                if ($mergedHeaderTooltip.children().length > 0) {
                  $mergedHeaderTooltip.css({visibility: "visible"});
                }
                $attributeTooltip.attr("style", "").empty();
              }
            )
            .off("click")
            .click(function (ev) {
              ev.stopPropagation();

              const {jsonStat, obsAttributeMap, dimAttributeMap, seriesAttributeMap, spreadAttributes, obsAttributes} =
                tableSupportStructures;

              const $elem = $(this).get(0);

              const attributes = [];
              if ($elem.className.includes("ctd")) {
                const dataIdx = $elem.id;

                (obsAttributeMap?.[dataIdx] ?? [])
                  .filter(attr => !(obsAttributes || []).find(({id}) => id === attr.id))
                  .forEach(attr => attributes.push(attr));

                const coordinates = getCoordinatesArrayFromDataIdx(dataIdx, jsonStat.size);
                spreadAttributes.forEach(attr => {
                  const dims = [];
                  attr.dims.forEach(dim => {
                    const dimIdx = jsonStat.id.indexOf(dim);
                    const dimValIdx = coordinates[dimIdx];
                    const dimVal = jsonStat.dimension[dim].category.index[dimValIdx];
                    dims.push(dimVal);
                  });
                  if (dims.length > 0 && seriesAttributeMap[attr.id][dims.join()]) {
                    attributes.push(seriesAttributeMap[attr.id][dims.join()]);
                  }
                });
              } else {
                const datasetId = $elem.id.split(":")[0];
                const dim = $elem.id.split(":")[1];
                const dimVal = $elem.id.split(":")[2];
                (dimAttributeMap[datasetId][dim][dimVal] ?? []).forEach(attr => attributes.push(attr));
              }

              setAttributes(attributes);
            });

          /** sorting handling **/
          $(`#jsonstat-table__${uuid} .c .table-icon.col-sort`)
            .off("click")
            .click(function () {
              const $elem = $(this).get(0);

              const col = Number($elem.id.split("-")[1]);
              const dir = $elem.className.includes("col-sort--a");

              setOrder(prevOrder => {
                if (prevOrder[0] === col && prevOrder[1] === dir) {
                  return [null, true];
                } else {
                  return [col, dir];
                }
              });

              setRow(0);
            });

          /** observation filters handling **/
          $(`#jsonstat-table__${uuid} .c.csh .table-icon.col-filter`)
            .off("click")
            .click(function () {
              const $elem = $(this).get(0);
              const col = Number($elem.id.split("-")[1]);
              handleObservationFilterOpen(col, $elem);
            });

          /** dimension filters handling **/
          $(`#jsonstat-table__${uuid} .c.ch .table-icon.col-filter`)
            .off("click")
            .click(function () {
              const $elem = $(this).get(0);
              const dim = $elem.id.split("-")[1];
              handleDimensionFilterOpen(dim, $elem);
            });
        }
      }
    },
    [uuid, tableSupportStructures, labelFormat, isPreview, handleObservationFilterOpen, handleDimensionFilterOpen]
  );

  const handleScrollbar = useCallback(
    updateVisibleColAndRowCount => {
      if (!isPreview) {
        let $tableContainer = $(`#jsonstat-table__${uuid}`);
        if ($tableContainer && tableSupportStructures) {
          const {rowCount, colCount} = tableSupportStructures;
          const $table = $(`#jsonstat-table__${uuid} table`);

          const isVerticalScrollbarVisible =
            row !== null && (row !== 0 || rowCount > ROWS_PER_PAGE || $table.height() > $tableContainer.height());
          setVerticalScrollbarVisibility(isVerticalScrollbarVisible);

          const isHorizontalScrollbarVisible =
            col !== null && (col !== 0 || colCount > COLS_PER_PAGE || $table.width() > $tableContainer.width());
          setHorizontalScrollbarVisibility(isHorizontalScrollbarVisible);

          if (updateVisibleColAndRowCount) {
            let visibleRowCount = 0;
            $(`#jsonstat-table__${uuid} tbody tr`).each((idx, el) => {
              if (isElementVerticallyInContainer(el, $tableContainer.get(0))) {
                visibleRowCount++;
              }
            });
            setVisibleRowCount(visibleRowCount);

            let visibleColCount = 0;
            $(`#jsonstat-table__${uuid} tbody tr:not(.rs):first td:not(.c-attr-as-dim)`).each((idx, el) => {
              if (isElementHorizontallyInContainer(el, $tableContainer.get(0))) {
                visibleColCount++;
              }
            });
            setVisibleColCount(visibleColCount);
          }
        }
      }
    },
    [uuid, tableSupportStructures, row, col, isPreview]
  );

  /* resize handler */
  useEffect(() => {
    const func = () => {
      handleStyle(true);
      handleScrollbar(true);
    };
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [handleStyle, handleScrollbar]);

  /* generating support table structure */
  useEffect(() => {
    if (jsonStat && layout) {
      setTableSupportStructures(null);
      setHtmlTable("");
      setHorizontalScrollbarVisibility(false);
      setVerticalScrollbarVisibility(false);

      const newLayout = Object.assign({}, layout);
      if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
        newLayout.cols = [...layout.cols, VARIATION_DIMENSION_KEY];
      }

      if (!isPreview) {
        worker.onmessage = event => {
          if (onFilter && isFiltering) {
            onFilter(event.data.filteredRows);
            isFiltering = false;
          }

          const valorizedColsFromArr = new BitSet(event.data.valorizedColsArr);
          const valorizedSectionRowsFromArr = event.data.valorizedSectionRowsArr.map(
            valorizedRowsArr => new BitSet(valorizedRowsArr)
          );

          if (onStructureGenerationComplete) {
            onStructureGenerationComplete({
              arithmeticMeans: event.data.arithmeticMeans,
              arithmeticMeanDims: jsonStat.id.filter(dim => newLayout.cols.includes(dim))
            });
          }

          setTableSupportStructures({
            ...event.data,
            jsonStat,
            layout: newLayout,
            valorizedCols: valorizedColsFromArr,
            valorizedColsFromArr: undefined,
            valorizedSectionRows: valorizedSectionRowsFromArr,
            valorizedSectionRowsFromArr: undefined
          });

          if (event.data.obsAttributeMap === null) {
            console.error(t("components.table.error.observationAttribute"));
          }
        };
        worker.onerror = () => {
          setTableSupportStructures(null);
        };
        worker.postMessage({
          jsonStat,
          layout: newLayout,
          isPreview,
          removeEmptyLines,
          showTrend,
          showCyclical,
          filterable,
          filters,
          labelFormat,
          decimalSeparator,
          roundingStrategy,
          decimalPlaces,
          invertedDims,
          hierarchyOnlyAttributes,
          hideHierarchyOnlyRows,
          localizedTimePeriodFormatMap,
          attributesAsDim
        });
      } else {
        setTableSupportStructures({
          jsonStat,
          layout: newLayout,
          isPreview: true
        });
      }

      setRow(null);
      setCol(null);
      isFirstRender = true;
    }
  }, [
    worker,
    jsonStat,
    layout,
    isPreview,
    removeEmptyLines,
    showTrend,
    showCyclical,
    filterable,
    onFilter,
    filters,
    labelFormat,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    invertedDims,
    hierarchyOnlyAttributes,
    hideHierarchyOnlyRows,
    onStructureGenerationComplete,
    t,
    localizedTimePeriodFormatMap,
    attributesAsDim
  ]);

  /* generating html table */
  useEffect(() => {
    if (tableSupportStructures) {
      const {valueMatrix, sectionRowsOrder} = tableSupportStructures;

      const orderedCol = order[0];
      const isOrderedAscending = order[1];

      let newSectionRowsOrder;
      if (orderedCol !== null) {
        newSectionRowsOrder = {};
        Object.keys(sectionRowsOrder).forEach(s => {
          newSectionRowsOrder[s] = {};
          const prevOrder = Object.keys(sectionRowsOrder[s]).sort((a, b) => a - b);
          const nextOrder = Object.keys(sectionRowsOrder[s]).sort((a, b) => {
            const aVal = valueMatrix?.[s]?.[orderedCol]?.[a];
            const bVal = valueMatrix?.[s]?.[orderedCol]?.[b];
            return valueSorter(aVal, bVal, isOrderedAscending);
          });
          prevOrder.forEach((prevPos, index) => {
            newSectionRowsOrder[s][prevPos] = Number(nextOrder[index]);
          });
        });
      } else {
        newSectionRowsOrder = sectionRowsOrder;
      }

      if (row === null || col === null) {
        if (row === null) {
          setRow(0);
        }
        if (col === null) {
          setCol(0);
        }
      } else {
        if (!isPreview) {
          setHtmlTable(
            getTableHtml(
              uuid,
              tableSupportStructures,
              {
                rowStart: row || 0,
                rowPerPage: ROWS_PER_PAGE,
                colStart: col || 0,
                colPerPage: COLS_PER_PAGE
              },
              labelFormat,
              fontSize,
              decimalSeparator,
              roundingStrategy,
              decimalPlaces,
              sanitize(emptyChar),
              sortable,
              orderedCol,
              isOrderedAscending,
              newSectionRowsOrder,
              filterable,
              hierarchyOnlyAttributes,
              onPageGenerationComplete,
              splitHeaderCells,
              splitSideCells,
              t,
              localizedTimePeriodFormatMap
            )
          );
        } else {
          setHtmlTable(getPreviewTableHtml(uuid, tableSupportStructures, labelFormat));
        }

        $(`#jsonstat-table__${uuid}`).scrollTop(0).scrollLeft(0);
      }
    }
  }, [
    tableSupportStructures,
    uuid,
    labelFormat,
    fontSize,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    emptyChar,
    sortable,
    isPreview,
    row,
    col,
    isFullscreen,
    order,
    onPageGenerationComplete,
    t,
    filterable,
    hierarchyOnlyAttributes,
    splitHeaderCells,
    splitSideCells,
    localizedTimePeriodFormatMap
  ]);

  /* exec style and scrollbar handler */
  useEffect(() => {
    if (htmlTable && htmlTable.length > 0) {
      handleStyle(false);
      handleScrollbar(isFirstRender);
      isFirstRender = false;
    }
  });

  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          height: "100%"
        }}
        className={`jsonstat-table`}
        aria-hidden={true}
      >
        {tableSupportStructures && row !== null && col !== null ? (
          !isPreview ? (
            tableSupportStructures.colCount + tableSupportStructures.rowCount === 0 ? (
              <CustomEmpty text={t("components.table.noDataToDisplay")} />
            ) : (
              <Scrollbars
                verticalValue={row}
                verticalMaxValue={tableSupportStructures.rowCount}
                verticalTicks={
                  tableSupportStructures.rowCount -
                  (scrollToLastRow
                    ? 1
                    : visibleRowCount - Math.floor((visibleRowCount / 100) * SLIDER_SAFETY_MARGIN_PERCENTAGE))
                }
                onVerticalScroll={setRow}
                isVerticalScrollbarVisible={isVerticalScrollbarVisible}
                horizontalValue={col}
                horizontalMaxValue={tableSupportStructures.colCount}
                horizontalTicks={
                  tableSupportStructures.colCount -
                  (scrollToLastCol
                    ? 1
                    : visibleColCount - Math.floor((visibleColCount / 100) * SLIDER_SAFETY_MARGIN_PERCENTAGE))
                }
                onHorizontalScroll={setCol}
                isHorizontalScrollbarVisible={isHorizontalScrollbarVisible}
                disableWheelZoom={disableWheelZoom}
              >
                <Box
                  id={`jsonstat-table__${uuid}`}
                  sx={{
                    width: "100%",
                    height: "100%"
                  }}
                  style={{overflow: "hidden"}}
                  dangerouslySetInnerHTML={{__html: htmlTable}}
                />
              </Scrollbars>
            )
          ) : (
            <Box
              id={`jsonstat-table__${uuid}`}
              sx={{
                width: "100%",
                height: "100%"
              }}
              className={` jsonstat-table__preview`}
              style={{overflow: "auto"}}
              dangerouslySetInnerHTML={{__html: htmlTable}}
            />
          )
        ) : (
          <CustomEmpty text={t("components.table.loading") + "..."} image={<CircularProgress />} />
        )}
      </Box>

      <Dialog open={attributes !== null} onClose={() => setAttributes(null)}>
        <CustomDialogTitle onClose={() => setAttributes(null)}>
          {t("components.table.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {(attributes || []).map((attribute, idx) => (
              <Grid item key={idx} xs={12}>
                <SanitizedHTML
                  html={`- <b>${getAttributeLabel(attribute, labelFormat)}</b>: ${getAttributeValueLabel(
                    attribute,
                    labelFormat
                  )}`}
                  allowTarget
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttributes(null)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        slotProps={{
          paper: {
            sx: {
              width: "320px",
              padding: "8px",
              overflow: "hidden",
              "& .MuiOutlinedInput-input": {
                padding: "8px"
              },
              "& .MuiSelect-outlined": {
                paddingRight: "32px"
              }
            }
          }
        }}
      >
        {filterColIdx !== null ? (
          <ObservationFilterForm
            initialFilter={filters[filterColIdx]}
            onApply={handleFilterApply}
            onRemove={handleFilterRemove}
          />
        ) : (
          <DimensionFilterForm
            initialFilter={filters[filterDimId]}
            onApply={handleFilterApply}
            onRemove={handleFilterRemove}
          />
        )}
      </Popover>
    </Fragment>
  );
}

export default Table;
