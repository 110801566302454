import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ImportDashboardDto} from "../../../../model/wshub-models/importDashboardDto";
import {ImportViewDto} from "../../../../model/wshub-models/importViewDto";
import {UserData} from "../../../../model/wshub-models/userData";

export interface ObjectContainerProps {
  data: ImportDashboardDto[] & ImportViewDto[];
  importData: ImportDashboardDto[] & ImportViewDto[];
  objectType: "template" | "view" | "dashboard";
  setTitlesToChange: (d: any) => void;
  users: UserData[];
}

function CheckBoxInput({label, name, control}: {label: string; name: string; control: any}) {
  return (
    <FormControlLabel
      label={label}
      control={<Controller render={({field}) => <Checkbox {...field} />} name={name} control={control} />}
    />
  );
}

function UserSelectInput({
  name,
  label,
  errors,
  control,
  users
}: {
  name: string;
  label: string;
  errors: any;
  control: any;
  users: UserData[];
}) {
  return (
    <FormControl variant="outlined" size="small">
      <InputLabel id={name}>{label}</InputLabel>
      <Controller
        render={({field}) => (
          <Select
            sx={{minWidth: theme => theme.spacing(11)}}
            labelId={name}
            label={label}
            error={!!errors?.[name]}
            {...field}
          >
            {users &&
              users.map(user => (
                <MenuItem key={user.userId} value={user.userId}>
                  {user.firstName ? [user.firstName, user.lastName].join(" ") : user.username}
                </MenuItem>
              ))}
          </Select>
        )}
        control={control}
        rules={{
          required: true
        }}
        name={name}
      />
    </FormControl>
  );
}

const hasDuplicateTitles = (obj: ImportDashboardDto[] | ImportViewDto[]) => {
  return obj.some(({duplicateTittle}) => duplicateTittle !== undefined);
};
const ObjectContainer = ({data, importData, objectType, setTitlesToChange, users}: ObjectContainerProps) => {
  const inputRefs =
    objectType === "template"
      ? ["IncludeTemplate"]
      : objectType === "view"
      ? ["IncludeView", "UserView", "ViewTittle"]
      : ["IncludeDashboards", "UserDashboards", "DashboardTittle"];

  const {t} = useTranslation();
  const {
    control,
    watch,
    formState: {errors}
  } = useFormContext();

  const getDuplicateTitlesToShow = () => {
    return importData.map((item: ImportDashboardDto & ImportViewDto) => ({
      show: item.hasOwnProperty("duplicateTittle"),
      id: item?.viewTemplateId || item?.itemContainerId,
      tittle: item?.duplicateTittle || item?.title
    }));
  };
  const isImportInValid = (importData && hasDuplicateTitles(importData) && watch(inputRefs[0])) || false;

  const paperStyle = {
    display: "flex",
    margin: "10px 0px",
    alignItems: "center"
  };
  const paperInvalidStyle = {
    borderColor: theme => theme.palette.error.main
  };
  const paperClasses = isImportInValid ? [paperStyle, paperInvalidStyle] : [paperStyle];

  const stillToChange = importData?.filter(item => item.hasOwnProperty("duplicateTittle")).length;

  if (data.length > 0) {
    return (
      <Paper variant="outlined" sx={paperClasses}>
        <Grid
          container
          xs={12}
          sx={{
            padding: "15px",
            alignItems: "center"
          }}
        >
          <Grid item xs={4}>
            <CheckBoxInput
              name={inputRefs[0]}
              label={
                objectType === "template"
                  ? t(`components.nodeImportForm.wizard.secondStep.checkBox.templates`, {
                      objNumber: importData ? stillToChange : data.length
                    })
                  : objectType === "view"
                  ? t(`components.nodeImportForm.wizard.secondStep.checkBox.views`, {
                      objNumber: importData ? stillToChange : data.length
                    })
                  : t(`components.nodeImportForm.wizard.secondStep.checkBox.dashboards`, {
                      objNumber: importData ? stillToChange : data.length
                    })
              }
              control={control}
            ></CheckBoxInput>
          </Grid>
          {objectType !== "template" && watch(inputRefs[0]) && (
            <>
              <Grid item xs={4}>
                <UserSelectInput
                  users={users}
                  label={t("components.nodeImportForm.wizard.secondStep.userSelect")}
                  control={control}
                  name={inputRefs[1]}
                  errors={errors}
                ></UserSelectInput>
              </Grid>

              <Grid
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                {isImportInValid && (
                  <Button onClick={() => setTitlesToChange([inputRefs[2], getDuplicateTitlesToShow()])}>
                    {t("components.nodeImportForm.wizard.secondStep.changeButton")}
                  </Button>
                )}
              </Grid>
            </>
          )}
          {isImportInValid && (
            <Grid item xs={12}>
              <Box pt={2}>
                <Typography variant="caption" color="error">
                  {t("components.nodeImportForm.wizard.secondStep.changeTexts")}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  } else {
    return null;
  }
};

export default ObjectContainer;
