import React from "react";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import CheckIcon from "@mui/icons-material/Check";
import {useTheme} from "@mui/material";
import Badge from "@mui/material/Badge";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import ButtonSelect from "../button-select";

const mapStateToProps = state => ({
  isA11y: state.app.isA11y
});

const A11ySelect = ({isA11y, getCustomA11yPath, getAdditionalA11yUrlParams}) => {
  const location = useLocation();
  const {t} = useTranslation();

  const theme = useTheme();

  const badgeStyle = {
    padding: "0px",
    width: "12px",
    height: "12px",
    minWidth: "0px"
  };

  const badgeActiveStyle = {
    padding: "0px",
    width: "12px",
    height: "12px",
    minWidth: "0px",
    backgroundColor: theme.palette.success.main
  };

  return (
    <ButtonSelect
      icon={
        <Badge
          showZero
          color="secondary"
          overlap="rectangular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          badgeContent={isA11y ? <CheckIcon sx={{fontSize: "12px"}} /> : undefined}
          slotProps={{
            badge: {
              sx: isA11y ? badgeActiveStyle : badgeStyle
            }
          }}
        >
          <AccessibilityNewIcon />
        </Badge>
      }
      onChange={val => {
        if (val !== isA11y) {
          const params = new URLSearchParams(location.search);

          if (val) {
            params.set("accessible", "true");
          } else {
            params.delete("accessible");
          }

          if (getAdditionalA11yUrlParams) {
            const additionalA11yUrlParams = getAdditionalA11yUrlParams(val);
            if (additionalA11yUrlParams && Object.keys(additionalA11yUrlParams).length > 0) {
              Object.keys(additionalA11yUrlParams).forEach(key => params.set(key, additionalA11yUrlParams[key]));
            }
          }

          const paramsStr = params.toString();

          const path = getCustomA11yPath ? getCustomA11yPath(val) || location.pathname : location.pathname;

          window.open("./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr, "_self");
        }
      }}
      ariaLabel={t("components.header.actions.a11y.ariaLabel")}
      tooltip={t("components.header.actions.a11y.title")}
    >
      <span data-value={false}>{t("components.a11ySelect.disable")}</span>
      <span data-value={true}>{t("components.a11ySelect.enable")}</span>
    </ButtonSelect>
  );
};

export default connect(mapStateToProps)(A11ySelect);
