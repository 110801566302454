import React, {Fragment, useEffect, useRef, useState} from "react";
import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import FullscreenDialog from "../../fullscreen-dialog";
import Calculator from "./Calculator";
import Preview from "./Preview";
import VariationAndGrowthRates from "./VariationAndGrowthRates";
import {isMultiLanguageNameUnique} from "../../../utils/validator";
import "./style.css";

const $ = window.jQuery;

const TAB_ID_CALCULATOR = "calculator";
const TAB_ID_STATISTICS = "statistics";

const IndicatorCreate = ({
  indicatorsBaseUrl,
  defaultLanguage,
  languages,
  nodeId,
  nodeCode,

  isOpen,
  onClose,
  datasetId,
  territoryDim,
  timeDim,
  criteria,
  indicators,
  indicatorPreview,
  additionalDatasets,

  years,
  combinations,
  variables,
  variablesDataset,

  getDimLabel,
  getDimValueLabel,

  onPreviewFetch,
  onPreviewReset,
  onPublish
}) => {
  const {t} = useTranslation();

  const [tab, setTab] = useState(TAB_ID_CALCULATOR);
  const [isNameErrorVisible, setNameErrorVisibility] = useState(false);

  const calculatorFormRef = useRef();
  const variationAndGrowthRatesFormRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  const handleStyle = () => {
    const tabsHeight = $("#indicator-create__tabs").outerHeight(true) || 0;
    $("indicator-create__tab-content").height(`calc(100% - ${tabsHeight}px)`);
  };

  const handleClose = () => {
    if (tab === TAB_ID_CALCULATOR) {
      if (calculatorFormRef.current) {
        calculatorFormRef.current.cancel(() => {
          onClose();
        });
      } else {
        onClose();
      }
    } else {
      if (variationAndGrowthRatesFormRef.current) {
        variationAndGrowthRatesFormRef.current.cancel(() => {
          onClose();
        });
      } else {
        onClose();
      }
    }
  };

  const handlePreviewOpen = indicator => {
    const filteredVariablesDataset = {};
    Object.keys(indicator.variables).forEach(
      variable => (filteredVariablesDataset[variable] = variablesDataset[variable])
    );
    const newIndicator = {
      ...indicator,
      variablesDataset: filteredVariablesDataset
    };
    onPreviewFetch(
      nodeId,
      nodeCode,
      datasetId,
      criteria,
      timeDim,
      territoryDim,
      [newIndicator],
      additionalDatasets,
      defaultLanguage,
      languages,
      indicatorsBaseUrl
    );
  };

  const handlePreviewClose = () => {
    onPreviewReset();
  };

  const handlePublish = indicator => {
    if (
      isMultiLanguageNameUnique(
        indicator.title,
        indicators.map(({title}) => title)
      )
    ) {
      const filteredVariablesDataset = {};
      Object.keys(indicator.variables).forEach(
        variable => (filteredVariablesDataset[variable] = variablesDataset[variable])
      );
      onPublish({
        ...indicator,
        variablesDataset: filteredVariablesDataset
      });
    } else {
      setNameErrorVisibility(true);
    }
  };

  const handleSubmit = f => {
    if (tab === TAB_ID_CALCULATOR) {
      if (calculatorFormRef.current) {
        calculatorFormRef.current.submit(f);
      }
    } else {
      if (variationAndGrowthRatesFormRef.current) {
        variationAndGrowthRatesFormRef.current.submit(f);
      }
    }
  };

  return (
    <Fragment>
      <FullscreenDialog open={isOpen} onClose={handleClose} maxWidth={1280}>
        <CustomDialogTitle onClose={handleClose}>
          {t("components.indicatorDialogs.create.dialogs.form.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Box id="indicator-create__tabs" sx={{height: "100%"}}>
            <Tabs
              value={tab}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(_, tab) => {
                if (tab === TAB_ID_CALCULATOR) {
                  document.getElementById("indicator-create__tab-content").style.overflowY = "auto";
                } else {
                  document.getElementById("indicator-create__tab-content").style.overflowY = "hidden";
                }
                setTab(tab);
              }}
            >
              <Tab value={TAB_ID_CALCULATOR} label={t("components.indicatorDialogs.create.tabs.calculator.title")} />
              <Tab value={TAB_ID_STATISTICS} label={t("components.indicatorDialogs.create.tabs.statistics.title")} />
            </Tabs>
            <Box
              id="indicator-create__tab-content"
              sx={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "calc(100% - 60px)",
                marginTop: "12px",
                padding: "12px"
              }}
            >
              {tab === TAB_ID_CALCULATOR && (
                <Calculator
                  years={years}
                  timeDim={timeDim}
                  combinations={combinations}
                  variables={variables}
                  variablesDataset={variablesDataset}
                  getDimLabel={getDimLabel}
                  getDimValueLabel={getDimValueLabel}
                  ref={calculatorFormRef}
                />
              )}
              {tab === TAB_ID_STATISTICS && (
                <VariationAndGrowthRates
                  years={years}
                  timeDim={timeDim}
                  combinations={combinations}
                  variables={variables}
                  variablesDataset={variablesDataset}
                  getDimLabel={getDimLabel}
                  getDimValueLabel={getDimValueLabel}
                  ref={variationAndGrowthRatesFormRef}
                />
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("commons.confirm.close")}</Button>
          <Button onClick={() => handleSubmit(handlePreviewOpen)}>
            {t("components.indicatorDialogs.create.actions.showPreview")}
          </Button>
          <Button onClick={() => handleSubmit(handlePublish)}>
            {t("components.indicatorDialogs.create.actions.publish")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <FullscreenDialog open={indicatorPreview !== null} onClose={handlePreviewClose}>
        <CustomDialogTitle onClose={handlePreviewClose}>
          {t("components.indicatorDialogs.create.dialogs.preview.title")}
        </CustomDialogTitle>
        <DialogContent>
          {indicatorPreview !== "" ? (
            <Preview jsonStat={indicatorPreview} territoryDim={territoryDim} timeDim={timeDim} />
          ) : (
            <CustomEmpty text={t("components.indicatorDialogs.create.dialogs.emptyPreview")} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </FullscreenDialog>

      <Dialog open={isNameErrorVisible} onClose={() => setNameErrorVisibility(false)}>
        <CustomDialogTitle onClose={() => setNameErrorVisibility(false)}>
          {t("components.indicatorDialogs.create.dialogs.error.duplicateName.title")}
        </CustomDialogTitle>
        <DialogContent>{t("components.indicatorDialogs.create.dialogs.error.duplicateName.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setNameErrorVisibility(false)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default IndicatorCreate;
