import React from "react";
import {Box, Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ColorPickerArea from "./ColorPickerArea";
import ColorsPickerCard from "./ColorsPickerCard";

const CustomColorsForm = fieldStyle => {
  const {t} = useTranslation();
  const {setValue, watch} = useFormContext();

  return (
    <Box display="flex" flexDirection="column" sx={{marginTop: "16px"}}>
      <FormControl fullWidth sx={fieldStyle}>
        <FormControlLabel
          control={
            <Checkbox
              checked={watch("customColors.isEnabled")}
              onChange={(_, checked) => setValue("customColors.isEnabled", checked)}
            />
          }
          label={t("scenes.customColorsSettings.enable")}
        />
      </FormControl>
      {watch("customColors.isEnabled") && (
        <Box display="flex" flexDirection="column" marginTop="30px">
          <ColorPickerArea label={t("scenes.customColorsSettings.text")} valueName={["text", "primary"]} />
          <Box display="flex" marginTop="30px" flexDirection="row" gap={"20px"}>
            <ColorsPickerCard title={t("scenes.customColorsSettings.primary")} isPrimary />
            <ColorsPickerCard title={t("scenes.customColorsSettings.secondary")} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CustomColorsForm;
