import React, {Fragment, useState} from "react";
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {sanitize} from "dompurify";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../custom-dialog-title";
import MetadataIcon from "../custom-icons/MetadataIcon";
import FullscreenDialog from "../fullscreen-dialog";
import {fetchDatasetMetadata, hideDatasetMetadata} from "../../state/miscellaneous/miscellaneousActions";

const mapStateToProps = ({miscellaneous}) => ({
  datasetMetadataHtml: miscellaneous.datasetMetadataHtml,
  datasetMetadataUrl: miscellaneous.datasetMetadataUrl
});

const mapDispatchToProps = dispatch => ({
  onDatasetMetadataFetch: (nodeId, datasetId, metadataUrl) =>
    dispatch(fetchDatasetMetadata(nodeId, datasetId, metadataUrl)),
  onDatasetMetadataHide: () => dispatch(hideDatasetMetadata())
});

export const VIEW_MODE_BOTTOM_NAVIGATION_BUTTON = "VIEW_MODE_BOTTOM_NAVIGATION_BUTTON";
export const VIEW_MODE_ICON_BUTTON = "VIEW_MODE_ICON_BUTTON";
export const VIEW_MODE_BUTTON = "VIEW_MODE_BUTTON";

const DatasetMetadataButton = ({
  t,
  nodeId,
  datasetId,
  metadataUrl,
  sx,
  label,
  iconSize,
  viewMode = VIEW_MODE_BOTTOM_NAVIGATION_BUTTON,
  datasetMetadataHtml,
  datasetMetadataUrl,
  onDatasetMetadataFetch,
  onDatasetMetadataHide
}) => {
  const [isVisible, setVisibility] = useState(false);

  const onOpen = () => {
    onDatasetMetadataFetch(nodeId, datasetId, metadataUrl);
    setVisibility(true);
  };

  const onClose = ev => {
    ev.stopPropagation();
    onDatasetMetadataHide();
    setVisibility(false);
  };

  if (!metadataUrl && !viewMode === VIEW_MODE_BUTTON) {
    return <span />;
  }

  return (
    <Fragment>
      {viewMode === VIEW_MODE_BOTTOM_NAVIGATION_BUTTON ? (
        <BottomNavigation showLabels onChange={() => onOpen()} style={{marginBottom: 8}}>
          <BottomNavigationAction
            id="bottom_navigation_action_dataset_metadata_btn"
            label={label || t("components.datasetMetadataButton.label")}
            icon={<MetadataIcon />}
            role="menuitem"
          />
        </BottomNavigation>
      ) : viewMode === VIEW_MODE_ICON_BUTTON ? (
        <Tooltip title={label || t("components.datasetMetadataButton.label")}>
          <IconButton
            id="dataset-metadata-btn"
            size={iconSize}
            onClick={ev => {
              ev.stopPropagation();
              onOpen();
            }}
            aria-label={label || t("components.datasetMetadataButton.label")}
            sx={sx}
            className="dataset-metadata-btn"
          >
            <MetadataIcon />
          </IconButton>
        </Tooltip>
      ) : viewMode === VIEW_MODE_BUTTON ? (
        <Tooltip title={label || t("components.datasetMetadataButton.label")}>
          <Button
            onClick={ev => {
              ev.stopPropagation();
              onOpen();
            }}
            id="dataset_metadata_btn"
            size="small"
            color="secondary"
            variant="contained"
            style={{whiteSpace: "nowrap"}}
            sx={sx}
          >
            {label || t("components.datasetMetadataButton.label")}
          </Button>
        </Tooltip>
      ) : null}

      <FullscreenDialog open={isVisible} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>{label || t("components.datasetMetadataButton.label")}</CustomDialogTitle>
        <DialogContent>
          <iframe
            title={"title"}
            srcDoc={datasetMetadataHtml !== null ? sanitize(datasetMetadataHtml, {FORCE_BODY: true}) : null}
            src={datasetMetadataUrl}
            style={{
              border: 0,
              width: "100%",
              height: "calc(100% - 6px)"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </FullscreenDialog>
    </Fragment>
  );
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DatasetMetadataButton);
