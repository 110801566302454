import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import AutocompleteSearchInput from "../autocomplete-search-input";
import {setDetailLevel} from "../../state/detailLevel/detailLevelActions";
import {closeSearchDialog} from "../../state/search-dialog/searchDialogActions";
import {getTrimmedStr} from "../../utils/formatters";

const SearchDialog = ({dialogTop, onSubmit, catalog, isOpen, onClose, resetDetailLevel}) => {
  const [uuid] = useState(uuidv4());

  const datasets = Object.keys(catalog?.datasetMap || {}).map(key => catalog.datasetMap[key].title);
  const uncategorizedDatasets = (catalog?.uncategorizedDatasets || []).map(({title}) => title);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          position: "absolute",
          top: dialogTop,
          height: 40
        }
      }}
      maxWidth={false}
    >
      <div id={`search-dialog__dialog__${uuid}`} style={{width: "75vw"}}>
        <AutocompleteSearchInput
          onSubmit={value => {
            const trimmedValue = getTrimmedStr(value);
            if (trimmedValue.length > 0) {
              onClose();
              onSubmit(trimmedValue);
              resetDetailLevel();
            }
          }}
          options={datasets.concat(uncategorizedDatasets)}
        />
      </div>
    </Dialog>
  );
};

export default connect(
  state => ({
    catalog: state.catalog,
    isOpen: state.searchDialog.isOpen
  }),
  dispatch => ({
    onClose: () => dispatch(closeSearchDialog()),
    resetDetailLevel: () => dispatch(setDetailLevel(null))
  })
)(SearchDialog);
