import React, {Fragment, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect, useSelector} from "react-redux";
import {Route, Routes, useParams} from "react-router-dom";
import {compose} from "redux";
import Categories from "../components/categories";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../components/label-format-selector/constants";
import ModulePage from "../components/module-page";
import Node from "../components/node";
import CategoriesDomain from "../domains/CategoriesDomain";
import DatasetDomain from "../domains/DatasetDomain";
import ItemContainersDomain from "../domains/ItemContainersDomain";
import SearchDomain from "../domains/SearchDomain";
import Call from "../hocs/call";
import {goToNode} from "../links";
import {submitDatasetSVDownload} from "../state/dataset/single-viewer/actions";
import {nodeCustomHomePageIdSelector} from "../state/node/nodeSelectors";
import {DOWNLOAD_FORMAT_CSV, downloadFormats} from "../utils/download";
import {DECIMAL_SEPARATOR_DEFAULT} from "../utils/formatters";

const mapStateToProps = state => ({
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  modulesConfig: state.app.modulesConfig,
  hub: state.hub,
  node: state.node,
  catalog: state.catalog
});

const mapDispatchToProps = dispatch => ({
  onDownloadSubmit: (
    nodeId,
    datasetId,
    datasetTitle,
    criteria,
    layout,
    format,
    extension,
    zipped,
    params,
    defaultLanguage,
    languages,
    t
  ) =>
    dispatch(
      submitDatasetSVDownload(
        nodeId,
        datasetId,
        datasetTitle,
        criteria,
        null,
        layout,
        format,
        extension,
        zipped,
        params,
        defaultLanguage,
        languages,
        t
      )
    )
});

const NodeRouter = ({
  t,
  defaultLanguage,
  languages,
  modulesConfig,
  hub,
  node,
  catalog,
  isDefault,
  onDownloadSubmit
}) => {
  const {nodeCode} = useParams();

  const [accessibleDataset, setAccessibleDataset] = useState(null);

  const areNodeAndCatalogOk =
    node && catalog && node.code.toLowerCase() === nodeCode.toLowerCase() && node.nodeId === catalog.nodeId;

  const nodeHavingCode = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());
  const nodeHomePageId = useSelector(nodeCustomHomePageIdSelector(nodeHavingCode.nodeId));

  return (
    <Fragment>
      <Routes>
        <Route
          path="/"
          element={
            nodeHomePageId ? (
              <ItemContainersDomain
                itemContainerId={nodeHomePageId}
                hideBreadcrumbs={true}
                nodeCode={nodeCode}
                isDefault={isDefault}
              />
            ) : (
              <Node nodeCode={nodeCode} isDefault={isDefault} />
            )
          }
        />
        {modulesConfig.modules.includes("dashboard") && (
          <Route path="/dashboards" element={<ItemContainersDomain nodeCode={nodeCode} />} />
        )}
        {modulesConfig.modules.includes("custom-page") && (
          <Route path="/pages/:id" element={<ItemContainersDomain />} />
        )}
        <Route
          path="/search"
          element={
            areNodeAndCatalogOk && (
              <SearchDomain
                hub={hub}
                node={node}
                nodeCode={nodeCode}
                isDefault={isDefault}
                catalog={catalog}
                setAccessibleDataset={setAccessibleDataset}
              />
            )
          }
        />
        <Route
          path="/categories"
          element={
            areNodeAndCatalogOk &&
            (catalog.isEmpty ? (
              <Call cb={goToNode} cbParam={nodeCode}>
                <span />
              </Call>
            ) : (
              /* list of categories */
              <Categories hub={hub} node={node} catalog={catalog} nodeCode={nodeCode} isDefault={isDefault} />
            ))
          }
        />
        <Route
          path="/categories/*"
          element={
            areNodeAndCatalogOk && (
              <CategoriesDomain
                hub={hub}
                node={node}
                catalog={catalog}
                nodeCode={nodeCode}
                isDefault={isDefault}
                setAccessibleDataset={setAccessibleDataset}
              />
            )
          }
        />
        {(modulesConfig.nodeRoutes || []).map(module => {
          return (
            <Route
              key={module.id}
              path={`/${module.route}/*`}
              element={
                areNodeAndCatalogOk && (
                  <ModulePage
                    moduleId={module.id}
                    moduleComponent={module.component}
                    moduleFallback={module.fallback}
                    hideAppBar={module.hideAppBar}
                    hideFooter={module.hideFooter}
                    isDefault={isDefault}
                  />
                )
              }
            />
          );
        })}
        <Route
          path="/:datasetId"
          element={
            areNodeAndCatalogOk &&
            !catalog.isEmpty && (
              <DatasetDomain
                key={window.location.href}
                hub={hub}
                node={node}
                nodeCode={nodeCode}
                isDefault={isDefault}
                catalog={catalog}
                categoryPath={[]}
              />
            )
          }
        />
        <Route
          element={
            <Call cb={goToNode} cbParam={nodeCode}>
              <span />
            </Call>
          }
        />
      </Routes>
      <Call
        cb={({nodeId, datasetId, criteria, datasetTitle}) => {
          const exportParams = {
            decimalSeparator: DECIMAL_SEPARATOR_DEFAULT,
            labelFormat: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
          };

          onDownloadSubmit(
            nodeId,
            datasetId,
            datasetTitle,
            criteria,
            null,
            DOWNLOAD_FORMAT_CSV,
            downloadFormats()[DOWNLOAD_FORMAT_CSV].extension,
            false,
            exportParams,
            defaultLanguage,
            languages,
            t
          );

          setAccessibleDataset(null);
        }}
        cbParam={{
          nodeId: node?.nodeId,
          datasetId: accessibleDataset?.identifier,
          criteria: {},
          datasetTitle: accessibleDataset?.title
        }}
        disabled={!accessibleDataset}
      >
        <span />
      </Call>
    </Fragment>
  );
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(NodeRouter);
