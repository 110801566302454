import React, {useEffect} from "react";
import {Alert, Box, Button, DialogActions, Divider, FormControl, Grid, Paper, Typography} from "@mui/material";
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {getImportFiltersErrorTranslations} from "../../../constants/getImportFiltersErrorTranslations";
import {FilterDto} from "../../../model/item-containers-models/filterDto";
import FileInputTs from "../../file-input-ts";
import I18nTextField from "../../i18n-text-field";
import useApi from "../../../state/api/useApi";
import {getFilter, importFilterFile, validateFilterFile} from "../../../state/dashboardFilters/dashboardFilterActions";

export interface FilterConfigationFormProps {
  file?: File;
  data: number & FilterDto;
  filterToEdit: number;
  onClose: () => void;
  onFilterCreated: () => void;
}

interface ImportFilterForm {
  filters: FilterDto;
  fileOnedit?: FileList;
}

interface LevelLabelInputProps {
  code: string;
  index: number;
}

function allPropertiesEmpty(obj: any) {
  return obj ? Object.values(obj).every(val => val === "") : true;
}

const LevelLabelInput = ({code, index}: LevelLabelInputProps) => {
  const {t} = useTranslation();
  const {
    control,
    formState: {errors},
    watch
  } = useFormContext<ImportFilterForm>();

  const filtersWatch = watch("filters");

  return (
    <Paper variant="outlined">
      <Box padding={1}>
        <Grid container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="subtitle1" component="p">
              {code}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box marginBottom={1}>
              <FormControl fullWidth>
                <Controller
                  render={({field}) => (
                    <I18nTextField
                      {...field}
                      value={filtersWatch?.levels?.[index].label}
                      fullWidth
                      label={t("components.filtersSettingsForm.wizard.secondStep.levelLable")}
                      variant="outlined"
                      error={errors.filters?.levels && !!errors.filters?.levels[index]}
                    />
                  )}
                  rules={{validate: value => !allPropertiesEmpty(value)}}
                  control={control}
                  name={`filters.levels.${index}.label`}
                ></Controller>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const FilterConfigurationForm = ({data, onClose, file, filterToEdit, onFilterCreated}: FilterConfigationFormProps) => {
  const configurationForm = useForm<ImportFilterForm>({
    defaultValues: {
      fileOnedit: null
    }
  });
  const {t} = useTranslation();
  const watchFileOnEdit = configurationForm.watch("fileOnedit");

  const getFilerApi = useApi<FilterDto>(getFilter(filterToEdit), {
    onSuccess: data => {
      configurationForm.setValue("filters.code", data.code);
      configurationForm.setValue("filters.id", data.id);
      configurationForm.setValue("filters.name", data.name);
      configurationForm.setValue(
        "filters.levels",
        data.levels.map(({label, level}) => ({label, level}))
      );
    }
  });

  const isFormOnEdit = getFilerApi.data && filterToEdit !== -1;

  const importFilterApi = useApi<number>(importFilterFile(), {
    onSuccess: data => {
      onClose();
      onFilterCreated();
      getFilerApi.clear();
    },
    onError: data => {},
    clearOnUnmount: true
  });

  const validateFilterApi = useApi<number>(validateFilterFile(), {clearOnUnmount: true});

  useEffect(() => {
    if (filterToEdit !== -1) {
      getFilerApi.call({...getFilerApi.request});
    }
  }, []);

  const areLevelsMismatch = validateFilterApi?.data
    ? watchFileOnEdit && getFilerApi.data?.levels.length !== validateFilterApi.data
    : false;

  const validateFilterHandler = (fileOnedit: FileList) => {
    const importFormData = new FormData();
    importFormData.append("file", fileOnedit[0]);
    validateFilterApi.call({...validateFilterApi.request, data: importFormData});
  };

  const importFiltersHandler = ({filters, fileOnedit}: {filters: FilterDto; fileOnedit?: FileList}) => {
    const updatedLevels = filters.levels.map((v, index) => {
      return {...v, level: index + 1};
    });
    filters.id = filterToEdit !== -1 ? filterToEdit : 0;
    filters.levels = updatedLevels;
    const importFormData = new FormData();
    importFormData.append("filters", JSON.stringify(filters));
    importFormData.append("file", file || fileOnedit?.[0] || null);
    importFilterApi.call({...importFilterApi.request, data: importFormData});
  };

  return (
    <FormProvider {...configurationForm}>
      <form onSubmit={configurationForm.handleSubmit(importFiltersHandler)}>
        {importFilterApi.error && (
          <Alert
            style={{
              marginBottom: 16
            }}
            severity="error"
          >
            {getImportFiltersErrorTranslations(t)[importFilterApi.error] || t("errors.importFilters.generic")}
          </Alert>
        )}
        {isFormOnEdit && (
          <Box my={3}>
            <FileInputTs
              initialTextValue={getFilerApi.data?.code}
              inputProps={{
                ...configurationForm.register("fileOnedit")
              }}
              onUpload={validateFilterHandler}
              OnReset={() => configurationForm.resetField("fileOnedit")}
              textFieldProps={{
                error: areLevelsMismatch,
                helperText:
                  areLevelsMismatch && t("components.filtersSettingsForm.wizard.secondStep.mismatchFileLevels"),
                fullWidth: true
              }}
            />
          </Box>
        )}
        <Box my={3}>
          <FormControl fullWidth>
            <Controller
              render={({field}) => (
                <I18nTextField
                  {...field}
                  fullWidth
                  label={t("components.filtersSettingsForm.wizard.secondStep.filterLabel")}
                  variant="outlined"
                  error={!!configurationForm.formState.errors.filters?.name}
                  helperText={configurationForm.formState.errors.filters?.name && t("commons.validation.required")}
                />
              )}
              name="filters.name"
              control={configurationForm.control}
              rules={{validate: value => !allPropertiesEmpty(value)}}
            ></Controller>
          </FormControl>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        {!isFormOnEdit
          ? Array.from({length: data}, (_, index) => (
              <Box mb={1}>
                <LevelLabelInput
                  key={index}
                  index={index}
                  code={t("components.filtersSettingsForm.wizard.secondStep.typographyLabel", {index: index + 1})}
                />
              </Box>
            ))
          : getFilerApi.data?.levels.map((_, index) => (
              <Box mb={1}>
                <LevelLabelInput
                  key={index}
                  index={index}
                  code={t("components.filtersSettingsForm.wizard.secondStep.typographyLabel", {index: index + 1})}
                />
              </Box>
            ))}

        <DialogActions>
          <Button
            type="button"
            onClick={() => {
              getFilerApi.clear();
              onClose();
            }}
          >
            {t("commons.confirm.close")}
          </Button>
          <Button type="submit" disabled={areLevelsMismatch}>
            {filterToEdit === -1
              ? t("components.filtersSettingsForm.wizard.secondStep.createButton")
              : t("components.filtersSettingsForm.wizard.secondStep.editButton")}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default FilterConfigurationForm;
