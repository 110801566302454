import {ICategory} from "../model/ICategory";
import {ICategoryGroup} from "../model/ICategoryGroup";
import {ICategoryProvider} from "../model/ICategoryProvider";
import {IDataset} from "../model/IDataset";
import {CategoryGroupModelView} from "../model/item-containers-models/categoryGroupModelView";
import {CategoryModelView} from "../model/item-containers-models/categoryModelView";
import {getFilteredTreeWithPaths, getMappedTree, getNodes} from "./tree";
import {CatalogState} from "../state/catalog/catalogReducer";

export const getDetailLevelTree = (tree: any, t: any) =>
  getMappedTree(tree, "layers", (node: any, idx: number) => ({
    ...node,
    key: (node.id || `root_${idx}`).toString(),
    label: node.parentCategory
      ? node.label || node.nameIdentifier
      : t(`detailLevels.categories.${node.category}`) /* i18next-extract-disable-line */,
    layers: (node.layers || [])
      .map((child: any) => ({...child, parentCategory: node.category || null}))
      .sort((a: any, b: any) => a.order - b.order || a.id - b.id)
  }));

export const getDetailLevelFromTree = (tree: any, prefValues: number[] | null = null) => {
  const values = getNodes(tree, "layers", (node: any) => node.id !== null && node.id !== undefined).map(({id}) => id);
  if (values.length > 0) {
    if (prefValues !== null) {
      const prefValue = prefValues.find((value: number) => values.includes(value));
      if (prefValue) {
        return prefValue;
      }
    }

    if (values.includes(3)) {
      return 3;
    } else {
      return values[0];
    }
  }
};

export const getFilteredCatalog = (catalog: ICategoryProvider, filter: (id: string, dataset: IDataset) => boolean) => {
  const datasets: {[key: string]: IDataset} = {};
  Object.keys(catalog.datasetMap)
    .filter(id => filter(id, catalog.datasetMap[id]))
    .forEach(id => (datasets[id] = catalog.datasetMap[id]));

  const categoryGroups = (catalog.categoryGroups || [])
    .map((group: ICategoryGroup) => ({
      ...group,
      categories: getFilteredTreeWithPaths(
        getMappedTree(group.categories, "childrenCategories", (cat: ICategory) => ({
          ...cat,
          datasetIdentifiers: (cat.datasetIdentifiers || []).filter(id => filter(id, catalog.datasetMap[id]))
        })),
        "childrenCategories",
        (cat: ICategory) => cat.datasetIdentifiers && cat.datasetIdentifiers.length > 0
      )
    }))
    .filter((group: ICategoryGroup) => group.categories && group.categories.length > 0);

  const uncategorizedDatasets = (catalog.uncategorizedDatasets || []).filter((dataset: IDataset) =>
    filter(dataset.identifier, dataset)
  );

  return {
    ...catalog,
    categoryGroups: categoryGroups,
    datasets: datasets,
    uncategorizedDatasets: uncategorizedDatasets,
    isEmpty: Object.keys(datasets).length === 0 && (uncategorizedDatasets || []).length === 0
  };
};

export const getCategoryFromCatalog = (categoryPath: string[], catalog: CatalogState) => {
  if (!categoryPath || !catalog) {
    return null;
  }

  let res = null;

  if (catalog.categoryGroups.length === 0) {
    return null;
  } else if (catalog.categoryGroups.length === 1) {
    const category = (catalog.categoryGroups[0]?.categories || []).find(
      (category: ICategory) => category.id === categoryPath[0]
    );

    if (!category) {
      return null;
    }

    if (categoryPath.length === 1) {
      res = category;
    } else {
      let tree = category.childrenCategories;
      categoryPath.slice(1).forEach((elem, idx, arr) => {
        if (idx < arr.length - 1) {
          tree = tree.find(({id}) => id === elem)?.childrenCategories || [];
        } else {
          res = tree.find(({id}) => id === elem) || null;
        }
      });
    }
  } else {
    const categorySchema = catalog.categoryGroups.find(
      (categoryGroup: ICategoryGroup) => categoryGroup.id === categoryPath[0]
    );

    if (!categorySchema) {
      return null;
    }

    if (categoryPath.length === 1) {
      res = {
        childrenCategories: categorySchema.categories,
        datasetIdentifiers: []
      };
    } else {
      let tree = categorySchema.categories;
      categoryPath.slice(1).forEach((elem, idx, arr) => {
        if (idx < arr.length - 1) {
          tree = tree.find(({id}) => id === elem)?.childrenCategories || [];
        } else {
          res = tree.find(({id}) => id === elem) || null;
        }
      });
    }
  }

  return res;
};

export const getDatasetFromCatalog = (datasetId: string, catalog: CatalogState) => {
  if (!datasetId || !catalog) {
    return null;
  }

  const categorizedDataset = catalog.datasetMap[datasetId] || null;
  const uncategorizedDataset = catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) || null;

  return categorizedDataset || uncategorizedDataset;
};

export function getPathToSelectedCategory(
  selectedCategoryId: string,
  agency: CategoryGroupModelView
): CategoryModelView[] {
  const treeHasSelectedNode = (child: CategoryModelView) => {
    if (child.id === selectedCategoryId) return true;
    else return child.childrenCategories.some(treeHasSelectedNode);
  };
  const buildPath = (path: CategoryModelView[], child: CategoryModelView) =>
    treeHasSelectedNode(child)
      ? child.childrenCategories.reduce<CategoryModelView[]>(buildPath, path.concat(child))
      : path;

  return agency.categories.reduce(buildPath, []);
}

export function findSelectedCategory(
  selectedCategoryId: string,
  agency: CategoryGroupModelView
): CategoryModelView | CategoryGroupModelView {
  if (agency.id === selectedCategoryId) return agency;
  const findRec = (categories: CategoryModelView[] = []): CategoryModelView => {
    for (const category of categories) {
      if (category.id === selectedCategoryId) return category;
      const result = findRec(category.childrenCategories);
      if (result) return result;
    }
    return null;
  };
  return findRec(agency.categories);
}

export function getAgencyAndCategoryFromFullId(fullId: string) {
  const [agencyId, categoryId] = fullId.split(";");
  return {agencyId, categoryId};
}

export function updateCategoriesGroupDashboardWidgetInfo(
  categoryGroups: Array<ICategoryGroup>,
  datasets: {[key: string]: IDataset}
) {
  const newCategoryGroups = (categoryGroups || []).map((group: ICategoryGroup) => ({
    ...group,
    categories: updateCategoriesDashboardWidgetInfo(group.categories, datasets || {})
  }));

  return newCategoryGroups;
}

function updateCategoriesDashboardWidgetInfo(categories: ICategory[], datasets: {[key: string]: IDataset}) {
  const newCategories = (categories || []).map((category: ICategory) => ({
    ...category,
    hasDashboards: categoryHasDashboard(category.datasetIdentifiers, datasets)
  }));

  return newCategories;
}

function categoryHasDashboard(datasetIdentifiers: string[], datasets: {[key: string]: IDataset}) {
  let hasDashboards: boolean = false;

  for (var i = 0; i < (datasetIdentifiers || []).length; i++) {
    const datasetId = datasetIdentifiers[i];
    const dataset = datasets[datasetId];
    if (dataset && dataset.datasetType === "dashboard") {
      hasDashboards = true;
      break;
    }
  }

  return hasDashboards;
}

export function uncategorizedHasDashboard(uncategorizedDatasets: IDataset[]) {
  let hasDashboards: boolean = false;

  for (var i = 0; i < (uncategorizedDatasets || []).length; i++) {
    if (uncategorizedDatasets[i].datasetType === "dashboard") {
      hasDashboards = true;
      break;
    }
  }

  return hasDashboards;
}
