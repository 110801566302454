import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const PolarIcon = props => (
  <SvgIcon {...props}>
    <path d="M 6.86 2.4 C 6.86 2.4 7.42 4.56 7.42 4.56 C 7.42 4.56 8.6 9.12 8.6 9.12 C 8.6 9.12 9.8 13.76 9.8 13.76 C 10.7 13.13 12.27 11.48 13.14 10.64 C 13.14 10.64 13.96 9.92 13.96 9.92 C 13.96 9.92 16.33 7.6 16.33 7.6 C 16.33 7.6 18.45 5.6 18.45 5.6 C 17.96 4.94 16.81 4.14 16.08 3.71 C 14.05 2.52 11.91 1.99 9.55 2 C 8.86 2 7.5 2.17 6.86 2.4 Z" />
    <path d="M 10.12 14 C 10.12 14 13.88 15.05 13.88 15.05 C 13.88 15.05 19.59 16.64 19.59 16.64 C 20.29 14.27 19.97 11.34 18.69 9.2 C 18.4 8.71 18.05 8.19 17.68 7.76 C 17.55 7.62 17.35 7.38 17.14 7.38 C 16.94 7.38 16.71 7.63 16.57 7.76 C 16.57 7.76 15.27 8.96 15.27 8.96 C 15.27 8.96 11.76 12.32 11.76 12.32 C 11.29 12.77 10.48 13.51 10.12 14 Z" />
    <path d="M 5.22 12.64 C 5.22 12.64 9.47 13.84 9.47 13.84 C 9.47 13.84 8.72 10.72 8.72 10.72 C 8.67 10.5 8.54 9.8 8.36 9.69 C 8.13 9.55 7.49 9.9 7.27 10.02 C 6.29 10.55 5.43 11.54 5.22 12.64 Z" />
    <path d="M 4.24 12.72 C 3.83 13.96 4.08 15.5 4.67 16.64 C 4.81 16.9 5.23 17.65 5.55 17.67 C 5.85 17.68 6.41 17.08 6.61 16.88 C 6.61 16.88 8.49 15.04 8.49 15.04 C 8.8 14.73 9.19 14.47 9.39 14.08 C 9.39 14.08 4.24 12.72 4.24 12.72 Z" />
    <path d="M 9.96 14.32 C 9.96 14.32 10.46 16.24 10.46 16.24 C 10.46 16.24 11.34 19.68 11.34 19.68 C 11.34 19.68 11.92 22 11.92 22 C 13.94 21.55 15.7 20.27 16.81 18.56 C 17.22 17.94 17.65 17.15 17.71 16.4 C 17.71 16.4 15.27 15.74 15.27 15.74 C 15.27 15.74 12.33 14.93 12.33 14.93 C 12.33 14.93 9.96 14.32 9.96 14.32 Z" />
    <path d="M 9.71 14.4 C 9.21 14.63 8.73 15.21 8.33 15.6 C 8.33 15.6 6.2 17.6 6.2 17.6 C 6 17.8 5.24 18.46 5.24 18.72 C 5.24 19 5.97 19.45 6.2 19.6 C 7.45 20.37 8.43 20.56 9.88 20.56 C 10.13 20.56 10.95 20.55 11.1 20.34 C 11.24 20.15 10.9 19.07 10.83 18.8 C 10.83 18.8 9.71 14.4 9.71 14.4 Z" />
  </SvgIcon>
);

export default PolarIcon;
