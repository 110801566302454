import {Fragment, useCallback, useState} from "react";
import HelpIcon from "@mui/icons-material/Help";
import {Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import AppInfo from "../app-info";

const InfoButton = ({hub}) => {
  const {t} = useTranslation();
  const [isOpen, setisOpen] = useState(false);

  const onOpen = useCallback(() => {
    setisOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setisOpen(false);
  }, []);

  return (
    <Fragment>
      <Grid item className="info-btn">
        <Tooltip title={t("components.header.actions.info.title")}>
          <IconButton onClick={onOpen} color="inherit" aria-label={t("components.header.actions.info.ariaLabel")}>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Dialog open={isOpen} maxWidth="sm" onClose={onClose}>
        <DialogContent>
          <AppInfo hub={hub} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default InfoButton;
