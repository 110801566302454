import React, {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Grid} from "@mui/material";
import {Button} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {goToCustomPage, goToDashboard} from "../../../links";
import {NodeDashboardSharePermission} from "../../../modules/dashboard/constants";
import ConfirmationDialog from "../../confirmation-dialog";
import CustomDialogTitle from "../../custom-dialog-title";
import MaterialTable from "../../material-table";
import TabPanel from "../../tab-panel";
import ItemContainerConfigurationDialog from "./ItemContainerConfigurationDialog";
import {ItemContainerSettingsFormContext} from "./ItemContainerSettingsFormProvider";
import {useItemContainerSettingsLabels} from "./useItemContainerSettingsLabels";
import {languageSelector, languagesSelector, modulesConfigSelector} from "../../../state/app/appSelectors";
import {hubNodesSelector} from "../../../state/hub/hubSelectors";
import {
  clearOtherConfigDashboardToShare,
  clearOtherConfigItemContainers,
  closeOtherConfigItemContainer,
  createOtherConfigItemContainer,
  deleteOtherConfigItemContainer,
  fetchOtherConfigDashboardToShare,
  fetchOtherConfigItemContainers,
  hideOtherConfigItemContainer,
  manageShareDashboardOnNodes,
  updateOtherConfigItemContainer
} from "../../../state/otherConfig/otherConfigActions";
import {otherConfigSelector} from "../../../state/otherConfig/otherConfigSelectors";
import {UserRoles} from "../../../state/user/userReducer";
import {userSelector} from "../../../state/user/userSelectors";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../utils/i18n";
import {getGenericPermissionStr} from "../../../utils/user";

interface UserItemContainerSettingsForm {
  asDashboard: boolean;
}

const TableData = ({
  asDashboard,
  isPrivate,
  userId,
  defaultLanguage,
  languages,
  data,
  goToItemContainer,
  setSelectedItemContainerId,
  setToShareItemContainerId,
  setDeleteItemContainerId
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {scenes} = useItemContainerSettingsLabels(asDashboard);
  const modulesConfigs = useSelector(modulesConfigSelector);
  const itemContainersActions = modulesConfigs.tableActionFactories["item-containers-settings"] || [];

  return (
    <MaterialTable
      rightActions={
        !asDashboard || isPrivate
          ? [
              {
                label: t(scenes.createElement),
                startIcon: <AddIcon />,
                onClick: () =>
                  dispatch(
                    createOtherConfigItemContainer({
                      type: asDashboard ? "Dashboard" : "CustomPage",
                      title: {},
                      item: [],
                      viewIds: [],
                      views: {},
                      filterLevels: {},
                      isPrivate: true,
                      userId: userId
                    })
                  )
              }
            ]
          : null
      }
      columns={[
        {
          field: "title",
          title: t(scenes.table.columns.title),
          render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
          customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
        }
      ]}
      data={data}
      actions={[
        {
          icon: <VisibilityIcon />,
          tooltip: t(scenes.table.viewElement),
          onClick: (_, {id}) => goToItemContainer(id)
        },
        ...itemContainersActions.map(getAction => getAction({asDashboard})),
        {
          icon: <EditIcon />,
          tooltip: t(scenes.table.editElement),
          onClick: (_, {id}) => {
            dispatch(updateOtherConfigItemContainer(id, asDashboard ? "dashboard" : "customPages"));
            setSelectedItemContainerId(id);
          }
        },
        asDashboard && isPrivate
          ? {
              icon: <GroupIcon />,
              tooltip: t(scenes.table.shareElement),
              onClick: (_, {id}) => {
                dispatch(fetchOtherConfigDashboardToShare(id));
                setToShareItemContainerId(id);
              }
            }
          : null,
        {
          icon: <DeleteIcon />,
          tooltip: t(scenes.table.deleteElement),
          onClick: (_, {id}) => setDeleteItemContainerId(id)
        }
      ]}
    />
  );
};

const UserItemContainersSettingsForm = ({asDashboard}: UserItemContainerSettingsForm, ref) => {
  const {scenes} = useItemContainerSettingsLabels(asDashboard);
  const {itemContainer, itemContainers, needItemContainers, dashboardToShare} = useSelector(otherConfigSelector);
  const defaultLanguage = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const nodes = useSelector(hubNodesSelector);
  const dispatch = useDispatch();
  const {setAsDashboard} = useContext(ItemContainerSettingsFormContext);

  const [deleteItemContainerId, setDeleteItemContainerId] = useState<number>(null);
  const [selectedItemContainerId, setSelectedItemContainerId] = useState<number>(null);
  const [dashboardIdToShare, setDashboardIdToShare] = useState<number>(null);
  const [dashboardSharedNodes, setDashboardSharedNodes] = useState(null);
  const user = useSelector(userSelector);
  const {t} = useTranslation();

  const [tabId, setTabId] = useState("private");

  useEffect(() => {
    setAsDashboard(asDashboard);
    dispatch(fetchOtherConfigItemContainers(asDashboard ? "dashboard" : "customPages"));
  }, [asDashboard, dispatch, setAsDashboard]);

  useEffect(() => {
    return () => {
      dispatch(hideOtherConfigItemContainer());
    };
  }, [dispatch]);

  useEffect(() => {
    if (needItemContainers) {
      dispatch(fetchOtherConfigItemContainers(asDashboard ? "dashboard" : "customPages"));
    }
  }, [asDashboard, dispatch, itemContainers, needItemContainers]);

  useEffect(() => {
    if (asDashboard && dashboardToShare && dashboardIdToShare) {
      setDashboardSharedNodes(dashboardToShare.sharedOnNodes || []);
    } else {
      setDashboardSharedNodes(null);
    }
  }, [asDashboard, dashboardToShare, dashboardIdToShare]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      dispatch(clearOtherConfigItemContainers());
      dispatch(closeOtherConfigItemContainer());
      f();
    }
  }));

  const handleItemContainerClose = () => {
    dispatch(hideOtherConfigItemContainer());
    setSelectedItemContainerId(null);
  };

  const handleShareDialogClose = () => {
    setDashboardIdToShare(null);
    dispatch(clearOtherConfigDashboardToShare());
  };

  const handleNodeCheck = (nodeId: number, checked: boolean) => {
    let currentDashboardSharedNodes = _.cloneDeep(dashboardSharedNodes || []);
    if (checked) {
      currentDashboardSharedNodes = [...currentDashboardSharedNodes, nodeId];
    } else {
      currentDashboardSharedNodes = currentDashboardSharedNodes.filter(id => id !== nodeId);
    }
    setDashboardSharedNodes(currentDashboardSharedNodes);
  };

  const submitDashboardShare = (dashboardId: number) => {
    dispatch(manageShareDashboardOnNodes(dashboardId, dashboardSharedNodes || []));
    handleShareDialogClose();
  };

  const goToItemContainer = (itemContainerId: string) => {
    if (
      !RegExp(`${`/${asDashboard ? "dashboards" : "pages"}/${itemContainerId}`}$`).test(
        window.location.href.toLowerCase()
      )
    ) {
      asDashboard ? goToDashboard(itemContainerId) : goToCustomPage(itemContainerId);
    } else {
      window.location.reload();
    }
  };

  const privateData = useMemo(() => {
    return asDashboard ? (itemContainers || []).filter(d => d.ownerId === user.userId) : null;
  }, [asDashboard, itemContainers, user]);

  const sharedData = useMemo(() => {
    return asDashboard ? (itemContainers || []).filter(d => d.ownerId !== user.userId) : null;
  }, [asDashboard, itemContainers, user]);

  const availableNodes = useMemo(() => {
    return asDashboard
      ? user.roles.includes(UserRoles.Administrator)
        ? nodes
        : (nodes || []).filter(node =>
            user.permissions.includes(getGenericPermissionStr(NodeDashboardSharePermission, node.nodeId))
          )
      : null;
  }, [asDashboard, user, nodes]);

  return (
    itemContainers && (
      <>
        {asDashboard ? (
          <>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={tabId}
              onChange={(_, id) => {
                setTabId(id);
              }}
              sx={{
                heigth: "49px"
              }}
            >
              <Tab value="private" label={t("scenes.dashboardsSettings.tabs.private.label")} />
              <Tab value="shared" label={t("scenes.dashboardsSettings.tabs.shared.label")} />
            </Tabs>
            <TabPanel value="private" selected={tabId} sx={{height: "calc(100% - 49px)", paddingTop: "8px"}}>
              <TableData
                asDashboard
                isPrivate={true}
                userId={user.userId}
                defaultLanguage={defaultLanguage}
                languages={languages}
                data={privateData}
                goToItemContainer={goToItemContainer}
                setSelectedItemContainerId={setSelectedItemContainerId}
                setToShareItemContainerId={setDashboardIdToShare}
                setDeleteItemContainerId={setDeleteItemContainerId}
              />
            </TabPanel>
            <TabPanel value="shared" selected={tabId} sx={{height: "calc(100% - 49px)", paddingTop: "8px"}}>
              <TableData
                asDashboard
                isPrivate={false}
                userId={user.userId}
                defaultLanguage={defaultLanguage}
                languages={languages}
                data={sharedData}
                goToItemContainer={goToItemContainer}
                setSelectedItemContainerId={setSelectedItemContainerId}
                setToShareItemContainerId={setDashboardIdToShare}
                setDeleteItemContainerId={setDeleteItemContainerId}
              />
            </TabPanel>
          </>
        ) : (
          <TableData
            asDashboard={false}
            isPrivate={false}
            userId={user.userId}
            defaultLanguage={defaultLanguage}
            languages={languages}
            data={itemContainers}
            goToItemContainer={goToItemContainer}
            setSelectedItemContainerId={setSelectedItemContainerId}
            setDeleteItemContainerId={setDeleteItemContainerId}
            setToShareItemContainerId={setDashboardIdToShare}
          />
        )}

        <ConfirmationDialog
          open={deleteItemContainerId !== null}
          onClose={() => setDeleteItemContainerId(null)}
          onConfirm={() => {
            dispatch(deleteOtherConfigItemContainer(deleteItemContainerId, asDashboard ? "dashboard" : "customPages"));
            setDeleteItemContainerId(null);
          }}
          title={t(scenes.deleteElement.title)}
          content={t(scenes.deleteElement.content)}
        />

        {itemContainer && (
          <ItemContainerConfigurationDialog
            onClose={handleItemContainerClose}
            itemContainerId={selectedItemContainerId}
          />
        )}

        <Dialog maxWidth="md" fullWidth open={dashboardIdToShare !== null} onClose={handleShareDialogClose}>
          <CustomDialogTitle onClose={handleShareDialogClose}>
            {t("scenes.dashboardsSettings.modals.share.title")}
          </CustomDialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid key={-1} item xs={12}>
                {t("scenes.dashboardsSettings.modals.share.content.title")}
              </Grid>
              {(availableNodes ?? []).map(({nodeId, name}, idx) => (
                <Grid key={idx} item xs={12}>
                  <FormControlLabel
                    label={name}
                    control={
                      <Checkbox
                        checked={(dashboardSharedNodes || []).includes(nodeId)}
                        onChange={(_, checked) => {
                          handleNodeCheck(nodeId, checked);
                        }}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleShareDialogClose}>{t("commons.confirm.cancel")}</Button>
            <Button onClick={() => submitDashboardShare(dashboardIdToShare)}>{t("commons.confirm.confirm")}</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default forwardRef(UserItemContainersSettingsForm);
