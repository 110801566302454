import {PayloadAction} from "@reduxjs/toolkit";
import {Reducer} from "redux";
import {IHubNode} from "../../model/IHubNode";
import {NODE_CLEAR, NODE_FETCH, NODE_FETCH_ITEM_CONTAINERS} from "./nodeActions";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {
  TIME_PERIOD_FORMAT_DAY_KEY,
  TIME_PERIOD_FORMAT_MONTH_KEY,
  TIME_PERIOD_FORMAT_QUARTER_KEY,
  TIME_PERIOD_FORMAT_SEMESTER_KEY,
  TIME_PERIOD_FORMAT_YEAR_KEY
} from "../../utils/dataset";

export type NodeState = IHubNode | null;

const handleSessionStorage = (config: NodeState, request: string) => {
  if (request === NODE_CLEAR) {
    sessionStorage.removeItem(TIME_PERIOD_FORMAT_YEAR_KEY);
    sessionStorage.removeItem(TIME_PERIOD_FORMAT_MONTH_KEY);
    sessionStorage.removeItem(TIME_PERIOD_FORMAT_DAY_KEY);
    sessionStorage.removeItem(TIME_PERIOD_FORMAT_QUARTER_KEY);
    sessionStorage.removeItem(TIME_PERIOD_FORMAT_SEMESTER_KEY);
  }

  if (request === REQUEST_INIT || request === REQUEST_SUCCESS || request === REQUEST_ERROR) {
    const y = config?.extras?.find(({key}) => key === "TimePeriodFormatYear");
    if (y) sessionStorage.setItem(TIME_PERIOD_FORMAT_YEAR_KEY, y.value);

    const m = config?.extras?.find(({key}) => key === "TimePeriodFormatMonth");
    if (m) sessionStorage.setItem(TIME_PERIOD_FORMAT_MONTH_KEY, m.value);

    const d = config?.extras?.find(({key}) => key === "TimePeriodFormatDay");
    if (d) sessionStorage.setItem(TIME_PERIOD_FORMAT_DAY_KEY, d.value);

    const q = config?.extras?.find(({key}) => key === "TimePeriodFormatQuarter");
    if (q) sessionStorage.setItem(TIME_PERIOD_FORMAT_QUARTER_KEY, q.value);

    const s = config?.extras?.find(({key}) => key === "TimePeriodFormatSemester");
    if (s) sessionStorage.setItem(TIME_PERIOD_FORMAT_SEMESTER_KEY, s.value);
  }
};

const nodeReducer: Reducer<NodeState, PayloadAction<any>> = (state = null, action) => {
  switch (action.type) {
    case NODE_CLEAR: {
      handleSessionStorage(null, NODE_CLEAR);
      return null;
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          handleSessionStorage(null, NODE_CLEAR);
          return null;
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          handleSessionStorage(action.payload.response, REQUEST_SUCCESS);
          return {...action.payload.response};
        }
        case NODE_FETCH_ITEM_CONTAINERS: {
          return !!state
            ? {
                ...state,
                itemContainers: {
                  ...(state.itemContainers || {}),
                  [action.payload.extra.type]: action.payload.response
                }
              }
            : state;
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          handleSessionStorage(state, REQUEST_ERROR);
          return state;
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default nodeReducer;
