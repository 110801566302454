import React, {Fragment, useEffect, useState} from "react";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import InfiniteScrollTable from "../infinite-scroll-table";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH} from "../label-format-selector/constants";
import MaterialTree, {DEFAULT_SELECT_MODE, SINGLE_SELECT_MODE} from "../material-tree";
import {useForceUpdate} from "../../utils/customHooks";
import {getFormattedDimensionValueLabel, MARGINAL_DIMENSION_KEY} from "../../utils/dataset";
import {getTextWidth} from "../../utils/style";
import {isMultiSelectDim} from "./utils";

const $ = window.jQuery;

const alertStyle = {marginBottom: 8};

const DatasetFilters = ({
  datasetId: externalDatasetId,
  jsonStat,
  timeDim,
  layout,
  labelFormat,
  getIsDimensionAllowed,
  getDimensionLabel,
  getFilterValues,
  getTreeFilterValues,
  getDimensionIsHierarchical,
  getFormattedName,
  getFormattedTooltip,
  dimAttributeMap,
  onSelect,
  onCriteriaShow,
  getIsDatasetExceedingLimit,
  enclosingContainerId,
  localizedTimePeriodFormatMapExternal
}) => {
  const {t} = useTranslation();

  const forceUpdate = useForceUpdate();

  const [visibleId, setVisibleId] = useState(null);
  const [data, setData] = useState(null);
  const [checkedKeys, setCheckedKeys] = useState(null);

  useEffect(() => {
    return () => {
      $("#dataset-filters__text-width-el").remove();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    return () => window.removeEventListener("resize", forceUpdate);
  }, [forceUpdate]);

  const filters = (layout?.primaryDim || []).concat(layout?.secondaryDim || []).concat(layout?.filters || []);

  const handleFilterOpen = visibleIdx => {
    setVisibleId(visibleIdx);
    const dim = filters[visibleIdx];
    const dimIsHierarchical = getDimensionIsHierarchical(dim);

    if (dimIsHierarchical) {
      const data = [...getTreeFilterValues(dim)];
      setData(data);
    } else {
      const data = [...getFilterValues(dim)];
      if (dim === timeDim) {
        data.reverse();
      }
      setData(data.map(val => ({id: val})));
    }

    if (isMultiSelectDim(dim, layout)) {
      if (dim === layout?.primaryDim?.[0]) {
        setCheckedKeys(layout.primaryDimValues);
      } else if (dim === layout?.secondaryDim?.[0]) {
        setCheckedKeys(layout.secondaryDimValues);
      }
    } else {
      setCheckedKeys([layout.filtersValue[dim]]);
    }
  };

  const handleFilterClose = () => {
    setVisibleId(null);
    setCheckedKeys(null);
    setData(null);
  };

  const handleFilterSubmit = (dimension, values) => {
    const submitValues = isMultiSelectDim(dimension, layout)
      ? values
      : getDimensionIsHierarchical(filters[visibleId])
        ? values[0]
        : values;
    onSelect(dimension, submitValues);
    handleFilterClose();
  };

  return (
    <Fragment>
      <Box sx={{maxWidth: "100%"}}>
        {filters.map((dim, idx) => {
          const isDimensionAllowed = getIsDimensionAllowed(dim);
          if (!isDimensionAllowed) {
            return null;
          } else {
            const {filtersValue} = layout;

            if ($("#dataset-filters__text-width-el").length === 0) {
              $("<span id='dataset-filters__text-width-el'>")
                .css({
                  visibility: "hidden",
                  fontSize: 16
                })
                .appendTo("body");
            }

            const dimLabel = getDimensionLabel(dim);
            const minWidth = getTextWidth(dimLabel, $("#dataset-filters__text-width-el").get(0)) + 1;
            let selectStyle = {minWidth: minWidth};

            if (enclosingContainerId) {
              const $containerElement = $(enclosingContainerId);
              if ($containerElement.length > 0) {
                const containerWidth = $containerElement.width() || 0;
                if (containerWidth > 0) {
                  selectStyle.maxWidth = containerWidth;
                }
              }
            }

            const value = isMultiSelectDim(dim, layout)
              ? layout.primaryDim[0] === dim
                ? layout.primaryDimValues
                : layout.secondaryDimValues
              : filtersValue[dim];

            const datasetId = externalDatasetId
              ? externalDatasetId
              : !isMultiSelectDim(dim, layout) &&
                  dim === MARGINAL_DIMENSION_KEY &&
                  jsonStat?.extension?.marginalvalues?.[value]?.datasetid
                ? jsonStat.extension.marginalvalues[value].datasetid
                : jsonStat?.extension?.datasets?.[0];

            const values = getFilterValues(dim);

            const getValueLabel = value =>
              isMultiSelectDim(dim, layout)
                ? values.length !== 1
                  ? t("components.datasetFilters.selectedCount", {count: value.length})
                  : getFormattedName(dim, value[0], labelFormat)
                : getFormattedName(dim, value, labelFormat);

            return (
              <Box
                key={idx}
                sx={{
                  display: "inline-block",
                  verticalAlign: "bottom",
                  marginRight: "16px",
                  marginBottom: "8px",
                  maxWidth: "100%"
                }}
              >
                {isMultiSelectDim(dim, layout) && (
                  <InputLabel
                    sx={{
                      marginBottom: "4px",
                      fontSize: "13px",
                      color: theme => theme.palette.primary.main
                    }}
                  >
                    {layout.primaryDim[0] === dim
                      ? t("components.datasetFilters.primaryDim")
                      : t("components.datasetFilters.secondaryDim")}
                  </InputLabel>
                )}
                <FormControl
                  sx={{
                    display: "inline-block",
                    verticalAlign: "bottom",
                    maxWidth: "100%"
                  }}
                >
                  <InputLabel>{dimLabel}</InputLabel>
                  <Tooltip title={getValueLabel(value)}>
                    <Select
                      open={false}
                      style={selectStyle}
                      value={value}
                      multiple={isMultiSelectDim(dim, layout)}
                      renderValue={value => getValueLabel(value)}
                      sx={{
                        fontSize: "14px",
                        maxWidth: "100%"
                      }}
                      onOpen={() => handleFilterOpen(idx)}
                      SelectDisplayProps={{
                        "aria-haspopup": true,
                        "aria-label": `${dimLabel}: ${getValueLabel(value)}`
                      }}
                    >
                      {values.map((el, idx) => (
                        <MenuItem key={idx + el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                </FormControl>
                {jsonStat && (
                  <div style={{display: "inline-block", marginBottom: 4}}>
                    <DatasetFilterAttributeIcon
                      dimension={dim}
                      dimensionValues={isMultiSelectDim(dim, layout) ? value : [value]}
                      datasetDimAttributeMap={dimAttributeMap?.[datasetId]}
                      labelFormat={labelFormat}
                      getDimValueLabel={dimVal =>
                        getFormattedDimensionValueLabel(
                          jsonStat,
                          datasetId,
                          dim,
                          dimVal,
                          labelFormat,
                          undefined,
                          localizedTimePeriodFormatMapExternal
                        )
                      }
                    />
                  </div>
                )}
              </Box>
            );
          }
        })}
      </Box>
      <Dialog open={data !== null} onClose={handleFilterClose} fullWidth maxWidth="md">
        <CustomDialogTitle onClose={handleFilterClose}>
          {visibleId !== null
            ? !isMultiSelectDim(filters[visibleId], layout)
              ? t("components.datasetFilters.modals.singleSelect", {
                  dimension: getDimensionLabel(filters[visibleId])
                })
              : t("components.datasetFilters.modals.multiSelect", {
                  dimension: getDimensionLabel(filters[visibleId])
                })
            : ""}
        </CustomDialogTitle>
        <DialogContent>
          {getIsDatasetExceedingLimit && getIsDatasetExceedingLimit(filters[visibleId], checkedKeys) && (
            <Alert severity="warning" sx={alertStyle}>
              {t("components.datasetFilters.alerts.limitExceeded")}
            </Alert>
          )}
          {onCriteriaShow && (
            <Alert severity="info" sx={alertStyle}>
              {t("components.datasetFilters.alerts.openCriteria.prefixLabel")}
              <Box
                component="span"
                sx={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={() => {
                  handleFilterClose();
                  onCriteriaShow(filters[visibleId]);
                }}
              >
                {t("components.datasetFilters.alerts.openCriteria.clickableLabel")}
              </Box>
              {t("components.datasetFilters.alerts.openCriteria.suffixLabel")}
            </Alert>
          )}
          {getDimensionIsHierarchical(filters[visibleId]) ? (
            <MaterialTree
              treeData={data}
              idKey={"id"}
              childrenKey={"children"}
              labelKey={"label"}
              selectableKey={"isSelectable"}
              selectMode={isMultiSelectDim(filters[visibleId], layout) ? DEFAULT_SELECT_MODE : SINGLE_SELECT_MODE}
              defaultSelectedNodeIds={checkedKeys}
              onNodeSelect={setCheckedKeys}
              defaultExpandedNodeIds={checkedKeys}
              height={400}
            />
          ) : !isMultiSelectDim(filters[visibleId], layout) ? (
            <InfiniteScrollTable
              data={data}
              getRowKey={({id}) => id}
              showHeader={false}
              columns={[
                {
                  title: "",
                  dataIndex: "id",
                  render: (_, {id}) =>
                    getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH, true),
                  renderText: (_, {id}) =>
                    getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                  renderTooltip: (_, {id}) =>
                    getFormattedTooltip(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                  minWidth: 100
                }
              ]}
              onRowClick={rowData => handleFilterSubmit(filters[visibleId], rowData.id)}
              getRowStyle={rowData => ({
                background: rowData.id === layout.filtersValue[filters[visibleId]] ? "#fff9e5" : undefined
              })}
              height={400}
            />
          ) : (
            <InfiniteScrollTable
              data={data}
              getRowKey={({id}) => id}
              columns={[
                {
                  title: t("components.datasetFilters.table.columns.label.title"),
                  dataIndex: "id",
                  render: (_, {id}) =>
                    getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH, true),
                  renderText: (_, {id}) =>
                    getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                  renderTooltip: (_, {id}) =>
                    getFormattedTooltip(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                  minWidth: 100,
                  noFilter: true,
                  noSort: true
                }
              ]}
              rowSelection={{
                selectedRowKeys: checkedKeys,
                onChange: checkedKeys => setCheckedKeys(data.map(({id}) => id).filter(key => checkedKeys.includes(key)))
              }}
              height={400}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFilterClose} color="primary">
            {t("commons.confirm.close")}
          </Button>
          {(isMultiSelectDim(filters[visibleId], layout) || getDimensionIsHierarchical(filters[visibleId])) && (
            <Button
              onClick={() => handleFilterSubmit(filters[visibleId], checkedKeys)}
              color="primary"
              disabled={
                (checkedKeys || []).length < 1 ||
                (getIsDatasetExceedingLimit && getIsDatasetExceedingLimit(filters[visibleId], checkedKeys))
              }
            >
              {t("commons.confirm.confirm")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DatasetFilters;
