import React, {useEffect} from "react";
import MomentUtils from "@date-io/moment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pt";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

const getLocale = (lang = "en") => {
  const map = {
    en: "en",
    it: "it",
    fr: "fr",
    es: "es",
    pt: "pt",
    de: "de"
  };

  return map[lang] || map.en;
};

const getFormat = (lang = "en") => {
  const map = {
    en: "YYYY/MM/DD",
    it: "DD/MM/YYYY"
  };

  return map[lang] || map.en;
};

const CustomDatePicker = ({
  appLang,

  label,
  value,
  minDate,
  maxDate,
  required,
  error,
  helperText,
  onChange,

  openTo = "day",
  views = ["year", "month", "day"],
  format = getFormat(appLang),
  inputVariant = undefined,
  sx = undefined
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    moment.locale(appLang);
  }, [appLang]);

  return (
    <LocalizationProvider utils={MomentUtils} adapterLocale={getLocale(appLang)} dateAdapter={AdapterMoment}>
      <DatePicker
        slotProps={{
          textField: {
            helperText: helperText,
            label: label,
            variant: inputVariant,
            sx: sx,
            error: error || false
          }
        }}
        value={moment(value)}
        minDate={minDate}
        maxDate={maxDate}
        required={required}
        error={error}
        onChange={onChange}
        openTo={openTo}
        views={views}
        format={format}
        sx={sx}
        clearable
        cancelLabel={t("components.customDatePicker.cancelLabel")}
        clearLabel={t("components.customDatePicker.clearLabel")}
        okLabel={t("components.customDatePicker.okLabel")}
        todayLabel={t("components.customDatePicker.todayLabel")}
      />
    </LocalizationProvider>
  );
};

export default connect(state => ({
  appLang: state.app.language
}))(CustomDatePicker);
