import _ from "lodash";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../../label-format-selector/constants";
import {
  getCoordinatesArrayFromDataIdx,
  getDataIdxFromCoordinatesArray,
  getDimensionValuesIndexesMap,
  getFormattedDimensionLabel,
  getFormattedDimensionValueLabel,
  getMarginalDimensions,
  getObservationAttributeMap,
  MARGINAL_ATTRIBUTE_KEY,
  MARGINAL_DIMENSION_KEY,
  VARIATION_DIMENSION_KEY,
  VARIATION_VALUE_CYCLICAL_KEY,
  VARIATION_VALUE_TREND_KEY,
  VARIATION_VALUE_VALUE_KEY
} from "../../../utils/dataset";
import {getFormattedValue} from "../../../utils/formatters";
import {getCombinationArrays} from "../../../utils/other";
import {getMappedTree} from "../../../utils/tree";
import {isNumeric} from "../../../utils/validator";

export const TABLE_HEADER_NORMAL = "TABLE_HEADER_NORMAL";
export const TABLE_HEADER_MERGED = "TABLE_HEADER_MERGED";

export const FILTER_TYPE_OBS = "FILTER_TYPE_OBS";
export const FILTER_TYPE_DIM = "FILTER_TYPE_DIM";

export const OBS_FILTER_OPERATOR_AND = "OBS_FILTER_OPERATOR_AND";
export const OBS_FILTER_OPERATOR_OR = "OBS_FILTER_OPERATOR_OR";
export const OBS_FILTER_OPERATOR_EQUAL = "OBS_FILTER_OPERATOR_EQUAL";
export const OBS_FILTER_OPERATOR_NOT_EQUAL = "OBS_FILTER_OPERATOR_NOT_EQUAL";
export const OBS_FILTER_OPERATOR_GREATER_OR_EQUAL = "OBS_FILTER_OPERATOR_GREATER_OR_EQUAL";
export const OBS_FILTER_OPERATOR_GREATER = "OBS_FILTER_OPERATOR_GREATER";
export const OBS_FILTER_OPERATOR_LESS_OR_EQUAL = "OBS_FILTER_OPERATOR_LESS_OR_EQUAL";
export const OBS_FILTER_OPERATOR_LESS = "OBS_FILTER_OPERATOR_LESS";

export const DIM_FILTER_OPERATOR_EQUAL = "DIM_FILTER_OPERATOR_EQUAL";
export const DIM_FILTER_OPERATOR_NOT_EQUAL = "DIM_FILTER_OPERATOR_NOT_EQUAL";
export const DIM_FILTER_OPERATOR_STARTS_WITH = "DIM_FILTER_OPERATOR_STARTS_WITH";
export const DIM_FILTER_OPERATOR_INCLUDES = "DIM_FILTER_OPERATOR_INCLUDES";
export const DIM_FILTER_OPERATOR_NOT_INCLUDES = "DIM_FILTER_OPERATOR_NOT_INCLUDES";
export const DIM_FILTER_OPERATOR_ENDS_WITH = "DIM_FILTER_OPERATOR_ENDS_WITH";

export const DIM_VALUE_LABEL_MODIFIER_REMOVE = "replace";
export const DIM_VALUE_LABEL_MODIFIER_APPEND = "append";
export const DIM_VALUE_LABEL_MODIFIER_PREPEND = "prepend";

const willValuePassObsFilters = (value, filter, decimalSeparator, roundingStrategy, decimalPlaces) => {
  if (!isNumeric(value)) {
    return false;
  }

  const computeSingleEntity = (value, entity) => {
    if (!isNumeric(entity.filterValue)) {
      return false;
    }

    const formattedValue = getFormattedValue(value, decimalSeparator, decimalPlaces, "", roundingStrategy);
    const formattedFilterValue = getFormattedValue(
      entity.filterValue,
      decimalSeparator,
      decimalPlaces,
      "",
      roundingStrategy
    );

    if ((formattedValue || "").length === 0 || (formattedFilterValue || "").length === 0) {
      return false;
    }

    const thousandsSeparator = decimalSeparator === "." ? "," : ".";

    const numericFormattedValue = Number(
      formattedValue.replaceAll(thousandsSeparator, "").replace(decimalSeparator, ".")
    );
    const numericFormattedFilterValue = Number(
      formattedFilterValue.replaceAll(thousandsSeparator, "").replace(decimalSeparator, ".")
    );

    switch (entity.operator) {
      case OBS_FILTER_OPERATOR_EQUAL: {
        return numericFormattedValue === numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_NOT_EQUAL: {
        return numericFormattedValue !== numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_GREATER_OR_EQUAL: {
        return numericFormattedValue >= numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_GREATER: {
        return numericFormattedValue > numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_LESS_OR_EQUAL: {
        return numericFormattedValue <= numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_LESS: {
        return numericFormattedValue < numericFormattedFilterValue;
      }
      default: {
        return false;
      }
    }
  };

  const isEntity1Valorized = (filter.entity1.filterValue || "").length > 0;
  const isEntity2Valorized = (filter.entity2.filterValue || "").length > 0;

  const isEntity1Passed = isEntity1Valorized && computeSingleEntity(value, filter.entity1);
  const isEntity2Passed = isEntity2Valorized && computeSingleEntity(value, filter.entity2);

  if (isEntity1Valorized && isEntity2Valorized) {
    if (filter.operator === OBS_FILTER_OPERATOR_AND) {
      return isEntity1Passed && isEntity2Passed;
    } else {
      return isEntity1Passed || isEntity2Passed;
    }
  } else if (isEntity1Valorized) {
    return isEntity1Passed;
  } else if (isEntity2Valorized) {
    return isEntity2Passed;
  } else {
    return false;
  }
};

const willValuePassDimFilters = (value, filter) => {
  const operator = filter.operator;
  const filterValue = (filter.filterValue || "").toLowerCase();

  if ((value || "").length === 0) {
    return false;
  }

  switch (operator) {
    case DIM_FILTER_OPERATOR_EQUAL: {
      return value.toLowerCase() === filterValue;
    }
    case DIM_FILTER_OPERATOR_NOT_EQUAL: {
      return value.toLowerCase() !== filterValue;
    }
    case DIM_FILTER_OPERATOR_STARTS_WITH: {
      return value.toLowerCase().startsWith(filterValue);
    }
    case DIM_FILTER_OPERATOR_INCLUDES: {
      return value.toLowerCase().includes(filterValue);
    }
    case DIM_FILTER_OPERATOR_NOT_INCLUDES: {
      return !value.toLowerCase().includes(filterValue);
    }
    case DIM_FILTER_OPERATOR_ENDS_WITH: {
      return value.toLowerCase().endsWith(filterValue);
    }
    default: {
      return false;
    }
  }
};

const TABLE_PREVIEW_PLACEHOLDER = "xxx";
const TABLE_SECTION_DIMENSIONS_SEPARATOR_ICON =
  '<svg viewBox="0 0 24 24" height="20px" width="20px" fill="currentColor"><path d="M 8 8 H 16 V 16 H 8 Z"/></svg>';
const TABLE_SECTION_DIMENSIONS_SEPARATOR = `<span style="display: inline-block; vertical-align: middle; margin: 0 2px; height: 20px;">${TABLE_SECTION_DIMENSIONS_SEPARATOR_ICON}</span>`;

const upIcon =
  '<svg viewBox="0 0 24 24" height="20px" width="20px" fill="currentColor" style="transform: scale(1.4);"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 14l5-5 5 5z"/></svg>';
const downIcon =
  '<svg viewBox="0 0 24 24" height="20px" width="20px" fill="currentColor" style="transform: scale(1.4);"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>';
const filterIcon =
  '<svg viewBox="0 0 24 24" height="20px" width="20px" fill="currentColor" style="transform: scale(0.9);"><g><path d="M0,0h24 M24,24H0" fill="none"/><path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/><path d="M0,0h24v24H0V0z" fill="none"/></g></svg>';
const settingsIcon =
  "<svg " +
  'viewBox="0 0 24 24" ' +
  'height="20px" ' +
  'width="20px" ' +
  'fill="currentColor" ' +
  'style="transform: scale(0.9);" ' +
  ">" +
  "<g>" +
  '<path d="M0,0h24v24H0V0z" fill="none"/>' +
  '<path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/>' +
  "</g>" +
  "</svg>";
const closeIcon =
  "<svg " +
  'viewBox="0 0 24 24" ' +
  'height="20px" ' +
  'width="20px" ' +
  'fill="currentColor" ' +
  ">" +
  '<path d="M0 0h24v24H0z" fill="none"/>' +
  '<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>' +
  "</svg>";

const TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT = 2;

const getDimSpanMap = (jsonStat, arr, length) => {
  const obj = {};
  arr.forEach((el, idx) => {
    obj[el] =
      idx === 0
        ? length / jsonStat.size[jsonStat.id.indexOf(el)]
        : obj[arr[idx - 1]] / jsonStat.size[jsonStat.id.indexOf(el)];
  });

  return obj;
};

const getDimValuesMap = (jsonStat, repCount, arr, length, dimsToInvert) => {
  const obj = {};

  arr.forEach(dim => {
    if (repCount[dim]) {
      obj[dim] = [];
      const dimValues = !dimsToInvert.includes(dim)
        ? jsonStat.dimension[dim].category.index
        : jsonStat.dimension[dim].category.index.slice().reverse();

      obj[dim] = [...dimValues];
    }
  });

  return obj;
};

const getFirstIndex = (objArr, n) => {
  const sectionsLength = (objArr ?? []).map(obj => Object.keys(obj ?? {}).length);
  let arr = [];
  (objArr ?? []).forEach((obj, secIdx) => {
    const prevSectionLength = sectionsLength.slice(0, secIdx).reduce((acc, l) => acc + l, 0);
    Object.keys(obj ?? {}).forEach(rowIdx => {
      arr.push(prevSectionLength + Number(rowIdx));
    });
  });
  return arr[n];
};

const getJsonStatLayoutObjects = (jsonStat, rows, cols, sections, dimsToInvert, isPreview) => {
  /** rows handling **/
  let sectionRowCountFull = 1;
  (rows || []).forEach(row => (sectionRowCountFull *= jsonStat.size[jsonStat.id.indexOf(row)]));

  /** cols handling **/
  let colCountFull = 1;
  (cols || []).forEach(col => (colCountFull *= jsonStat.size[jsonStat.id.indexOf(col)]));

  /** sections handling **/
  const sectionArray = [];
  (sections || []).forEach(section => {
    const array = !dimsToInvert.includes(section)
      ? jsonStat.dimension[section].category.index
      : jsonStat.dimension[section].category.index.slice().reverse();
    sectionArray.push(array);
  });
  const sectionDimCombinations = sectionArray.length > 0 ? getCombinationArrays(sectionArray) : [];

  const indexesMap = getDimensionValuesIndexesMap(jsonStat);

  const dimSpanMap = {
    ...getDimSpanMap(jsonStat, rows, sectionRowCountFull),
    ...getDimSpanMap(jsonStat, cols, colCountFull)
  };

  let dimValuesMap = null;
  if (!isPreview) {
    dimValuesMap = {
      ...getDimValuesMap(jsonStat, dimSpanMap, rows, sectionRowCountFull, dimsToInvert),
      ...getDimValuesMap(jsonStat, dimSpanMap, cols, colCountFull, dimsToInvert)
    };
  }

  return {
    sectionRowCountFull,
    colCountFull,
    sectionDimCombinations,
    indexesMap,
    dimSpanMap,
    dimValuesMap
  };
};

export const getCellAttributeIdsElem = (ids, htmlElemId, isDataCell) => {
  if (!ids || ids.length === 0) {
    return "";
  }

  return `<span id="${htmlElemId}" class="ct ${isDataCell ? "ctd" : "ctsh"}">(*)</span>`;
};

export const getDimensionValueFromIdx = (dim, idx, dimValuesMap, dimSpanMap) =>
  dimValuesMap[dim][Math.floor(idx / dimSpanMap[dim]) % dimValuesMap[dim].length];

export const getTableSupportStructures = (
  jsonStat,
  layout,
  isPreview,
  removeEmptyLines,
  showTrend,
  showCyclical,
  externalDimensionFilterValues,
  filterable,
  filters,
  onFilterComplete,
  labelFormat,
  customLabelFormats,
  splitRowDimensions,
  dimensionValueModifiers,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  invertedDims,
  hierarchyOnlyAttributes,
  hideHierarchyOnlyRows,
  enableMeasuresOfSynthesisAndVariability,
  localizedTimePeriodFormatMap
) => {
  if (isPreview) {
    return null;
  }

  const {rows, cols, filters: layoutFilters, filtersValue, sections} = layout;

  const dimsToInvert = (invertedDims || []).filter(dimensionId =>
    [...rows, ...cols, ...sections].includes(dimensionId)
  );

  const {sectionRowCountFull, colCountFull, sectionDimCombinations, indexesMap, dimSpanMap, dimValuesMap} =
    getJsonStatLayoutObjects(jsonStat, rows, cols, sections, dimsToInvert, isPreview);

  const sectionIdxs = sectionDimCombinations.length > 0 ? sectionDimCombinations.map((_, idx) => idx) : [0];

  const valueMatrix = {};

  const valorizedSectionRows = sectionIdxs.map(() => ({}));
  const valorizedCols = {};

  /** dimension attributes handling **/

  // commented because it is not possible to pass the t function to a worker
  // const dimAttributeMap = getDimensionAttributeMap(jsonStat, str => str, (ids, dim, dimValue) => getCellAttributeIdsElem(ids, `${dim},${dimValue}`, false));

  /** observation attributes handling **/

  const obsAttributeMap = getObservationAttributeMap(jsonStat, (ids, obsIdx) =>
    getCellAttributeIdsElem(ids, obsIdx, true)
  );

  /** empty rows & cols handling **/

  if (!removeEmptyLines) {
    valorizedSectionRows.forEach(valorizedRows => {
      for (let i = 0; i < sectionRowCountFull; i++) {
        valorizedRows[i] = 1;
      }
    });
    for (let i = 0; i < colCountFull; i++) {
      valorizedCols[i] = 1;
    }
  }

  const dimensionFilterValues = {};

  /** dimension filter from layout **/
  layoutFilters.forEach(dim => {
    dimensionFilterValues[dim] = {};
    dimensionFilterValues[dim][filtersValue[dim]] = 1;
  });

  /** dimension filter from variation selector **/
  if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
    dimensionFilterValues[VARIATION_DIMENSION_KEY] = {};
    dimensionFilterValues[VARIATION_DIMENSION_KEY][VARIATION_VALUE_VALUE_KEY] = 1;
    if (showTrend) {
      dimensionFilterValues[VARIATION_DIMENSION_KEY][VARIATION_VALUE_TREND_KEY] = 1;
    }
    if (showCyclical) {
      dimensionFilterValues[VARIATION_DIMENSION_KEY][VARIATION_VALUE_CYCLICAL_KEY] = 1;
    }
  }

  /** dimension filter from external filters **/
  Object.keys(externalDimensionFilterValues || {}).forEach(dim => {
    if ((externalDimensionFilterValues[dim] || []).length > 0) {
      dimensionFilterValues[dim] = externalDimensionFilterValues[dim].reduce(
        (o, key) => Object.assign(o, {[key]: 1}),
        {}
      );
    }
  });

  /** observation handling **/
  Object.keys(jsonStat.value).forEach(key => {
    const obsAttributes = obsAttributeMap[key] || [];
    const obsValue = jsonStat.value[key];

    const isHierarchicalOnlyObs =
      (obsValue === null || obsValue === "") &&
      obsAttributes.length === 1 &&
      (hierarchyOnlyAttributes || []).includes(`${obsAttributes[0].id}+${obsAttributes[0].valueId}`) &&
      (hideHierarchyOnlyRows ||
        !rows.find(row => Object.keys(jsonStat.dimension[row].category?.child || {}).length > 0)); // there are no hierarchical dimensions in rows

    if (obsValue !== undefined && !isHierarchicalOnlyObs) {
      let coordinates = getCoordinatesArrayFromDataIdx(key, jsonStat.size);

      dimsToInvert.forEach(dim => {
        const dimIdx = jsonStat.id.indexOf(dim);
        coordinates[dimIdx] = jsonStat.size[dimIdx] - 1 - coordinates[dimIdx];
      });

      const obsDimValues = {};
      jsonStat.id.forEach((dim, dimIdx) => {
        obsDimValues[dim] = jsonStat.dimension[dim].category.index[coordinates[dimIdx]];
      });

      const isFiltered =
        jsonStat.id.find(dim => dimensionFilterValues[dim] && !dimensionFilterValues[dim][obsDimValues[dim]]) !==
        undefined;

      if (!isFiltered) {
        let secIdx = 0;
        if (sections.length > 0) {
          const sectionValueArray = new Array(sections.length);
          coordinates.forEach((val, idx) => {
            const dim = jsonStat.id[idx];
            if (sections.includes(dim)) {
              sectionValueArray[sections.indexOf(dim)] = jsonStat.dimension[dim].category.index[val];
            }
          });
          secIdx = sectionDimCombinations.findIndex(
            combination => combination.join("+") === sectionValueArray.join("+")
          );
        }

        let colIdx = 0;
        cols.forEach(col => {
          const val = coordinates[jsonStat.id.indexOf(col)];
          colIdx += val * dimSpanMap[col];
        });

        let rowIdx = 0;
        rows.forEach(row => {
          const val = coordinates[jsonStat.id.indexOf(row)];
          rowIdx += val * dimSpanMap[row];
        });

        valorizedSectionRows[secIdx][rowIdx] = 1;

        valorizedCols[colIdx] = 1;

        if (!valueMatrix[secIdx]) {
          valueMatrix[secIdx] = {};
        }
        if (!valueMatrix[secIdx][colIdx]) {
          valueMatrix[secIdx][colIdx] = {};
        }
        valueMatrix[secIdx][colIdx][rowIdx] = jsonStat.value[key];
      }
    }
  });

  /** table filters handling **/

  let filteredRows = null;
  if (filterable && !_.isEmpty(filters)) {
    const dimTableFilters = Object.fromEntries(
      Object.entries(filters || {}).filter(([key, val]) => val.type === FILTER_TYPE_DIM)
    );
    const obsTableFilters = Object.fromEntries(
      Object.entries(filters || {}).filter(([key, val]) => val.type === FILTER_TYPE_OBS)
    );

    filteredRows = {};

    valorizedSectionRows.forEach((valorizedRows, s) => {
      for (let r = 0; r < sectionRowCountFull; r++) {
        if (valorizedRows[r] === 1) {
          let passFilter = true;

          /** observation filters **/
          Object.keys(obsTableFilters).forEach(c => {
            const value = valueMatrix[s][c][r];

            passFilter =
              passFilter &&
              willValuePassObsFilters(value, filters[c], decimalSeparator, roundingStrategy, decimalPlaces);
          });

          /** dimension filters **/
          Object.keys(dimTableFilters).forEach(dim => {
            const dimValue = getDimensionValueFromIdx(dim, r, dimValuesMap, dimSpanMap);
            if (splitRowDimensions) {
              let dimValueId = getFormattedDimensionValueLabel(
                jsonStat,
                null,
                dim,
                dimValue,
                LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
                null,
                localizedTimePeriodFormatMap
              );
              dimValueId = dimValueId.replace(
                dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "",
                ""
              );
              dimValueId = dimValueId + (dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
              dimValueId = (dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + dimValueId;

              let dimValueLabel = getFormattedDimensionValueLabel(
                jsonStat,
                null,
                dim,
                dimValue,
                LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
                null,
                localizedTimePeriodFormatMap
              );
              dimValueLabel = dimValueLabel.replace(
                dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "",
                ""
              );
              dimValueLabel = dimValueLabel + (dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
              dimValueLabel =
                (dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + dimValueLabel;

              passFilter =
                passFilter &&
                (willValuePassDimFilters(dimValueId, filters[dim]) ||
                  willValuePassDimFilters(dimValueLabel, filters[dim]));
            } else {
              let dimValueLabel = getFormattedDimensionValueLabel(
                jsonStat,
                null,
                dim,
                dimValue,
                customLabelFormats?.[dim] || labelFormat,
                null,
                localizedTimePeriodFormatMap
              );
              dimValueLabel = dimValueLabel.replace(
                dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "",
                ""
              );
              dimValueLabel = dimValueLabel + (dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
              dimValueLabel =
                (dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + dimValueLabel;

              passFilter = passFilter && willValuePassDimFilters(dimValueLabel, filters[dim]);
            }
          });

          if (passFilter) {
            const rowDimValues = rows.map(row => getDimensionValueFromIdx(row, r, dimValuesMap, dimSpanMap)).join("+");
            filteredRows[rowDimValues] = 1;
          } else {
            delete valorizedRows[r];
            Object.keys(valueMatrix[s]).forEach(c => delete valueMatrix[s][c][r]);
          }
        }
      }
    });
  }

  /** order handling **/

  const sectionRowsOrder = {};
  valorizedSectionRows.forEach((valorizedRows, sIdx) => {
    sectionRowsOrder[sIdx] = {};
    Object.keys(valorizedRows).forEach(rIdx => {
      sectionRowsOrder[sIdx][rIdx] = rIdx;
    });
  });

  /** hierarchical items handling **/

  const depths = {};
  rows.forEach(row => {
    const childMap = jsonStat.dimension[row].category?.child || {};

    const map = {};
    const tree = [];
    const depth = {};

    jsonStat.dimension[row].category.index.forEach(key => {
      map[key] = {
        id: key,
        children: []
      };
    });
    Object.keys(childMap).forEach(parent => {
      childMap[parent].forEach(child => {
        map[child] = {
          ...map[child],
          parent: parent
        };
      });
    });
    jsonStat.dimension[row].category.index.forEach(item => {
      const node = map[item];
      if (node.parent && map[node.parent]) {
        // if the element is not at the root level, add it to its parent array of children.
        map[node.parent].children.push(node);
      } else {
        // if the element is at the root level, add it to first level elements array.
        tree.push(node);
      }
    });

    tree.forEach(({id}) => (depth[id] = 0));
    getMappedTree(tree, "children", node => {
      node.children.forEach(({id}) => {
        depth[id] = depth[node.id] + 1;
      });
      return node;
    });

    depths[row] = depth;
  });

  /** row and col count handling **/

  let rowCount = 0;
  valorizedSectionRows.forEach(valorizedRows => (rowCount += Object.keys(valorizedRows).length));

  const colCount = Object.keys(valorizedCols).length;

  /** measures of synthesis snd variability handling **/

  let arithmeticMeans = null;
  let standardDeviations = null;
  let coefficientOfVariations = null;

  if (enableMeasuresOfSynthesisAndVariability) {
    arithmeticMeans = {};
    standardDeviations = {};
    coefficientOfVariations = {};

    for (let c = 0; c < colCountFull; c++) {
      let arithmeticMean = null;
      let standardDeviation = null;
      let coefficientOfVariation = null;

      let summation;
      let count;

      if (valorizedCols[c] === 1) {
        const colId = jsonStat.id
          .filter(dim => cols.includes(dim))
          .map(col => getDimensionValueFromIdx(col, c, dimValuesMap, dimSpanMap))
          .join("+");

        /** computing arithmetic mean **/
        summation = 0;
        count = 0;
        sectionIdxs.forEach(s => {
          const numericValues = Object.values(valueMatrix[s][c]).filter(value => isNumeric(value));
          numericValues.forEach(val => (summation += val));
          count += numericValues.length;
        });
        arithmeticMean = summation / count;

        /** computing standard deviation **/
        summation = 0;
        count = 0;
        sectionIdxs.forEach(s => {
          const numericValues = Object.values(valueMatrix[s][c]).filter(value => isNumeric(value));
          numericValues.forEach(val => (summation += Math.pow(val - arithmeticMean, 2)));
          count += numericValues.length;
        });
        standardDeviation = Math.sqrt(summation / count);

        /** computing coefficient of variation **/
        coefficientOfVariation = (standardDeviation / Math.abs(arithmeticMean)) * 100;

        arithmeticMeans[colId] = arithmeticMean;
        standardDeviations[colId] = standardDeviation;
        coefficientOfVariations[colId] = coefficientOfVariation;
      }
    }
  }

  return {
    rowCount: rowCount,
    colCount: colCount,
    sectionRowCountFull: sectionRowCountFull,
    rowCountFull:
      sectionDimCombinations && sectionDimCombinations.length > 0
        ? sectionDimCombinations.length * sectionRowCountFull
        : sectionRowCountFull,
    colCountFull: colCountFull,
    dimSpanMap: dimSpanMap,
    dimValuesMap: dimValuesMap,
    sectionDimCombinations: sectionDimCombinations,
    indexesMap: indexesMap,
    valorizedSectionRows: valorizedSectionRows,
    valorizedCols: valorizedCols,
    dimAttributeMap: null, // dimAttributeMap -> commented because it is not possible to pass the t function to a worker
    obsAttributeMap: obsAttributeMap,
    isPreview: isPreview,
    showTrend: showTrend,
    showCyclical: showCyclical,
    valueMatrix: valueMatrix,
    sectionRowsOrder: sectionRowsOrder,
    filters: filters,
    filteredRows: filteredRows ? Object.keys(filteredRows) : null,
    depths: depths,
    customLabelFormats: customLabelFormats,
    dimensionValueModifiers: dimensionValueModifiers,
    arithmeticMeans: arithmeticMeans,
    standardDeviations: standardDeviations,
    coefficientOfVariations: coefficientOfVariations
  };
};

const getTableRightPadding = () => `<td class="c c-rb"></td>`;

const getTableHeaderRightPadding = () => `<th class="c c-rb"></th>`;

const getTableHeaderDimensionValueCells = (
  tableSupportStructures,
  valorizedCols,
  paginationParams,
  labelFormat,
  customLabelFormats,
  dimensionValueModifiers,
  fontSize,
  firstColIdx,
  getTextWidthEl,
  dimension,
  t
) => {
  const {colCountFull, jsonStat, dimSpanMap, dimValuesMap, dimAttributeMap} = tableSupportStructures;

  const timeDim = jsonStat.role?.time?.[0];

  let string = "";

  let colCount = 0;
  let c = firstColIdx;
  while (c < colCountFull && colCount < paginationParams.colPerPage) {
    const colSpanMax = dimSpanMap[dimension] - (c % dimSpanMap[dimension]);

    const min = c;
    const max = c + Math.min(colSpanMax, paginationParams.colPerPage);
    let colSpan = 0;
    Object.keys(valorizedCols).forEach(val => {
      if (val >= min && val < max) {
        colSpan++;
      }
    });

    if (colSpan > 0) {
      const dimensionValue = getDimensionValueFromIdx(dimension, c, dimValuesMap, dimSpanMap);
      let cellText = getFormattedDimensionValueLabel(
        jsonStat,
        null,
        dimension,
        dimensionValue,
        customLabelFormats?.[dimension] || labelFormat,
        t
      ).replace(dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "", "");
      cellText = cellText + (dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
      cellText = (dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + cellText;

      let datasetId;
      if (dimension === MARGINAL_DIMENSION_KEY) {
        const marginal = jsonStat.extension.marginalvalues[dimensionValue];
        datasetId = marginal.label ? MARGINAL_ATTRIBUTE_KEY : marginal.datasetid;
      } else {
        datasetId = jsonStat?.extension?.datasets?.[0];
      }

      const htmlString = dimAttributeMap?.[datasetId]?.[dimension]?.[dimensionValue]
        ? `<span id="${datasetId}:${dimension}:${dimensionValue}" class="ct ctsh">(*)</span>`
        : "";

      window.jQuery(getTextWidthEl).addClass(`c cf${fontSize} csh ${htmlString.length > 0 ? "ca" : ""}`);
      window.jQuery(`<span>${cellText + htmlString}<span/>`).appendTo(getTextWidthEl);
      const textWidth = window.jQuery(getTextWidthEl).innerWidth();
      window.jQuery(getTextWidthEl).removeClass().empty();

      cellText += htmlString;

      string +=
        `<th class="c cf${fontSize} csh ${
          htmlString.length > 0 ? "ca" : ""
        }" colspan="${colSpan}" style="white-space: ${dimension === timeDim ? "nowrap" : "normal"}; min-width: ${
          textWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 40
        }px">` +
        cellText +
        `</th>`;

      colCount += colSpan;
    }

    c += colSpanMax;
  }

  if (paginationParams) {
    string += getTableHeaderRightPadding();
  }

  return string;
};

const getTableHeaderIconCells = (
  tableSupportStructures,
  valorizedCols,
  paginationParams,
  fontSize,
  firstColIdx,
  sortable,
  orderedCol,
  isOrderedAscending,
  filterable
) => {
  const {colCountFull, isPreview, filters} = tableSupportStructures;

  let string = "";
  if (!isPreview) {
    let colCount = 0;
    for (let c = firstColIdx; c < colCountFull && colCount < paginationParams.colPerPage; c++) {
      if (valorizedCols[c] === 1) {
        let iconsWidth = 0 + (sortable ? 48 : 0) + (filterable ? 24 : 0);

        string += `<th class="c cf${fontSize} csh csh-icons ${
          colCount === 0 ? "csh-icons--first" : ""
        }" colspan="1" style="min-width: ${iconsWidth + 8 + "px"}">`;

        string += "&nbsp;"; // needed to handle cell height

        string += `<div class="table-icons" style="width: ${iconsWidth}px; left: calc(50% - ${iconsWidth / 2}px)">`;
        if (sortable) {
          string +=
            `<div id="c-${c}" class="table-icon col-sort col-sort--a ${
              orderedCol === c && isOrderedAscending ? "table-icon--selected" : ""
            }">${upIcon}</div>` +
            `<div id="c-${c}" class="table-icon col-sort col-sort--d ${
              orderedCol === c && !isOrderedAscending ? "table-icon--selected" : ""
            }">${downIcon}</div>`;
        }
        if (filterable) {
          string += `<div id="c-${c}" class="table-icon col-filter ${
            filters && filters[c] ? "table-icon--selected" : ""
          }">${filterIcon}</div>`;
        }

        string += "</div>";

        string += "</th>";

        colCount++;
      }
    }
  } else {
    string += `<th class="c cf${fontSize} csh" colspan="${3}"/>`;
  }

  if (paginationParams) {
    string += getTableHeaderRightPadding();
  }

  return string;
};

const getTableHeader = (
  uuid,
  tableSupportStructures,
  valorizedCols,
  paginationParams,
  labelFormat,
  customLabelFormats,
  dimensionValueModifiers,
  customDimensionLabels,
  fontSize,
  decimalSeparator,
  roundingStrategy,
  firstColIdx,
  sortable,
  orderedCol,
  isOrderedAscending,
  filterable,
  showArithmeticMean,
  showStandardDeviation,
  showCoefficientOfVariation,
  t
) => {
  const {jsonStat, layout, isPreview, showTrend, showCyclical, filters} = tableSupportStructures;

  const {rows, cols} = layout;

  const getTextWidthEl = window
    .jQuery("<span/>")
    .css({visibility: "hidden"})
    .appendTo(`#jsonstat-table__${uuid}`)
    .get(0);

  let thead = `<thead class="table-head">`;

  const filteredCols = cols.filter(col => col !== VARIATION_DIMENSION_KEY || showTrend || showCyclical);
  filteredCols.forEach((col, idx) => {
    thead += `<tr id="h-${idx}">`;

    const cellText = customDimensionLabels?.[col] || getFormattedDimensionLabel(jsonStat, null, col, labelFormat, t);

    window.jQuery(getTextWidthEl).addClass(`c cf${fontSize} ch`);
    window.jQuery(`<span>${cellText}<span/>`).appendTo(getTextWidthEl);
    const minWidth = window.jQuery(getTextWidthEl).innerWidth();
    window.jQuery(getTextWidthEl).removeClass().empty();

    thead += `<th class="c cf${fontSize} ch cl0" colspan="${rows.length}" style="min-width: ${
      minWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 24
    }px">${cellText}</th>`;

    if (!isPreview) {
      thead += getTableHeaderDimensionValueCells(
        tableSupportStructures,
        valorizedCols,
        paginationParams,
        labelFormat,
        customLabelFormats,
        dimensionValueModifiers,
        fontSize,
        firstColIdx,
        getTextWidthEl,
        col,
        t
      );
    } else {
      for (let c = 0; c < 3; c++) {
        thead += `<th class="c cf${fontSize} csh" colspan="1">${TABLE_PREVIEW_PLACEHOLDER}</th>`;
      }
    }

    thead += "</tr>";
  });

  thead += `<tr id="hh">`;
  if (rows.length > 0) {
    rows.forEach((row, idx) => {
      thead += `<th class="c cf${fontSize} ch cl${idx} ${filterable ? "ch-icons" : ""}">`;
      thead += customDimensionLabels?.[row] || getFormattedDimensionLabel(jsonStat, null, row, labelFormat, t);
      if (filterable) {
        thead += `<div id="c-${row}" class="table-icon col-filter ${
          filters && filters[row] ? "table-icon--selected" : ""
        }">${filterIcon}</div>`;
      }
      thead += "</th>";
    });
  } else {
    thead += '<th class="c ch">&nbsp;</th>';
  }
  thead += getTableHeaderIconCells(
    tableSupportStructures,
    valorizedCols,
    paginationParams,
    fontSize,
    firstColIdx,
    sortable,
    orderedCol,
    isOrderedAscending,
    filterable
  );
  thead += "</tr>";

  thead += "</thead>";

  window.jQuery(getTextWidthEl).remove();

  return thead;
};

const getMergedTableHeaderHtml = (
  uuid,
  tableSupportStructures,
  valorizedCols,
  paginationParams,
  labelFormat,
  customLabelFormats,
  splitRowDimensions,
  dimensionValueModifiers,
  customDimensionLabels,
  fontSize,
  decimalSeparator,
  roundingStrategy,
  firstColIdx,
  sortable,
  orderedCol,
  isOrderedAscending,
  filterable,
  showArithmeticMean,
  showStandardDeviation,
  showCoefficientOfVariation,
  t,
  unmergedDims,
  hiddenDimensionValueLabels,
  showTableSettings,
  territorialClassificationsConfig
) => {
  const {
    jsonStat,
    layout,
    colCountFull,
    dimSpanMap,
    dimValuesMap,
    dimAttributeMap,
    showTrend,
    showCyclical,
    filters,
    arithmeticMeans: arithmeticMeansFull,
    standardDeviations: standardDeviationsFull,
    coefficientOfVariations: coefficientOfVariationsFull
  } = tableSupportStructures;

  const {rows, cols} = layout;

  const hiddenLabels = (hiddenDimensionValueLabels || []).map(label => label.toLowerCase());

  const getTextWidthEl = window
    .jQuery("<span/>")
    .css({visibility: "hidden"})
    .appendTo(`#jsonstat-table__${uuid}`)
    .get(0);

  let thead = `<thead class="table-merged-head">`;

  thead += '<tr id="h-0">';

  rows.forEach(dim => {
    const cellText = splitRowDimensions
      ? getFormattedDimensionLabel(jsonStat, null, dim, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME, t)
      : customDimensionLabels?.[dim] || getFormattedDimensionLabel(jsonStat, null, dim, labelFormat, t);

    window.jQuery(getTextWidthEl).addClass(`c cf${fontSize} ch`);
    window.jQuery(`<span>${cellText}<span/>`).appendTo(getTextWidthEl);
    const labelWidth = window.jQuery(getTextWidthEl).innerWidth();
    window.jQuery(getTextWidthEl).removeClass().empty();

    thead +=
      `<th ` +
      `class="c cf${fontSize} ch cl0" ` +
      `colspan="${splitRowDimensions ? 2 : 1}" ` +
      `rowspan="${1 + (cols.length > 1 ? 1 : 0)}" ` +
      `style="min-width: ${labelWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 24}px"> ` +
      cellText +
      (showTableSettings ? `<div class="table-icon terr-class-config">${settingsIcon}</div>` : "") +
      `</th>`;
  });

  /** territorial classifications **/

  (territorialClassificationsConfig ?? []).forEach(({label, format}) => {
    window.jQuery(getTextWidthEl).addClass("c csh");
    window.jQuery(`<span>${label}<span/>`).appendTo(getTextWidthEl);
    const labelWidth = window.jQuery(getTextWidthEl).innerWidth();
    window.jQuery(getTextWidthEl).removeClass().empty();

    thead +=
      `<th ` +
      `class="c csh cl0" ` +
      `rowspan="2" ` +
      `colspan="${format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH ? 2 : 1}" ` +
      `style="min-width: ${labelWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 24 + "px"}" ` +
      `>` +
      label +
      `</th>`;
  });

  (unmergedDims || []).forEach(dim => {
    thead += getTableHeaderDimensionValueCells(
      tableSupportStructures,
      valorizedCols,
      paginationParams,
      labelFormat,
      customLabelFormats,
      dimensionValueModifiers,
      fontSize,
      firstColIdx,
      getTextWidthEl,
      dim,
      t
    );
  });

  thead += "</tr>";

  const arithmeticMeans = [];
  const standardDeviations = [];
  const coefficientOfVariations = [];

  const filteredCols = cols.filter(
    col => !(unmergedDims || []).includes(col) && (col !== VARIATION_DIMENSION_KEY || showTrend || showCyclical)
  );
  if (filteredCols.length > 0) {
    thead += '<tr id="h-1">';

    let colCount = 0;
    for (let c = firstColIdx; c < colCountFull && colCount < paginationParams.colPerPage; c++) {
      if (valorizedCols[c] === 1) {
        let cellText = "";
        let hasAttributes = false;

        filteredCols.forEach(col => {
          const dimValue = getDimensionValueFromIdx(col, c, dimValuesMap, dimSpanMap);
          if (col !== MARGINAL_DIMENSION_KEY) {
            const datasetId = jsonStat?.extension?.datasets?.[0];

            let valueLabel = getFormattedDimensionValueLabel(
              jsonStat,
              null,
              col,
              dimValue,
              customLabelFormats?.[col] || labelFormat,
              t
            ).replace(dimensionValueModifiers?.[col]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "", "");
            valueLabel = valueLabel + (dimensionValueModifiers?.[col]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
            valueLabel = (dimensionValueModifiers?.[col]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + valueLabel;
            const htmlString = dimAttributeMap?.[datasetId]?.[col]?.[dimValue]
              ? `<span id="${datasetId}:${col}:${dimValue}" class="ct ctsh">(*)</span>`
              : "";
            if (htmlString.length > 0) {
              hasAttributes = true;
            }
            if (!hiddenLabels.includes(valueLabel.toLowerCase()) || htmlString.length > 0) {
              cellText += valueLabel;
              cellText += htmlString;
              cellText += "<br/>";
            }
          } else {
            const marginal = jsonStat.extension.marginalvalues[dimValue];

            if (marginal.label) {
              const htmlString = dimAttributeMap?.[MARGINAL_ATTRIBUTE_KEY]?.[col]?.[dimValue]
                ? `<span id="${MARGINAL_ATTRIBUTE_KEY}:${col}:${dimValue}" class="ct ctsh">(*)</span>`
                : "";
              if (htmlString.length > 0) {
                hasAttributes = true;
              }
              cellText += marginal.label;
              cellText += htmlString;
              cellText += "<br/>";
            } else {
              getMarginalDimensions(jsonStat, marginal.datasetid).forEach(dim => {
                let valueLabel = getFormattedDimensionValueLabel(
                  jsonStat,
                  marginal.datasetid,
                  dim,
                  marginal.dimensionvalues[dim],
                  customLabelFormats?.[dim] || labelFormat,
                  t
                ).replace(dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "", "");
                valueLabel = valueLabel + (dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
                valueLabel = (dimensionValueModifiers?.[dim]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + valueLabel;
                const htmlString = dimAttributeMap?.[marginal.datasetid]?.[dim]?.[marginal.dimensionvalues[dim]]
                  ? `<span id="${marginal.datasetid}:${dim}:${marginal.dimensionvalues[dim]}" class="ct ctsh">(*)</span>`
                  : "";
                if (htmlString.length > 0) {
                  hasAttributes = true;
                }
                if (!hiddenLabels.includes(valueLabel.toLowerCase()) || htmlString.length > 0) {
                  cellText += valueLabel;
                  cellText += htmlString;
                  cellText += "<br/>";
                }
              });
            }
          }
        });

        thead += `<th id="c-${c}-dims-${filteredCols.join(",")}" class="c cf${fontSize} csh ${
          hasAttributes ? "ca" : ""
        }" colspan="1">`;
        thead += cellText;
        thead += "</th>";

        const colId = jsonStat.id
          .filter(dim => cols.includes(dim))
          .map(col => getDimensionValueFromIdx(col, c, dimValuesMap, dimSpanMap))
          .join("+");

        arithmeticMeans.push(arithmeticMeansFull?.[colId] || null);
        standardDeviations.push(standardDeviationsFull?.[colId] || null);
        coefficientOfVariations.push(coefficientOfVariationsFull?.[colId] || null);

        colCount++;
      }
    }

    if (paginationParams) {
      thead += getTableHeaderRightPadding();
    }
    thead += "</tr>";
  }

  thead += `<tr id="hh">`;
  rows.forEach(row => {
    let iconCell = "";
    iconCell += `<th class="c ch ch-icons" colspan="1" style="min-width: 32px">`;
    iconCell += "&nbsp;"; // needed to handle cell height
    iconCell += '<div class="table-icons" style="width: 24px; left: calc(50% - 12px)">';
    if (filterable) {
      iconCell += `<div id="c-${row}" class="table-icon col-filter ${
        filters && filters[row] ? "table-icon--selected" : ""
      }">${filterIcon}</div>`;
    }
    iconCell += "</div>";
    iconCell += "</th>";

    thead += iconCell;
    if (splitRowDimensions) {
      thead += iconCell;
    }
  });

  (territorialClassificationsConfig ?? []).forEach(({id, format}) => {
    thead +=
      `<th ` +
      `class="c csh csh-icons ch-icons-terr-class-values" ` +
      `colspan="${format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH ? 2 : 1}" ` +
      `>`;
    thead += `<div class="table-icons">`;
    thead += `<div id="c-level-${id}" class="table-icon terr-class-level-remove">` + closeIcon + `</div>`;
    thead += "</div>";
    thead += "</th>";
  });

  thead += getTableHeaderIconCells(
    tableSupportStructures,
    valorizedCols,
    paginationParams,
    fontSize,
    firstColIdx,
    sortable,
    orderedCol,
    isOrderedAscending,
    filterable
  );
  thead += "</tr>";

  thead += "</thead>";

  window.jQuery(getTextWidthEl).remove();

  if (showArithmeticMean && arithmeticMeans.length > 0) {
    thead += `<tr id="arithmetic-mean" class="indicators">`;
    thead +=
      `<th class="c ci-h" colspan="${rows.length * (splitRowDimensions ? 2 : 1)}">` +
      t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.label") +
      ":" +
      `</th>`;
    arithmeticMeans.forEach(value => {
      thead += `<th class="c ci-v">${getFormattedValue(value, decimalSeparator, 2, "", roundingStrategy)}</th>`;
    });
    thead += getTableRightPadding();
    thead += "</tr>";
  }

  if (showStandardDeviation && standardDeviations.length > 0) {
    thead += `<tr id="standard-deviation" class="indicators">`;
    thead += `<th class="c ci-h" colspan="${rows.length * (splitRowDimensions ? 2 : 1)}">${t(
      "commons.measuresOfSynthesisAndVariability.values.standardDeviation.label"
    )}:</th>`;
    standardDeviations.forEach(value => {
      thead += `<th class="c ci-v">${getFormattedValue(value, decimalSeparator, 2, "", roundingStrategy)}</th>`;
    });
    thead += getTableRightPadding();
    thead += "</tr>";
  }

  if (showCoefficientOfVariation && coefficientOfVariations.length > 0) {
    thead += `<tr id="coefficient-of-variation" class="indicators">`;
    thead += `<th class="c ci-h" colspan="${rows.length * (splitRowDimensions ? 2 : 1)}">${t(
      "commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation.label"
    )}:</th>`;
    coefficientOfVariations.forEach(value => {
      thead += `<th class="c ci-v">${getFormattedValue(value, decimalSeparator, 2, "", roundingStrategy)}</th>`;
    });
    thead += getTableRightPadding();
    thead += "</tr>";
  }

  return thead;
};

export const getTableHtml = (
  uuid,
  tableSupportStructures,
  headerType,
  paginationParams,
  labelFormat,
  customLabelFormats,
  splitRowDimensions,
  dimensionValueModifiers,
  customDimensionLabels,
  fontSize,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  emptyChar,
  sortable,
  orderedCol,
  isOrderedAscending,
  sectionRowsOrder,
  filterable,
  highlightedRowsDimValues,
  rowHover,
  hiddenDimensionValueLabels,
  showArithmeticMean,
  showStandardDeviation,
  showCoefficientOfVariation,
  hierarchyOnlyAttributes,
  showTableSettings,
  territorialClassificationsConfig,
  territorialClassificationsValues,
  onPageGenerationComplete,
  t
) => {
  const t0 = performance.now();

  const {
    jsonStat,
    layout,
    valorizedCols,
    valorizedSectionRows,
    sectionRowCountFull,
    colCountFull,
    dimSpanMap,
    dimValuesMap,
    sectionDimCombinations,
    indexesMap,
    dimAttributeMap,
    obsAttributeMap,
    filteredRows,
    depths
  } = tableSupportStructures;

  const {rows, cols, filtersValue, sections} = layout;

  const timeDim = jsonStat.role?.time?.[0];

  let renderedRows = [];
  let renderedCols = [];

  const isOrderingRow = orderedCol !== null;

  /** pagination **/

  const firstColIdx = getFirstIndex([valorizedCols], paginationParams.colStart);

  /** HTML generating **/

  let table = `<table id="${uuid}">`;

  /** table head **/

  table += getMergedTableHeaderHtml(
    uuid,
    tableSupportStructures,
    valorizedCols,
    paginationParams,
    labelFormat,
    customLabelFormats,
    splitRowDimensions,
    dimensionValueModifiers,
    customDimensionLabels,
    fontSize,
    decimalSeparator,
    roundingStrategy,
    firstColIdx,
    sortable,
    orderedCol,
    isOrderedAscending,
    filterable,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    t,
    [timeDim],
    hiddenDimensionValueLabels,
    showTableSettings,
    territorialClassificationsConfig
  );

  /** table body **/

  table += '<tbody id="body">';

  const sectionsStarts = [0];
  sectionDimCombinations.forEach((_, idx) => {
    sectionsStarts.push(sectionsStarts[idx] + sectionRowCountFull);
  });

  const subHeaderHandled = {};
  rows.forEach(row => (subHeaderHandled[row] = -1));

  let isRenderedColHandled = false;

  Object.keys(valorizedSectionRows[0] ?? [])
    .sort((a, b) => a.localeCompare(b, undefined, {numeric: true}))
    .slice(paginationParams.rowStart, paginationParams.rowStart + paginationParams.rowPerPage)
    .forEach(rs => {
      const r = Number(rs);

      const currentSectionIdx = 0;
      const sectionR = r % sectionRowCountFull;
      const sortedSectionR = sectionRowsOrder[currentSectionIdx][sectionR];

      if (valorizedSectionRows[currentSectionIdx][sectionR] === 1) {
        let rowDimsValue = [];
        rows.forEach(row => rowDimsValue.push(getDimensionValueFromIdx(row, sortedSectionR, dimValuesMap, dimSpanMap)));
        renderedRows.push(rowDimsValue.join("+"));

        const isSelectedRow =
          (highlightedRowsDimValues &&
            !_.isEmpty(highlightedRowsDimValues) &&
            !rows.find(
              dim =>
                !!highlightedRowsDimValues?.[dim] &&
                getDimensionValueFromIdx(dim, sortedSectionR, dimValuesMap, dimSpanMap) !==
                  highlightedRowsDimValues?.[dim]
            )) ||
          false;

        table += `<tr id="r-${r}" class="jsonstat-table__body__row ${
          isSelectedRow ? "jsonstat-table__body__row--selected" : ""
        } ${rowHover ? "jsonstat-table__body__row__hoverable" : ""}">`;

        let subHeader = "";

        if (rows.length > 0) {
          for (let rr = 0; rr < rows.length; rr++) {
            const datasetId = jsonStat?.extension?.datasets?.[0];
            const dimValue = rowDimsValue[rr];
            const htmlString = dimAttributeMap?.[datasetId]?.[rows[rr]]?.[dimValue]
              ? `<span id="${datasetId}:${rows[rr]}:${dimValue}" class="ct ctsh">(*)</span>`
              : "";

            if (r > subHeaderHandled[rows[rr]]) {
              const rowSpanMax = isOrderingRow ? 1 : dimSpanMap[rows[rr]] - (sortedSectionR % dimSpanMap[rows[rr]]);

              const min = sectionR;
              const max = sectionR + rowSpanMax;
              let rowSpan = 0;
              Object.keys(valorizedSectionRows[currentSectionIdx]).forEach(val => {
                if (val >= min && val < max) {
                  rowSpan++;
                }
              });

              subHeaderHandled[rows[rr]] =
                r + (isOrderingRow ? 0 : dimSpanMap[rows[rr]] - (r % dimSpanMap[rows[rr]]) - 1);

              if (rowSpan > 0) {
                const dimension = rows[rr];
                const value = getDimensionValueFromIdx(dimension, sortedSectionR, dimValuesMap, dimSpanMap);

                if (splitRowDimensions) {
                  let valueId = getFormattedDimensionValueLabel(
                    jsonStat,
                    null,
                    dimension,
                    value,
                    LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
                  );
                  valueId = valueId.replace(
                    dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "",
                    ""
                  );
                  valueId = valueId + (dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
                  valueId = (dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + valueId;

                  subHeader +=
                    `<th ` +
                    `class="c cf${fontSize} csh cl${rr} ${htmlString.length > 0 ? "ca" : ""}" ` +
                    `rowspan="${rowSpan}" ` +
                    `style="white-space: ${dimension === timeDim ? "nowrap" : "normal"};" ` +
                    `>` +
                    valueId +
                    `</th>`;

                  let valueLabel = getFormattedDimensionValueLabel(
                    jsonStat,
                    null,
                    dimension,
                    value,
                    LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
                  );
                  valueLabel = valueLabel.replace(
                    dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "",
                    ""
                  );
                  valueLabel =
                    valueLabel + (dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
                  valueLabel =
                    (dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + valueLabel;

                  subHeader +=
                    `<th ` +
                    `class="c cf${fontSize} csh cl${rr} ${htmlString.length > 0 ? "ca" : ""}" ` +
                    `rowspan="${rowSpan}" ` +
                    `style="white-space: ${dimension === timeDim ? "nowrap" : "normal"}";` +
                    `>` +
                    valueLabel +
                    htmlString +
                    `</th>`;
                } else {
                  let valueLabel = getFormattedDimensionValueLabel(
                    jsonStat,
                    null,
                    dimension,
                    value,
                    customLabelFormats?.[dimension] || labelFormat
                  );
                  valueLabel = valueLabel.replace(
                    dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] || "",
                    ""
                  );
                  valueLabel =
                    valueLabel + (dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_APPEND] || "");
                  valueLabel =
                    (dimensionValueModifiers?.[dimension]?.[DIM_VALUE_LABEL_MODIFIER_PREPEND] || "") + valueLabel;

                  const paddingLeft =
                    8 + (filteredRows || isOrderingRow ? 0 : (depths?.[dimension]?.[value] || 0) * 16);

                  subHeader +=
                    `<th ` +
                    `class="c cf${fontSize} csh cl${rr} ${htmlString.length > 0 ? "ca" : ""}" ` +
                    `rowspan="${rowSpan}" ` +
                    `style="white-space: ${
                      dimension === timeDim ? "nowrap" : "normal"
                    }; padding-left: ${paddingLeft}px !important;" ` +
                    `>` +
                    valueLabel +
                    htmlString +
                    `</th>`;
                }
              }
            }
          }

          const firstRowDimsValue = rowDimsValue[0];

          (territorialClassificationsConfig ?? []).forEach(({id, format}) => {
            const idCell =
              '<td class="c">' + (territorialClassificationsValues?.[firstRowDimsValue]?.[id]?.id ?? "") + "</td>";
            const nameCell =
              '<td class="c">' + (territorialClassificationsValues?.[firstRowDimsValue]?.[id]?.label ?? "") + "</td>";

            if (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME) {
              subHeader += nameCell;
            } else if (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID) {
              subHeader += idCell;
            } else if (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH) {
              subHeader += idCell;
              subHeader += nameCell;
            }
          });
        } else {
          subHeader += `<th class="c cf${fontSize} csh cl0"/>`;
        }

        table += subHeader;

        const getDataCell = (c, currentSectionIdx) => {
          const dataObj = {
            ...filtersValue
          };
          rows.forEach(row => (dataObj[row] = getDimensionValueFromIdx(row, sortedSectionR, dimValuesMap, dimSpanMap)));
          cols.forEach(col => (dataObj[col] = getDimensionValueFromIdx(col, c, dimValuesMap, dimSpanMap)));
          sections.forEach((section, idx) => (dataObj[section] = sectionDimCombinations[currentSectionIdx][idx]));

          const dataIndexArr = jsonStat.id.map(dim => indexesMap[dim][dataObj[dim]]);

          const dataIdx = getDataIdxFromCoordinatesArray(dataIndexArr, jsonStat.size);
          const value = jsonStat.value[dataIdx];

          let obsAttributes = obsAttributeMap?.[dataIdx] || [];
          obsAttributes = obsAttributes.filter(
            obsAttribute => !(hierarchyOnlyAttributes || []).includes(`${obsAttribute.id}+${obsAttribute.valueId}`)
          );

          const htmlString = obsAttributes.length > 0 ? `<span id="${dataIdx}" class="ct ctd">(*)</span>` : "";

          return (
            `<td id="r-${r}-c-${c}-d-${dataIdx}" class="c cf${fontSize} ${htmlString.length > 0 ? "ca" : ""}">` +
            htmlString +
            getFormattedValue(value, decimalSeparator, decimalPlaces, emptyChar, roundingStrategy) +
            `</td>`
          );
        };

        let colCount = 0;
        for (let c = firstColIdx; c < colCountFull && colCount < paginationParams.colPerPage; c++) {
          if (valorizedCols[c]) {
            table += getDataCell(c, currentSectionIdx);
            if (!isRenderedColHandled) {
              let colDimValues = [];
              cols.forEach(col => colDimValues.push(getDimensionValueFromIdx(col, c, dimValuesMap, dimSpanMap)));
              renderedCols.push(colDimValues.join("+"));
            }

            colCount++;
          }
        }
        isRenderedColHandled = true;

        table += getTableRightPadding();

        table += "</tr>";
      }
    });

  const terrClassColSpan = (territorialClassificationsConfig ?? []).reduce(
    (acc, {format}) => acc + (format === LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH ? 2 : 1),
    0
  );

  const bbColSpan = renderedCols.length + (rows.length * (splitRowDimensions ? 2 : 1) || 1) + terrClassColSpan;

  table += `<tr><td class="c c-bb" colspan="${bbColSpan}"></td></tr>`;

  table += "</tbody>";

  table += "</table>";

  table += '<div id="jsonstat-table__tooltip__attribute" class="ctt"></div>';
  if (headerType === TABLE_HEADER_MERGED) {
    table += '<div id="jsonstat-table__tooltip__merged-header" class="ctt"></div>';
  }

  const t1 = performance.now();

  if (onPageGenerationComplete) {
    onPageGenerationComplete({
      layout: layout,
      renderedRows: renderedRows,
      renderedCols: renderedCols,
      timings: t1 - t0
    });
  }

  return table;
};

export const getPreviewTableHtml = (
  uuid,
  tableSupportStructures,
  labelFormat,
  customLabelFormats,
  dimensionValueModifiers,
  customDimensionLabels
) => {
  const {jsonStat, layout} = tableSupportStructures;

  const {rows, cols, sections} = layout;

  /** HTML generating **/

  let table = `<table id="${uuid}">`;

  /** table head **/

  table += getTableHeader(
    uuid,
    tableSupportStructures,
    null,
    null,
    labelFormat,
    customLabelFormats,
    dimensionValueModifiers,
    customDimensionLabels
  );

  /** table body **/

  if (sections && sections.length > 0) {
    table += `<tr id="s-0" class="rs">`;
    let sectionLabel = "";
    sections.forEach((section, idx) => {
      sectionLabel += `<span style="display: inline-block;"><span class="cs-d">${
        customDimensionLabels?.[section] || getFormattedDimensionLabel(jsonStat, null, section, labelFormat)
      }:</span> ${TABLE_PREVIEW_PLACEHOLDER}</span>`;
      sectionLabel += idx < sections.length - 1 ? TABLE_SECTION_DIMENSIONS_SEPARATOR : "";
    });
    table += `<th class="c cs" colspan="${(rows.length || 1) + (cols.length > 0 ? 3 : 1)}">${sectionLabel}</th>`;
    table += "</tr>";
  }
  for (let r = 0; r < (rows.length > 0 ? 3 : 1); r++) {
    table += `<tr id="r-${r}">`;
    if (rows.length > 0) {
      for (let rr = 0; rr < rows.length; rr++) {
        table += `<th class="c csh cl${rr}">xxx</th>`;
      }
    } else {
      table += `<th class="c csh cl0">&nbsp;</th>`;
    }
    for (let c = 0; c < (cols.length > 0 ? 3 : 1); c++) {
      table += `<td class="c"/>`;
    }
    table += "</tr>";
  }

  table += "</tbody>";

  table += "</table>";

  return table;
};
