import React from "react";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import Button from "@mui/material/Button";

export const KeypadValues = {
  "(": "(",
  ")": ")",
  C: "C",
  D: "D",
  ",": ",",
  "+": "+",
  "-": "-",
  "*": "×",
  "/": "÷",
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9
};

const IndicatorCreateDialogKeypad = ({
  onClick,
  numberDisabled,
  operationDisabled,
  commaDisabled,
  leftParDisabled,
  rightParDisabled
}) => {
  const Key = ({children, span = 1, value, disabled}) => (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      style={{minWidth: 0, width: 42 * span + 8 * (span - 1), margin: 4, height: 42}}
      onClick={() => onClick(value || children)}
      className="indicator-calculator-dialog__click-listen"
      disabled={disabled}
    >
      {children}
    </Button>
  );

  return (
    <div>
      <div>
        <Key disabled={leftParDisabled}>{KeypadValues["("]}</Key>
        <Key disabled={rightParDisabled}>{KeypadValues[")"]}</Key>
        <Key>{KeypadValues.C}</Key>
        <Key value={KeypadValues.D}>
          <BackspaceOutlinedIcon className="indicator-calculator-dialog__click-listen" style={{fontSize: 16}} />
        </Key>
      </div>
      <div>
        <Key disabled={numberDisabled}>{KeypadValues["7"]}</Key>
        <Key disabled={numberDisabled}>{KeypadValues["8"]}</Key>
        <Key disabled={numberDisabled}>{KeypadValues["9"]}</Key>
        <Key disabled={operationDisabled}>{KeypadValues["/"]}</Key>
      </div>
      <div>
        <Key disabled={numberDisabled}>{KeypadValues["4"]}</Key>
        <Key disabled={numberDisabled}>{KeypadValues["5"]}</Key>
        <Key disabled={numberDisabled}>{KeypadValues["6"]}</Key>
        <Key disabled={operationDisabled}>{KeypadValues["*"]}</Key>
      </div>
      <div>
        <Key disabled={numberDisabled}>{KeypadValues["1"]}</Key>
        <Key disabled={numberDisabled}>{KeypadValues["2"]}</Key>
        <Key disabled={numberDisabled}>{KeypadValues["3"]}</Key>
        <Key disabled={operationDisabled}>{KeypadValues["-"]}</Key>
      </div>
      <div>
        <Key disabled={numberDisabled} span={2}>
          {KeypadValues["0"]}
        </Key>
        <Key disabled={commaDisabled}>{KeypadValues[","]}</Key>
        <Key disabled={operationDisabled}>{KeypadValues["+"]}</Key>
      </div>
    </div>
  );
};

export default IndicatorCreateDialogKeypad;
