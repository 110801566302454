import React, {createRef, Fragment, useMemo} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {compose} from "redux";
import {getNodeInternalUrl, goToHubDashboards} from "../../links";
import {NodeVisibility} from "../../model/IHubMinimalNode.d.ts";
import CardsGrid from "../cards-grid";
import Footer from "../footer";
import Header from "../header";
import Hero from "../hero";
import ModulesPlaceholder from "../modules-placeholder";
import Page from "../page";
import PageSection from "../page-section";
import SanitizedHTML from "../sanitized-html";
import {getPageTitle} from "../../utils/other";
import themeConfig from "../../theme-config/config.json";

const mapStateToProps = state => ({
  hub: state.hub,
  baseURL: state.config.baseURL,
  isA11y: state.app.isA11y,
  modulesConfig: state.app.modulesConfig
});

const scrollToRef = ref => {
  ref.current.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest"
  });
  ref.current.focus({preventScroll: true});
};

const Home = ({t, hub, baseURL, isA11y}) => {
  const location = useLocation();

  const info = createRef();
  const nodesRef = createRef();

  const params = new URLSearchParams(location.search);
  params.set("accessible", "true");
  const paramsStr = params.toString();
  const path = location.pathname;

  const hasDashboards = useMemo(() => {
    if (isA11y) {
      return false;
    } else {
      return (
        (
          hub.hub?.itemContainers?.dashboard?.uncategorizedDashboards?.concat(
            hub.hub?.itemContainers?.dashboard?.categorizedDashboards
          ) ?? []
        ).length > 0
      );
    }
  }, [isA11y, hub]);

  return (
    <Fragment>
      {!isA11y && (
        <a
          href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
          target="_self"
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.accessible")}
        </a>
      )}
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      {hub.hub.description && hub.hub.description.length > 0 && (
        <HashLink to={{hash: "#hub-information", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.hubInformation")}
        </HashLink>
      )}
      <HashLink to={{hash: "#nodes", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.nodes")}
      </HashLink>
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page
        title={
          hub?.hub?.name
            ? getPageTitle([t("scenes.hubWithName.title", {hubName: hub.hub.name})], t)
            : getPageTitle([t("scenes.hubWithoutName.title")], t)
        }
        id="landing-page"
      >
        <Header />
        <Box
          component="main"
          id="main"
          sx={{
            width: "100%",
            height: "100%"
          }}
          className={` home`}
        >
          <Hero
            title={hub.hub.name}
            slogan={hub.hub.slogan}
            logo={hub.hub.logoURL && <img src={baseURL + hub.hub.logoURL} alt="" />}
            background={
              hub.hub.backgroundMediaURL ? (
                hub.hub.backgroundMediaURL.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG|svg|SVG)$/) ? (
                  <Box
                    sx={{
                      background: `url("${baseURL + hub.hub.backgroundMediaURL}") center center / cover no-repeat`
                    }}
                  />
                ) : (
                  <video autoPlay muted loop>
                    <source src={baseURL + hub.hub.backgroundMediaURL} />
                  </video>
                )
              ) : (
                <Box className={{backgroundColor: theme => theme.palette.primary.light}} />
              )
            }
            showExtraLogos
          >
            <Grid container justifyContent="center" spacing={2}>
              {hub.hub.description && hub.hub.description.length > 0 && (
                <Grid item id="infos-btn">
                  <Button
                    id="hub_home_body_info_btn"
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={() => scrollToRef(info)}
                  >
                    {t("scenes.hub.informations")}
                  </Button>
                </Grid>
              )}
              <Grid item id="nodes-btn">
                <Button
                  id="hub_home_body_nodes_btn"
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={() => scrollToRef(nodesRef)}
                >
                  {t("scenes.hub.nodes")}
                </Button>
              </Grid>
              <ModulesPlaceholder id="hub-home-buttons" />
              {hasDashboards && (
                <Grid item id="dashboards-btn">
                  <Button
                    id="hub_home_body_dashboards_btn"
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={goToHubDashboards}
                  >
                    {t("scenes.hub.dashboards")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Hero>
          <Box
            sx={{
              backgroundColor: "#f5f5f5",
              width: "100%"
            }}
          >
            <Container sx={{paddingTop: theme => theme.spacing(3)}} id="section-container">
              {hub.hub.description && hub.hub.description.length > 0 && (
                <Box sx={{position: "relative"}} id="description-section">
                  <Box
                    id="hub-information"
                    sx={{
                      position: "absolute",
                      top: theme => -theme.mixins.toolbar.minHeight
                    }}
                    ref={info}
                    tabIndex={-1}
                  />
                  <PageSection
                    sx={{paddingTop: theme => theme.spacing(3)}}
                    className={`hub-information`}
                    sectiontitle={t("scenes.hub.informations")}
                  >
                    <Box textAlign="justify">
                      <SanitizedHTML html={hub.hub.description} allowTarget />
                    </Box>
                  </PageSection>
                </Box>
              )}
              <Box sx={{position: "relative"}} id="nodes-section">
                <Box
                  id="nodes"
                  sx={{
                    position: "absolute",
                    top: theme => -theme.mixins.toolbar.minHeight
                  }}
                  ref={nodesRef}
                  tabIndex={-1}
                />
                <PageSection sx={{paddingTop: theme => theme.spacing(3)}} sectiontitle={t("scenes.hub.nodes")}>
                  <Box sx={{marginTop: theme => theme.spacing(2)}}>
                    <nav aria-label={t("scenes.hub.nodes")}>
                      <CardsGrid
                        list={hub.nodes
                          .filter(({visible}) => visible !== NodeVisibility.No)
                          .sort((a, b) => a.order - b.order)
                          .map(({code, name, backgroundMediaURL}) => ({
                            code,
                            id: code,
                            label: name,
                            image: backgroundMediaURL
                              ? baseURL + backgroundMediaURL
                              : "./images/default-node-background.jpg"
                          }))}
                        getHref={node => getNodeInternalUrl(node?.code)}
                        {...themeConfig.nodesGridProps}
                      />
                    </nav>
                  </Box>
                </PageSection>
              </Box>
            </Container>
            <Footer />
          </Box>
        </Box>
      </Page>
    </Fragment>
  );
};

export default compose(withTranslation(), connect(mapStateToProps))(Home);
