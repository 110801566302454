import React, {Fragment, useState} from "react";
import {Box} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import getDownloadButtonStyle from "./getDownloadButtonStyle";

const DatasetAttachments = ({datasetTitle, attachedDataFiles}) => {
  const {t} = useTranslation();

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [downloadModal, setDownloadModal] = useState(null);

  const groupedAttachments = [];
  (attachedDataFiles || []).forEach(({format, url, description}) => {
    const group = groupedAttachments.find(({extension}) => extension === format.toLowerCase());
    if (!group) {
      groupedAttachments.push({
        extension: format.toLowerCase(),
        urls: [{url, description}]
      });
    } else {
      group.urls.push({url, description});
    }
  });

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          "& > .MuiChip-root": {
            margin: "4px"
          },
          "& > .MuiChip-root:focus": {
            outline: "-webkit-focus-ring-color auto 1px"
          }
        }}
      >
        {groupedAttachments.map((group, idx) => (
          <Tooltip
            key={`${group.format}_${idx}`}
            title={group.urls.length === 1 ? group.urls[0].description || "" : ""}
          >
            <Chip
              className={`download-btn--${group.extension.toLowerCase()}`}
              label={group.extension.toUpperCase()}
              deleteIcon={
                group.urls.length > 1 ? (
                  <Avatar
                    style={{fontSize: 12, color: "black"}}
                    sx={{
                      width: "16px",
                      height: "16px",
                      marginLeft: "-4px",
                      marginRight: "4px"
                    }}
                  >
                    {group.urls.length}
                  </Avatar>
                ) : null
              }
              onDelete={
                group.urls.length > 1
                  ? e => {
                      e.stopPropagation();
                      setIsDownloadModalOpen(true);
                      setDownloadModal(group);
                    }
                  : null
              }
              size="small"
              onClick={e => {
                e.stopPropagation();
                if (group.urls.length > 1) {
                  setIsDownloadModalOpen(true);
                  setDownloadModal(group);
                } else {
                  window.open(group.urls[0].url, "_blank");
                }
              }}
              style={{
                cursor: "pointer",
                ...getDownloadButtonStyle(group.extension)
              }}
              sx={{
                "& .MuiChip-deleteIconSmall": {
                  backgroundColor: "white"
                }
              }}
              aria-label={t("scenes.results.datasetCard.downloadAria", {
                title: datasetTitle,
                extension: group.extension.toUpperCase()
              })}
            />
          </Tooltip>
        ))}
      </Box>
      {groupedAttachments.length > 0 && (
        <Dialog
          open={!!isDownloadModalOpen}
          onClose={() => setIsDownloadModalOpen(false)}
          TransitionProps={{
            onExited: () => {
              setDownloadModal(null);
            }
          }}
        >
          <DialogContent>
            <List>
              {downloadModal?.urls.map((data, key) => (
                <ListItem key={key}>
                  <Tooltip title={data.description || ""}>
                    <a href={data.url} target="_blank" rel="noopener noreferrer">
                      {data.url}
                    </a>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
            <DialogActions>
              <Button onClick={() => setIsDownloadModalOpen(false)}>{t("commons.confirm.close")}</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
};

export default DatasetAttachments;
