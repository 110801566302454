import {PayloadAction, Reducer} from "@reduxjs/toolkit";
import {IHub} from "../../model/IHub";
import {IHubMinimalNode} from "../../model/IHubMinimalNode";
import {ISupportedAuth} from "../../model/ISupportedAuth";
import {HUB_CLEAR, HUB_FETCH, HUB_FETCH_CUSTOM_ITEM_CONTAINERS} from "./hubActions";
import {REQUEST_SUCCESS} from "../../middlewares/request/requestActions";

export type HubState = {
  hub: IHub;
  nodes: IHubMinimalNode[];
  supportedAuths: ISupportedAuth[];
  disableRegistration: boolean;
} | null;

const hubReducer: Reducer<HubState, PayloadAction<any>> = (state: HubState = null, action) => {
  switch (action.type) {
    case HUB_CLEAR: {
      return null;
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case HUB_FETCH: {
          return {
            hub: action.payload.response.hub,
            nodes: action.payload.response.nodes,
            supportedAuths: action.payload.response.supportedAuths,
            disableRegistration: action.payload.response.disableRegistration || false
          };
        }
        case HUB_FETCH_CUSTOM_ITEM_CONTAINERS: {
          return {
            ...state,
            hub: {
              ...state.hub,
              itemContainers: {
                ...(state.hub.itemContainers || {}),
                [action.payload.extra.type]: action.payload.response
              }
            }
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default hubReducer;
