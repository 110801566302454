import React, {useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import {useTranslation} from "react-i18next";

export const DIM_FILTER_OPERATOR_EQUAL = "Equal";
export const DIM_FILTER_OPERATOR_NOT_EQUAL = "NotEqual";
export const DIM_FILTER_OPERATOR_STARTS_WITH = "StartsWith";
export const DIM_FILTER_OPERATOR_ENDS_WITH = "EndsWith";
export const DIM_FILTER_OPERATOR_INCLUDES = "Like";
export const DIM_FILTER_OPERATOR_NOT_INCLUDES = "NotLike";

const emptyFilter = {
  operator: DIM_FILTER_OPERATOR_EQUAL,
  value: ""
};

const DimensionFilterForm = ({filter, onSubmit}) => {
  const {t} = useTranslation();

  const [filterTmp, setFilterTmp] = useState(_.merge({}, emptyFilter, filter));

  const handleApply = () => {
    onSubmit((filterTmp.value || "").length > 0 ? filterTmp : null);
  };

  const handleRemove = () => {
    onSubmit(null);
  };

  const {operator, value} = filterTmp;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {t("components.table.dimensionFilter.info")}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Select
              value={operator}
              variant="outlined"
              fullWidth
              onChange={({target}) => setFilterTmp({...filterTmp, operator: target.value})}
              SelectDisplayProps={{"aria-haspopup": true}}
            >
              <MenuItem value={DIM_FILTER_OPERATOR_EQUAL}>
                {t("components.table.dimensionFilter.operator.equal")}
              </MenuItem>
              <MenuItem value={DIM_FILTER_OPERATOR_NOT_EQUAL}>
                {t("components.table.dimensionFilter.operator.notEqual")}
              </MenuItem>
              <MenuItem value={DIM_FILTER_OPERATOR_STARTS_WITH}>
                {t("components.table.dimensionFilter.operator.startsWith")}
              </MenuItem>
              <MenuItem value={DIM_FILTER_OPERATOR_ENDS_WITH}>
                {t("components.table.dimensionFilter.operator.endsWith")}
              </MenuItem>
              <MenuItem value={DIM_FILTER_OPERATOR_INCLUDES}>
                {t("components.table.dimensionFilter.operator.contains")}
              </MenuItem>
              <MenuItem value={DIM_FILTER_OPERATOR_NOT_INCLUDES}>
                {t("components.table.dimensionFilter.operator.notContains")}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={value}
              variant="outlined"
              fullWidth
              onChange={({target}) => setFilterTmp({...filterTmp, value: target.value})}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button onClick={handleRemove} color="primary">
              {t("components.table.dimensionFilter.remove")}
            </Button>
            <Button onClick={handleApply} color="primary">
              {t("components.table.dimensionFilter.apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DimensionFilterForm;
