import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import UserErrors from "../../user-errors";
import {setUserPassword} from "../../../state/user/userActions";

const Form = forwardRef(({onSubmit}, ref) => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    watch
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const {t} = useTranslation();

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        onSubmit(val);
        f(val);
      })();
    },
    cancel(f) {
      f();
    }
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            {...register("email", {
              required: t("commons.validation.required")
            })}
            name="email"
            label={t("components.userSetPasswordDialog.fields.email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            variant="outlined"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            {...register("password", {
              required: t("commons.validation.required")
            })}
            name="password"
            label={t("components.userSetPasswordDialog.fields.password")}
            error={!!errors.password}
            helperText={errors.password?.message}
            variant="outlined"
            required
            type={showPassword ? undefined : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            inputProps={{
              autoComplete: "new-password"
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            {...register("passwordConfirm", {
              required: t("commons.validation.required"),
              validate: val =>
                val === watch("password") || t("components.userSetPasswordDialog.validation.passwordMustBeEqual")
            })}
            name="passwordConfirm"
            label={t("components.userSetPasswordDialog.fields.passwordConfirm")}
            error={!!errors.passwordConfirm}
            helperText={errors.passwordConfirm?.message}
            variant="outlined"
            required
            type={showPasswordConfirm ? undefined : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                    {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            inputProps={{
              autoComplete: "new-password"
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
});

const mapStateToProps = state => ({
  userErrors: state.usersConfig.userErrors
});

const mapDispatchToProps = dispatch => ({
  sendConfig: (token, config) => dispatch(setUserPassword(config.email, token, config.password))
});

const UserSetPasswordForm = ({token, sendConfig, userErrors}, ref) => {
  return (
    <Fragment>
      <UserErrors errors={userErrors} />
      <Form ref={ref} onSubmit={config => sendConfig(token, config)} />
    </Fragment>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(UserSetPasswordForm);
