import React, {Fragment, useCallback, useMemo, useState} from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {Box, Button, Dialog, DialogActions, DialogContent, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useSelector} from "react-redux";
import {DatasetData} from "../../model/IDatasetData";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import AttributeList from "../attribute-list";
import ChartZoomIn from "../chart/ChartZoomIn";
import ChartZoomOut from "../chart/ChartZoomOut";
import ChartZoomReset from "../chart/ChartZoomReset";
import {
  CHART_TYPE_AREA,
  CHART_TYPE_BAR,
  CHART_TYPE_HORIZONTAL_BAR,
  CHART_TYPE_LINE,
  CHART_TYPE_PYRAMID
} from "../chart/constants";
import CustomDialogTitle from "../custom-dialog-title";
import AttributeIcon from "../custom-icons/AttributeIcon";
import {getViewerIdxFromType} from "../data-viewer/constant";
import DatasetFiltersCodelistsWrapper from "../dataset-filters/DatasetFiltersCodelistsWrapper";
import DatasetFiltersJsonStatWrapper from "../dataset-filters/DatasetFiltersJsonStatWrapper";
import DatasetStaticFiltersCodelistsWrapper from "../dataset-static-filters/DatasetStaticFiltersCodelistsWrapper";
import DatasetStaticFiltersJsonStatWrapper from "../dataset-static-filters/DatasetStaticFiltersJsonStatWrapper";
import ExportButtonJsonStatWrapper from "../export-button/ExportButtonJsonStatWrapper";
import {useFullscreen} from "./FullscreenContext";
import {usePreview} from "./PreviewContext";
import {useDownload} from "./useDownload";
import {modulesConfigSelector} from "../../state/app/appSelectors";
import useLanguages from "../../state/hooks/useLanguages";
import {hubNodesSelector} from "../../state/hub/hubSelectors";
import {DASHBOARD_ELEM_ENABLE_FILTERS_KEY} from "../../utils/dashboards";
import {getDatasetAttributeMap, getSeriesAttributeMap} from "../../utils/dataset";
import themeConfig from "../../theme-config/config.json";

const ActiveFiltersComponent = ({
  isOptimized,
  view,
  itemContainerId,
  viewIdx,
  jsonStat,
  layoutObj,
  layout,
  filterTree,
  onFilterSet,
  nodeCode,
  filterDim,
  pageFilterValue,
  criteria,
  localizedTimePeriodFormatMapExternal
}) => (
  <Box
    id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__active-filters`}
    className={`itemContainer__view-container__header__active-filters`}
    sx={{paddingBottom: "8px"}}
  >
    {isOptimized ? (
      <DatasetFiltersCodelistsWrapper
        nodeCode={nodeCode}
        datasetId={view.datasetId}
        dimensions={view.dimensionValues}
        dimensionsInfo={view.dimensionsInfo}
        dimensionsHidden={pageFilterValue ? [filterDim] : []}
        codelistLists={view.codelistLists}
        codelistMaps={view.codelistMaps}
        codelistTrees={view.codelistTrees}
        criteria={criteria}
        timeDim={view.timeDim}
        layout={layout}
        jsonStat={jsonStat}
        labelFormat={layoutObj.labelFormat}
        onSelect={(dimension, value) => {
          onFilterSet({dimension, value});
        }}
        onCriteriaShow={null}
        limit={null}
        enclosingContainerId={null}
        localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
      />
    ) : filterTree ? (
      <DatasetFiltersJsonStatWrapper
        jsonStat={jsonStat}
        layout={layout}
        filterTree={filterTree}
        labelFormat={layoutObj.labelFormat}
        onSelect={(dimension, value) => {
          onFilterSet({dimension, value});
        }}
        timeDim={undefined}
        onCriteriaShow={undefined}
        enclosingContainerId={undefined}
        localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
      />
    ) : null}
  </Box>
);

const StaticFiltersComponent = ({
  isOptimized,
  view,
  itemContainerId,
  viewIdx,
  jsonStat,
  layoutObj,
  layout,
  staticFilters,
  nodeCode,
  localizedTimePeriodFormatMapExternal
}) => (
  <Box
    id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__static-filters`}
    className={`itemContainer_view-container__header__static-filters`}
    sx={{paddingBottom: "8px"}}
  >
    {!isOptimized ? (
      <DatasetStaticFiltersJsonStatWrapper
        jsonStat={jsonStat}
        layout={layout}
        labelFormat={layoutObj.labelFormat}
        isDimensionAllowed={dim => staticFilters.includes(dim)}
        localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
      />
    ) : (
      <DatasetStaticFiltersCodelistsWrapper
        nodeCode={nodeCode}
        datasetId={view.datasetId}
        dimensionsInfo={view.dimensionsInfo}
        timeDim={view.timeDim}
        codelistMaps={view.codelistMaps}
        jsonStat={jsonStat}
        layout={layout}
        labelFormat={layoutObj.labelFormat}
        isDimensionAllowed={dim => staticFilters.includes(dim)}
        localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
      />
    )}
  </Box>
);

const ControllersComponent = ({
  isOptimized,
  downloadFormats,
  itemContainerId,
  viewIdx,
  itemContainerElem,
  widgetId,
  viewerIdx,
  layoutObj,
  jsonStat,
  view,
  mapId,
  chartId,
  hideFullscreen,
  itemContainerType,
  chartRef,
  configs,
  localizedTimePeriodFormatMapExternal
}) => {
  const {isFullscreen, handleFullscreen} = useFullscreen();
  const {t, localizeI18nObj} = useLanguages();
  const theme = useTheme();
  const getDimensionsCombinationCount = useCallback(
    dimensions =>
      isOptimized
        ? 1
        : dimensions && jsonStat && (jsonStat?.id || []).length > 0
          ? dimensions.reduce((acc, dim) => acc * jsonStat.size[jsonStat.id.indexOf(dim)], 1)
          : 0,
    [jsonStat, isOptimized]
  );

  const handleDownload = useDownload({
    view,
    layoutObj,
    jsonStat,
    mapId,
    itemContainerType,
    itemId: itemContainerElem.itemId,
    widgetId,
    configs
  });

  const enableZoomButtons = type => {
    return (
      !themeConfig.enableOptimization &&
      (type === CHART_TYPE_BAR ||
        type === CHART_TYPE_HORIZONTAL_BAR ||
        type === CHART_TYPE_PYRAMID ||
        type === CHART_TYPE_LINE ||
        type === CHART_TYPE_AREA)
    );
  };

  const chartZoomReset = useCallback(() => {
    if (chartRef && chartRef.current) chartRef.current.zoomReset();
  }, [chartRef]);

  const chartZoomIn = useCallback(() => {
    if (chartRef && chartRef.current) chartRef.current.zoomIn();
  }, [chartRef]);

  const chartZoomOut = useCallback(() => {
    if (chartRef && chartRef.current) chartRef.current.zoomOut();
  }, [chartRef]);

  return (
    <Box
      id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__controllers`}
      className="itemContainer_view-container__header__controllers"
      sx={{
        position: "absolute",
        top: "16px",
        right: "24px",
        display: "flex",
        "& > *": {
          marginLeft: "4px"
        }
      }}
    >
      {enableZoomButtons(view.defaultView) && (
        <>
          <ChartZoomReset
            id={`itemContainer_${itemContainerId}_view_container_${viewIdx}_chart_zoom_menu_reset_zoom_btn`}
            onClick={chartZoomReset}
          />
          <ChartZoomOut
            id={`itemContainer_${itemContainerId}_view_container_${viewIdx}_chart_zoom_menu_zoom_out_btn`}
            onClick={chartZoomOut}
          />
          <ChartZoomIn
            id={`itemContainer_${itemContainerId}_view_container_${viewIdx}_chart_zoom_menu_zoom_in_btn`}
            onClick={chartZoomIn}
          />
        </>
      )}

      {!itemContainerElem.showTitle && (
        <AttributesToggle
          jsonStat={jsonStat}
          layoutObj={layoutObj}
          view={view}
          localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
        />
      )}
      {!hideFullscreen && (
        <Tooltip
          title={
            isFullscreen
              ? t("components.itemContainer.actions.fullscreen.exit")
              : t("components.itemContainer.actions.fullscreen.enter")
          }
        >
          <IconButton
            id={`itemContainer_${itemContainerId}_view_container_${viewIdx}_actions_fullscreen_btn`}
            aria-label={
              isFullscreen
                ? t("components.itemContainer.actions.fullscreen.exit")
                : t("components.itemContainer.actions.fullscreen.enter")
            }
            color="primary"
            onClick={handleFullscreen}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Tooltip>
      )}
      <ExportButtonJsonStatWrapper
        id={`itemContainer_${itemContainerId}_view_container_${viewIdx}_actions_export_btn`}
        formats={downloadFormats}
        jsonStat={jsonStat}
        viewerIdx={viewerIdx}
        isTableVisible={viewerIdx === 0}
        isMapVisible={viewerIdx === 1}
        isChartVisible={viewerIdx >= 2}
        tableLayout={layoutObj?.layout}
        mapId={mapId}
        mapContainerId={`itemContainer_${itemContainerId}__view-container__${viewIdx}__view`}
        mapLayout={layoutObj?.layout}
        mapSettings={layoutObj?.mapSettings}
        chartId={chartId}
        chartContainerId={`itemContainer_${itemContainerId}__view-container__${viewIdx}__view`}
        chartLayout={layoutObj?.layout}
        chartSettings={layoutObj?.chartSettings}
        labelFormat={layoutObj?.labelFormat}
        datasetTitle={localizeI18nObj(view?.title)}
        getDimensionsCombinationCount={getDimensionsCombinationCount}
        submitDownload={handleDownload}
        buttonStyle={{color: theme.palette.primary.main}}
        localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
      />
    </Box>
  );
};

const PlaceHolderComponent = ({itemContainerId, viewIdx}) => (
  <Box
    id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__placeholder`}
    className={`itemContainer_view-container__header__pleaceholder`}
    sx={{paddingBottom: "8px"}}
  />
);

const AttributesToggle = ({jsonStat, layoutObj, view, iconButtonSx = {}, localizedTimePeriodFormatMapExternal}) => {
  const {t} = useLanguages();
  const [isAttributesVisible, setAttributesVisibility] = useState(false);
  const datasetAttributes = useMemo(() => {
    return jsonStat && layoutObj && view?.datasetId ? getDatasetAttributeMap(jsonStat)?.[view.datasetId] || [] : [];
  }, [jsonStat, layoutObj, view?.datasetId]);

  const seriesAttributes = useMemo(() => {
    return jsonStat && layoutObj && view?.datasetId
      ? getSeriesAttributeMap(jsonStat, layoutObj.labelFormat, localizedTimePeriodFormatMapExternal)[view.datasetId] ||
          []
      : [];
  }, [jsonStat, layoutObj, view?.datasetId, localizedTimePeriodFormatMapExternal]);

  const showAttributeIcon = datasetAttributes.concat(seriesAttributes).length > 0;
  return (
    <>
      {showAttributeIcon && (
        <Tooltip title={t("components.itemContainer.actions.attributes")}>
          <IconButton
            aria-label={t("components.itemContainer.actions.attributes")}
            color="primary"
            onClick={() => setAttributesVisibility(true)}
            sx={iconButtonSx}
          >
            <AttributeIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog open={isAttributesVisible} fullWidth maxWidth="md" onClose={() => setAttributesVisibility(false)}>
        <CustomDialogTitle onClose={() => setAttributesVisibility(false)}>
          {t("components.itemContainer.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <AttributeList
            datasetAttributes={datasetAttributes}
            seriesAttributes={seriesAttributes}
            labelFormat={layoutObj.labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttributesVisibility(false)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TitleComponent = ({
  itemContainerId,
  viewIdx,
  view,
  jsonStat,
  layoutObj,
  defaultTitle,
  localizedTimePeriodFormatMapExternal
}: {
  itemContainerId: number;
  viewIdx: string;
  view: ViewTemplateDto;
  jsonStat: any;
  layoutObj: any;
  defaultTitle: string;
  localizedTimePeriodFormatMapExternal?: any;
}) => {
  const {localizeI18nObj, defaultLanguage} = useLanguages();

  const getViewTitle = (view: ViewTemplateDto) => {
    if (defaultLanguage in view.title) {
      return localizeI18nObj(view.title);
    }
    return defaultTitle;
  };

  return (
    <Box
      id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__title`}
      className="itemContainer_view-container__header__title"
      sx={{
        minHeight: "48px",
        paddingBottom: "8px",
        display: "flex",
        alignItems: "center",
        fontSize: "18px",
        "& > button": {
          marginLeft: "4px"
        }
      }}
    >
      {getViewTitle(view)}
      <AttributesToggle
        jsonStat={jsonStat}
        layoutObj={layoutObj}
        view={view}
        iconButtonSx={{
          "& text > tspan": {
            fontWeight: "normal !important"
          }
        }}
        localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
      />
    </Box>
  );
};

interface ViewHeadingProps {
  isOptimized: boolean;
  itemContainer: ItemContainerDto;
  item: ItemViewTemplateDto;
  view: ViewTemplateDto;
  viewIdx: string;
  defaultTitle: string;
  jsonStat: DatasetData;
  criteria: any;
  layout: any;
  layoutObj: any;
  mapId: string;
  chartId: string;
  chartRef: any;
  filterDim: string | null;
  pageFilterValue: string | null;
  onFilterSet: (filters: {dimension: string; value: any}) => void;
  downloadFormats: string[];

  filterTree?: any;
  timePeriodsByFreq?: any;
  localizedTimePeriodFormatMapExternal?: any;
}

const ViewItemHeading = ({
  isOptimized,
  itemContainer,
  item,
  view,
  viewIdx,
  defaultTitle,
  jsonStat,
  criteria,
  layout,
  layoutObj,
  onFilterSet,
  filterDim,
  pageFilterValue,
  downloadFormats,
  mapId,
  chartId,
  chartRef,

  filterTree,
  localizedTimePeriodFormatMapExternal
}: ViewHeadingProps) => {
  const {hideFullscreen} = usePreview();

  const modulesConfig = useSelector(modulesConfigSelector);
  const nodes = useSelector(hubNodesSelector);
  const nodeCode = useMemo(() => (nodes ?? []).find(({nodeId}) => nodeId === view.nodeId)?.code, [nodes, view.nodeId]);

  const staticFilters = useMemo(() => {
    if (layoutObj === null || layoutObj === undefined || jsonStat === null || jsonStat === undefined) {
      return [];
    } else {
      const staticFilters = [];

      if (isOptimized) {
        layout.filters.forEach(dim => {
          if (
            !item[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] ||
            view.codelistLists[dim].length === 1 ||
            (filterDim && dim === filterDim && pageFilterValue)
          ) {
            staticFilters.push(dim);
          }
        });
      } else {
        layout.filters.forEach(dim => {
          if (!item[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] || jsonStat.size[jsonStat.id.indexOf(dim)] === 1) {
            staticFilters.push(dim);
          }
        });
      }

      return staticFilters;
    }
  }, [item, jsonStat, layoutObj, layout, filterDim, pageFilterValue, isOptimized, view.codelistLists]);

  const viewerIdx = useMemo(() => getViewerIdxFromType(item.viewTemplate.defaultView), [item.viewTemplate.defaultView]);

  const controllerProps = {
    isOptimized: isOptimized,
    downloadFormats: downloadFormats,
    itemContainerId: item.itemContainerId,
    widgetId: itemContainer.identifier,
    viewIdx: viewIdx,
    itemContainerElem: item,
    viewerIdx: viewerIdx,
    layoutObj: layoutObj,
    jsonStat: jsonStat,
    view: item.viewTemplate,
    mapId: mapId,
    chartId: chartId,
    hideFullscreen: hideFullscreen,
    itemContainerType: itemContainer.type,
    chartRef: chartRef,
    configs: modulesConfig.configs,
    localizedTimePeriodFormatMapExternal: localizedTimePeriodFormatMapExternal
  };

  const staticFilterProps = {
    isOptimized: isOptimized,
    view: view,
    itemContainerId: item.itemContainerId,
    viewIdx: viewIdx,
    jsonStat: jsonStat,
    layoutObj: layoutObj,
    layout: layout,
    staticFilters: staticFilters,
    nodeCode: nodeCode,
    filterDim: filterDim,
    localizedTimePeriodFormatMapExternal: localizedTimePeriodFormatMapExternal
  };

  const activeFilterProps = {
    isOptimized: isOptimized,
    view: view,
    itemContainerId: item.itemContainerId,
    viewIdx: viewIdx,
    jsonStat: jsonStat,
    layoutObj: layoutObj,
    layout: layout,
    filterTree: filterTree,
    onFilterSet: onFilterSet,
    nodeCode: nodeCode,
    filterDim: filterDim,
    pageFilterValue: pageFilterValue,
    criteria: criteria,
    localizedTimePeriodFormatMapExternal: localizedTimePeriodFormatMapExternal
  };

  return (
    <div
      id={`itemContainer_${item.itemContainerId}__view-container__${viewIdx}__header`}
      className={`itemContainer_view-container__header`}
      style={{
        paddingBottom: item.showTitle ? 4 : 12
      }}
    >
      {item.showTitle ? (
        <Fragment>
          <TitleComponent
            itemContainerId={item.itemContainerId}
            defaultTitle={defaultTitle}
            viewIdx={viewIdx}
            view={item.viewTemplate}
            jsonStat={jsonStat}
            layoutObj={layoutObj}
            localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
          />
          <ControllersComponent {...controllerProps} />
          {staticFilters.length > 0 && <StaticFiltersComponent {...staticFilterProps} />}
          {item.enableFilters && <ActiveFiltersComponent {...activeFilterProps} />}
        </Fragment>
      ) : staticFilters.length > 0 ? (
        <Fragment>
          <StaticFiltersComponent {...staticFilterProps} />
          <ControllersComponent {...controllerProps} />
          {item.enableFilters && <ActiveFiltersComponent {...activeFilterProps} />}
        </Fragment>
      ) : item.enableFilters ? (
        <Fragment>
          <ActiveFiltersComponent {...activeFilterProps} />
          <ControllersComponent {...controllerProps} />
        </Fragment>
      ) : (
        <Fragment>
          <PlaceHolderComponent itemContainerId={item.itemContainerId} viewIdx={viewIdx} />
          <ControllersComponent {...controllerProps} />
        </Fragment>
      )}
    </div>
  );
};

export default ViewItemHeading;
