import React, {Fragment, useCallback, useState} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../custom-dialog-title";
import CustomEmpty from "../custom-empty";
import InfiniteScrollTable from "../infinite-scroll-table";
import {localizeI18nObj} from "../../utils/i18n";

const filterElementStyle = {
  display: "inline-block",
  verticalAlign: "middle"
};

const mapStateToProps = ({app, appConfig}) => ({
  hiddenDimensionValueLabels: (
    localizeI18nObj(appConfig.hiddenDimensionValueLabels, app.language, app.languages) ?? []
  ).map(label => label.toLowerCase())
});
const getKeyFromFiltersValue = (filters, filtersValue) => filters.map(dim => filtersValue[dim]).join("+");

const DatasetMergedFilters = ({
  t,
  layout,
  dimensions,
  combination,
  combinations,
  hiddenDimensionValueLabels,
  getDatasetLabel,
  getAttributeIcon,
  onChange
}) => {
  const [visible, setVisibility] = useState(false);

  const getTooltip = useCallback(
    (combination, row) => {
      return (
        <div>
          {getDatasetLabel && (
            <Box
              sx={{
                fontSize: "15px",
                marginBottom: "8px"
              }}
            >
              <b>{getDatasetLabel(row)}</b>
            </Box>
          )}
          {combination.map(({dimLabel, valueLabel}, idx) => (
            <Box key={idx} sx={{margin: "4px 0"}}>
              <b>{dimLabel}</b>: <i>{valueLabel}</i>
            </Box>
          ))}
        </div>
      );
    },
    [getDatasetLabel]
  );

  return (
    <Fragment>
      <Tooltip title={getTooltip(combination)}>
        <Box
          sx={{
            cursor: "pointer",
            padding: "4px",
            "&:hover": {
              background: "#f5f5f5"
            },
            display: "flex",
            alignItems: "center"
          }}
          role="button"
          onClick={() => setVisibility(true)}
        >
          <Box
            sx={{
              fontSize: "14px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
          >
            {combination.map(({dim, value, valueLabel}, idx, arr) =>
              !(hiddenDimensionValueLabels || []).includes(valueLabel.toLowerCase()) ? (
                <Box
                  component="span"
                  key={idx}
                  sx={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}
                >
                  <Box component="span" sx={filterElementStyle}>
                    <i>{valueLabel}</i>
                  </Box>
                  <Box component="span" sx={filterElementStyle}>
                    {getAttributeIcon(dim, value, true)}
                  </Box>
                  <Box component="span" sx={filterElementStyle}>
                    {idx < arr.length - 1 ? "," : ""}&nbsp;
                  </Box>
                </Box>
              ) : null
            )}
          </Box>
          <ArrowDropDownIcon
            sx={{
              display: "inline-block",
              verticalAlign: "middle",
              float: "right",
              color: "rgba(0, 0, 0, 0.54)"
            }}
          />
        </Box>
      </Tooltip>
      <Dialog open={visible} onClose={() => setVisibility(false)} fullWidth maxWidth="md">
        <CustomDialogTitle onClose={() => setVisibility(false)}>
          {t("components.datasetMergedFilters.dialog.title")}
        </CustomDialogTitle>
        <DialogContent>
          {!combinations ? (
            <CustomEmpty
              text={t("components.datasetMergedFilters.dialog.content.loading") + "..."}
              image={<CircularProgress />}
              style={{height: 400}}
            />
          ) : (
            <InfiniteScrollTable
              data={combinations}
              getRowKey={({filtersValue}) => getKeyFromFiltersValue(dimensions, filtersValue)}
              showHeader={false}
              columns={[
                {
                  title: "",
                  dataIndex: "values",
                  render: (values, row) =>
                    values.map(({dim, value, valueLabel}, idx, arr) => (
                      <Box
                        component="span"
                        key={idx}
                        sx={{
                          display: "inline-block",
                          verticalAlign: "middle"
                        }}
                      >
                        <Box component="span" sx={filterElementStyle}>
                          <i>{valueLabel}</i>
                        </Box>
                        <Box component="span" sx={filterElementStyle}>
                          {getAttributeIcon(dim, value, false, row)}
                        </Box>
                        <Box component="span" sx={filterElementStyle}>
                          {idx < arr.length - 1 ? "," : ""}&nbsp;
                        </Box>
                      </Box>
                    )),
                  renderText: values => values.map(({valueLabel}) => valueLabel).join(", "),
                  renderTooltip: (values, row) => getTooltip(values, row),
                  minWidth: 100
                }
              ]}
              onRowClick={({filtersValue}) => {
                onChange({
                  ...layout,
                  filtersValue: filtersValue
                });
                setVisibility(false);
              }}
              getRowStyle={({filtersValue}) => ({
                background:
                  getKeyFromFiltersValue(dimensions, filtersValue) ===
                  getKeyFromFiltersValue(dimensions, layout.filtersValue)
                    ? "#fff9e5"
                    : undefined
              })}
              height={400}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisibility(false)} color="primary">
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(connect(mapStateToProps), withTranslation())(DatasetMergedFilters);
