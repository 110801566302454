import React, {useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {getDatasetInternalUrl} from "../../links";
import {CategoryGroupModelView} from "../../model/item-containers-models/categoryGroupModelView";
import {CategoryModelView} from "../../model/item-containers-models/categoryModelView";
import {DatasetModelView} from "../../model/item-containers-models/datasetModelView";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import DatasetCard from "../results/DatasetCard";
import CatalogPortionPreview from "./CatalogPortionPreview";
import useLanguages from "../../state/hooks/useLanguages";
import {hubNodesSelector} from "../../state/hub/hubSelectors";
import {findSelectedCategory, getAgencyAndCategoryFromFullId} from "../../utils/catalog";
import themeConfig from "../../theme-config/config.json";

const isCategoryGroup = (v: CategoryModelView | CategoryGroupModelView): v is CategoryGroupModelView =>
  v.hasOwnProperty("categories");
const getChildrenCategories = (v: CategoryModelView | CategoryGroupModelView) =>
  isCategoryGroup(v) ? v.categories : v.childrenCategories;
const getDatasetIdentifiers = (v: CategoryModelView | CategoryGroupModelView) =>
  !isCategoryGroup(v) ? v.datasetIdentifiers : [];

const ItemContainerCategoriesList = ({item}: {item: ItemCategoryTemplateDto}) => {
  const {agencyId: selectedAgencyId, categoryId: selectedCategoryId} = getAgencyAndCategoryFromFullId(item.category);
  const agency = item.catalog.categoryGroups.find(agency => agency.id === selectedAgencyId);
  const category = findSelectedCategory(selectedCategoryId, agency);
  const childrenCategories = getChildrenCategories(category);
  const {t} = useLanguages();
  const [selectedCategory, setSelectedCategory] = useState<CategoryModelView>(childrenCategories[0]);

  if (item.enableCatalogPortion) {
    return (
      <>
        {selectedCategory !== undefined ? (
          <>
            <CatalogPortionPreview
              childrenCategories={childrenCategories}
              setInitialCategory={id => setSelectedCategory(childrenCategories.find(el => el.id === id))}
              partialPath={[selectedCategory]}
              {...themeConfig.CatalogPortionPreviewProps}
            />
            <Category
              item={item}
              path={[category.id, selectedCategory.id]}
              datasetMap={item.catalog.datasetMap}
              category={selectedCategory}
              level={0}
            />
          </>
        ) : (
          <Typography variant="h5">{t("components.itemContainer.categoryPreview.noChildren")}</Typography>
        )}
      </>
    );
  } else {
    return (
      <>
        <Category item={item} path={[category.id]} datasetMap={item.catalog.datasetMap} category={category} level={0} />
      </>
    );
  }
};

const Category = ({
  path,
  category,
  datasetMap,
  level,
  item
}: {
  path: string[];
  category: CategoryModelView | CategoryGroupModelView;
  datasetMap: {[key: string]: DatasetModelView};
  level: number;
  item: ItemCategoryTemplateDto;
}) => {
  return (
    <>
      {level !== 0 && (
        <Accordion
          style={{marginBottom: "10px", marginLeft: `${40 * (level - 1)}px`}}
          defaultExpanded={item.expandCategories}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" p={"16px 0"}>
              <FolderIcon sx={{marginRight: "8px"}} />
              <Typography className="itemContainer__category-item__category-label" variant="h5" style={{fontSize: 20}}>
                {category.label}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <DatasetList item={item} identifiers={getDatasetIdentifiers(category)} datasetMap={datasetMap} />
          </AccordionDetails>
        </Accordion>
      )}
      {getChildrenCategories(category)?.map(childCategory => (
        <Category
          item={item}
          key={childCategory.id}
          path={path.concat(childCategory.id)}
          category={childCategory}
          datasetMap={datasetMap}
          level={level + 1}
        />
      ))}
      {level === 0 && <DatasetList item={item} identifiers={getDatasetIdentifiers(category)} datasetMap={datasetMap} />}
    </>
  );
};

const DatasetList = ({
  identifiers = [],
  datasetMap,
  item
}: {
  identifiers: string[];
  datasetMap: {[key: string]: DatasetModelView};
  item: ItemCategoryTemplateDto;
}) => {
  const nodes = useSelector(hubNodesSelector);
  const nodeCode = nodes.find(n => n.nodeId === item.nodeId).code;
  return identifiers.length > 0 ? (
    <Grid container spacing={2} className="itemContainer__category-item__dataset-list">
      {identifiers.map(id => (
        <DatasetCard
          key={id}
          dataset={{
            identifier: id,
            ...datasetMap[id]
          }}
          xs={12}
          onClick={undefined}
          md={undefined}
          to={getDatasetInternalUrl(nodeCode, [], id, undefined)}
          nodeId={item.nodeId}
          isMultiCategorized={undefined}
          categoryItem={item}
          enableBrowseData
          enableMetadataAsButton
          enableSDMXDownload
        />
      ))}
    </Grid>
  ) : null;
};

export default ItemContainerCategoriesList;
