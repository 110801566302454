import React, {Fragment, useEffect, useMemo, useState} from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Call from "../../../hocs/call";
import AttributeList from "../../attribute-list";
import ButtonSelect from "../../button-select";
import CustomDialogTitle from "../../custom-dialog-title";
import AttributeIcon from "../../custom-icons/AttributeIcon";
import DatasetMetadataButton, {VIEW_MODE_ICON_BUTTON} from "../../dataset-metadata-button";
import I18nTextField from "../../i18n-text-field";
import ModulesPlaceholder from "../../modules-placeholder";
import Query from "../../query";
import DatasetViewerTools from "../DatasetViewerTools";
import DataViewerTimings from "../Timings";
import MultiViewerPlusTemplateBuilder from "./TemplateBuilder";
import {ViewerMode} from "../../../state/dataset/constants";
import {setDatasetViewerMode} from "../../../state/dataset/datasetActions";
import {
  fetchDatasetMVPSdmxQuery,
  hideDatasetMVPSdmxQuery,
  hideDatasetMVPViewError,
  hideDatasetMVPViewTemplate,
  showDatasetMVPSdmxQuery,
  showDatasetMVPViewTemplate,
  submitDatasetMVPViewTemplate
} from "../../../state/dataset/multi-viewer-plus/actions";
import {MERGED_DATASETS_TITLE_SEPARATOR} from "../../../utils/constants";
import {CRITERIA_FILTER_TYPE_PERIODS, getCriteriaArrayFromObject} from "../../../utils/criteria";
import {getDimensionLabelFromCombinations, getDimensionValueLabelFromCombinations} from "../../../utils/dataset";
import {DECIMAL_SEPARATOR_DEFAULT, ROUNDING_STRATEGY} from "../../../utils/formatters";
import {validateI18nObj} from "../../../utils/i18n";
import {
  canSaveAsView,
  canSaveTemplate,
  canUseDatasetTools,
  canViewTemplateOrAnnotationIcon,
  canViewTimesLog
} from "../../../utils/user";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

const $ = window.jQuery;

const mapStateToProps = ({app, user, hub, dataset}) => ({
  themeConfig: app.themeConfig,
  defaultLanguage: app.language,
  user: user,
  dimensionLabels: dataset.multiViewerPlus.dimensionLabels,
  dimensionMap: dataset.multiViewerPlus.dimensionMap,
  tableChartDataset: dataset.multiViewerPlus.tableChartDataset,
  timeDim: dataset.multiViewerPlus.timeDim,
  datasetAttributes: dataset.multiViewerPlus.datasetAttributes,
  seriesAttributeMap: dataset.multiViewerPlus.seriesAttributeMap,
  isTableVisible: dataset.multiViewerPlus.isTableVisible,
  isMapVisible: dataset.multiViewerPlus.isMapVisible,
  isChartVisible: dataset.multiViewerPlus.isChartVisible,
  chartType: dataset.multiViewerPlus.chartType,
  view: dataset.multiViewerPlus.view,
  template: dataset.multiViewerPlus.template,
  hasViewLayout: dataset.multiViewerPlus.hasViewLayout,
  hasTemplateLayout: dataset.multiViewerPlus.hasTemplateLayout,
  hasAnnotationLayout: dataset.multiViewerPlus.hasAnnotationLayout,
  tableLayout: dataset.multiViewerPlus.tableLayout,
  mapLayout: dataset.multiViewerPlus.mapLayout,
  chartLayout: dataset.multiViewerPlus.chartLayout,
  labelFormat: dataset.multiViewerPlus.labelFormat,
  temporalDimOrder: dataset.multiViewerPlus.temporalDimOrder,
  criteria: dataset.multiViewerPlus.criteria,
  decimalSeparator: dataset.multiViewerPlus.decimalSeparator,
  roundingStrategy: dataset.multiViewerPlus.roundingStrategy,
  decimalPlaces: dataset.multiViewerPlus.decimalPlaces,
  tableEmptyChar: dataset.multiViewerPlus.tableEmptyChar,
  chartSettings: dataset.multiViewerPlus.chartSettings,
  mapSettings: dataset.multiViewerPlus.mapSettings,
  timings: dataset.multiViewerPlus.timings,
  isViewVisible: dataset.multiViewerPlus.isViewVisible,
  isViewErrorVisible: dataset.multiViewerPlus.isViewErrorVisible,
  viewErrorMessage: dataset.multiViewerPlus.viewErrorMessage,
  isTemplateVisible: dataset.multiViewerPlus.isTemplateVisible,
  isQueryVisible: dataset.multiViewerPlus.isQueryVisible,
  structureQuery: dataset.multiViewerPlus.structureQuery,
  dataQuery: dataset.multiViewerPlus.dataQuery,
  detailLevel: dataset.multiViewerPlus.detailLevel,
  territories: dataset.multiViewerPlus.territories,
  allTerritoriesSelected: dataset.multiViewerPlus.allTerritoriesSelected,
  territorialClassificationsConfig: dataset.multiViewerPlus.territorialClassificationsConfig,
  indicators: dataset.multiViewerPlus.indicators,
  showArithmeticMean: dataset.multiViewerPlus.showArithmeticMean,
  showStandardDeviation: dataset.multiViewerPlus.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewerPlus.showCoefficientOfVariation,
  additionalDatasets: dataset.multiViewerPlus.additionalDatasets
});

const mapDispatchToProps = dispatch => ({
  onViewTemplateShow: isView => dispatch(showDatasetMVPViewTemplate(isView)),
  onViewTemplateHide: isView => dispatch(hideDatasetMVPViewTemplate(isView)),
  onViewTemplateSubmit: (nodeId, viewTemplate, isView) =>
    dispatch(submitDatasetMVPViewTemplate(nodeId, viewTemplate, isView)),
  onViewErrorHide: isView => dispatch(hideDatasetMVPViewError(isView)),
  onQueryShow: () => dispatch(showDatasetMVPSdmxQuery()),
  onQueryHide: () => dispatch(hideDatasetMVPSdmxQuery()),
  fetchQuery: ({nodeId, datasetId, criteria, datasetTitle}) =>
    dispatch(fetchDatasetMVPSdmxQuery(nodeId, datasetId, criteria, datasetTitle)),
  setViewerMode: viewerMode => dispatch(setDatasetViewerMode(viewerMode))
});

function MultiViewerPlusHeader({...props}) {
  const {
    nodeId,
    nodeExtras,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    onRender,
    datasetMap,

    themeConfig,
    defaultLanguage,
    user,
    dimensionLabels,
    dimensionMap,
    tableChartDataset,
    timeDim,
    datasetAttributes,
    seriesAttributeMap,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    chartType,
    view,
    template,
    hasViewLayout,
    hasTemplateLayout,
    hasAnnotationLayout,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    temporalDimOrder,
    criteria,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    timings,
    isViewVisible,
    isViewErrorVisible,
    viewErrorMessage,
    isTemplateVisible,
    isQueryVisible,
    structureQuery,
    dataQuery,
    detailLevel,
    territories,
    allTerritoriesSelected,
    territorialClassificationsConfig,
    indicators,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    additionalDatasets,

    onViewTemplateShow,
    onViewTemplateHide,
    onViewTemplateSubmit,
    onViewErrorHide,
    onQueryShow,
    onQueryHide,
    fetchQuery,
    setViewerMode
  } = props;
  const showQueryInfo = nodeExtras?.QueryInfo;

  const {t} = useTranslation();

  const [tempView, setTempView] = useState(null);
  const [tempTemplate, setTempTemplate] = useState(null);

  const notes = useMemo(() => datasetMap[datasetId]?.notes ?? "", [datasetMap, datasetId]);
  const [notesVisibility, setNotesVisibility] = useState(false);

  const metadataUrl = useMemo(() => datasetMap[datasetId]?.referenceMetadata ?? null, [datasetMap, datasetId]);

  const seriesAttributes = useMemo(
    () =>
      (tableChartDataset?.seriesAttributes ?? []).map(({dimensions, attributes}) => ({
        dimensions: (dimensions || []).map(({id, value}) => ({
          id: getDimensionLabelFromCombinations(id, dimensionLabels),
          value: getDimensionValueLabelFromCombinations(id, value, dimensionMap)
        })),
        attributes: (attributes || []).map(({id, value}) => ({
          id: id,
          label: seriesAttributeMap?.[id]?.label,
          value: value,
          valueLabel: seriesAttributeMap?.[id]?.values?.[value]
        }))
      })),
    [dimensionLabels, dimensionMap, tableChartDataset, seriesAttributeMap]
  );
  const [attributesVisiblity, setAttributesVisibility] = useState(false);

  const [isTimingsVisible, setTimingsVisibility] = useState(false);

  const [isUpdatingView, setIsUpdatingView] = useState(false);

  useEffect(() => {
    const headerExtraActionsWidth = $("#data-viewer__header__extra-actions").outerWidth(true);
    $("#data-viewer__header__title-actions-container").width(`calc(100% - ${headerExtraActionsWidth}px)`);
  }, []);

  useEffect(() => {
    const actionsWidth = $("#data-viewer__header__actions").outerWidth(true);
    $("#data-viewer__header__title").width(`calc(100% - ${actionsWidth}px - 8px)`);
  });

  useEffect(() => {
    if (onRender) {
      onRender();
    }
  });

  const getCompleteViewTemplate = (viewTemplate, isUpdating) => ({
    defaultView: "table",
    enableCriteria: true,
    enableLayout: true,
    enableVariation: false,
    hiddenDimensions: [],
    ...viewTemplate,
    datasetId: datasetId,
    title: isUpdating
      ? viewTemplate.title
      : {
          [defaultLanguage]: [datasetTitle, ...additionalDatasets.map(({datasetTitle}) => datasetTitle)].join(
            ` ${MERGED_DATASETS_TITLE_SEPARATOR} `
          )
        },
    mode: ViewerMode.MultiViewer,
    criteria: getCriteriaArrayFromObject(criteria),
    layouts: {
      ...viewTemplate?.layouts,
      detailLevel: detailLevel,
      mapDetailLevel: undefined,
      territories: territories,
      allTerritoriesSelected: allTerritoriesSelected,
      territorialClassificationsConfig: territorialClassificationsConfig,
      labelFormat: labelFormat,
      temporalDimOrder: temporalDimOrder,
      isTableVisible: isTableVisible,
      isMapVisible: isMapVisible,
      isChartVisible: isChartVisible,
      chartType: chartType,
      mapBaseLayer: mapSettings?.baseLayer ? mapSettings.baseLayer : null
    },
    decimalSeparator: {
      ...(viewTemplate?.decimalSeparator || {}),
      [defaultLanguage]:
        decimalSeparator !== null && decimalSeparator !== undefined ? decimalSeparator : DECIMAL_SEPARATOR_DEFAULT
    },
    roundingStrategy: roundingStrategy || ROUNDING_STRATEGY,
    decimalNumber: decimalPlaces === null || decimalPlaces === undefined || decimalPlaces < 0 ? "" : decimalPlaces
  });

  const handleViewOpen = isUpdating => {
    setIsUpdatingView(isUpdating);
    onViewTemplateShow(true);
    const completeView = getCompleteViewTemplate(view, isUpdating);
    setTempView({
      ...completeView,
      type: "view",
      layouts: {
        ...completeView.layouts,
        tableLayout: tableLayout,
        tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
        mapLayout: mapLayout,
        ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {}),
        showArithmeticMean: showArithmeticMean,
        showStandardDeviation: showStandardDeviation,
        showCoefficientOfVariation: showCoefficientOfVariation
      },
      indicatorsDefinition: JSON.stringify(indicators),
      additionalDatasets: JSON.stringify(additionalDatasets)
    });
  };

  const handleViewClose = () => {
    onViewTemplateHide(true);
    setTempView(null);
  };

  const handleViewSubmit = viewId => {
    setTempView(prevVal => ({
      ...prevVal,
      decimalSeparator: undefined,
      roundingStrategy: undefined,
      decimalNumber: undefined
    }));
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempView,
        viewTemplateId: viewId ? Number(viewId) : undefined,
        layouts: JSON.stringify(tempView.layouts)
      },
      true
    );
  };

  const handleTemplateOpen = isUpdating => {
    onViewTemplateShow(false);
    const completeTemplate = getCompleteViewTemplate(template, isUpdating);
    setTempTemplate({
      ...completeTemplate,
      type: "template",
      layouts: {
        ...completeTemplate.layouts,
        tableDefaultLayout: "custom",
        tableLayout: tableLayout,
        tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
        mapLayout: mapLayout,
        ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {})
      }
    });
  };

  const handleTemplateClose = () => {
    onViewTemplateHide(false);
    setTempTemplate(null);
  };

  const handleTemplateSubmit = () => {
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempTemplate,
        layouts: JSON.stringify({
          ...tempTemplate.layouts,
          tableDefaultLayout: undefined
        }),
        roundingStrategy: tempTemplate.roundingStrategy === "inherit" ? null : tempTemplate.roundingStrategy,
        decimalNumber:
          tempTemplate.decimalNumber === null ||
          tempTemplate.decimalNumber === undefined ||
          tempTemplate.decimalNumber === ""
            ? -1
            : tempTemplate.decimalNumber
      },
      false
    );
  };

  const handleNotesShow = () => {
    setNotesVisibility(true);
  };

  const handleNotesHide = () => {
    setNotesVisibility(false);
  };

  const handleAttributesShow = () => {
    setAttributesVisibility(true);
  };

  const handleAttributesHide = () => {
    setAttributesVisibility(false);
  };

  const handleTimingsShow = () => {
    setTimingsVisibility(true);
  };

  const handleTimingsHide = () => {
    setTimingsVisibility(false);
  };

  const isValidTemplateDecimalNumber =
    (tempTemplate?.decimalNumber || "").length === 0 ||
    isValidIntegerInInclusiveRange(tempTemplate?.decimalNumber, 0, 20);

  const isValidTemplate =
    validateI18nObj(tempTemplate?.title) &&
    validateI18nObj(tempTemplate?.decimalSeparator) &&
    isValidTemplateDecimalNumber;

  return (
    <Fragment>
      <Box
        id="data-viewer__header__title-actions-container"
        sx={{
          color: theme => theme.palette.primary.main,
          display: "inline-block",
          verticalAlign: "top"
        }}
      >
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid
            item
            id="data-viewer__header__title"
            sx={{
              paddingTop: "7px",
              minHeight: "48px",
              "& > h1:first-child": {
                marginLeft: "0px"
              },
              "& > h1:last-child": {
                marginRight: "0px"
              }
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                display: "unset",
                fontSize: "28px",
                fontWeight: "300px",
                letterSpacing: "0px",
                margin: "0 4px",
                color: theme => theme.palette.text.primary
              }}
            >
              {datasetTitle}
            </Typography>
            {notes.length > 0 && (
              <Tooltip title={t("scenes.dataViewer.header.action.information.tooltip")}>
                <IconButton
                  id="dataset-notes-btn"
                  aria-label={t("scenes.dataViewer.header.action.information.label")}
                  onClick={handleNotesShow}
                  sx={{marginTop: "-10px"}}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            {(datasetAttributes || []).concat(seriesAttributes).length > 0 && (
              <Tooltip title={t("scenes.dataViewer.header.action.attributes.tooltip")}>
                <IconButton
                  id="dataset-attributes-btn"
                  aria-label={t("scenes.dataViewer.header.action.attributes.label")}
                  onClick={handleAttributesShow}
                  sx={{marginTop: "-10px"}}
                >
                  <AttributeIcon />
                </IconButton>
              </Tooltip>
            )}
            {metadataUrl !== null && (
              <DatasetMetadataButton
                viewMode={VIEW_MODE_ICON_BUTTON}
                metadataUrl={metadataUrl}
                datasetId={datasetId}
                nodeId={nodeId}
                sx={{marginTop: "-10px"}}
              />
            )}
          </Grid>
          <Grid item id="data-viewer__header__actions">
            <Grid container alignItems="center">
              {(() => {
                if (canViewTemplateOrAnnotationIcon(user, nodeId)) {
                  let title = null;
                  if (hasViewLayout) {
                    title = t("scenes.dataViewer.header.action.hasViewLayout.tooltip");
                  } else if (hasTemplateLayout) {
                    title = t("scenes.dataViewer.header.action.hasTemplateLayout.tooltip");
                  } else if (hasAnnotationLayout) {
                    title = t("scenes.dataViewer.header.action.hasAnnotationLayout.tooltip");
                  }

                  if (title) {
                    return (
                      <Grid
                        item
                        id="dataset-layout-info-btn"
                        sx={{
                          width: "40px",
                          height: "40px",
                          padding: "8px",
                          color: "rgba(0, 0, 0, 0.54)"
                        }}
                      >
                        <Tooltip id="data_viewer_multi_viewer_plus_header_info_btn" title={title}>
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                    );
                  }
                }
              })()}
              {canViewTimesLog(user, nodeId) && (
                <Grid item id="dataset-times-log-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.timesLog.tooltip")}>
                    <div>
                      <IconButton
                        id="data_viewer_multi_viewer_plus_header_times_log_btn"
                        aria-label={t("scenes.dataViewer.header.action.timesLog.label")}
                        onClick={handleTimingsShow}
                        disabled={timings === null}
                      >
                        <AccessTimeIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
              {additionalDatasets.length === 0 && canUseDatasetTools(user) && (
                <Grid item id="dataset-cache-template-btn">
                  <DatasetViewerTools
                    id="data_viewer_multi_viewer_plus_header_cache_template_btn"
                    templateId={template?.viewTemplateId || null}
                  />
                </Grid>
              )}
              {(canSaveAsView(user) || canSaveTemplate(user, nodeId)) && (
                <Grid item id="dataset-save-btn">
                  <ButtonSelect
                    id="data_viewer_multi_viewer_plus_header_dataset_save_btn"
                    icon={<SaveIcon />}
                    ariaLabel={t("scenes.dataViewer.header.action.save.label")}
                    tooltip={t("scenes.dataViewer.header.action.save.tooltip")}
                    color="default"
                    disabled={!tableLayout && !chartLayout && !mapLayout}
                  >
                    {canSaveAsView(user) && (
                      <div onClick={() => handleViewOpen(false)}>
                        {t("scenes.dataViewer.header.action.save.values.createView")}
                      </div>
                    )}
                    {canSaveAsView(user) && hasViewLayout && viewId && (
                      <div onClick={() => handleViewOpen(true)}>
                        {t("scenes.dataViewer.header.action.save.values.updateView")}
                      </div>
                    )}
                    {indicators.length === 0 && additionalDatasets.length === 0 && canSaveTemplate(user, nodeId) && (
                      <div onClick={() => handleTemplateOpen(hasTemplateLayout)}>
                        {hasTemplateLayout
                          ? t("scenes.dataViewer.header.action.save.values.updateTemplate")
                          : t("scenes.dataViewer.header.action.save.values.createTemplate")}
                      </div>
                    )}
                  </ButtonSelect>
                </Grid>
              )}
              <ModulesPlaceholder
                id="data-viewer-header-button"
                viewTemplate={{
                  ...getCompleteViewTemplate(view, false),
                  type: "view",
                  layouts: JSON.stringify({
                    ...getCompleteViewTemplate(view, false).layouts,
                    tableLayout: tableLayout,
                    tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
                    mapLayout: mapLayout,
                    ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
                    chartLayout: chartLayout,
                    ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {}),
                    showArithmeticMean: showArithmeticMean,
                    showStandardDeviation: showStandardDeviation,
                    showCoefficientOfVariation: showCoefficientOfVariation
                  }),
                  nodeId,
                  indicatorsDefinition: JSON.stringify(indicators),
                  additionalDatasets: JSON.stringify(additionalDatasets)
                }}
                sx={{
                  width: "40px",
                  height: "40px"
                }}
              />
              {(attachedFiles || []).length > 0 && (
                <Grid item id="dataset-attachments-btn">
                  <ButtonSelect
                    id="data_viewer_multi_viewer_plus_header_dataset_attachments_btn"
                    icon={<AttachFileIcon />}
                    ariaLabel={t("scenes.dataViewer.header.action.attachments.label")}
                    tooltip={t("scenes.dataViewer.header.action.attachments.tooltip")}
                    color="default"
                    onChange={({attachedFile}) => window.open(attachedFile.url)}
                  >
                    {attachedFiles.map((attachedFile, idx) => {
                      const fileName = attachedFile.url.split("/").pop();
                      return (
                        <Tooltip title={attachedFile.description} key={idx} data-value={{attachedFile}}>
                          <div>{`${fileName} (${attachedFile.format})`}</div>
                        </Tooltip>
                      );
                    })}
                  </ButtonSelect>
                </Grid>
              )}
              {showQueryInfo && (
                <Grid item id="dataset-query-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.query.tooltip")}>
                    <div>
                      <IconButton
                        id="data_viewer_multi_viewer_plus_header_dataset_query_btn"
                        aria-label={t("scenes.dataViewer.header.action.query.label")}
                        onClick={onQueryShow}
                      >
                        <DeveloperModeIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        id="data-viewer__header__extra-actions"
        sx={{
          display: "inline-block",
          verticalAlign: "top"
        }}
      >
        {themeConfig.isMultiViewer && (
          <Box
            id="dataset-viewer-mode-btn"
            sx={{
              display: "none",
              width: "48px"
            }}
          >
            <ButtonSelect
              id="data_viewer_multi_viewer_plus_header_dataset_viewer_mode_btn"
              icon={<HelpIcon />}
              ariaLabel={t("scenes.dataViewer.header.action.viewerMode.label")}
              tooltip={t("scenes.dataViewer.header.action.viewerMode.tooltip")}
              color="default"
              onChange={({mode}) => setViewerMode(mode)}
            >
              <div data-value={{mode: ViewerMode.SingleViewer}}>{ViewerMode.SingleViewer}</div>
              <div data-value={{mode: ViewerMode.MultiViewer}}>{ViewerMode.MultiViewer}</div>
            </ButtonSelect>
          </Box>
        )}
      </Box>

      <Dialog open={attributesVisiblity} fullWidth maxWidth="md" onClose={handleAttributesHide}>
        <CustomDialogTitle onClose={handleAttributesHide}>
          {t("scenes.dataViewer.header.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <AttributeList
            datasetAttributes={datasetAttributes}
            seriesAttributes={seriesAttributes}
            labelFormat={labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAttributesHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isTimingsVisible} onClose={handleTimingsHide}>
        <DialogContent style={{width: 400}}>
          <DataViewerTimings timings={timings} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleTimingsHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isViewVisible} maxWidth="sm" fullWidth onClose={handleViewClose}>
        <CustomDialogTitle onClose={handleViewClose}>
          {isUpdatingView
            ? t("scenes.dataViewer.header.dialogs.view.title.update")
            : t("scenes.dataViewer.header.dialogs.view.title.create")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS && (
              <Grid item xs={12}>
                <Alert severity="warning">{t("scenes.dataViewer.warnings.viewTemplateLastNPeriods.label")}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <I18nTextField
                  label={t("scenes.dataViewer.header.dialogs.view.form.title.label")}
                  required
                  variant="outlined"
                  value={tempView?.title || ""}
                  onChange={value => setTempView({...tempView, title: value})}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose}>{t("commons.confirm.cancel")}</Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => handleViewSubmit(isUpdatingView ? viewId : null)}
            disabled={!tempView || !validateI18nObj(tempView.title)}
          >
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isViewErrorVisible} maxWidth="md" onClose={onViewErrorHide}>
        <CustomDialogTitle onClose={onViewErrorHide}>
          {t("scenes.dataViewer.header.dialogs.duplicateViewError.title")}
        </CustomDialogTitle>
        <DialogContent>
          {viewErrorMessage && (
            <Fragment>
              {t("scenes.dataViewer.header.dialogs.duplicateViewError.content") + ": "}
              <b>{Object.keys(viewErrorMessage).join(", ")}</b>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onViewErrorHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isTemplateVisible} maxWidth="sm" fullWidth onClose={handleTemplateClose}>
        <CustomDialogTitle onClose={handleTemplateClose}>
          {hasTemplateLayout
            ? t("scenes.dataViewer.header.dialogs.template.title.update")
            : t("scenes.dataViewer.header.dialogs.template.title.create")}
        </CustomDialogTitle>
        <DialogContent>
          <MultiViewerPlusTemplateBuilder
            template={tempTemplate}
            onChange={setTempTemplate}
            criteria={criteria}
            timeDim={timeDim}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTemplateClose}>{t("commons.confirm.cancel")}</Button>
          <Button color="primary" onClick={handleTemplateSubmit} disabled={!isValidTemplate}>
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={notesVisibility} fullWidth maxWidth="md" onClose={handleNotesHide}>
        <CustomDialogTitle onClose={handleNotesHide}>
          {t("scenes.dataViewer.header.dialogs.notes.title")}
        </CustomDialogTitle>
        <DialogContent>{notes}</DialogContent>
        <DialogActions>
          <Button onClick={handleNotesHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isQueryVisible} fullWidth maxWidth="md" onClose={onQueryHide}>
        <CustomDialogTitle onClose={onQueryHide}>{t("scenes.dataViewer.header.dialogs.query.title")}</CustomDialogTitle>
        <DialogContent
          sx={theme => ({
            "& > *": {
              marginBottom: theme.spacing(2)
            },
            "& > *:last-child": {
              marginBottom: "0px"
            }
          })}
        >
          <Call
            cb={fetchQuery}
            cbParam={{nodeId, datasetId, criteria, datasetTitle}}
            disabled={structureQuery !== null && dataQuery !== null}
          >
            {structureQuery && (
              <Query
                title={t("scenes.dataViewer.header.dialogs.query.content.structureQuery.title")}
                query={structureQuery}
              />
            )}
            {dataQuery && (
              <Query title={t("scenes.dataViewer.header.dialogs.query.content.dataQuery.title")} query={dataQuery} />
            )}
          </Call>
        </DialogContent>
        <DialogActions>
          <Button onClick={onQueryHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewerPlusHeader);
