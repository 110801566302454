import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import Map from "./Map";
import {getLayoutGeometriesFromCombinations} from "../utils";

const MapJsonStatWrapper = ({
  dimensionIds,
  dimensionMap,
  territoryDim,
  territories,
  seriesAttributeMap,
  observationAttributeMap,
  layout,
  data,
  ...props
}) => {
  const {t} = useTranslation();

  const getLayoutGeometries = useCallback(
    (fetchedGeometries, allGeometriesMap) => {
      return getLayoutGeometriesFromCombinations(
        fetchedGeometries,
        allGeometriesMap,
        dimensionIds,
        dimensionMap,
        territoryDim,
        territories,
        data,
        seriesAttributeMap,
        observationAttributeMap,
        layout,
        t
      );
    },
    [
      dimensionIds,
      dimensionMap,
      territoryDim,
      territories,
      data,
      seriesAttributeMap,
      observationAttributeMap,
      layout,
      t
    ]
  );

  const getObservationValue = useCallback(
    territoryId => {
      const valueKey = dimensionIds
        .map(dim => (dim === territoryDim ? territoryId : layout.filtersValue[dim]))
        .join("+");
      return data.values[valueKey];
    },
    [dimensionIds, territoryDim, layout, data]
  );

  return (
    <Map
      {...props}
      dimensionIds={dimensionIds}
      territoryDim={territoryDim}
      territories={territories}
      layout={layout}
      data={data}
      getLayoutGeometries={getLayoutGeometries}
      getObservationValue={getObservationValue}
    />
  );
};

export default MapJsonStatWrapper;
