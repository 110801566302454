import {Box, Grid, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {getHomeInternalUrl} from "../../links";

const HubLogo = ({
  hub,
  headerLogoTitle,
  headerLogoHref,
  baseURL,
  headerLogoAlt,
  handleStyle,
  headerLogoSmallURL,
  isCollapsed,
  sx
}) => {
  if ((!isCollapsed && hub.headerLogoURL) || (isCollapsed && headerLogoSmallURL)) {
    const img = (
      <img
        src={baseURL + (!isCollapsed ? hub.headerLogoURL : headerLogoSmallURL)}
        alt={headerLogoAlt}
        onLoad={handleStyle}
      />
    );

    return (
      <Grid item className="hub-logo" sx={sx}>
        <Tooltip title={headerLogoTitle}>
          {headerLogoHref.length > 0 ? (
            <a href={headerLogoHref} target="_blank" rel="noopener noreferrer">
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  "& img": {
                    height: "32px"
                  }
                }}
              >
                {img}
              </Box>
            </a>
          ) : (
            <Link to={getHomeInternalUrl()}>
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  "& img": {
                    height: "32px"
                  }
                }}
              >
                {img}
              </Box>
            </Link>
          )}
        </Tooltip>
      </Grid>
    );
  } else {
    return null;
  }
};

export default HubLogo;
