import {Grid} from "@mui/material";
import {canDisplayNewsManagerForm} from "../../modules/news/constants";
import SettingsSelect from "../settings-select";
import {
  canClearServerCache,
  canDisplayAppSettingsForm,
  canDisplayNodesSettingsForm,
  canDisplayUsersSettingsForm,
  canGetQueryLog
} from "../../utils/user";

const AdminSettingButton = ({user, nodes}) => {
  if (
    canDisplayAppSettingsForm(user) ||
    canDisplayUsersSettingsForm(user) ||
    canDisplayNodesSettingsForm(user) ||
    canGetQueryLog(user) ||
    canClearServerCache(user) ||
    canDisplayNewsManagerForm(user)
  ) {
    return (
      <Grid item className="admin-settings-btn">
        <SettingsSelect nodes={nodes} />
      </Grid>
    );
  } else {
    return null;
  }
};

export default AdminSettingButton;
