import React, {useEffect, useState} from "react";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import FullscreenDialog from "../fullscreen-dialog";
import I18nHtmlEditor from "../i18n-html-editor";

export function TextEditingDialog({open, onClose, handleTextChange, initialValue}) {
  const [textValue, setTextValue] = useState(undefined);
  const {t} = useTranslation();

  useEffect(() => {
    setTextValue(initialValue);
  }, [initialValue]);

  return (
    <FullscreenDialog open={open} fullWidth disableEnforceFocus maxWidth="md" onClose={onClose}>
      <CustomDialogTitle onClose={onClose}>{t("components.itemContainerBuilder.modals.text.title")}</CustomDialogTitle>
      <DialogContent id="item-container__text-editing-dialog__content">
        <I18nHtmlEditor value={textValue} onChange={setTextValue} enableImageUpload enableSourceEditing />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
        <Button
          onClick={() => {
            handleTextChange(textValue);
            onClose();
          }}
        >
          {t("commons.confirm.confirm")}
        </Button>
      </DialogActions>
    </FullscreenDialog>
  );
}
