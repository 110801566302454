import _ from "lodash";
import {ICategoryProvider} from "../../../model/ICategoryProvider";
import {RoundingMode} from "../../../model/wshub-models/roundingMode";
import {ChartSettings, Criteria, MapSettings, ViewerMode} from "../constants";
import {AdditionalDataset, Indicator} from "../multi-viewer/constants";
import {getCriteriaArrayFromObject} from "../../../utils/criteria";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

export type DatasetMultiViewerPlusState = {
  datasetUuid: string | null;
  notCompatibleDataset: boolean;
  dimensions: any[] | null;
  dimensionLabels: {[key: string]: string};
  dimensionMap: any;
  territoryDimCodelist: string | null;
  territoryDim: string | null;
  timeDim: string | null;
  freqDim: string | null;
  colCount: number | null;
  colCountLimit: number | null;
  colCountLimitExceeded: number | null;
  isFetchingCols: boolean;
  cols: {[key: string]: string}[] | null;
  rowCount: number | null;
  rows: {[key: string]: string}[] | null;
  datasetAttributes: any;
  observationAttributeMap: any;
  seriesAttributeMap: any;
  tableFilters: any;
  tableChartDatasetFetchEnabled: boolean;
  tableChartDatasetFetchPending: boolean;
  tableChartDatasetFetchError: boolean;
  tableChartDataset: any | null;
  tableChartDatasetSize: number | null;
  mapFilters: string[] | null;
  mapDatasetFetchEnabled: boolean;
  mapDatasetFetchPending: boolean;
  mapDatasetFetchError: boolean;
  mapTerritories: string[] | null;
  mapDataset: any | null;
  isEmptyData: boolean;
  isTooBigData: boolean;
  isCriteriaVisible: boolean;
  isCriteriaAlertVisible: boolean;
  isObsCountWarningVisible: boolean;
  isTableVisible: boolean | null;
  isMapVisible: boolean | null;
  isChartVisible: boolean | null;
  chartType: string;
  hasViewLayout: boolean;
  view: any | null;
  hasTemplateLayout: boolean;
  template: any | null;
  tableLayout: any | null;
  mapLayout: any | null;
  chartLayout: any | null;
  labelFormat: string | null;
  temporalDimOrder: string | null;
  areCriteriaApplied: boolean;
  criteria: {[key: string]: Criteria} | {};
  initialCriteria: {[key: string]: Criteria} | {};
  decimalSeparator: string | null;
  roundingStrategy: RoundingMode | null;
  decimalPlaces: number | null;
  tableEmptyChar: string;
  chartSettings: ChartSettings;
  mapSettings: MapSettings;
  codelists: {[key: string]: any[]} | null;
  codelistsLength: (number | null)[] | null;
  codelistFetchError: boolean;
  criteriaObsCount: number | null;
  timings: any | null;
  isDownloadWarningVisible: boolean;
  isUnavailableViewWarningVisible: boolean;
  isViewVisible: boolean;
  isViewErrorVisible: boolean;
  viewErrorMessage: string | null;
  isTemplateVisible: boolean;
  isQueryVisible: boolean;
  structureQuery: string | null;
  dataQuery: string | null;
  isTerritoryVisible: boolean;
  detailLevelTree: any | null;
  detailLevel: number | null;
  territoryTree: any[] | null;
  territories: string[] | null;
  allTerritoriesSelected: boolean;
  lastTerritoryYear: string | null;
  territorialClassifications: any;
  territorialClassificationsConfig: any;
  territorialClassificationsFetched: number[];
  territorialClassificationsValues: any;
  isIndicatorCreateVisible: boolean;
  indicators: Indicator[];
  indicatorPreview: any;
  synthesisAndVariabilityMeasures: any;
  showArithmeticMean: boolean;
  showStandardDeviation: boolean;
  showCoefficientOfVariation: boolean;
  additionalDatasets: AdditionalDataset[];
  additionalDatasetCatalog: ICategoryProvider | null;
  additionalDataset: AdditionalDataset | null;
  datasetCacheInfo: any | null;
  missingFilterValues: any[] | null;
  geometriesYear: string | null;
  geometriesYears: {[key: string]: string}[] | null;
};

export const getViewTemplateBackupFromMVPState = (state: DatasetMultiViewerPlusState) => ({
  mode: ViewerMode.MultiViewer,
  criteria: getCriteriaArrayFromObject(state.criteria),
  layouts: JSON.stringify({
    detailLevel: state.detailLevel,
    territories: state.territories,
    territorialClassificationsConfig: state.territorialClassificationsConfig,
    tableLayout: state.tableLayout,
    mapLayout: state.mapLayout,
    ...getViewTemplateLayoutsFromMapSettings(state.mapSettings),
    chartLayout: state.chartLayout,
    ...getViewTemplateLayoutsFromChartSettings(state.chartSettings),
    isTableVisible: state.isTableVisible,
    isMapVisible: state.isMapVisible,
    isChartVisible: state.isChartVisible,
    chartType: state.chartType,
    showArithmeticMean: state.showArithmeticMean,
    showStandardDeviation: state.showStandardDeviation,
    showCoefficientOfVariation: state.showCoefficientOfVariation
  }),
  indicatorsDefinition: JSON.stringify(state.indicators),
  additionalDatasets: JSON.stringify(state.additionalDatasets)
});

export const getOptimizedDataPayload = (
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  columns?: string[],
  tableFilters?: any,
  mapFilters?: string[] | null
) => {
  const newCriteria = _.cloneDeep(criteria ?? {});
  if (mapFilters !== null && mapFilters !== undefined) {
    newCriteria[territoryDim] = {
      id: territoryDim,
      filterValues: mapFilters,
      type: "CodeValues"
    };
  }
  return {
    filters: getCriteriaArrayFromObject(newCriteria),
    columns: columns,
    territorialDimension: territoryDim,
    detailLevel: detailLevelNameIdentifier,
    allTerritoriesSelected: mapFilters !== null && mapFilters !== undefined ? false : allTerritoriesSelected,
    idTextFilter: tableFilters?.idTextFilter ?? undefined,
    labelTextFilter: tableFilters?.labelTextFilter ?? undefined,
    observationFilters: tableFilters?.observationFilters
      ? (Object.values(tableFilters.observationFilters) ?? []).filter(val => val)
      : undefined
  };
};
