import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import InfoIcon from "@mui/icons-material/Info";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import Call from "../../hocs/call";
import CustomDialogTitle from "../custom-dialog-title";
import CustomEmpty from "../custom-empty";
import InfiniteScrollTable from "../infinite-scroll-table";
import MaterialTree from "../material-tree";
import HierarchicalCodelistSelector from "./HierarchicalCodelistSelector";
import TimePeriod from "./TimePeriod";
import {CRITERIA_SELECTION_MODE_ALL, CRITERIA_SELECTION_TYPE_DYNAMIC, ViewerMode} from "../../state/dataset/constants";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_EMPTY,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE,
  CRITERIA_FILTER_TYPE_STRING,
  criteriaFilterTypes
} from "../../utils/criteria";
import {getFreqValueFromCriteria, getTimePeriod} from "../../utils/timePeriodAndFreq";
import {getFilteredTreeWithPaths, getMappedTree, getMaxTreeDepth, getNodes} from "../../utils/tree";

const $ = window.jQuery;

const styles = {
  root: {
    height: "100%",
    width: "100%"
  },
  criteriaContainer: {
    width: "100%",
    padding: "16px 24px 0",
    overflowY: "hidden"
  },
  criteriaContainerDimension: {
    height: "100%"
  },
  codelistInfo: {
    width: "100%",
    marginBottom: "8px",
    minHeight: "46px"
  },
  onlySelectedSwitch: {
    marginLeft: "0px"
  },
  missingValuesWarning: {
    width: "100%",
    marginBottom: "12px",
    alignItems: "center"
  },
  missingValuesWarningIcon: {
    marginLeft: "8px"
  },
  emptyContainer: {
    height: "100%",
    width: "100%"
  },
  timePeriodContainer: {
    height: "100%",
    width: "100%"
  },
  treeActions: {
    marginBottom: "8px"
  },
  tabLabel: {
    display: "-webkit-box",
    lineClamp: "3px",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
};

const mapStateToProps = ({app, node}) => ({
  themeConfig: app.themeConfig,
  node: node
});

const handleStyle = () => {
  const headerHeight = $("#criteria__header").outerHeight(true) || 0;
  $("#criteria__container").height(`calc(100% - ${headerHeight}px)`);

  const codelistHeaderHeight = $("#criteria-container__codelist-header").outerHeight(true) || 0;
  const codelistWarningsHeight = $("#criteria-container__codelist-warnings").outerHeight(true) || 0;
  $("#criteria-container__dimensions").css({height: `calc(100% - ${codelistHeaderHeight + codelistWarningsHeight}px)`});
};

function Criteria(props) {
  const {
    t,
    themeConfig,
    node,
    viewerMode,
    nodeId,
    nodeCode,
    datasetId,
    dimensions,
    initialDim,
    timeDim,
    freqDim,
    territoryDim,
    hideTerritoryDim,
    mode,
    type,
    criteria,
    territoryDimCriteria,
    onSetCriteria,
    codelists,
    hierarchicalCodelists,
    codelistsLength,
    fetchCodelist,
    codelistFetchError,
    isCriteriaValid,
    setCriteriaValidity,
    isObsCountWarningVisible,
    onCriteriaObsCountWarningHide,
    onSubmit,
    defaultLastNPeriods,
    showCodelistInfo = false,
    preserveFiltersWithDynamic = false,
    missingFilterValues,
    fetchCodelistFull,
    onCodelistFullHide,
    onHierarchyChange
  } = props;

  const initialTimePeriod = {
    selectorType: CRITERIA_FILTER_TYPE_RANGE,
    freq: themeConfig?.defaultFreq || null,
    minDate: null,
    maxDate: null,
    fromDate: null,
    toDate: null,
    periods: null,
    missingRange: false
  };

  const [tabId, setTabId] = useState(null);
  const [isCallDisabled, setIsCallDisabled] = useState(false);

  const [criteriaBackup, setCriteriaBackup] = useState(null);
  const [tabIdBackup, setTabIdBackup] = useState(null);

  const [isTree, setIsTree] = useState(null);
  const [data, setData] = useState(null);
  const [dataBackup, setDataBackup] = useState(null);

  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedKeysSnapshot, setCheckedKeysSnapshot] = useState(null);

  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState(null);

  const [timePeriod, setTimePeriod] = useState(initialTimePeriod);

  const [missingFilterValueIds, setMissingFilterValueIds] = useState([]);

  const [lastNotCodedFilter, setLastNotCodedFilter] = useState(null);

  const [treeKey, setTreeKey] = useState(Date.now());

  const [selectedHierarchy, setSelectedHierarchy] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  useEffect(() => {
    if (dimensions.length > 0 && tabId !== null) {
      const data = codelists?.[dimensions[tabId].id] || null;
      setData(data);
      setIsTree(getMaxTreeDepth(data, "children") > 1);
    }
  }, [codelists, dimensions, tabId]);

  useEffect(() => {
    if (dimensions.length > 0 && tabId !== null) {
      const dimensionCriteria = criteria[dimensions[tabId].id];
      setCheckedKeys(dimensionCriteria?.filterValues || []);
    }
  }, [criteria, dimensions, tabId]);

  useEffect(() => {
    if (timeDim && codelists) {
      setTimePeriod(prevTimeP =>
        getTimePeriod(
          prevTimeP,
          criteria,
          timeDim,
          codelists[timeDim] || null,
          freqDim,
          codelists[freqDim] || null,
          node
        )
      );
    }
  }, [node, codelists, timeDim, freqDim, criteria]);

  useEffect(() => {
    if (data) {
      const dataFlatValues = isTree ? getNodes(data, "children", () => true) : data;

      const dataFlatValuesIds = {};
      dataFlatValues.forEach(({id}) => {
        dataFlatValuesIds[id] = true;
      });
      const missingFilterValueIds = checkedKeys.filter(key => !dataFlatValuesIds[key]);

      setMissingFilterValueIds(missingFilterValueIds);
    }
  }, [data, isTree, checkedKeys]);

  useEffect(() => {
    setCheckedKeysSnapshot(null);
    setDefaultExpandedKeys(null);
    setDataBackup(null);
  }, [tabId]);

  useEffect(() => {
    setTreeKey(Date.now());
  }, [tabId, checkedKeysSnapshot, selectedHierarchy]);

  useEffect(() => {
    if (tabId === null) {
      let tabId;
      if (initialDim) {
        tabId = dimensions.findIndex(({id}) => id === initialDim);
      } else if (viewerMode === ViewerMode.SingleViewer) {
        tabId = 0;
      } else {
        const lastValorizedDimension = dimensions
          .filter(({id}) => !territoryDim || !hideTerritoryDim || id !== territoryDim)
          .map(({id}) => id)
          .reverse()
          .find(
            dim =>
              (criteria[dim]?.filterValues || []).length > 0 ||
              criteria[dim]?.period > 0 ||
              (criteria[dim]?.from && criteria[dim]?.to)
          );

        tabId = lastValorizedDimension
          ? dimensions.map(({id}) => id).indexOf(lastValorizedDimension)
          : !territoryDim || !hideTerritoryDim || dimensions[0].id !== territoryDim
            ? 0
            : 1;
      }

      setTabId(tabId);
    }
  }, [tabId, viewerMode, dimensions, initialDim, criteria, territoryDim, hideTerritoryDim]);

  const defaultSelectedKeys = useMemo(() => {
    return dimensions.length > 0 && tabId !== null ? criteria[dimensions[tabId].id]?.filterValues || [] : [];
  }, [dimensions, tabId, criteria]);

  const isCheckDisabled = useMemo(() => {
    return (
      !preserveFiltersWithDynamic &&
      !checkedKeysSnapshot &&
      (data || []).length === 1 &&
      (data[0].children || []).length === 0
    );
  }, [preserveFiltersWithDynamic, checkedKeysSnapshot, data]);

  /*const getTreeNodeIsSelectable = useCallback(
    ({isSelectable}) => {
      return isSelectable && !isCheckDisabled;
    },
    [isCheckDisabled]
  );*/

  const handleFetch = ({dimensionId, criteria}) => {
    let fullCriteria;
    if (territoryDimCriteria) {
      fullCriteria = {
        [territoryDim]: territoryDimCriteria,
        ...criteria
      };
    } else {
      fullCriteria = criteria;
    }

    fetchCodelist(
      nodeId,
      nodeCode,
      datasetId,
      mode,
      type,
      dimensionId,
      fullCriteria,
      timePeriod.freq,
      defaultLastNPeriods,
      preserveFiltersWithDynamic
    );

    setIsCallDisabled(true);
  };

  const handleTabChange = newTabId => {
    const newCriteria = _.cloneDeep(criteria);

    const dimension = dimensions[tabId].id;
    const filterValue = criteria?.[dimension]?.filterValues?.[0];
    if (type === CRITERIA_SELECTION_TYPE_DYNAMIC && !dimensions[tabId]?.extra?.DataStructureRef && filterValue) {
      setLastNotCodedFilter({dimension, filterValue});
    }

    if (type === CRITERIA_SELECTION_TYPE_DYNAMIC && newTabId < dimensions.length - 1 && !preserveFiltersWithDynamic) {
      const criteriaToRemove = dimensions
        .map(({id}) => id)
        .slice(newTabId + 1)
        .filter(dim => newCriteria[dim] !== null && newCriteria[dim] !== undefined);

      if (criteriaToRemove.length > 0) {
        criteriaToRemove.forEach(dim => (newCriteria[dim] = undefined));
        setCriteriaBackup(newCriteria);
        setTabIdBackup(newTabId);
      } else {
        setTabId(newTabId);
        setIsCallDisabled(false);
      }
    } else {
      setTabId(newTabId);

      if (mode !== CRITERIA_SELECTION_MODE_ALL) {
        setIsCallDisabled(false);
      }
    }
  };

  const handleCriteriaChange = useCallback(
    (checkedKeys, type, hierarchyId) => {
      const newCriteria = _.cloneDeep(criteria);
      const dimensionId = dimensions[tabId].id;
      const newType = type && criteriaFilterTypes.includes(type) ? type : CRITERIA_FILTER_TYPE_CODES;
      const hierId = (hierarchyId || "") !== "" ? hierarchyId : null;
      const hasHierarchyId = (hierId || "") !== "";

      let newDimensionCriteria = undefined;

      if (
        hasHierarchyId ||
        (checkedKeys && ((checkedKeys.length === 1 && checkedKeys[0] !== "") || checkedKeys.length > 1))
      ) {
        newDimensionCriteria = {
          id: dimensionId,
          type: newType,
          filterValues: checkedKeys || [],
          period: null,
          from: null,
          to: null,
          hierarchyId: hierId
        };
      }

      newCriteria[dimensionId] = newDimensionCriteria;

      onSetCriteria(newCriteria);

      setTimePeriod(prevTimePeriod => ({
        ...prevTimePeriod,
        freq: getFreqValueFromCriteria(newCriteria, freqDim, codelists[freqDim] || null, prevTimePeriod.freq)
      }));
    },
    [criteria, dimensions, tabId, onSetCriteria, freqDim, codelists]
  );

  const handleTimePeriodChange = useCallback(
    timePeriod => {
      const type = timePeriod.selectorType || CRITERIA_FILTER_TYPE_EMPTY;

      const timePeriodCriteria =
        type === CRITERIA_FILTER_TYPE_EMPTY || (type === CRITERIA_FILTER_TYPE_PERIODS && timePeriod.periods === null)
          ? undefined
          : {
              id: timeDim,
              type: type,
              filterValues: null,
              period: type === CRITERIA_FILTER_TYPE_PERIODS ? timePeriod.periods : null,
              from: type === CRITERIA_FILTER_TYPE_RANGE ? timePeriod.fromDate : null,
              to: type === CRITERIA_FILTER_TYPE_RANGE ? timePeriod.toDate : null
            };

      onSetCriteria({
        ...criteria,
        [timeDim]: timePeriodCriteria
      });

      setTimePeriod(timePeriod);
    },
    [criteria, timeDim, onSetCriteria]
  );

  const showHclSelector = useMemo(() => {
    const dimension = dimensions?.[tabId];
    const hclHierarchies = Object.keys(hierarchicalCodelists?.[dimension?.id] || {});
    return dimension && (dimension.hierarchies || []).length > 0 && hclHierarchies.length > 0;
  }, [dimensions, tabId, hierarchicalCodelists]);

  const showOnlySelectedSwitch = useCallback(() => {
    const dimId = dimensions[tabId].id;
    if (dimId === timeDim) {
      return false;
    }
    const hasData = (data || []).length > 1 || (data?.[0]?.children || []).length > 0;
    return checkedKeysSnapshot !== null || hasData;
  }, [checkedKeysSnapshot, data, dimensions, tabId, timeDim]);

  const onShowOnlySelectedSwitchClick = useCallback(
    checked => {
      let nodesIds = [];
      let newData;
      if (checked) {
        let orData = _.cloneDeep(data);
        newData = getFilteredTreeWithPaths(orData, "children", ({id}) => checkedKeys.includes(id));
        newData = getMappedTree(newData, "children", node => ({
          ...node,
          isSelectable: node.isSelectable && checkedKeys.includes(node.id)
        }));
        setDataBackup(orData);
      } else {
        newData = dataBackup;
        setDataBackup(null);
      }
      setData(newData);
      nodesIds = getNodes(newData, "children", ({children}) => (children || []).length > 0).map(({id}) => id);
      setDefaultExpandedKeys(nodesIds);
      setCheckedKeysSnapshot(checked ? checkedKeys : null);
    },
    [checkedKeys, data, dataBackup]
  );

  const showMissingValuesAlert = useCallback(() => {
    return data && missingFilterValueIds.length > 0 && dimensions[tabId]?.extra?.DataStructureRef;
  }, [data, missingFilterValueIds, dimensions, tabId]);

  const getSelectedValuesLength = useCallback(
    dimension => {
      const filterValues = criteria?.[dimension.id]?.filterValues || [];
      let selValuesLength = filterValues.length;
      if ((dimension.hierarchies || []).length > 0 && selValuesLength > 0) {
        const selectedHierarchy = criteria?.[dimension.id]?.hierarchyId || "";
        if (selectedHierarchy !== "") {
          const hierarchies = hierarchicalCodelists?.[dimension.id] || {};
          const hierarchyValues = hierarchies[selectedHierarchy] || [];
          const nodes = getNodes(hierarchyValues, "hierarchyCodes", ({id}) => filterValues.includes(id)) || [];
          selValuesLength = nodes.length;
        }
      }
      return selValuesLength;
    },
    [criteria, hierarchicalCodelists]
  );

  const onCriteriaHierarchyChange = (dimensionId, hierarchyId, tabId) => {
    setCheckedKeys([]);
    setSelectedHierarchy(hierarchyId);
    if (onHierarchyChange) {
      onHierarchyChange(dimensionId, hierarchyId, tabId, []);
    }
  };

  if (!dimensions || tabId === null) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      <Box id="criteria__header">
        <Tabs
          value={tabId}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newValue) => handleTabChange(newValue)}
        >
          {dimensions.map((dim, idx) => (
            <Tab
              key={idx}
              label={
                <Tooltip title={dim.label ? `[${dim.id}] ${dim.label}` : dim.id}>
                  <Grid container justifyContent="center" direction="column" alignItems="center">
                    <Grid item>
                      <Box sx={styles.tabLabel}>{dim.label || dim.id}</Box>
                    </Grid>
                    {codelistsLength &&
                      codelistsLength[idx] !== null &&
                      codelistsLength[idx] !== 0 &&
                      dimensions[idx]?.extra?.DataStructureRef !== null &&
                      dimensions[idx]?.extra?.DataStructureRef !== undefined && (
                        <Grid>{`(${getSelectedValuesLength(dim)}/${codelistsLength[idx]})`}</Grid>
                      )}
                  </Grid>
                </Tooltip>
              }
              id={`tab__${dim.id}`}
              aria-controls={`dimension__${dim.id}`}
              tabIndex={0}
              disabled={!isCriteriaValid}
              style={{
                display: dim.id === territoryDim && hideTerritoryDim ? "none" : "flex"
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box id="criteria__container" sx={styles.criteriaContainer}>
        {showCodelistInfo && dimensions[tabId].id !== timeDim && (
          <Grid
            container
            id="criteria-container__codelist-header"
            alignItems="center"
            justifyContent="space-between"
            sx={styles.codelistInfo}
          >
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item id="criteria-container__codelist-header__info" sx={{display: "flex", gap: "4px"}}>
                  <Typography id="criteria-container__codelist-header__info__label">
                    {dimensions[tabId].label || dimensions[tabId].id}
                  </Typography>
                  <Typography id="criteria-container__codelist-header__info__codelist-ref">
                    {dimensions[tabId]?.extra?.DataStructureRef ? `(${dimensions[tabId].extra.DataStructureRef})` : ""}
                  </Typography>
                </Grid>
                {showHclSelector && (
                  <Fragment>
                    <Grid item id="criteria-container__codelist-header__hcl__label" sx={{fontSize: "14px"}}>
                      {t("components.criteria.hierarchicalCodelist.selector.label") + ":"}
                    </Grid>
                    <Grid item id="criteria-container__codelist-header__hcl__container" sx={{marginLeft: "-8px"}}>
                      <HierarchicalCodelistSelector
                        dimension={dimensions[tabId]}
                        tabId={tabId}
                        selectedHierarchy={criteria?.[dimensions?.[tabId]?.id]?.hierarchyId || ""}
                        onHierarchyChange={onCriteriaHierarchyChange}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
            <Grid item id="criteria-container__codelist-header__only-selected-switch">
              {showOnlySelectedSwitch() && (
                <Tooltip title={t("components.criteria.codelistHeader.onlySelected.tooltip")}>
                  <FormControlLabel
                    label={t("components.criteria.codelistHeader.onlySelected.label")}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={checkedKeysSnapshot !== null}
                        onChange={({target}) => {
                          onShowOnlySelectedSwitchClick(target.checked);
                        }}
                        disabled={checkedKeysSnapshot === null && (checkedKeys || []).length === 0}
                      />
                    }
                    sx={styles.onlySelectedSwitch}
                  />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        )}
        {showMissingValuesAlert() && (
          <Alert id="criteria-container__codelist-warnings" severity="warning" sx={styles.missingValuesWarning}>
            {t("components.criteria.warnings.missingFilterValueIds.message.label")}
            <Tooltip title={t("components.criteria.warnings.missingFilterValueIds.action.tooltip")}>
              <IconButton
                aria-label={t("components.criteria.warnings.missingFilterValueIds.action.ariaLabel")}
                size="small"
                color="primary"
                onClick={() => fetchCodelistFull(nodeId, datasetId, dimensions[tabId].id, missingFilterValueIds)}
                sx={styles.missingValuesWarningIcon}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Alert>
        )}
        <Box id="criteria-container__dimensions">
          {dimensions.map((dim, idx) => (
            <Box
              key={idx}
              id={`dimension__${dim.id}`}
              aria-labelledby={`tab__${dim.id}`}
              hidden={idx !== tabId}
              sx={styles.criteriaContainerDimension}
            >
              <Call
                cb={handleFetch}
                cbParam={{
                  dimensionId: dim.id,
                  criteria: {
                    ...criteria,
                    [dim.id]: undefined
                  }
                }}
                disabled={
                  !fetchCodelist ||
                  idx !== tabId ||
                  (mode === CRITERIA_SELECTION_MODE_ALL && !!codelists) ||
                  isCallDisabled
                }
              >
                {idx !== tabId ? (
                  <span />
                ) : codelistFetchError ? (
                  <Box sx={styles.emptyContainer}>
                    <CustomEmpty text={t("components.criteria.errors.fetchingCodelist")} />
                  </Box>
                ) : (
                  <Fragment>
                    {!data ? (
                      <Box sx={styles.emptyContainer}>
                        <CustomEmpty text={""} />
                      </Box>
                    ) : dimensions[tabId].id === timeDim ? (
                      <Box sx={styles.timePeriodContainer}>
                        <TimePeriod
                          timePeriod={timePeriod}
                          freqDim={freqDim}
                          onSetTimePeriod={handleTimePeriodChange}
                          isCriteriaValid={isCriteriaValid}
                          setCriteriaValidity={setCriteriaValidity}
                        />
                      </Box>
                    ) : (
                      <Box sx={{height: "100%", width: "100%"}}>
                        {(data || []).length === 0 ? (
                          showHclSelector ? (
                            <CustomEmpty text={t("components.criteria.noHierarchySelected")} />
                          ) : dimensions[tabId]?.extra?.DataStructureRef ? (
                            <CustomEmpty
                              text={
                                lastNotCodedFilter
                                  ? t("components.criteria.dialogs.warning.notCodedDimension.label", lastNotCodedFilter)
                                  : t("components.criteria.emptyCodelist.label")
                              }
                            />
                          ) : (
                            <Fragment>
                              <Box sx={{margin: "16px 0"}}>{t("components.criteria.noCodelistAssociated.info")}</Box>
                              <TextField
                                value={checkedKeys[0] || ""}
                                placeholder={t("components.criteria.noCodelistAssociated.noFilter")}
                                variant="outlined"
                                sx={{width: "100%"}}
                                onChange={({target}) => {
                                  const newCheckedKeys = (target.value || "").length === 0 ? [] : [target.value];
                                  handleCriteriaChange(newCheckedKeys, CRITERIA_FILTER_TYPE_STRING, null);
                                }}
                              />
                            </Fragment>
                          )
                        ) : isTree ? (
                          <MaterialTree
                            key={treeKey}
                            treeData={data}
                            idKey={"id"}
                            childrenKey={"children"}
                            labelKey={"label"}
                            selectableKey={"isSelectable"}
                            defaultSelectedNodeIds={defaultSelectedKeys}
                            disableSelection={isCheckDisabled}
                            onNodeSelect={selectedKeys =>
                              handleCriteriaChange(
                                selectedKeys,
                                null,
                                criteria?.[dimensions?.[tabId]?.id]?.hierarchyId || ""
                              )
                            }
                            defaultExpandedNodeIds={defaultExpandedKeys}
                          />
                        ) : (
                          <InfiniteScrollTable
                            data={data}
                            getRowKey={({id}) => id}
                            showHeader={false}
                            columns={[
                              {
                                title: "",
                                dataIndex: "label",
                                minWidthToContent: true,
                                noFilter: true
                              }
                            ]}
                            rowSelection={{
                              selectedRowKeys: checkedKeys,
                              onChange: handleCriteriaChange
                            }}
                            leftActions={
                              <Fragment>
                                <Tooltip title={t("components.criteria.table.actions.selectAll.tooltip")}>
                                  <span>
                                    <IconButton
                                      aria-label={t("components.criteria.table.actions.selectAll.ariaLabel")}
                                      onClick={() => handleCriteriaChange(data.map(({id}) => id))}
                                      sx={{padding: "8px"}}
                                      disabled={isCheckDisabled}
                                    >
                                      <LibraryAddCheckIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip title={t("components.criteria.table.actions.deselectAll.tooltip")}>
                                  <span>
                                    <IconButton
                                      aria-label={t("components.criteria.table.actions.deselectAll.ariaLabel")}
                                      onClick={() => handleCriteriaChange([])}
                                      sx={{padding: "8px"}}
                                      disabled={isCheckDisabled}
                                    >
                                      <FilterNoneIcon sx={{padding: "1px"}} />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Fragment>
                            }
                            isCheckDisabled={isCheckDisabled}
                          />
                        )}
                      </Box>
                    )}
                  </Fragment>
                )}
              </Call>
            </Box>
          ))}
        </Box>
      </Box>

      <Dialog open={criteriaBackup !== null} fullWidth maxWidth="sm" onClose={() => setCriteriaBackup(null)}>
        <DialogContent>{t("components.criteria.dialogs.warning.losingFilters.title")}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCriteriaBackup(null);
              setTabIdBackup(null);
            }}
          >
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setCriteriaBackup(null);
              onSetCriteria(criteriaBackup, true);
              setTabIdBackup(null);
              setTabId(tabIdBackup);
              handleFetch({
                dimensionId: dimensions[tabIdBackup].id,
                criteria: {
                  ...criteriaBackup,
                  [dimensions[tabIdBackup].id]: undefined
                }
              });
            }}
            color="primary"
          >
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isObsCountWarningVisible === true} fullWidth maxWidth="sm" onClose={onCriteriaObsCountWarningHide}>
        <DialogContent>{t("components.criteria.dialogs.warning.obsCountWarning.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onCriteriaObsCountWarningHide}>{t("commons.confirm.cancel")}</Button>
          <Button
            autoFocus
            onClick={() => {
              onCriteriaObsCountWarningHide();
              if (onSubmit) {
                onSubmit();
              }
            }}
          >
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={missingFilterValues !== null} fullWidth maxWidth="sm" onClose={onCodelistFullHide}>
        <CustomDialogTitle onClose={onCodelistFullHide}>
          {t("components.criteria.dialogs.warning.missingFilterValues.title", {dimension: dimensions[tabId].label})}
        </CustomDialogTitle>
        <DialogContent>
          <InfiniteScrollTable
            data={missingFilterValues}
            getRowKey={({id}) => id}
            showHeader={false}
            columns={[
              {
                title: "",
                dataIndex: "name",
                render: (_, {id, name}) => `[${id}] ${name}`,
                renderText: (_, {id, name}) => `[${id}] ${name}`,
                noFilter: true,
                noSort: true
              }
            ]}
            height={400}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCodelistFullHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default compose(withTranslation(), connect(mapStateToProps))(Criteria);
