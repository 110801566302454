import React, {Fragment, useState} from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {withTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import DatasetAttachments from "../dataset-attachments";
import SanitizedHTML from "../sanitized-html";

const sectionTitleStyle = {
  fontWeight: "bold",
  marginBottom: "8px"
};

const sectionContentStyle = {
  "& p:first-child": {
    marginTop: "0px"
  },
  "& p:last-child": {
    marginBottom: "0px"
  },
  "& .MuiChip-root": {
    margin: "0 8px 0 0"
  }
};

const CatalogInfoButton = ({t, title, source, description, note, attachments}) => {
  const [isVisible, setVisibility] = useState(false);

  const onOpen = () => {
    setVisibility(true);
  };

  const onClose = ev => {
    ev.stopPropagation();
    setVisibility(false);
  };

  if (!source && !description && !note && !attachments) {
    return <span />;
  }

  return (
    <Fragment>
      <Tooltip title={t("components.catalogInfoButton.action.title")}>
        <IconButton
          id="catalog-info-btn"
          size="small"
          onClick={ev => {
            ev.stopPropagation();
            onOpen();
          }}
          aria-label={t("components.catalogInfoButton.action.ariaLabel")}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={isVisible} maxWidth="sm" fullWidth onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {t("components.catalogInfoButton.dialog.title", {datasetTitle: title})}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {source && (
              <Grid item container>
                <Grid item xs={12} sx={sectionTitleStyle}>
                  {t("components.catalogInfoButton.dialog.content.source")}:
                </Grid>
                <Grid item xs={12} sx={sectionContentStyle}>
                  {source}
                </Grid>
              </Grid>
            )}
            {description && (
              <Grid item container>
                <Grid item xs={12} sx={sectionTitleStyle}>
                  {t("components.catalogInfoButton.dialog.content.description")}:
                </Grid>
                <Grid item xs={12} sx={sectionContentStyle}>
                  <SanitizedHTML html={description} allowTarget />
                </Grid>
              </Grid>
            )}
            {note && (
              <Grid item container>
                <Grid item xs={12} sx={sectionTitleStyle}>
                  {t("components.catalogInfoButton.dialog.content.notes")}:
                </Grid>
                <Grid item xs={12} sx={sectionContentStyle}>
                  <SanitizedHTML html={note} allowTarget />
                </Grid>
              </Grid>
            )}
            {attachments && (
              <Grid item container>
                <Grid item xs={12} sx={sectionTitleStyle}>
                  {t("components.catalogInfoButton.dialog.content.attachments")}:
                </Grid>
                <Grid item xs={12} sx={sectionContentStyle}>
                  <DatasetAttachments title={title} attachedDataFiles={attachments} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withTranslation()(CatalogInfoButton);
