export const isMultiSelectDim = (dim, layout) => layout?.primaryDim?.[0] === dim || layout?.secondaryDim?.[0] === dim;

const getItemChildren = (itemId, childMap, dimensionCategory, additionalitemslabels) => {
  let children = [];

  const itemChildren = childMap[itemId] || [];
  itemChildren.forEach(childId => {
    const node = getFilterTreeNode(childId, itemId, childMap, dimensionCategory, additionalitemslabels);
    children.push(node);
  });

  return children;
};

export const getFilterTreeNode = (itemId, parentId, childMap, dimensionCategory, additionalitemslabels) => {
  const name = dimensionCategory.label[itemId] || additionalitemslabels[itemId];
  const isSelectable = dimensionCategory.index.includes(itemId);
  const node = {
    id: itemId,
    name: name,
    isSelectable: isSelectable,
    label: `[${itemId}] ${name}`,
    children: getItemChildren(itemId, childMap, dimensionCategory, additionalitemslabels)
  };

  if (parentId) {
    node.parentId = parentId;
  }

  return node;
};
