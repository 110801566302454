import React, {Component, Fragment} from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import HeightIcon from "@mui/icons-material/Height";
import ListIcon from "@mui/icons-material/List";
import StorageIcon from "@mui/icons-material/Storage";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {TreeItem} from "@mui/x-tree-view/TreeItem";
import {TreeView} from "@mui/x-tree-view/TreeView";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {getDatasetInternalUrl, getDatasetsInternalUrl} from "../../links";
import CatalogInfoButton from "../catalog-info-button";
import CatalogMetadataButton from "../catalog-metadata-button";
import CustomLink from "../custom-link";
import {uncategorizedHasDashboard} from "../../utils/catalog";

const treeItemContentStyle = {
  cursor: "default",
  backgroundColor: "transparent !important",
  "&:hover": {
    backgroundColor: "transparent !important"
  },
  "&.Mui-focused .MuiTreeItem-label": {
    outline: "-webkit-focus-ring-color auto 1px",
    outlineOffset: "-1px"
  },
  color: theme => theme.palette.text.primary
};

const treeItemContentUnselectableStyle = {
  "& > .MuiTreeItem-label": {
    background: "unset !important"
  }
};

const treeItemLabelStyle = {
  paddingLeft: "0px"
};

const treeItemLabelDatasetStyle = {
  width: "unset",
  backgroundColor: "inherit !important",
  "& .custom-link--enabled:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04) !important"
  }
};

const treeItemLabelDatasetSelectedStyle = {
  width: "unset",
  backgroundColor: "rgba(0, 41, 90, 0.2) !important",
  "& .custom-link--enabled:hover": {
    backgroundColor: "rgba(0, 41, 90, 0.2) !important"
  }
};

const treeItemLabelCategoryStyle = {
  cursor: "pointer",
  backgroundColor: "inherit !important",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04) !important"
  }
};

const treeItemLabelCategorySelectedStyle = {
  cursor: "pointer",
  backgroundColor: "rgba(0, 41, 90, 0.2) !important",
  "&:hover": {
    backgroundColor: "rgba(0, 41, 90, 0.2) !important"
  }
};

const nodeActionStyle = {
  marginLeft: "8px",
  "& button": {
    padding: "4px"
  }
};

const getNodeId = (catId, prevPath) => [...(prevPath || []), catId].join("+");

const getSelectedNodeIds = categoryPath => {
  const ret = [];

  categoryPath.forEach((category, idx) => {
    ret.unshift(getNodeId(category, categoryPath.slice(0, idx)));
  });

  return ret;
};

const getAllNodeIds = (tree, prevPath) => {
  const res = [];

  const recursive = (subTree, prevPath) =>
    subTree
      ? subTree.map(node => {
          res.push(getNodeId(node.id, prevPath));
          if (node.childrenCategories && node.childrenCategories.length) {
            recursive(node.childrenCategories, [...prevPath, node.id]);
          }
          return null;
        })
      : [];

  recursive(tree, prevPath);

  return res;
};

class CategoriesTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: getSelectedNodeIds(props.selectedCategoryPath || []),
      selected: getSelectedNodeIds(props.selectedCategoryPath || [])
    };
    this.onExpand = this.onExpand.bind(this);
  }

  onExpand = expanded => {
    if (this.props.onCategoryClick !== undefined) {
      if (expanded.length > 1) {
        let parent = expanded[expanded.length - 1];
        parent = parent.replace("+", "");
        for (var i = 0; i < expanded.length - 1; i++) {
          if (expanded[i].indexOf(parent + "+") < 0) {
            expanded.splice(expanded.length - 1);
          } else {
            if (expanded.length > 1 && ("+" + expanded[i]).indexOf(expanded[i + 1]) < 0) {
              expanded.splice(1, 1);
              i = -1;
            }
          }
        }
      }
      if (this.props.onCategoryClick !== undefined) {
        this.props.onCategoryClick(expanded);
      }
    }
    this.setState({expanded: expanded});
  };

  render() {
    const {
      t,
      themeConfig,
      node,
      catalog: inputCatalog,
      nodeCatalog,
      selectedDataset,
      onClick,
      onClose,
      initialPath = [],
      showDatasetList = false,
      showExpandControls = false,
      showCategoriesFirst = false,
      treeViewClasses = "",
      treeItemCategoryClasses = "",
      treeItemDatasetClasses = "",
      hideLeaf = false
    } = this.props;

    const catalog = inputCatalog || nodeCatalog;

    const {expanded = [], selected} = this.state;

    const getDataset = (datasetId, cat, prevPath) => {
      const nodeId = getNodeId(cat?.id, prevPath) + "-" + datasetId;

      const datasetLabel = catalog?.datasetMap?.[datasetId]
        ? catalog?.datasetMap?.[datasetId].title
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
        ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).title
        : datasetId;
      const datasetSource = catalog?.datasetMap?.[datasetId]
        ? catalog?.datasetMap?.[datasetId].source
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
        ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).source
        : null;
      const datasetDescription = catalog?.datasetMap?.[datasetId]
        ? catalog?.datasetMap?.[datasetId].description
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
        ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).description
        : null;
      const datasetAttachments = catalog?.datasetMap?.[datasetId]
        ? catalog?.datasetMap?.[datasetId].attachedDataFiles
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
        ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).attachedDataFiles
        : null;
      const isDatasetOnlyFile = catalog?.datasetMap?.[datasetId]
        ? catalog?.datasetMap?.[datasetId].datasetType === "onlyFile"
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
        ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).datasetType === "onlyFile"
        : false;
      const isDatasetWithDashboard = catalog?.datasetMap?.[datasetId]
        ? catalog?.datasetMap?.[datasetId].datasetType === "dashboard"
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
        ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).datasetType === "dashboard"
        : false;

      return (
        <TreeItem
          key={nodeId}
          nodeId={nodeId}
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
              className={"categories-tree__tree-item__dataset"}
            >
              {onClick ? (
                <Button
                  onClick={() => (!isDatasetOnlyFile ? onClick(datasetId) : null)}
                  disabled={isDatasetOnlyFile}
                  sx={{
                    textTransform: "none",
                    fontStyle: "italic",
                    fontWeight: "normal",
                    fontSize: "16px"
                  }}
                  startIcon={isDatasetWithDashboard ? <DashboardIcon /> : <StorageIcon />}
                >
                  {datasetLabel}
                </Button>
              ) : (
                <CustomLink
                  to={getDatasetInternalUrl(node.code.toLowerCase(), [...prevPath, cat?.id], datasetId)}
                  text={<i className="categories-tree__tree-item__dataset__label">{datasetLabel}</i>}
                  icon={
                    isDatasetWithDashboard ? (
                      <DashboardIcon fontSize="small" className="categories-tree__tree-item__dataset__icon" />
                    ) : (
                      <StorageIcon fontSize="small" className="categories-tree__tree-item__dataset__icon" />
                    )
                  }
                  onClick={onClose}
                  disabled={isDatasetOnlyFile}
                />
              )}
              {(datasetSource || datasetDescription || (datasetAttachments && datasetAttachments.length > 0)) && (
                <Box
                  sx={{
                    marginLeft: "4px",
                    "& button": {
                      padding: "4px"
                    }
                  }}
                  className={"categories-tree__tree-item__dataset__action"}
                >
                  <CatalogInfoButton
                    title={datasetLabel}
                    source={datasetSource}
                    description={datasetDescription}
                    attachments={datasetAttachments}
                  />
                </Box>
              )}
            </Box>
          }
          sx={[
            {
              "& > .MuiTreeItem-content": [treeItemContentStyle, isDatasetOnlyFile && treeItemContentUnselectableStyle]
            },
            {
              "& > .MuiTreeItem-content > .MuiTreeItem-label":
                datasetId === selectedDataset ? treeItemLabelDatasetSelectedStyle : treeItemLabelDatasetStyle
            }
          ]}
          className={`categories-tree__tree-item--dataset ${treeItemDatasetClasses}`}
        />
      );
    };

    const getTreeItems = (tree, prevPath) =>
      tree.map(cat => {
        const nodeId = getNodeId(cat.id, prevPath);
        return (
          <TreeItem
            key={nodeId}
            nodeId={nodeId}
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px"
                }}
                className={"categories-tree__tree-item__node"}
              >
                {expanded.includes(nodeId) ? (
                  <FolderOpenIcon sx={{marginRight: "8px"}} className="categories-tree__tree-item__node__icon" />
                ) : (
                  <FolderIcon sx={{marginRight: "8px"}} className="categories-tree__tree-item__node__icon" />
                )}
                <span className="categories-tree__tree-item__node__label">{cat.label}</span>
                {cat.description && (
                  <Box sx={nodeActionStyle} className="categories-tree__tree-item__node__action">
                    <CatalogInfoButton title={cat.label} description={cat.description} />
                  </Box>
                )}
                {cat.metadataUrl && (
                  <Box sx={nodeActionStyle} className="categories-tree__tree-item__node__action">
                    <CatalogMetadataButton metadataUrl={cat.metadataUrl} iconSize="small" />
                  </Box>
                )}
              </Box>
            }
            sx={[
              {
                "& > .MuiTreeItem-content": treeItemContentStyle
              },
              {
                "& > .MuiTreeItem-content > .MuiTreeItem-label": [
                  treeItemLabelStyle,
                  selected.includes(nodeId) ? treeItemLabelCategorySelectedStyle : treeItemLabelCategoryStyle
                ]
              }
            ]}
            className={`categories-tree__tree-item--category ${treeItemCategoryClasses}`}
            tabIndex={0}
          >
            {((cat.datasetIdentifiers ?? []).length > 0 || (cat.childrenCategories ?? []).length > 0) && (
              <Fragment>
                {showCategoriesFirst && (cat.childrenCategories ?? []).length > 0
                  ? getTreeItems(cat.childrenCategories, [...prevPath, cat.id])
                  : null}
                {hideLeaf || (cat.datasetIdentifiers || []).length === 0 ? (
                  <span />
                ) : themeConfig.showDatasetListInCategoriesTree || showDatasetList ? (
                  cat.datasetIdentifiers.map(datasetId => getDataset(datasetId, cat, prevPath))
                ) : (
                  <TreeItem
                    nodeId={getNodeId(cat.id, prevPath) + "-results"}
                    label={
                      <CustomLink
                        to={getDatasetsInternalUrl(node.code.toLowerCase(), [...prevPath, cat.id])}
                        text={
                          <i className="categories-tree__tree-item__dataset__label">
                            {cat.hasDashboards
                              ? t("components.categoriesTree.goToDataWithDashboards", {
                                  datasetCount: cat.datasetIdentifiers.length
                                })
                              : t("components.categoriesTree.goToData", {
                                  datasetCount: cat.datasetIdentifiers.length
                                })}
                          </i>
                        }
                        icon={<ListIcon className="categories-tree__tree-item__dataset__icon" />}
                        onClick={onClose}
                      />
                    }
                    sx={[
                      {
                        "& > .MuiTreeItem-content": treeItemContentStyle
                      },
                      {
                        "& > .MuiTreeItem-content > .MuiTreeItem-label": [treeItemLabelStyle, treeItemLabelDatasetStyle]
                      }
                    ]}
                    className={`categories-tree__tree-item--dataset ${treeItemDatasetClasses}`}
                  />
                )}
                {!showCategoriesFirst && (cat.childrenCategories ?? []).length > 0
                  ? getTreeItems(cat.childrenCategories, [...prevPath, cat.id])
                  : null}
              </Fragment>
            )}
          </TreeItem>
        );
      });

    if (catalog === undefined) return <></>;

    let tree =
      catalog.categoryGroups.length > 0
        ? catalog.hasCategorySchemes
          ? catalog.categoryGroups.map(({id, label, categories}) => ({id, label, childrenCategories: categories}))
          : catalog.categoryGroups[0].categories
        : null;

    if (catalog.uncategorizedDatasets && catalog.uncategorizedDatasets.length > 0) {
      tree = [
        ...(tree || []),
        {
          id: "uncategorized",
          label: t("commons.catalog.uncategorized"),
          childrenCategories: [],
          datasetIdentifiers: catalog.uncategorizedDatasets.map(({identifier}) => identifier),
          hasDashboards: uncategorizedHasDashboard(catalog.uncategorizedDatasets)
        }
      ];
    }

    return (
      <Fragment>
        {showExpandControls && catalog.categoryGroups.length > 0 && (
          <Grid
            container
            spacing={1}
            sx={{
              marginBottom: "2px"
            }}
            justifyContent="flex-end"
          >
            <Grid item>
              <Tooltip title={t("components.categoriesTree.expandAll.tooltip")}>
                <IconButton
                  aria-label={t("components.categoriesTree.expandAll.ariaLabel")}
                  sx={{
                    padding: "8px"
                  }}
                  onClick={() => {
                    let nodeIds = [];
                    if (catalog.hasCategorySchemes) {
                      catalog.categoryGroups.forEach(({id, label, categories}) => {
                        nodeIds = [
                          ...nodeIds,
                          ...getAllNodeIds([{id, label, childrenCategories: categories}], initialPath),
                          "uncategorized"
                        ];
                      });
                    } else {
                      nodeIds = [...getAllNodeIds(catalog.categoryGroups[0].categories, initialPath), "uncategorized"];
                    }
                    this.onExpand(nodeIds);
                  }}
                >
                  <HeightIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("components.categoriesTree.collapseAll.tooltip")}>
                <IconButton
                  aria-label={t("components.categoriesTree.collapseAll.ariaLabel")}
                  sx={{
                    padding: "8px"
                  }}
                  onClick={() => this.onExpand([])}
                >
                  <VerticalAlignCenterIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        {tree && (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={(_, nodeIds) => this.onExpand(nodeIds)}
            sx={{
              color: theme => theme.palette.primary.main
            }}
            className={`categories-tree__tree-view  ${treeViewClasses}`}
          >
            {getTreeItems(tree, initialPath)}
            {(catalog?.rootDatasets || []).map(({identifier}) => getDataset(identifier, null, initialPath))}
          </TreeView>
        )}
      </Fragment>
    );
  }
}

export default compose(
  withTranslation(),
  connect(state => ({
    themeConfig: state.app.themeConfig,
    nodeCatalog: state.catalog
  }))
)(CategoriesTree);
