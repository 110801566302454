import moment from "moment";
import {
  getCreateTemplateUrl,
  getCreateViewUrl,
  getDatasetDetailLevels,
  getDatasetDownloadUrl,
  getDatasetExportMetadataUrl,
  getDatasetSdmxQueryUrl,
  getDatasetStructureCodelistsUrl,
  getDatasetStructureCodelistUrl,
  getDatasetStructureDynamicCodelistUrl,
  getDatasetStructureUrl,
  getDatasetTerritories,
  getDatasetUrl,
  getDatasetWithIndicatorsUrl,
  getDatasetWithMarginalCustomDownloadUrl,
  getDatasetWithMarginalUrl,
  getNodeCatalogUrl,
  getTerritorialClassificationsUrl,
  getTerritorialClassificationsValuesUrl
} from "../../../serverApi/urls";
import {
  Criteria,
  CRITERIA_SELECTION_MODE_ALL,
  CRITERIA_SELECTION_TYPE_DYNAMIC,
  CRITERIA_SELECTION_TYPE_FULL
} from "../constants";
import {AdditionalDataset, getIndicatorsDefinition, getMarginalDefinition, Indicator} from "./constants";
import {initRequest, RequestMethod} from "../../../middlewares/request/requestActions";
import {getDetailLevelFromTree} from "../../../utils/catalog";
import {CRITERIA_FILTER_TYPE_RANGE, getCriteriaArrayFromObject} from "../../../utils/criteria";
import {
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  downloadFormats,
  getCustomExportRequestBody,
  getCustomExportRequestBodyWithMarginal
} from "../../../utils/download";
import {getFilteredTreeWithPaths} from "../../../utils/tree";

export const DATASET_MV_STATE_BACKUP = "dataset/mv/state/backup";

export const DATASET_MV_STRUCTURE_FETCH_INIT = "dataset/mv/structure/fetch/init";
export const DATASET_MV_STRUCTURE_FETCH_SUCCESS = "dataset/mv/structure/fetch/success";
export const DATASET_MV_STRUCTURE_FETCH_ERROR = "dataset/mv/structure/fetch/error";

export const DATASET_MV_DETAIL_LEVELS_SET = "dataset/mv/detail/levels/set";
export const DATASET_MV_DETAIL_LEVELS_FETCH = "dataset/mv/detail/levels/fetch";

export const DATASET_MV_DATASET_FETCH_ENABLE = "dataset/mv/dataset/fetch/enable";
export const DATASET_MV_DATASET_FETCH = "dataset/mv/dataset/fetch";

export const DATASET_MV_DATASET_FETCH_INIT = "dataset/fetch/dataset/fetch/init";
export const DATASET_MV_DATASET_FETCH_SUCCESS = "dataset/fetch/dataset/fetch/success";
export const DATASET_MV_DATASET_FETCH_ERROR = "dataset/fetch/dataset/fetch/error";

export const DATASET_MV_TERRITORIAL_CLASSIFICATIONS_FETCH = "dataset/mv/territorialClassifications/fetch";
export const DATASET_MV_TERRITORIAL_CLASSIFICATIONS_CONFIG_SET = "dataset/mv/territorialClassifications/config/set";
export const DATASET_MV_TERRITORIAL_CLASSIFICATIONS_VALUES_FETCH = "dataset/mv/territorialClassifications/values/fetch";

export const DATASET_MV_CRITERIA_SHOW = "dataset/mv/criteria/show";
export const DATASET_MV_CRITERIA_HIDE = "dataset/mv/criteria/hide";
export const DATASET_MV_CRITERIA_ALERT_HIDE = "dataset/mv/criteria/alert/hide";
export const DATASET_MV_CRITERIA_OBS_COUNT_WARNING_HIDE = "dataset/mv/criteria/obs/count/warning/hide";
export const DATASET_MV_STRUCTURE_CODELIST_FETCH = "dataset/mv/structure/codelist/fetch";
export const DATASET_MV_STRUCTURE_CRITERIA_SET = "dataset/mv/structure/criteria/set";

export const DATASET_MV_TERRITORY_SHOW = "dataset/mv/territory/show";
export const DATASET_MV_TERRITORY_HIDE = "dataset/mv/territory/hide";
export const DATASET_MV_TERRITORY_TERRITORIES_FETCH = "dataset/mv/territory/territories/fetch";
export const DATASET_MV_TERRITORY_SUBMIT = "dataset/mv/territory/submit";
export const DATASET_MV_TERRITORY_LAST_YEAR_FETCH = "dataset/mv/territory/last/year/fetch";

export const DATASET_MV_VIEWER_TABLE_VISIBILITY_SET = "dataset/mv/viewer/table/visibility/set";
export const DATASET_MV_VIEWER_MAP_VISIBILITY_SET = "dataset/mv/viewer/map/visibility/set";
export const DATASET_MV_VIEWER_CHART_VISIBILITY_SET = "dataset/mv/viewer/chart/visibility/set";
export const DATASET_MV_VIEWER_CHART_TYPE_SET = "dataset/mv/viewer/chart/type/set";

export const DATASET_MV_MAP_LAYOUT_SUBMIT = "dataset/mv/map/layout/submit";
export const DATASET_MV_CHART_LAYOUT_SUBMIT = "dataset/mv/chart/layout/submit";
export const DATASET_MV_CHART_LAYOUT_TERRITORY_DIM_VALUES_SET = "dataset/mv/chart/layout/territory/dim/values/set";

export const DATASET_MV_CHART_SETTINGS_SET = "dataset/mv/chart/settings/set";

export const DATASET_MV_MAP_SETTINGS_SET = "dataset/mv/map/settings/set";

export const DATASET_MV_DOWNLOAD_SUBMIT = "dataset/mv/download/submit";
export const DATASET_MV_DOWNLOAD_WARNING_HIDE = "dataset/mv/download/warning/hide";

export const DATASET_MV_VIEW_TEMPLATE_SHOW = "dataset/mv/view/template/show";
export const DATASET_MV_VIEW_TEMPLATE_HIDE = "dataset/mv/view/template/hide";
export const DATASET_MV_VIEW_TEMPLATE_SUBMIT = "dataset/mv/view/template/submit";
export const DATASET_MV_VIEW_ERROR_HIDE = "dataset/mv/view/error/hide";

export const DATASET_MV_UNAVAILABLE_VIEW_WARNING_HIDE = "dataset/mv/unavailable/view/warning/hide";

export const DATASET_MV_HTML_GENERATING_TIME_SET = "dataset/mv/html/generating/time/set";

export const DATASET_MV_SDMX_QUERY_SHOW = "dataset/mv/sdmx/query/show";
export const DATASET_MV_SDMX_QUERY_HIDE = "dataset/mv/sdmx/query/hide";
export const DATASET_MV_SDMX_QUERY_FETCH = "dataset/mv/sdmx/query/fetch";

export const DATASET_MV_INDICATOR_CREATE_VISIBILITY_SET = "dataset/mv/indicator/create/visibility/set";
export const DATASET_MV_INDICATOR_LIST_VISIBILITY_SET = "dataset/mv/indicator/list/visibility/set";
export const DATASET_MV_INDICATOR_PREVIEW_FETCH = "dataset/mv/indicator/preview/fetch";
export const DATASET_MV_INDICATOR_PREVIEW_RESET = "dataset/mv/indicator/preview/reset";
export const DATASET_MV_INDICATOR_PUBLISH = "dataset/mv/indicator/publish";
export const DATASET_MV_INDICATOR_DELETE = "dataset/mv/indicator/delete";
export const DATASET_MV_INDICATOR_WARNING_HIDE = "dataset/mv/indicator/warning/hide";
export const DATASET_MV_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET = "dataset/mv/indicator/arithmeticMean/visibility/set";
export const DATASET_MV_INDICATOR_ARITHMETIC_MEAN_VALUES_SET = "dataset/mv/indicator/arithmeticMean/values/set";
export const DATASET_MV_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET =
  "dataset/mv/indicator/standardDeviation/visibility/set";
export const DATASET_MV_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET =
  "dataset/mv/coefficientOfVariation/visibility/set";

export const DATASET_MV_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET = "dataset/mv/additionalDataset/create/visibility/set";
export const DATASET_MV_ADDITIONAL_DATASET_LIST_VISIBILITY_SET = "dataset/mv/additionalDataset/list/visibility/set";
export const DATASET_MV_ADDITIONAL_DATASET_CATALOG_FETCH = "dataset/mv/additionalDataset/catalog/fetch";
export const DATASET_MV_ADDITIONAL_DATASET_CHANGE = "dataset/mv/additionalDataset/change";
export const DATASET_MV_ADDITIONAL_DATASET_STRUCTURE_FETCH = "dataset/mv/additionalDataset/structure/fetch";
export const DATASET_MV_ADDITIONAL_DATASET_SUBMIT = "dataset/mv/additionalDataset/submit";
export const DATASET_MV_ADDITIONAL_DATASET_DELETE = "dataset/mv/additionalDataset/delete";
export const DATASET_MV_ADDITIONAL_DATASET_CRITERIA_SET = "dataset/mv/additionalDataset/criteria/set";

export const DATASET_MV_DOWNLOAD_METADATA_SUBMIT = "dataset/mv/download/metadata/submit";

export const DATASET_MV_STRUCTURE_CODELIST_FULL_FETCH = "dataset/mv/structure/codelist/full/fetch";
export const DATASET_MV_STRUCTURE_CODELIST_FULL_HIDE = "dataset/mv/structure/codelist/full/hide";

export const backupDatasetMVState = (datasetUuid: string) => ({
  type: DATASET_MV_STATE_BACKUP,
  datasetUuid
});

export const fetchDatasetMVStructureInit = () => ({
  type: DATASET_MV_STRUCTURE_FETCH_INIT
});

export const fetchDatasetMVStructureSuccess = (datasetUuid: string, structure: any, viewId: string | null) => ({
  type: DATASET_MV_STRUCTURE_FETCH_SUCCESS,
  payload: {
    datasetUuid,
    structure,
    viewId
  }
});

export const fetchDatasetMVStructureError = () => ({
  type: DATASET_MV_STRUCTURE_FETCH_ERROR
});

export const handleDatasetMVDetailLevels = (
  nodeId: any,
  datasetId: any,
  catalogDetailLevelTree: any,
  datasetDetailLevels: any,
  datasetDetailLevel: any,
  nodeDetailLevel: any,
  baseUrl: string
) => {
  if (catalogDetailLevelTree && datasetDetailLevels) {
    const detailLevelTree = getFilteredTreeWithPaths(catalogDetailLevelTree, "layers", (node: any) =>
      (datasetDetailLevels || []).includes(node.id)
    );
    const detailLevel = getDetailLevelFromTree(detailLevelTree, [datasetDetailLevel, nodeDetailLevel]);

    return {
      type: DATASET_MV_DETAIL_LEVELS_SET,
      payload: {
        detailLevelTree: detailLevelTree,
        detailLevel: detailLevel
      }
    };
  } else {
    return initRequest(
      DATASET_MV_DETAIL_LEVELS_FETCH,
      getDatasetDetailLevels(nodeId, datasetId),
      RequestMethod.POST,
      undefined,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingDetailLevels")
      }),
      {
        datasetDetailLevel,
        nodeDetailLevel
      },
      baseUrl
    );
  }
};

export const enableDatasetMVDatasetFetch = (maxObservations?: number) => ({
  type: DATASET_MV_DATASET_FETCH_ENABLE,
  payload: {
    maxObservations
  }
});

export const fetchDatasetMVDataset = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria},
  timeDim: string,
  territoryDim: string | null,
  indicators: Indicator[],
  additionalDatasets: AdditionalDataset[],
  defaultLanguage: string,
  languages: string[],
  indicatorsBaseUrl?: string
) => {
  let url,
    body,
    baseUrl = undefined;

  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle,
      criteria: criteria,
      timeDim: timeDim,
      territoryDim: territoryDim
    },
    ...additionalDatasets
  ];

  if (indicators.length > 0) {
    url = getDatasetWithIndicatorsUrl(false);
    body = getIndicatorsDefinition(indicators, datasets, timeDim, defaultLanguage, languages);
    baseUrl = indicatorsBaseUrl;
  } else if (additionalDatasets.length > 0) {
    url = getDatasetWithMarginalUrl();
    body = getMarginalDefinition(datasets);
    baseUrl = indicatorsBaseUrl;
  } else {
    url = getDatasetUrl(nodeId, datasetId);
    body = getCriteriaArrayFromObject(criteria);
  }

  return initRequest(
    DATASET_MV_DATASET_FETCH,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart:
        additionalDatasets.length === 0
          ? t("scenes.dataset.actions.fetchingDataset", {title: datasetTitle})
          : t("scenes.dataset.actions.fetchingMergedDatasets")
    }),
    undefined,
    baseUrl,
    (statusCode: number) => statusCode === 413 || statusCode === 414
  );
};

export const fetchDatasetMVDatasetInit = () => ({
  type: DATASET_MV_DATASET_FETCH_INIT
});

export const fetchDatasetMVDatasetSuccess = (
  response: any,
  statusCode: number,
  responseHeaders: any,
  isPointData: boolean,
  pointDim: string | null,
  cellCount: number,
  isTooBigData: boolean,
  isEmptyData: boolean,
  isPartialData: boolean,
  tableColCount: number | null,
  mapPointCount: number | null,
  isResponseNotValid: boolean,
  tableLayout: any,
  tableFilterTree: any,
  mapLayout: any,
  mapFilterTree: any,
  chartLayout: any,
  chartFilterTree: any,
  timePeriodsByFreq: any,
  worker: any
) => ({
  type: DATASET_MV_DATASET_FETCH_SUCCESS,
  payload: {
    response,
    statusCode,
    responseHeaders,
    isPointData,
    pointDim,
    cellCount,
    isTooBigData,
    isEmptyData,
    isPartialData,
    tableColCount,
    mapPointCount,
    isResponseNotValid,
    tableLayout,
    tableFilterTree,
    mapLayout,
    mapFilterTree,
    chartLayout,
    chartFilterTree,
    timePeriodsByFreq,
    worker
  }
});

export const fetchDatasetMVDatasetError = (statusCode?: number) => ({
  type: DATASET_MV_DATASET_FETCH_ERROR,
  payload: {
    statusCode
  }
});

export const fetchDatasetMVTerritorialClassifications = (detailLevel: number, baseUrl: string) =>
  initRequest(
    DATASET_MV_TERRITORIAL_CLASSIFICATIONS_FETCH,
    getTerritorialClassificationsUrl(detailLevel),
    RequestMethod.GET,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingTerritorialClassifications")
    }),
    undefined,
    baseUrl
  );

export const setDatasetMVTerritorialClassificationsConfig = (territorialClassificationsConfig: any) => ({
  type: DATASET_MV_TERRITORIAL_CLASSIFICATIONS_CONFIG_SET,
  payload: {
    territorialClassificationsConfig
  }
});

export const fetchDatasetMVTerritorialClassificationsValues = (
  nodeId: number,
  territories: string[],
  classifications: number[],
  year: string | null,
  baseUrl: string
) => {
  return initRequest(
    DATASET_MV_TERRITORIAL_CLASSIFICATIONS_VALUES_FETCH,
    getTerritorialClassificationsValuesUrl(nodeId),
    RequestMethod.POST,
    {
      items: territories,
      relationships: classifications,
      year: year
    },
    t => ({
      onStart: t("scenes.dataset.actions.fetchingTerritorialClassificationsValues")
    }),
    {layers: classifications},
    baseUrl
  );
};

export const showDatasetMVCriteria = () => ({
  type: DATASET_MV_CRITERIA_SHOW
});

export const hideDatasetMVCriteria = () => ({
  type: DATASET_MV_CRITERIA_HIDE
});

export const hideDatasetMVCriteriaAlert = () => ({
  type: DATASET_MV_CRITERIA_ALERT_HIDE
});

export const hideDatasetMVCriteriaObsCountWarning = () => ({
  type: DATASET_MV_CRITERIA_OBS_COUNT_WARNING_HIDE
});

export const fetchDatasetMVStructureCodelist = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  mode: string,
  type: string,
  dimensionId: string,
  criteria: {[key: string]: Criteria},
  freq: string,
  defaultLastNPeriods: number,
  preserveFiltersWithDynamic: boolean
) => {
  let flatCriteria = getCriteriaArrayFromObject(criteria);

  if (mode === CRITERIA_SELECTION_MODE_ALL) {
    return initRequest(
      DATASET_MV_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureCodelistsUrl(nodeId, datasetId, type === CRITERIA_SELECTION_TYPE_FULL),
      undefined,
      undefined,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelists")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        freq: freq,
        mode: mode,
        type: type
      }
    );
  } else if (type === CRITERIA_SELECTION_TYPE_DYNAMIC) {
    return initRequest(
      DATASET_MV_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureDynamicCodelistUrl(nodeId, datasetId, dimensionId),
      RequestMethod.POST,
      flatCriteria,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelist")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        preserveFiltersWithDynamic: preserveFiltersWithDynamic,
        freq: freq,
        mode: mode,
        type: type
      },
      undefined,
      (statusCode: number) => statusCode === 414
    );
  } else {
    return initRequest(
      DATASET_MV_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, type === CRITERIA_SELECTION_TYPE_FULL),
      undefined,
      undefined,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelist")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        freq: freq,
        mode: mode,
        type: type
      }
    );
  }
};

export const setDatasetMVStructureCriteria = (criteria: {[key: string]: Criteria}) => ({
  type: DATASET_MV_STRUCTURE_CRITERIA_SET,
  criteria
});

export const showDatasetMVTerritory = () => ({
  type: DATASET_MV_TERRITORY_SHOW
});

export const hideDatasetMVTerritory = () => ({
  type: DATASET_MV_TERRITORY_HIDE
});

export const fetchDatasetMVTerritoryTerritories = (
  nodeId: number,
  datasetId: string,
  territoryDim: string,
  detailLevel: number,
  baseUrl: string
) =>
  initRequest(
    DATASET_MV_TERRITORY_TERRITORIES_FETCH,
    getDatasetTerritories(nodeId, datasetId, territoryDim, detailLevel),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingTerritories")
    }),
    undefined,
    baseUrl
  );

export const submitDatasetMVTerritoryTerritories = (
  detailLevel: number,
  territories: string[],
  criteria: {[key: string]: Criteria}
) => ({
  type: DATASET_MV_TERRITORY_SUBMIT,
  payload: {
    detailLevel: detailLevel,
    territories: territories,
    criteria: criteria
  }
});

export const fetchDatasetMVTerritoryLastYear = (
  nodeId: number,
  datasetId: string,
  timeDim: string,
  criteria: {[key: string]: Criteria}
) => {
  if (criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_RANGE && criteria?.[timeDim]?.to) {
    return {
      type: DATASET_MV_TERRITORY_LAST_YEAR_FETCH,
      payload: {
        lastTerritoryYear: moment(criteria[timeDim].to).format("YYYY")
      }
    };
  } else {
    const newCriteria = {
      ...criteria,
      [timeDim]: undefined
    };

    return initRequest(
      DATASET_MV_TERRITORY_LAST_YEAR_FETCH,
      getDatasetStructureDynamicCodelistUrl(nodeId, datasetId, timeDim),
      RequestMethod.POST,
      getCriteriaArrayFromObject(newCriteria),
      t => ({
        onStart: t("scenes.dataset.actions.fetchingTimePeriod")
      })
    );
  }
};

export const setDatasetMVViewerTableVisibility = (isVisible: boolean) => ({
  type: DATASET_MV_VIEWER_TABLE_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVViewerMapVisibility = (isVisible: boolean) => ({
  type: DATASET_MV_VIEWER_MAP_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVViewerChartVisibility = (isVisible: boolean) => ({
  type: DATASET_MV_VIEWER_CHART_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVViewerChartType = (chartType: string) => ({
  type: DATASET_MV_VIEWER_CHART_TYPE_SET,
  chartType
});

export const submitDatasetMVMapLayout = (layout: any) => ({
  type: DATASET_MV_MAP_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetMVChartLayout = (layout: any) => ({
  type: DATASET_MV_CHART_LAYOUT_SUBMIT,
  layout
});

export const setDatasetMVChartLayoutTerritoryDimValues = (territoryDimValues: string[]) => ({
  type: DATASET_MV_CHART_LAYOUT_TERRITORY_DIM_VALUES_SET,
  territoryDimValues
});

export const setDatasetMVChartSettings = (chartSettings: any) => ({
  type: DATASET_MV_CHART_SETTINGS_SET,
  chartSettings
});

export const setDatasetMVMapSettings = (mapSettings: any) => ({
  type: DATASET_MV_MAP_SETTINGS_SET,
  mapSettings
});

export const submitDatasetMVDownload = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  datasetTitle: string,
  isPointData: boolean,
  criteria: {[key: string]: Criteria},
  timeDim: string,
  territoryDim: string,
  layout: any,
  indicators: Indicator[] | null,
  additionalDatasets: any[],
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any,
  indicatorsBaseUrl?: string
) => {
  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle,
      criteria: criteria,
      timeDim: timeDim,
      territoryDim: territoryDim,
      layout: layout
    },
    ...additionalDatasets
  ];

  let url,
    body,
    baseUrl = undefined;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetWithMarginalCustomDownloadUrl(format, zipped);
    body = isPointData
      ? getCustomExportRequestBody(
          nodeId,
          datasetId,
          datasetTitle,
          criteria,
          layout,
          params,
          defaultLanguage,
          languages,
          t
        )
      : getCustomExportRequestBodyWithMarginal(
          datasets,
          indicators,
          timeDim,
          layout,
          params,
          defaultLanguage,
          languages,
          t
        );
    baseUrl = indicatorsBaseUrl;
  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL ? "application/vnd.ms-excel" : "text/plain;charset=utf-8";

  return initRequest(
    DATASET_MV_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: datasets.map(({datasetId, datasetTitle}) => `${datasetTitle} (${datasetId})`).join(`, `),
        extension: extension,
        type: type
      }
    },
    baseUrl,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

export const hideDatasetMVDownloadWarning = () => ({
  type: DATASET_MV_DOWNLOAD_WARNING_HIDE
});

export const showDatasetMVViewTemplate = (isView: boolean) => ({
  type: DATASET_MV_VIEW_TEMPLATE_SHOW,
  isView
});

export const hideDatasetMVViewTemplate = (isView: boolean) => ({
  type: DATASET_MV_VIEW_TEMPLATE_HIDE,
  isView
});

export const submitDatasetMVViewTemplate = (nodeId: number, viewTemplate: any, isView: boolean) =>
  initRequest(
    DATASET_MV_VIEW_TEMPLATE_SUBMIT,
    isView ? getCreateViewUrl(nodeId) : getCreateTemplateUrl(nodeId),
    RequestMethod.POST,
    viewTemplate,
    t => ({
      onStart: isView ? t("scenes.dataset.actions.savingView") : t("scenes.dataset.actions.savingTemplate")
    }),
    {
      isView: isView
    },
    undefined,
    (statusCode: number) => statusCode === 409
  );

export const hideDatasetMVViewError = () => ({
  type: DATASET_MV_VIEW_ERROR_HIDE
});

export const hideDatasetMVUnavailableViewWarning = () => ({
  type: DATASET_MV_UNAVAILABLE_VIEW_WARNING_HIDE
});

export const setDatasetMVHtmlGeneratingTime = (time: number) => ({
  type: DATASET_MV_HTML_GENERATING_TIME_SET,
  time
});

export const showDatasetMVSdmxQuery = () => ({
  type: DATASET_MV_SDMX_QUERY_SHOW
});

export const hideDatasetMVSdmxQuery = () => ({
  type: DATASET_MV_SDMX_QUERY_HIDE
});

export const fetchDatasetMVSdmxQuery = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  datasetTitle: string
) =>
  initRequest(
    DATASET_MV_SDMX_QUERY_FETCH,
    getDatasetSdmxQueryUrl(nodeId, datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDatasetSdmxQuery", {title: datasetTitle})
    })
  );

export const setDatasetMVIndicatorCreateVisibility = (isVisible: boolean) => ({
  type: DATASET_MV_INDICATOR_CREATE_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVIndicatorListVisibility = (isVisible: boolean) => ({
  type: DATASET_MV_INDICATOR_LIST_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVAdditionalDatasetCreateVisibility = (isVisible: boolean) => ({
  type: DATASET_MV_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVAdditionalDatasetListVisibility = (isVisible: boolean) => ({
  type: DATASET_MV_ADDITIONAL_DATASET_LIST_VISIBILITY_SET,
  isVisible
});

export const fetchDatasetMVAdditionalDatasetCatalog = (
  selectedNodeId: number,
  mainDatasetId: string,
  baseUrl: string
) =>
  initRequest(
    DATASET_MV_ADDITIONAL_DATASET_CATALOG_FETCH,
    getNodeCatalogUrl(selectedNodeId),
    undefined,
    undefined,
    t => ({
      onStart: t("domains.catalog.messages.fetch.start")
    }),
    {
      selectedNodeId: selectedNodeId,
      mainDatasetId: mainDatasetId
    },
    baseUrl
  );

export const changeDatasetMVAdditionalDataset = (additionalDataset: any) => ({
  type: DATASET_MV_ADDITIONAL_DATASET_CHANGE,
  additionalDataset
});

export const fetchDatasetMVAdditionalDatasetStructure = (nodeId: number, datasetId: string) =>
  initRequest(
    DATASET_MV_ADDITIONAL_DATASET_STRUCTURE_FETCH,
    getDatasetStructureUrl(nodeId, datasetId),
    RequestMethod.GET,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingAdditionalDatasetStructure")
    })
  );

export const submitDatasetMVAdditionalDataset = () => ({
  type: DATASET_MV_ADDITIONAL_DATASET_SUBMIT
});

export const deleteDatasetMVAdditionalDataset = (datasetIdx: number) => ({
  type: DATASET_MV_ADDITIONAL_DATASET_DELETE,
  datasetIdx
});

export const setDatasetMVAdditionalDatasetCriteria = (datasetIdx: number, criteria: {[key: string]: Criteria}) => ({
  type: DATASET_MV_ADDITIONAL_DATASET_CRITERIA_SET,
  datasetIdx,
  criteria
});

export const fetchDatasetMVIndicatorPreview = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  timeDim: string,
  territoryDim: string,
  indicators: Indicator[],
  additionalDatasets: any[],
  defaultLanguage: string,
  languages: string[],
  indicatorsBaseUrl?: string
) => {
  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      criteria: criteria,
      timeDim: timeDim,
      territoryDim: territoryDim
    },
    ...additionalDatasets
  ];

  return initRequest(
    DATASET_MV_INDICATOR_PREVIEW_FETCH,
    getDatasetWithIndicatorsUrl(true),
    RequestMethod.POST,
    getIndicatorsDefinition(indicators, datasets, timeDim, defaultLanguage, languages),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingIndicatorPreview")
    }),
    undefined,
    indicatorsBaseUrl
  );
};

export const resetDatasetMVIndicatorPreview = () => ({
  type: DATASET_MV_INDICATOR_PREVIEW_RESET
});

export const publishDatasetMVIndicator = (indicator: Indicator) => ({
  type: DATASET_MV_INDICATOR_PUBLISH,
  indicator
});

export const deleteDatasetMVIndicator = (indicatorIdx: number) => ({
  type: DATASET_MV_INDICATOR_DELETE,
  indicatorIdx
});

export const hideDatasetMVIndicatorWarning = () => ({
  type: DATASET_MV_INDICATOR_WARNING_HIDE
});

export const setDatasetMVIndicatorArithmeticMeanVisibility = (visible: boolean) => ({
  type: DATASET_MV_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET,
  visible
});

export const setDatasetMVIndicatorArithmeticMeanValues = (
  arithmeticMeanDims: string[] | null,
  arithmeticMeans: {[key: string]: number} | null
) => ({
  type: DATASET_MV_INDICATOR_ARITHMETIC_MEAN_VALUES_SET,
  arithmeticMeanDims,
  arithmeticMeans
});

export const setDatasetMVIndicatorStandardDeviationVisibility = (visible: boolean) => ({
  type: DATASET_MV_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET,
  visible
});

export const setDatasetMVIndicatorCoefficientOfVariationVisibility = (visible: boolean) => ({
  type: DATASET_MV_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET,
  visible
});

export const submitDatasetMVDownloadMetadata = (nodeId: number, datasetId: string, datasetTitle: string) => {
  return initRequest(
    DATASET_MV_DOWNLOAD_METADATA_SUBMIT,
    getDatasetExportMetadataUrl(nodeId, datasetId),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDatasetMetadata")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: "html",
        type: "text/html;charset=utf-8"
      }
    }
  );
};

export const fetchDatasetMVStructureCodelistFull = (
  nodeId: number,
  datasetId: string,
  dimensionId: string,
  missingFilterValueIds: string[]
) =>
  initRequest(
    DATASET_MV_STRUCTURE_CODELIST_FULL_FETCH,
    getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, true),
    undefined,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingCodelist")
    }),
    {
      missingFilterValueIds: missingFilterValueIds
    }
  );

export const hideDatasetMVStructureCodelistFull = () => ({
  type: DATASET_MV_STRUCTURE_CODELIST_FULL_HIDE
});
