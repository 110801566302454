import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Call from "../../../hocs/call";
import ChartSettingsColors from "../../chart-settings-forms/Colors";
import ChartSettingsGeneral from "../../chart-settings-forms/General";
import ChartSettingsLayout from "../../chart-settings-forms/Layout";
import ChartJsonStatWrapper from "../../chart/ChartJsonStatWrapper";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import DatasetMergedFiltersJsonStatWrapper from "../../dataset-merged-filters/DatasetMergedFiltersJsonStatWrapper";
import MapSettingsSettings from "../../map-settings-form/Settings";
import MapJsonStatWrapper from "../../map/multi-viewer/MapJsonStatWrapper";
import Table from "../../table/multi-viewer-tmp";
import TerritorialClassificationsForm from "../../table/multi-viewer/TerritorialClassificationsForm";
import TablePointData from "../../table/point-data";
import {
  fetchDatasetMVTerritorialClassifications,
  fetchDatasetMVTerritorialClassificationsValues,
  hideDatasetMVIndicatorWarning,
  setDatasetMVChartLayoutTerritoryDimValues,
  setDatasetMVChartSettings,
  setDatasetMVHtmlGeneratingTime,
  setDatasetMVIndicatorArithmeticMeanValues,
  setDatasetMVMapSettings,
  setDatasetMVTerritorialClassificationsConfig,
  setDatasetMVViewerChartType,
  submitDatasetMVChartLayout,
  submitDatasetMVMapLayout
} from "../../../state/dataset/multi-viewer/actions";
import {usePrevious} from "../../../utils/customHooks";
import {
  getDimensionLabel,
  getDimensionValueLabel,
  getFilterTreeFromJsonStat,
  VARIATION_DIMENSION_KEY
} from "../../../utils/dataset";
import {numberFormatter} from "../../../utils/formatters";
import {getNode} from "../../../utils/tree";
import {getTimeDimValueFromLayout} from "../../map/utils";
import {DIM_VALUE_LABEL_MODIFIER_REMOVE, TABLE_HEADER_MERGED} from "../../table/multi-viewer-tmp/utils";

const $ = window.jQuery;

const viewerStyle = {
  width: "100%",
  height: "100%",
  position: "relative"
};

const mapStateToProps = ({config, appConfig, hub, dataset}) => ({
  geoglossaryBaseUrl: config.externalServices?.geoglossary,
  maxChartTerritoryValueCount: appConfig.chartConfig.maxTerritoryValueCount,
  maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
  dataset: dataset.multiViewer.dataset,
  datasetFetchStart: dataset.multiViewer.datasetFetchStart,
  datasetFetchError: dataset.multiViewer.datasetFetchError,
  isPartialData: dataset.multiViewer.isPartialData,
  isEmptyData: dataset.multiViewer.isEmptyData,
  isTooBigData: dataset.multiViewer.isTooBigData,
  isPointData: dataset.multiViewer.isPointData,
  latAttributeId: dataset.multiViewer.latAttributeId,
  longAttributeId: dataset.multiViewer.longAttributeId,
  srid: dataset.multiViewer.srid,
  dimensions: dataset.multiViewer.dimensions,
  territoryDimCodelist: dataset.multiViewer.territoryDimCodelist,
  territoryDim: dataset.multiViewer.territoryDim,
  timeDim: dataset.multiViewer.timeDim,
  pointDim: dataset.multiViewer.pointDim,
  isTableVisible: dataset.multiViewer.isTableVisible,
  isMapVisible: dataset.multiViewer.isMapVisible,
  isChartVisible: dataset.multiViewer.isChartVisible,
  chartType: dataset.multiViewer.chartType,
  tableLayout: dataset.multiViewer.tableLayout,
  mapLayout: dataset.multiViewer.mapLayout,
  chartLayout: dataset.multiViewer.chartLayout,
  mapFilterTree: dataset.multiViewer.mapFilterTree,
  chartFilterTree: dataset.multiViewer.chartFilterTree,
  timePeriodsByFreq: dataset.multiViewer.timePeriodsByFreq,
  labelFormat: dataset.multiViewer.labelFormat,
  criteria: dataset.multiViewer.criteria,
  decimalSeparator: dataset.multiViewer.decimalSeparator,
  roundingStrategy: dataset.multiViewer.roundingStrategy,
  decimalPlaces: dataset.multiViewer.decimalPlaces,
  tableEmptyChar: dataset.multiViewer.tableEmptyChar,
  chartSettings: dataset.multiViewer.chartSettings,
  mapSettings: dataset.multiViewer.mapSettings,
  detailLevelTree: dataset.multiViewer.detailLevelTree,
  detailLevel: dataset.multiViewer.detailLevel,
  territorialClassifications: dataset.multiViewer.territorialClassifications,
  territorialClassificationsConfig: dataset.multiViewer.territorialClassificationsConfig,
  territorialClassificationsFetched: dataset.multiViewer.territorialClassificationsFetched,
  territorialClassificationsValues: dataset.multiViewer.territorialClassificationsValues,
  missingIndicators: dataset.multiViewer.missingIndicators,
  showArithmeticMean: dataset.multiViewer.showArithmeticMean,
  arithmeticMeans: dataset.multiViewer.arithmeticMeans,
  arithmeticMeanDims: dataset.multiViewer.arithmeticMeanDims,
  showStandardDeviation: dataset.multiViewer.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewer.showCoefficientOfVariation,
  geometriesYear: dataset.multiViewer.geometriesYear,
  geometriesYears: dataset.multiViewer.geometriesYears
});

const mapDispatchToProps = dispatch => ({
  onChartTerritoryDimValuesSet: territoryDimValues =>
    dispatch(setDatasetMVChartLayoutTerritoryDimValues(territoryDimValues)),
  onChartSettingsSet: chartSetting => dispatch(setDatasetMVChartSettings(chartSetting)),
  onChartLayoutSet: layout => dispatch(submitDatasetMVChartLayout(layout)),
  onChartTypeSet: type => dispatch(setDatasetMVViewerChartType(type)),
  onMapSettingsSet: mapSettings => dispatch(setDatasetMVMapSettings(mapSettings)),
  onMapLayoutSet: layout => dispatch(submitDatasetMVMapLayout(layout)),
  fetchTerritorialClassifications: (detailLevel, baseUrl) =>
    dispatch(fetchDatasetMVTerritorialClassifications(detailLevel, baseUrl)),
  setTerritorialClassificationsConfig: territorialClassificationsConfig =>
    dispatch(setDatasetMVTerritorialClassificationsConfig(territorialClassificationsConfig)),
  fetchTerritorialClassificationsValues: (nodeId, territories, classifications, year, baseUrl) =>
    dispatch(fetchDatasetMVTerritorialClassificationsValues(nodeId, territories, classifications, year, baseUrl)),
  onIndicatorWarningHide: layout => dispatch(hideDatasetMVIndicatorWarning(layout)),
  onArithmeticMeanValuesSet: (arithmeticMeanDims, arithmeticMeans) =>
    dispatch(setDatasetMVIndicatorArithmeticMeanValues(arithmeticMeanDims, arithmeticMeans)),
  onTimeSet: time => dispatch(setDatasetMVHtmlGeneratingTime(time))
});

const handleStyle = mapId => {
  const viewerHeaderHeight = $("#data-viewer__viewer__header").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers").height(`calc(100% - ${viewerHeaderHeight}px)`);

  const mapSettingsWidth = $("#data-viewer__viewer__viewers__map__settings").outerWidth(true) || 0;
  $("#data-viewer__viewer__viewers__map__filters").css({maxWidth: `calc(100% - ${mapSettingsWidth}px)`});

  const mapFiltersAndSettingsHeight =
    $("#data-viewer__viewer__viewers__map__filters-settings-container").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers__map__viewer").height(`calc(100% - ${mapFiltersAndSettingsHeight}px)`);

  const chartSettingsWidth = $("#data-viewer__viewer__viewers__chart__settings").outerWidth(true) || 0;
  $("#data-viewer__viewer__viewers__chart__filters").css({maxWidth: `calc(100% - ${chartSettingsWidth}px)`});

  const chartFiltersAndSettingsHeight =
    $("#data-viewer__viewer__viewers__chart__filters-settings-container").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers__chart__viewer").height(`calc(100% - ${chartFiltersAndSettingsHeight}px)`);

  if (window.LMap && mapId) {
    window.LMap.handleViewportChange(mapId);
  }
};

function MultiViewerViewer(props) {
  const {
    nodeId,
    nodeExtras,
    chartId,
    mapId,

    geoglossaryBaseUrl,
    maxChartTerritoryValueCount,
    maxObservations,
    dataset,
    datasetFetchStart,
    datasetFetchError,
    isPartialData,
    isEmptyData,
    isTooBigData,
    isPointData,
    latAttributeId,
    longAttributeId,
    srid,
    dimensions,
    territoryDimCodelist,
    territoryDim,
    timeDim,
    pointDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    chartType,
    tableLayout,
    mapLayout,
    chartLayout,
    mapFilterTree,
    chartFilterTree,
    timePeriodsByFreq,
    labelFormat,
    criteria,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    detailLevelTree,
    detailLevel,
    territorialClassifications,
    territorialClassificationsConfig,
    territorialClassificationsFetched,
    territorialClassificationsValues,
    missingIndicators,
    showArithmeticMean,
    arithmeticMeans,
    arithmeticMeanDims,
    showStandardDeviation,
    showCoefficientOfVariation,
    geometriesYear: geometriesYearConfig,
    geometriesYears: geometriesYearsConfig,

    onChartTerritoryDimValuesSet,
    onChartSettingsSet,
    onChartLayoutSet,
    onChartTypeSet,
    onMapSettingsSet,
    setTerritorialClassificationsConfig,
    fetchTerritorialClassifications,
    fetchTerritorialClassificationsValues,
    onMapLayoutSet,
    onIndicatorWarningHide,
    onArithmeticMeanValuesSet,
    onTimeSet
  } = props;

  const {t} = useTranslation();

  const [isChartSettingVisible, setChartSettingsVisibility] = useState(false);
  const [chartSettingsTabId, setChartSettingsTabId] = useState(null);
  const [tmpChartSettings, setTmpChartSettings] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);
  const [tmpChartType, setTmpChartType] = useState(null);

  const [isMapSettingVisible, setMapSettingsVisibility] = useState(false);
  const [tmpMapSettings, setTmpMapSettings] = useState(null);
  const [isMapSettingsValid, setMapSettingsValidity] = useState(true);
  const [isMapRendering, setIsMapRendering] = useState(true);

  const [tableDimensionFilterValues, setTableDimensionFilterValues] = useState(null);
  const [tableHighlightedRowsDimValues, setTableHighlightedRowsDimValues] = useState(null);
  const [tableFirstRowDimValues, setTableFirstRowDimValues] = useState(null);

  const [selectedTerritoryId, setSelectedTerritoryId] = useState(null);

  const [filteredTerritoryIds, setFilteredTerritoryIds] = useState(null);

  const [dimensionValueModifiers, setDimensionValueModifiers] = useState({
    [territoryDim]: {
      [DIM_VALUE_LABEL_MODIFIER_REMOVE]:
        getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || null
    }
  });

  const invertedDims = useMemo(() => (timeDim ? [timeDim] : null), [timeDim]);

  const [referenceYear, setReferenceYear] = useState(
    getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.referenceYear || null
  );

  const [generalizationLevels, setGeneralizationLevels] = useState(
    getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.generalizationLevels || []
  );

  const hierarchyOnlyAttributes = useMemo(() => nodeExtras?.HierarchyOnlyAttributes || [], [nodeExtras]);
  const hideHierarchyOnlyRows = useMemo(() => nodeExtras?.HideHierarchyOnlyRows || false, [nodeExtras]);

  const dimensionIds = useMemo(() => (dataset?.id ?? []).filter(dim => dim !== VARIATION_DIMENSION_KEY), [dataset]);

  const geometriesYear = useMemo(() => {
    if (referenceYear) {
      return referenceYear;
    } else {
      const timeVal = getTimeDimValueFromLayout(timeDim, mapLayout);
      return geometriesYearsConfig?.[timeVal] ?? geometriesYearConfig ?? timeVal;
    }
  }, [mapLayout, timeDim, referenceYear, geometriesYearsConfig, geometriesYearConfig]);

  useEffect(() => {
    const func = () => handleStyle(mapId);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [mapId]);

  useEffect(() => {
    handleStyle(mapId);
    window.dispatchEvent(new Event("resize"));
  }, [mapId, dataset, isTableVisible, isMapVisible, isChartVisible, isMapRendering]);

  useEffect(() => {
    setTableDimensionFilterValues(null);
    setSelectedTerritoryId(null);
  }, [dataset]);

  useEffect(() => {
    if (!selectedTerritoryId) {
      setTableHighlightedRowsDimValues(null);
    } else if (isPointData) {
      setTableHighlightedRowsDimValues({[pointDim]: selectedTerritoryId});
    } else {
      setTableHighlightedRowsDimValues({[territoryDim]: selectedTerritoryId});
    }
  }, [selectedTerritoryId, territoryDim, isPointData, pointDim, mapLayout]);

  useEffect(() => {
    setDimensionValueModifiers(prevValue => {
      const detailLevelSuffix =
        getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || null;

      return prevValue?.[territoryDim]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] !== detailLevelSuffix
        ? {[territoryDim]: {[DIM_VALUE_LABEL_MODIFIER_REMOVE]: detailLevelSuffix}}
        : prevValue;
    });
  }, [territoryDim, detailLevelTree, detailLevel]);

  useEffect(() => {
    if (detailLevelTree && detailLevel) {
      setReferenceYear(getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.referenceYear || null);
    }
  }, [detailLevelTree, detailLevel]);

  useEffect(() => {
    if (detailLevelTree && detailLevel) {
      setGeneralizationLevels(
        getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.generalizationLevels || []
      );
    }
  }, [detailLevelTree, detailLevel]);

  const onTablePageGenerationComplete = useCallback(
    tableStructures => {
      if ((tableStructures?.renderedRows || []).length > 0) {
        const chartTerritoryDim = isPointData ? pointDim : territoryDim;
        onChartTerritoryDimValuesSet(
          tableStructures.renderedRows
            .map(renderedRow => renderedRow.split("+")[tableStructures.layout.rows.indexOf(chartTerritoryDim)])
            .filter((val, idx, arr) => idx === arr.indexOf(val))
            .slice(0, maxChartTerritoryValueCount)
        );
      }

      const timings = tableStructures.timings;
      if (timings) {
        onTimeSet(Math.round(tableStructures.timings * 100) / 100);
      }
    },
    [maxChartTerritoryValueCount, onTimeSet, onChartTerritoryDimValuesSet, territoryDim, isPointData, pointDim]
  );

  const onTableStructureGenerationComplete = useCallback(
    tableStructures => {
      const {arithmeticMeanDims = null, arithmeticMeans = null} = tableStructures || {};

      onArithmeticMeanValuesSet(arithmeticMeanDims, arithmeticMeans);
    },
    [onArithmeticMeanValuesSet]
  );

  const handleMapSelect = useCallback(
    terrDimValues => {
      if ((terrDimValues || []).length > 0) {
        if (isPointData) {
          setTableDimensionFilterValues({[pointDim]: terrDimValues});
        } else {
          setTableDimensionFilterValues({[territoryDim]: terrDimValues});
        }
      } else {
        setTableDimensionFilterValues(null);
      }
    },
    [territoryDim, isPointData, pointDim]
  );

  const handleMapClick = useCallback(
    (geometry, isActive, isSelected) => {
      if (!geometry) {
        setSelectedTerritoryId(null);
      } else if (geometry && isActive) {
        const territoryId = geometry.territoryId;
        if (!isSelected) {
          setSelectedTerritoryId(null);
          setTableFirstRowDimValues(null);
        } else if (isPointData) {
          setSelectedTerritoryId(territoryId);
          setTableFirstRowDimValues({[pointDim]: territoryId});
        } else {
          setSelectedTerritoryId(territoryId);
          setTableFirstRowDimValues({[territoryDim]: territoryId});
        }
      }
    },
    [territoryDim, isPointData, pointDim]
  );

  const handleTableRowClick = useCallback(
    (clickedRow, isActive) => {
      const territoryId = isPointData ? clickedRow[pointDim] : clickedRow[territoryDim];
      setSelectedTerritoryId(isActive ? territoryId : null);
    },
    [territoryDim, isPointData, pointDim]
  );

  const onMapRenderStart = useCallback(() => {
    setIsMapRendering(true);
  }, []);

  const onMapRenderFinish = useCallback(() => {
    setIsMapRendering(false);
  }, []);

  const handleTableFilter = useCallback(
    filteredTerritories => {
      if (!filteredTerritories) {
        setFilteredTerritoryIds(null);
      } else {
        const mapDim = isPointData ? pointDim : territoryDim;
        const mapDimIdx = tableLayout.rows.indexOf(mapDim);
        setFilteredTerritoryIds(filteredTerritories.map(filteredTerritory => filteredTerritory.split("+")[mapDimIdx]));
      }
    },
    [tableLayout, territoryDim, isPointData, pointDim]
  );

  const handleChartSettingsOpen = () => {
    setTmpChartSettings(chartSettings);
    setTmpChartLayout(chartLayout);
    setTmpChartType(chartType);
    setChartSettingsTabId("layout");
    setChartSettingsVisibility(true);
  };

  const handleChartSettingsClose = () => {
    setChartSettingsVisibility(false);
  };

  const handleChartSettingsSubmit = () => {
    setChartSettingsVisibility(false);
    onChartSettingsSet(tmpChartSettings);
    onChartLayoutSet(tmpChartLayout);
    onChartTypeSet(tmpChartType);
  };

  const handleMapSettingsOpen = settings => {
    setTmpMapSettings(settings);
    setMapSettingsValidity(true);
    setMapSettingsVisibility(true);
  };

  const handleMapSettingsClose = () => {
    setMapSettingsVisibility(false);
  };

  const handleMapSettingsSubmit = () => {
    setMapSettingsVisibility(false);
    onMapSettingsSet(tmpMapSettings);
  };

  const [terrClassConfigVisibility, setTerrClassConfigVisibility] = useState(false);
  const [terrClassConfigTmp, setTerrClassConfigTmp] = useState(null);
  const prevTerritorialClassificationsConfig = usePrevious(territorialClassificationsConfig);

  const handleTerrClassConfigShow = useCallback(() => {
    setTerrClassConfigVisibility(true);
    setTerrClassConfigTmp(territorialClassificationsConfig);
  }, [territorialClassificationsConfig]);

  const handleTerrClassConfigHide = useCallback(() => {
    setTerrClassConfigVisibility(false);
  }, []);

  const handleTerrClassFetch = useCallback(() => {
    fetchTerritorialClassifications(detailLevel, geoglossaryBaseUrl);
  }, [detailLevel, geoglossaryBaseUrl, fetchTerritorialClassifications]);

  const handleTerrClassConfigSubmit = useCallback(
    newConfig => {
      setTerrClassConfigVisibility(false);
      setTerritorialClassificationsConfig(newConfig);
    },
    [setTerritorialClassificationsConfig]
  );

  useEffect(() => {
    if (
      (territorialClassificationsConfig ?? []).length !== (prevTerritorialClassificationsConfig ?? []).length ||
      (territorialClassificationsConfig ?? []).some(
        (config, idx) => !_.isEqual(config, prevTerritorialClassificationsConfig[idx])
      )
    ) {
      const missingClassifications = (territorialClassificationsConfig ?? [])
        .map(({id}) => id)
        .filter(level => !(territorialClassificationsFetched ?? []).includes(level));
      if (missingClassifications.length > 0) {
        const terrValues = criteria?.[territoryDim]?.filterValues ?? [];
        fetchTerritorialClassificationsValues(nodeId, terrValues, missingClassifications, null, geoglossaryBaseUrl);
      }
    }
  }, [
    prevTerritorialClassificationsConfig,
    territorialClassificationsConfig,
    territorialClassificationsFetched,
    nodeId,
    criteria,
    territoryDim,
    geoglossaryBaseUrl,
    fetchTerritorialClassificationsValues
  ]);

  if (!dataset) {
    let text = "";
    if (datasetFetchStart) {
      text = "";
    } else if (datasetFetchError) {
      text = t("scenes.dataViewer.errors.fetchData");
    } else if (isEmptyData) {
      text = t("scenes.dataViewer.errors.emptyData");
    } else if (dimensions) {
      text = t("scenes.dataViewer.errors.applyCriteria");
    }
    return (
      <Card sx={viewerStyle}>
        <CustomEmpty text={text} />
      </Card>
    );
  }

  const leftPanelWidth = isMapVisible || isChartVisible ? (isTableVisible ? "60%" : "100%") : "0%";
  const rightPanelWidth = `calc(100% - ${leftPanelWidth})`;

  const leftPanelTopHeight = isMapVisible ? (isChartVisible ? "60%" : "100%") : "0%";
  const leftPanelBottomHeight = `calc(100% - ${leftPanelTopHeight})`;

  return (
    <Fragment>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          padding: "8px"
        }}
      >
        {dataset && isPartialData && (
          <Box
            id="data-viewer__viewer__header"
            sx={{
              width: "100%",
              marginBottom: "8px"
            }}
          >
            <Alert severity="warning">
              {t("scenes.dataViewer.warnings.maxObservations.label", {
                maxObservations: maxObservations ? numberFormatter(maxObservations) : ""
              })}
            </Alert>
          </Box>
        )}
        <Box
          id="data-viewer__viewer__viewers"
          sx={{
            width: "100%",
            position: "relative"
          }}
        >
          {(isMapVisible || isChartVisible) && (
            <Box sx={{display: "inline-block", verticalAlign: "top", height: "100%", width: leftPanelWidth}}>
              {isMapVisible && (
                <Box sx={{height: leftPanelTopHeight, width: "100%", padding: "8px"}}>
                  <Grid
                    container
                    id="data-viewer__viewer__viewers__map__filters-settings-container"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{marginBottom: "4px"}}
                    wrap="nowrap"
                  >
                    <Grid item id="data-viewer__viewer__viewers__map__filters">
                      <DatasetMergedFiltersJsonStatWrapper
                        jsonStat={dataset}
                        layout={mapLayout}
                        filterTree={mapFilterTree}
                        timeDim={timeDim}
                        onChange={onMapLayoutSet}
                      />
                    </Grid>
                    <Grid item id="data-viewer__viewer__viewers__map__settings" sx={{height: "30px"}}>
                      {!isMapRendering && (
                        <Tooltip title={t("scenes.dataViewer.actions.mapSettings.tooltip")}>
                          <IconButton
                            aria-label={t("scenes.dataViewer.actions.mapSettings.ariaLabel")}
                            size={"small"}
                            onClick={() => handleMapSettingsOpen(mapSettings)}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Box id="data-viewer__viewer__viewers__map__viewer" sx={viewerStyle}>
                    <MapJsonStatWrapper
                      mapId={mapId}
                      nodeId={nodeId}
                      dimensionIds={dimensionIds}
                      geometriesYear={geometriesYear}
                      layout={mapLayout}
                      getFilterTree={getFilterTreeFromJsonStat}
                      data={dataset}
                      decimalSeparator={decimalSeparator}
                      roundingStrategy={roundingStrategy}
                      decimalPlaces={decimalPlaces}
                      detailLevel={detailLevel}
                      territoryDimCodelist={territoryDimCodelist}
                      initialBaseLayer={mapSettings.baseLayer}
                      defaultSettings={{
                        isLegendCollapsed: mapSettings.isLegendCollapsed,
                        opacity: mapSettings.opacity
                      }}
                      settings={{
                        classificationMethod: mapSettings.classificationMethod,
                        paletteStartColor: mapSettings.paletteStartColor,
                        paletteEndColor: mapSettings.paletteEndColor,
                        paletteCardinality: mapSettings.paletteCardinality,
                        customIntervals: mapSettings.customIntervals
                      }}
                      setSettings={onMapSettingsSet}
                      showSelection
                      disableSettings
                      onDataClick={handleMapClick}
                      onDataSelect={handleMapSelect}
                      selectedTerritoryId={selectedTerritoryId}
                      filteredTerritoryIds={filteredTerritoryIds}
                      onSettingsShow={handleMapSettingsOpen}
                      onRenderStart={onMapRenderStart}
                      onRenderFinish={onMapRenderFinish}
                      showSingleGeometry
                      isPointData={isPointData}
                      latAttributeId={latAttributeId}
                      longAttributeId={longAttributeId}
                      srid={srid}
                      generalizationLevels={generalizationLevels}
                    />
                  </Box>
                </Box>
              )}
              {isChartVisible && (
                <Box sx={{height: leftPanelBottomHeight, width: "100%", padding: "8px"}}>
                  <Grid
                    container
                    id="data-viewer__viewer__viewers__chart__filters-settings-container"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{marginBottom: "4px"}}
                  >
                    <Grid item id="data-viewer__viewer__viewers__chart__filters">
                      <DatasetMergedFiltersJsonStatWrapper
                        jsonStat={dataset}
                        layout={chartLayout}
                        filterTree={chartFilterTree}
                        timeDim={timeDim}
                        onChange={onChartLayoutSet}
                      />
                    </Grid>
                    <Grid item id="data-viewer__viewer__viewers__chart__settings" sx={{height: "30px"}}>
                      <Tooltip title={t("scenes.dataViewer.actions.chartSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.chartSettings.ariaLabel")}
                          size={"small"}
                          onClick={handleChartSettingsOpen}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Box id="data-viewer__viewer__viewers__chart__viewer" sx={viewerStyle}>
                    <ChartJsonStatWrapper
                      chartId={chartId}
                      type={chartType}
                      jsonStat={dataset}
                      layout={chartLayout}
                      timePeriodsByFreq={timePeriodsByFreq}
                      decimalSeparator={decimalSeparator}
                      roundingStrategy={roundingStrategy}
                      decimalPlaces={decimalPlaces}
                      chartSettings={chartSettings}
                      showArithmeticMean={showArithmeticMean}
                      arithmeticMeanDims={arithmeticMeanDims}
                      arithmeticMeans={arithmeticMeans}
                      invertedDims={invertedDims}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {isTableVisible && (
            <Box sx={{display: "inline-block", verticalAlign: "top", height: "100%", width: rightPanelWidth}}>
              <Box
                id="data-viewer__viewer__viewers__table__viewer"
                sx={viewerStyle}
                style={{height: "100%", width: "100%", padding: 8}}
              >
                {isTooBigData ? (
                  <CustomEmpty text={t("scenes.dataViewer.errors.tooBigData")} />
                ) : isPointData ? (
                  <TablePointData
                    jsonStat={dataset}
                    tableHeaderType={TABLE_HEADER_MERGED}
                    layout={tableLayout}
                    rowHover
                    labelFormat={labelFormat}
                    splitRowDimensions={!isPointData}
                    dimensionValueModifiers={dimensionValueModifiers}
                    decimalSeparator={decimalSeparator}
                    decimalPlaces={decimalPlaces}
                    emptyChar={tableEmptyChar}
                    dimensionFilterValues={tableDimensionFilterValues}
                    firstRowDimValues={tableFirstRowDimValues}
                    highlightedRowsDimValues={tableHighlightedRowsDimValues}
                    onRowClick={handleTableRowClick}
                    scrollToLastRow
                    filterable
                    onFilter={handleTableFilter}
                    invertedDims={invertedDims}
                    enableMeasuresOfSynthesisAndVariability
                    showArithmeticMean={showArithmeticMean}
                    showStandardDeviation={showStandardDeviation}
                    showCoefficientOfVariation={showCoefficientOfVariation}
                    onPageGenerationComplete={onTablePageGenerationComplete}
                    onStructureGenerationComplete={onTableStructureGenerationComplete}
                    hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                    hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                    showTerritorialClassificationsConfig={handleTerrClassConfigShow}
                    setTerritorialClassificationsConfig={handleTerrClassConfigSubmit}
                    territorialClassificationsValues={territorialClassificationsValues}
                    territorialClassificationsConfig={territorialClassificationsConfig}
                  />
                ) : (
                  <Table
                    jsonStat={dataset}
                    tableHeaderType={TABLE_HEADER_MERGED}
                    layout={tableLayout}
                    rowHover
                    labelFormat={labelFormat}
                    splitRowDimensions={!isPointData}
                    dimensionValueModifiers={dimensionValueModifiers}
                    decimalSeparator={decimalSeparator}
                    roundingStrategy={roundingStrategy}
                    decimalPlaces={decimalPlaces}
                    emptyChar={tableEmptyChar}
                    dimensionFilterValues={tableDimensionFilterValues}
                    firstRowDimValues={tableFirstRowDimValues}
                    highlightedRowsDimValues={tableHighlightedRowsDimValues}
                    onRowClick={handleTableRowClick}
                    scrollToLastRow
                    filterable
                    onFilter={handleTableFilter}
                    isPointData={isPointData}
                    invertedDims={invertedDims}
                    enableMeasuresOfSynthesisAndVariability
                    showArithmeticMean={showArithmeticMean}
                    showStandardDeviation={showStandardDeviation}
                    showCoefficientOfVariation={showCoefficientOfVariation}
                    onPageGenerationComplete={onTablePageGenerationComplete}
                    onStructureGenerationComplete={onTableStructureGenerationComplete}
                    hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                    hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                    showTerritorialClassificationsConfig={handleTerrClassConfigShow}
                    setTerritorialClassificationsConfig={handleTerrClassConfigSubmit}
                    territorialClassificationsValues={territorialClassificationsValues}
                    territorialClassificationsConfig={territorialClassificationsConfig}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Card>

      <Dialog open={isChartSettingVisible} maxWidth="md" fullWidth onClose={handleChartSettingsClose}>
        <CustomDialogTitle onClose={handleChartSettingsClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.chartSettings.title")}
        </CustomDialogTitle>
        <DialogContent sx={{height: "500px"}}>
          <Box sx={{height: "48px"}}>
            <Tabs
              value={chartSettingsTabId}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => setChartSettingsTabId(newValue)}
            >
              <Tab value={"layout"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.layout")} />
              <Tab value={"general"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.general")} />
              <Tab value={"colors"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.colors")} />
            </Tabs>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 64px)",
              marginTop: "16px",
              paddingRight: "4px",
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            {chartSettingsTabId === "layout" && (
              <ChartSettingsLayout
                layout={tmpChartLayout}
                onLayoutSet={setTmpChartLayout}
                type={tmpChartType}
                onTypeSet={setTmpChartType}
                getDimLabel={dim => getDimensionLabel(dataset, null, dim)}
              />
            )}
            {chartSettingsTabId === "general" && (
              <ChartSettingsGeneral settings={tmpChartSettings} onSettingsSet={setTmpChartSettings} />
            )}
            {chartSettingsTabId === "colors" && (
              <ChartSettingsColors
                settings={tmpChartSettings}
                dimensions={[...(chartLayout?.primaryDim || []), ...(chartLayout?.secondaryDim || [])]}
                onSettingsSet={setTmpChartSettings}
                dataHasDimension={dim => dataset.id.includes(dim)}
                dataHasDimensionValue={(dim, dimVal) => dataset.dimension[dim].category.index.includes(dimVal)}
                getDimLabel={dimensionId => getDimensionLabel(dataset, null, dimensionId, t)}
                getDimValueLabel={(dimensionId, valueId) =>
                  getDimensionValueLabel(dataset, null, dimensionId, valueId, t)
                }
                getDimensionValues={dim => dataset.dimension[dim].category.index || []}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChartSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button autoFocus onClick={handleChartSettingsSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isMapSettingVisible} maxWidth="md" fullWidth onClose={handleMapSettingsClose}>
        <CustomDialogTitle onClose={handleMapSettingsClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.mapSettings.title")}
        </CustomDialogTitle>
        <DialogContent>
          <MapSettingsSettings
            mapId={mapId}
            settings={tmpMapSettings}
            onSettingsSet={setTmpMapSettings}
            setSettingsValidity={setMapSettingsValidity}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMapSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button autoFocus onClick={handleMapSettingsSubmit} color="primary" disabled={!isMapSettingsValid}>
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={missingIndicators !== null} maxWidth="md" onClose={onIndicatorWarningHide}>
        <CustomDialogTitle onClose={onIndicatorWarningHide}>
          {t("scenes.dataViewer.multiViewer.dialogs.missingIndicator.title")}
        </CustomDialogTitle>
        <DialogContent>
          {t("scenes.dataViewer.multiViewer.dialogs.missingIndicator.content", {
            missingIndicators: (missingIndicators || []).join(", ")
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onIndicatorWarningHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={terrClassConfigVisibility} maxWidth="md" onClose={handleTerrClassConfigHide}>
        <CustomDialogTitle onClose={handleTerrClassConfigHide}>
          {t("components.table.dialogs.territorialClassificationsConfig.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Call cb={handleTerrClassFetch} disabled={territorialClassifications !== null}>
            <TerritorialClassificationsForm
              territorialClassifications={territorialClassifications}
              config={terrClassConfigTmp}
              setConfig={setTerrClassConfigTmp}
            />
          </Call>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTerrClassConfigHide}>{t("commons.confirm.close")}</Button>
          {(territorialClassifications || []).length > 0 && (
            <Button onClick={() => handleTerrClassConfigSubmit(terrClassConfigTmp)}>
              {t("commons.confirm.confirm")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewerViewer);
