import React, {Fragment, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../../custom-dialog-title";
import MaterialTable from "../../material-table";

const AdditionalDatasetListDialog = ({isOpen, onClose, additionalDatasets, onDelete}) => {
  const {t} = useTranslation();

  const [datasetIdx, setDatasetIdx] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete(datasetIdx);
    setDatasetIdx(null);
  };

  return (
    <Fragment>
      <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
        <CustomDialogTitle onClose={handleClose}>
          {t("components.additionalDatasetDialog.list.dialogs.list.title")}
        </CustomDialogTitle>
        <DialogContent sx={{height: "480px"}}>
          <MaterialTable
            hideHeader
            columns={[
              {
                field: "datasetTitle"
              }
            ]}
            data={(additionalDatasets || []).map((ds, idx) => ({...ds, idx: idx}))}
            actions={[
              {
                icon: <DeleteIcon />,
                tooltip: t("components.additionalDatasetDialog.list.dialogs.list.actions.delete"),
                onClick: (_, {idx}) => {
                  setDatasetIdx(idx);
                }
              }
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={datasetIdx !== null} maxWidth="xs" onClose={() => setDatasetIdx(null)}>
        <CustomDialogTitle onClose={() => setDatasetIdx(null)}>
          {t("components.additionalDatasetDialog.list.dialogs.deleteDataset.title")}
        </CustomDialogTitle>
        <DialogContent>{t("components.additionalDatasetDialog.list.dialogs.deleteDataset.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setDatasetIdx(null)} autoFocus>
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleDelete}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AdditionalDatasetListDialog;
