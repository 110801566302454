import {Box, Breadcrumbs} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getHomeInternalUrl, getNodeInternalUrl} from "../../links";
import CustomLink from "../custom-link";
import {hubSelector} from "../../state/hub/hubSelectors";
import {nodeCodeSelector, nodeNameSelector} from "../../state/node/nodeSelectors";

const elementStyle = {
  padding: "4px"
};

const CustomBreadcrumbs = ({sx = {}, children = []}) => {
  const {t} = useTranslation();
  const hub = useSelector(hubSelector);
  const nodeCode = useSelector(nodeCodeSelector);
  const nodeName = useSelector(nodeNameSelector);

  return (
    <Breadcrumbs
      aria-label={t("components.customBreadcrumbs.ariaLabel")}
      sx={[
        {
          display: "flex",
          padding: "8px 0"
        },
        {
          "& .MuiBreadcrumbs-separator": {
            margin: "0 4px"
          }
        },
        sx
      ]}
    >
      {hub && (
        <CustomLink
          key={"hub-home"}
          to={getHomeInternalUrl()}
          text={t("components.customBreadcrumbs.links.home")}
          textStyle={elementStyle}
        />
      )}
      {nodeCode && (
        <CustomLink
          key={nodeCode}
          to={getNodeInternalUrl(nodeCode)}
          text={nodeName ?? nodeCode}
          textStyle={elementStyle}
        />
      )}
      {children
        .filter(child => !!child)
        .map(({to, label}, idx) =>
          !!to ? (
            <CustomLink key={idx} to={to} text={label} textStyle={elementStyle} />
          ) : (
            <Box
              key={idx}
              component="span"
              sx={[
                elementStyle,
                {
                  color: theme => theme.palette.text.primary
                }
              ]}
            >
              {label}
            </Box>
          )
        )}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
