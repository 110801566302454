import {useTheme} from "@emotion/react";
import {Box, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import {getHomeInternalUrl} from "../../links";

const HubName = ({hub}) => {
  const theme = useTheme();

  if ((hub.name || "").length > 0) {
    return (
      <Grid item className="hub-name">
        <Link
          to={getHomeInternalUrl()}
          style={{
            fontSize: "20px",
            fontWeight: "500px",
            color: theme.palette.secondary.main,
            textDecoration: "none"
          }}
        >
          <Box
            component="span"
            sx={{
              height: "48px",
              padding: "8px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
          >
            {hub.name}
          </Box>
        </Link>
      </Grid>
    );
  } else {
    return null;
  }
};

export default HubName;
