import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import MapIcon from "@mui/icons-material/Map";
import PieChartIcon from "@mui/icons-material/PieChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import {
  CHART_TYPE_AREA,
  CHART_TYPE_BAR,
  CHART_TYPE_DOUGHNUT,
  CHART_TYPE_HORIZONTAL_BAR,
  CHART_TYPE_LINE,
  CHART_TYPE_PIE,
  CHART_TYPE_POLAR_AREA,
  CHART_TYPE_PYRAMID,
  CHART_TYPE_RADAR
} from "../chart/constants";
import AreaIcon from "../custom-icons/AreaIcon";
import PolarIcon from "../custom-icons/PolarIcon";
import PyramidIcon from "../custom-icons/PyramindIcon";
import RadarIcon from "../custom-icons/RadarIcon";

const cartesianChartTypes = [CHART_TYPE_BAR, CHART_TYPE_HORIZONTAL_BAR, CHART_TYPE_LINE, CHART_TYPE_AREA];

/*const radialChartTypes = [
  CHART_TYPE_DOUGHNUT,
  CHART_TYPE_PIE,
  CHART_TYPE_POLAR_AREA,
  CHART_TYPE_PYRAMID,
  CHART_TYPE_RADAR];*/

export const viewersFactory = t => [
  {
    key: 0,
    type: "table",
    title: t ? t("scenes.dataViewers.viewers.table") : "",
    icon: <TableChartIcon />
  },
  {
    key: 1,
    title: t ? t("scenes.dataViewers.viewers.map") : "",
    type: "map",
    icon: <MapIcon />
  },
  {
    key: 2,
    title: t ? t("scenes.dataViewers.viewers.verticalBar") : "",
    type: CHART_TYPE_BAR,
    icon: <BarChartIcon />,
    chartType: "bar"
  },
  {
    key: 3,
    title: t ? t("scenes.dataViewers.viewers.horizontalBar") : "",
    type: CHART_TYPE_HORIZONTAL_BAR,
    icon: <BarChartIcon style={{transform: "rotate(90deg)"}} />,
    chartType: "horizontalBar"
  },
  {
    key: 4,
    title: t ? t("scenes.dataViewers.viewers.line") : "",
    type: CHART_TYPE_LINE,
    icon: <TimelineIcon />,
    chartType: "line"
  },
  {
    key: 5,
    title: t ? t("scenes.dataViewers.viewers.area") : "",
    type: CHART_TYPE_AREA,
    icon: <AreaIcon />,
    chartType: "area"
  },
  {
    key: 6,
    title: t ? t("scenes.dataViewers.viewers.doughnut") : "",
    type: CHART_TYPE_DOUGHNUT,
    icon: <DonutLargeIcon />,
    chartType: "doughnut"
  },
  {
    key: 7,
    title: t ? t("scenes.dataViewers.viewers.pie") : "",
    type: CHART_TYPE_PIE,
    icon: <PieChartIcon />,
    chartType: "pie"
  },
  {
    key: 8,
    title: t ? t("scenes.dataViewers.viewers.radar") : "",
    type: CHART_TYPE_RADAR,
    icon: <RadarIcon />,
    chartType: "radar"
  },
  {
    key: 9,
    title: t ? t("scenes.dataViewers.viewers.polarArea") : "",
    type: CHART_TYPE_POLAR_AREA,
    icon: <PolarIcon />,
    chartType: "polarArea"
  },
  {
    key: 10,
    title: t ? t("scenes.dataViewers.viewers.pyramid") : "",
    type: CHART_TYPE_PYRAMID,
    icon: <PyramidIcon />,
    chartType: "pyramid"
  }
];

export const getViewerIdxFromType = viewerType => {
  if (!viewerType) {
    return null;
  }

  const viewer = viewersFactory().find(({type}) => type === viewerType);

  return viewer !== undefined ? viewer.key : null;
};

export const getViewerTypeFromIdx = viewerIdx => {
  if (viewerIdx === null || viewerIdx === undefined) {
    return null;
  }

  const viewer = viewersFactory().find(({key}) => key === viewerIdx);

  return viewer?.type || null;
};

export const isChartLayoutCartesianByViewerIdx = viewerIdx => {
  const type = getViewerTypeFromIdx(viewerIdx);
  return isChartLayoutCartesianByType(type);
};

export const isChartLayoutCartesianByType = type => {
  return cartesianChartTypes.includes(type);
};

export const getViewerIdxFromStructure = defaultView => {
  if (!defaultView) {
    return null;
  }

  const type = (defaultView || "").toLowerCase();
  let viewerIdx = null;

  if (type === "table") {
    viewerIdx = 0;
  } else if (type === "map") {
    viewerIdx = 1;
  } else if (type === "graph") {
    viewerIdx = 2;
  }

  return viewerIdx;
};

export const validateTemplateDefaultView = (template, isTableEnabled, isMapEnabled, isChartEnabled) => {
  const defaultView = template?.defaultView;
  const viewerIdx = getViewerIdxFromType(defaultView);

  if (viewerIdx === 0) {
    return isTableEnabled && !template.layouts.disableTable;
  } else if (viewerIdx === 1) {
    return isMapEnabled && !template.layouts.disableMap;
  } else if (viewerIdx >= 2) {
    return isChartEnabled && !template.layouts.disableChart;
  } else {
    return false;
  }
};

export const getDimensionsWithHclWithError = (dimensions, criteria) => {
  let dimensionsWithError = [];
  (dimensions || []).forEach(dimension => {
    if ((dimension.hierarchies || []).length > 0) {
      var dimensionCriteria = criteria[dimension.id];
      if (!dimensionCriteria || (dimensionCriteria.hierarchyId || "") === "") {
        dimensionsWithError.push(dimension.label);
      }
    }
  });
  return dimensionsWithError;
};
