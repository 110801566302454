import {PayloadAction} from "@reduxjs/toolkit";
import {Reducer} from "redux";
import {INIT} from "../rootActions";

export type ConfigState = Record<string, any> | null;

const appConfigReducer: Reducer<ConfigState, PayloadAction<any>> = (state = null, action) => {
  switch (action.type) {
    case INIT: {
      return action.payload.appConfig;
    }
    default:
      return state;
  }
};

export default appConfigReducer;
