import React from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FilterDto} from "../../model/item-containers-models/filterDto";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import I18nDelayedTextField from "../i18n-delayed-text-field";
import ItemContainerBuilderProvider from "./itemContainerBuiderProvider";
import {ItemContainerBuilderRow} from "./ItemContainerBuilderRow";
import {useItemContainerBuilder} from "./useItemContainerBuilder";
import useApi from "../../state/api/useApi";
import {fetchFilters} from "../../state/dashboardFilters/dashboardFilterActions";
import useLanguages from "../../state/hooks/useLanguages";
import {hubNodesSelector} from "../../state/hub/hubSelectors";
import {changeOtherConfigItemContainer} from "../../state/otherConfig/otherConfigActions";
import {currentItemContainerSelector} from "../../state/otherConfig/otherConfigSelectors";
import "./style.css";

function ItemContainerBuilder({isTitleDuplicate}) {
  const itemContainer = useSelector(currentItemContainerSelector);
  const nodes = useSelector(hubNodesSelector);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onChange = itemContainer => dispatch(changeOtherConfigItemContainer(itemContainer));
  const {handleRowAdd, getGridContainerProps, getRowContainerProps, asDashboard} = useItemContainerBuilder();

  const hasNodeConfiguration = () => {
    return itemContainer?.configNodes?.length > 0;
  };

  const someNodesVisible = () => {
    return nodes?.some(n => n.visible === 1 && itemContainer?.configNodes?.some(i => i.nodeId === n.nodeId));
  };

  const isCustomPagePublic = () => {
    if (itemContainer?.configNodes?.some(i => i.hub === true) || someNodesVisible()) {
      return true;
    }
    return !itemContainer.isPrivate;
  };

  return (
    <ItemContainerBuilderProvider>
      {itemContainer && (
        <Grid id="itemContainer-builder__main-container" container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} id="itemContainer-builder__title-input-container">
            <FormControl fullWidth>
              <I18nDelayedTextField
                label={t("components.itemContainerBuilder.title")}
                error={isTitleDuplicate()}
                helperText={isTitleDuplicate() ? "Duplicate title" : null}
                value={itemContainer.title}
                variant="outlined"
                required
                onChange={value => onChange({...itemContainer, title: value})}
              />
            </FormControl>
          </Grid>
          {!asDashboard && (
            <Grid item container direction={"column"}>
              <Grid item alignItems="center" sx={{display: "flex"}}>
                <FormControlLabel
                  label={t("components.itemContainerBuilder.checkbox.isPublic")}
                  labelPlacement="end"
                  control={
                    <Checkbox
                      disabled={hasNodeConfiguration()}
                      checked={isCustomPagePublic()}
                      onChange={(_, checked) => onChange({...itemContainer, isPrivate: !checked})}
                    />
                  }
                />
                {hasNodeConfiguration() && (
                  <Tooltip title={t("components.itemContainerBuilder.checkbox.tooltip")}>
                    <InfoOutlinedIcon />
                  </Tooltip>
                )}
              </Grid>
              <Grid item alignItems="center" sx={{display: "flex"}}>
                <FormControlLabel
                  label={t("components.itemContainerBuilder.checkbox.hideTitle")}
                  labelPlacement="end"
                  control={
                    <Checkbox
                      checked={itemContainer.hideTitle}
                      onChange={(_, checked) => onChange({...itemContainer, hideTitle: checked})}
                    />
                  }
                />
              </Grid>
            </Grid>
          )}
          {asDashboard && <FilterContainer itemContainer={itemContainer}></FilterContainer>}
          <Grid
            id="itemContainer-builder__items-container"
            item
            container
            style={{pointerEvents: "auto"}}
            {...getGridContainerProps()}
          >
            <Grid item xs={12} style={{margin: "0px -8px 20px"}}>
              {(itemContainer.item || []).map((row, idx) => (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    pointerEvents: "auto",
                    marginBottom: "8px"
                  }}
                  {...getRowContainerProps(idx)}
                >
                  <ItemContainerBuilderRow row={row} rowIdx={idx} />
                </div>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: "100%",
              height: "100%",
              display: "inline-block",
              verticalAlign: "middle"
            }}
          >
            <Grid container justifyContent="center">
              <Button endIcon={<AddIcon />} onClick={handleRowAdd}>
                {t("components.itemContainerBuilder.actions.addRow")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ItemContainerBuilderProvider>
  );
}

const FilterContainer = ({itemContainer}: {itemContainer: ItemContainerDto}) => {
  const {localizeI18nObj, t} = useLanguages();
  const {handleFilterChange, handleFilterLevelsChange} = useItemContainerBuilder();

  const {data} = useApi<FilterDto[]>(fetchFilters(), {
    invokeImmediately: true
  });

  return (
    <Grid item container alignItems="center" sx={{marginTop: "12px"}}>
      <Grid item>
        <FormControl variant="outlined" sx={{minWidth: "180px"}}>
          <InputLabel id="filter-label">{t("components.filterContainer.title")}</InputLabel>
          <Select
            labelId="filter-label"
            label={t("components.filterContainer.title")}
            style={{minWidth: 125}}
            onChange={({target}) => handleFilterChange(data?.find(({id}) => id === target.value))}
            value={itemContainer?.filter?.id | 0}
          >
            <MenuItem value="0">
              <em>{t("components.map.layers.none")}</em>
            </MenuItem>
            {data &&
              data.map(({id, name}) => (
                <MenuItem key={id} value={id}>
                  {localizeI18nObj(name)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {itemContainer?.filter &&
        itemContainer.filter.levels.map(({level, label}, index) => (
          <Grid key={index} item sx={{marginLeft: "16px"}}>
            <FormControlLabel
              value={level}
              sx={{
                marginLeft: "0px",
                marginRight: "0px"
              }}
              label={localizeI18nObj(label)}
              control={
                <Checkbox
                  checked={itemContainer.filterLevelsSelected.includes(level)}
                  style={{padding: 5}}
                  onChange={({target}, checked) => handleFilterLevelsChange(checked, Number(target.value))}
                />
              }
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default ItemContainerBuilder;
