import _ from "lodash";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import {
  getCustomPageCreateUrl,
  getCustomPageDeleteUrl,
  getCustomPageUpdateUrl,
  getCustomPageUrl,
  getDashboardCreateUrl,
  getDashboardDeleteUrl,
  getDashboardShareManageUrl,
  getDashboardUpdateUrl,
  getDashboardUrl,
  getDeleteViewUrl,
  getItemContainerNodeView,
  getUserCustomPagesUrl,
  getUserDashboardsUrl,
  getViewsUrl
} from "../../serverApi/urls";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {
  ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW,
  ITEMCONTAINER_ELEM_VALUE_KEY
} from "../../utils/itemContainers";

export const OTHER_CONFIG_VIEWS_FETCH = "otherConfig/fetchViews";
export const OTHER_CONFIG_VIEWS_CLEAR = "otherConfig/clearViews";
export const OTHER_CONFIG_VIEW_DELETE = "otherConfig/deleteView";

export const OTHER_CONFIG_ITEMCONTAINERS_FETCH = "otherConfig/fetchItemContainers";
export const OTHER_CONFIG_ITEMCONTAINERS_CLEAR = "otherConfig/clearItemContainers";
export const OTHER_CONFIG_ITEMCONTAINERS_CLOSE = "otherConfig/closeItemContainers";

export const OTHER_CONFIG_ITEMCONTAINER_CREATE = "otherConfig/itemContainer/create";
export const OTHER_CONFIG_ITEMCONTAINER_UPDATE = "otherConfig/itemContainer/update";
export const OTHER_CONFIG_ITEMCONTAINER_CHANGE = "otherConfig/itemContainer/change";
export const OTHER_CONFIG_ITEMCONTAINER_CREATE_SUBMIT = "otherConfig/itemContainer/create/submit";
export const OTHER_CONFIG_ITEMCONTAINER_UPDATE_SUBMIT = "otherConfig/itemContainer/update/submit";
export const OTHER_CONFIG_ITEMCONTAINER_HIDE = "otherConfig/itemContainer/hide";
export const OTHER_CONFIG_ITEMCONTAINER_DELETE = "otherConfig/itemContainer/create";
export const OTHER_CONFIG_ITEMCONTAINER_VIEWS_FETCH = "otherConfig/itemContainer/views/fetch";
export const OTHER_CONFIG_ITEMCONTAINER_VIEW_FETCH = "otherConfig/itemContainer/view/fetch";
export const OTHER_CONFIG_ITEMCONTAINER_DATASET_CLEAR = "otherConfig/itemContainer/dataset/clear";
export const OTHER_CONFIG_MANAGE_SHARE_DASHBOARD_ON_NODES = "otherConfig/manage/share/dashboard/onNodes";
export const OTHER_CONFIG_DASHBOARD_TO_SHARE_FETCH = "otherConfig/dashboard/toShare/fetch";
export const OTHER_CONFIG_DASHBOARD_TO_SHARE_CLEAR = "otherConfig/dashboard/toShare/clear";

export const fetchOtherConfigViews = () =>
  initRequest(OTHER_CONFIG_VIEWS_FETCH, getViewsUrl(), undefined, undefined, t => ({
    onStart: t("scenes.viewsConfig.messages.fetchViews.start")
  }));

export const clearOtherConfigViews = () => ({
  type: OTHER_CONFIG_VIEWS_CLEAR
});

export const deleteOtherConfigView = (nodeId: number, id: number) =>
  initRequest(OTHER_CONFIG_VIEW_DELETE, getDeleteViewUrl(nodeId, id), RequestMethod.DELETE, undefined, t => ({
    onStart: t("scenes.viewsConfig.messages.deleteView.start")
  }));

export const fetchOtherConfigItemContainers = (type: "dashboard" | "customPages") =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINERS_FETCH,
      type === "dashboard" ? getUserDashboardsUrl() : getUserCustomPagesUrl(),
      undefined,
      undefined,
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.fetchDashboards.start")
            : t("scenes.customPagesSettings.messages.fetchCustomPages.start")
      })
    ),
    type
  );

export const clearOtherConfigItemContainers = () => ({
  type: OTHER_CONFIG_ITEMCONTAINERS_CLEAR
});

export const closeOtherConfigItemContainer = () => ({
  type: OTHER_CONFIG_ITEMCONTAINERS_CLOSE
});

export const createOtherConfigItemContainer = (itemContainer: any) => ({
  type: OTHER_CONFIG_ITEMCONTAINER_CREATE,
  payload: {
    itemContainer: itemContainer
  }
});

export const updateOtherConfigItemContainer = (itemContainerId: number, type: "dashboard" | "customPages") =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINER_UPDATE,
      type === "dashboard" ? getDashboardUrl(itemContainerId) : getCustomPageUrl(itemContainerId),
      undefined,
      undefined,
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.fetchDashboard.start")
            : t("scenes.customPagesSettings.messages.fetchCustomPage.start")
      })
    ),
    type
  );

export const fetchOtherConfigDashboardToShare = (dashboardId: number) =>
  external(
    initRequest(OTHER_CONFIG_DASHBOARD_TO_SHARE_FETCH, getDashboardUrl(dashboardId), undefined, undefined, t => ({
      onStart: t("scenes.dashboardsSettings.messages.fetchDashboard.start")
    })),
    "dashboard"
  );

export const clearOtherConfigDashboardToShare = () => ({
  type: OTHER_CONFIG_DASHBOARD_TO_SHARE_CLEAR
});

export const changeOtherConfigItemContainer = (itemContainer: any) => ({
  type: OTHER_CONFIG_ITEMCONTAINER_CHANGE,
  payload: {
    itemContainer: itemContainer
  }
});

const getNewItemContainer = (itemContainer: any, item: any) => {
  const newItemContainer = _.cloneDeep(itemContainer);
  const ids: string[] = [];
  let counterIndex = 0;
  newItemContainer.item.forEach((row: any, rowIdx: number) => {
    row.forEach((col: any, colIdx: number) => {
      counterIndex = counterIndex + rowIdx + colIdx;
      const type = col.type;
      const value = col[ITEMCONTAINER_ELEM_VALUE_KEY];
      const filterDimension = col[ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY];
      if (
        (type === ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW || type === ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY) &&
        value !== null &&
        value !== undefined &&
        !ids.includes(value)
      ) {
        ids.push(value);
      }
      if (filterDimension === "") {
        newItemContainer.item[rowIdx][colIdx].filterDimension = null;
      }
    });
  });
  let newFilterLevels = {};
  (item?.labels || []).forEach((label: string) => {
    newFilterLevels = {
      ...newFilterLevels,
      [label]: newItemContainer.filterLevels[label] || false
    };
  });

  let newItemContainerItem = newItemContainer.item;
  for (var row = 0; row < newItemContainerItem.length; row++) {
    for (var col = 0; col < newItemContainerItem[row].length; col++) {
      newItemContainerItem[row][col].row = row;
      newItemContainerItem[row][col].column = col;
      if (newItemContainerItem[row][col].type === "itemViewDto")
        newItemContainerItem[row][col].type = "itemViewTemplateDto";
      if (
        newItemContainerItem[row][col].type === "itemCategoryTemplateDto" ||
        newItemContainerItem[row][col].type === "itemCategoryDto"
      ) {
        newItemContainerItem[row][col].type = "itemCategoryTemplateDto";
      }
      if (newItemContainerItem[row][col].type === "itemRichTextDTO") {
        newItemContainerItem[row][col].data = newItemContainerItem[row][col].id;
        delete newItemContainerItem[row][col].idM;
      }
    }
  }
  return {
    ...newItemContainer,
    title: newItemContainer.title,
    item: newItemContainerItem,
    type: newItemContainer.type,
    hubId: 1,
    weight: 0,
    configNodes: newItemContainer.configNodes,
    configHubs: newItemContainer.configHubs
  };
};

export const submitOtherConfigItemContainerCreate = (
  itemContainer: any,
  item: any,
  type: "dashboard" | "customPages"
) =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINER_CREATE_SUBMIT,
      type === "dashboard" ? getDashboardCreateUrl() : getCustomPageCreateUrl(),
      RequestMethod.POST,
      getNewItemContainer(itemContainer, item),
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.submitDashboardCreate.start")
            : t("scenes.customPagesSettings.messages.submitCustomPageCreate.start")
      })
    ),
    type
  );

export const submitOtherConfigItemContainerUpdate = (
  itemContainerId: number,
  itemContainer: any,
  item: any,
  type: "dashboard" | "customPages"
) =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINER_UPDATE_SUBMIT,
      type === "dashboard" ? getDashboardUpdateUrl(itemContainerId) : getCustomPageUpdateUrl(itemContainerId),
      RequestMethod.PUT,
      getNewItemContainer(itemContainer, item),
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.submitDashboardUpdate.start")
            : t("scenes.customPagesSettings.messages.submitCustomPageUpdate.start")
      }),
      {
        itemContainerId: itemContainerId
      }
    ),
    type
  );

export const deleteOtherConfigItemContainer = (itemContainerId: number, type: "dashboard" | "customPages") =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINER_DELETE,
      type === "dashboard" ? getDashboardDeleteUrl(itemContainerId) : getCustomPageDeleteUrl(itemContainerId),
      RequestMethod.DELETE,
      undefined,
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.deleteDashboard.start")
            : t("scenes.customPagesSettings.messages.deleteCustomPage.start")
      })
    ),
    type
  );

export const hideOtherConfigItemContainer = () => ({
  type: OTHER_CONFIG_ITEMCONTAINER_HIDE
});

export const fetchOtherConfigItemContainerViews = (type: "dashboard" | "customPages") =>
  initRequest(OTHER_CONFIG_ITEMCONTAINER_VIEWS_FETCH, getViewsUrl(), undefined, undefined, t => ({
    onStart:
      type === "dashboard"
        ? t("scenes.dashboardsSettings.messages.fetchDashboardViews.start")
        : t("scenes.customPagesSettings.messages.fetchCustomPageViews.start")
  }));

export const fetchOtherConfigItemContainerView = (view: ViewTemplateDto, rowidx: number, colIdx: number) =>
  initRequest(
    OTHER_CONFIG_ITEMCONTAINER_VIEW_FETCH,
    getItemContainerNodeView(view.nodeId, view.viewTemplateId),
    undefined,
    undefined,
    t => ({
      onStart: t("scenes.itemContainersSettings.messages.updateViewSelectedTemplate.start")
    }),
    {
      rowIdx: rowidx,
      colIdx: colIdx
    }
  );

export const clearOtherConfigItemContainersDataset = () => ({
  type: OTHER_CONFIG_ITEMCONTAINER_DATASET_CLEAR
});

const getDashboardShareSyncDto = (dashboardId: number, sharedNodes: number[]) => {
  let nodeDashboardShare = {};
  (sharedNodes || []).forEach(nodeId => {
    nodeDashboardShare[nodeId] = true;
  });

  let dashboardShareSyncDto = {
    dashboardId: dashboardId,
    nodeDashboardShare: nodeDashboardShare
  };

  return dashboardShareSyncDto;
};

export const manageShareDashboardOnNodes = (dashboardId: number, sharedNodes: number[]) =>
  external(
    initRequest(
      OTHER_CONFIG_MANAGE_SHARE_DASHBOARD_ON_NODES,
      getDashboardShareManageUrl(),
      RequestMethod.POST,
      getDashboardShareSyncDto(dashboardId, sharedNodes),
      t => ({
        onStart: t("scenes.dashboardsSettings.messages.shareDashboard.start")
      })
    ),
    "dashboard"
  );
