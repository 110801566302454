import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import DatasetStaticFilters from "./DatasetStaticFilters";
import {getFiltersValueInfo} from "../../utils/dataset";

const DatasetStaticFiltersJsonStatWrapper = ({
  jsonStat,
  layout,
  labelFormat,
  isDimensionAllowed,
  localizedTimePeriodFormatMapExternal
}) => {
  const {t} = useTranslation();

  const filtersValueInfo = useMemo(() => {
    if (!layout || !jsonStat) {
      return {};
    } else {
      const filterDims = layout.filters.filter(dim => !isDimensionAllowed || isDimensionAllowed(dim));
      return getFiltersValueInfo(
        filterDims,
        layout.filtersValue,
        jsonStat,
        labelFormat,
        t,
        localizedTimePeriodFormatMapExternal
      );
    }
  }, [layout, isDimensionAllowed, jsonStat, labelFormat, t, localizedTimePeriodFormatMapExternal]);

  return (
    jsonStat && (
      <DatasetStaticFilters
        datasetId={filtersValueInfo?.datasetId || null}
        values={filtersValueInfo?.values || []}
        jsonStat={jsonStat}
        labelFormat={labelFormat}
        localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
      />
    )
  );
};

export default DatasetStaticFiltersJsonStatWrapper;
