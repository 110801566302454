import React, {ComponentType} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";

export type ConfirmationDialogProps = DialogProps & {
  onConfirm: () => void;
  onClose: () => void;
  title: string | ComponentType<any>;
  content: string | ComponentType<any>;
};
const ConfirmationDialog = ({open, onClose, onConfirm, title, content, ...dialogProps}: ConfirmationDialogProps) => {
  const {t} = useTranslation();
  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose} {...dialogProps}>
      <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t("commons.confirm.cancel")}
        </Button>
        <Button onClick={onConfirm}>{t("commons.confirm.confirm")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
