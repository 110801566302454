import React, {Fragment} from "react";
import {Box} from "@mui/material";
import {withTranslation} from "react-i18next";

const CustomEmpty = ({
  t,
  style = {},
  sx = {},
  text = null,
  textStyle = {},
  image = null,
  imageStyle = {},
  children = undefined
}) => (
  <Box
    sx={[
      {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
      },
      sx
    ]}
    className={`custom-empty`}
    style={{...style}}
  >
    {children !== undefined ? (
      children
    ) : (
      <Fragment>
        {image && (
          <Box sx={{paddingBottom: "16px"}} className={`custom-empty__image`} style={{...imageStyle}}>
            {image}
          </Box>
        )}
        <Box
          sx={{
            textAlign: "center",
            fontStyle: "italic",
            fontSize: 14,
            color: theme => theme.palette.text.primary
          }}
          className={`custom-empty__text`}
          style={{...textStyle}}
        >
          {text !== null && text !== undefined ? text : t("components.customEmpty.placeholder")}
        </Box>
      </Fragment>
    )}
  </Box>
);

export default withTranslation()(CustomEmpty);
