import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import {useSelector} from "react-redux";
import Call from "../../hocs/call";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import CustomEmpty from "../custom-empty";
import {useItemContainerFilters} from "../item-containers/ItemContainerFilters";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../temporal-dim-order-selector/constants";
import {useViewDataset} from "./useViewDataset";
import ViewItemBody from "./ViewItemBody";
import ViewItemHeading from "./ViewItemHeading";
import {modulesConfigSelector} from "../../state/app/appSelectors";
import useLanguages from "../../state/hooks/useLanguages";
import {hubNodesSelector} from "../../state/hub/hubSelectors";
import {CRITERIA_FILTER_TYPE_CODES, getCriteriaArrayFromObject, getCriteriaObjectFromArray} from "../../utils/criteria";
import {DASHBOARD_ELEM_ENABLE_FILTERS_KEY} from "../../utils/dashboards";
import {generateLocalizedTimePeriodFormatMapFromExtras, getUpdatedLayout} from "../../utils/dataset";
import {getNodeExtras, handleStyle, makeViewData} from "./utils";

declare global {
  interface Window {
    LMap: any;
  }
}

interface ViewItemProps {
  itemContainerElem: ItemViewTemplateDto;
  viewIdx: string;
  minItemContainerWidth: number;
  itemContainer: ItemContainerDto;
  mapId: string;
  chartId: string;
}

const ViewItem = ({
  itemContainerElem,
  viewIdx,
  itemContainer,
  minItemContainerWidth,
  mapId,
  chartId
}: ViewItemProps) => {
  const {t} = useLanguages();
  const chartRef = useRef();

  const nodes = useSelector(hubNodesSelector);
  const [nodeExtras, setNodeExtras] = useState(null);
  const [localizedTimePeriodFormatMap, setLocalizedTimePeriodFormatMap] = useState({});
  const modulesConfig = useSelector(modulesConfigSelector);

  const pageFilterValue = useItemContainerFilters();

  const filterDim = useMemo(() => itemContainerElem?.filterDimension ?? null, [itemContainerElem?.filterDimension]);

  const [view, setView] = useState<ViewTemplateDto & {initialLayout?: any; initialCriteria?: any}>(null);
  const [layoutObj, setLayoutObj] = useState(null);
  const [layout, setLayout] = useState(null);
  const [filterTree, setFilterTree] = useState(null);
  const [timePeriodsByFreq, setTimePeriodsByFreq] = useState(null);
  const [criteria, setCriteria] = useState(null);

  const {call, request, loading, data, error} = useViewDataset(
    itemContainerElem,
    itemContainer.type,
    itemContainer.identifier,
    modulesConfig.configs
  );

  useEffect(() => {
    const view: ViewTemplateDto & {initialLayout?: any; initialCriteria?: any} = _.cloneDeep(
      itemContainerElem.viewTemplate
    );

    const viewCriteria = getCriteriaObjectFromArray(view.criteria);
    view.initialCriteria = viewCriteria;

    const nodeExtras = getNodeExtras(nodes, view);
    setNodeExtras(nodeExtras);

    const localizedTimePeriodFormatMap = generateLocalizedTimePeriodFormatMapFromExtras(nodeExtras);
    setLocalizedTimePeriodFormatMap(localizedTimePeriodFormatMap);

    setView(view);
    setCriteria(viewCriteria);
  }, [itemContainerElem, nodes]);

  useEffect(() => {
    if (data) {
      setLayout(prevLayout => {
        const {layoutObj, layout, filterTree, timePeriodsByFreq} = makeViewData(itemContainerElem, data, prevLayout);
        setLayoutObj(layoutObj);
        setFilterTree(filterTree);
        setTimePeriodsByFreq(timePeriodsByFreq);
        if (!prevLayout) {
          setView(prevView => ({
            ...prevView,
            initialLayout: layout
          }));
        }
        return layout;
      });
    }
  }, [itemContainerElem, data]);

  useEffect(() => {
    if (view && filterDim) {
      setLayout(prevLayout => {
        if (!prevLayout) {
          return prevLayout;
        }
        let newLayout = _.cloneDeep(prevLayout);
        if (pageFilterValue) {
          if ((newLayout.filters || []).includes(filterDim)) {
            newLayout.filtersValue[filterDim] = pageFilterValue;
          } else if ((newLayout.primaryDim || []).includes(filterDim)) {
            newLayout.primaryDimValues = [pageFilterValue];
          } else if ((newLayout.secondaryDim || []).includes(filterDim)) {
            newLayout.secondaryDimValues = [pageFilterValue];
          }
        } else if (!view[DASHBOARD_ELEM_ENABLE_FILTERS_KEY]) {
          newLayout = view.initialLayout;
        }
        return newLayout;
      });

      setCriteria(prevCriteria => {
        return pageFilterValue
          ? {
              ...prevCriteria,
              [filterDim]: {
                ...prevCriteria[filterDim],
                id: filterDim,
                type: CRITERIA_FILTER_TYPE_CODES,
                filterValues: [pageFilterValue]
              }
            }
          : view.initialCriteria;
      });
    }
  }, [view, filterDim, pageFilterValue]);

  useEffect(() => {
    handleStyle(itemContainer.id, viewIdx, itemContainerElem, minItemContainerWidth);
  }, [itemContainer.id, viewIdx, itemContainerElem, minItemContainerWidth, data]);

  try {
    const timeDim = data?.role?.time?.[0] || null;
    const invertedDims =
      layoutObj?.temporalDimOrder &&
      layoutObj.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC &&
      layout?.filters &&
      !layout.filters.includes(timeDim)
        ? [timeDim]
        : null;

    return (
      <Call
        cb={criteria => {
          if (!filterDim || !pageFilterValue || criteria?.[filterDim]?.filterValues?.[0] === pageFilterValue) {
            call({
              ...request,
              data: getCriteriaArrayFromObject(criteria),
              clearCache: true
            });
          }
        }}
        cbParam={criteria}
        disabled={criteria === null}
      >
        {loading ? (
          <CustomEmpty text={t("components.itemContainer.fetching") + "..."} image={<CircularProgress />} />
        ) : error ? (
          <CustomEmpty text={t("components.itemContainer.fetchingDatasetError")} />
        ) : !data ? (
          <CustomEmpty text={""} />
        ) : (data?.id || []).length === 0 ? (
          <CustomEmpty text={t("components.itemContainer.emptyView")} />
        ) : layoutObj ? (
          <Fragment>
            <ViewItemHeading
              isOptimized={false}
              itemContainer={itemContainer}
              item={itemContainerElem}
              viewIdx={viewIdx}
              view={view}
              defaultTitle={data.label}
              jsonStat={data}
              criteria={criteria}
              layout={layout}
              layoutObj={layoutObj}
              mapId={mapId}
              chartId={chartId}
              chartRef={chartRef}
              filterDim={filterDim}
              pageFilterValue={pageFilterValue}
              onFilterSet={({dimension, value}) => {
                const newLayout = getUpdatedLayout(dimension, value, layout, data, filterTree, timeDim);
                setLayout(newLayout);
              }}
              downloadFormats={nodeExtras?.DownloadFormats || []}
              filterTree={filterTree}
              timePeriodsByFreq={timePeriodsByFreq}
              localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMap}
            />
            <ViewItemBody
              itemContainer={itemContainer}
              item={itemContainerElem}
              jsonStat={data}
              layout={layout}
              hierarchyOnlyAttributes={nodeExtras?.HierarchyOnlyAttributes || []}
              hideHierarchyOnlyRows={nodeExtras?.HideHierarchyOnlyRows || false}
              invertedDims={invertedDims}
              layoutObj={layoutObj}
              timePeriodsByFreq={timePeriodsByFreq}
              viewIdx={viewIdx}
              minItemContainerWidth={minItemContainerWidth}
              mapId={mapId}
              chartId={chartId}
              chartRef={chartRef}
              localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMap}
            />
          </Fragment>
        ) : (
          <span />
        )}
      </Call>
    );
  } catch (e) {
    return <CustomEmpty text={t("components.itemContainer.genericError")} />;
  }
};

export default ViewItem;
