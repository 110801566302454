import React from "react";
import {Box} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {withTranslation} from "react-i18next";

const Footer = ({t, isLoading, isHidden, rowNumStart, rowNumEnd, rowCount}) => (
  <Grid container justifyContent="flex-end" style={{marginTop: 8}}>
    <Grid item>
      {!isHidden ? (
        isLoading ? (
          <CircularProgress
            size="small"
            className="infinite-scroll-table__footer__spinner"
            style={{
              fontSize: 10,
              width: 13,
              height: 13,
              marginRight: 2
            }}
          />
        ) : (
          <Box component="span" sx={{fontSize: 14}}>
            {t("components.infiniteScrollTable.footer.rowCount", {rowNumStart, rowNumEnd, rowCount})}
          </Box>
        )
      ) : (
        <span />
      )}
    </Grid>
  </Grid>
);

export default withTranslation()(Footer);
