import React, {Fragment} from "react";
import LockIcon from "@mui/icons-material/Lock";
import {Box} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {Draggable, Droppable} from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  marginBottom: 8,
  ...draggableStyle
});

const LayoutSection = ({id, title, Icon, dimensions, dimensionsInfo, disabled, lockedDimensions}) => (
  <Card
    variant="outlined"
    sx={{
      height: "100%",
      width: "100%",
      background: "#eeeeee"
    }}
  >
    <CardHeader
      sx={[
        {
          height: "48px",
          padding: "16px 16px 0"
        },
        {
          "& .MuiCardHeader-content": {
            width: "100%"
          }
        }
      ]}
      title={
        <Tooltip title={title}>
          <Fragment>
            <Box
              component="span"
              sx={{
                width: "24px",
                marginRight: "8px",
                display: "inline-block",
                verticalAlign: "middle"
              }}
            >
              {Icon}
            </Box>
            <Typography
              sx={{
                width: `calc(100% - 32px)`,
                height: "30px",
                display: "inline-block",
                verticalAlign: "middle",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
              }}
              variant="button"
            >
              {title}
            </Typography>
          </Fragment>
        </Tooltip>
      }
    />
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <Box
          sx={[
            {
              width: "100%",
              height: "calc(100% - 48px)",
              padding: "16px"
            },
            snapshot.isDraggingOver && {
              border: "1px dashed #999999"
            }
          ]}
        >
          <Box
            ref={provided.innerRef}
            sx={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            {dimensions.map((item, idx) => (
              <Draggable key={idx} index={idx} draggableId={`${id}-${idx}`} isDragDisabled={disabled}>
                {(provided, snapshot) => {
                  const hasLockIcon = (lockedDimensions || []).includes(item);
                  return (
                    <div
                      id={`dimension_${id}_item_${item}_idx_${idx}_btn`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <Tooltip title={dimensionsInfo[item].label ? `[${item}] ${dimensionsInfo[item].label}` : item}>
                        <Paper
                          sx={{
                            padding: "6px 12px"
                          }}
                        >
                          <Grid container columnSpacing={1} justifyContent="space-between" wrap="nowrap">
                            <Grid item style={{width: `calc(100% - ${hasLockIcon ? 28 : 0}px)`}}>
                              <Grid
                                container
                                columnSpacing={1}
                                justifyContent="center"
                                wrap="nowrap"
                                sx={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontSize: "14px"
                                }}
                              >
                                <Grid
                                  item
                                  sx={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden"
                                  }}
                                >
                                  {dimensionsInfo[item].label || item}
                                </Grid>
                                <Grid item>{`(${dimensionsInfo[item].size})`}</Grid>
                              </Grid>
                            </Grid>
                            {hasLockIcon && (
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  "& > svg": {
                                    color: "gray"
                                  }
                                }}
                              >
                                <LockIcon fontSize="small" />
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Tooltip>
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        </Box>
      )}
    </Droppable>
  </Card>
);

export default LayoutSection;
