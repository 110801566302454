import React, {Fragment, useEffect, useRef, useState} from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import ButtonSelect from "../button-select";
import ModuleMenuItemPlaceholder from "../module-menu-item-placeholder";
import ModulesPlaceholder from "../modules-placeholder";
import SettingsDialog from "../settings-dialog";
import UserItemContainersSettingsForm from "../settings-select/user-itemContainers-settings-form";
import ItemContainerSettingsFormProvider from "../settings-select/user-itemContainers-settings-form/ItemContainerSettingsFormProvider";
import UserViewsSettingsForm from "../settings-select/user-views-settings-form";
import UserSettingsForm from "../settings-select/users-settings-form/user-settings-form";
import EditUserForm from "./edit-user-form";
import UserChangePasswordForm from "./user-change-password-form";
import {
  fetchExternalUser,
  hideUserChangePasswordForm,
  hideUserEditUserModal,
  hideUserRegisterModal,
  logoutUser,
  showUserChangePasswordForm,
  showUserEditUserModal,
  showUserLoginForm
} from "../../state/user/userActions";
import {BASE_LOGIN_IDENTIFIER} from "../../state/user/userReducer";
import {canDisplayUserViews, canManagePersonalDashboards} from "../../utils/user";

const mapStateToProps = state => ({
  modulesConfig: state.app.modulesConfig,
  user: state.user,
  supportedAuths: state.hub.supportedAuths
});

const mapDispatchToProps = dispatch => ({
  onExternalLogin: (providerId, loginCode) => dispatch(fetchExternalUser(providerId, loginCode)),
  onLogout: userId => dispatch(logoutUser(userId)),
  onLoginFormShow: () => dispatch(showUserLoginForm()),
  onChangePasswordShow: () => dispatch(showUserChangePasswordForm()),
  onChangePasswordHide: () => dispatch(hideUserChangePasswordForm()),
  onRegisterModalHide: () => dispatch(hideUserRegisterModal()),
  onEditModalShow: () => dispatch(showUserEditUserModal()),
  onEditModalHide: () => dispatch(hideUserEditUserModal())
});

const dividerStyle = {
  width: "100%",
  color: "gray",
  fontStyle: "italic",
  fontSize: "14px"
};

let providerId = null;

const UserSelect = ({
  modulesConfig,
  user,
  supportedAuths,
  onExternalLogin,
  onLogout,
  onLoginFormShow,
  onChangePasswordShow,
  onChangePasswordHide,
  onRegisterModalHide,
  onEditModalShow,
  onEditModalHide,
  nodes,
  disabled
}) => {
  const {t} = useTranslation();

  const registerFormRef = useRef();
  const changePasswordFormRef = useRef();
  const editUserFormRef = useRef();
  const viewsSettingsRef = useRef();
  const dashboardsSettingsRef = useRef();

  const [isViewsOpen, setIsViewsOpen] = useState(false);
  const [isDashboardsOpen, setIsDashboardsOpen] = useState(false);

  useEffect(() => {
    window.onstorage = e => {
      const loginCode = localStorage.getItem("loginCode");
      if (e.storageArea === localStorage && loginCode && providerId) {
        onExternalLogin(providerId, loginCode);
        localStorage.removeItem("loginCode");
        providerId = null;
      }
    };
    return () => {
      window.onstorage = null;
    };
  }, [onExternalLogin]);

  const onDashboardsOpen = () => {
    setIsDashboardsOpen(true);
  };

  const onDashboardsClose = () => {
    setIsDashboardsOpen(false);
  };

  const onViewsOpen = () => {
    setIsViewsOpen(true);
  };

  const onViewsClose = () => {
    setIsViewsOpen(false);
  };

  const handleExternalLogin = (provider, loginURL) => {
    providerId = provider;
    localStorage.removeItem("loginCode");
    window.open(loginURL, "", "width=720,height=720");
  };

  const handleExternalLogout = logoutURL => {
    window.open(logoutURL, "", "width=720,height=720");
  };

  return (
    <Fragment>
      {(() => {
        if (user.isAuthenticated) {
          return (
            <Tooltip title={user.email}>
              <span>
                <ButtonSelect
                  value=""
                  icon={<AccountCircleIcon />}
                  ariaLabel={t("components.header.actions.user.ariaLabel")}
                  tooltip={t("components.header.actions.user.title")}
                  onChange={actionId => {
                    switch (actionId) {
                      case "views":
                        onViewsOpen();
                        break;
                      case "dashboards":
                        onDashboardsOpen();
                        break;
                      case "changePassword":
                        onChangePasswordShow();
                        break;
                      case "editUser":
                        onEditModalShow();
                        break;
                      case "logout":
                        onLogout(user.userId);
                        const currentAuth = (supportedAuths ?? []).find(({identifier}) => identifier === user.provider);
                        if (currentAuth?.logoutUrl) {
                          handleExternalLogout(currentAuth.logoutUrl);
                        }
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {[
                    <Box
                      key="email"
                      sx={{
                        cursor: "initial",
                        color: "gray"
                      }}
                    >
                      {user.email}
                    </Box>,
                    canDisplayUserViews(user) ||
                    (modulesConfig.modules.includes("dashboard") && canManagePersonalDashboards(user)) ? (
                      <Box key="divider1" sx={dividerStyle}>
                        {t("components.userSelect.userObjects")}
                      </Box>
                    ) : null,
                    canDisplayUserViews(user) ? (
                      <div id="header_user_select_views_btn" key="views" data-value={"views"}>
                        {t("components.userSelect.views")}
                      </div>
                    ) : null,
                    modulesConfig.modules.includes("dashboard") && canManagePersonalDashboards(user) ? (
                      <div key="dashboards" id="user-dashboards-btn" data-value={"dashboards"}>
                        {t("components.userSelect.dashboards")}
                      </div>
                    ) : null,
                    ...(modulesConfig.placeholders?.["user-select-menu"] || []).map((module, idx) => (
                      <ModuleMenuItemPlaceholder
                        id={`header_user_select_menu_${idx}_btn`}
                        isModulePlaceholder
                        key={idx}
                        module={module}
                      />
                    )),
                    <Box key="divider2" sx={dividerStyle}>
                      {t("components.userSelect.accountSettings")}
                    </Box>,
                    user.provider !== BASE_LOGIN_IDENTIFIER ? null : (
                      <div
                        id="header_user_select_change_password_btn"
                        key="changePassword"
                        data-value={"changePassword"}
                      >
                        {t("components.userSelect.changePassword")}
                      </div>
                    ),
                    user.provider !== BASE_LOGIN_IDENTIFIER ? null : (
                      <div id="header_user_select_edit_user_btn" key="editUser" data-value={"editUser"}>
                        {t("components.userSelect.editUser")}
                      </div>
                    ),
                    <div id="header_user_select_logout_btn" key="logout" data-value={"logout"}>
                      {t("components.userSelect.logout")}
                    </div>
                  ]}
                </ButtonSelect>
              </span>
            </Tooltip>
          );
        } else if ((supportedAuths || []).length === 1) {
          const provider = supportedAuths[0];
          return (
            <Button
              color="inherit"
              onClick={() =>
                provider.identifier === BASE_LOGIN_IDENTIFIER
                  ? onLoginFormShow()
                  : handleExternalLogin(provider.identifier, provider.loginURL)
              }
            >
              {t("components.userSelect.login")}
            </Button>
          );
        } else if ((supportedAuths || []).length > 1) {
          return (
            <ButtonSelect
              value={t("components.userSelect.login")}
              ariaLabel={t("components.header.actions.user.ariaLabel")}
              tooltip={t("components.header.actions.user.title")}
              onChange={({identifier, loginURL}) =>
                identifier === BASE_LOGIN_IDENTIFIER ? onLoginFormShow() : handleExternalLogin(identifier, loginURL)
              }
              removeEndIcon
            >
              {supportedAuths.map(({identifier, label, loginURL}) => (
                <div key={identifier} data-value={{identifier, loginURL}}>
                  {identifier === BASE_LOGIN_IDENTIFIER
                    ? t("components.userSelect.loginWithBasicAuth")
                    : t("components.userSelect.loginWith", {provider: label || identifier})}
                </div>
              ))}
            </ButtonSelect>
          );
        }
      })()}

      <SettingsDialog
        title={t("components.userSelect.registerModal.title")}
        open={user.isRegisterDialogOpen && !disabled}
        onClose={() => {
          if (registerFormRef.current) {
            registerFormRef.current.cancel(() => {
              onRegisterModalHide();
            });
          } else {
            onRegisterModalHide();
          }
        }}
        onSubmit={() => {
          if (registerFormRef.current) {
            registerFormRef.current.submit(() => {});
          }
        }}
        hasSubmit
        customSubmitLabel={t("components.userSelect.registerModal.submit.label")}
      >
        <UserSettingsForm ref={registerFormRef} userId={-1} isAnonymous />
      </SettingsDialog>

      <SettingsDialog
        title={t("components.userSelect.editUserModal.title")}
        open={user.isEditUserDialogOpen && !disabled}
        onClose={() => {
          if (editUserFormRef.current) {
            editUserFormRef.current.cancel(() => {
              onEditModalHide();
            });
          } else {
            onEditModalHide();
          }
        }}
        onSubmit={() => {
          if (editUserFormRef.current) {
            editUserFormRef.current.submit(() => {});
          }
        }}
        hasSubmit
        noMinHeight
        noFullScreen
      >
        <EditUserForm ref={editUserFormRef} />
      </SettingsDialog>

      <SettingsDialog
        title={t("components.userSelect.changePasswordModal.title")}
        maxWidth={"xs"}
        open={user.isChangePasswordDialogOpen && !disabled}
        onClose={() => {
          if (changePasswordFormRef.current) {
            changePasswordFormRef.current.cancel(() => {
              onChangePasswordHide();
            });
          } else {
            onChangePasswordHide();
          }
        }}
        onSubmit={() => {
          if (changePasswordFormRef.current) {
            changePasswordFormRef.current.submit(() => {});
          }
        }}
        hasSubmit
        noMinHeight
        noFullScreen
      >
        <UserChangePasswordForm ref={changePasswordFormRef} />
      </SettingsDialog>

      <SettingsDialog
        title={t("scenes.viewsSettings.title")}
        open={isViewsOpen}
        onClose={() => {
          if (viewsSettingsRef.current) {
            viewsSettingsRef.current.cancel(() => {
              onViewsClose();
            });
          } else {
            onViewsClose();
          }
        }}
      >
        <UserViewsSettingsForm ref={viewsSettingsRef} nodes={nodes} onViewsClose={onViewsClose} />
      </SettingsDialog>

      <SettingsDialog
        title={t("scenes.dashboardsSettings.title")}
        open={modulesConfig.modules.includes("dashboard") && isDashboardsOpen}
        onClose={() => {
          if (dashboardsSettingsRef.current) {
            dashboardsSettingsRef.current.cancel(() => {
              onDashboardsClose();
            });
          } else {
            onDashboardsClose();
          }
        }}
      >
        <ItemContainerSettingsFormProvider>
          <UserItemContainersSettingsForm asDashboard={true} ref={dashboardsSettingsRef} />
        </ItemContainerSettingsFormProvider>
      </SettingsDialog>

      <ModulesPlaceholder id="user-select-dialog" />
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
