import BitSet from "bitset";
import {t} from "i18next";
import _ from "lodash";
import {
  getAttributeLabel,
  getAttributeValueLabel,
  getCoordinatesArrayFromDataIdx,
  getDataIdxFromCoordinatesArray,
  getDimensionAttributeMap,
  getDimensionValuesIndexesMap,
  getFormattedDimensionLabel,
  getFormattedDimensionValueLabel,
  getObservationAttributeMap,
  MARGINAL_ATTRIBUTE_KEY,
  MARGINAL_DIMENSION_KEY,
  VARIATION_DIMENSION_KEY,
  VARIATION_VALUE_CYCLICAL_KEY,
  VARIATION_VALUE_TREND_KEY,
  VARIATION_VALUE_VALUE_KEY
} from "../../../utils/dataset";
import {getFormattedValue} from "../../../utils/formatters";
import {getCombinationArrays} from "../../../utils/other";
import {getMappedTree} from "../../../utils/tree";
import {isNumeric} from "../../../utils/validator";

export const TABLE_HEADER_NORMAL = "TABLE_HEADER_NORMAL";
export const TABLE_HEADER_MERGED = "TABLE_HEADER_MERGED";

export const FILTER_TYPE_OBS = "FILTER_TYPE_OBS";
export const FILTER_TYPE_DIM = "FILTER_TYPE_DIM";

export const OBS_FILTER_OPERATOR_AND = "OBS_FILTER_OPERATOR_AND";
export const OBS_FILTER_OPERATOR_OR = "OBS_FILTER_OPERATOR_OR";
export const OBS_FILTER_OPERATOR_EQUAL = "OBS_FILTER_OPERATOR_EQUAL";
export const OBS_FILTER_OPERATOR_NOT_EQUAL = "OBS_FILTER_OPERATOR_NOT_EQUAL";
export const OBS_FILTER_OPERATOR_GREATER_OR_EQUAL = "OBS_FILTER_OPERATOR_GREATER_OR_EQUAL";
export const OBS_FILTER_OPERATOR_GREATER = "OBS_FILTER_OPERATOR_GREATER";
export const OBS_FILTER_OPERATOR_LESS_OR_EQUAL = "OBS_FILTER_OPERATOR_LESS_OR_EQUAL";
export const OBS_FILTER_OPERATOR_LESS = "OBS_FILTER_OPERATOR_LESS";

export const DIM_FILTER_OPERATOR_EQUAL = "DIM_FILTER_OPERATOR_EQUAL";
export const DIM_FILTER_OPERATOR_NOT_EQUAL = "DIM_FILTER_OPERATOR_NOT_EQUAL";
export const DIM_FILTER_OPERATOR_STARTS_WITH = "DIM_FILTER_OPERATOR_STARTS_WITH";
export const DIM_FILTER_OPERATOR_INCLUDES = "DIM_FILTER_OPERATOR_INCLUDES";
export const DIM_FILTER_OPERATOR_NOT_INCLUDES = "DIM_FILTER_OPERATOR_NOT_INCLUDES";
export const DIM_FILTER_OPERATOR_ENDS_WITH = "DIM_FILTER_OPERATOR_ENDS_WITH";

export const DIM_VALUE_LABEL_MODIFIER_REMOVE = "replace";
export const DIM_VALUE_LABEL_MODIFIER_APPEND = "append";
export const DIM_VALUE_LABEL_MODIFIER_PREPEND = "prepend";

const willValuePassObsFilters = (value, filter, decimalSeparator, roundingStrategy, decimalPlaces) => {
  if (!isNumeric(value)) {
    return false;
  }

  const computeSingleEntity = (value, entity) => {
    if (!isNumeric(entity.filterValue)) {
      return false;
    }

    const formattedValue = getFormattedValue(value, decimalSeparator, decimalPlaces, "", roundingStrategy);
    const formattedFilterValue = getFormattedValue(
      entity.filterValue,
      decimalSeparator,
      decimalPlaces,
      "",
      roundingStrategy
    );

    if ((formattedValue || "").length === 0 || (formattedFilterValue || "").length === 0) {
      return false;
    }

    const thousandsSeparator = decimalSeparator === "." ? "," : ".";

    const numericFormattedValue = Number(
      formattedValue.replaceAll(thousandsSeparator, "").replace(decimalSeparator, ".")
    );
    const numericFormattedFilterValue = Number(
      formattedFilterValue.replaceAll(thousandsSeparator, "").replace(decimalSeparator, ".")
    );

    switch (entity.operator) {
      case OBS_FILTER_OPERATOR_EQUAL: {
        return numericFormattedValue === numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_NOT_EQUAL: {
        return numericFormattedValue !== numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_GREATER_OR_EQUAL: {
        return numericFormattedValue >= numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_GREATER: {
        return numericFormattedValue > numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_LESS_OR_EQUAL: {
        return numericFormattedValue <= numericFormattedFilterValue;
      }
      case OBS_FILTER_OPERATOR_LESS: {
        return numericFormattedValue < numericFormattedFilterValue;
      }
      default: {
        return false;
      }
    }
  };

  const isEntity1Valorized = (filter.entity1.filterValue || "").length > 0;
  const isEntity2Valorized = (filter.entity2.filterValue || "").length > 0;

  const isEntity1Passed = isEntity1Valorized && computeSingleEntity(value, filter.entity1);
  const isEntity2Passed = isEntity2Valorized && computeSingleEntity(value, filter.entity2);

  if (isEntity1Valorized && isEntity2Valorized) {
    if (filter.operator === OBS_FILTER_OPERATOR_AND) {
      return isEntity1Passed && isEntity2Passed;
    } else {
      return isEntity1Passed || isEntity2Passed;
    }
  } else if (isEntity1Valorized) {
    return isEntity1Passed;
  } else if (isEntity2Valorized) {
    return isEntity2Passed;
  } else {
    return false;
  }
};

const willValuePassDimFilters = (value, filter) => {
  const operator = filter.operator;
  const filterValue = (filter.filterValue || "").toLowerCase();

  if ((value || "").length === 0) {
    return false;
  }

  switch (operator) {
    case DIM_FILTER_OPERATOR_EQUAL: {
      return value.toLowerCase() === filterValue;
    }
    case DIM_FILTER_OPERATOR_NOT_EQUAL: {
      return value.toLowerCase() !== filterValue;
    }
    case DIM_FILTER_OPERATOR_STARTS_WITH: {
      return value.toLowerCase().startsWith(filterValue);
    }
    case DIM_FILTER_OPERATOR_INCLUDES: {
      return value.toLowerCase().includes(filterValue);
    }
    case DIM_FILTER_OPERATOR_NOT_INCLUDES: {
      return !value.toLowerCase().includes(filterValue);
    }
    case DIM_FILTER_OPERATOR_ENDS_WITH: {
      return value.toLowerCase().endsWith(filterValue);
    }
    default: {
      return false;
    }
  }
};

const TABLE_PREVIEW_PLACEHOLDER = "xxx";
const TABLE_SECTION_DIMENSIONS_SEPARATOR_ICON =
  '<svg viewBox="0 0 24 24" height="20px" width="20px" fill="currentColor"><path d="M 8 8 H 16 V 16 H 8 Z"/></svg>';
const TABLE_SECTION_DIMENSIONS_SEPARATOR = `<span style="display: inline-block; vertical-align: middle; margin: 0 2px; height: 20px;">${TABLE_SECTION_DIMENSIONS_SEPARATOR_ICON}</span>`;

const upIcon =
  '<svg viewBox="0 0 24 24" height="20px" width="20px" fill="currentColor" style="transform: scale(1.4);"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 14l5-5 5 5z"/></svg>';
const downIcon =
  '<svg viewBox="0 0 24 24" height="20px" width="20px" fill="currentColor" style="transform: scale(1.4);"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>';
const filterIcon =
  '<svg viewBox="0 0 24 24" height="20px" width="20px" fill="currentColor" style="transform: scale(0.9);"><g><path d="M0,0h24 M24,24H0" fill="none"/><path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/><path d="M0,0h24v24H0V0z" fill="none"/></g></svg>';

const TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT = 2;

const getDimSpanMap = (jsonStat, arr, length) => {
  const obj = {};
  arr.forEach((el, idx) => {
    obj[el] =
      idx === 0
        ? length / jsonStat.size[jsonStat.id.indexOf(el)]
        : obj[arr[idx - 1]] / jsonStat.size[jsonStat.id.indexOf(el)];
  });

  return obj;
};

const getDimValuesMap = (jsonStat, repCount, arr, length, dimsToInvert) => {
  const obj = {};

  arr.forEach(dim => {
    if (repCount[dim]) {
      obj[dim] = [];
      const dimValues = !dimsToInvert.includes(dim)
        ? jsonStat.dimension[dim].category.index
        : jsonStat.dimension[dim].category.index.slice().reverse();

      obj[dim] = [...dimValues];
    }
  });

  return obj;
};

const getFirstIndex = (bitSets, n, bitSetLength) => {
  let c = 0;

  for (let i = 0; i < bitSets.length; i++) {
    const bitSet = bitSets[i];
    for (let j = 0; j < bitSetLength; j++) {
      if (bitSet.get(j)) {
        if (c === n) {
          return i * bitSetLength + j;
        }
        c++;
      }
    }
  }

  return -1;
};

const getJsonStatLayoutObjects = (jsonStat, rows, cols, sections, dimsToInvert, isPreview) => {
  /** rows handling **/
  let sectionRowCountFull = 1;
  (rows || []).forEach(row => (sectionRowCountFull *= jsonStat.size[jsonStat.id.indexOf(row)]));

  /** cols handling **/
  let colCountFull = 1;
  (cols || []).forEach(col => (colCountFull *= jsonStat.size[jsonStat.id.indexOf(col)]));

  /** sections handling **/
  const sectionArray = [];
  (sections || []).forEach(section => {
    const array = !dimsToInvert.includes(section)
      ? jsonStat.dimension[section].category.index
      : jsonStat.dimension[section].category.index.slice().reverse();
    sectionArray.push(array);
  });
  const sectionDimCombinations = sectionArray.length > 0 ? getCombinationArrays(sectionArray) : [];

  const indexesMap = getDimensionValuesIndexesMap(jsonStat);

  const dimSpanMap = {
    ...getDimSpanMap(jsonStat, rows, sectionRowCountFull),
    ...getDimSpanMap(jsonStat, cols, colCountFull)
  };

  let dimValuesMap = null;
  if (!isPreview) {
    dimValuesMap = {
      ...getDimValuesMap(jsonStat, dimSpanMap, rows, sectionRowCountFull, dimsToInvert),
      ...getDimValuesMap(jsonStat, dimSpanMap, cols, colCountFull, dimsToInvert)
    };
  }

  return {
    sectionRowCountFull,
    colCountFull,
    sectionDimCombinations,
    indexesMap,
    dimSpanMap,
    dimValuesMap
  };
};

export const getDimensionValueFromIdx = (dim, idx, dimValuesMap, dimSpanMap) =>
  dimValuesMap[dim][Math.floor(idx / dimSpanMap[dim]) % dimValuesMap[dim].length];

function isSubset(subset, superset) {
  const subsetSet = new Set(subset);
  const supersetSet = new Set(superset);
  for (let element of subsetSet) {
    if (!supersetSet.has(element)) {
      return false;
    }
  }
  return true;
}

export const getTableSupportStructures = (
  jsonStat,
  layout,
  isPreview,
  removeEmptyLines,
  showTrend,
  showCyclical,
  filterable,
  filters,
  labelFormat,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  invertedDims,
  hierarchyOnlyAttributes,
  hideHierarchyOnlyRows,
  localizedTimePeriodFormatMap,
  attributesAsDim
) => {
  if (isPreview) {
    return null;
  }

  const {rows, cols, filters: layoutFilters, filtersValue, sections} = layout;

  const dimsToInvert = (invertedDims || []).filter(dimensionId =>
    [...rows, ...cols, ...sections].includes(dimensionId)
  );

  const {sectionRowCountFull, colCountFull, sectionDimCombinations, indexesMap, dimSpanMap, dimValuesMap} =
    getJsonStatLayoutObjects(jsonStat, rows, cols, sections, dimsToInvert, isPreview);

  const sectionIdxs = sectionDimCombinations.length > 0 ? sectionDimCombinations.map((_, idx) => idx) : [0];

  const valueMatrix = {};

  const valorizedSectionRows = sectionIdxs.map(() => new BitSet());
  const valorizedCols = new BitSet();

  /** dimension attributes handling **/

  const dimAttributeMap = getDimensionAttributeMap(jsonStat, t, attributesAsDim);

  /** observation attributes handling **/

  const obsAttributeMap = getObservationAttributeMap(jsonStat);

  /** attributes as dimension handling **/

  const datasetId = jsonStat?.extension?.datasets?.[0];
  const observationAttributes = jsonStat?.extension?.attributes?.[datasetId]?.observation || [];
  const seriesAttributes = jsonStat?.extension?.attributes?.[datasetId]?.series || [];
  const seriesAttributeIndexes = jsonStat?.extension?.attributes?.[datasetId]?.index?.series || [];

  const seriesAttributeMap = {};
  seriesAttributeIndexes.forEach(({coordinates, attributes}) => {
    attributes.forEach((attrValueIdx, attrIdx) => {
      if (attrValueIdx !== null) {
        const attr = seriesAttributes?.[attrIdx]?.id;
        const attrValue = seriesAttributes?.[attrIdx]?.values?.[attrValueIdx];
        const dims = coordinates
          .map((dimValueIdx, dimIdx) => {
            if (dimValueIdx === null) {
              return null;
            }
            const dim = jsonStat.id[dimIdx];
            const dimValue = jsonStat.dimension[dim].category.index[dimValueIdx];
            return dimValue;
          })
          .filter(dim => dim !== null)
          .join();
        if (!seriesAttributeMap[attr]) {
          seriesAttributeMap[attr] = {};
        }
        seriesAttributeMap[attr][dims] = {
          id: attr,
          label: seriesAttributes?.[attrIdx]?.name ?? attr,
          valueId: attrValue.id,
          valueLabel: attrValue.name ?? attrValue.id
        };
      }
    });
  }, {});

  const colAttributes = [];
  const rowAttributes = [];
  const sectionAttributes = [];
  const spreadAttributes = [];
  const obsAttributes = [];

  (attributesAsDim ?? []).forEach(attributeId => {
    const seriesAttribute = seriesAttributes.find(({id}) => id === attributeId);
    const observationAttribute = observationAttributes.find(({id}) => id === attributeId);
    if (seriesAttribute) {
      const dimensions = [];
      jsonStat.id.forEach(dim => {
        if ((seriesAttribute?.relationship?.dimensions ?? []).includes(dim)) {
          dimensions.push(dim);
        }
      });

      if (isSubset(dimensions, cols)) {
        colAttributes.push({
          id: seriesAttribute.id,
          label: seriesAttribute.name,
          dims: dimensions,
          size: dimensions.length
        });
      } else if (isSubset(dimensions, rows)) {
        rowAttributes.push({
          id: seriesAttribute.id,
          label: seriesAttribute.name,
          dims: dimensions,
          size: dimensions.length
        });
      } else if (isSubset(dimensions, sections)) {
        sectionAttributes.push({
          id: seriesAttribute.id,
          label: seriesAttribute.name,
          dims: dimensions,
          size: dimensions.length
        });
      } else if (dimensions.length > 1) {
        spreadAttributes.push({
          id: seriesAttribute.id,
          label: seriesAttribute.name,
          dims: dimensions,
          size: dimensions.length
        });
      }
    } else if (observationAttribute) {
      obsAttributes.push({
        id: observationAttribute.id,
        label: observationAttribute.name
      });
    }
  });

  colAttributes.sort((a, b) => b.size - a.size);
  rowAttributes.sort((a, b) => b.size - a.size);
  sectionAttributes.sort((a, b) => b.size - a.size);

  /** empty rows & cols handling **/

  if (!removeEmptyLines) {
    valorizedSectionRows.forEach(valorizedRows => {
      valorizedRows.setRange(0, sectionRowCountFull, 1);
    });
    valorizedCols.setRange(0, colCountFull, 1);
  }

  const dimensionFilterValues = {};

  /** dimension filter from layout **/

  layoutFilters.forEach(dim => {
    dimensionFilterValues[dim] = {};
    dimensionFilterValues[dim][filtersValue[dim]] = 1;
  });

  /** dimension filter from variation selector **/

  if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
    dimensionFilterValues[VARIATION_DIMENSION_KEY] = {};
    dimensionFilterValues[VARIATION_DIMENSION_KEY][VARIATION_VALUE_VALUE_KEY] = 1;
    if (showTrend) {
      dimensionFilterValues[VARIATION_DIMENSION_KEY][VARIATION_VALUE_TREND_KEY] = 1;
    }
    if (showCyclical) {
      dimensionFilterValues[VARIATION_DIMENSION_KEY][VARIATION_VALUE_CYCLICAL_KEY] = 1;
    }
  }

  /** observation handling **/

  Object.keys(jsonStat.value).forEach(key => {
    const obsAttributes = obsAttributeMap[key] || [];
    const obsValue = jsonStat.value[key];

    const isHierarchicalOnlyObs =
      (obsValue === null || obsValue === "") &&
      obsAttributes.length === 1 &&
      (hierarchyOnlyAttributes || []).includes(`${obsAttributes[0].id}+${obsAttributes[0].valueId}`) &&
      (hideHierarchyOnlyRows ||
        !rows.find(row => Object.keys(jsonStat.dimension[row].category?.child || {}).length > 0)); // there are no hierarchical dimensions in rows

    if (obsValue !== undefined && !isHierarchicalOnlyObs) {
      let coordinates = getCoordinatesArrayFromDataIdx(key, jsonStat.size);

      dimsToInvert.forEach(dim => {
        const dimIdx = jsonStat.id.indexOf(dim);
        coordinates[dimIdx] = jsonStat.size[dimIdx] - 1 - coordinates[dimIdx];
      });

      const obsDimValues = {};
      jsonStat.id.forEach((dim, dimIdx) => {
        obsDimValues[dim] = jsonStat.dimension[dim].category.index[coordinates[dimIdx]];
      });

      const isFiltered =
        jsonStat.id.find(dim => dimensionFilterValues[dim] && !dimensionFilterValues[dim][obsDimValues[dim]]) !==
        undefined;

      if (!isFiltered) {
        let secIdx = 0;
        if (sections.length > 0) {
          const sectionValueArray = new Array(sections.length);
          coordinates.forEach((val, idx) => {
            const dim = jsonStat.id[idx];
            if (sections.includes(dim)) {
              sectionValueArray[sections.indexOf(dim)] = jsonStat.dimension[dim].category.index[val];
            }
          });
          secIdx = sectionDimCombinations.findIndex(
            combination => combination.join("+") === sectionValueArray.join("+")
          );
        }

        let colIdx = 0;
        cols.forEach(col => {
          const val = coordinates[jsonStat.id.indexOf(col)];
          colIdx += val * dimSpanMap[col];
        });

        let rowIdx = 0;
        rows.forEach(row => {
          const val = coordinates[jsonStat.id.indexOf(row)];
          rowIdx += val * dimSpanMap[row];
        });

        valorizedSectionRows[secIdx].set(rowIdx, 1);

        valorizedCols.set(colIdx, 1);

        if (!valueMatrix[secIdx]) {
          valueMatrix[secIdx] = {};
        }
        if (!valueMatrix[secIdx][colIdx]) {
          valueMatrix[secIdx][colIdx] = {};
        }
        valueMatrix[secIdx][colIdx][rowIdx] = jsonStat.value[key];
      }
    }
  });

  /** table filters handling **/

  let filteredRows = null;
  if (filterable && !_.isEmpty(filters)) {
    const dimTableFilters = Object.fromEntries(
      Object.entries(filters || {}).filter(([key, val]) => val.type === FILTER_TYPE_DIM)
    );
    const obsTableFilters = Object.fromEntries(
      Object.entries(filters || {}).filter(([key, val]) => val.type === FILTER_TYPE_OBS)
    );

    filteredRows = {};

    valorizedSectionRows.forEach((valorizedRows, s) => {
      for (let r = 0; r < sectionRowCountFull; r++) {
        if (valorizedRows.get(r)) {
          let passFilter = true;

          /** observation filters **/
          Object.keys(obsTableFilters).forEach(c => {
            const value = valueMatrix?.[s]?.[c]?.[r];

            passFilter =
              passFilter &&
              willValuePassObsFilters(value, filters[c], decimalSeparator, roundingStrategy, decimalPlaces);
          });

          /** dimension filters **/
          Object.keys(dimTableFilters).forEach(dim => {
            const dimValue = getDimensionValueFromIdx(dim, r, dimValuesMap, dimSpanMap);
            let dimValueLabel = getFormattedDimensionValueLabel(
              jsonStat,
              null,
              dim,
              dimValue,
              labelFormat,
              null,
              localizedTimePeriodFormatMap
            );
            passFilter = passFilter && willValuePassDimFilters(dimValueLabel, filters[dim]);
          });

          if (passFilter) {
            const rowDimValues = rows.map(row => getDimensionValueFromIdx(row, r, dimValuesMap, dimSpanMap)).join("+");
            filteredRows[rowDimValues] = 1;
          } else {
            valorizedRows.set(r, 0);
            Object.keys(valueMatrix[s]).forEach(c => delete valueMatrix[s][c][r]);
          }
        }
      }
    });
  }

  /** order handling **/

  const sectionRowsOrder = {};
  valorizedSectionRows.forEach((valorizedRows, sIdx) => {
    sectionRowsOrder[sIdx] = {};
    for (let i = 0; i < sectionRowCountFull; i++) {
      if (valorizedRows.get(i)) {
        sectionRowsOrder[sIdx][i] = i;
      }
    }
  });

  /** hierarchical items handling **/

  const depths = {};
  rows.forEach(row => {
    const depth = {};
    const dimValues = jsonStat.dimension[row].category.index ?? [];
    const jsonStatChildMap = jsonStat.dimension[row].category?.child ?? {};

    if (!_.isEmpty(jsonStatChildMap)) {
      /* builds a tree with the info in the JsonStat to get the complete hierarchy */
      const completeHierarchy = [];
      const map = {};
      dimValues.forEach(key => {
        map[key] = {
          id: key,
          children: []
        };
      });
      Object.keys(jsonStatChildMap).forEach(parent => {
        jsonStatChildMap[parent].forEach(child => {
          map[child] = {
            id: child,
            children: [],
            parent: parent
          };
        });
      });
      Object.keys(map).forEach(key => {
        const item = map[key];
        if (item.parent && map[item.parent] && map[item.parent].children) {
          // if the element is not at the root level, add it to its parent array of children.
          map[item.parent].children.push(item);
        } else {
          // if the element is at the root level, add it to first level elements array.
          completeHierarchy.push(item);
        }
      });

      /* this function remove nodes not present in the JsonStat */
      const removeNodes = tree =>
        tree.flatMap(
          node =>
            dimValues.includes(node.id)
              ? [{...node, children: removeNodes(node.children)}] // filter children recursively
              : removeNodes(node.children) // the node must be removed: it returns its children to ascend the hierarchy
        );

      const filteredHierarchy = removeNodes(completeHierarchy);

      /* this function iterates over the tree and saves the depth of each node in the depth map */
      getMappedTree(filteredHierarchy, "children", parent => {
        parent.children.forEach(child => {
          depth[child.id] = (depth[parent.id] ?? 0) + 1;
        });
        return parent;
      });
    }

    depths[row] = depth;
  });

  const valorizedSectionRowsArr = valorizedSectionRows.map(valorizedRows => valorizedRows.toArray());
  const valorizedColsArr = valorizedCols.toArray();

  /** row and col count handling **/

  let rowCount = 0;
  valorizedSectionRows.forEach(valorizedRows => (rowCount += valorizedRows.cardinality()));

  const colCount = valorizedCols.cardinality();

  return {
    rowCount: rowCount,
    colCount: colCount,
    sectionRowCountFull: sectionRowCountFull,
    rowCountFull:
      sectionDimCombinations && sectionDimCombinations.length > 0
        ? sectionDimCombinations.length * sectionRowCountFull
        : sectionRowCountFull,
    colCountFull: colCountFull,
    dimSpanMap: dimSpanMap,
    dimValuesMap: dimValuesMap,
    sectionDimCombinations: sectionDimCombinations,
    indexesMap: indexesMap,
    valorizedSectionRowsArr: valorizedSectionRowsArr,
    valorizedColsArr: valorizedColsArr,
    dimAttributeMap: dimAttributeMap,
    obsAttributeMap: obsAttributeMap,
    isPreview: isPreview,
    showTrend: showTrend,
    showCyclical: showCyclical,
    valueMatrix: valueMatrix,
    sectionRowsOrder: sectionRowsOrder,
    filters: filters,
    filteredRows: filteredRows ? Object.keys(filteredRows) : null,
    depths: depths,
    seriesAttributeMap: seriesAttributeMap,
    colAttributes: colAttributes,
    rowAttributes: rowAttributes,
    sectionAttributes: sectionAttributes,
    spreadAttributes: spreadAttributes,
    obsAttributes: obsAttributes
  };
};

const getTableRightPadding = () => `<td class="c c-rb"></td>`;

const getTableHeaderRightPadding = () => `<th class="c c-rb"></th>`;

const getTableHeaderDimensionValueCells = (
  tableSupportStructures,
  valorizedCols,
  paginationParams,
  labelFormat,
  fontSize,
  firstColIdx,
  getTextWidthEl,
  dimension,
  splitHeaderCells,
  t,
  localizedTimePeriodFormatMap
) => {
  const {
    colCountFull,
    jsonStat,
    dimSpanMap,
    dimValuesMap,
    dimAttributeMap,
    obsAttributes = []
  } = tableSupportStructures;

  const timeDim = jsonStat.role?.time?.[0];

  let string = "";

  let colCount = 0;
  let c = firstColIdx;
  while (c < colCountFull && colCount < paginationParams.colPerPage) {
    const colSpanMax = splitHeaderCells ? 1 : dimSpanMap[dimension] - (c % dimSpanMap[dimension]);

    const colSpan = valorizedCols.slice(c, c + colSpanMax - 1).cardinality() * (obsAttributes.length + 1);

    if (colSpan > 0) {
      const dimensionValue = getDimensionValueFromIdx(dimension, c, dimValuesMap, dimSpanMap);
      let cellText = getFormattedDimensionValueLabel(
        jsonStat,
        null,
        dimension,
        dimensionValue,
        labelFormat,
        t,
        localizedTimePeriodFormatMap
      );

      let datasetId;
      if (dimension === MARGINAL_DIMENSION_KEY) {
        const marginal = jsonStat.extension.marginalvalues[dimensionValue];
        datasetId = marginal.label ? MARGINAL_ATTRIBUTE_KEY : marginal.datasetid;
      } else {
        datasetId = jsonStat?.extension?.datasets?.[0];
      }

      const htmlString = dimAttributeMap?.[datasetId]?.[dimension]?.[dimensionValue]
        ? `<span id="${datasetId}:${dimension}:${dimensionValue}" class="ct ctsh">(*)</span>`
        : "";

      window.jQuery(getTextWidthEl).addClass(`c cf${fontSize} csh ${htmlString.length > 0 ? "ca" : ""}`);
      window.jQuery(`<span>${cellText + htmlString}<span/>`).appendTo(getTextWidthEl);
      const textWidth = window.jQuery(getTextWidthEl).innerWidth();
      window.jQuery(getTextWidthEl).removeClass().empty();

      cellText += htmlString;

      string +=
        `<th ` +
        `class="c cf${fontSize} csh ${htmlString.length > 0 ? "ca" : ""}" ` +
        `colspan="${colSpan}" ` +
        `style="white-space: ${dimension === timeDim ? "nowrap" : "normal"}; min-width: ${textWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 40}px;"` +
        `>` +
        cellText +
        `</th>`;

      colCount += colSpan;
    }

    c += colSpanMax;
  }

  if (paginationParams) {
    string += getTableHeaderRightPadding();
  }

  return string;
};

const getTableHeaderIconCells = (
  tableSupportStructures,
  valorizedCols,
  paginationParams,
  labelFormat,
  fontSize,
  firstColIdx,
  sortable,
  orderedCol,
  isOrderedAscending,
  filterable
) => {
  const {colCountFull, isPreview, filters, obsAttributes = []} = tableSupportStructures;

  let string = "";
  if (!isPreview) {
    let colCount = 0;
    for (let c = firstColIdx; c < colCountFull && colCount < paginationParams.colPerPage; c++) {
      if (valorizedCols.get(c)) {
        let iconsWidth = 0 + (sortable ? 48 : 0) + (filterable ? 24 : 0);

        string +=
          `<th ` +
          `class="c cf${fontSize} csh csh-icons ${colCount === 0 ? "csh-icons--first" : ""}" ` +
          `colspan="1" ` +
          `style="min-width: ${iconsWidth + 8 + "px"}" ` +
          `>`;

        string += "&nbsp;"; // needed to handle cell height

        string += `<div class="table-icons" style="width: ${iconsWidth}px; left: calc(50% - ${iconsWidth / 2}px)">`;
        if (sortable) {
          string +=
            `<div ` +
            `id="c-${c}" ` +
            `class="table-icon col-sort col-sort--a ${orderedCol === c && isOrderedAscending ? "table-icon--selected" : ""}" ` +
            `>` +
            upIcon +
            `</div>` +
            `<div ` +
            `id="c-${c}" ` +
            `class="table-icon col-sort col-sort--d ${orderedCol === c && !isOrderedAscending ? "table-icon--selected" : ""}" ` +
            `>` +
            downIcon +
            `</div>`;
        }
        if (filterable) {
          string +=
            `<div ` +
            `id="c-${c}" ` +
            `class="table-icon col-filter ${filters && filters[c] ? "table-icon--selected" : ""}" ` +
            `>` +
            filterIcon +
            `</div>`;
        }

        string += "</div>";

        string += "</th>";

        let s = "";
        obsAttributes.forEach(attr => {
          s += `<th class="c cf${fontSize} csh c-attr-as-dim">${getAttributeLabel(attr, labelFormat)}</th>`;
        });
        string += s;

        colCount++;
      }
    }
  } else {
    string += `<th class="c cf${fontSize} csh" colspan="${3}"/>`;
  }

  if (paginationParams) {
    string += getTableHeaderRightPadding();
  }

  return string;
};

const getTableHeader = (
  uuid,
  tableSupportStructures,
  valorizedCols,
  paginationParams,
  labelFormat,
  fontSize,
  firstRowIdx,
  firstColIdx,
  sortable,
  orderedCol,
  isOrderedAscending,
  filterable,
  splitHeaderCells,
  t,
  localizedTimePeriodFormatMap
) => {
  const {
    jsonStat,
    layout,
    colCountFull,
    dimSpanMap,
    dimValuesMap,
    isPreview,
    showTrend,
    showCyclical,
    filters,
    seriesAttributeMap,
    rowAttributes = [],
    colAttributes = [],
    obsAttributes = []
  } = tableSupportStructures;

  const {rows, cols} = layout;

  const getTextWidthEl = window
    .jQuery("<span/>")
    .css({visibility: "hidden"})
    .appendTo(`#jsonstat-table__${uuid}`)
    .get(0);

  let thead = `<thead class="table-head">`;

  const filteredCols = cols.filter(col => col !== VARIATION_DIMENSION_KEY || showTrend || showCyclical);
  filteredCols.forEach((col, idx) => {
    thead += `<tr id="h-${idx}">`;

    const cellText = getFormattedDimensionLabel(jsonStat, null, col, labelFormat, t);

    window.jQuery(getTextWidthEl).addClass(`c cf${fontSize} ch`);
    window.jQuery(`<span>${cellText}<span/>`).appendTo(getTextWidthEl);
    const minWidth = window.jQuery(getTextWidthEl).innerWidth();
    window.jQuery(getTextWidthEl).removeClass().empty();

    thead +=
      `<th ` +
      `class="c cf${fontSize} ch cl0" ` +
      `colspan="${rows.length + rowAttributes.length}" ` +
      `style="min-width: ${minWidth / TABLE_HEADER_CELL_TEXT_MAX_ROW_COUNT + 24}px" ` +
      `>` +
      cellText +
      `</th>`;

    if (!isPreview) {
      thead += getTableHeaderDimensionValueCells(
        tableSupportStructures,
        valorizedCols,
        paginationParams,
        labelFormat,
        fontSize,
        firstColIdx,
        getTextWidthEl,
        col,
        splitHeaderCells,
        t,
        localizedTimePeriodFormatMap
      );
    } else {
      for (let c = 0; c < 3; c++) {
        thead += `<th class="c cf${fontSize} csh" colspan="1">${TABLE_PREVIEW_PLACEHOLDER}</th>`;
      }
    }

    thead += "</tr>";
  });

  if (colAttributes.length > 0) {
    colAttributes.forEach((attr, idx) => {
      thead += `<tr id="ha-${idx}">`;
      thead += `<th class="c cf${fontSize} ch c-attr-as-dim" colspan="${rows.length + rowAttributes.length}">${attr.label}</th>`;

      let c = firstColIdx;
      let renderedColCount = 0;
      while (c < colCountFull && renderedColCount < paginationParams.colPerPage) {
        if (valorizedCols.get(c)) {
          const dims = [];
          const _c = c;
          attr.dims.forEach(dim => dims.push(getDimensionValueFromIdx(dim, _c, dimValuesMap, dimSpanMap)));
          thead +=
            `<th class="c cf${fontSize} csh c-attr-as-dim" colspan="${obsAttributes.length + 1}">` +
            (dims.length > 0 && seriesAttributeMap?.[attr.id]?.[dims.join()]
              ? getAttributeValueLabel(seriesAttributeMap[attr.id][dims.join()], labelFormat)
              : "") +
            `</th>`;
          renderedColCount++;
        }
        c++;
      }

      thead += getTableRightPadding();

      thead += "</tr>";
    });
  }

  thead += `<tr id="hh">`;
  if (rows.length > 0) {
    rows.forEach((row, idx) => {
      thead += `<th class="c cf${fontSize} ch cl${idx} ${filterable ? "ch-icons" : ""}">`;
      thead += getFormattedDimensionLabel(jsonStat, null, row, labelFormat, t);
      if (filterable) {
        thead +=
          `<div ` +
          `id="c-${row}" ` +
          `class="table-icon col-filter ${filters && filters[row] ? "table-icon--selected" : ""}" ` +
          `>` +
          filterIcon +
          `</div>`;
      }
      thead += "</th>";
    });
    rowAttributes.forEach(({label}) => {
      thead += `<th class="c cf${fontSize} ch c-attr-as-dim">${label}</th> `;
    });
  } else {
    thead += '<th class="c ch">&nbsp;</th>';
  }
  thead += getTableHeaderIconCells(
    tableSupportStructures,
    valorizedCols,
    paginationParams,
    labelFormat,
    fontSize,
    firstColIdx,
    sortable,
    orderedCol,
    isOrderedAscending,
    filterable
  );
  thead += "</tr>";

  thead += "</thead>";

  window.jQuery(getTextWidthEl).remove();

  return thead;
};

export const getTableHtml = (
  uuid,
  tableSupportStructures,
  paginationParams,
  labelFormat,
  fontSize,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  emptyChar,
  sortable,
  orderedCol,
  isOrderedAscending,
  sectionRowsOrder,
  filterable,
  hierarchyOnlyAttributes,
  onPageGenerationComplete,
  splitHeaderCells,
  splitSideCells,
  t,
  localizedTimePeriodFormatMap
) => {
  const t0 = performance.now();

  const {
    jsonStat,
    layout,
    rowCount,
    colCount,
    valorizedCols,
    valorizedSectionRows,
    sectionRowCountFull,
    rowCountFull,
    colCountFull,
    dimSpanMap,
    dimValuesMap,
    sectionDimCombinations,
    indexesMap,
    dimAttributeMap,
    obsAttributeMap,
    filteredRows,
    depths,
    seriesAttributeMap,
    rowAttributes = [],
    sectionAttributes = [],
    spreadAttributes = [],
    obsAttributes = []
  } = tableSupportStructures;

  const {rows, cols, filtersValue, sections} = layout;

  const timeDim = jsonStat.role?.time?.[0];

  if (rowCount + colCount === 0) {
    return "";
  }

  let renderedRows = [];
  let renderedCols = [];

  const isOrderingRow = orderedCol !== null;

  /** order **/

  let orderedValorizedSectionRows;
  if (isOrderingRow) {
    orderedValorizedSectionRows = valorizedSectionRows.map((valorizedRows, sIdx) => {
      const orderedValorizedRows = new BitSet();
      let i = 0;
      for (let b of valorizedRows) {
        if (b && sectionRowsOrder[sIdx][i] !== undefined) {
          orderedValorizedRows.set(sectionRowsOrder[sIdx][i], b);
        }
        i++;
      }
      return orderedValorizedRows;
    });
  } else {
    orderedValorizedSectionRows = valorizedSectionRows;
  }

  /** pagination **/

  const firstRowIdx = getFirstIndex(orderedValorizedSectionRows, paginationParams.rowStart, sectionRowCountFull);
  const firstColIdx = getFirstIndex([valorizedCols], paginationParams.colStart, colCountFull);

  /** HTML generating **/

  let table = `<table id="${uuid}">`;

  /** table head **/

  table += getTableHeader(
    uuid,
    tableSupportStructures,
    valorizedCols,
    paginationParams,
    labelFormat,
    fontSize,
    firstRowIdx,
    firstColIdx,
    sortable,
    orderedCol,
    isOrderedAscending,
    filterable,
    splitHeaderCells,
    t,
    localizedTimePeriodFormatMap
  );

  /** table body **/

  table += '<tbody id="body">';

  const sectionsStarts = [0];
  sectionDimCombinations.forEach((_, idx) => {
    sectionsStarts.push(sectionsStarts[idx] + sectionRowCountFull);
  });

  const subHeaderHandled = {};
  rows.forEach(row => (subHeaderHandled[row] = -1));

  const getSectionRow = sectionIdx => {
    if (orderedValorizedSectionRows[sectionIdx].isEmpty()) {
      return "";
    }

    let sectionRow = `<tr id="s-${sectionIdx}" class="rs">`;
    let sectionLabel = "";
    const handledSectionAttributes = [];
    sections.forEach((section, idx) => {
      const datasetId = jsonStat?.extension?.datasets?.[0];
      const htmlString = dimAttributeMap?.[datasetId]?.[section]?.[sectionDimCombinations[sectionIdx][idx]]
        ? `<span id="${datasetId}:${section}:${sectionDimCombinations[sectionIdx][idx]}" class="ct ctsh">(*)</span>`
        : "";

      const value = sectionDimCombinations[sectionIdx][idx];
      let valueLabel = getFormattedDimensionValueLabel(
        jsonStat,
        null,
        section,
        value,
        labelFormat,
        undefined,
        localizedTimePeriodFormatMap
      );

      sectionLabel +=
        `<span class="${htmlString.length > 0 ? "ca" : ""}" style="display: inline-block; vertical-align: middle;">` +
        `<span class="cs-d">` +
        getFormattedDimensionLabel(jsonStat, null, section, labelFormat, t) +
        `: ` +
        `</span>` +
        valueLabel +
        htmlString +
        "</span>";

      const subSections = sections.slice(0, idx + 1);
      sectionAttributes.forEach(attr => {
        if (!handledSectionAttributes.includes(attr.id)) {
          const dims = [];
          attr.dims.forEach(dim => {
            const dimIdx = subSections.findIndex(d => d === dim);
            if (dimIdx > -1) {
              dims.push(sectionDimCombinations[sectionIdx][dimIdx]);
            }
          });
          if (dims.length > 0 && seriesAttributeMap?.[attr.id]?.[dims.join()]) {
            sectionLabel +=
              TABLE_SECTION_DIMENSIONS_SEPARATOR +
              `<span class="c-attr-as-dim" style="display: inline-block; vertical-align: middle;">` +
              `<span class="cs-d">` +
              attr.label +
              `: ` +
              `</span>` +
              getAttributeValueLabel(seriesAttributeMap[attr.id][dims.join()], labelFormat) +
              "</span>";
            handledSectionAttributes.push(attr.id);
          }
        }
      });

      sectionLabel += idx < sections.length - 1 ? TABLE_SECTION_DIMENSIONS_SEPARATOR : "";
    });

    sectionRow +=
      `<th ` +
      `class="c cf${fontSize} cs" ` +
      `colspan="${paginationParams.colPerPage * (obsAttributes.length + 1)}" ` +
      `>` +
      sectionLabel +
      `</th>`;

    sectionRow += getTableRightPadding();

    sectionRow += "</tr>";

    return sectionRow;
  };

  let isRenderedColHandled = false;

  let renderedRowCount = 0;
  for (let r = firstRowIdx; r < rowCountFull && renderedRowCount < paginationParams.rowPerPage; r++) {
    let currentSectionIdx = 0;

    if (sections && sections.length > 0) {
      const nextSectionIdx = sectionsStarts.findIndex(val => val > r);
      currentSectionIdx = nextSectionIdx > -1 ? nextSectionIdx - 1 : sectionsStarts.length - 1;

      if (r === firstRowIdx || sectionsStarts.indexOf(r) > -1) {
        table += getSectionRow(currentSectionIdx);
      }
    }

    const sectionR = r % sectionRowCountFull;
    const sortedSectionR = sectionRowsOrder[currentSectionIdx][sectionR];

    if (orderedValorizedSectionRows[currentSectionIdx].get(sectionR)) {
      let rowDimsValue = [];
      rows.forEach(row => rowDimsValue.push(getDimensionValueFromIdx(row, sortedSectionR, dimValuesMap, dimSpanMap)));
      renderedRows.push(rowDimsValue.join("+"));

      table += `<tr id="r-${r}" class="jsonstat-table__body__row">`;

      let subHeader = "";

      if (rows.length > 0) {
        for (let rr = 0; rr < rows.length; rr++) {
          const datasetId = jsonStat?.extension?.datasets?.[0];
          const dimValue = rowDimsValue[rr];
          const htmlString = dimAttributeMap?.[datasetId]?.[rows[rr]]?.[dimValue]
            ? `<span id="${datasetId}:${rows[rr]}:${dimValue}" class="ct ctsh">(*)</span>`
            : "";

          if (r > subHeaderHandled[rows[rr]]) {
            const rowSpanMax =
              splitSideCells || isOrderingRow ? 1 : dimSpanMap[rows[rr]] - (sortedSectionR % dimSpanMap[rows[rr]]);

            const rowSpan = orderedValorizedSectionRows[currentSectionIdx]
              .slice(sectionR, sectionR + rowSpanMax - 1)
              .cardinality();

            subHeaderHandled[rows[rr]] =
              r + (splitSideCells || isOrderingRow ? 0 : dimSpanMap[rows[rr]] - (r % dimSpanMap[rows[rr]]) - 1);

            if (rowSpan > 0) {
              const dimension = rows[rr];
              const value = getDimensionValueFromIdx(dimension, sortedSectionR, dimValuesMap, dimSpanMap);
              const valueLabel = getFormattedDimensionValueLabel(
                jsonStat,
                null,
                dimension,
                value,
                labelFormat,
                undefined,
                localizedTimePeriodFormatMap
              );

              const paddingLeft = 8 + (filteredRows || isOrderingRow ? 0 : (depths?.[dimension]?.[value] || 0) * 16);

              subHeader +=
                `<th ` +
                `class="c cf${fontSize} csh cl${rr} ${htmlString.length > 0 ? "ca" : ""}" ` +
                `rowspan="${rowSpan}" ` +
                `style="white-space: ${dimension === timeDim ? "nowrap" : "normal"}; padding-left: ${paddingLeft}px !important;" ` +
                `>` +
                valueLabel +
                htmlString +
                `</th>`;
            }
          }
        }
        rowAttributes.forEach(attr => {
          const dims = [];
          attr.dims.forEach(dim => dims.push(getDimensionValueFromIdx(dim, sortedSectionR, dimValuesMap, dimSpanMap)));
          subHeader +=
            `<th ` +
            `class="c cf${fontSize} csh c-attr-as-dim" ` +
            `>` +
            (dims.length > 0 && seriesAttributeMap?.[attr.id]?.[dims.join()]
              ? getAttributeValueLabel(seriesAttributeMap[attr.id][dims.join()], labelFormat)
              : "") +
            `</th>`;
        });
      } else {
        subHeader += `<th class="c cf${fontSize} csh cl0"/>`;
      }

      table += subHeader;

      const getDataCell = (c, currentSectionIdx) => {
        const dataObj = {
          ...filtersValue
        };
        rows.forEach(row => (dataObj[row] = getDimensionValueFromIdx(row, sortedSectionR, dimValuesMap, dimSpanMap)));
        cols.forEach(col => (dataObj[col] = getDimensionValueFromIdx(col, c, dimValuesMap, dimSpanMap)));
        sections.forEach((section, idx) => (dataObj[section] = sectionDimCombinations[currentSectionIdx][idx]));

        const dataIndexArr = jsonStat.id.map(dim => indexesMap[dim][dataObj[dim]]);

        const dataIdx = getDataIdxFromCoordinatesArray(dataIndexArr, jsonStat.size);
        const value = jsonStat.value[dataIdx];

        const dataCellObsAttributes = (obsAttributeMap?.[dataIdx] ?? [])
          .filter(attr => !(hierarchyOnlyAttributes || []).includes(`${attr.id}+${attr.valueId}`))
          .filter(attr => !obsAttributes.find(({id}) => id === attr.id));
        spreadAttributes.forEach(attr => {
          const dims = [];
          attr.dims.forEach(dim => dims.push(dataObj[dim]));
          if (dims.length > 0 && seriesAttributeMap?.[attr.id]?.[dims.join()]) {
            dataCellObsAttributes.push(true);
          }
        });

        const htmlString = dataCellObsAttributes.length > 0 ? `<span id="${dataIdx}" class="ct ctd">(*)</span>` : "";

        const dataCell =
          `<td id="r-${r}-c-${c}-d-${dataIdx}" class="c cf${fontSize} ${htmlString.length > 0 ? "ca" : ""}">` +
          htmlString +
          getFormattedValue(value, decimalSeparator, decimalPlaces, emptyChar, roundingStrategy) +
          "&nbsp;" +
          `</td>`;

        let attributeCells = "";
        obsAttributes.forEach(({id: attributeId}) => {
          const attribute = (obsAttributeMap[dataIdx] ?? []).find(({id}) => id === attributeId);
          if (attribute) {
            attributeCells +=
              `<td id="r-${r}-c-${c}-d-${dataIdx}-attr" class="c cf${fontSize} c-attr-as-dim">` +
              getAttributeValueLabel(attribute, labelFormat) +
              `</td>`;
          } else {
            attributeCells += `<td id="r-${r}-c-${c}-d-${dataIdx}-attr" class="c cf${fontSize}">&nbsp;</td>`;
          }
        });

        return dataCell + attributeCells;
      };

      let renderedColCount = 0;
      for (let c = firstColIdx; c < colCountFull && renderedColCount < paginationParams.colPerPage; c++) {
        if (valorizedCols.get(c)) {
          table += getDataCell(c, currentSectionIdx);
          if (!isRenderedColHandled) {
            let colDimValues = [];
            cols.forEach(col => colDimValues.push(getDimensionValueFromIdx(col, c, dimValuesMap, dimSpanMap)));
            renderedCols.push(colDimValues.join("+"));
          }

          renderedColCount++;
        }
      }
      isRenderedColHandled = true;

      table += getTableRightPadding();

      table += "</tr>";

      renderedRowCount++;
    }
  }

  table += `<tr><td class="c c-bb" colspan="${paginationParams.colPerPage * (obsAttributes.length + 1)}"></td></tr>`;

  table += "</tbody>";

  table += "</table>";

  table += '<div id="jsonstat-table__tooltip__attribute" class="ctt"></div>';

  const t1 = performance.now();

  if (onPageGenerationComplete) {
    onPageGenerationComplete({
      layout: layout,
      renderedRows: renderedRows,
      renderedCols: renderedCols,
      timings: t1 - t0
    });
  }

  return table;
};

export const getPreviewTableHtml = (uuid, tableSupportStructures, labelFormat) => {
  const {jsonStat, layout} = tableSupportStructures;

  const {rows, cols, sections} = layout;

  /** HTML generating **/

  let table = `<table id="${uuid}">`;

  /** table head **/

  table += getTableHeader(uuid, tableSupportStructures, null, null, labelFormat, true);

  /** table body **/

  table += '<tbody class="table-body">';

  if (sections && sections.length > 0) {
    table += `<tr id="s-0" class="rs">`;
    let sectionLabel = "";
    sections.forEach((section, idx) => {
      sectionLabel += `<span style="display: inline-block;"><span class="cs-d">${getFormattedDimensionLabel(
        jsonStat,
        null,
        section,
        labelFormat
      )}:</span> ${TABLE_PREVIEW_PLACEHOLDER}</span>`;
      sectionLabel += idx < sections.length - 1 ? TABLE_SECTION_DIMENSIONS_SEPARATOR : "";
    });
    table += `<th class="c cs" colspan="${(rows.length || 1) + (cols.length > 0 ? 3 : 1)}">${sectionLabel}</th>`;
    table += "</tr>";
  }
  for (let r = 0; r < (rows.length > 0 ? 3 : 1); r++) {
    table += `<tr id="r-${r}">`;
    if (rows.length > 0) {
      for (let rr = 0; rr < rows.length; rr++) {
        table += `<th class="c csh cl${rr}">xxx</th>`;
      }
    } else {
      table += `<th class="c csh cl0">&nbsp;</th>`;
    }
    for (let c = 0; c < (cols.length > 0 ? 3 : 1); c++) {
      table += `<td class="c"/>`;
    }
    table += "</tr>";
  }

  table += "</tbody>";

  table += "</table>";

  return table;
};
