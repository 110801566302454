import {Grid} from "@mui/material";
import TerritoryTools from "../territory-tools/TerritoryTools";

const TerritoryToolsButton = () => (
  <Grid item className="territory-tools-btn">
    <TerritoryTools />
  </Grid>
);

export default TerritoryToolsButton;
