import React, {Fragment, useState} from "react";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";
import {withTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import DimensionAttributeList from "../dimension-attribute-list";

const attributeIconStyle = {
  height: "18px",
  lineHeight: "18px",
  borderRadius: "3px",
  fontSize: 14
};

const attributeIconAstStyle = {
  fontFamily: "Do Hyeon"
};

const attributeIconCharStyle = {
  color: "rgb(255, 255, 255)",
  backgroundColor: "rgb(108, 108, 108)",
  marginLeft: "2px",
  padding: "0 4px"
};

const DatasetFilterAttributeIcon = ({
  t,
  dimension,
  dimensionValues,
  datasetDimAttributeMap,
  labelFormat,
  disabled,
  disabledTooltip,
  getDimValueLabel
}) => {
  const [isVisible, setVisibility] = useState(false);

  const dimValuesAttributeList = [];
  (dimensionValues || []).forEach(dimValue => {
    if (datasetDimAttributeMap?.[dimension]?.[dimValue]) {
      dimValuesAttributeList.push({
        attributes: datasetDimAttributeMap[dimension][dimValue],
        dimVal: dimValue
      });
    }
  });

  return (
    <Fragment>
      {dimValuesAttributeList.length > 0 && (
        <Tooltip
          title={
            <DimensionAttributeList
              dimension={dimension}
              dimValuesAttributeList={dimValuesAttributeList}
              labelFormat={labelFormat}
              getDimValueLabel={getDimValueLabel}
              textColor={"white"}
            />
          }
          placement="top"
          open={disabledTooltip === true ? false : undefined}
        >
          <Box
            component="span"
            sx={{
              padding: "0 2px 0 4px",
              cursor: "pointer"
            }}
            role={!disabled ? "button" : undefined}
            onClick={!disabled ? () => setVisibility(true) : undefined}
          >
            {dimValuesAttributeList.length === 1 &&
            dimValuesAttributeList[0].attributes.length === 1 &&
            dimValuesAttributeList[0].attributes[0].valueId !== dimValuesAttributeList[0].attributes[0].valueLabel &&
            dimValuesAttributeList[0].attributes[0].valueId.length <= 2 ? (
              <Box component="span" sx={[attributeIconStyle, attributeIconCharStyle]}>
                {dimValuesAttributeList[0].attributes[0].valueId}
              </Box>
            ) : (
              <Box component="span" sx={[attributeIconStyle, attributeIconAstStyle]}>
                (*)
              </Box>
            )}
          </Box>
        </Tooltip>
      )}

      <Dialog open={isVisible} onClose={() => setVisibility(false)}>
        <CustomDialogTitle onClose={() => setVisibility(false)}>
          {t("components.attributeIcons.dialog.title")}
        </CustomDialogTitle>
        <DialogContent>
          <DimensionAttributeList
            dimension={dimension}
            dimValuesAttributeList={dimValuesAttributeList}
            labelFormat={labelFormat}
            getDimValueLabel={getDimValueLabel}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={event => {
              event.stopPropagation();
              setVisibility(false);
            }}
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withTranslation()(DatasetFilterAttributeIcon);
