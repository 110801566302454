import {Reducer} from "@reduxjs/toolkit";
import _ from "lodash";
import {
  getViewerIdxFromStructure,
  getViewerIdxFromType,
  isChartLayoutCartesianByViewerIdx
} from "../../../components/data-viewer/constant";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../../../components/label-format-selector/constants";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC} from "../../../components/temporal-dim-order-selector/constants";
import {
  Criteria,
  CRITERIA_SELECTION_TYPE_PARTIAL,
  decodeLayoutAggregateCells,
  GENERATING_HTML_TIME_KEY,
  HEADER_CELLS_TYPE,
  OBSERVATION_COUNT_KEY,
  SERVER_TIMINGS_KEY,
  SIDE_CELLS_TYPE,
  ViewerMode
} from "../constants";
import {
  DATASET_SV_CHART_FILTERS_TREE_SUBMIT,
  DATASET_SV_CHART_LAYOUT_SUBMIT,
  DATASET_SV_CHART_SETTINGS_SET,
  DATASET_SV_CRITERIA_ALERT_HIDE,
  DATASET_SV_CRITERIA_HIDE,
  DATASET_SV_CRITERIA_OBS_COUNT_WARNING_HIDE,
  DATASET_SV_CRITERIA_SHOW,
  DATASET_SV_DATASET_FETCH_ENABLE,
  DATASET_SV_DATASET_FETCH_ERROR,
  DATASET_SV_DATASET_FETCH_INIT,
  DATASET_SV_DATASET_FETCH_SUCCESS,
  DATASET_SV_DOWNLOAD_SUBMIT,
  DATASET_SV_DOWNLOAD_WARNING_HIDE,
  DATASET_SV_HIERARCHY_SET,
  DATASET_SV_HTML_GENERATING_TIME_SET,
  DATASET_SV_LABEL_FORMAT_SET,
  DATASET_SV_LAYOUT_HIDE,
  DATASET_SV_LAYOUT_SHOW,
  DATASET_SV_MAP_DETAIL_LEVEL_SET,
  DATASET_SV_MAP_LAYOUT_SUBMIT,
  DATASET_SV_MAP_SETTINGS_SET,
  DATASET_SV_SDMX_QUERY_FETCH,
  DATASET_SV_SDMX_QUERY_HIDE,
  DATASET_SV_SDMX_QUERY_SHOW,
  DATASET_SV_SET_FULLSCREEN,
  DATASET_SV_STATE_BACKUP,
  DATASET_SV_STRUCTURE_CODELIST_FETCH,
  DATASET_SV_STRUCTURE_CODELIST_FULL_FETCH,
  DATASET_SV_STRUCTURE_CODELIST_FULL_HIDE,
  DATASET_SV_STRUCTURE_CRITERIA_SET,
  DATASET_SV_STRUCTURE_FETCH_ERROR,
  DATASET_SV_STRUCTURE_FETCH_INIT,
  DATASET_SV_STRUCTURE_FETCH_SUCCESS,
  DATASET_SV_TABLE_FILTERS_TREE_SUBMIT,
  DATASET_SV_TABLE_LAYOUT_SUBMIT,
  DATASET_SV_TABLE_SETTINGS_SET,
  DATASET_SV_TEMPORAL_DIM_ORDER_SET,
  DATASET_SV_UNAVAILABLE_VIEW_WARNING_HIDE,
  DATASET_SV_VARIATION_SET,
  DATASET_SV_VIEW_ERROR_HIDE,
  DATASET_SV_VIEW_TEMPLATE_HIDE,
  DATASET_SV_VIEW_TEMPLATE_SHOW,
  DATASET_SV_VIEW_TEMPLATE_SUBMIT,
  DATASET_SV_VIEWER_SET
} from "./actions";
import {
  DatasetSingleViewerState,
  getUpdatedSVStateDataOnHierarchySelect,
  getViewTemplateBackupFromState
} from "./constants";
import {
  getActionExtras,
  getAppConfig,
  getCurrentNodeConfig
} from "../../../middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../../middlewares/request/requestActions";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE,
  getCriteriaObjectFromArray
} from "../../../utils/criteria";
import {TABLE_EMPTY_CHAR_DEFAULT} from "../../../utils/formatters";
import {localizeI18nObj} from "../../../utils/i18n";
import {getDatasetStorageKey} from "../../../utils/other";
import {getMinAndMax} from "../../../utils/timePeriodAndFreq";
import {getMappedTree, getTreeFromArray} from "../../../utils/tree";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getChartSettingsFromViewTemplateLayouts,
  getMapSettingsFromViewTemplateLayouts
} from "../../../utils/viewTemplate";
import themeConfig from "../../../theme-config/config.json";

const initialState: DatasetSingleViewerState = {
  datasetUuid: null,
  dataset: null,
  isFirstFetch: false,
  datasetFetchStart: false,
  datasetFetchError: false,
  isPartialData: false,
  isEmptyData: false,
  isTooBigData: false,
  isTooLongQuery: false,
  isCriteriaVisible: false,
  isCriteriaAlertVisible: false,
  isObsCountWarningVisible: false,
  dimensions: null,
  dimensionsInfo: {},
  territoryDim: null,
  timeDim: null,
  freqDim: null,
  viewerIdx: null,
  view: null,
  template: null,
  hasViewLayout: false,
  hasTemplateLayout: false,
  hasAnnotationLayout: false,
  isLayoutVisible: false,
  tableLayout: null,
  mapLayout: null,
  chartLayoutCartesian: null,
  chartLayoutRadial: null,
  tableDefaultFiltersValue: null,
  chartDefaultFiltersValue: null,
  tableFilterTree: null,
  mapFilterTree: null,
  chartFilterTreeCartesian: null,
  chartFilterTreeRadial: null,
  timePeriodsByFreq: null,
  labelFormat: null,
  customLabelFormats: {},
  temporalDimOrder: null,
  showTrend: false,
  showCyclical: false,
  splitHeaderCells: false,
  splitSideCells: false,
  criteria: {},
  initialCriteria: {},
  decimalSeparator: null,
  roundingStrategy: null,
  decimalPlaces: null,
  tableEmptyChar: TABLE_EMPTY_CHAR_DEFAULT,
  chartSettings: {},
  mapSettings: {},
  enableCriteria: true,
  enableLayout: true,
  enableVariation: false,
  codelists: null,
  hierarchicalCodelists: null,
  codelistsLength: null,
  codelistFetchError: false,
  criteriaObsCount: null,
  timings: null,
  isFetchDatasetDisabled: true,
  isDownloadWarningVisible: false,
  isUnavailableViewWarningVisible: false,
  isViewVisible: false,
  isViewErrorVisible: false,
  viewErrorMessage: null,
  isTemplateVisible: false,
  isQueryVisible: false,
  structureQuery: null,
  dataQuery: null,
  detailLevel: null,
  isFullscreen: false,
  isTableEnabled: true,
  isMapEnabled: true,
  isChartEnabled: true,
  tableLockedDimensions: null,
  graphLockedDimensions: null,
  missingFilterValues: null,
  checkDatasetSize: false,
  lastNObservations: null,
  attributesAsDim: null
};

const datasetSVReducer: Reducer<DatasetSingleViewerState, any> = (state = initialState, action) => {
  switch (action.type) {
    case DATASET_SV_STATE_BACKUP: {
      if (state.datasetUuid !== null && state.datasetUuid === action.datasetUuid && state.dataset !== null) {
        sessionStorage.setItem(state.datasetUuid, JSON.stringify(getViewTemplateBackupFromState(state)));
      }
      return state;
    }
    case DATASET_SV_STRUCTURE_FETCH_INIT: {
      return initialState;
    }
    case DATASET_SV_STRUCTURE_FETCH_SUCCESS: {
      const {language, languages} = getActionExtras(action);

      const VIEW_KEY = "view";
      const TEMPLATE_KEY = "template";

      const {datasetUuid, structure, viewId} = action.payload;

      const dimensions = structure?.criteria || [];

      const territoryDim = structure?.territorialDimension || null;
      const timeDim = structure?.timeDimension || null;
      const freqDim = structure?.freqDimension || null;

      const hasView =
        structure[VIEW_KEY] && (!structure[VIEW_KEY]?.mode || structure[VIEW_KEY].mode === ViewerMode.SingleViewer);
      const view = hasView
        ? {
            ...structure[VIEW_KEY],
            layouts: JSON.parse(structure[VIEW_KEY].layouts)
          }
        : null;

      const hasTemplate =
        structure[TEMPLATE_KEY] &&
        (!structure[TEMPLATE_KEY]?.mode || structure[TEMPLATE_KEY].mode === ViewerMode.SingleViewer);
      const template = hasTemplate
        ? {
            ...structure[TEMPLATE_KEY],
            layouts: JSON.parse(structure[TEMPLATE_KEY].layouts)
          }
        : null;

      let sessionBackup;
      const storageItem = sessionStorage.getItem(datasetUuid);
      if (storageItem && storageItem.length > 0) {
        sessionBackup = JSON.parse(storageItem || "{}");
        sessionBackup.layouts = JSON.parse(sessionBackup?.layouts || "{}");
        sessionBackup = sessionBackup.mode === ViewerMode.SingleViewer ? sessionBackup : null;

        const storedDatasets = JSON.parse(sessionStorage.getItem("datasets") || "[]");
        if (!storedDatasets.map(getDatasetStorageKey).includes(datasetUuid)) {
          sessionStorage.removeItem(datasetUuid);
        }
      }

      let viewTemplate = sessionBackup || view || template || null;
      let viewTemplateLayouts = null;

      if (viewTemplate) {
        viewTemplate = {
          ...viewTemplate,
          decimalSeparator: hasTemplate ? template.decimalSeparator : null,
          roundingStrategy: hasTemplate ? template.roundingStrategy : null,
          decimalNumber: hasTemplate ? template.decimalNumber : null,
          enableCriteria: hasTemplate ? template.enableCriteria : true,
          enableLayout: hasTemplate ? template.enableLayout : true,
          enableVariation: hasTemplate ? template.enableVariation : false
        };
        viewTemplateLayouts = {
          ...(template?.layouts || {}),
          ...(view?.layouts || {}),
          ...(sessionBackup?.layouts || {})
        };
      }

      const structureCriteria = (structure.filters || []).length > 0 ? structure.filters : null;

      const criteria = getCriteriaObjectFromArray(viewTemplate?.criteria || structureCriteria);

      let isCriteriaVisible = !viewTemplate && _.isEmpty(criteria);

      // check whether each dimension with associated HCL has a hierarchy selected
      const dimensionsWithHcl = dimensions.filter(dimension => (dimension.hierarchies || []).length > 0) || [];
      dimensionsWithHcl.forEach(dim => {
        if (!criteria?.[dim.id]?.hierarchyId) {
          // there is no selected hierarchy
          if (dim.hierarchyId) {
            // there is a default value, this value will be selected
            criteria[dim.id] = {
              id: dim.id,
              type: CRITERIA_FILTER_TYPE_CODES,
              filterValues: [],
              period: undefined,
              from: undefined,
              to: undefined,
              hierarchyId: dim.hierarchyId
            };
          } else {
            // there is no default value, the user must choose a value
            isCriteriaVisible = true;
          }
        }
      });

      const disabledViewers = (structure?.disabledViewers || []).map((v: string) => v.toLowerCase());

      let viewerIdx = null;
      let isTableEnabled = !disabledViewers.includes("table");
      let isMapEnabled = !disabledViewers.includes("map");
      let isChartEnabled = !disabledViewers.includes("graph");

      if (viewTemplate) {
        viewerIdx = getViewerIdxFromType(viewTemplate.defaultView);
        if (viewerIdx !== null && viewerIdx === 0) {
          isTableEnabled = true;
        } else if (viewerIdx !== null && viewerIdx === 1) {
          isMapEnabled = true;
        } else if (viewerIdx !== null && viewerIdx >= 2) {
          isChartEnabled = true;
        }
      } else if (structure.defaultView) {
        viewerIdx = getViewerIdxFromStructure(structure.defaultView);
        if (viewerIdx !== null && viewerIdx === 0) {
          isTableEnabled = true;
        } else if (viewerIdx !== null && viewerIdx === 1) {
          isMapEnabled = true;
        } else if (viewerIdx !== null && viewerIdx >= 2) {
          isChartEnabled = true;
        }
      } else if (isTableEnabled) {
        viewerIdx = 0;
      } else if (isMapEnabled) {
        viewerIdx = 1;
      } else if (isChartEnabled) {
        viewerIdx = 2;
      }

      const tableLayout =
        viewTemplateLayouts && viewTemplateLayouts?.tableLayout
          ? viewTemplateLayouts.tableLayout
          : structure?.layoutTable
            ? {
                cols: [],
                rows: [],
                sections: [],
                filters: [],
                ...structure.layoutTable,
                filtersValue: undefined
              }
            : null;
      const mapLayout = !territoryDim
        ? null
        : viewTemplateLayouts && viewTemplateLayouts?.mapLayout
          ? viewTemplateLayouts.mapLayout
          : structure?.layoutMap
            ? {
                filters: [],
                ...structure.layoutMap,
                territoryDim: territoryDim,
                filtersValue: undefined
              }
            : null;
      const chartLayoutCartesian =
        viewTemplateLayouts && viewTemplateLayouts?.chartLayoutCartesian
          ? viewTemplateLayouts.chartLayoutCartesian
          : viewTemplateLayouts && viewTemplateLayouts?.chartLayout
            ? viewTemplateLayouts.chartLayout
            : structure?.layoutChartCartesian
              ? {
                  primaryDim: [],
                  primaryDimValues: [],
                  secondaryDim: [],
                  secondaryDimValues: [],
                  filters: [],
                  ...structure.layoutChartCartesian,
                  filtersValue: undefined
                }
              : null;
      const chartLayoutRadial =
        viewTemplateLayouts && viewTemplateLayouts?.chartLayoutRadial
          ? viewTemplateLayouts.chartLayoutRadial
          : viewTemplateLayouts && viewTemplateLayouts?.chartLayout
            ? viewTemplateLayouts.chartLayout
            : structure?.layoutChartRadial
              ? {
                  primaryDim: [],
                  secondaryDim: [],
                  primaryDimValues: [],
                  secondaryDimValues: [],
                  filters: [],
                  ...structure.layoutChartRadial,
                  filtersValue: undefined
                }
              : null;

      const chartLockedDimensionsCartesian =
        hasTemplate &&
        template.layouts?.lockChartDimensionsCartesian &&
        (template.layouts?.chartLockedDimensionsCartesian || []).length > 0
          ? template.layouts?.chartLockedDimensionsCartesian
          : structure?.chartLockedDimensionsCartesian || [];
      const chartLockedDimensionsRadial =
        hasTemplate &&
        template.layouts?.lockChartDimensionsRadial &&
        (template.layouts?.chartLockedDimensionsRadial || []).length > 0
          ? template.layouts?.chartLockedDimensionsRadial
          : structure?.chartLockedDimensionsRadial || [];

      const customLabelFormats = [...(structure?.criteria ?? []), ...(structure?.attributes ?? [])].reduce(
        (acc: {[key: string]: string}, item: any) => {
          if ((item?.extra?.DataStructureRef ?? "").length === 0) {
            acc[item.id] = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME;
          }
          return acc;
        },
        {
          [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
        }
      );

      return {
        ...state,
        datasetUuid: datasetUuid,
        isFirstFetch: true,
        hasViewLayout: hasView,
        hasTemplateLayout: hasTemplate,
        hasAnnotationLayout:
          !!structure.layoutTable ||
          !!structure.layoutMap ||
          !!structure.layoutChartCartesian ||
          !!structure.layoutChartRadial,
        view: view,
        template: template,
        dimensions: dimensions,
        territoryDim: territoryDim,
        timeDim: timeDim,
        freqDim: freqDim,
        detailLevel: !viewTemplateLayouts
          ? null
          : viewTemplateLayouts.detailLevel !== null && viewTemplateLayouts.detailLevel !== undefined
            ? viewTemplateLayouts.detailLevel
            : viewTemplateLayouts.mapDetailLevel !== null && viewTemplateLayouts.mapDetailLevel !== undefined
              ? viewTemplateLayouts.mapDetailLevel
              : null,
        codelistsLength: state.codelistsLength ? state.codelistsLength : dimensions.map(() => null),
        isCriteriaVisible: isCriteriaVisible,
        viewerIdx: viewerIdx,
        isUnavailableViewWarningVisible: viewId !== null && !structure?.[VIEW_KEY],
        tableLayout: tableLayout,
        mapLayout: mapLayout,
        chartLayoutCartesian: chartLayoutCartesian,
        chartLayoutRadial: chartLayoutRadial,
        tableDefaultFiltersValue: structure?.layoutTable?.filtersValue || null,
        chartDefaultFiltersValue: structure?.layoutChart?.filtersValue || null,
        labelFormat: viewTemplateLayouts?.labelFormat || LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
        customLabelFormats: customLabelFormats,
        temporalDimOrder:
          viewTemplateLayouts?.temporalDimOrder || structure.temporalDimOrder || TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
        showTrend: viewTemplateLayouts?.showTrend || false,
        showCyclical: viewTemplateLayouts?.showCyclical || false,
        splitHeaderCells: themeConfig.enableOptimization
          ? false
          : viewTemplateLayouts?.splitHeaderCells !== undefined && viewTemplateLayouts?.splitHeaderCells !== null
            ? viewTemplateLayouts?.splitHeaderCells
            : structure.layoutAggregateCells !== undefined && structure.layoutAggregateCells !== null
              ? decodeLayoutAggregateCells(structure.layoutAggregateCells, HEADER_CELLS_TYPE)
              : getAppConfig(action).tableDefaultSettings?.layoutAggregateCells !== undefined &&
                  getAppConfig(action).tableDefaultSettings?.layoutAggregateCells !== null
                ? decodeLayoutAggregateCells(
                    getAppConfig(action).tableDefaultSettings?.layoutAggregateCells,
                    HEADER_CELLS_TYPE
                  )
                : false,
        splitSideCells: themeConfig.enableOptimization
          ? false
          : viewTemplateLayouts?.splitSideCells !== undefined && viewTemplateLayouts?.splitSideCells !== null
            ? viewTemplateLayouts?.splitSideCells
            : structure.layoutAggregateCells !== undefined && structure.layoutAggregateCells !== null
              ? decodeLayoutAggregateCells(structure.layoutAggregateCells, SIDE_CELLS_TYPE)
              : getAppConfig(action).tableDefaultSettings?.layoutAggregateCells !== undefined &&
                  getAppConfig(action).tableDefaultSettings?.layoutAggregateCells !== null
                ? decodeLayoutAggregateCells(
                    getAppConfig(action).tableDefaultSettings?.layoutAggregateCells,
                    SIDE_CELLS_TYPE
                  )
                : false,
        criteria: criteria,
        initialCriteria: criteria,
        decimalSeparator:
          Object.keys(viewTemplate?.decimalSeparator || {}).length > 0
            ? localizeI18nObj(viewTemplate.decimalSeparator, language, languages)
            : structure.decimalSeparator,
        roundingStrategy:
          viewTemplate?.roundingStrategy !== undefined ? viewTemplate?.roundingStrategy : structure.roundingStrategy,
        decimalPlaces:
          viewTemplate?.decimalNumber !== null &&
          viewTemplate?.decimalNumber !== undefined &&
          viewTemplate?.decimalNumber !== -1
            ? viewTemplate?.decimalNumber
            : structure.decimalPlaces,
        tableEmptyChar:
          viewTemplateLayouts?.tableEmptyChar !== null && viewTemplateLayouts?.tableEmptyChar !== undefined
            ? viewTemplateLayouts?.tableEmptyChar
            : structure.emptyCellPlaceHolder,
        chartSettings: {
          ...getAppConfig(action).chartDefaultSettings,
          ...getChartSettingsFromViewTemplateLayouts(viewTemplateLayouts)
        },
        mapSettings: {
          ...getAppConfig(action).mapDefaultSettings,
          ...getMapSettingsFromViewTemplateLayouts(viewTemplateLayouts)
        },
        enableCriteria: viewTemplate?.enableCriteria !== false && dimensions.length > 0,
        enableLayout: viewTemplate?.enableLayout !== false && dimensions.length > 0,
        enableVariation: viewTemplate?.enableVariation === true,
        isFetchDatasetDisabled: isCriteriaVisible,
        isTableEnabled: isTableEnabled,
        isMapEnabled: isMapEnabled,
        isChartEnabled: isChartEnabled,
        tableLockedDimensions:
          hasTemplate &&
          template.layouts?.lockTableDimensions &&
          (template.layouts?.tableLockedDimensions || []).length > 0
            ? template.layouts?.tableLockedDimensions
            : structure?.tableLockedDimensions || [],
        chartLockedDimensionsCartesian: chartLockedDimensionsCartesian,
        chartLockedDimensionsRadial: chartLockedDimensionsRadial,
        checkDatasetSize: structure.checkDatasetSize || false,
        lastNObservations: structure.lastNObservations,
        attributesAsDim: structure.attributesAsDim ?? []
      };
    }
    case DATASET_SV_STRUCTURE_FETCH_ERROR: {
      return {
        ...initialState,
        enableCriteria: false,
        enableLayout: false
      };
    }
    case DATASET_SV_DATASET_FETCH_ENABLE: {
      const tooMuchObs =
        state.criteriaObsCount !== null &&
        action.payload.maxObservations !== undefined &&
        state.criteriaObsCount > action.payload.maxObservations;

      return {
        ...state,
        isFetchDatasetDisabled: tooMuchObs,
        criteriaObsCount: tooMuchObs ? state.criteriaObsCount : null,
        isObsCountWarningVisible: tooMuchObs
      };
    }
    case DATASET_SV_DATASET_FETCH_INIT: {
      return {
        ...state,
        isFetchDatasetDisabled: true,
        datasetFetchStart: true,
        datasetFetchError: false
      };
    }
    case DATASET_SV_DATASET_FETCH_SUCCESS: {
      if (action.payload.worker) {
        action.payload.worker.terminate();
      }

      const isResponseValid = !action.payload.isResponseNotValid;

      const dataset = isResponseValid ? action.payload.response : state.dataset;

      const tableLayout = isResponseValid ? action.payload.tableLayout : state.tableLayout;
      const mapLayout = isResponseValid ? action.payload.mapLayout : state.mapLayout;
      const chartLayoutCartesian = isResponseValid ? action.payload.chartLayoutCartesian : state.chartLayoutCartesian;
      const chartLayoutRadial = isResponseValid ? action.payload.chartLayoutRadial : state.chartLayoutRadial;

      const dimensionsInfo: {[key: string]: any} = {};
      (dataset?.id || []).forEach((dim: string) => {
        dimensionsInfo[dim] = {
          id: dim,
          label: dataset.dimension[dim].label,
          size: dataset.size[dataset.id.indexOf(dim)]
        };
      });

      return {
        ...state,
        dataset: dataset,

        dimensionsInfo: dimensionsInfo,

        isFirstFetch: false,
        datasetFetchStart: false,
        datasetFetchError: false,

        isEmptyData: action.payload.isEmptyData,
        isPartialData: action.payload.isPartialData,

        enableCriteria: state.enableCriteria,
        isCriteriaVisible: state.isCriteriaVisible && !isResponseValid,
        isCriteriaAlertVisible: !isResponseValid,

        tableLayout: tableLayout,
        mapLayout: mapLayout,

        chartLayoutCartesian: chartLayoutCartesian,
        chartLayoutRadial: chartLayoutRadial,

        tableFilterTree: isResponseValid ? action.payload.tableFilterTree : state.tableFilterTree,
        mapFilterTree: isResponseValid ? action.payload.mapFilterTree : state.mapFilterTree,

        chartFilterTreeCartesian: isResponseValid
          ? action.payload.chartFilterTreeCartesian
          : state.chartFilterTreeCartesian,
        chartFilterTreeRadial: isResponseValid ? action.payload.chartFilterTreeRadial : state.chartFilterTreeRadial,

        timePeriodsByFreq: isResponseValid ? action.payload.timePeriodsByFreq : state.timePeriodsByFreq,

        initialCriteria: isResponseValid ? state.criteria : state.initialCriteria,

        codelists: isResponseValid ? null : state.codelists,
        hierarchicalCodelists: isResponseValid ? null : state.hierarchicalCodelists,

        timings: {
          [OBSERVATION_COUNT_KEY]: action.payload.response?.value
            ? Object.keys(action.payload.response.value).length
            : null,
          [SERVER_TIMINGS_KEY]: action.payload.responseHeaders.backendtimers
            ? JSON.parse(action.payload.responseHeaders.backendtimers)
            : null
        },

        structureQuery: null,
        dataQuery: null
      };
    }
    case DATASET_SV_DATASET_FETCH_ERROR: {
      const isPayloadTooLarge = action.payload.statusCode === 413;
      const isTooLongQuery = action.payload.statusCode === 414;

      return {
        ...state,
        dataset: null,
        dimensionsInfo: {},
        datasetFetchStart: false,
        datasetFetchError: true,
        isPartialData: false,
        isEmptyData: false,
        isTooBigData: isPayloadTooLarge,
        isTooLongQuery: isTooLongQuery,
        isCriteriaAlertVisible: isPayloadTooLarge || isTooLongQuery
      };
    }
    case DATASET_SV_CRITERIA_SHOW: {
      return {
        ...state,
        isCriteriaVisible: true
      };
    }
    case DATASET_SV_CRITERIA_HIDE: {
      return {
        ...state,
        isCriteriaVisible: false,
        criteria: state.initialCriteria,
        codelists: null,
        hierarchicalCodelists: null
      };
    }
    case DATASET_SV_MAP_DETAIL_LEVEL_SET: {
      return {
        ...state,
        detailLevel: action.detailLevel
      };
    }
    case DATASET_SV_CRITERIA_ALERT_HIDE: {
      return {
        ...state,
        isCriteriaAlertVisible: false
      };
    }
    case DATASET_SV_CRITERIA_OBS_COUNT_WARNING_HIDE: {
      return {
        ...state,
        isObsCountWarningVisible: false
      };
    }
    case DATASET_SV_STRUCTURE_CRITERIA_SET: {
      const newCodelistsLength = _.cloneDeep(state.codelistsLength);
      let hasModifiedLength = false;
      if (action.clearHcl) {
        const dimensions = state.dimensions;
        (dimensions || []).forEach((dimension, idx) => {
          const dimensionHasHcl = (dimension.hierarchies || []).length > 0;
          if (dimensionHasHcl) {
            const dimensionCriteria = action.criteria?.[dimension.id];
            // dimensionCriteria &&
            // (dimensionCriteria.hierarchyId || "").length > 0 &&
            if (!dimensionCriteria && (newCodelistsLength[idx] || 0) !== 0) {
              newCodelistsLength[idx] = 0;
              hasModifiedLength = true;
            }
          }
        });
      }
      return {
        ...state,
        criteria: action.criteria,
        criteriaObsCount: null,
        codelistsLength: hasModifiedLength ? newCodelistsLength : state.codelistsLength
      };
    }
    case DATASET_SV_VIEWER_SET: {
      return {
        ...state,
        viewerIdx: action.viewerIdx,
        isTableVisible: action.viewerIdx === 0,
        isMapVisible: action.viewerIdx === 1,
        isChartVisible: action.viewerIdx >= 2
      };
    }
    case DATASET_SV_LAYOUT_SHOW: {
      return {
        ...state,
        isLayoutVisible: true
      };
    }
    case DATASET_SV_LAYOUT_HIDE: {
      return {
        ...state,
        isLayoutVisible: false
      };
    }
    case DATASET_SV_TABLE_LAYOUT_SUBMIT: {
      return {
        ...state,
        tableLayout: action.layout
      };
    }
    case DATASET_SV_TABLE_FILTERS_TREE_SUBMIT: {
      return {
        ...state,
        tableFilterTree: action.filterTree
      };
    }
    case DATASET_SV_MAP_LAYOUT_SUBMIT: {
      return {
        ...state,
        mapLayout: action.layout
      };
    }
    case DATASET_SV_CHART_LAYOUT_SUBMIT: {
      return isChartLayoutCartesianByViewerIdx(state.viewerIdx)
        ? {
            ...state,
            chartLayoutCartesian: action.layout
          }
        : {
            ...state,
            chartLayoutRadial: action.layout
          };
    }
    case DATASET_SV_CHART_FILTERS_TREE_SUBMIT: {
      return isChartLayoutCartesianByViewerIdx(state.viewerIdx)
        ? {
            ...state,
            chartFilterTreeCartesian: action.filterTree
          }
        : {
            ...state,
            chartFilterTreeRadial: action.filterTree
          };
    }
    case DATASET_SV_LABEL_FORMAT_SET: {
      return {
        ...state,
        labelFormat: action.labelFormat
      };
    }
    case DATASET_SV_VARIATION_SET: {
      return {
        ...state,
        showTrend: action.variation?.showTrend || false,
        showCyclical: action.variation?.showCyclical || false
      };
    }
    case DATASET_SV_TEMPORAL_DIM_ORDER_SET: {
      return {
        ...state,
        temporalDimOrder: action.temporalDimOrder
      };
    }
    case DATASET_SV_TABLE_SETTINGS_SET: {
      return {
        ...state,
        temporalDimOrder: action.tableSettings.temporalDimOrder,
        labelFormat: action.tableSettings.labelFormat,
        splitHeaderCells: action.tableSettings.splitHeaderCells,
        splitSideCells: action.tableSettings.splitSideCells
      };
    }
    case DATASET_SV_CHART_SETTINGS_SET: {
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          ...action.chartSettings
        }
      };
    }
    case DATASET_SV_MAP_SETTINGS_SET: {
      return {
        ...state,
        mapSettings: {
          ...state.mapSettings,
          ...action.mapSettings
        }
      };
    }
    case DATASET_SV_DOWNLOAD_WARNING_HIDE: {
      return {
        ...state,
        isDownloadWarningVisible: false
      };
    }
    case DATASET_SV_UNAVAILABLE_VIEW_WARNING_HIDE: {
      return {
        ...state,
        isUnavailableViewWarningVisible: false
      };
    }
    case DATASET_SV_HTML_GENERATING_TIME_SET: {
      return {
        ...state,
        timings: {
          ...state.timings,
          [GENERATING_HTML_TIME_KEY]: action.time
        }
      };
    }
    case DATASET_SV_VIEW_TEMPLATE_SHOW: {
      return {
        ...state,
        isViewVisible: action.isView ? true : state.isViewVisible,
        isTemplateVisible: !action.isView ? true : state.isTemplateVisible
      };
    }
    case DATASET_SV_VIEW_TEMPLATE_HIDE: {
      return {
        ...state,
        isViewVisible: false,
        isTemplateVisible: false
      };
    }
    case DATASET_SV_VIEW_ERROR_HIDE: {
      return {
        ...state,
        isViewErrorVisible: false,
        viewErrorMessage: null
      };
    }
    case DATASET_SV_SDMX_QUERY_SHOW: {
      return {
        ...state,
        isQueryVisible: true
      };
    }
    case DATASET_SV_SDMX_QUERY_HIDE: {
      return {
        ...state,
        isQueryVisible: false
      };
    }
    case DATASET_SV_STRUCTURE_CODELIST_FULL_HIDE: {
      return {
        ...state,
        missingFilterValues: null
      };
    }
    case DATASET_SV_SET_FULLSCREEN: {
      return {
        ...state,
        isFullscreen: action.isFullscreen
      };
    }
    case DATASET_SV_HIERARCHY_SET: {
      const hierarchyValues = state.hierarchicalCodelists[action.dimensionId];
      const updatedData =
        getUpdatedSVStateDataOnHierarchySelect(
          state,
          action.dimensionId,
          action.hierarchyId || "",
          hierarchyValues,
          action.tabId,
          action.selectedKeys
        ) || {};
      return {
        ...state,
        ...updatedData
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case DATASET_SV_STRUCTURE_CODELIST_FETCH: {
          return {
            ...state,
            criteriaObsCount: null,
            codelistFetchError: false
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DATASET_SV_STRUCTURE_CODELIST_FETCH: {
          if ((action.payload.response?.criteria ?? []).length === 0) {
            return {
              ...state,
              isCriteriaAlertVisible: true,
              codelistFetchError: true
            };
          }

          const dimensions = state.dimensions;
          const timeDim = state.timeDim;
          const freqDim = state.freqDim;
          const codelistsLength = state.codelistsLength;
          const criteria = state.criteria;

          const newCodelists: {[key: string]: any[]} = _.cloneDeep(state.codelists) || {};
          const newCodelistsLength = codelistsLength ? [...codelistsLength] : (dimensions || []).map(() => null);
          const newCriteria: {[key: string]: Criteria} = _.cloneDeep(criteria) || {};
          const newHierarchicalCodelists: {[key: string]: any} = _.cloneDeep(state.hierarchicalCodelists) || {};

          (dimensions || []).forEach((dimension, idx) => {
            const dimensionValues = action.payload.response.criteria.find(({id}: any) => id === dimension.id);
            if (dimensionValues) {
              const dimensionHasHcl = (dimension.hierarchies || []).length > 0;
              if (dimensionHasHcl) {
                const hierarchiesValues = _.cloneDeep(dimensionValues.hierarchiesValues || {});
                const hierarchies = Object.keys(hierarchiesValues) || [];
                const hierarchyId = dimension.hierarchyId || "";
                const dimensionCriteria = criteria?.[dimension.id];
                const dimensionCriteriaHierarchyId = dimensionCriteria?.hierarchyId || "";
                const hasToSelectHierarchy =
                  dimensionCriteriaHierarchyId !== "" || hierarchyId !== "" || hierarchies.length === 1;
                hierarchies.forEach(hierId => {
                  let hierarchyValues = hierarchiesValues[hierId];
                  hierarchyValues = getMappedTree(hierarchyValues, "hierarchyCodes", (node: any) => ({
                    ...node,
                    label: `[${node.id}] ${node.name}`
                  }));
                  hierarchiesValues[hierId] = hierarchyValues;
                });
                let selectedHierarchy = "";
                if (hasToSelectHierarchy) {
                  selectedHierarchy =
                    dimensionCriteriaHierarchyId !== ""
                      ? dimensionCriteriaHierarchyId
                      : hierarchyId !== ""
                        ? hierarchyId
                        : hierarchies[0];
                }
                const updatedData = getUpdatedSVStateDataOnHierarchySelect(
                  state,
                  dimension.id,
                  selectedHierarchy,
                  hierarchiesValues,
                  idx,
                  dimensionCriteria?.filterValues || []
                );
                newCriteria[dimension.id] = updatedData.criteria[dimension.id];
                newCodelists[dimension.id] = updatedData.codelists[dimension.id];
                newCodelistsLength[idx] = updatedData.codelistsLength[idx];
                newHierarchicalCodelists[dimension.id] = hierarchiesValues;
              } else {
                let length = 0;
                const values = (dimensionValues.values || []).map((code: any) => {
                  if (code.isSelectable) {
                    length++;
                  }
                  return {
                    ...code,
                    label: `[${code.id}] ${code.name}`
                  };
                });

                newCodelists[dimension.id] = getTreeFromArray(values, "parentId", "children");

                if (!timeDim || dimension.id !== timeDim) {
                  newCodelistsLength[idx] = length;

                  if (values.length === 1) {
                    newCriteria[dimension.id] = {
                      id: dimension.id,
                      type: CRITERIA_FILTER_TYPE_CODES,
                      filterValues: [values[0].id],
                      period: undefined,
                      from: undefined,
                      to: undefined
                    };
                  }
                } else if (!newCriteria[timeDim]) {
                  if (
                    freqDim &&
                    isValidIntegerInInclusiveRange(action.payload.extra.defaultLastNPeriods, 1) &&
                    action.payload.extra.type === CRITERIA_SELECTION_TYPE_PARTIAL
                  ) {
                    newCriteria[timeDim] = {
                      id: timeDim,
                      type: CRITERIA_FILTER_TYPE_PERIODS,
                      period: action.payload.extra.defaultLastNPeriods,
                      filterValues: undefined,
                      from: undefined,
                      to: undefined
                    };
                  } else {
                    const {min, max} = getMinAndMax(values, action.payload.extra.freq, getCurrentNodeConfig(action));

                    newCriteria[timeDim] = {
                      id: timeDim,
                      type: CRITERIA_FILTER_TYPE_RANGE,
                      from: min,
                      to: max,
                      filterValues: undefined,
                      period: undefined
                    };
                  }
                }
              }
            } else {
              newCodelists[dimension.id] = [];
            }
          });

          return {
            ...state,
            criteria: newCriteria,
            codelists: newCodelists,
            codelistsLength: newCodelistsLength,
            hierarchicalCodelists: newHierarchicalCodelists
          };
        }
        case DATASET_SV_VIEW_TEMPLATE_SUBMIT: {
          return action.payload.extra.isView
            ? {
                ...state,
                isViewVisible: false,
                isTemplateVisible: false
              }
            : {
                ...initialState
              };
        }
        case DATASET_SV_SDMX_QUERY_FETCH: {
          return {
            ...state,
            structureQuery: action.payload.response?.structureUrl || null,
            dataQuery: action.payload.response?.dataflowUrl || null
          };
        }
        case DATASET_SV_STRUCTURE_CODELIST_FULL_FETCH: {
          const codelist = (action.payload.response.criteria[0] || {}).values || [];
          return {
            ...state,
            missingFilterValues: codelist.filter((code: any) =>
              action.payload.extra.missingFilterValueIds.includes(code.id)
            )
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DATASET_SV_STRUCTURE_CODELIST_FETCH: {
          const isTooLongQuery = action.payload.statusCode === 414;

          return {
            ...state,
            isTooLongQuery: isTooLongQuery,
            isCriteriaAlertVisible: isTooLongQuery,
            codelistFetchError: true
          };
        }
        case DATASET_SV_DOWNLOAD_SUBMIT: {
          return {
            ...state,
            isDownloadWarningVisible: action.payload.statusCode === 406
          };
        }
        case DATASET_SV_VIEW_TEMPLATE_SUBMIT: {
          return {
            ...state,
            isViewErrorVisible: !!(
              action.payload.extra.isView &&
              action.payload.statusCode === 409 &&
              action.payload.response
            ),
            viewErrorMessage: action.payload.response
          };
        }
        case DATASET_SV_SDMX_QUERY_FETCH: {
          return {
            ...state,
            isQueryVisible: false,
            structureQuery: null,
            dataQuery: null
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default datasetSVReducer;
