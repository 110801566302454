import React from "react";
import {Box, CardHeader, Paper} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {withTranslation} from "react-i18next";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../label-format-selector/constants";

const paperStyle = {
  padding: "16px"
};

const cardHeaderStyle = {
  padding: "0 0 8px 0"
};

function MapGeneralSettings(props) {
  const {t, commonSettings, setCommonSettings} = props;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      <Paper variant="outlined" sx={paperStyle}>
        <CardHeader subheader={t("components.chartSettings.header.common")} sx={cardHeaderStyle} />
        <FormControl fullWidth sx={{marginBottom: theme => theme.spacing(3)}}>
          <TextField
            select
            fullWidth
            label={t("components.labelFormatSelector.labelFormat.title")}
            value={commonSettings.labelFormat || ""}
            onChange={ev => setCommonSettings({...commonSettings, labelFormat: ev.target.value})}
            variant="outlined"
          >
            <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}>
              {t("components.labelFormatSelector.labelFormat.values.name")}
            </MenuItem>
            <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}>
              {t("components.labelFormatSelector.labelFormat.values.id")}
            </MenuItem>
            <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}>
              {t("components.labelFormatSelector.labelFormat.values.both")}
            </MenuItem>
          </TextField>
        </FormControl>
      </Paper>
    </Box>
  );
}

export default withTranslation()(MapGeneralSettings);
