import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../../hocs/call";
import CustomEmpty from "../../custom-empty";
import MultiViewerPlusHeader from "./Header";
import MultiViewerPlusSidebar from "./Sidebar";
import MultiViewerPlusViewer from "./Viewer";
import {
  fetchDatasetMVPDatasetSynthesisAndVariabilityMeasures,
  fetchDatasetMVPTableColCount,
  fetchDatasetMVPTableCols,
  fetchDatasetMVPTableRows,
  handleDatasetMVPDetailLevels,
  hideDatasetMVPDownloadWarning,
  hideDatasetMVPUnavailableViewWarning
} from "../../../state/dataset/multi-viewer-plus/actions";
import {getNode} from "../../../utils/tree";

const $ = window.jQuery;

const mapStateToProps = ({config, appConfig, catalog, detailLevel, dataset}) => ({
  geoglossaryBaseUrl: config.externalServices?.geoglossary,
  optimizedDataRetrieverBaseUrl: config.externalServices?.optimizedDataRetriever,
  maxTableColCount: appConfig.tableConfig.maxColCount,
  datasetMap: (catalog.uncategorizedDatasets || []).reduce(
    (acc, v) => ({...acc, [v.identifier]: v}),
    catalog.datasetMap || {}
  ),
  catalogDetailLevelTree: catalog?.detailLevelTree,
  nodeDetailLevel: detailLevel.detailLevel,
  datasetUuid: dataset.multiViewerPlus.datasetUuid,
  notCompatibleDataset: dataset.multiViewerPlus.notCompatibleDataset,
  cols: dataset.multiViewerPlus.cols,
  colCount: dataset.multiViewerPlus.colCount,
  rows: dataset.multiViewerPlus.rows,
  dimensions: dataset.multiViewerPlus.dimensions,
  timeDim: dataset.multiViewerPlus.timeDim,
  territoryDim: dataset.multiViewerPlus.territoryDim,
  tableFilters: dataset.multiViewerPlus.tableFilters,
  mapFilters: dataset.multiViewerPlus.mapFilters,
  tableChartDatasetFetchEnabled: dataset.multiViewerPlus.tableChartDatasetFetchEnabled,
  mapDatasetFetchEnabled: dataset.multiViewerPlus.mapDatasetFetchEnabled,
  areCriteriaApplied: dataset.multiViewerPlus.areCriteriaApplied,
  criteria: dataset.multiViewerPlus.criteria,
  isDownloadWarningVisible: dataset.multiViewerPlus.isDownloadWarningVisible,
  isUnavailableViewWarningVisible: dataset.multiViewerPlus.isUnavailableViewWarningVisible,
  datasetDetailLevelTree: dataset.multiViewerPlus.detailLevelTree,
  datasetDetailLevel: dataset.multiViewerPlus.detailLevel,
  allTerritoriesSelected: dataset.multiViewerPlus.allTerritoriesSelected,
  synthesisAndVariabilityMeasures: dataset.multiViewerPlus.synthesisAndVariabilityMeasures,
  showArithmeticMean: dataset.multiViewerPlus.showArithmeticMean,
  showStandardDeviation: dataset.multiViewerPlus.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewerPlus.showCoefficientOfVariation
});

const mapDispatchToProps = dispatch => ({
  handleDetailLevels: ({
    nodeId,
    datasetId,
    catalogDetailLevelTree,
    datasetDetailLevels,
    datasetDetailLevel,
    nodeDetailLevel,
    baseUrl
  }) =>
    dispatch(
      handleDatasetMVPDetailLevels(
        nodeId,
        datasetId,
        catalogDetailLevelTree,
        datasetDetailLevels,
        datasetDetailLevel,
        nodeDetailLevel,
        baseUrl
      )
    ),
  fetchTableColCount: (
    nodeId,
    datasetId,
    criteria,
    territoryDim,
    detailLevelNameIdentifier,
    allTerritoriesSelected,
    dimensions,
    maxTableColCount,
    baseUrl
  ) =>
    dispatch(
      fetchDatasetMVPTableColCount(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        dimensions,
        maxTableColCount,
        baseUrl
      )
    ),
  fetchTableCols: (
    nodeId,
    datasetId,
    criteria,
    territoryDim,
    detailLevelNameIdentifier,
    allTerritoriesSelected,
    dimensions,
    baseUrl
  ) =>
    dispatch(
      fetchDatasetMVPTableCols(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        dimensions,
        baseUrl
      )
    ),
  fetchTableRows: (
    nodeId,
    datasetId,
    criteria,
    territoryDim,
    detailLevelNameIdentifier,
    allTerritoriesSelected,
    tableFilters,
    mapFilters,
    dimensions,
    baseUrl
  ) =>
    dispatch(
      fetchDatasetMVPTableRows(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        tableFilters,
        mapFilters,
        dimensions,
        baseUrl
      )
    ),
  fetchDatasetSAVMeasures: (
    nodeId,
    datasetId,
    criteria,
    territoryDim,
    detailLevelNameIdentifier,
    allTerritoriesSelected,
    tableFilters,
    mapFilters,
    dimensions,
    baseUrl
  ) =>
    dispatch(
      fetchDatasetMVPDatasetSynthesisAndVariabilityMeasures(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        tableFilters,
        mapFilters,
        dimensions,
        baseUrl
      )
    ),
  onDownloadWarningHide: () => dispatch(hideDatasetMVPDownloadWarning()),
  onUnavailableViewHide: () => dispatch(hideDatasetMVPUnavailableViewWarning())
});

const handleStyle = () => {
  const headerHeight = document.getElementById("data-viewer__header")?.offsetHeight || 0;
  $("#data-viewer__sidebar-viewer-container").height(`calc(100% - ${headerHeight}px)`);

  const sidebarWidth = document.getElementById("data-viewer__sidebar")?.offsetWidth || 0;
  $("#data-viewer__viewer").width(`calc(100% - ${sidebarWidth}px)`);
};

function MultiViewerPlus(props) {
  const {
    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    nodeExtras,

    geoglossaryBaseUrl,
    optimizedDataRetrieverBaseUrl,
    maxTableColCount,
    datasetMap,
    catalogDetailLevelTree,
    nodeDetailLevel,
    datasetUuid,
    notCompatibleDataset,
    colCount,
    cols,
    rows,
    dimensions,
    timeDim,
    territoryDim,
    tableFilters,
    mapFilters,
    tableChartDatasetFetchEnabled,
    mapDatasetFetchEnabled,
    criteria,
    isDownloadWarningVisible,
    isUnavailableViewWarningVisible,
    datasetDetailLevelTree,
    datasetDetailLevel,
    allTerritoriesSelected,
    synthesisAndVariabilityMeasures,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,

    handleDetailLevels,
    fetchTableColCount,
    fetchTableCols,
    fetchTableRows,
    fetchDatasetSAVMeasures,
    onDownloadWarningHide,
    onUnavailableViewHide
  } = props;

  const {t} = useTranslation();

  const [chartId] = useState("chart__" + uuidv4());

  const [mapId] = useState("map__" + uuidv4());

  const detailLevelNameIdentifier = useMemo(
    () => getNode(datasetDetailLevelTree, "layers", ({id}) => id === datasetDetailLevel)?.nameIdentifier ?? null,
    [datasetDetailLevelTree, datasetDetailLevel]
  );

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  const handleFetchColCount = useCallback(
    criteria => {
      const columns = [timeDim, ...dimensions.map(({id}) => id).filter(dim => dim !== territoryDim && dim !== timeDim)];
      fetchTableColCount(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        columns,
        maxTableColCount,
        optimizedDataRetrieverBaseUrl
      );
    },
    [
      nodeId,
      datasetId,
      dimensions,
      timeDim,
      territoryDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      optimizedDataRetrieverBaseUrl,
      maxTableColCount,
      fetchTableColCount
    ]
  );

  const handleFetchTableCols = useCallback(
    criteria => {
      const columns = [timeDim, ...dimensions.map(({id}) => id).filter(dim => dim !== territoryDim && dim !== timeDim)];
      fetchTableCols(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        columns,
        optimizedDataRetrieverBaseUrl
      );
    },
    [
      nodeId,
      datasetId,
      dimensions,
      timeDim,
      territoryDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      optimizedDataRetrieverBaseUrl,
      fetchTableCols
    ]
  );

  const handleFetchTableRows = useCallback(
    ({criteria, tableFilters, mapFilters}) => {
      const rows = [territoryDim];
      fetchTableRows(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        tableFilters,
        mapFilters,
        rows,
        optimizedDataRetrieverBaseUrl
      );
    },
    [
      nodeId,
      datasetId,
      territoryDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      optimizedDataRetrieverBaseUrl,
      fetchTableRows
    ]
  );

  const handleFetchSAVMeasures = useCallback(
    ({criteria, tableFilters, mapFilters}) => {
      const columns = [timeDim, ...dimensions.map(({id}) => id).filter(dim => dim !== territoryDim && dim !== timeDim)];
      fetchDatasetSAVMeasures(
        nodeId,
        datasetId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        tableFilters,
        mapFilters,
        columns,
        optimizedDataRetrieverBaseUrl
      );
    },
    [
      nodeId,
      datasetId,
      dimensions,
      timeDim,
      territoryDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      optimizedDataRetrieverBaseUrl,
      fetchDatasetSAVMeasures
    ]
  );

  return (
    <Fragment>
      <Box
        id="data-viewer"
        sx={{
          width: "100%",
          height: "100%",
          overflow: "auto"
        }}
        className={`data-viewer__multi-viewer-plus`}
      >
        <Call
          cb={handleDetailLevels}
          cbParam={{
            nodeId: nodeId,
            datasetId: datasetId,
            catalogDetailLevelTree: catalogDetailLevelTree,
            datasetDetailLevels: datasetMap[datasetId]?.detailsLevels,
            datasetDetailLevel: datasetDetailLevel,
            nodeDetailLevel: nodeDetailLevel,
            baseUrl: geoglossaryBaseUrl
          }}
          disabled={!datasetUuid || datasetDetailLevelTree !== null}
        >
          <Call
            cb={handleFetchColCount}
            cbParam={criteria}
            disabled={
              !tableChartDatasetFetchEnabled ||
              !mapDatasetFetchEnabled ||
              datasetDetailLevelTree === null ||
              colCount !== null
            }
          >
            <Call
              cb={handleFetchTableCols}
              cbParam={criteria}
              disabled={
                (!tableChartDatasetFetchEnabled && !mapDatasetFetchEnabled) ||
                colCount === null ||
                colCount > maxTableColCount ||
                cols !== null
              }
            >
              <Call
                cb={handleFetchTableRows}
                cbParam={{
                  criteria: criteria,
                  tableFilters: tableFilters,
                  mapFilters: mapFilters
                }}
                disabled={(!tableChartDatasetFetchEnabled && !mapDatasetFetchEnabled) || cols === null || rows !== null}
              >
                <Call
                  cb={handleFetchSAVMeasures}
                  cbParam={{
                    criteria: criteria,
                    tableFilters: tableFilters,
                    mapFilters: mapFilters
                  }}
                  disabled={
                    !tableChartDatasetFetchEnabled ||
                    cols === null ||
                    synthesisAndVariabilityMeasures !== null ||
                    (!showArithmeticMean && !showStandardDeviation && !showCoefficientOfVariation)
                  }
                >
                  {notCompatibleDataset ? (
                    <CustomEmpty
                      id="data-viewer__error"
                      text={t("scenes.dataViewer.multiViewer.errors.notCompatibleData")}
                      image={<ErrorIcon />}
                    />
                  ) : (
                    <Fragment>
                      <Box
                        id="data-viewer__header"
                        sx={{
                          width: "100%",
                          padding: "4px 16px 12px"
                        }}
                      >
                        <MultiViewerPlusHeader
                          nodeId={nodeId}
                          nodeCode={nodeCode}
                          nodeExtras={nodeExtras}
                          datasetId={datasetId}
                          datasetTitle={datasetTitle}
                          viewId={viewId}
                          attachedFiles={attachedFiles}
                          chartId={chartId}
                          mapId={mapId}
                          onRender={handleStyle}
                          datasetMap={datasetMap}
                        />
                      </Box>
                      <Box
                        id="data-viewer__sidebar-viewer-container"
                        sx={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          padding: "0 16px 16px",
                          minHeight: "400px",
                          minWidth: "560px"
                        }}
                      >
                        <Box id="data-viewer__sidebar" sx={{height: "100%"}}>
                          <MultiViewerPlusSidebar
                            nodeId={nodeId}
                            nodeCode={nodeCode}
                            nodeExtras={nodeExtras}
                            datasetId={datasetId}
                            datasetTitle={datasetTitle}
                            chartId={chartId}
                            mapId={mapId}
                            datasetMap={datasetMap}
                          />
                        </Box>
                        <Box id="data-viewer__viewer" sx={{height: "100%"}}>
                          <MultiViewerPlusViewer
                            nodeId={nodeId}
                            nodeExtras={nodeExtras}
                            datasetId={datasetId}
                            datasetTitle={datasetTitle}
                            chartId={chartId}
                            mapId={mapId}
                          />
                        </Box>
                      </Box>
                    </Fragment>
                  )}
                </Call>
              </Call>
            </Call>
          </Call>
        </Call>
      </Box>

      <Dialog open={isDownloadWarningVisible} maxWidth="md" onClose={onDownloadWarningHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.downloadFormat.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onDownloadWarningHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isUnavailableViewWarningVisible} maxWidth="md" onClose={onUnavailableViewHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.unavailableView.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onUnavailableViewHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewerPlus);
