import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {Box, useTheme} from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import AdditionalDatasetCreateDialog from "../../additional-datasets-dialogs/create";
import AdditionalDatasetListDialog from "../../additional-datasets-dialogs/list";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import CalculateIcon from "../../custom-icons/CalculateIcon";
import FileDownloadIcon from "../../custom-icons/FileDownloadIcon";
import FilterAltIcon from "../../custom-icons/FIlterAltIcon";
import FlagIcon from "../../custom-icons/FlagIcon";
import ExportButtonJsonStatWrapper from "../../export-button/ExportButtonJsonStatWrapper";
import FullscreenDialog from "../../fullscreen-dialog";
import IndicatorCreateJsonStatWrapper from "../../indicator-dialogs/create/IndicatorCreateJsonStatWrapper";
import IndicatorListDialog from "../../indicator-dialogs/list";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
} from "../../label-format-selector/constants";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../../temporal-dim-order-selector/constants";
import DetailLevelSelector from "../../territory-selectors/DetailLevelSelector";
import TerritoriesSelector from "../../territory-selectors/TerritorySelector";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  changeDatasetMVAdditionalDataset,
  deleteDatasetMVAdditionalDataset,
  deleteDatasetMVIndicator,
  enableDatasetMVDatasetFetch,
  fetchDatasetMVAdditionalDatasetCatalog,
  fetchDatasetMVAdditionalDatasetStructure,
  fetchDatasetMVIndicatorPreview,
  fetchDatasetMVStructureCodelist,
  fetchDatasetMVStructureCodelistFull,
  fetchDatasetMVTerritoryLastYear,
  fetchDatasetMVTerritoryTerritories,
  hideDatasetMVCriteria,
  hideDatasetMVCriteriaAlert,
  hideDatasetMVCriteriaObsCountWarning,
  hideDatasetMVStructureCodelistFull,
  hideDatasetMVTerritory,
  publishDatasetMVIndicator,
  resetDatasetMVIndicatorPreview,
  setDatasetMVAdditionalDatasetCreateVisibility,
  setDatasetMVAdditionalDatasetCriteria,
  setDatasetMVAdditionalDatasetListVisibility,
  setDatasetMVIndicatorArithmeticMeanVisibility,
  setDatasetMVIndicatorCoefficientOfVariationVisibility,
  setDatasetMVIndicatorCreateVisibility,
  setDatasetMVIndicatorListVisibility,
  setDatasetMVIndicatorStandardDeviationVisibility,
  setDatasetMVStructureCriteria,
  setDatasetMVViewerChartVisibility,
  setDatasetMVViewerMapVisibility,
  setDatasetMVViewerTableVisibility,
  showDatasetMVCriteria,
  showDatasetMVTerritory,
  submitDatasetMVAdditionalDataset,
  submitDatasetMVDownload,
  submitDatasetMVDownloadMetadata,
  submitDatasetMVTerritoryTerritories
} from "../../../state/dataset/multi-viewer/actions";
import {isFormatAvailableForMergedData} from "../../../utils/download";
import {getFormattedValue} from "../../../utils/formatters";
import {getNode, getNodes} from "../../../utils/tree";

const actionGroupBackgroundStyle = {
  "& button": {
    background: "rgba(0, 0, 0, 0.07)"
  }
};

const actionDisabledStyle = {
  color: "rgba(0, 0, 0, 0.54) !important",
  "& svg": {
    color: "rgba(0, 0, 0, 0.54)"
  }
};

const mapStateToProps = ({config, appConfig, app, hub, node, dataset}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  shapefilesUrl: appConfig.shapefilesUrl,
  maxTableColCount: appConfig.tableConfig.maxColCount,
  maxMapPolygonCount: appConfig.mapConfig.maxPolygonCount,
  maxMapPointCount: appConfig.mapConfig.maxPointCount,
  hiddenDimensionValueLabels: appConfig.hiddenDimensionValueLabels,
  defaultLanguage: app.language,
  languages: app.languages,
  exportConfig: hub.hub.exportConfig,
  maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
  node: node,
  mode: dataset.commons.mode,
  type: dataset.commons.type,
  dataset: dataset.multiViewer.dataset,
  isEmptyData: dataset.multiViewer.isEmptyData,
  isTooBigData: dataset.multiViewer.isTooBigData,
  isPointData: dataset.multiViewer.isPointData,
  isTooLongQuery: dataset.multiViewer.isTooLongQuery,
  tableColCount: dataset.multiViewer.tableColCount,
  mapPointCount: dataset.multiViewer.mapPointCount,
  isCriteriaVisible: dataset.multiViewer.isCriteriaVisible,
  isCriteriaAlertVisible: dataset.multiViewer.isCriteriaAlertVisible,
  isObsCountWarningVisible: dataset.multiViewer.isObsCountWarningVisible,
  dimensions: dataset.multiViewer.dimensions,
  territoryDim: dataset.multiViewer.territoryDim,
  timeDim: dataset.multiViewer.timeDim,
  freqDim: dataset.multiViewer.freqDim,
  isTableVisible: dataset.multiViewer.isTableVisible,
  isMapVisible: dataset.multiViewer.isMapVisible,
  isChartVisible: dataset.multiViewer.isChartVisible,
  tableLayout: dataset.multiViewer.tableLayout,
  mapLayout: dataset.multiViewer.mapLayout,
  chartLayout: dataset.multiViewer.chartLayout,
  labelFormat: dataset.multiViewer.labelFormat,
  criteria: dataset.multiViewer.criteria,
  territoryDimCriteria: dataset.multiViewer.criteria?.[dataset.multiViewer.territoryDim] || null,
  decimalSeparator: dataset.multiViewer.decimalSeparator,
  roundingStrategy: dataset.multiViewer.roundingStrategy,
  decimalPlaces: dataset.multiViewer.decimalPlaces,
  tableEmptyChar: dataset.multiViewer.tableEmptyChar,
  chartSettings: dataset.multiViewer.chartSettings,
  mapSettings: dataset.multiViewer.mapSettings,
  codelists: dataset.multiViewer.codelists,
  codelistsLength: dataset.multiViewer.codelistsLength,
  codelistFetchError: dataset.multiViewer.codelistFetchError,
  isTerritoryVisible: dataset.multiViewer.isTerritoryVisible,
  detailLevelTree: dataset.multiViewer.detailLevelTree,
  detailLevel: dataset.multiViewer.detailLevel,
  territoryTree: dataset.multiViewer.territoryTree,
  territories: dataset.multiViewer.territories,
  territorialClassificationsConfig: dataset.multiViewer.territorialClassificationsConfig,
  territorialClassificationsValues: dataset.multiViewer.territorialClassificationsValues,
  isIndicatorCreateVisible: dataset.multiViewer.isIndicatorCreateVisible,
  isIndicatorListVisible: dataset.multiViewer.isIndicatorListVisible,
  indicators: dataset.multiViewer.indicators,
  indicatorPreview: dataset.multiViewer.indicatorPreview,
  showArithmeticMean: dataset.multiViewer.showArithmeticMean,
  showStandardDeviation: dataset.multiViewer.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewer.showCoefficientOfVariation,
  isAdditionalDatasetCreateVisible: dataset.multiViewer.isAdditionalDatasetCreateVisible,
  isAdditionalDatasetListVisible: dataset.multiViewer.isAdditionalDatasetListVisible,
  additionalDatasets: dataset.multiViewer.additionalDatasets,
  additionalDatasetCatalog: dataset.multiViewer.additionalDatasetCatalog,
  missingFilterValues: dataset.multiViewer.missingFilterValues,
  territoryDimCodelist: dataset.multiViewer.territoryDimCodelist,
  lastTerritoryYear: dataset.multiViewer.lastTerritoryYear,
  additionalDataset: dataset.multiViewer.additionalDataset
});

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: maxObservations => dispatch(enableDatasetMVDatasetFetch(maxObservations)),
  onCriteriaShow: () => dispatch(showDatasetMVCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetMVCriteria()),
  onSetCriteria: criteria => dispatch(setDatasetMVStructureCriteria(criteria)),
  onSetADCriteria: (datasetIdx, criteria) => dispatch(setDatasetMVAdditionalDatasetCriteria(datasetIdx, criteria)),
  onCriteriaAlertHide: () => dispatch(hideDatasetMVCriteriaAlert()),
  fetchCodelist: (
    nodeId,
    nodeCode,
    datasetId,
    mode,
    type,
    dimensionId,
    criteria,
    freq,
    defaultLastNPeriods,
    preserveFiltersWithDynamic
  ) =>
    dispatch(
      fetchDatasetMVStructureCodelist(
        nodeId,
        nodeCode,
        datasetId,
        mode,
        type,
        dimensionId,
        criteria,
        freq,
        defaultLastNPeriods,
        preserveFiltersWithDynamic
      )
    ),
  onCriteriaObsCountWarningHide: () => dispatch(hideDatasetMVCriteriaObsCountWarning()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) =>
    dispatch(fetchDatasetMVStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetMVStructureCodelistFull()),
  onTerritoryShow: () => dispatch(showDatasetMVTerritory()),
  onTerritoryHide: () => dispatch(hideDatasetMVTerritory()),
  onTableVisibilitySet: isVisible => dispatch(setDatasetMVViewerTableVisibility(isVisible)),
  onMapVisibilitySet: isVisible => dispatch(setDatasetMVViewerMapVisibility(isVisible)),
  onChartVisibilitySet: isVisible => dispatch(setDatasetMVViewerChartVisibility(isVisible)),
  onTerritorySubmit: (detailLevel, territories, criteria) =>
    dispatch(submitDatasetMVTerritoryTerritories(detailLevel, territories, criteria)),
  onDownloadSubmit: (
    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    isPointData,
    criteria,
    timeDim,
    territoryDim,
    layout,
    indicators,
    additionalDatasets,
    format,
    extension,
    zipped,
    params,
    defaultLanguage,
    languages,
    t,
    indicatorsBaseUrl
  ) =>
    dispatch(
      submitDatasetMVDownload(
        nodeId,
        nodeCode,
        datasetId,
        datasetTitle,
        isPointData,
        criteria,
        timeDim,
        territoryDim,
        layout,
        indicators,
        additionalDatasets,
        format,
        extension,
        zipped,
        params,
        defaultLanguage,
        languages,
        t,
        indicatorsBaseUrl
      )
    ),
  onIndicatorCreateVisibilitySet: isVisible => dispatch(setDatasetMVIndicatorCreateVisibility(isVisible)),
  onIndicatorListVisibilitySet: isVisible => dispatch(setDatasetMVIndicatorListVisibility(isVisible)),
  onIndicatorPreviewFetch: (
    nodeId,
    nodeCode,
    datasetId,
    criteria,
    timeDim,
    territoryDim,
    indicators,
    additionalDatasets,
    defaultLanguage,
    languages,
    indicatorsBaseUrl
  ) =>
    dispatch(
      fetchDatasetMVIndicatorPreview(
        nodeId,
        nodeCode,
        datasetId,
        criteria,
        timeDim,
        territoryDim,
        indicators,
        additionalDatasets,
        defaultLanguage,
        languages,
        indicatorsBaseUrl
      )
    ),
  onIndicatorPreviewReset: () => dispatch(resetDatasetMVIndicatorPreview()),
  onIndicatorPublish: indicator => dispatch(publishDatasetMVIndicator(indicator)),
  onIndicatorDelete: indicatorIdx => dispatch(deleteDatasetMVIndicator(indicatorIdx)),
  onArithmeticMeanVisibilitySet: isVisible => dispatch(setDatasetMVIndicatorArithmeticMeanVisibility(isVisible)),
  onStandardDeviationVisibilitySet: isVisible => dispatch(setDatasetMVIndicatorStandardDeviationVisibility(isVisible)),
  onCoefficientOfVariationVisibilitySet: isVisible =>
    dispatch(setDatasetMVIndicatorCoefficientOfVariationVisibility(isVisible)),
  onAdditionalDatasetCreateVisibilitySet: isVisible =>
    dispatch(setDatasetMVAdditionalDatasetCreateVisibility(isVisible)),
  onAdditionalDatasetListVisibilitySet: isVisible => dispatch(setDatasetMVAdditionalDatasetListVisibility(isVisible)),
  fetchADCatalog: ({selectedNodeId, mainDatasetId, baseUrl}) =>
    dispatch(fetchDatasetMVAdditionalDatasetCatalog(selectedNodeId, mainDatasetId, baseUrl)),
  fetchADStructure: ({nodeId, datasetId}) => dispatch(fetchDatasetMVAdditionalDatasetStructure(nodeId, datasetId)),
  onADChange: additionalDataset => dispatch(changeDatasetMVAdditionalDataset(additionalDataset)),
  onADSubmit: () => dispatch(submitDatasetMVAdditionalDataset()),
  onADDelete: datasetIdx => dispatch(deleteDatasetMVAdditionalDataset(datasetIdx)),
  onDownloadReferenceMetadataSubmit: (nodeId, datasetId, datasetTitle) =>
    dispatch(submitDatasetMVDownloadMetadata(nodeId, datasetId, datasetTitle)),
  fetchLastYear: ({nodeId, datasetId, timeDim, criteria}) =>
    dispatch(fetchDatasetMVTerritoryLastYear(nodeId, datasetId, timeDim, criteria)),
  fetchTerritories: (nodeId, datasetId, territoryDim, detailLevel, baseUrl) =>
    dispatch(fetchDatasetMVTerritoryTerritories(nodeId, datasetId, territoryDim, detailLevel, baseUrl))
});

function MultiViewerSidebar(props) {
  const {
    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetTitle,
    chartId,
    mapId,
    datasetMap,

    indicatorsBaseUrl,
    shapefilesUrl,
    maxTableColCount,
    maxMapPolygonCount,
    maxMapPointCount,
    hiddenDimensionValueLabels,
    defaultLanguage,
    languages,
    exportConfig,
    maxObservations,
    node,
    mode,
    type,
    dataset,
    isEmptyData,
    isTooBigData,
    isPointData,
    isTooLongQuery,
    tableColCount,
    mapPointCount,
    isCriteriaVisible,
    isCriteriaAlertVisible,
    isObsCountWarningVisible,
    dimensions,
    territoryDim,
    timeDim,
    freqDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    criteria,
    territoryDimCriteria,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    codelists,
    codelistsLength,
    codelistFetchError,
    isTerritoryVisible,
    detailLevelTree,
    detailLevel,
    territoryTree,
    territories,
    territorialClassificationsConfig,
    territorialClassificationsValues,
    isIndicatorCreateVisible,
    isIndicatorListVisible,
    indicators,
    indicatorPreview,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    isAdditionalDatasetCreateVisible,
    isAdditionalDatasetListVisible,
    additionalDatasets,
    additionalDatasetCatalog,
    missingFilterValues,
    territoryDimCodelist,
    lastTerritoryYear,
    additionalDataset,

    onFetchDatasetEnable,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,
    onSetADCriteria,
    onCriteriaAlertHide,
    fetchCodelist,
    onCriteriaObsCountWarningHide,
    fetchCodelistFull,
    onCodelistFullHide,
    onTerritoryShow,
    onTerritoryHide,
    onTableVisibilitySet,
    onMapVisibilitySet,
    onChartVisibilitySet,
    onTerritorySubmit,
    onDownloadSubmit,
    onIndicatorCreateVisibilitySet,
    onIndicatorListVisibilitySet,
    onIndicatorPreviewFetch,
    onIndicatorPreviewReset,
    onIndicatorPublish,
    onIndicatorDelete,
    onArithmeticMeanVisibilitySet,
    onStandardDeviationVisibilitySet,
    onCoefficientOfVariationVisibilitySet,
    onAdditionalDatasetCreateVisibilitySet,
    onAdditionalDatasetListVisibilitySet,
    fetchADCatalog,
    fetchADStructure,
    onADChange,
    onADSubmit,
    onADDelete,
    onDownloadReferenceMetadataSubmit,
    fetchLastYear,
    fetchTerritories
  } = props;

  const {t} = useTranslation();

  const theme = useTheme();

  const [datasetIdx, setDatasetIdx] = useState(0);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const [detailLevelTmp, setDetailLevelTmp] = useState(null);
  const [territoriesTmp, setTerritoriesTmp] = useState(null);

  const [step, setStep] = useState(null);
  useEffect(() => {
    setStep(isPointData ? 1 : 0);
  }, [isPointData]);

  const [isTerritoryWarningVisible, setTerritoryWarningVisibility] = useState(false);

  const [indicatorsAnchorEl, setIndicatorsAnchorEl] = useState(null);
  const [additionalDatasetsAnchorEl, setAdditionalDatasetsAnchorEl] = useState(null);

  const [datasetToExportIdx, setDatasetToExportIdx] = useState(null);

  const generalizationLevel = useMemo(() => {
    const generalizationLevels =
      getNode(detailLevelTree, "layers", ({id}) => id === detailLevelTmp)?.generalizationLevels ?? [];
    return (generalizationLevels || []).length > 0 ? generalizationLevels[generalizationLevels.length - 1] : null;
  }, [detailLevelTree, detailLevelTmp]);

  const getDimensionsCombinationCount = useCallback(
    dimensions =>
      dataset && dimensions ? dimensions.reduce((acc, dim) => acc * dataset.size[dataset.id.indexOf(dim)], 1) : 0,
    [dataset]
  );

  const downloadFormats =
    additionalDatasets.length === 0
      ? nodeExtras?.DownloadFormats
      : nodeExtras?.DownloadFormats.filter(format => isFormatAvailableForMergedData(format));

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  const actionGroupStyle = {
    marginBottom: "8px",
    "& button:hover": {
      background: "rgba(0, 0, 0, 0.1)",
      color: `${theme.palette.primary.main} !important`,
      "& svg": {
        color: theme.palette.primary.main
      }
    }
  };

  const handleCriteriaOpen = () => {
    setDatasetIdx(0);
    onCriteriaShow();
  };

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  };

  const handleCriteriaSubmit = maxObservations => {
    onFetchDatasetEnable(maxObservations);
  };

  const handleTerritoryOpen = () => {
    setStep(isPointData ? 1 : 0);

    setDetailLevelTmp(detailLevel);
    setTerritoriesTmp(territories);

    onTerritoryShow();
  };

  useEffect(() => {
    setDetailLevelTmp(prevVal => (prevVal === null && detailLevel !== null ? detailLevel : prevVal));
  }, [detailLevel]);

  useEffect(() => {
    setTerritoriesTmp(prevVal =>
      (prevVal || []).length === 0 && (territoryTree || []).length > 0
        ? getNodes(territoryTree, "children", () => true).map(({id}) => id)
        : prevVal
    );
  }, [territoryTree]);

  const handleTerritoryClose = () => {
    onTerritoryHide();
  };

  const handleTerritoriesFetch = useCallback(
    ({nodeId, datasetId, territoryDim, detailLevel: detailLevelTmp, baseUrl}) => {
      fetchTerritories(nodeId, datasetId, territoryDim, detailLevelTmp, baseUrl);
    },
    [fetchTerritories]
  );

  const handleTerritorySubmit = () => {
    const territoryFilterValues = getNodes(
      territoryTree,
      "children",
      ({id, children}) => territoriesTmp.includes(id) && (children || []).length === 0
    );

    if (territoryFilterValues.length < maxMapPolygonCount) {
      let newCriteria = {
        ...criteria,
        [territoryDim]: {
          id: territoryDim,
          filterValues: territoryFilterValues.map(({id}) => id)
        }
      };
      onTerritorySubmit(detailLevelTmp, territoriesTmp, newCriteria);
    } else {
      setTerritoryWarningVisibility(true);
    }
  };

  const visibleViewerCount = [isTableVisible, isChartVisible, isMapVisible].filter(el => el).length;
  const isTableDisabled = !dataset || !tableLayout;
  const isChartDisabled = !dataset || !chartLayout;
  const isMapDisabled = !dataset || !mapLayout;

  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle,
      dimensions: dimensions,
      timeDim: timeDim,
      freqDim: freqDim,
      territoryDim: territoryDim,
      codelists: codelists,
      codelistsLength: codelistsLength,
      criteria: criteria
    },
    ...additionalDatasets
  ];

  const datasetsWithMetadata = datasets.filter(({datasetId}) => datasetMap[datasetId]?.referenceMetadata);

  return (
    <Card
      sx={{
        width: "80px",
        height: "100%",
        overflow: "auto",
        padding: "8px 0",
        marginRight: "16px",
        "& .MuiBottomNavigation-root": {
          padding: "unset !important"
        },
        "& .MuiBottomNavigation-root button": {
          color: theme.palette.primary.main
        },
        "& .MuiBottomNavigationAction-label": {
          fontSize: "13px !important"
        }
      }}
      role="menu"
    >
      <Box sx={actionGroupStyle}>
        {territoryDim && (
          <BottomNavigation showLabels onChange={handleTerritoryOpen}>
            <BottomNavigationAction
              label={t("scenes.dataViewer.multiViewer.sidebar.territory")}
              icon={<FlagIcon />}
              role="menuitem"
            />
          </BottomNavigation>
        )}
        <BottomNavigation showLabels onChange={handleCriteriaOpen}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.criteria")}
            icon={<FilterAltIcon />}
            sx={(territories || []).length === 0 && !isPointData ? actionDisabledStyle : null}
            disabled={(territories || []).length === 0 && !isPointData}
            role="menuitem"
          />
        </BottomNavigation>
      </Box>

      <Box sx={[actionGroupStyle, actionGroupBackgroundStyle]}>
        <BottomNavigation
          showLabels
          value={isTableVisible ? 0 : null}
          onChange={() => onTableVisibilitySet(!isTableVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.table")}
            icon={<TableChartOutlinedIcon />}
            sx={!isTableVisible ? actionDisabledStyle : null}
            disabled={isTableDisabled || (isTableVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation
          showLabels
          value={isChartVisible ? 0 : null}
          onChange={() => onChartVisibilitySet(!isChartVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.chart")}
            icon={<BarChartIcon />}
            sx={!isChartVisible ? actionDisabledStyle : null}
            disabled={isChartDisabled || (isChartVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation showLabels value={isMapVisible ? 0 : null} onChange={() => onMapVisibilitySet(!isMapVisible)}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.map")}
            icon={<MapOutlinedIcon />}
            sx={!isMapVisible ? actionDisabledStyle : null}
            disabled={isMapDisabled || (isMapVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
      </Box>

      <Box sx={actionGroupStyle}>
        {!isPointData && (
          <BottomNavigation showLabels onChange={({currentTarget}) => setAdditionalDatasetsAnchorEl(currentTarget)}>
            <BottomNavigationAction
              label={t("scenes.dataViewer.multiViewer.sidebar.addData")}
              icon={<AddCircleOutlineOutlinedIcon />}
              sx={!dataset && additionalDatasets.length === 0 ? actionDisabledStyle : null}
              disabled={!dataset && additionalDatasets.length === 0}
              role="menuitem"
            />
          </BottomNavigation>
        )}
        <BottomNavigation showLabels onChange={({currentTarget}) => setIndicatorsAnchorEl(currentTarget)}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.calculateIndicator")}
            icon={<CalculateIcon />}
            sx={!dataset && indicators.length === 0 ? actionDisabledStyle : null}
            disabled={!dataset && indicators.length === 0}
            role="menuitem"
          />
        </BottomNavigation>
      </Box>

      <Box sx={actionGroupStyle}>
        <ExportButtonJsonStatWrapper
          formats={downloadFormats}
          jsonStat={dataset}
          viewerIdx={0}
          showAsBottomNavigation={true}
          icon={<FileDownloadIcon />}
          isTableVisible={isTableVisible}
          isMapVisible={isMapVisible}
          isChartVisible={isChartVisible}
          tableLayout={tableLayout}
          mapId={mapId}
          mapContainerId="data-viewer__viewers__map__viewer"
          mapLayout={mapLayout}
          mapSettings={mapSettings}
          chartId={chartId}
          chartContainerId="data-viewer__viewers__chart__viewer"
          chartLayout={chartLayout}
          chartSettings={chartSettings}
          labelFormat={labelFormat}
          datasetTitle={datasetTitle}
          getDimensionsCombinationCount={getDimensionsCombinationCount}
          submitDownload={(format, extension, additionalParams) => {
            const exportParams = {
              labelFormat: labelFormat,
              customLabelFormat: {
                [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
                [territoryDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH
              },
              decimalSeparator: decimalSeparator,
              roundingStrategy: roundingStrategy,
              decimalNumber: decimalPlaces,
              emptyCellPlaceHolder: tableEmptyChar,
              hiddenDimensionValueLabels: hiddenDimensionValueLabels,
              temporalDimOrder: TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
              exportConfig: exportConfig,
              suffixToRemove: {
                [territoryDim]:
                  getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || ""
              },
              customDimensionLabels: {
                [territoryDim]: getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.label || null
              },
              territorialClassificationsConfig: territorialClassificationsConfig,
              territorialClassificationsValues: territorialClassificationsValues,
              ...additionalParams
            };

            onDownloadSubmit(
              nodeId,
              nodeCode,
              datasetId,
              datasetTitle,
              isPointData,
              criteria,
              timeDim,
              territoryDim,
              tableLayout,
              indicators,
              additionalDatasets,
              format,
              extension,
              false,
              exportParams,
              defaultLanguage,
              languages,
              t,
              indicatorsBaseUrl
            );
          }}
          shapefilesUrl={shapefilesUrl}
          role="menuitem"
          submitMetadataDownload={
            additionalDatasets.length === 0
              ? datasetMap[datasetId]?.referenceMetadata
                ? () => onDownloadReferenceMetadataSubmit(nodeId, datasetId, datasetTitle)
                : null
              : datasetsWithMetadata.length > 0
                ? () => setDatasetToExportIdx(0)
                : null
          }
          //className={!dataset ? actionDisabledStyle : ""} // TODO: fix (with sx prop) className propagation in nested commponents
          disabled={!dataset}
          hideLabelSelectorFormat={true}
        />
      </Box>

      <FullscreenDialog open={isCriteriaVisible === true} onClose={handleCriteriaClose} id={"criteria-dialog"}>
        <CustomDialogTitle onClose={handleCriteriaClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.criteria.title")}
        </CustomDialogTitle>
        <DialogContent sx={{height: "100%"}}>
          {datasets.length > 1 && (
            <FormControl id="criteria__dataset-selector" sx={{marginBottom: "8px"}} fullWidth>
              <TextField
                select
                value={datasetIdx}
                onChange={ev => setDatasetIdx(ev.target.value)}
                variant="outlined"
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                {datasets.map(({datasetTitle}, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {datasetTitle}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          <div
            style={{
              height: `calc(100% - ${datasets.length > 1 ? 64 : 0}px)`
            }}
          >
            {datasets[datasetIdx] && (
              <Criteria
                key={`${datasets[datasetIdx].nodeCode}+${datasets[datasetIdx].datasetId}`}
                viewerMode={ViewerMode.MultiViewer}
                nodeId={datasets[datasetIdx].nodeId}
                nodeCode={datasets[datasetIdx].nodeCode}
                datasetId={datasets[datasetIdx].datasetId}
                dimensions={datasets[datasetIdx].dimensions}
                timeDim={datasets[datasetIdx].timeDim}
                freqDim={datasets[datasetIdx].freqDim}
                territoryDim={datasets[datasetIdx].territoryDim}
                hideTerritoryDim={!datasets[datasetIdx].isPointData}
                mode={mode}
                type={type}
                criteria={datasets[datasetIdx].criteria}
                territoryDimCriteria={datasetIdx > 0 && criteria?.[territoryDim] ? criteria[territoryDim] : null}
                onSetCriteria={criteria =>
                  datasetIdx === 0 ? onSetCriteria(criteria) : onSetADCriteria(datasetIdx - 1, criteria)
                }
                codelists={datasets[datasetIdx].codelists}
                codelistsLength={datasets[datasetIdx].codelistsLength}
                fetchCodelist={fetchCodelist}
                codelistFetchError={codelistFetchError}
                isCriteriaValid={isCriteriaValid}
                setCriteriaValidity={setCriteriaValidity}
                isObsCountWarningVisible={isObsCountWarningVisible}
                onCriteriaObsCountWarningHide={onCriteriaObsCountWarningHide}
                onSubmit={handleCriteriaSubmit}
                defaultLastNPeriods={defaultLastNPeriods}
                preserveFiltersWithDynamic
                missingFilterValues={missingFilterValues}
                fetchCodelistFull={fetchCodelistFull}
                onCodelistFullHide={onCodelistFullHide}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCriteriaClose}>{t("commons.confirm.cancel")}</Button>
          <Button
            autoFocus
            color="primary"
            onClick={() => handleCriteriaSubmit(maxObservations)}
            disabled={!isCriteriaValid}
            id="data_viewer_multi_viewer_sidebar_criteria_confirm_btn"
          >
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <Dialog open={isCriteriaAlertVisible} fullWidth maxWidth="sm" onClose={onCriteriaAlertHide}>
        {(() => {
          if (isEmptyData) {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.emptyData.title")}
              </CustomDialogTitle>
            );
          } else if (isTooBigData) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooBigData.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.tooBigData.content")}</DialogContent>
              </Fragment>
            );
          } else if (isTooLongQuery) {
            return (
              <Fragment>
                <CustomDialogTitle onClose={onCriteriaAlertHide}>
                  {t("scenes.dataViewer.sidebar.warning.isTooLongQuery.title")}
                </CustomDialogTitle>
                <DialogContent>{t("scenes.dataViewer.sidebar.warning.isTooLongQuery.content")}</DialogContent>
              </Fragment>
            );
          } else if (tableColCount !== null) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooMuchCols.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {t("scenes.dataViewer.sidebar.warning.tooMuchCols.content", {
                    val: getFormattedValue(tableColCount, node?.decimalSeparator, node?.decimalNumber),
                    max: getFormattedValue(maxTableColCount, node?.decimalSeparator, node?.decimalNumber)
                  })}
                </DialogContent>
              </Fragment>
            );
          } else if (mapPointCount !== null) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooMuchPoints.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {t("scenes.dataViewer.sidebar.warning.tooMuchPoints.content", {
                    val: getFormattedValue(mapPointCount, node?.decimalSeparator, node?.decimalNumber),
                    max: getFormattedValue(maxMapPointCount, node?.decimalSeparator, node?.decimalNumber)
                  })}
                </DialogContent>
              </Fragment>
            );
          } else {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.genericError.title")}
              </CustomDialogTitle>
            );
          }
        })()}
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              onCriteriaAlertHide();
              if (!isIndicatorCreateVisible && !isAdditionalDatasetCreateVisible) {
                onCriteriaShow();
              }
            }}
          >
            {tableColCount || mapPointCount ? t("commons.confirm.confirm") : t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <FullscreenDialog open={isTerritoryVisible === true} onClose={handleTerritoryClose} maxWidth={1280}>
        <CustomDialogTitle onClose={handleTerritoryClose}>{datasetTitle}</CustomDialogTitle>
        <DialogContent sx={{height: "100%"}}>
          {!isPointData && (
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Stepper activeStep={step} nonLinear alternativeLabel>
                  <Step key={0}>
                    <StepButton onClick={() => setStep(0)}>
                      {t("components.territory.steps.detailLevel.title")}
                    </StepButton>
                  </Step>
                  <Step key={1}>
                    <StepButton onClick={() => setStep(1)} disabled={detailLevelTmp === null}>
                      {t("components.territory.steps.territory.title")}
                    </StepButton>
                  </Step>
                </Stepper>
              </Grid>
            </Grid>
          )}
          <Box
            key={step}
            sx={[
              {
                height: "calc(100% - 108px)",
                overflow: "hidden"
              },
              isPointData && {height: "100%"}
            ]}
          >
            {step === 0 && (
              <DetailLevelSelector
                detailLevelTree={detailLevelTree}
                detailLevel={detailLevelTmp}
                setDetailLevel={newDetailLevel => {
                  setDetailLevelTmp(newDetailLevel);
                  setTerritoriesTmp(null);
                }}
                additionalDatasets={additionalDatasets}
              />
            )}
            {step === 1 && (
              <TerritoriesSelector
                nodeId={nodeId}
                datasetId={datasetId}
                territoryDimCodelist={territoryDimCodelist}
                territoryDim={territoryDim}
                timeDim={timeDim}
                criteria={criteria}
                detailLevelTree={detailLevelTree}
                detailLevel={detailLevelTmp}
                territoryTree={territoryTree}
                territories={territoriesTmp}
                setTerritories={setTerritoriesTmp}
                fetchTerritories={handleTerritoriesFetch}
                lastTerritoryYear={lastTerritoryYear}
                fetchLastYear={fetchLastYear}
                generalizationLevel={generalizationLevel}
                isFetchEnabled={isTerritoryVisible}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {step === 0 && (
            <Fragment>
              <Button onClick={handleTerritoryClose}>{t("commons.confirm.cancel")}</Button>
              <Button autoFocus color="primary" onClick={() => setStep(1)} disabled={detailLevelTmp === null}>
                {t("commons.confirm.next")}
              </Button>
            </Fragment>
          )}
          {step === 1 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                {!isPointData && (
                  <Button color="primary" onClick={() => setStep(0)}>
                    {t("commons.confirm.back")}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button onClick={handleTerritoryClose}>{t("commons.confirm.cancel")}</Button>
                <Button
                  autoFocus
                  color="primary"
                  onClick={handleTerritorySubmit}
                  disabled={(territoriesTmp || []).length === 0}
                >
                  {t("commons.confirm.apply")}
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </FullscreenDialog>

      <Dialog open={datasetToExportIdx !== null} maxWidth="sm" fullWidth onClose={() => setDatasetToExportIdx(null)}>
        <CustomDialogTitle onClose={() => setDatasetToExportIdx(null)}>
          {t("scenes.dataViewer.multiViewer.dialogs.exportMetadata.title")}
        </CustomDialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              select
              value={datasetToExportIdx !== null ? datasetToExportIdx : ""}
              onChange={ev => setDatasetToExportIdx(ev.target.value)}
              variant="outlined"
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {datasetsWithMetadata.map(({datasetTitle}, idx) => (
                <MenuItem key={idx} value={idx}>
                  {datasetTitle}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDatasetToExportIdx(null)}>{t("commons.confirm.close")}</Button>
          <Button
            onClick={() => {
              const dataToExport = datasetsWithMetadata[datasetToExportIdx];
              onDownloadReferenceMetadataSubmit(dataToExport.nodeId, dataToExport.datasetId, dataToExport.datasetTitle);
            }}
          >
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTerritoryWarningVisible === true}
        maxWidth="sm"
        fullWidth
        onClose={() => setTerritoryWarningVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setTerritoryWarningVisibility(false)}>
          {t("scenes.dataViewer.multiViewer.dialogs.toMuchPolygon.title")}
        </CustomDialogTitle>
        <DialogContent>{t("scenes.dataViewer.multiViewer.dialogs.toMuchPolygon.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setTerritoryWarningVisibility(false)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Menu
        anchorEl={indicatorsAnchorEl}
        keepMounted
        open={indicatorsAnchorEl !== null}
        onClose={() => setIndicatorsAnchorEl(null)}
      >
        {!isPointData && (
          <MenuItem
            disabled
            sx={{
              width: "100%",
              color: "gray",
              fontStyle: "italic",
              fontSize: "14px"
            }}
          >
            {t("scenes.dataViewer.multiViewer.indicators.title")}
          </MenuItem>
        )}
        {!isPointData && (
          <MenuItem
            onClick={() => {
              onIndicatorCreateVisibilitySet(true);
              setIndicatorsAnchorEl(null);
            }}
            disabled={!dataset || (indicators || []).length >= 5}
          >
            {t("scenes.dataViewer.multiViewer.indicators.values.create")}
          </MenuItem>
        )}
        {!isPointData && (
          <MenuItem
            onClick={() => {
              onIndicatorListVisibilitySet(true);
              setIndicatorsAnchorEl(null);
            }}
            disabled={(indicators || []).length === 0}
          >
            {t("scenes.dataViewer.multiViewer.indicators.values.list")}
          </MenuItem>
        )}
        <MenuItem
          disabled
          sx={{
            width: "100%",
            color: "gray",
            fontStyle: "italic",
            fontSize: "14px"
          }}
        >
          {t("scenes.dataViewer.multiViewer.measuresOfSynthesisAndVariability.title")}
        </MenuItem>
        <Tooltip placement="top" title={t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.tooltip")}>
          <MenuItem onClick={() => onArithmeticMeanVisibilitySet(!showArithmeticMean)} disabled={!dataset}>
            {t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.label")}
            {showArithmeticMean && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
        <Tooltip
          placement="top"
          title={t("commons.measuresOfSynthesisAndVariability.values.standardDeviation.tooltip")}
        >
          <MenuItem onClick={() => onStandardDeviationVisibilitySet(!showStandardDeviation)} disabled={!dataset}>
            {t("commons.measuresOfSynthesisAndVariability.values.standardDeviation.label")}
            {showStandardDeviation && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
        <Tooltip
          placement="top"
          title={t("commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation.tooltip")}
        >
          <MenuItem
            onClick={() => onCoefficientOfVariationVisibilitySet(!showCoefficientOfVariation)}
            disabled={!dataset}
          >
            {t("commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation.label")}
            {showCoefficientOfVariation && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
      </Menu>

      <Menu
        anchorEl={additionalDatasetsAnchorEl}
        keepMounted
        open={additionalDatasetsAnchorEl !== null}
        onClose={() => setAdditionalDatasetsAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onAdditionalDatasetCreateVisibilitySet(true);
            setAdditionalDatasetsAnchorEl(null);
          }}
          disabled={!dataset || additionalDatasets.length >= 2}
        >
          {t("scenes.dataViewer.multiViewer.additionalDataset.create.title")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onAdditionalDatasetListVisibilitySet(true);
            setAdditionalDatasetsAnchorEl(null);
          }}
          disabled={additionalDatasets.length === 0}
        >
          {t("scenes.dataViewer.multiViewer.additionalDataset.list.title")}
        </MenuItem>
      </Menu>

      {isIndicatorCreateVisible && (
        <IndicatorCreateJsonStatWrapper
          isOpen={isIndicatorCreateVisible}
          onClose={() => onIndicatorCreateVisibilitySet(false)}
          datasetId={datasetId}
          dataset={dataset}
          territoryDim={territoryDim}
          timeDim={timeDim}
          criteria={criteria}
          tableLayout={tableLayout}
          indicators={indicators}
          indicatorPreview={indicatorPreview}
          additionalDatasets={additionalDatasets}
          onPreviewFetch={onIndicatorPreviewFetch}
          onPreviewReset={onIndicatorPreviewReset}
          onPublish={onIndicatorPublish}
        />
      )}

      {isIndicatorListVisible && (
        <IndicatorListDialog
          isOpen={isIndicatorListVisible}
          onClose={() => onIndicatorListVisibilitySet(false)}
          indicators={indicators}
          onDelete={onIndicatorDelete}
        />
      )}

      {isAdditionalDatasetCreateVisible && (
        <AdditionalDatasetCreateDialog
          isOpen={isAdditionalDatasetCreateVisible}
          onClose={() => onAdditionalDatasetCreateVisibilitySet(false)}
          defaultLastNPeriods={defaultLastNPeriods}
          catalog={additionalDatasetCatalog}
          fetchCatalog={fetchADCatalog}
          additionalDataset={additionalDataset}
          fetchStructure={fetchADStructure}
          onDatasetChange={onADChange}
          onDatasetSubmit={onADSubmit}
          isObsCountWarningVisible={isObsCountWarningVisible}
          onObsCountWarningHide={onCriteriaObsCountWarningHide}
          territoryDimCriteria={territoryDimCriteria}
          fetchCodelist={fetchCodelist}
          codelistFetchError={codelistFetchError}
          fetchCodelistFull={fetchCodelistFull}
          onCodelistFullHide={onCodelistFullHide}
          missingFilterValues={missingFilterValues}
        />
      )}

      {isAdditionalDatasetListVisible && (
        <AdditionalDatasetListDialog
          isOpen={isAdditionalDatasetListVisible}
          onClose={() => onAdditionalDatasetListVisibilitySet(false)}
          additionalDatasets={additionalDatasets}
          onDelete={onADDelete}
        />
      )}
    </Card>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewerSidebar);
