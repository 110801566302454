import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../../label-format-selector/constants";
import Map from "./Map";
import {getDataIdxFromCoordinatesArray, getDimensionValuesIndexesMap} from "../../../utils/dataset";
import {getLayoutGeometriesFromJsonStat} from "../utils";

const MapJsonStatWrapper = ({layout, data, isPointData, latAttributeId, longAttributeId, ...props}) => {
  const {t} = useTranslation();

  const territoryDim = useMemo(() => layout?.territoryDim ?? null, [layout]);
  const territories = useMemo(() => data?.dimension?.[territoryDim]?.category?.index ?? [], [data, territoryDim]);

  const indexesMap = useMemo(() => (data ? getDimensionValuesIndexesMap(data) : null), [data]);

  const getLayoutGeometries = useCallback(
    (fetchedGeometries, allGeometriesMap, dimensionValueTree) => {
      return getLayoutGeometriesFromJsonStat(
        fetchedGeometries,
        data,
        layout,
        LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
        t,
        undefined,
        true,
        dimensionValueTree,
        isPointData,
        latAttributeId,
        longAttributeId,
        allGeometriesMap
      );
    },
    [data, layout, isPointData, latAttributeId, longAttributeId, t]
  );

  const getObservationValue = useCallback(
    territoryId => {
      if (!data || !indexesMap || isPointData) {
        return null;
      } else {
        let dimValueArray = data.id.map(dim =>
          dim === territoryDim ? territoryId : layout.filtersValue?.[dim] ?? null
        );
        const dimValueIndexArray = dimValueArray.map((value, idx) =>
          value === null ? null : indexesMap?.[data.id[idx]]?.[value] ?? null
        );
        if (dimValueIndexArray.some(val => val === null)) {
          return null;
        } else {
          const valueIdx = getDataIdxFromCoordinatesArray(dimValueIndexArray, data.size);
          return data.value[valueIdx];
        }
      }
    },
    [isPointData, territoryDim, layout, data, indexesMap]
  );

  return (
    <Map
      {...props}
      territoryDim={territoryDim}
      territories={territories}
      layout={layout}
      data={data}
      isPointData={isPointData}
      getLayoutGeometries={getLayoutGeometries}
      getObservationValue={getObservationValue}
    />
  );
};

export default MapJsonStatWrapper;
