import {useContext} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {FilterDto} from "../../model/item-containers-models/filterDto";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {ItemRichTextDto} from "../../model/item-containers-models/itemRichTextDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {NodeCatalogModelView} from "../../model/item-containers-models/nodeCatalogModelView";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import {ItemContainerSettingsFormContext} from "../settings-select/user-itemContainers-settings-form/ItemContainerSettingsFormProvider";
import {
  changeOtherConfigItemContainer,
  fetchOtherConfigItemContainerView
} from "../../state/otherConfig/otherConfigActions";
import {currentItemContainerSelector} from "../../state/otherConfig/otherConfigSelectors";
import {
  emptyItemContainerElem,
  ITEMCONTAINER_ELEM_ENABLE_FILTERS_KEY,
  ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY,
  ITEMCONTAINER_ELEM_SHOW_TITLE_KEY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT,
  ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW,
  ITEMCONTAINER_ELEM_VALUE_KEY,
  ITEMCONTAINER_ELEM_WIDTH_KEY
} from "../../utils/itemContainers";

const handleResizeItems = newItemContainer => {
  newItemContainer.item.forEach(item => {
    item.forEach(obj => {
      obj.widthPercentage = 100 / item.length;
    });
  });
};

const draggingRow = {
  current: -1
};

export const useItemContainerBuilder = () => {
  const itemContainer = useSelector(currentItemContainerSelector);
  const {asDashboard} = useContext(ItemContainerSettingsFormContext);

  const dispatch = useDispatch();

  const handleRowAdd = () => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item.push([emptyItemContainerElem]);
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleRowRemove = (rowIdx: number) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item = newItemContainer.item.filter((_, idx) => idx !== rowIdx);
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleColAdd = (rowIdx: number) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item[rowIdx].push(emptyItemContainerElem);

    let totalWidth = 0;
    newItemContainer.item[rowIdx] = newItemContainer.item[rowIdx].map((col, colIdx) => {
      const width =
        colIdx < newItemContainer.item[rowIdx].length - 1 ? Math.floor(100 / newItemContainer.item[rowIdx].length) : 0;
      totalWidth += width;

      return {
        ...col,
        [ITEMCONTAINER_ELEM_WIDTH_KEY]: width || 100 - totalWidth
      };
    });
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleColRemove = (rowIdx: number, colIdx: number) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item[rowIdx] = newItemContainer.item[rowIdx].filter((_, idx) => idx !== colIdx);
    newItemContainer.item[rowIdx] = newItemContainer.item[rowIdx].map(col => ({
      ...col,
      [ITEMCONTAINER_ELEM_WIDTH_KEY]: 100 / newItemContainer.item[rowIdx].length
    }));
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleFilterChange = (value: FilterDto) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.filterLevelsSelected = [];
    newItemContainer.filter = value;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleFilterLevelsChange = (checked: boolean, value: number) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.filterLevelsSelected = checked
      ? newItemContainer.filterLevelsSelected.concat(value)
      : newItemContainer.filterLevelsSelected.filter(level => level !== value);
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleShowTitleChange = (rowIdx: number, colIdx: number, value: boolean) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item[rowIdx][colIdx][ITEMCONTAINER_ELEM_SHOW_TITLE_KEY] = value;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleEnableFiltersChange = (rowIdx: number, colIdx: number, value: boolean) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item[rowIdx][colIdx][ITEMCONTAINER_ELEM_ENABLE_FILTERS_KEY] = value;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleFilterDimensionChange = (rowIdx: number, colIdx: number, value: string) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item[rowIdx][colIdx][ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY] = value;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleViewSet = (view: ViewTemplateDto, rowIdx: number, colIdx: number) => {
    dispatch(fetchOtherConfigItemContainerView(view, rowIdx, colIdx));
    const newItemContainer = _.cloneDeep(itemContainer);
    const item = newItemContainer.item[rowIdx][colIdx] as ItemViewTemplateDto & {viewTemplateId: number};
    item.type = ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW;
    item.id = view.viewTemplateId;
    item.viewTemplateId = view.viewTemplateId;
    item.viewTemplate = view;
    item.nodeId = item.viewTemplate.nodeId;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };
  const handleTextChange = (text: {[key: string]: string}, rowIdx: number, colIdx: number) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    const item = newItemContainer.item[rowIdx][colIdx] as ItemRichTextDto;
    item.type = ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT;
    item.data = text;
    item.id = 0;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };
  const handleViewReset = (rowIdx: number, colIdx: number) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item[rowIdx][colIdx][ITEMCONTAINER_ELEM_VALUE_KEY] = null;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleCategoryChange = (
    newCatalog: NodeCatalogModelView,
    rowIdx: number,
    colIdx: number,
    fullId: string,
    nodeId: number
  ) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    const item = newItemContainer.item[rowIdx][colIdx] as ItemCategoryTemplateDto;
    item.type = ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY;
    item.nodeId = nodeId;
    item.catalog = newCatalog;
    item.category = fullId;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleCategoryOptionChange = (categoryOption, rowIdx: number, colIdx: number) => {
    const {name, value} = categoryOption;
    const newItemContainer = _.cloneDeep(itemContainer);
    const item = newItemContainer.item[rowIdx][colIdx] as ItemCategoryTemplateDto;
    item[name] = value;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleTypeReset = (rowIdx: number, colIdx: number) => {
    const newItemContainer = _.cloneDeep(itemContainer);
    newItemContainer.item[rowIdx][colIdx].type = null;
    newItemContainer.item[rowIdx][colIdx][ITEMCONTAINER_ELEM_VALUE_KEY] = null;
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const getHorizontalDragAfterElement = (container, x) => {
    return container.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();
        const offset = x - box.left - box.width / 2;
        if (offset < 0 && offset > closest.offset) {
          return {offset: offset, element: child};
        } else {
          return closest;
        }
      },
      {offset: Number.NEGATIVE_INFINITY}
    );
  };

  const getVerticalDragAfterElement = (container, y) => {
    return container.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();
        const offset = y - box.top - box.height / 2;
        if (offset < 0 && offset > closest.offset) {
          return {offset: offset, element: child};
        } else {
          return closest;
        }
      },
      {offset: Number.NEGATIVE_INFINITY}
    );
  };

  const handleColSortedDropChange = (ev, newIdx, colNewIdx) => {
    let newItemContainer = _.cloneDeep(itemContainer);
    const tmpItemContainer = itemContainer;
    const draggedDatas = ev.dataTransfer.getData("data").split("-");
    if (newItemContainer.item[newIdx].length > 3) {
      dispatch(changeOtherConfigItemContainer(newItemContainer));
    } else {
      newItemContainer.item[draggedDatas[0]].splice(draggedDatas[1], 1);
      if (colNewIdx !== undefined) {
        newItemContainer.item[newIdx].splice(
          parseInt(colNewIdx),
          0,
          tmpItemContainer.item[draggedDatas[0]][draggedDatas[1]]
        );
      } else {
        newItemContainer.item[newIdx].push(tmpItemContainer.item[draggedDatas[0]][draggedDatas[1]]);
      }
      if (newItemContainer.item[draggedDatas[0]].length === 0) {
        newItemContainer.item.splice(draggedDatas[0], 1);
      }
      handleResizeItems(newItemContainer);
      dispatch(changeOtherConfigItemContainer(newItemContainer));
    }
  };

  const handleRowDropChange = (newIdxDest, oldIdx) => {
    let newItemContainer = _.cloneDeep(itemContainer);
    let tmpItemContainer = itemContainer;
    newItemContainer.item[oldIdx] = tmpItemContainer.item[newIdxDest];
    newItemContainer.item[newIdxDest] = tmpItemContainer.item[oldIdx];
    dispatch(changeOtherConfigItemContainer(newItemContainer));
  };

  const handleRowSortedDropChange = (newIdxDest, oldIdx) => {
    let newItemContainer = _.cloneDeep(itemContainer);
    if (newIdxDest !== undefined) {
      newItemContainer.item.splice(parseInt(newIdxDest), 0, newItemContainer.item[oldIdx]);
      if (oldIdx < newIdxDest) {
        newItemContainer.item.splice(oldIdx, 1);
      } else {
        newItemContainer.item.splice(oldIdx + 1, 1);
      }
      dispatch(changeOtherConfigItemContainer(newItemContainer));
    } else {
      newItemContainer.item.push(newItemContainer.item[oldIdx]);
      newItemContainer.item.splice(oldIdx, 1);
      dispatch(changeOtherConfigItemContainer(newItemContainer));
    }
  };

  const getGridContainerProps = () => {
    const dropGrid = (e: React.DragEvent) => {
      const draggedDatas = e.dataTransfer.getData("data");
      const rows = [...Array.from(document.getElementsByClassName("rowDrag"))] as HTMLDivElement[];

      if (draggedDatas.startsWith("draggableRow")) {
        const {element} = getVerticalDragAfterElement(rows, e.clientY);
        const rowId = element === undefined ? undefined : element.getAttribute("rowid");
        handleRowSortedDropChange(rowId, parseInt(draggedDatas.charAt(draggedDatas.length - 1)));
      }
    };
    const enterGrid = (e: React.DragEvent) => {
      const rows = [...Array.from(document.getElementsByClassName("rowDrag"))] as HTMLDivElement[];
      const {element} = getVerticalDragAfterElement(rows, e.clientY);
      const elementRow = element !== undefined ? element.getAttribute("rowid") : undefined;
      if (element !== undefined) {
        element.style.borderTop = "15px solid";
        element.style.borderBottom = "none";
      } else {
        rows[rows.length - 1].style.borderBottom = "15px solid";
      }
      rows.forEach(row => {
        if (elementRow !== undefined) {
          if (row.getAttribute("rowid") !== elementRow) {
            row.style.border = "none";
          }
        } else {
          if (row.getAttribute("rowid") !== rows[rows.length - 1].getAttribute("rowid")) {
            row.style.borderBottom = "none";
          } else {
            row.style.borderTop = "none";
          }
        }
      });
    };

    return {
      id: "itemContainer-builder__row-container",
      onDrop: dropGrid,
      onDragOver: (e: React.DragEvent) => e.preventDefault(),
      onDragEnter: enterGrid
    };
  };

  const getRowContainerProps = (idx: number) => {
    const enterRow = (e: React.DragEvent, id: number) => {
      e.stopPropagation();
      const rows = [...Array.from(document.getElementsByClassName("rowDrag"))] as HTMLDivElement[];
      const cols = [...Array.from(document.getElementsByClassName("colDrag"))] as HTMLDivElement[];
      if (draggingRow.current === -1) {
        const filteredCol = cols.filter(col => {
          return col.id.startsWith("colDrag_" + id);
        });
        const {element} = getHorizontalDragAfterElement(filteredCol, e.clientX);
        const elementRow = element !== undefined ? element.getAttribute("rowid") : undefined;
        const elementCol = element !== undefined ? element.getAttribute("colid") : undefined;

        if (element !== undefined) {
          element.style.borderLeft = "15px solid";
        } else {
          filteredCol[filteredCol.length - 1].style.borderRight = "15px solid";
        }

        cols.forEach(col => {
          if (element !== undefined) {
            if (col.getAttribute("rowid") !== elementRow || col.getAttribute("colid") !== elementCol) {
              col.style.border = "1px solid rgba(0, 0, 0, 0.2)";
            } else {
              col.style.borderRight = "1px solid rgba(0, 0, 0, 0.2)";
            }
          } else {
            if (
              col.getAttribute("rowid") !== rows[rows.length - 1].getAttribute("rowid") ||
              col.getAttribute("colid") !== rows[rows.length - 1].getAttribute("colid")
            ) {
              col.style.borderLeft = "1px solid rgba(0, 0, 0, 0.2)";
            } else {
              col.style.border = "1px solid rgba(0, 0, 0, 0.2)";
            }
          }
        });
      } else {
        rows.forEach(el => {
          if (el.getAttribute("rowid") === id.toString()) {
            el.style.opacity = "0.5";
            el.style.background = "#dedede";
            el.style.border = "1px solid rgba(0, 0, 0, 0.2)";
          } else {
            el.style.opacity = "1";
            el.style.background = "white";
            el.style.border = "1px solid rgba(0, 0, 0, 0.2)";
          }
        });
      }
    };

    const dropRow = (ev: React.DragEvent, newIdx: number) => {
      ev.preventDefault();
      ev.stopPropagation();
      const rows = [...Array.from(document.getElementsByClassName("rowDrag"))] as HTMLDivElement[];
      const cols = [...Array.from(document.getElementsByClassName("colDrag"))] as HTMLDivElement[];
      const draggedDatas = ev.dataTransfer.getData("data");

      rows.forEach(row => {
        row.style.background = "white";
        row.style.opacity = "1";
        row.style.pointerEvents = "auto";
        row.style.border = "none";
      });
      cols.forEach(col => {
        col.style.background = "white";
        col.style.opacity = "1";
        col.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        col.style.pointerEvents = "auto";
      });
      if (draggedDatas.startsWith("draggableRow")) {
        handleRowDropChange(newIdx, parseInt(draggedDatas.charAt(draggedDatas.length - 1)));
      } else {
        const filteredCol = cols.filter(col => {
          return col.id.startsWith("colDrag_" + newIdx);
        });
        const {element} = getHorizontalDragAfterElement(filteredCol, ev.clientX);
        const colId = element === undefined ? undefined : element.getAttribute("colid");
        handleColSortedDropChange(ev, newIdx, colId);
      }
    };

    return {
      className: "rowDrag",
      id: "itemContainer-builder__row-container",
      rowid: idx,
      onDragEnter: (e: React.DragEvent) => enterRow(e, idx),
      onDrop: (e: React.DragEvent) => dropRow(e, idx),
      onDragOver: (e: React.DragEvent) => e.preventDefault()
    };
  };

  const onDragEnd = (e: React.DragEvent, rowIdx: number, colIdx = undefined) => {
    e.stopPropagation();
    e.preventDefault();
    const rows = [...Array.from(document.getElementsByClassName("rowDrag"))] as HTMLDivElement[];
    const cols = [...Array.from(document.getElementsByClassName("colDrag"))] as HTMLDivElement[];
    const rowGrid = document.getElementById("itemContainer-builder__row-container");

    rows.forEach(row => {
      row.style.background = "white";
      row.style.opacity = "1";
      row.style.pointerEvents = "auto";
      row.style.border = "none";
    });
    cols.forEach(col => {
      col.style.background = "white";
      col.style.opacity = "1";
      col.style.pointerEvents = "auto";
      col.style.border = "1px solid rgba(0, 0, 0, 0.2)";
    });

    rowGrid.style.pointerEvents = "auto";

    if (colIdx === undefined) {
      const draggedRow = document.getElementById("draggableRow_" + rowIdx);
      draggedRow.setAttribute("draggable", "false");
    } else {
      const col = document.getElementById("draggableCol_" + rowIdx + "_" + colIdx);
      col.setAttribute("draggable", "false");
    }
    // draggingRow.current=-1;
  };

  const getRowItemProps = (rowIdx: number) => {
    const dragRow = (ev, rowIdx) => {
      draggingRow.current = rowIdx;
      ev.stopPropagation();
      ev.dataTransfer.setData("data", ev.target.id);
      const cols = [...Array.from(document.getElementsByClassName("colDrag"))] as HTMLDivElement[];
      cols.forEach(col => {
        col.style.pointerEvents = "none";
      });
    };
    return {
      className: "draggableRow",
      id: "draggableRow_" + rowIdx,
      draggable: false,
      onDragEnd: (e: React.DragEvent) => onDragEnd(e, rowIdx),
      onDragStart: (e: React.DragEvent) => dragRow(e, rowIdx)
    };
  };

  const getColContainerProps = (rowIdx: number, idx: number) => {
    const dragCol = (e, rowId, colId) => {
      draggingRow.current = -1;
      e.stopPropagation();
      e.dataTransfer.setData("data", rowIdx + "-" + colId);
      const dragStartElement = document.getElementById("colDrag_" + rowId + "_" + colId);
      dragStartElement.style.opacity = "0.2";
      const rowGrid = document.getElementById("itemContainer-builder__row-container");
      rowGrid.style.pointerEvents = "none";
    };

    return {
      className: "draggableCol",
      id: "draggableCol_" + rowIdx + "_" + idx,
      draggable: false,
      onDragStart: (e: React.DragEvent) => dragCol(e, rowIdx, idx),
      onDragEnd: (e: React.DragEvent) => onDragEnd(e, rowIdx, idx)
    };
  };

  const getColItemProps = (rowIdx: number, colIdx: number) => {
    const enterCol = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const rows = [...Array.from(document.getElementsByClassName("rowDrag"))] as HTMLDivElement[];
      const unselectedCols = [...Array.from(document.getElementsByClassName("colDrag"))] as HTMLDivElement[];
      unselectedCols.forEach(el => {
        if (el.id === "colDrag_" + rowIdx + "_" + colIdx) {
          el.style.opacity = "0.5";
          el.style.background = "#dedede";
          el.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        } else {
          el.style.opacity = "1";
          el.style.background = "white";
          el.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        }
      });
      rows.forEach(el => {
        el.style.opacity = "1";
        el.style.background = "white";
      });
    };

    const dropCol = (ev, rowIdx, newIdx) => {
      ev.preventDefault();
      if (!ev.dataTransfer.getData("data").startsWith("draggableRow")) {
        ev.stopPropagation();
        const draggedDatas = ev.dataTransfer.getData("data").split("-");
        let newItemContainer = _.cloneDeep(itemContainer);
        let tmpItemContainer = itemContainer;
        newItemContainer.item[draggedDatas[0]][draggedDatas[1]] = tmpItemContainer.item[rowIdx][newIdx];
        newItemContainer.item[rowIdx][newIdx] = tmpItemContainer.item[draggedDatas[0]][draggedDatas[1]];
        handleResizeItems(newItemContainer);
        dispatch(changeOtherConfigItemContainer(newItemContainer));
      }
    };

    return {
      className: "colDrag",
      id: "colDrag_" + rowIdx + "_" + colIdx,
      onDragEnter: (e: React.DragEvent) => enterCol(e),
      onDrop: (e: React.DragEvent) => dropCol(e, rowIdx, colIdx),
      onDragOver: (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
      }
    };
  };

  const getRowMoveCursorProps = (rowIdx: number) => {
    const onMouseDown = (rowIdx: number) =>
      document.getElementById("draggableRow_" + rowIdx).setAttribute("draggable", "true");
    return {
      onMouseDown: () => onMouseDown(rowIdx),
      style: {cursor: "move"}
    };
  };

  const getColMoveCursorProps = (rowIdx: number, colIdx: number) => {
    const onMouseDown = (rowIdx: number, colIdx: number) =>
      document.getElementById("draggableCol_" + rowIdx + "_" + colIdx).setAttribute("draggable", "true");
    return {
      onMouseDown: () => onMouseDown(rowIdx, colIdx),
      style: {cursor: "move"}
    };
  };

  return {
    asDashboard,
    handleFilterChange,
    handleFilterLevelsChange,
    handleColAdd,
    handleColRemove,
    handleEnableFiltersChange,
    handleFilterDimensionChange,
    handleShowTitleChange,
    handleRowAdd,
    handleRowRemove,
    handleTypeReset,
    handleCategoryChange,
    handleCategoryOptionChange,
    handleViewReset,
    handleTextChange,
    handleViewSet,
    getGridContainerProps,
    getRowContainerProps,
    getRowItemProps,
    getColContainerProps,
    getColItemProps,
    getRowMoveCursorProps,
    getColMoveCursorProps
  };
};
