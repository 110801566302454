import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {CategoryOptionForm} from "../item-container-categories-list/CategoryOptionForm";
import {getAgencyAndCategoryFromFullId, getPathToSelectedCategory} from "../../utils/catalog";

const CategoryItem = ({
  item,
  rowIdx,
  colIdx,
  onEditClick
}: {
  item: ItemCategoryTemplateDto;
  rowIdx: number;
  colIdx: number;
  onEditClick: () => void;
}) => {
  const {categoryId, agencyId} = getAgencyAndCategoryFromFullId(item.category);
  const agency = item.catalog?.categoryGroups.find(agency => agency.id === agencyId);
  const selectedCategories = getPathToSelectedCategory(categoryId, agency).map(v => v.label);

  return (
    <>
      <IconButton onClick={onEditClick}>
        <EditIcon />
      </IconButton>
      <br />
      <span className="itemContainer_category-item_selected-categories-label">
        {[...(agency?.label ? [agency.label] : []), ...selectedCategories].join(" -> ")}
      </span>
      <Box my={2} className="itemContainer_category-item_option-form-container">
        <CategoryOptionForm item={item} rowIdx={rowIdx} colIdx={colIdx} />
      </Box>
    </>
  );
};

export default CategoryItem;
