import {getDeleteNodeDatasetDetailsLevels} from "../../serverApi/urls";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

export const DETAIL_LEVEL_SET = "detailLevel/set";
export const DETAIL_LEVEL_FILTERED_CATALOG_SET = "detailLevel/setFilteredCatalog";
export const DETAIL_LEVELS_DELETE = "detailLevels/delete";

export const setDetailLevel = (detailLevel: number) => ({
  type: DETAIL_LEVEL_SET,
  payload: {
    detailLevel
  }
});

export const setDetailLevelFilteredCatalog = (filteredCatalog: any) => ({
  type: DETAIL_LEVEL_FILTERED_CATALOG_SET,
  payload: {
    filteredCatalog
  }
});

export const deleteDetailLevels = (nodeId: number, dataflowIds: Array<string>, baseUrl: string) =>
  initRequest(
    DETAIL_LEVELS_DELETE,
    getDeleteNodeDatasetDetailsLevels(nodeId),
    RequestMethod.DELETE,
    dataflowIds,
    undefined,
    undefined,
    baseUrl
  );
