import React from "react";
import {Box, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {configSelector} from "../../state/config/configSelectors";

interface CategoryTitleSectionProps {
  imageUrl: string;
  description: string;
  label: string;
}

const CategoryTitleSection = ({imageUrl, description, label}: CategoryTitleSectionProps) => {
  const baseUrl = useSelector(configSelector).baseURL;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "12px 0 12px 8px",
          paddingLeft: imageUrl ? 0 : "8px"
        }}
      >
        {imageUrl && (
          <Box
            component="img"
            id="category_title_section_image"
            src={baseUrl + imageUrl}
            alt="selected category"
            className={"item_container_category_preview_title_section_image"}
            sx={{
              mr: "16px",
              height: "100px"
            }}
          />
        )}
        <Typography
          component="h2"
          id="category_title_section_title"
          className={"item_container_category_preview_title_section_title"}
          sx={{
            fontSize: "32px"
          }}
        >
          {label}
        </Typography>
      </Box>
      {description && (
        <Typography
          component="p"
          id="category_title_section_description"
          className={"item_container_category_preview_title_section_description"}
          sx={{
            fontSize: "20px",
            padding: "0px 8px 4px 8px",
            marginTop: "-8px",
            marginBottom: "0px"
          }}
        >
          {description}
        </Typography>
      )}
    </>
  );
};

export default CategoryTitleSection;
