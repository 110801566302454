import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {withTranslation} from "react-i18next";
import {connect, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {compose} from "redux";
import {getNodeInternalUrl, goToCustomPage, goToNodeCategories, goToNodeDashboards} from "../../links";
import {NodeVisibility} from "../../model/IHubMinimalNode.d.ts";
import {ConfigNodeMenuId} from "../../model/item-containers-models/configNodeMenuId";
import CardsGrid from "../cards-grid";
import ComponentVariantLoader from "../component-variant-loader";
import Footer from "../footer";
import Header from "../header";
import Hero from "../hero";
import ModulesPlaceholder from "../modules-placeholder";
import Page from "../page";
import PageSection from "../page-section";
import SanitizedHTML from "../sanitized-html";
import useLanguages from "../../state/hooks/useLanguages";
import {nodeCustomPagesSelector} from "../../state/node/nodeSelectors";
import {openSearchDialog} from "../../state/search-dialog/searchDialogActions";
import {WINDOW_SCREEN_WIDTH_MD} from "../../utils/constants";
import {getCustomPagesInMenu} from "../../utils/itemContainers";
import {getPageTitle} from "../../utils/other";
import themeConfig from "../../theme-config/config.json";

const mapStateToProps = state => ({
  isA11y: state.app.isA11y,
  baseURL: state.config.baseURL,
  hub: state.hub,
  node: state.node,
  catalog: state.catalog,
  modulesConfig: state.app.modulesConfig
});

const mapDispatchToProps = dispatch => ({
  onSearch: () => dispatch(openSearchDialog())
});

const scrollToElement = elementId => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView();
  }
};

const Node = ({t, isA11y, baseURL, hub, nodeCode, isDefault, node, catalog, onSearch, modulesConfig}) => {
  const location = useLocation();
  const {localizeI18nObj} = useLanguages();
  const theme = useTheme();

  const sectionStyle = {
    paddingTop: theme.spacing(3),
    scrollMargin: "80px"
  };

  const nodesContainerStyle = {
    marginTop: theme.spacing(2)
  };

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  useEffect(() => {
    const setSearchButtonVisibility = () => {
      setIsSearchVisible(window.innerWidth > WINDOW_SCREEN_WIDTH_MD);
    };
    setSearchButtonVisibility();
    window.addEventListener("resize", setSearchButtonVisibility);
    return () => window.removeEventListener("resize", setSearchButtonVisibility);
  }, []);

  const nodeMinimalInfo = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());

  const otherNodes = hub.nodes.filter(
    n => n.code.toLowerCase() !== nodeCode.toLowerCase() && n.visible !== NodeVisibility.No
  );

  const params = new URLSearchParams(location.search);
  params.set("accessible", "true");
  const paramsStr = params.toString();
  const path = location.pathname;

  const nodeCustomPages = useSelector(nodeCustomPagesSelector);
  const customPagesInCentralMenu = getCustomPagesInMenu(nodeCustomPages, ConfigNodeMenuId.CENTRAL);

  const hasDashboards = useMemo(() => {
    if (isA11y) {
      return false;
    } else {
      return (
        (
          node?.itemContainers?.dashboard?.uncategorizedDashboards?.concat(
            node?.itemContainers?.dashboard?.categorizedDashboards
          ) ?? []
        ).length > 0
      );
    }
  }, [isA11y, node]);

  return (
    <Fragment>
      {!isA11y && (
        <a
          href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
          target="_self"
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.accessible")}
        </a>
      )}
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      {node && node.description && node.description.length > 0 && (
        <HashLink to={{hash: "#node-information", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.nodeInformation")}
        </HashLink>
      )}
      {isDefault && otherNodes.length > 0 && (
        <HashLink to={{hash: "#other-nodes", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.otherNodes")}
        </HashLink>
      )}
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page
        id={isDefault ? "landing-page" : "node-page"}
        title={getPageTitle([nodeMinimalInfo?.name, hub?.hub?.name], t)}
      >
        {themeConfig.componentsVariant?.["node-home"] ? (
          <ComponentVariantLoader
            componentId={"node-home"}
            fallback={<Box />}
            node={node}
            nodeCode={nodeCode}
            hub={hub}
            catalog={catalog}
            baseURL={baseURL}
            isDefault={isDefault}
            isA11y={isA11y}
            onSearch={onSearch}
            modulesConfig={modulesConfig}
          />
        ) : (
          <Fragment>
            <Header isDefault={isDefault} />
            <Box
              component="main"
              sx={{
                width: "100%",
                height: "100%"
              }}
              id="main"
              className={`node`}
            >
              <Hero
                title={nodeMinimalInfo.name}
                slogan={node && node.slogan}
                logo={node && nodeMinimalInfo.logoURL && <img src={baseURL + nodeMinimalInfo.logoURL} alt="" />}
                background={
                  nodeMinimalInfo.backgroundMediaURL ? (
                    nodeMinimalInfo.backgroundMediaURL.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG|svg|SVG)$/) ? (
                      <div
                        style={{
                          background: `url("${
                            baseURL + nodeMinimalInfo.backgroundMediaURL
                          }") center center / cover no-repeat`
                        }}
                      />
                    ) : (
                      <video autoPlay muted loop>
                        <source src={baseURL + nodeMinimalInfo.backgroundMediaURL} />
                      </video>
                    )
                  ) : (
                    <div
                      style={{
                        background: `url("./images/default-node-background.jpg") center center / cover no-repeat`
                      }}
                    />
                  )
                }
                showExtraLogos={isDefault}
              >
                <Grid container justifyContent="center" spacing={2}>
                  {node && node.description && node.description.length > 0 && (
                    <Grid item id="infos-btn">
                      <Button
                        id="node_home_infos_btn"
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={() => scrollToElement("node-information")}
                      >
                        {t("scenes.node.informations")}
                      </Button>
                    </Grid>
                  )}
                  {catalog && !catalog.isEmpty && (
                    <Grid item id="explore-btn">
                      <Button
                        id="node_home_categories_btn"
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={() => goToNodeCategories(nodeCode)}
                      >
                        {t("scenes.node.explore")}
                      </Button>
                    </Grid>
                  )}
                  {hasDashboards && (
                    <Grid item id="dashboards-btn">
                      <Button
                        id="node_home_dashboards_btn"
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={() => goToNodeDashboards(nodeCode)}
                      >
                        {t("scenes.node.dashboards")}
                      </Button>
                    </Grid>
                  )}
                  {isDefault && otherNodes.length > 0 && (
                    <Grid item id="other-nodes-btn">
                      <Button
                        id="node_home_other_nodes_btn"
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={() => scrollToElement("other-nodes")}
                      >
                        {t("scenes.node.otherNodes")}
                      </Button>
                    </Grid>
                  )}
                  <ModulesPlaceholder
                    id="node-home-buttons"
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignContent: "center"
                    }}
                  />
                  {catalog && !catalog.isEmpty && isSearchVisible && (
                    <Grid item id="search-btn">
                      <Button
                        id="node_home_search_btn"
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={onSearch}
                      >
                        {t("scenes.node.search")}
                      </Button>
                    </Grid>
                  )}
                  {!isA11y &&
                    modulesConfig.modules.includes("custom-page") &&
                    customPagesInCentralMenu.map(v => (
                      <Grid item key={v.id}>
                        <Button
                          id="customPages-btn"
                          size="large"
                          color="secondary"
                          variant="contained"
                          onClick={() => goToCustomPage(v.id, node?.code)}
                        >
                          {localizeI18nObj(v.title)}
                        </Button>
                      </Grid>
                    ))}
                </Grid>
              </Hero>
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  width: "100%"
                }}
              >
                <Container sx={{paddingTop: theme => theme.spacing(3)}} id="section-container">
                  {node && node.description && node.description.length > 0 && (
                    <Box id="description-section">
                      <PageSection
                        id="node-information"
                        className={"node-information"}
                        sx={sectionStyle}
                        sectiontitle={t("scenes.node.informations")}
                      >
                        <Box textAlign="justify">
                          <SanitizedHTML html={node.description} allowTarget />
                        </Box>
                      </PageSection>
                    </Box>
                  )}
                  <ModulesPlaceholder id="node-home-sections" sx={sectionStyle} />
                  {isDefault && otherNodes.length > 0 && (
                    <Box id="other-nodes-section">
                      <PageSection
                        id="other-nodes"
                        className="other-nodes"
                        sx={sectionStyle}
                        sectiontitle={t("scenes.node.otherNodes")}
                      >
                        <Box sx={nodesContainerStyle}>
                          <nav aria-label={t("scenes.node.otherNodes")}>
                            <CardsGrid
                              list={otherNodes
                                .sort((a, b) => a.order - b.order)
                                .map(({code, name, backgroundMediaURL}) => ({
                                  code,
                                  id: code,
                                  label: name,
                                  image: backgroundMediaURL
                                    ? baseURL + backgroundMediaURL
                                    : "./images/default-node-background.jpg"
                                }))}
                              getHref={node => getNodeInternalUrl(node?.code)}
                              {...themeConfig.nodesGridProps}
                            />
                          </nav>
                        </Box>
                      </PageSection>
                    </Box>
                  )}
                </Container>
                <Footer />
              </Box>
            </Box>
          </Fragment>
        )}
      </Page>
    </Fragment>
  );
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Node);
