import {PayloadAction} from "@reduxjs/toolkit";
import moment from "moment";
import {Reducer} from "redux";
import {INIT} from "../rootActions";
import {APP_IS_A11Y_SET, APP_LANGUAGE_SET} from "./appActions";

export type ThemeConfig = {
  isMultiViewer: boolean;
  enableOptimization: boolean;
  showDatasetListInCategoriesTree: boolean;
  showLangsAsText: boolean;
  defaultFreq: string | null;
  nodesGridProps: {[key: string]: number};
  CatalogPortionPreviewProps: {[key: string]: number};
  defaultCriteriaSelectionMode: string | null;
  componentsVariant: {[key: string]: string};
  configsToMerge: string[];
  appConfig: {[key: string]: any};
};

export type AppState = {
  language: string;
  languages: string[];
  isA11y: boolean;
  modulesConfig: any;
  themeConfig: ThemeConfig;
} | null;

const appReducer: Reducer<AppState, PayloadAction<any>> = (state = null, action) => {
  switch (action.type) {
    case INIT:
      const initialState = {
        language: action.payload.defaultLanguage,
        languages: action.payload.supportedLanguages,
        isA11y: false,
        modulesConfig: action.payload.modulesConfig,
        themeConfig: action.payload.themeConfig
      };
      sessionStorage.setItem("appLanguage", action.payload.defaultLanguage);
      sessionStorage.setItem("appLanguages", action.payload.supportedLanguages);
      moment.locale(action.payload.defaultLanguage);
      return initialState;
    case APP_LANGUAGE_SET: {
      sessionStorage.setItem("appLanguage", action.payload.language);
      moment.locale(action.payload.language);
      return {
        ...state,
        language: action.payload.language
      };
    }
    case APP_IS_A11Y_SET: {
      return {
        ...state,
        isA11y: action.payload.isA11y
      };
    }
    default:
      return state;
  }
};

export default appReducer;
