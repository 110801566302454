import moment from "moment";

moment.fn.customFormat = function (stringFormat) {
  if (stringFormat.includes("Sm")) {
    const quarter = this.format("YYYY-[Q]Q");
    const YYYY_S_REGEX = /(\d{4})-(Q)([1-4])/;
    if (YYYY_S_REGEX.test(quarter)) {
      const m = quarter.match(YYYY_S_REGEX);
      const s = stringFormat
        .replace("[S]", "S")
        .replace("Sm", m[3] > 2 ? 2 : 1)
        .replace("YYYY", m[1]);
      return s;
    } else {
      return quarter;
    }
  } else {
    return this.format(stringFormat);
  }
};
