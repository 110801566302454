import React from "react";
import {Box} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import {getAttributeLabel, getAttributeValueLabel} from "../../utils/dataset";

const cardTitleStyle = {
  fontSize: "17px",
  fontWeight: "bold"
};

const Attribute = ({attribute, labelFormat, list}) => (
  <Grid item xs={12}>
    {(list ? "- " : "") +
      getAttributeLabel(attribute, labelFormat) +
      ": " +
      getAttributeValueLabel(attribute, labelFormat)}
  </Grid>
);

function AttributeList(props) {
  const {datasetAttributes, seriesAttributes, labelFormat} = props;

  const {t} = useTranslation();

  return (
    <div>
      {(datasetAttributes || []).length > 0 && (
        <Card
          variant="outlined"
          sx={{
            background: "#F5F5F5"
          }}
        >
          <CardHeader
            title={<Box sx={cardTitleStyle}>{t("components.attributeList.datasetInformation.title")}:</Box>}
            disableTypography
          />
          <Grid
            container
            spacing={2}
            sx={{
              margin: "0px",
              padding: "0 16px 16px"
            }}
          >
            {datasetAttributes.map((attribute, idx) => (
              <Attribute key={idx} attribute={attribute} labelFormat={labelFormat} />
            ))}
          </Grid>
        </Card>
      )}
      {(seriesAttributes || []).length > 0 && (
        <Card variant="outlined" sx={{marginTop: "16px"}}>
          <CardHeader
            title={<Box sx={cardTitleStyle}>{t("components.attributeList.seriesInformation.title")}:</Box>}
            disableTypography
          />
          <Grid
            container
            spacing={2}
            sx={{
              margin: "0px",
              padding: "0 0 16px"
            }}
          >
            {seriesAttributes.map(({dimensions, attributes}, idx) => (
              <Grid
                container
                key={idx}
                spacing={2}
                sx={{
                  margin: "0px",
                  padding: "0 16px",
                  background: idx % 2 ? "#FFFFFF" : "#F5F5F5"
                }}
              >
                <Grid item xs={12}>
                  {dimensions.map(({id, value}, idx) => (
                    <Box key={idx} sx={{display: "inline-block", marginRight: "8px"}}>
                      <b>{id}</b>: <i>{value}</i>
                      {idx < dimensions.length - 1 ? "," : ""}
                    </Box>
                  ))}
                </Grid>
                {attributes.map((attribute, idx) => (
                  <Attribute key={idx} attribute={attribute} labelFormat={labelFormat} list />
                ))}
              </Grid>
            ))}
          </Grid>
        </Card>
      )}
    </div>
  );
}

export default AttributeList;
