import React, {useMemo} from "react";
import DatasetStaticFilters from "./DatasetStaticFilters";
import {getFormattedDimensionValueLabelFromCodelists} from "../../utils/dataset";

const DatasetStaticFiltersCodelistsWrapper = ({
  nodeCode,
  datasetId,
  dimensionsInfo,
  timeDim,
  codelistMaps,
  jsonStat,
  layout,
  labelFormat,
  isDimensionAllowed,
  localizedTimePeriodFormatMapExternal
}) => {
  const values = useMemo(() => {
    if (!layout || !codelistMaps) {
      return [];
    } else {
      const filtersValue = layout?.filtersValue;
      const filterDims = layout.filters.filter(dim => !isDimensionAllowed || isDimensionAllowed(dim));
      return filterDims.map(dim => ({
        dim: dim,
        dimLabel: dimensionsInfo?.[dim]?.label,
        value: filtersValue[dim],
        valueLabel: getFormattedDimensionValueLabelFromCodelists(
          dim,
          filtersValue[dim],
          codelistMaps,
          timeDim,
          labelFormat,
          localizedTimePeriodFormatMapExternal
        )
      }));
    }
  }, [
    layout,
    codelistMaps,
    labelFormat,
    isDimensionAllowed,
    dimensionsInfo,
    timeDim,
    localizedTimePeriodFormatMapExternal
  ]);

  return (
    layout && (
      <DatasetStaticFilters
        datasetId={`${nodeCode},${datasetId}`}
        values={values}
        jsonStat={jsonStat}
        labelFormat={labelFormat}
        localizedTimePeriodFormatMapExternal={localizedTimePeriodFormatMapExternal}
      />
    )
  );
};

export default DatasetStaticFiltersCodelistsWrapper;
