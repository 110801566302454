import moment from "moment";
import {CRITERIA_FILTER_TYPE_RANGE} from "./criteria";

export const FREQ_ANNUAL = "A";
export const FREQ_SEMESTER = "S";
export const FREQ_QUARTERLY = "Q";
export const FREQ_MONTHLY = "M";
export const FREQ_DAILY = "D";
export const SUPPORTED_FREQ_VALUES = [FREQ_DAILY, FREQ_MONTHLY, FREQ_QUARTERLY, FREQ_SEMESTER, FREQ_ANNUAL];

export const getPeriodsFromYear = year => ({
  [FREQ_SEMESTER]: {
    values: ["S1", "S2"],
    S1: {
      start: `${year}-01-01`,
      end: moment(`${year}-06`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    S2: {
      start: `${year}-07-01`,
      end: moment(`${year}-12`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    }
  },
  [FREQ_QUARTERLY]: {
    values: ["Q1", "Q2", "Q3", "Q4"],
    Q1: {
      start: `${year}-01-01`,
      end: moment(`${year}-03`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    Q2: {
      start: `${year}-04-01`,
      end: moment(`${year}-06`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    Q3: {
      start: `${year}-07-01`,
      end: moment(`${year}-09`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    Q4: {
      start: `${year}-10-01`,
      end: moment(`${year}-12`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    }
  },
  [FREQ_MONTHLY]: {
    values: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
    "01": {
      start: `${year}-01-01`,
      end: moment(`${year}-01`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    "02": {
      start: `${year}-02-01`,
      end: moment(`${year}-02`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    "03": {
      start: `${year}-03-01`,
      end: moment(`${year}-03`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    "04": {
      start: `${year}-04-01`,
      end: moment(`${year}-04`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    "05": {
      start: `${year}-05-01`,
      end: moment(`${year}-05`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    "06": {
      start: `${year}-06-01`,
      end: moment(`${year}-06`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    "07": {
      start: `${year}-07-01`,
      end: moment(`${year}-07`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    "08": {
      start: `${year}-08-01`,
      end: moment(`${year}-08`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    "09": {
      start: `${year}-09-01`,
      end: moment(`${year}-09`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    10: {
      start: `${year}-10-01`,
      end: moment(`${year}-10`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    11: {
      start: `${year}-11-01`,
      end: moment(`${year}-11`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    },
    12: {
      start: `${year}-12-01`,
      end: moment(`${year}-12`, "YYYY-MM").endOf("month").format("YYYY-MM-DD")
    }
  }
});

const getDefaultMaxPeriod = freq => {
  if (freq === FREQ_ANNUAL) {
    return moment().add(-1, "y").format("YYYY-MM-DD");
  } else if (freq === FREQ_SEMESTER) {
    return moment().add(-6, "M").format("YYYY-MM-DD");
  } else if (freq === FREQ_QUARTERLY) {
    return moment().add(-3, "M").format("YYYY-MM-DD");
  } else if (freq === FREQ_MONTHLY) {
    return moment().add(-1, "M").format("YYYY-MM-DD");
  } else {
    return moment().add(-1, "d").format("YYYY-MM-DD");
  }
};

export const getMinAndMax = (timePeriodCodes, freq, nodeConfig) => {
  const nodeExtras = nodeConfig?.extras || [];
  const nodeConfigMin = nodeExtras.find(({key}) => key === "TimePeriodRangeStart")?.value || null;
  const nodeConfigMax = nodeExtras.find(({key}) => key === "TimePeriodRangeEnd")?.value || null;

  let min = nodeConfigMin || moment().add(-20, "y").format("YYYY") + "-01-01";
  let max = nodeConfigMax || getDefaultMaxPeriod(freq);

  return {
    min:
      timePeriodCodes && timePeriodCodes[0] && timePeriodCodes[0].id
        ? moment(timePeriodCodes[0].id).format("YYYY-MM-DD")
        : min,
    max:
      timePeriodCodes && timePeriodCodes[1] && timePeriodCodes[1].id
        ? moment(timePeriodCodes[1].id).format("YYYY-MM-DD")
        : max,
    missingRange: !timePeriodCodes || timePeriodCodes.length !== 2
  };
};

export const getTimePeriod = (
  initialTimePeriod,
  criteria,
  timeDim,
  timeDimCodes,
  freqDim,
  freqDimCodes,
  nodeConfig
) => {
  const freq = getFreqValueFromCriteria(criteria, freqDim, freqDimCodes, initialTimePeriod?.freq);

  const minAndMax = timeDimCodes
    ? getMinAndMax(timeDimCodes, freq, nodeConfig)
    : {
        min: initialTimePeriod.minDate,
        max: initialTimePeriod.maxDate,
        missingRange: initialTimePeriod.missingRange
      };

  return {
    freq: freq,
    selectorType: criteria?.[timeDim]?.type || initialTimePeriod.selectorType || CRITERIA_FILTER_TYPE_RANGE,
    minDate: minAndMax.min,
    maxDate: minAndMax.max,
    fromDate:
      criteria?.[timeDim]?.from && criteria?.[timeDim]?.from > minAndMax.min
        ? criteria?.[timeDim]?.from
        : minAndMax.min,
    toDate:
      criteria?.[timeDim]?.to && criteria?.[timeDim]?.to < minAndMax.max ? criteria?.[timeDim]?.to : minAndMax.max,
    periods: criteria?.[timeDim]?.period || 1,
    missingRange: minAndMax.missingRange
  };
};

export const getFreqValueFromTimeValue = timeValue => {
  const dRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  const mRegex = /^[0-9]{4}-[0-9]{2}$/;
  const qRegex = /^[0-9]{4}-Q[1-4]$/i;
  const sRegex = /^[0-9]{4}-S[1-2]$/i;
  const aRegex = /^[0-9]{4}$/;

  if (dRegex.test(timeValue)) {
    return FREQ_DAILY;
  } else if (mRegex.test(timeValue)) {
    return FREQ_MONTHLY;
  } else if (qRegex.test(timeValue)) {
    return FREQ_QUARTERLY;
  } else if (sRegex.test(timeValue)) {
    return FREQ_SEMESTER;
  } else if (aRegex.test(timeValue)) {
    return FREQ_ANNUAL;
  } else {
    return null;
  }
};

const getFormatFromFreq = freq => {
  if (freq === FREQ_ANNUAL) {
    return "YYYY";
  } else if (freq === FREQ_SEMESTER) {
    return undefined;
  } else if (freq === FREQ_QUARTERLY) {
    return "YYYY-[Q]Q";
  } else if (freq === FREQ_MONTHLY) {
    return "YYYY-MM";
  } else if (freq === FREQ_DAILY) {
    return "YYYY-MM-DD";
  } else {
    return undefined;
  }
};

export const getMoment = (value, format) => {
  const YYYY_S_REGEX = /(\d{4})-S([1,2])/;
  if (moment.isMoment(value)) {
    return value;
  } else if (YYYY_S_REGEX.test(value)) {
    const quarterValue = value.replace(
      YYYY_S_REGEX,
      (match, year, semester) => `${year}-Q${semester === "1" ? "1" : "3"}`
    );
    return moment(quarterValue, "YYYY-Q");
  } else if (format) {
    return moment(value, format);
  } else {
    const _freq = getFreqValueFromTimeValue(value);
    const _format = getFormatFromFreq(_freq);
    return moment(value, _format);
  }
};

export const getTimeValuesByFreq = (timeValues, freqValue) =>
  timeValues.filter(timeValue => getFreqValueFromTimeValue(timeValue) === freqValue);

export const getFreqValueFromCriteria = (criteria, freqDim, freqDimCodes, initialFreqValue) => {
  const defaultFreq = initialFreqValue || FREQ_DAILY;

  if (!freqDim) {
    return defaultFreq;
  }

  const codelistFreqValues = (freqDimCodes ?? []).map(({id}) => id);

  const criteriaFreqValues = (criteria?.[freqDim]?.filterValues ?? []).length > 0 ? criteria[freqDim].filterValues : [];

  if (codelistFreqValues.length === 0 && criteriaFreqValues.length === 0) {
    return defaultFreq;
  }

  let availableFreqValues = [...SUPPORTED_FREQ_VALUES];

  if (codelistFreqValues.length > 0) {
    availableFreqValues = availableFreqValues.filter(freq => codelistFreqValues.includes(freq));
  }

  if (criteriaFreqValues.length > 0) {
    availableFreqValues = availableFreqValues.filter(freq => criteriaFreqValues.includes(freq));
  }

  return availableFreqValues?.[0] ?? defaultFreq;
};

export const getFreqValueFromLayout = (layout, freqDim, codelistLists) => {
  const {
    filters = [],
    filtersValue = {},
    primaryDim = [],
    primaryDimValues = [],
    secondaryDim = [],
    secondaryDimValues = []
  } = layout;

  if (!codelistLists[freqDim]) {
    return FREQ_ANNUAL;
  } else if (!layout) {
    return SUPPORTED_FREQ_VALUES.find(freq => codelistLists[freqDim].includes(freq)) || FREQ_ANNUAL;
  } else if (filters.includes(freqDim)) {
    return filtersValue[freqDim];
  } else if (primaryDim.includes(freqDim)) {
    return SUPPORTED_FREQ_VALUES.find(freq => primaryDimValues.includes(freq)) || FREQ_ANNUAL;
  } else if (secondaryDim.includes(freqDim)) {
    return SUPPORTED_FREQ_VALUES.find(freq => secondaryDimValues.includes(freq)) || FREQ_ANNUAL;
  } else {
    // rows, cols, sections
    return SUPPORTED_FREQ_VALUES.find(freq => codelistLists[freqDim].includes(freq)) || FREQ_ANNUAL;
  }
};

export const getTimeValuesFromRange = (availableFreqs, startDate, endDate) => {
  const values = new Set();

  const startDateMoment = getMoment(startDate);
  const endDateMoment = getMoment(endDate).startOf("d");

  const daysDiff = endDateMoment.diff(startDateMoment, "days");

  for (let i = daysDiff; i >= 0; i--) {
    if (availableFreqs.includes(FREQ_ANNUAL)) {
      values.add(endDateMoment.format("YYYY"));
    }
    if (availableFreqs.includes(FREQ_SEMESTER)) {
      if (endDateMoment.month() >= 0 && endDateMoment.month() <= 5) {
        values.add(endDateMoment.format("YYYY") + "-S1");
      } else {
        values.add(endDateMoment.format("YYYY") + "-S2");
      }
    }
    if (availableFreqs.includes(FREQ_QUARTERLY)) {
      if (endDateMoment.month() >= 0 && endDateMoment.month() <= 2) {
        values.add(endDateMoment.format("YYYY") + "-Q1");
      } else if (endDateMoment.month() >= 3 && endDateMoment.month() <= 5) {
        values.add(endDateMoment.format("YYYY") + "-Q2");
      } else if (endDateMoment.month() >= 6 && endDateMoment.month() <= 8) {
        values.add(endDateMoment.format("YYYY") + "-Q3");
      } else {
        values.add(endDateMoment.format("YYYY") + "-Q4");
      }
    }
    if (availableFreqs.includes(FREQ_MONTHLY)) {
      values.add(endDateMoment.format("YYYY-MM"));
    }
    if (availableFreqs.includes(FREQ_DAILY)) {
      values.add(endDateMoment.format("YYYY-MM-DD"));
    }

    endDateMoment.subtract(1, "d");
  }

  return Array.from(values).reverse();
};
