import {PayloadAction} from "@reduxjs/toolkit";
import {Middleware} from "redux";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../request/requestActions";
import {ViewerMode} from "../../state/dataset/constants";
import {DATASET_STRUCTURE_FETCH} from "../../state/dataset/datasetActions";
import {
  fetchDatasetMVPStructureError,
  fetchDatasetMVPStructureInit,
  fetchDatasetMVPStructureSuccess
} from "../../state/dataset/multi-viewer-plus/actions";
import {
  fetchDatasetMVStructureError,
  fetchDatasetMVStructureInit,
  fetchDatasetMVStructureSuccess
} from "../../state/dataset/multi-viewer/actions";
import {
  fetchDatasetSVPStructureError,
  fetchDatasetSVPStructureInit,
  fetchDatasetSVPStructureSuccess
} from "../../state/dataset/single-viewer-plus/actions";
import {
  fetchDatasetSVStructureError,
  fetchDatasetSVStructureInit,
  fetchDatasetSVStructureSuccess
} from "../../state/dataset/single-viewer/actions";
import {getDatasetStorageKey} from "../../utils/other";

const fetchStructureHandlerMiddleware: Middleware =
  ({getState, dispatch}) =>
  next =>
  (action: PayloadAction<any>) => {
    const result = next(action);

    if (action?.payload?.label === DATASET_STRUCTURE_FETCH) {
      const datasetState = getState().dataset.commons;
      if (action.type === REQUEST_INIT) {
        dispatch(fetchDatasetSVStructureInit());
        dispatch(fetchDatasetSVPStructureInit());
        dispatch(fetchDatasetMVStructureInit());
        dispatch(fetchDatasetMVPStructureInit());
      } else if (action.type === REQUEST_SUCCESS) {
        const datasetUuid = getDatasetStorageKey(datasetState);
        const viewerMode = datasetState.viewerMode;

        if (viewerMode === ViewerMode.SingleViewer) {
          dispatch(fetchDatasetSVStructureSuccess(datasetUuid, action.payload.response, datasetState.viewId));
        } else if (viewerMode === ViewerMode.SingleViewerPlus) {
          dispatch(fetchDatasetSVPStructureSuccess(datasetUuid, action.payload.response, datasetState.viewId));
        } else if (viewerMode === ViewerMode.MultiViewer) {
          dispatch(fetchDatasetMVStructureSuccess(datasetUuid, action.payload.response, datasetState.viewId));
        } else if (viewerMode === ViewerMode.MultiViewerPlus) {
          dispatch(fetchDatasetMVPStructureSuccess(datasetUuid, action.payload.response, datasetState.viewId));
        }
      } else if (action.type === REQUEST_ERROR) {
        dispatch(fetchDatasetSVStructureError());
        dispatch(fetchDatasetSVPStructureError());
        dispatch(fetchDatasetMVStructureError());
        dispatch(fetchDatasetMVPStructureError());
      }
    }

    return result;
  };

export default fetchStructureHandlerMiddleware;
