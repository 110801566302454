import React, {Fragment, useEffect} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import SearchDialog from "../components/search-dialog";
import Call from "../hocs/call";
import {goToDatasetsSearch, goToHome} from "../links";
import {clearCatalog, fetchCatalog} from "../state/catalog/catalogActions";
import {clearNode, fetchNode, fetchNodeItemContainers} from "../state/node/nodeActions";
import {showUserLoginForm} from "../state/user/userActions";
import {getIsUserAuthenticated} from "../utils/user";

const mapStateToProps = state => ({
  hub: state.hub,
  node: state.node,
  catalog: state.catalog,
  user: state.user,
  geoglossaryBaseUrl: state.config.externalServices?.geoglossary,
  isMultiViewerTheme: state.app.themeConfig.isMultiViewer,
  modulesConfig: state.app.modulesConfig
});

const mapDispatchToProps = dispatch => ({
  fetchNode: ({nodeId, nodeCode}) => dispatch(fetchNode(nodeId, nodeCode)),
  clearNode: () => dispatch(clearNode()),
  fetchCatalog: ({nodeId, nodeCode, baseUrl}) => dispatch(fetchCatalog(nodeId, nodeCode, baseUrl)),
  clearCatalog: () => dispatch(clearCatalog()),
  onLoginShow: () => dispatch(showUserLoginForm("NotAccessibleNode", "warning", true)),
  fetchNodeDashboards: ({nodeId}) => dispatch(fetchNodeItemContainers(nodeId, "dashboard")),
  fetchNodeCustomPages: ({nodeId}) => dispatch(fetchNodeItemContainers(nodeId, "customPages"))
});

const NodeDomain = ({
  nodeCode: externalNodeCode,
  hub,
  node,
  catalog,
  user,
  fetchNode,
  clearNode,
  fetchCatalog,
  clearCatalog,
  fetchNodeDashboards,
  fetchNodeCustomPages,
  onLoginShow,
  children,
  geoglossaryBaseUrl,
  isMultiViewerTheme,
  modulesConfig
}) => {
  const {nodeCode: urlNodeCode} = useParams();

  const nodeCode = externalNodeCode ? externalNodeCode : urlNodeCode;

  useEffect(() => {
    return () => {
      clearNode();
      clearCatalog();
    };
  }, [nodeCode, clearNode, clearCatalog]);

  const nodeHavingCode = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase()) || null;

  const isFetchNodeEnabled = !node || node.code.toLowerCase() !== nodeCode.toLowerCase();
  const fetchCatalogBaseUrl = isMultiViewerTheme && geoglossaryBaseUrl ? geoglossaryBaseUrl : undefined;

  return (
    <Fragment>
      <Call
        cb={() => {
          if (!getIsUserAuthenticated(user)) {
            onLoginShow();
          } else {
            goToHome();
          }
        }}
        cbParam={user}
        disabled={nodeHavingCode !== null}
      >
        {nodeHavingCode !== null && (
          <Call
            cb={fetchNode}
            cbParam={{nodeId: nodeHavingCode.nodeId, nodeCode: nodeCode}}
            disabled={!nodeCode || !isFetchNodeEnabled || node !== null}
          >
            <Call
              cb={fetchCatalog}
              cbParam={{nodeId: nodeHavingCode.nodeId, nodeCode: nodeCode, baseUrl: fetchCatalogBaseUrl}}
              disabled={!nodeCode || node === null || catalog !== null}
            >
              <Call
                cb={fetchNodeDashboards}
                cbParam={{nodeId: nodeHavingCode.nodeId}}
                disabled={
                  !modulesConfig.modules.includes("dashboard") ||
                  !nodeCode ||
                  isFetchNodeEnabled ||
                  !node?.code ||
                  !!node?.itemContainers?.dashboard
                }
              >
                <Call
                  cb={fetchNodeCustomPages}
                  cbParam={{nodeId: nodeHavingCode.nodeId}}
                  disabled={
                    !modulesConfig.modules.includes("custom-page") ||
                    !nodeCode ||
                    isFetchNodeEnabled ||
                    !node?.code ||
                    !!node?.itemContainers?.customPages
                  }
                >
                  <div id={`node__${nodeCode}`} style={{width: "100%", height: "100%"}}>
                    <Fragment key={nodeCode}>{children}</Fragment>
                  </div>
                </Call>
              </Call>
            </Call>
          </Call>
        )}
      </Call>
      <SearchDialog modalWidth={320} dialogTop={56} onSubmit={value => goToDatasetsSearch(node.code, value)} />
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeDomain);
