import {
  getAddHubDashboardUrl,
  getHubConfigGetUrl,
  getHubConfigPutUrl,
  getHubDashboardsUrl,
  getOrderHubDashboardsUrl,
  getRemoveHubDashboardUrl,
  getSetMachineToMachineCredentialsUrl,
  getUserDashboardsUrl
} from "../../serverApi/urls";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

export const HUB_CONFIG_FETCH = "hubConfig/fetch";
export const HUB_CONFIG_SEND = "hubConfig/send";
export const HUB_CONFIG_CLEAR = "hubConfig/clear";
export const HUB_CONFIG_DASHBOARDS_SUBMIT = "hubConfig/dashboards/submit";
export const HUB_CONFIG_DASHBOARDS_FETCH = "hubConfig/dashboards/fetch";
export const HUB_CONFIG_DASHBOARDS_FETCH_ALL = "hubConfig/dashboards/fetchAll";
export const HUB_CONFIG_DASHBOARDS_DASHBOARD_ADD = "hubConfig/dashboards/addDashboard";
export const HUB_CONFIG_DASHBOARDS_DASHBOARD_REMOVE = "hubConfig/dashboards/removeDashboard";
export const HUB_CONFIG_DASHBOARDS_ORDERS_SEND = "hubConfig/dashboards/sendOrders";
export const HUB_CONFIG_DASHBOARDS_CLEAR = "hubConfig/dashboards/clear";
export const HUB_CONFIG_DASHBOARDS_CLEAR_ALL = "hubConfig/dashboards/clearAll";
export const HUB_CONFIG_CLOSE = "hubConfig/close";
export const HUB_CONFIG_MACHINE_TO_MACHINE_PASSWORD_SET = "hubConfig/machineToMachine/password/set";

export const fetchHubConfig = () =>
  initRequest(HUB_CONFIG_FETCH, getHubConfigGetUrl(), undefined, undefined, t => ({
    onStart: t("scenes.appSettings.messages.fetchConfig.start")
  }));

export const sendHubConfig = (config: any) =>
  initRequest(HUB_CONFIG_SEND, getHubConfigPutUrl(), RequestMethod.PUT, config, t => ({
    onStart: t("scenes.appSettings.messages.sendConfig.start")
  }));

export const clearHubConfig = () => ({
  type: HUB_CONFIG_CLEAR
});

export const submithHubConfigDashboards = (data: any) =>
  external(
    initRequest(HUB_CONFIG_DASHBOARDS_SUBMIT, getHubDashboardsUrl(), RequestMethod.POST, data, t => ({
      onStart: t("scenes.appSettings.messages.submitDashboard.start")
    })),
    "dashboard"
  );

export const fetchHubConfigDashboards = () =>
  external(
    initRequest(HUB_CONFIG_DASHBOARDS_FETCH, getHubDashboardsUrl(), RequestMethod.GET, null, t => ({
      onStart: t("scenes.appSettings.messages.fetchDashboards.start")
    })),
    "dashboard"
  );

export const fetchAllHubConfigDashboards = () =>
  external(
    initRequest(HUB_CONFIG_DASHBOARDS_FETCH_ALL, getUserDashboardsUrl(), RequestMethod.GET, null, t => ({
      onStart: t("scenes.appSettings.messages.fetchAllDashboards.start")
    })),
    "dashboard"
  );

export const addHubConfigDashboardsDashboard = (dashboardId: number) =>
  external(
    initRequest(
      HUB_CONFIG_DASHBOARDS_DASHBOARD_ADD,
      getAddHubDashboardUrl(dashboardId),
      RequestMethod.POST,
      null,
      t => ({
        onStart: t("scenes.appSettings.messages.addDashboard.start")
      })
    ),
    "dashboard"
  );

export const removeHubConfigDashboardsDashboard = (dashboardId: number) =>
  external(
    initRequest(
      HUB_CONFIG_DASHBOARDS_DASHBOARD_REMOVE,
      getRemoveHubDashboardUrl(dashboardId),
      RequestMethod.DELETE,
      null,
      t => ({
        onStart: t("scenes.appSettings.messages.removeDashboard.start")
      })
    ),
    "dashboard"
  );

export const sendHubConfigDashboardsOrders = (orderedDashboardsIds: number[]) =>
  external(
    initRequest(
      HUB_CONFIG_DASHBOARDS_ORDERS_SEND,
      getOrderHubDashboardsUrl(),
      RequestMethod.POST,
      orderedDashboardsIds,
      t => ({
        onStart: t("scenes.appSettings.messages.sendDashboardsOrders.start")
      })
    ),
    "dashboard"
  );

export const clearHubConfigDashboards = () => ({
  type: HUB_CONFIG_DASHBOARDS_CLEAR
});

export const clearAllHubConfigDashboards = () => ({
  type: HUB_CONFIG_DASHBOARDS_CLEAR_ALL
});

export const closeHubConfig = () => ({
  type: HUB_CONFIG_CLOSE
});

export const setHubConfigMachineToMachinePassword = (hubId: number, password: string) =>
  initRequest(
    HUB_CONFIG_MACHINE_TO_MACHINE_PASSWORD_SET,
    getSetMachineToMachineCredentialsUrl(hubId),
    RequestMethod.POST,
    password.length > 0 ? `"${btoa(password)}"` : undefined,
    t => ({
      onStart: t("scenes.appSettings.messages.setMachineToMachinePwd.start")
    }),
    {
      hubId: hubId,
      password: password
    },
    undefined,
    undefined,
    undefined,
    "application/json"
  );
