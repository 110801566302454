import React, {useState} from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {TextField} from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import "./style.css";

const PasswordInput = ({disableShowPassword = false, disableAutocomplete = false, InputProps, ...props}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TextField
      {...props}
      type={disableAutocomplete ? "text" : isVisible ? "text" : "password"}
      InputProps={{
        ...InputProps,
        className: disableAutocomplete ? (isVisible ? "" : "password") : "",
        endAdornment: disableShowPassword ? undefined : (
          <InputAdornment position="end">
            <IconButton onClick={() => setIsVisible(!isVisible)}>
              {isVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordInput;
