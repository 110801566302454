import React, {useState} from "react";
import {Dialog, DialogContent, Step, StepLabel, Stepper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FilterDto} from "../../../model/item-containers-models/filterDto";
import CustomDialogTitle from "../../custom-dialog-title";
import FilterConfigurationForm, {FilterConfigationFormProps} from "./FilterConfigurationForm";
import FilterFileUpload, {FilterFileUploadProps} from "./FilterFileUpload";

const steps = [
  {
    render: (props: FilterFileUploadProps) => <FilterFileUpload {...props} />
  },
  {
    render: (props: FilterConfigationFormProps) => <FilterConfigurationForm {...props} />
  }
] as const;

export interface FilterConfigurationDialogProps {
  open: boolean;
  onClose: () => void;
  filterToEdit: number;
  onFilterCreated: () => void;
}

interface GlobalFormProps {
  data: number & FilterDto;
  file?: File;
  filterToEdit: number;
}

const FilterConfigurationDialog = ({open, onClose, filterToEdit, onFilterCreated}: FilterConfigurationDialogProps) => {
  const {t} = useTranslation();
  const [activeStep, setActiveStep] = useState(filterToEdit === -1 ? 0 : 1);
  const step = steps[activeStep];
  const [data, setData] = useState<GlobalFormProps>({filterToEdit} as GlobalFormProps);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <CustomDialogTitle onClose={onClose}>{t("components.filtersSettingsForm.wizard.title")}</CustomDialogTitle>
      <DialogContent>
        {filterToEdit === -1 && (
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel>{t("components.filtersSettingsForm.wizard.firstStep.title")}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{t("components.filtersSettingsForm.wizard.secondStep.title")}</StepLabel>
            </Step>
          </Stepper>
        )}
        {step.render({
          onClose,
          onFileImport: data => {
            setActiveStep(1);
            setData({...data, filterToEdit});
          },
          onFilterCreated,
          ...data
        })}
      </DialogContent>
    </Dialog>
  );
};

export default FilterConfigurationDialog;
