import {MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS} from "../components/map/constants";

export const getMapSettingsFromViewTemplateLayouts = layouts => {
  if (!layouts) {
    return null;
  }

  const {
    mapClassificationMethod,
    mapPaletteStartColor,
    mapPaletteEndColor,
    mapPaletteCardinality,
    mapCustomIntervals,
    mapOpacity,
    mapIsLegendCollapsed,
    mapBaseLayer
  } = layouts;

  const settings = {
    classificationMethod: mapClassificationMethod,
    paletteStartColor: mapPaletteStartColor,
    paletteEndColor: mapPaletteEndColor,
    paletteCardinality: mapPaletteCardinality,
    customIntervals: mapCustomIntervals,
    opacity: mapOpacity,
    isLegendCollapsed: mapIsLegendCollapsed,
    baseLayer: mapBaseLayer
  };

  Object.keys(settings).forEach(key => {
    // @ts-ignore
    if (settings[key] === null || settings[key] === undefined) {
      // @ts-ignore
      delete settings[key];
    }
  });

  return settings;
};

export const getViewTemplateLayoutsFromMapSettings = settings => {
  const {
    classificationMethod,
    paletteStartColor,
    paletteEndColor,
    paletteCardinality,
    customIntervals,
    opacity,
    isLegendCollapsed,
    baseLayer
  } = settings;

  const layouts = {
    mapClassificationMethod: classificationMethod,
    mapPaletteStartColor: paletteStartColor,
    mapPaletteEndColor: paletteEndColor,
    mapPaletteCardinality: paletteCardinality,
    mapCustomIntervals: classificationMethod === MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS ? customIntervals : null,
    mapOpacity: opacity,
    mapIsLegendCollapsed: isLegendCollapsed,
    mapBaseLayer: baseLayer
  };

  Object.keys(layouts).forEach(key => {
    // @ts-ignore
    if (layouts[key] === null || layouts[key] === undefined) {
      // @ts-ignore
      delete layouts[key];
    }
  });

  return layouts;
};

export const getChartSettingsFromViewTemplateLayouts = layouts => {
  if (!layouts) {
    return null;
  }

  const {
    chartStacked,
    chartLegendPosition,
    chartColors,
    chartShowAxesLabel,
    chartCustomizeCategoryAxis,
    chartCategoryAxisLabel,
    chartValueAxisLabel,
    chartDataLabelType,
    chartExtremitiesCartesianAxesGraphs
  } = layouts;

  const settings = {
    stacked: chartStacked,
    legendPosition: chartLegendPosition,
    colors: chartColors,
    showAxesLabel: chartShowAxesLabel,
    customizeCategoryAxis: chartCustomizeCategoryAxis,
    categoryAxisLabel: chartCategoryAxisLabel,
    valueAxisLabel: chartValueAxisLabel,
    dataLabelType: chartDataLabelType,
    extremitiesCartesianAxesGraphs: chartExtremitiesCartesianAxesGraphs
  };

  Object.keys(settings).forEach(key => {
    // @ts-ignore
    if (settings[key] === null || settings[key] === undefined) {
      // @ts-ignore
      delete settings[key];
    }
  });
  return settings;
};

export const getViewTemplateLayoutsFromChartSettings = settings => {
  const {
    stacked,
    legendPosition,
    colors,
    showAxesLabel,
    customizeCategoryAxis,
    categoryAxisLabel,
    valueAxisLabel,
    dataLabelType,
    extremitiesCartesianAxesGraphs
  } = settings;

  const layouts = {
    chartStacked: stacked,
    chartLegendPosition: legendPosition,
    chartColors: colors,
    chartShowAxesLabel: showAxesLabel,
    chartCustomizeCategoryAxis: customizeCategoryAxis,
    chartCategoryAxisLabel: categoryAxisLabel,
    chartValueAxisLabel: valueAxisLabel,
    chartDataLabelType: dataLabelType,
    chartExtremitiesCartesianAxesGraphs: extremitiesCartesianAxesGraphs
  };

  Object.keys(layouts).forEach(key => {
    // @ts-ignore
    if (layouts[key] === null || layouts[key] === undefined) {
      // @ts-ignore
      delete layouts[key];
    }
  });

  return layouts;
};
