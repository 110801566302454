import React, {useMemo} from "react";
import {Box} from "@mui/material";
import Table from "../../table/multi-viewer-tmp";
import {getMultiViewerInitialTableLayout} from "../../../utils/dataset";
import {TABLE_HEADER_MERGED} from "../../table/multi-viewer-tmp/utils";

const IndicatorCreateDialogPreview = ({classes, jsonStat, territoryDim, timeDim}) => {
  const initialTableLayout = useMemo(() => {
    let tableLayout = null;
    if (jsonStat && territoryDim && timeDim) {
      tableLayout = getMultiViewerInitialTableLayout(jsonStat, territoryDim, timeDim);
    }
    return tableLayout;
  }, [jsonStat, territoryDim, timeDim]);

  return jsonStat && initialTableLayout ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden"
      }}
    >
      <Table
        jsonStat={jsonStat}
        layout={{
          ...initialTableLayout,
          filtersValue: {}
        }}
        tableHeaderType={TABLE_HEADER_MERGED}
      />
    </Box>
  ) : null;
};

export default IndicatorCreateDialogPreview;
