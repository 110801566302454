import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import "./style.css";

const mapStateToProps = state => ({
  messages: state.spinner.messages
});

const Spinner = ({children, messages, t}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      <Box
        id="spinner"
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 2000,
          background: "rgba(0, 0, 0, 0.7)"
        }}
        className={messages.length === 0 ? "invisible" : ""}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <LinearProgress
            sx={{
              width: "320px",
              maxWidth: "50%"
            }}
            color="secondary"
            aria-label={t("components.spinner.ariaLabel")}
          />
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            textAlign: "center"
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              marginTop: "calc(50vh + 48px)"
            }}
            aria-live="polite"
          >
            {messages.map(({uid, message, removed, isError}) => (
              <Grid
                key={uid}
                container
                justifyContent={messages.filter(({removed}) => removed).length > 0 ? "space-between" : "center"}
                sx={{height: "32px"}}
                spacing={2}
                marginBottom={1}
              >
                <Grid item>
                  <Typography color="secondary">
                    {message}
                    {!removed && <span aria-hidden={true}>...</span>}
                  </Typography>
                </Grid>
                {removed && (
                  <Grid
                    item
                    style={{color: isError ? theme.palette.error.main : theme.palette.success.main}}
                    aria-hidden={true}
                  >
                    {isError ? <CancelIcon fontSize="small" /> : <CheckCircleIcon fontSize="small" />}
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          width: "100%",
          height: "100%"
        }}
        style={{overflow: messages.length > 0 ? "hidden" : "initial"}}
        aria-busy={messages.length > 0}
      >
        {children}
      </Box>
    </Box>
  );
};

export default compose(connect(mapStateToProps), withTranslation())(Spinner);
