import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MenuIcon from "@mui/icons-material/Menu";
import {Box, Card, IconButton, Paper, Tab, Tabs, Tooltip, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {HashLink} from "react-router-hash-link";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemRichTextDto} from "../../model/item-containers-models/itemRichTextDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {getCustomPageUrl, getDashboardUrl} from "../../serverApi/urls";
import CustomBreadcrumbs from "../custom-breadcrumbs";
import CustomEmpty from "../custom-empty";
import Footer from "../footer";
import Header from "../header";
import ItemContainer from "../item-container";
import MaterialTree, {SINGLE_SELECT_MODE} from "../material-tree";
import Page from "../page";
import ItemContainerFilter, {FilterProvider} from "./ItemContainerFilters";
import useApi from "../../state/api/useApi";
import {appSelector} from "../../state/app/appSelectors";
import useLanguages from "../../state/hooks/useLanguages";
import {hubSelector} from "../../state/hub/hubSelectors";
import {fetchItemContainerById} from "../../state/itemContainer/itemContainerActions";
import {nodeSelector} from "../../state/node/nodeSelectors";
import {userSelector} from "../../state/user/userSelectors";
import {
  ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT,
  ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW
} from "../../utils/itemContainers";
import {getPageTitle} from "../../utils/other";
import "./style.css";

declare global {
  interface Window {
    language: string;
  }
}

export const itemIsView = (
  v: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto
): v is ItemViewTemplateDto => v.type === ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW;
export const itemIsText = (v: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto): v is ItemRichTextDto =>
  v.type === ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT;
export const itemIsCategory = (
  v: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto
): v is ItemCategoryTemplateDto => v.type === ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY;

const handleHeight = () => {
  const ItemContainersBreadcrumbsHeight = document.getElementById("itemContainers__breadcrumbs")
    ? document.getElementById("itemContainers__breadcrumbs").offsetHeight
    : 0;
  const ItemContainersTitleHeight = document.getElementById("itemContainers__title")
    ? document.getElementById("itemContainers__title").offsetHeight
    : 0;
  const ItemContainersFiltersHeight = document.getElementById("itemContainers__filters")
    ? document.getElementById("itemContainers__filters").offsetHeight
    : 0;
  const ItemContainersHeaderHeight = document.getElementById("itemContainers__header")
    ? document.getElementById("itemContainers__header").offsetHeight
    : 0;

  const footerHeight = document.getElementById("footer") ? document.getElementById("footer").offsetHeight : 0;
  if (document.getElementById("main__container")) {
    document
      .getElementById("main__container")
      .setAttribute(
        "style",
        `height: calc(100% - ${
          ItemContainersBreadcrumbsHeight +
          ItemContainersTitleHeight +
          ItemContainersFiltersHeight +
          ItemContainersHeaderHeight +
          footerHeight
        }px); display:flex;`
      );
  }
};

const AccessibleLinks = ({nodeCode}) => {
  const node = useSelector(nodeSelector);
  const {t} = useLanguages();
  const location = useLocation();
  const {isA11y} = useSelector(appSelector);
  const params = new URLSearchParams(location.search);
  params.set("accessible", "true");
  const paramsStr = params.toString();
  const path = node?.nodeId && nodeCode ? `/${window.language}/${nodeCode.toLowerCase()}` : `/${window.language}`;
  return (
    <>
      {!isA11y && (
        <a
          href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
          target="_self"
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.accessible")}
        </a>
      )}
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
    </>
  );
};

const BreadcrumbSection = () => {
  const {t} = useLanguages();
  return (
    <Box
      id="itemContainers__breadcrumbs"
      sx={{
        padding: "0 8px"
      }}
    >
      <CustomBreadcrumbs sx={{padding: "16px 8px 8px"}}>
        {[
          {
            label: window.location.href.includes("dashboards")
              ? t("scenes.itemContainers.breadcrumbs.dashboards")
              : t("scenes.itemContainers.breadcrumbs.customPages")
          }
        ]}
      </CustomBreadcrumbs>
    </Box>
  );
};

interface ItemContainersProps {
  itemContainerId?: number;
  nodeCode?: string;
  isDefault: boolean;
  hideBreadcrumbs: boolean;
  itemContainers: any;
}

function ItemContainers({
  itemContainerId,
  nodeCode,
  isDefault,
  hideBreadcrumbs,
  itemContainers = []
}: ItemContainersProps) {
  const {t, localizeI18nObj} = useLanguages();
  const [tabIdx, setTabIdx] = useState<any>();
  const user = useSelector(userSelector);
  const hub = useSelector(hubSelector);
  const node = useSelector(nodeSelector);
  const location = useLocation();
  const nodeId = node?.nodeId;

  const theme = useTheme();
  const [menuOpened, setMenuOpened] = useState(false);
  const [currentItemContainerId, setCurrentItemContainerId] = useState<any>();

  const isDashboards = useMemo(() => location.pathname.includes("dashboards"), [location]);

  const isCategorized = useMemo(() => {
    return (
      !itemContainerId &&
      isDashboards &&
      itemContainers &&
      itemContainers.categorizedDashboards &&
      itemContainers.categorizedDashboards.length > 0
    );
  }, [itemContainerId, isDashboards, itemContainers]);

  const managedItemContainers = useMemo(() => {
    let obj = [];
    if (!isDashboards) {
      obj = itemContainers;
    } else {
      if (itemContainers.uncategorizedDashboards && !isCategorized) {
        obj = itemContainers.uncategorizedDashboards;
      } else if (isCategorized) {
        obj = itemContainers.categorizedDashboards.reduce((newArray, c) => {
          newArray.push(...(c.dashboards || []));
          return newArray;
        }, []);
      }
    }
    return obj;
  }, [isDashboards, isCategorized, itemContainers]);

  const hasGlobalFilter = useMemo(
    () =>
      managedItemContainers.every(
        item =>
          !!item.filterId &&
          managedItemContainers[0]?.filterId === item.filterId &&
          _.isEqual(managedItemContainers[0].filterLevelsSelected, item.filterLevelsSelected)
      ),
    [managedItemContainers]
  );

  const categoryTree = useMemo(() => {
    const catTree =
      isCategorized && itemContainers
        ? itemContainers.categorizedDashboards.map((c, index) => {
            return {
              id: index + 1,
              ...c,
              title: localizeI18nObj(c.title),
              dashboards: (c.dashboards || []).map(d => {
                return {
                  ...d,
                  title: localizeI18nObj(d.title)
                };
              })
            };
          })
        : [];

    return catTree;
  }, [isCategorized, itemContainers, localizeI18nObj]);

  const setTabIdxForCurrentItemContainerId = useCallback(
    id => {
      if (id) {
        const idx = managedItemContainers.findIndex(d => d.id === id);
        setCurrentItemContainerId(id);
        setTabIdx(idx);
      }
    },
    [managedItemContainers, setTabIdx, setCurrentItemContainerId]
  );

  const {call, data, request, error} = useApi<{itemContainer: ItemContainerDto; viewsAdditionalInfo: any}>(
    fetchItemContainerById(currentItemContainerId, isDashboards ? "dashboard" : "customPages", true),
    {
      cache: true,
      getCacheKey: req => req.url
    }
  );

  useEffect(() => {
    handleHeight();
  });

  useEffect(() => {
    if (isCategorized) {
      setMenuOpened(itemContainers.expandCategoryTree);
    }
  }, [itemContainers, isCategorized]);

  useEffect(() => {
    if (itemContainerId) {
      setCurrentItemContainerId(itemContainerId);
    } else if (tabIdx === undefined || tabIdx === null) {
      if (itemContainers && itemContainers.defaultDashboardId) {
        setCurrentItemContainerId(itemContainers.defaultDashboardId);
        setTabIdxForCurrentItemContainerId(itemContainers.defaultDashboardId);
      } else if (managedItemContainers && managedItemContainers.length > 0) {
        setCurrentItemContainerId(managedItemContainers[0].id);
        setTabIdx(0);
      }

      if (!isCategorized && itemContainers && itemContainers.defaultDashboardId) {
        setTabIdxForCurrentItemContainerId(itemContainers.defaultDashboardId);
      }
    }
  }, [
    tabIdx,
    itemContainerId,
    itemContainers,
    isCategorized,
    managedItemContainers,
    setTabIdxForCurrentItemContainerId,
    setCurrentItemContainerId,
    setTabIdx
  ]);

  useEffect(() => {
    if (currentItemContainerId) {
      call({
        ...request,
        url: isDashboards
          ? getDashboardUrl(currentItemContainerId, true)
          : getCustomPageUrl(currentItemContainerId, true)
      });
    }
  }, [currentItemContainerId, user?.userId, isDashboards]);

  useEffect(() => {
    window.addEventListener("resize", handleHeight);
    return () => window.removeEventListener("resize", handleHeight);
  }, []);

  // useEffect(() => {
  //   if (error) {
  //     if (!getIsUserAuthenticated(user)) {
  //       dispatch(
  //         showUserLoginForm(
  //           location.pathname.includes("dashboards") ? "NotAccessibleDashboard" : "NotAccessibleCustomPage",
  //           "warning",
  //           true
  //         )
  //       );
  //     } else {
  //       goToHome();
  //     }
  //   }
  // }, [dispatch, error, location.pathname, user]);

  const itemContainer = useMemo(() => {
    const itemContainer: ItemContainerDto = _.cloneDeep(data?.itemContainer);
    if (itemContainer) {
      (itemContainer.item ?? []).forEach(row => {
        (row ?? []).forEach(col => {
          if (itemIsView(col) && data?.viewsAdditionalInfo?.[col.id]) {
            col.viewTemplate = {
              ...col.viewTemplate,
              ...data.viewsAdditionalInfo[col.id]
            };
          }
        });
      });
      return itemContainer;
    }
  }, [data]);

  const showItemContainerTitle = useMemo(() => {
    return isDashboards || !itemContainer?.hideTitle;
  }, [itemContainer, isDashboards]);

  return (
    <FilterProvider
      filterData={
        itemContainer?.filter
          ? {filter: itemContainer.filter, selectedLevels: itemContainer.filterLevelsSelected}
          : null
      }
    >
      <Fragment>
        <AccessibleLinks nodeCode={nodeCode} />
        <Page
          title={
            nodeId
              ? isDashboards
                ? getPageTitle([t("scenes.itemContainers.dashboards.title", {name: node?.name}), hub?.name], t)
                : getPageTitle([t("scenes.itemContainers.customPages.title", {name: node?.name}), hub?.name], t)
              : isDashboards
                ? getPageTitle([t("scenes.itemContainers.dashboards.title", {name: hub?.name})], t)
                : getPageTitle([t("scenes.itemContainers.customPages.title", {name: hub?.name})], t)
          }
        >
          <Box
            key={currentItemContainerId}
            sx={{
              backgroundColor: "#f5f5f5",
              width: "100%",
              height: "100%",
              padding: "0 !important",
              overflow: "hidden"
            }}
          >
            <Header
              isDefault={nodeId ? isDefault : undefined}
              getCustomA11yPath={isA11y => {
                if (isA11y) {
                  if (nodeCode) {
                    return `/${window.language}/${nodeCode.toLowerCase()}`;
                  } else {
                    return `/${window.language}`;
                  }
                } else {
                  return false;
                }
              }}
            />
            <Box
              id="main"
              component="main"
              className="itemContainers"
              sx={{
                width: "100%",
                height: "100%"
              }}
            >
              {!hideBreadcrumbs && <BreadcrumbSection />}
              {error ? (
                <CustomEmpty text={t("scenes.itemContainers.errors.requestError")} />
              ) : currentItemContainerId ? (
                <Fragment>
                  {showItemContainerTitle && (
                    <Box
                      id="itemContainers__title"
                      sx={{
                        padding: "0 8px"
                      }}
                    >
                      <Typography
                        variant={"h1"}
                        sx={{
                          padding: "0 12px 4px",
                          fontSize: "28px",
                          fontWeight: 300,
                          letterSpacing: 0,
                          color: theme => theme.palette.text.primary
                        }}
                      >
                        {itemContainerId || managedItemContainers.length === 1
                          ? localizeI18nObj(itemContainer?.title)
                          : isDashboards
                            ? isCategorized
                              ? localizeI18nObj(itemContainer?.title)
                              : t("scenes.itemContainers.dashboards.title")
                            : t("scenes.itemContainers.customPages.title")}
                      </Typography>
                    </Box>
                  )}
                  {hasGlobalFilter && (
                    <Box
                      id="itemContainers__filters"
                      sx={{
                        padding: "0 20px"
                      }}
                    >
                      {itemContainer?.filter && <ItemContainerFilter itemContainer={itemContainer} />}
                    </Box>
                  )}
                  {(managedItemContainers || []).length > 1 && !isCategorized && (
                    <Box
                      id="itemContainers__header"
                      sx={{
                        padding: "8px 12px"
                      }}
                    >
                      <Paper>
                        <Tabs
                          value={tabIdx}
                          variant="scrollable"
                          scrollButtons="auto"
                          onChange={(_, newValue) => {
                            setTabIdxForCurrentItemContainerId(managedItemContainers[newValue]?.id);
                          }}
                        >
                          {managedItemContainers.map(({id, title}, idx) => (
                            <Tab
                              key={idx}
                              label={localizeI18nObj(title)}
                              id={`tab__${id}`}
                              aria-controls={`itemContainer__${id}`}
                              tabIndex={0}
                            />
                          ))}
                        </Tabs>
                      </Paper>
                    </Box>
                  )}
                  <Box id="main__container" sx={{width: "100%", padding: "0 8px"}}>
                    {isCategorized && (
                      <Box id="menu__container" sx={{height: "100%", paddingTop: "8px"}}>
                        <Card sx={{height: "100%"}}>
                          <Box sx={{display: "flex", alignItems: "center"}}>
                            {menuOpened && (
                              <Box sx={{paddingLeft: "8px", flexGrow: 1}}>
                                <Typography
                                  variant={"h1"}
                                  sx={{
                                    padding: "8px 12px 4px",
                                    fontSize: "28px",
                                    fontWeight: 300,
                                    letterSpacing: 0
                                  }}
                                >
                                  {t("scenes.itemContainers.dashboards.title")}
                                </Typography>
                              </Box>
                            )}
                            <div>
                              <Tooltip
                                title={
                                  menuOpened
                                    ? t("scenes.itemContainers.dashboards.categoryTree.collapse.title")
                                    : t("scenes.itemContainers.dashboards.categoryTree.expand.title")
                                }
                              >
                                <span>
                                  <IconButton
                                    aria-label={
                                      menuOpened
                                        ? t("scenes.itemContainers.dashboards.categoryTree.collapse.ariaLabel")
                                        : t("scenes.itemContainers.dashboards.categoryTree.expand.ariaLabel")
                                    }
                                    onClick={() => setMenuOpened(!menuOpened)}
                                  >
                                    {menuOpened ? <ChevronLeftIcon /> : <MenuIcon />}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </div>
                          </Box>
                          {menuOpened && (
                            <Box sx={{height: "100%", minWidth: "300px", width: "100%"}}>
                              {(categoryTree || []).length > 0 && (
                                <Box sx={{height: "100%", paddingLeft: "8px", paddingRight: "8px"}}>
                                  {
                                    <MaterialTree
                                      treeData={categoryTree}
                                      idKey="id"
                                      childrenKey="dashboards"
                                      labelKey="title"
                                      renderNode={d => (
                                        <Box sx={{display: "flex", paddingTop: "4px", alignItems: "flex-start"}}>
                                          <Box>
                                            {d.hasChildren ? (
                                              <FolderOpenIcon color="primary" />
                                            ) : (
                                              <DashboardIcon color="primary" />
                                            )}
                                          </Box>
                                          <Box
                                            sx={{
                                              marginLeft: "4px",
                                              borderBottom:
                                                !d.hasChildren && d.id === `${currentItemContainerId}`
                                                  ? `1px solid ${theme.palette.primary.main}`
                                                  : "unset"
                                            }}
                                          >
                                            {`${d.title}`}
                                          </Box>
                                        </Box>
                                      )}
                                      selectMode={SINGLE_SELECT_MODE}
                                      disableSelection
                                      removeDisabledCheckbox
                                      defaultExpandedNodeIds={[`${currentItemContainerId}`]}
                                      onNodeClick={item =>
                                        item.hasChildren ? undefined : setTabIdxForCurrentItemContainerId(+item.id)
                                      }
                                      hideButtonBar
                                    />
                                  }
                                </Box>
                              )}
                            </Box>
                          )}
                        </Card>
                      </Box>
                    )}
                    <Box
                      id="itemContainers__content"
                      sx={{
                        overflow: "auto",
                        width: "100%"
                      }}
                    >
                      {isCategorized && (
                        <div id="itemContainers__internalTitle">
                          <Typography
                            variant={"h1"}
                            sx={{
                              padding: "0 12px 4px",
                              fontSize: "28px",
                              fontWeight: 300,
                              letterSpacing: 0
                            }}
                          >
                            {itemContainerId || managedItemContainers.length === 1
                              ? localizeI18nObj(itemContainer?.title)
                              : isDashboards
                                ? isCategorized
                                  ? localizeI18nObj(itemContainer?.title)
                                  : t("scenes.itemContainers.dashboards.title")
                                : t("scenes.itemContainers.customPages.title")}
                          </Typography>
                        </div>
                      )}
                      {!hasGlobalFilter && (
                        <Box
                          id="specificFilter__container"
                          sx={{
                            padding: "0 12px"
                          }}
                        >
                          {itemContainer?.filter && <ItemContainerFilter itemContainer={itemContainer} />}
                        </Box>
                      )}
                      <div
                        style={{width: "100%"}}
                        id={`itemContainer_${currentItemContainerId}`}
                        aria-labelledby={`tab__${currentItemContainerId}`}
                      >
                        <ItemContainer itemContainer={itemContainer} />
                      </div>
                    </Box>
                  </Box>
                </Fragment>
              ) : (
                <CustomEmpty
                  text={
                    nodeId
                      ? isDashboards
                        ? t("scenes.itemContainers.dashboards.empty.node")
                        : t("scenes.itemContainers.customPages.empty.node")
                      : isDashboards
                        ? t("scenes.itemContainers.dashboards.empty.hub")
                        : t("scenes.itemContainers.customPages.empty.hub")
                  }
                />
              )}
              <Footer />
            </Box>
          </Box>
        </Page>
      </Fragment>
    </FilterProvider>
  );
}

export default ItemContainers;
