import {ICategoryGroup} from "./ICategoryGroup";
import {ICategoryProvider} from "./ICategoryProvider";
import {IDataset} from "./IDataset";
import {getDetailLevelTree, updateCategoriesGroupDashboardWidgetInfo} from "../utils/catalog";
import {getNodes} from "../utils/tree";
import themeConfig from "../theme-config/config.json";

export class LocalCategoryProvider implements ICategoryProvider {
  categoryGroups: Array<ICategoryGroup>;

  datasetMap: {[key: string]: IDataset};

  uncategorizedDatasets: IDataset[];

  hasCategorySchemes: boolean;

  isEmpty: boolean;

  nodeId: number;

  detailLevelTree: any = [];

  detailLevels: number[] = [];

  constructor(
    categories: Array<ICategoryGroup>,
    datasets: {[key: string]: IDataset},
    uncategorizedDatasets: IDataset[] = [],
    nodeId: number = -1,
    detailLevelTree: any = [],
    t: any,
    showSingleCategoryScheme: boolean = false
  ) {
    this.datasetMap = datasets || {};
    if (themeConfig.enableOptimization) {
      Object.values(this.datasetMap).forEach((dataset: IDataset) => {
        if (dataset.datasetType === "dashboard") {
          dataset.datasetType = "onlyFile";
        }
      });
    }
    this.categoryGroups = updateCategoriesGroupDashboardWidgetInfo(categories, this.datasetMap);
    this.uncategorizedDatasets = uncategorizedDatasets || [];
    if (themeConfig.enableOptimization) {
      this.uncategorizedDatasets.forEach((dataset: IDataset) => {
        if (dataset.datasetType === "dashboard") {
          dataset.datasetType = "onlyFile";
        }
      });
    }
    this.hasCategorySchemes = this.categoryGroups.length > (showSingleCategoryScheme ? 0 : 1);
    this.isEmpty = Object.keys(this.datasetMap).length === 0 && this.uncategorizedDatasets.length === 0;

    this.nodeId = nodeId || -1;

    const tree = getDetailLevelTree(detailLevelTree, t);
    this.detailLevelTree = tree;
    this.detailLevels = getNodes(tree, "layers", (node: any) => node.catalogSelection).sort(
      (a, b) => a.order - b.order || a.id - b.id
    );
  }
}
