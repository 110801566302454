import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

const CustomDialogTitle = ({onClose, children, ...props}) => {
  const {t} = useTranslation();

  return (
    <DialogTitle {...props}>
      <Grid container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
        <Grid item>
          <Typography variant="h6">{children}&nbsp;</Typography>
        </Grid>
        <Grid item>
          {onClose && (
            <IconButton
              aria-label={t("components.customDialogTitle.close.ariaLabel")}
              sx={{
                marginLeft: "8px",
                marginRight: "-8px",
                padding: "4px"
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default CustomDialogTitle;
