import React from "react";
import {Box} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {withTranslation} from "react-i18next";

export const PDF_ORIENTATION_VERTICAL = "vertical";
export const PDF_ORIENTATION_HORIZONTAL = "horizontal";

function ExportForm(props) {
  const {t, settings, onSettingsSet} = props;

  const {showTitle, title, showFilters, isLegendVisible, showLegend, showDate, sheetOrientation} = settings || {};

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        "& > *:not(:last-child)": {
          marginBottom: "16px"
        }
      }}
    >
      <Paper
        variant="outlined"
        sx={theme => ({
          padding: theme.spacing(2),
          "& > *:not(:last-child)": {
            marginBottom: "16px"
          }
        })}
      >
        <FormControl fullWidth>
          <FormControlLabel
            label={t("components.exportButton.exportForm.showTitle.label")}
            control={
              <Checkbox
                checked={showTitle || false}
                onChange={(e, value) => onSettingsSet({...settings, showTitle: value})}
              />
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t("components.exportButton.exportForm.title.label")}
            variant="outlined"
            value={title || ""}
            onChange={({target}) => onSettingsSet({...settings, title: target.value})}
          />
        </FormControl>
      </Paper>
      <FormControl fullWidth>
        <FormControlLabel
          label={t("components.exportButton.exportForm.showFilters.label")}
          control={
            <Checkbox
              checked={showFilters || false}
              onChange={(e, value) => onSettingsSet({...settings, showFilters: value})}
            />
          }
        />
      </FormControl>
      {showDate !== null && showDate !== undefined && (
        <FormControl fullWidth>
          <FormControlLabel
            label={t("components.exportButton.exportForm.showDate.label")}
            control={
              <Checkbox
                checked={showDate || false}
                onChange={(e, value) => onSettingsSet({...settings, showDate: value})}
              />
            }
          />
        </FormControl>
      )}
      {isLegendVisible && showLegend !== null && showLegend !== undefined && (
        <FormControl fullWidth>
          <FormControlLabel
            label={t("components.exportButton.exportForm.showLegend.label")}
            control={
              <Checkbox
                checked={showLegend || false}
                onChange={(e, value) => onSettingsSet({...settings, showLegend: value})}
              />
            }
          />
        </FormControl>
      )}
      {sheetOrientation !== null && sheetOrientation !== undefined && (
        <FormControl fullWidth>
          <TextField
            select
            fullWidth
            label={t("components.exportButton.exportForm.sheetOrientation.label")}
            value={sheetOrientation}
            variant="outlined"
            onChange={ev => onSettingsSet({...settings, sheetOrientation: ev.target.value})}
          >
            <MenuItem value={PDF_ORIENTATION_VERTICAL}>
              {t("components.exportButton.exportForm.sheetOrientation.values.vertical")}
            </MenuItem>
            <MenuItem value={PDF_ORIENTATION_HORIZONTAL}>
              {t("components.exportButton.exportForm.sheetOrientation.values.horizontal")}
            </MenuItem>
          </TextField>
        </FormControl>
      )}
    </Box>
  );
}

export default withTranslation()(ExportForm);
