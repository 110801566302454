import React, {Fragment, useCallback, useRef, useState} from "react";
import {Box, DialogActions} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {getUserErrorsTranslations} from "../../constants/getUserErrorsTranslations";
import {goToHome} from "../../links";
import CustomDialogTitle from "../custom-dialog-title";
import SettingsDialog from "../settings-dialog";
import UserRecoverPasswordForm from "./user-recover-password-form";

const fieldStyle = {
  marginTop: theme => theme.spacing(3)
};

const defaultValue = {
  email: null,
  password: null
};

const UserLoginDialog = ({
  open,
  disableRegistration,
  onSubmit,
  onHide,
  onRegister,
  alert,
  alertSeverity = "error",
  loginOrHome
}) => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    reset
  } = useForm(defaultValue);

  const {t} = useTranslation();

  const [isRecoverPasswordModalOpen, setIsRecoverPasswordModalOpen] = useState(false);

  const recoverPasswordFormRef = useRef();

  const handleFormClose = useCallback(() => {
    onHide();
    reset(defaultValue);
    if (loginOrHome) {
      goToHome();
    }
  }, [onHide, reset, loginOrHome]);

  const handleFormSubmit = useCallback(() => {
    handleSubmit(data => {
      onSubmit(data);
      // reset(defaultValue); reset should be done only on submit success
    })();
  }, [handleSubmit, onSubmit]);

  const keyDownHandler = useCallback(
    evt => {
      if (evt.key === "Enter") {
        handleFormSubmit();
      }
    },
    [handleFormSubmit]
  );

  return (
    <Dialog
      open={open || false}
      maxWidth="xs"
      fullWidth
      onClose={handleFormClose}
      TransitionProps={{
        onEntered: () => {
          const userField = document.getElementById("user-login-form__text-field__email");
          const pswField = document.getElementById("user-login-form__text-field__password");
          userField.addEventListener("keydown", keyDownHandler);
          pswField.addEventListener("keydown", keyDownHandler);
        },
        onExit: () => {
          const userField = document.getElementById("user-login-form__text-field__email");
          const pswField = document.getElementById("user-login-form__text-field__password");
          userField.removeEventListener("keydown", keyDownHandler);
          pswField.removeEventListener("keydown", keyDownHandler);
        }
      }}
    >
      <CustomDialogTitle onClose={handleFormClose}>{t("components.userLoginDialog.title")}</CustomDialogTitle>
      <DialogContent>
        {alert && (
          <Alert severity={alertSeverity}>{getUserErrorsTranslations(t)[alert] || t("errors.user.generic")}</Alert>
        )}
        <FormControl fullWidth sx={alert ? fieldStyle : undefined}>
          <TextField
            {...register("email", {
              required: t("commons.validation.required")
            })}
            id="user-login-form__text-field__email"
            name="email"
            label={t("components.userLoginDialog.fields.email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            variant="outlined"
            required
            autoFocus
          />
        </FormControl>
        <FormControl fullWidth sx={fieldStyle}>
          <TextField
            {...register("password")}
            id="user-login-form__text-field__password"
            name="password"
            type="password"
            label={t("components.userLoginDialog.fields.password")}
            error={!!errors.password}
            helperText={
              <Fragment>
                {errors.password?.message}
                <Box
                  component="span"
                  onClick={() => setIsRecoverPasswordModalOpen(true)}
                  sx={{
                    textDecoration: "underline",
                    color: theme => theme.palette.secondary.main,
                    cursor: "pointer"
                  }}
                  style={{display: "block"}}
                >
                  {t("components.userLoginDialog.recoverPassword")}
                </Box>
              </Fragment>
            }
            variant="outlined"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {loginOrHome ? (
          <Button id="user_login_form_goToHome_btn" onClick={handleFormClose}>
            {t("components.userLoginDialog.goToHome")}
          </Button>
        ) : (
          <Fragment>
            <Button id="user_login_form_cancel_btn" onClick={handleFormClose}>
              {t("commons.confirm.cancel")}
            </Button>
            {!disableRegistration && (
              <Button id="user_login_form_register_btn" onClick={onRegister}>
                {t("components.userLoginDialog.register")}
              </Button>
            )}
          </Fragment>
        )}
        <Button id="user_login_form_login_btn" onClick={handleFormSubmit}>
          {t("components.userLoginDialog.login")}
        </Button>
      </DialogActions>
      <SettingsDialog
        title={t("components.userLoginDialog.recoverPasswordModal.title")}
        maxWidth={"xs"}
        open={isRecoverPasswordModalOpen}
        onClose={() => {
          if (recoverPasswordFormRef.current) {
            recoverPasswordFormRef.current.cancel(() => {
              setIsRecoverPasswordModalOpen(false);
            });
          } else {
            setIsRecoverPasswordModalOpen(false);
          }
        }}
        onSubmit={() => {
          if (recoverPasswordFormRef.current) {
            recoverPasswordFormRef.current.submit(() => {
              setIsRecoverPasswordModalOpen(false);
            });
          }
        }}
        hasSubmit
        noMinHeight
        noFullScreen
      >
        <UserRecoverPasswordForm ref={recoverPasswordFormRef} />
      </SettingsDialog>
    </Dialog>
  );
};

export default UserLoginDialog;
