import React, {useState} from "react";
import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {useItemContainerBuilder} from "../item-container-builder/useItemContainerBuilder";
import useLanguages from "../../state/hooks/useLanguages";

const formControlLabelStyle = {
  marginLeft: "0px",
  marginRight: "0px"
};

export const CategoryOptionForm = ({
  item,
  rowIdx,
  colIdx
}: {
  item: ItemCategoryTemplateDto;
  rowIdx: number;
  colIdx: number;
}) => {
  const {t} = useLanguages();
  const {handleCategoryOptionChange} = useItemContainerBuilder();
  const [browseData, setBrowseDataLink] = useState(item.browseDataLink ? true : false);

  const onChange = (e: any) => {
    const name = e.target.name;
    const value = name === "browseDataLink" ? e.target.value : e.target.checked;
    if (name === "browseData") {
      if (!value) {
        handleCategoryOptionChange({name: "browseDataLink", value: null}, rowIdx, colIdx);
      }
      setBrowseDataLink(value);
    } else {
      handleCategoryOptionChange({name, value}, rowIdx, colIdx);
    }
  };

  return (
    <Box className="itemContainer_category-item_option-form">
      <FormGroup onChange={onChange} style={{gap: 5}}>
        <Box>
          <FormControlLabel
            labelPlacement="start"
            sx={formControlLabelStyle}
            control={<Checkbox checked={item.enableCatalogPortion || false} name="enableCatalogPortion" />}
            label={
              <Typography
                style={{
                  fontSize: 14
                }}
              >
                {t("components.categoryOptionForm.dataFlow.checkBoxes.showCatalogPreview") + ":"}
              </Typography>
            }
          />
        </Box>
        <Box>
          <FormControlLabel
            labelPlacement="start"
            sx={formControlLabelStyle}
            control={<Checkbox checked={item.expandCategories || false} name="expandCategories" />}
            label={
              <Typography
                style={{
                  fontSize: 14
                }}
              >
                {t("components.categoryOptionForm.dataFlow.checkBoxes.expandCategories") + ":"}
              </Typography>
            }
          />
        </Box>
        <Box>
          <FormControlLabel
            labelPlacement="start"
            sx={formControlLabelStyle}
            control={<Checkbox checked={item.enableSDMXDownload || false} name="enableSDMXDownload" />}
            label={
              <Typography
                style={{
                  fontSize: 14
                }}
              >
                {t("components.categoryOptionForm.dataFlow.checkBoxes.sdmx") + ":"}
              </Typography>
            }
          />
          <FormControlLabel
            labelPlacement="start"
            sx={formControlLabelStyle}
            control={<Checkbox checked={item.enableMetadata || false} name="enableMetadata" />}
            label={
              <Typography
                style={{
                  fontSize: 14
                }}
              >
                {t("components.categoryOptionForm.dataFlow.checkBoxes.metadata") + ":"}
              </Typography>
            }
          />
          <FormControlLabel
            labelPlacement="start"
            sx={formControlLabelStyle}
            control={<Checkbox name="browseData" checked={browseData} />}
            label={
              <Typography
                style={{
                  fontSize: 14
                }}
              >
                {t("components.categoryOptionForm.dataFlow.checkBoxes.browseData") + ":"}
              </Typography>
            }
          />
          <FormControl variant="outlined" size="small" style={{minWidth: "100px"}} sx={formControlLabelStyle}>
            <Select value={item.browseDataLink || ""} name="browseDataLink" disabled={!browseData} onChange={onChange}>
              <MenuItem value={"Internal"}>{t("components.categoryOptionForm.dataFlow.radio.internal")}</MenuItem>
              <MenuItem value={"External"}>{t("components.categoryOptionForm.dataFlow.radio.external")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </FormGroup>
    </Box>
  );
};
