import {PayloadAction} from "@reduxjs/toolkit";
import {CancelTokenSource} from "axios";
import {Reducer} from "redux";
import {HUB_FETCH} from "../hub/hubActions";
import {PENDING_REQUEST_ADD, PENDING_REQUESTS_CANCEL, PENDING_REQUESTS_CLEAR} from "./pendingRequestActions";

const excludedRequestFromClear = [HUB_FETCH];

type Request = {uuid: string; label: string; source: CancelTokenSource};

export type PendingRequestState = Request[];

const pendingRequestReducer: Reducer<PendingRequestState, PayloadAction<any>> = (state = [], action) => {
  switch (action.type) {
    case PENDING_REQUEST_ADD: {
      const newRequest = {
        uuid: action.payload.requestUuid,
        label: action.payload.requestLabel,
        source: action.payload.cancelTokenSource
      };
      return [...state, newRequest];
    }
    case PENDING_REQUESTS_CANCEL: {
      const newPendingRequests: Request[] = [];
      state.forEach((request: Request) => {
        if ((action.payload.requestUuids || []).includes(request.uuid)) {
          request.source.cancel();
        } else {
          newPendingRequests.push(request);
        }
      });
      return newPendingRequests;
    }
    case PENDING_REQUESTS_CLEAR: {
      const newPendingRequests: Request[] = [];
      state.forEach((request: Request) => {
        if (!excludedRequestFromClear.includes(request.label)) {
          request.source.cancel();
        } else {
          newPendingRequests.push(request);
        }
      });
      return newPendingRequests;
    }
    default:
      return state;
  }
};

export default pendingRequestReducer;
