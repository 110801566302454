import {ICategoryProvider} from "../../../model/ICategoryProvider";
import {RoundingMode} from "../../../model/wshub-models/roundingMode";
import {ChartSettings, Criteria, MapSettings, ViewerMode} from "../constants";
import {getCriteriaArrayFromObject} from "../../../utils/criteria";
import {localizeI18nObj} from "../../../utils/i18n";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

export type IndicatorVariable = {[key: string]: string};

export type Indicator = {
  title: {[key: string]: string};
  year: string;
  formula: string[];
  variables: {[key: string]: IndicatorVariable};
  variablesDataset: {[key: string]: string};
};

export type AdditionalDataset = {
  nodeId: number;
  nodeCode: string;
  datasetId: string;
  datasetTitle: string;
  dimensions: any[] | null;
  timeDim: string | null;
  freqDim: string | null;
  territoryDim: string | null;
  codelists: {[key: string]: any[]} | null;
  codelistsLength: (number | null)[] | null;
  criteria: {[key: string]: Criteria};
  detailLevels: number[];
};

export type DatasetMultiViewerState = {
  datasetUuid: string | null;
  notCompatibleDataset: boolean;
  dataset: any | null;
  isFirstFetch: boolean;
  datasetFetchStart: boolean;
  datasetFetchError: boolean;
  isPartialData: boolean;
  isEmptyData: boolean;
  isTooBigData: boolean;
  isPointData: boolean;
  latAttributeId: string | null;
  longAttributeId: string | null;
  srid: string | null;
  isTooLongQuery: boolean;
  tableColCount: number | null;
  mapPointCount: number | null;
  isCriteriaVisible: boolean;
  isCriteriaAlertVisible: boolean;
  isObsCountWarningVisible: boolean;
  dimensions: any[] | null;
  territoryDimCodelist: string | null;
  territoryDim: string | null;
  timeDim: string | null;
  freqDim: string | null;
  pointDim: string | null;
  measureDim: string | null;
  isTableVisible: boolean | null;
  isMapVisible: boolean | null;
  isChartVisible: boolean | null;
  chartType: string;
  view: any | null;
  template: any | null;
  hasViewLayout: boolean;
  hasTemplateLayout: boolean;
  tableLayout: any | null;
  mapLayout: any | null;
  chartLayout: any | null;
  tableFilterTree: any | null;
  mapFilterTree: any | null;
  chartFilterTree: any | null;
  timePeriodsByFreq: any | null;
  labelFormat: string | null;
  temporalDimOrder: string | null;
  criteria: {[key: string]: Criteria} | {};
  initialCriteria: {[key: string]: Criteria} | {};
  decimalSeparator: string | null;
  roundingStrategy: RoundingMode | null;
  decimalPlaces: number | null;
  tableEmptyChar: string;
  chartSettings: ChartSettings;
  mapSettings: MapSettings;
  codelists: {[key: string]: any[]} | null;
  codelistsLength: (number | null)[] | null;
  codelistFetchError: boolean;
  criteriaObsCount: number | null;
  timings: any | null;
  isFetchDatasetDisabled: boolean;
  isDownloadWarningVisible: boolean;
  isUnavailableViewWarningVisible: boolean;
  isViewVisible: boolean;
  isViewErrorVisible: boolean;
  viewErrorMessage: string | null;
  isTemplateVisible: boolean;
  isQueryVisible: boolean;
  structureQuery: string | null;
  dataQuery: string | null;
  isTerritoryVisible: boolean;
  detailLevelTree: any | null;
  detailLevel: number | null;
  territoryTree: any[] | null;
  territories: string[] | null;
  lastTerritoryYear: string | null;
  territorialClassifications: any;
  territorialClassificationsConfig: any;
  territorialClassificationsFetched: number[];
  territorialClassificationsValues: any;
  isIndicatorCreateVisible: boolean;
  isIndicatorListVisible: boolean;
  indicators: Indicator[];
  indicatorPreview: any;
  missingIndicators: string[] | null;
  showArithmeticMean: boolean;
  arithmeticMeanDims: string[] | null;
  arithmeticMeans: {[key: string]: number} | null;
  showStandardDeviation: boolean;
  showCoefficientOfVariation: boolean;
  isAdditionalDatasetCreateVisible: boolean;
  isAdditionalDatasetListVisible: boolean;
  additionalDatasets: AdditionalDataset[];
  additionalDatasetCatalog: ICategoryProvider | null;
  additionalDataset: AdditionalDataset | null;
  datasetCacheInfo: any | null;
  missingFilterValues: any[] | null;
  geometriesYear: string | null;
  geometriesYears: {[key: string]: string}[] | null;
};

export const getViewTemplateBackupFromMVState = (state: DatasetMultiViewerState) => ({
  mode: ViewerMode.MultiViewer,
  criteria: getCriteriaArrayFromObject(state.criteria),
  layouts: JSON.stringify({
    detailLevel: state.detailLevel,
    territories: state.territories,
    territorialClassificationsConfig: state.territorialClassificationsConfig,
    tableLayout: state.tableLayout,
    mapLayout: state.mapLayout,
    ...getViewTemplateLayoutsFromMapSettings(state.mapSettings),
    chartLayout: state.chartLayout,
    ...getViewTemplateLayoutsFromChartSettings(state.chartSettings),
    isTableVisible: state.isTableVisible,
    isMapVisible: state.isMapVisible,
    isChartVisible: state.isChartVisible,
    chartType: state.chartType,
    showArithmeticMean: state.showArithmeticMean,
    showStandardDeviation: state.showStandardDeviation,
    showCoefficientOfVariation: state.showCoefficientOfVariation
  }),
  indicatorsDefinition: JSON.stringify(state.indicators),
  additionalDatasets: JSON.stringify(state.additionalDatasets)
});

export const getIndicatorsDefinition = (
  indicators: Indicator[],
  datasets: any[],
  timeDim: string,
  defaultLanguage: string,
  languages: string[]
) => {
  return indicators.map((indicator: Indicator) => {
    const {title, year, formula, variables, variablesDataset} = indicator;

    return {
      name: localizeI18nObj(title, defaultLanguage, languages),
      formula: formula.map(el => (el.length > 1 ? "[" + el + "]" : el)).join(""),
      indicatorJoinDimensions: [datasets.map(({territoryDim}) => territoryDim)],
      targetValue: year,
      targetDimension: timeDim,
      dataflowFormulaItems: datasets.map(dataset => {
        const filteredVariables: {[key: string]: IndicatorVariable} = {};
        Object.keys(variables).forEach(variable => {
          if (variablesDataset[variable] === `${dataset.nodeCode},${dataset.datasetId}`) {
            filteredVariables[variable] = variables[variable];
          }
        });

        const newCriteria = {
          ...dataset.criteria,
          [dataset.territoryDim]: {
            ...datasets[0].criteria[datasets[0].territoryDim],
            id: dataset.territoryDim
          }
        };

        return {
          nodeId: dataset.nodeId,
          dataflowId: dataset.datasetId,
          formulaItems: Object.keys(filteredVariables).map(itemName => ({
            itemName: itemName,
            dimensionValues: Object.keys(filteredVariables[itemName]).map(id => ({
              id: id,
              value: filteredVariables[itemName][id]
            }))
          })),
          dataCriterias: getCriteriaArrayFromObject(newCriteria),
          dataflowJoinDimensions: [dataset.territoryDim]
        };
      })
    };
  });
};

export const getMarginalDefinition = (datasets: any[]) => {
  const territoryDims: string[] = [];
  const timeDims: string[] = [];

  const dataflowItems: any[] = [];

  datasets.forEach(dataset => {
    territoryDims.push(dataset.territoryDim);
    timeDims.push(dataset.timeDim);

    const newCriteria = {
      ...dataset.criteria,
      [dataset.territoryDim]: {
        ...datasets[0].criteria[datasets[0].territoryDim],
        id: dataset.territoryDim
      }
    };

    dataflowItems.push({
      nodeId: dataset.nodeId,
      dataflowId: dataset.datasetId,
      dataCriterias: getCriteriaArrayFromObject(newCriteria)
    });
  });

  return {
    dimensions: [territoryDims, timeDims],
    dataflowItems: dataflowItems
  };
};
