import React, {useCallback} from "react";
import {FormControl, MenuItem, TextField, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const HierarchicalCodelistSelector = ({dimension, selectedHierarchy, tabId, onHierarchyChange}) => {
  const {t} = useTranslation();

  const handleHierarchySelect = useCallback(
    hierarchyId => {
      if (onHierarchyChange) {
        onHierarchyChange(dimension.id, hierarchyId, tabId, []);
      }
    },
    [dimension, onHierarchyChange, tabId]
  );

  return (
    <FormControl fullWidth>
      <TextField
        id="criteria-container__codelist-header__hcl__selector"
        select
        value={selectedHierarchy || "none"}
        required
        variant="outlined"
        size="small"
        sx={{
          maxWidth: "240px",
          marginTop: "0px !important",
          "& .MuiTypography-root": {
            overflow: "hidden",
            textOverflow: "ellipsis"
          }
        }}
        onChange={({target}) => {
          handleHierarchySelect(target.value);
        }}
        SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
        disabled={(dimension.hierarchies || []).length <= 1}
      >
        {!selectedHierarchy && (
          <MenuItem value="none" disabled>
            <Tooltip title={t("components.criteria.hierarchicalCodelist.selector.placeholder")}>
              <Typography>{t("components.criteria.hierarchicalCodelist.selector.placeholder")}</Typography>
            </Tooltip>
          </MenuItem>
        )}
        {(dimension.hierarchies || []).map(({id, label}, idx) => (
          <MenuItem key={idx} value={id}>
            <Tooltip title={label}>
              <Typography>{label}</Typography>
            </Tooltip>
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default HierarchicalCodelistSelector;
