import React, {Fragment, useEffect, useState} from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CancelIcon from "@mui/icons-material/Cancel";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {goToDataset} from "../../../links";
import CustomDialogTitle from "../../custom-dialog-title";
import {getDatasetStorageKey} from "../../../utils/other";

const SCROLL_STEP = 100;

function SingleViewerPlusFooter(props) {
  const {dataset, onDatasetsChange} = props;

  const {t} = useTranslation();

  const [datasets, setDatasets] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(-1);

  const [datasetToRemove, setDatasetToRemove] = useState(null);

  const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true);
  const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(true);

  const handleScrollbar = () => {
    const sheets = document.getElementById("data-viewer__footer__sheets");
    setIsScrollLeftDisabled(sheets?.scrollLeft === 0);
    setIsScrollRightDisabled(sheets?.scrollLeft >= sheets?.scrollWidth - sheets?.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScrollbar);
    return () => window.removeEventListener("resize", handleScrollbar);
  }, []);

  useEffect(() => {
    let newDatasets = JSON.parse(sessionStorage.getItem("datasets")) || [];
    setDatasets(newDatasets);
  }, []);

  useEffect(() => {
    const newSelectedIdx = (datasets || []).findIndex(
      data => getDatasetStorageKey(data) === getDatasetStorageKey(dataset)
    );
    setSelectedIdx(newSelectedIdx);

    const sheets = document.getElementById("data-viewer__footer__sheets");
    if (sheets) {
      sheets.scrollLeft = newSelectedIdx * 208;
    }
    handleScrollbar();

    onDatasetsChange();
  }, [datasets, dataset, onDatasetsChange]);

  const handleRemoveDataset = () => {
    const newDatasets = datasets.filter(
      dataset => getDatasetStorageKey(dataset) !== getDatasetStorageKey(datasetToRemove)
    );

    sessionStorage.setItem("datasets", JSON.stringify(newDatasets));
    sessionStorage.removeItem(getDatasetStorageKey(datasetToRemove));
    setDatasets(newDatasets);

    setDatasetToRemove(null);
  };

  const handleAddDataset = () => {
    const newDatasets = _.uniqWith(
      [...datasets, dataset],
      (a, b) => getDatasetStorageKey(a) === getDatasetStorageKey(b)
    );

    sessionStorage.setItem("datasets", JSON.stringify(newDatasets));
    setDatasets(newDatasets);
  };

  const handleScrollLeft = () => {
    const sheets = document.getElementById("data-viewer__footer__sheets");
    if (sheets) {
      sheets.scrollLeft -= SCROLL_STEP;
    }
    handleScrollbar();
  };

  const handleScrollRight = () => {
    const sheets = document.getElementById("data-viewer__footer__sheets");
    if (sheets) {
      sheets.scrollLeft += SCROLL_STEP;
    }
    handleScrollbar();
  };

  return (
    <Fragment>
      <Box sx={{whiteSpace: "nowrap"}}>
        <Box
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            width: "72px",
            margin: "0 12px",
            "& button": {
              padding: "6px"
            }
          }}
        >
          <IconButton
            onClick={handleScrollLeft}
            disabled={isScrollLeftDisabled}
            aria-label={t("scenes.dataViewer.footer.actions.scrollLeft.ariaLabel")}
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            onClick={handleScrollRight}
            disabled={isScrollRightDisabled}
            aria-label={t("scenes.dataViewer.footer.actions.scrollRight.ariaLabel")}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
        <Box
          id="data-viewer__footer__sheets"
          sx={{
            overflow: "hidden",
            display: "inline-block",
            verticalAlign: "middle",
            width: `calc(100% - 112px)`,
            marginLeft: "8px",
            marginRight: "8px"
          }}
        >
          {datasets.map((dataset, key) => (
            <Box
              key={key}
              sx={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "8px"
              }}
            >
              <Tooltip title={dataset.datasetTitle}>
                <Chip
                  sx={{maxWidth: "200px"}}
                  label={dataset.datasetTitle}
                  color={key === selectedIdx ? "primary" : undefined}
                  onClick={() => goToDataset(dataset.nodeCode, dataset.categoryPath, dataset.datasetId, dataset.viewId)}
                  deleteIcon={
                    <Tooltip title={t("scenes.dataViewer.footer.dataset.delete")}>
                      <CancelIcon />
                    </Tooltip>
                  }
                  onDelete={() =>
                    setDatasetToRemove({
                      nodeCode: dataset.nodeCode,
                      categoryPath: dataset.categoryPath,
                      datasetId: dataset.datasetId,
                      viewId: dataset.viewId
                    })
                  }
                />
              </Tooltip>
            </Box>
          ))}
          <Chip
            label={t("scenes.dataViewer.footer.dataset.add")}
            color={"primary"}
            onClick={() => {
              handleAddDataset();
            }}
            disabled={selectedIdx !== -1}
          />
        </Box>
      </Box>

      <Dialog open={datasetToRemove !== null} onClose={() => setDatasetToRemove(null)}>
        <CustomDialogTitle onClose={() => setDatasetToRemove(null)}>
          {t("scenes.dataViewer.footer.dialogs.datasetDelete.title")}
        </CustomDialogTitle>
        <DialogActions>
          <Button onClick={() => setDatasetToRemove(null)} color="primary">
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleRemoveDataset} color="primary" autoFocus>
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default SingleViewerPlusFooter;
