import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";
import {CardHeader, Paper} from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Call from "../../../hocs/call";
import ChartSettingsColors from "../../chart-settings-forms/Colors";
import ChartSettingsGeneral from "../../chart-settings-forms/General";
import ChartJsonStatWrapper from "../../chart/ChartJsonStatWrapper";
import ChartZoomIn from "../../chart/ChartZoomIn";
import ChartZoomOut from "../../chart/ChartZoomOut";
import ChartZoomReset from "../../chart/ChartZoomReset";
import {CHART_TYPE_BAR, CHART_TYPE_LINE} from "../../chart/constants";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import DatasetFiltersCodelistsWrapper from "../../dataset-filters/DatasetFiltersCodelistsWrapper";
import {FONT_SIZE_SELECTOR_FONT_SIZE_MD, FONT_SIZE_SELECTOR_FONT_SIZE_SM} from "../../font-size-selector";
import MapGeneralSettings from "../../map-settings-form/General";
import MapSettingsSettings from "../../map-settings-form/Settings";
import Map from "../../map/single-viewer/Map";
import TableLayoutLight from "../../table-layout-light";
import {
  TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE,
  TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT,
  TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE,
  TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT
} from "../../table-settings-form/constants";
import TableSettings from "../../table-settings-form/Settings";
import Table, {
  JSONSTAT_TABLE_FONT_SIZE_LG,
  JSONSTAT_TABLE_FONT_SIZE_MD,
  JSONSTAT_TABLE_FONT_SIZE_SM
} from "../../table/single-viewer";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../../temporal-dim-order-selector/constants";
import VariationSelector from "../../variation-selector";
import {getViewerTypeFromIdx, isChartLayoutCartesianByViewerIdx, viewersFactory} from "../constant";
import {
  enableDatasetSVPDatasetFetch,
  fetchDatasetSVPDataset,
  setDatasetSVPChartSettings,
  setDatasetSVPHtmlGeneratingTime,
  setDatasetSVPLabelFormat,
  setDatasetSVPMapDetailLevel,
  setDatasetSVPMapSettings,
  setDatasetSVPTableSettings,
  setDatasetSVPTemporalDimOrder,
  setDatasetSVPVariation,
  showDatasetSVPCriteria,
  showDatasetSVPLayout,
  submitDatasetSVPChartLayout,
  submitDatasetSVPDownload,
  submitDatasetSVPMapLayout,
  submitDatasetSVPTableLayout
} from "../../../state/dataset/single-viewer-plus/actions";
import {getFilteredCriteriaFromLayout} from "../../../utils/criteria";
import {
  getDatasetSize,
  getDimensionLabel,
  getDimensionValueLabel,
  getSVPFilteredTableLayout,
  getUpdatedLayout,
  MAX_ALLOWED_CELLS
} from "../../../utils/dataset";
import {DOWNLOAD_FORMAT_CSV, downloadFormats} from "../../../utils/download";
import {numberFormatter} from "../../../utils/formatters";

const $ = window.jQuery;

const actionStyle = {
  height: "40px",
  "& button": {
    padding: "8px"
  }
};

const paperStyle = {
  padding: "16px"
};

const cardHeaderStyle = {
  padding: "0 0 8px 0"
};
const mapStateToProps = ({app, appConfig, hub, dataset}) => {
  const viewerIdx = dataset.singleViewerPlus.viewerIdx;

  const tableLayout = dataset.singleViewerPlus.tableLayout;
  const mapLayout = dataset.singleViewerPlus.mapLayout;

  const chartLayoutCartesian = dataset.singleViewerPlus.chartLayoutCartesian;
  const chartLayoutRadial = dataset.singleViewerPlus.chartLayoutRadial;

  return {
    defaultLanguage: app.language,
    languages: app.languages,
    maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
    maxCells: hub.hub.maxCells || MAX_ALLOWED_CELLS,
    showSingleGeometry: appConfig.mapConfig.showSingleGeometry,
    dataset: dataset.singleViewerPlus.dataset,
    isFetchStarted: dataset.singleViewerPlus.isFetchStarted,
    isFetchFailed: dataset.singleViewerPlus.isFetchFailed,
    isPartialData: dataset.singleViewerPlus.isPartialData,
    isEmptyData: dataset.singleViewerPlus.isEmptyData,
    isTooBigData: dataset.singleViewerPlus.isTooBigData,
    dimensions: dataset.singleViewerPlus.dimensions,
    dimensionsInfo: dataset.singleViewerPlus.dimensionsInfo,
    timeDim: dataset.singleViewerPlus.timeDim,
    freqDim: dataset.singleViewerPlus.freqDim,
    territoryDim: dataset.singleViewerPlus.territoryDim,
    viewerIdx: viewerIdx,
    isViewerHoverVisible: dataset.singleViewerPlus.isViewerHoverVisible,
    layout:
      viewerIdx === 0
        ? tableLayout
        : viewerIdx === 1
          ? mapLayout
          : isChartLayoutCartesianByViewerIdx(viewerIdx)
            ? chartLayoutCartesian
            : chartLayoutRadial,
    tableLayout: dataset.singleViewerPlus.tableLayout,
    mapLayout: dataset.singleViewerPlus.mapLayout,
    chartLayout: isChartLayoutCartesianByViewerIdx(viewerIdx) ? chartLayoutCartesian : chartLayoutRadial,
    labelFormat: dataset.singleViewerPlus.labelFormat,
    customLabelFormats: dataset.singleViewerPlus.customLabelFormats,
    temporalDimOrder: dataset.singleViewerPlus.temporalDimOrder,
    showTrend: dataset.singleViewerPlus.showTrend,
    showCyclical: dataset.singleViewerPlus.showCyclical,
    areCriteriaApplied: dataset.singleViewerPlus.areCriteriaApplied,
    criteria: dataset.singleViewerPlus.criteria,
    decimalSeparator: dataset.singleViewerPlus.decimalSeparator,
    roundingStrategy: dataset.singleViewerPlus.roundingStrategy,
    decimalPlaces: dataset.singleViewerPlus.decimalPlaces,
    tableEmptyChar: dataset.singleViewerPlus.tableEmptyChar,
    chartSettings: dataset.singleViewerPlus.chartSettings,
    mapSettings: dataset.singleViewerPlus.mapSettings,
    enableVariation: dataset.singleViewerPlus.enableVariation,
    codelistTrees: dataset.singleViewerPlus.codelistTrees,
    codelistLists: dataset.singleViewerPlus.codelistFilteredLists,
    codelistMaps: dataset.singleViewerPlus.codelistMaps,
    detailLevel: dataset.singleViewerPlus.detailLevel,
    isFetchDatasetDisabled: dataset.singleViewerPlus.isFetchDatasetDisabled,
    isFullscreen: dataset.singleViewerPlus.isFullscreen,
    splitHeaderCells: dataset.singleViewerPlus.splitHeaderCells,
    splitSideCells: dataset.singleViewerPlus.splitSideCells,
    lastNObservations: dataset.singleViewerPlus.lastNObservations,
    attributesAsDim: dataset.singleViewerPlus.attributesAsDim
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: limit => dispatch(enableDatasetSVPDatasetFetch(limit)),
  onDatasetFetch: (nodeId, datasetId, datasetTitle, criteria, lastNObservations) =>
    dispatch(fetchDatasetSVPDataset(nodeId, datasetId, datasetTitle, criteria, lastNObservations)),
  onCriteriaShow: initialCriteriaDimension => dispatch(showDatasetSVPCriteria(initialCriteriaDimension)),
  onLayoutShow: () => dispatch(showDatasetSVPLayout()),
  onTableLayoutSet: (layout, enableFetch) => dispatch(submitDatasetSVPTableLayout(layout, enableFetch)),
  onMapLayoutSet: layout => dispatch(submitDatasetSVPMapLayout(layout)),
  onChartLayoutSet: layout => dispatch(submitDatasetSVPChartLayout(layout)),
  onLabelFormatSet: labelFormat => dispatch(setDatasetSVPLabelFormat(labelFormat)),
  onVariationSet: variation => dispatch(setDatasetSVPVariation(variation)),
  onTableSettingsSet: tableSetting => dispatch(setDatasetSVPTableSettings(tableSetting)),
  onChartSettingsSet: chartSetting => dispatch(setDatasetSVPChartSettings(chartSetting)),
  onMapSettingsSet: mapSettings => dispatch(setDatasetSVPMapSettings(mapSettings)),
  onDetailLevelSet: detailLevel => dispatch(setDatasetSVPMapDetailLevel(detailLevel)),
  onTimeSet: time => dispatch(setDatasetSVPHtmlGeneratingTime(time)),
  onDownloadSubmit: (
    nodeId,
    datasetId,
    datasetTitle,
    criteria,
    lastNObservations,
    layout,
    format,
    extension,
    zipped,
    params,
    defaultLanguage,
    languages,
    t
  ) =>
    dispatch(
      submitDatasetSVPDownload(
        nodeId,
        datasetId,
        datasetTitle,
        criteria,
        lastNObservations,
        layout,
        format,
        extension,
        zipped,
        params,
        defaultLanguage,
        languages,
        t
      )
    ),
  onTemporalDimOrderSet: temporalDimOrder => dispatch(setDatasetSVPTemporalDimOrder(temporalDimOrder))
});

const handleStyle = mapId => {
  const $actions = $("#data-viewer__viewer__header__actions");
  const $filters = $("#data-viewer__viewer__header__filters");
  const $filtersAndActions = $("#data-viewer__viewer__header__filters-actions-container");

  const actionsWidth = $actions.outerWidth(true) || 0;
  const filtersWidth = $filters.outerWidth(true) || 0;
  const filtersAndActionsWidth = $filtersAndActions.width() || 0;

  if (Math.ceil(filtersAndActionsWidth) > Math.ceil(actionsWidth + filtersWidth + 8)) {
    $filtersAndActions.css({flexDirection: "row"});
    $actions.css({alignSelf: "unset"});
  } else {
    $filtersAndActions.css({flexDirection: "column-reverse"});
    $actions.css({alignSelf: "end"});
  }

  const viewerHeaderHeight = $("#data-viewer__viewer__header").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers").height(`calc(100% - ${viewerHeaderHeight}px)`);

  if (window.LMap && mapId) {
    window.LMap.handleViewportChange(mapId);
  }
};

function SingleViewerPlusViewer(props) {
  const {
    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetTitle,
    chartId,
    mapId,

    defaultLanguage,
    languages,
    maxObservations,
    maxCells,
    showSingleGeometry,
    dataset,
    isFetchStarted,
    isFetchFailed,
    isPartialData,
    isEmptyData,
    isTooBigData,
    dimensions,
    dimensionsInfo,
    timeDim,
    freqDim,
    territoryDim,
    viewerIdx,
    isViewerHoverVisible,
    layout,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    customLabelFormats,
    temporalDimOrder,
    showTrend,
    showCyclical,
    areCriteriaApplied,
    criteria,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    enableVariation,
    codelistLists,
    codelistMaps,
    codelistTrees,
    detailLevel,
    isFetchDatasetDisabled,
    isFullscreen,
    splitHeaderCells,
    splitSideCells,
    lastNObservations,
    attributesAsDim,

    onFetchDatasetEnable,
    onDatasetFetch,
    onCriteriaShow,
    onLayoutShow,
    onTableLayoutSet,
    onMapLayoutSet,
    onChartLayoutSet,
    onLabelFormatSet,
    onVariationSet,
    onTableSettingsSet,
    onChartSettingsSet,
    onMapSettingsSet,
    onDetailLevelSet,
    onTimeSet,
    onDownloadSubmit,
    onTemporalDimOrderSet
  } = props;

  const {t} = useTranslation();

  const [tableViewerLayout, setTableViewerLayout] = useState(null);
  const [mapViewerLayout, setMapViewerLayout] = useState(null);
  const [chartViewerLayout, setChartViewerLayout] = useState(null);

  const [fontSize, setFontSize] = useState(FONT_SIZE_SELECTOR_FONT_SIZE_MD);

  const [commonSettings, setCommonSettings] = useState(null);

  const [isTableSettingVisible, setTableSettingsVisibility] = useState(false);
  const [tableSettings, setTableSettings] = useState(null);
  const [tableOrdering, setTableOrdering] = useState(null);

  const [isChartSettingVisible, setChartSettingsVisibility] = useState(false);
  const [chartSettingsTabId, setChartSettingsTabId] = useState(null);
  const [tmpChartSettings, setTmpChartSettings] = useState(null);

  const [isCommonMapSettingVisible, setCommonMapSettingsVisibility] = useState(false);
  const [isMapSettingVisible, setMapSettingsVisibility] = useState(false);
  const [tmpMapSettings, setTmpMapSettings] = useState(null);
  const [isMapSettingsValid, setMapSettingsValidity] = useState(true);

  const [isTableLayoutLightVisible, setTableLayoutLightVisibility] = useState(false);

  const [hierarchyOnlyAttributes] = useState(nodeExtras?.HierarchyOnlyAttributes || []);
  const [hideHierarchyOnlyRows] = useState(nodeExtras?.HideHierarchyOnlyRows || false);

  const chartRef = useRef();

  useEffect(() => {
    const func = () => handleStyle(mapId);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [mapId]);

  useEffect(() => {
    handleStyle(mapId);
  });

  const decodeHeaderSideCellsMode = () => {
    if (!splitHeaderCells && splitSideCells) {
      return TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT;
    } else if (splitHeaderCells && !splitSideCells) {
      return TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE;
    } else if (splitHeaderCells && splitSideCells) {
      return TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT;
    } else {
      return TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE;
    }
  };

  const encodeTableSettingsToDispatch = () => {
    let ts = {
      ...commonSettings
    };
    switch (tableSettings.mergeSplitCells) {
      case TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE:
        ts.splitHeaderCells = false;
        ts.splitSideCells = false;
        break;
      case TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT:
        ts.splitHeaderCells = false;
        ts.splitSideCells = true;

        break;
      case TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE:
        ts.splitHeaderCells = true;
        ts.splitSideCells = false;
        break;
      case TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT:
        ts.splitHeaderCells = true;
        ts.splitSideCells = true;
        break;
      default:
        ts.splitHeaderCells = false;
        ts.splitSideCells = false;
        break;
    }

    return ts;
  };

  const handleTableOrdering = useCallback(() => {
    const isTimeDimInTable = !tableLayout?.filters.includes(timeDim);
    const order = temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && isTimeDimInTable ? [timeDim] : null;
    setTableOrdering(order);
  }, [temporalDimOrder, tableLayout, timeDim, setTableOrdering]);

  useEffect(() => {
    handleTableOrdering();
  }, [temporalDimOrder, handleTableOrdering]);

  useEffect(() => {
    setTableViewerLayout(null);
    setMapViewerLayout(null);
    setChartViewerLayout(null);
  }, [viewerIdx]);

  useEffect(() => {
    if (!isViewerHoverVisible && viewerIdx === 0) {
      setTableViewerLayout(tableLayout);
    }
  }, [tableLayout, isViewerHoverVisible, viewerIdx]);

  useEffect(() => {
    if (!isViewerHoverVisible && viewerIdx === 1) {
      setMapViewerLayout(mapLayout);
    }
  }, [mapLayout, isViewerHoverVisible, viewerIdx]);

  useEffect(() => {
    if (!isViewerHoverVisible && viewerIdx >= 2) {
      setChartViewerLayout(chartLayout);
    }
  }, [chartLayout, isViewerHoverVisible, viewerIdx]);

  useEffect(() => {
    setTmpChartSettings(chartSettings);
  }, [chartSettings]);

  useEffect(() => {
    setTmpMapSettings(mapSettings);
  }, [mapSettings]);

  const onTablePageGenerationComplete = useCallback(
    generatedTableStructure => {
      const timings = generatedTableStructure.timings;
      if (timings) {
        onTimeSet(Math.round(generatedTableStructure.timings * 100) / 100);
      }
    },
    [onTimeSet]
  );

  const handleFilterSelect = useCallback(
    (dimension, value) => {
      if (viewerIdx === 0) {
        onTableLayoutSet(getUpdatedLayout(dimension, value, tableLayout, dataset, null, timeDim));
      } else if (viewerIdx === 1) {
        onMapLayoutSet(getUpdatedLayout(dimension, value, mapLayout, dataset, null, timeDim));
      } else if (viewerIdx >= 2) {
        onChartLayoutSet(getUpdatedLayout(dimension, value, chartLayout, dataset, null, timeDim));
      }
    },
    [
      viewerIdx,
      dataset,
      tableLayout,
      mapLayout,
      chartLayout,
      timeDim,
      onTableLayoutSet,
      onMapLayoutSet,
      onChartLayoutSet
    ]
  );

  const handleTableSettingsOpen = () => {
    setCommonSettings({temporalDimOrder, labelFormat});
    setTableSettings({mergeSplitCells: decodeHeaderSideCellsMode(), fontSize: fontSize});

    setTableSettingsVisibility(true);
  };

  const handleTableSettingsClose = () => {
    setTableSettings({...tableSettings, fontSize: fontSize});
    setCommonSettings({temporalDimOrder, labelFormat});
    setTableSettingsVisibility(false);
  };

  const handleTableSettingsSubmit = () => {
    setTableSettingsVisibility(false);
    onTableSettingsSet(encodeTableSettingsToDispatch());
    setFontSize(tableSettings.fontSize);
  };

  const handleFetchDataset = useCallback(
    ({nodeId, datasetId, datasetTitle, criteria, lastNObservations}) =>
      onDatasetFetch(nodeId, datasetId, datasetTitle, criteria, lastNObservations),
    [onDatasetFetch]
  );

  const handleChartSettingsOpen = () => {
    setTmpChartSettings(chartSettings);
    setCommonSettings({temporalDimOrder, labelFormat});
    setChartSettingsTabId("general");
    setChartSettingsVisibility(true);
  };

  const handleChartSettingsClose = () => {
    setChartSettingsVisibility(false);
    setTmpChartSettings(chartSettings);

    setCommonSettings({temporalDimOrder, labelFormat});
  };

  const handleChartSettingsSubmit = () => {
    setChartSettingsVisibility(false);
    onChartSettingsSet(tmpChartSettings);

    onTemporalDimOrderSet(commonSettings.temporalDimOrder);
    onLabelFormatSet(commonSettings.labelFormat);
  };

  const handleCommonMapSettingsClose = () => {
    setCommonSettings({labelFormat});
    setCommonMapSettingsVisibility(false);
  };

  const handleCommonMapSettingsOpen = () => {
    setCommonSettings({labelFormat});
    setCommonMapSettingsVisibility(true);
  };

  const handleCommonMapSettingsSubmit = () => {
    setCommonMapSettingsVisibility(false);
    onLabelFormatSet(commonSettings.labelFormat);
    onMapSettingsSet(tmpMapSettings);
  };

  const handleMapSettingsOpen = useCallback(settings => {
    setTmpMapSettings(settings);
    setMapSettingsValidity(true);
    setMapSettingsVisibility(true);
  }, []);

  const handleMapSettingsClose = () => {
    setMapSettingsVisibility(false);
  };

  const handleMapSettingsSubmit = () => {
    setMapSettingsVisibility(false);
    onMapSettingsSet(tmpMapSettings);
  };

  const chartZoomReset = useCallback(() => {
    if (chartRef && chartRef.current) chartRef.current.zoomReset();
  }, [chartRef]);

  const chartZoomIn = useCallback(() => {
    if (chartRef && chartRef.current) chartRef.current.zoomIn();
  }, [chartRef]);

  const chartZoomOut = useCallback(() => {
    if (chartRef && chartRef.current) chartRef.current.zoomOut();
  }, [chartRef]);

  const datasetSize = getDatasetSize(dimensionsInfo, layout);

  return (
    <Fragment>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          padding: "16px"
        }}
      >
        {viewerIdx !== null && (
          <div id="data-viewer__viewer__header">
            {isPartialData && (
              <Alert
                severity="warning"
                sx={{
                  width: "100%",
                  marginBottom: "16px"
                }}
              >
                {t("scenes.dataViewer.warnings.maxObservations.label", {
                  maxObservations: maxObservations ? numberFormatter(maxObservations) : ""
                })}
              </Alert>
            )}
            <Box
              id="data-viewer__viewer__header__filters-actions-container"
              sx={{
                width: "100%",
                paddingBottom: theme => theme.spacing(1),
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start"
              }}
            >
              <Box id="data-viewer__viewer__header__filters">
                <DatasetFiltersCodelistsWrapper
                  nodeCode={nodeCode}
                  datasetId={datasetId}
                  dimensions={dimensions}
                  dimensionsInfo={dimensionsInfo}
                  codelistLists={codelistLists}
                  codelistMaps={codelistMaps}
                  codelistTrees={codelistTrees}
                  criteria={criteria}
                  timeDim={timeDim}
                  layout={layout}
                  jsonStat={dataset}
                  labelFormat={labelFormat}
                  onSelect={handleFilterSelect}
                  onCriteriaShow={onCriteriaShow}
                  limit={maxObservations}
                  enclosingContainerId={"#data-viewer__viewer__header__filters-actions-container"} // data-viewer__viewer__header
                />
              </Box>
              <Box id="data-viewer__viewer__header__actions" sx={{marginBottom: "8px"}}>
                <Grid container sx={{alignItems: "baseline"}}>
                  {enableVariation &&
                    (viewerIdx === 0 ||
                      getViewerTypeFromIdx(viewerIdx) === CHART_TYPE_BAR ||
                      getViewerTypeFromIdx(viewerIdx) === CHART_TYPE_LINE) && (
                      <Grid item id="variation-selector" sx={actionStyle}>
                        <VariationSelector
                          showTrend={showTrend}
                          showCyclical={showCyclical}
                          onVariationSet={onVariationSet}
                        />
                      </Grid>
                    )}
                  {viewerIdx >= 2 && (
                    <Fragment>
                      <Grid item id="dataset-zoom-reset-container">
                        <ChartZoomReset id={"dataset-zoom-reset-btn"} color={"action"} onClick={chartZoomReset} />
                      </Grid>
                      <Grid item id="dataset-zoom-out-container">
                        <ChartZoomOut id={"dataset-zoom-out-btn"} color={"action"} onClick={chartZoomOut} />
                      </Grid>
                      <Grid item id="dataset-zoom-in-container">
                        <ChartZoomIn id={"dataset-zoom-in-btn"} color={"action"} onClick={chartZoomIn} />
                      </Grid>
                    </Fragment>
                  )}
                  <Grid item id="export-csv" sx={actionStyle}>
                    <Tooltip title={t("scenes.dataViewer.actions.exportCsv.tooltip")}>
                      <IconButton
                        aria-label={t("scenes.dataViewer.actions.exportCsv.ariaLabel")}
                        onClick={() => {
                          const exportParams = {
                            labelFormat: labelFormat,
                            customLabelFormat: customLabelFormats,
                            decimalSeparator: decimalSeparator,
                            roundingStrategy: roundingStrategy,
                            decimalNumber: decimalPlaces,
                            emptyCellPlaceHolder: tableEmptyChar,
                            hasVariation: enableVariation,
                            showTrend: showTrend,
                            showCyclical: showCyclical,
                            temporalDimOrder: temporalDimOrder
                          };

                          const newCriteria = getFilteredCriteriaFromLayout(criteria, dimensions, layout, timeDim);
                          if (viewerIdx === 1 && layout?.territoryDim) {
                            const territoryDim = layout.territoryDim;
                            newCriteria[territoryDim] = {
                              id: territoryDim,
                              filterValues: window.LMap.getDataIds(mapId)
                            };
                          }

                          onDownloadSubmit(
                            nodeId,
                            datasetId,
                            datasetTitle,
                            newCriteria,
                            lastNObservations,
                            layout,
                            DOWNLOAD_FORMAT_CSV,
                            downloadFormats()[DOWNLOAD_FORMAT_CSV].extension,
                            false,
                            exportParams,
                            defaultLanguage,
                            languages,
                            t
                          );
                        }}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {viewerIdx >= 2 && (
                    <Grid item id="chart-settings" sx={actionStyle}>
                      <Tooltip title={t("scenes.dataViewer.actions.chartSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.chartSettings.ariaLabel")}
                          onClick={handleChartSettingsOpen}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {viewerIdx === 1 && (
                    <Grid item id="map-settings" sx={actionStyle}>
                      <Tooltip title={t("scenes.dataViewer.actions.mapSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.mapSettings.ariaLabel")}
                          onClick={handleCommonMapSettingsOpen}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {viewerIdx === 0 && (
                    <Grid item id="table-settings" sx={actionStyle}>
                      <Tooltip title={t("scenes.dataViewer.actions.tableSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.tableSettings.ariaLabel")}
                          onClick={handleTableSettingsOpen}
                          id="data_viewer_single_viewer_plus_viewer_settings_btn"
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </div>
        )}
        <Box
          id="data-viewer__viewer__viewers"
          sx={{
            width: "100%",
            height: "100%",
            position: "relative"
          }}
        >
          {isViewerHoverVisible && (
            <CustomEmpty style={{position: "absolute", zIndex: 1}}>
              <Button
                id="update-viewer-btn"
                variant="contained"
                color="primary"
                endIcon={<RefreshIcon />}
                onClick={() =>
                  onFetchDatasetEnable(viewerIdx === 0 ? Math.min(maxObservations, maxCells) : maxObservations)
                }
              >
                <Trans
                  i18nKey={
                    viewerIdx === 0
                      ? t("scenes.dataViewer.singleViewerPlus.viewerUpdateHover.table.label")
                      : viewerIdx === 1
                        ? t("scenes.dataViewer.singleViewerPlus.viewerUpdateHover.map.label")
                        : t("scenes.dataViewer.singleViewerPlus.viewerUpdateHover.chart.label")
                  }
                />
              </Button>
            </CustomEmpty>
          )}
          <Box sx={{width: "100%", height: "100%", filter: isViewerHoverVisible ? "blur(2px)" : undefined}}>
            {!dimensions || !codelistLists ? (
              <CustomEmpty>{""}</CustomEmpty>
            ) : !areCriteriaApplied ? (
              <CustomEmpty>{t("scenes.dataViewer.errors.applyCriteria")}</CustomEmpty>
            ) : viewerIdx === 0 && (isTooBigData || datasetSize > Math.min(maxObservations, maxCells)) ? (
              <Call cb={setTableLayoutLightVisibility} cbParam={true}>
                <CustomEmpty text={t("scenes.dataViewer.singleViewerPlus.errors.tooBigTable")} />
              </Call>
            ) : viewerIdx === 1 && (isTooBigData || datasetSize > maxObservations) ? (
              <Call cb={onCriteriaShow}>
                <CustomEmpty text={t("scenes.dataViewer.singleViewerPlus.errors.tooBigMap")} />
              </Call>
            ) : viewerIdx >= 2 && (isTooBigData || datasetSize > maxObservations) ? (
              <Call cb={onLayoutShow}>
                <CustomEmpty text={t("scenes.dataViewer.singleViewerPlus.errors.tooBigChart")} />
              </Call>
            ) : (
              <Call
                cb={handleFetchDataset}
                cbParam={{
                  nodeId,
                  datasetId,
                  datasetTitle,
                  criteria: getFilteredCriteriaFromLayout(criteria, dimensions, layout, timeDim),
                  lastNObservations
                }}
                disabled={isFetchDatasetDisabled}
              >
                {isFetchStarted ? (
                  <CustomEmpty>{""}</CustomEmpty>
                ) : isFetchFailed ? (
                  <CustomEmpty>{t("scenes.dataViewer.errors.fetchData")}</CustomEmpty>
                ) : isEmptyData ? (
                  <CustomEmpty>{t("scenes.dataViewer.errors.emptyData")}</CustomEmpty>
                ) : dataset && viewerIdx === 0 && tableViewerLayout ? (
                  <Table
                    jsonStat={dataset}
                    layout={tableViewerLayout}
                    isFullscreen={isFullscreen}
                    labelFormat={labelFormat}
                    showTrend={showTrend}
                    showCyclical={showCyclical}
                    fontSize={
                      fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_SM
                        ? JSONSTAT_TABLE_FONT_SIZE_SM
                        : fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_MD
                          ? JSONSTAT_TABLE_FONT_SIZE_MD
                          : JSONSTAT_TABLE_FONT_SIZE_LG
                    }
                    decimalSeparator={decimalSeparator}
                    roundingStrategy={roundingStrategy}
                    decimalPlaces={decimalPlaces}
                    emptyChar={tableEmptyChar}
                    invertedDims={tableOrdering}
                    hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                    hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                    onPageGenerationComplete={onTablePageGenerationComplete}
                    filterable
                    sortable
                    splitHeaderCells={splitHeaderCells}
                    splitSideCells={splitSideCells}
                    scrollToLastRow
                    attributesAsDim={attributesAsDim}
                  />
                ) : dataset && viewerIdx === 1 && mapViewerLayout ? (
                  <Map
                    mapId={mapId}
                    nodeId={nodeId}
                    jsonStat={dataset}
                    layout={mapViewerLayout}
                    labelFormat={labelFormat}
                    decimalSeparator={decimalSeparator}
                    roundingStrategy={roundingStrategy}
                    decimalPlaces={decimalPlaces}
                    defaultDetailLevel={detailLevel}
                    onDetailLevelChange={onDetailLevelSet}
                    initialBaseLayer={mapSettings.baseLayer}
                    defaultSettings={{
                      isLegendCollapsed: mapSettings.isLegendCollapsed,
                      opacity: mapSettings.opacity
                    }}
                    settings={{
                      classificationMethod: mapSettings.classificationMethod,
                      paletteStartColor: mapSettings.paletteStartColor,
                      paletteEndColor: mapSettings.paletteEndColor,
                      paletteCardinality: mapSettings.paletteCardinality,
                      customIntervals: mapSettings.customIntervals
                    }}
                    setSettings={onMapSettingsSet}
                    isFullscreen={isFullscreen}
                    showSelection
                    disableSettings
                    onSettingsShow={handleMapSettingsOpen}
                    showSingleGeometry={showSingleGeometry}
                  />
                ) : dataset && viewerIdx >= 2 && chartViewerLayout ? (
                  <ChartJsonStatWrapper
                    key={viewerIdx} // added to force chart render on type change, due the layout change
                    ref={chartRef}
                    chartId={chartId}
                    type={viewersFactory(t)[viewerIdx].chartType}
                    jsonStat={dataset}
                    layout={chartViewerLayout}
                    labelFormat={labelFormat}
                    showTrend={showTrend}
                    showCyclical={showCyclical}
                    decimalSeparator={decimalSeparator}
                    roundingStrategy={roundingStrategy}
                    decimalPlaces={decimalPlaces}
                    chartSettings={chartSettings}
                    invertedDims={
                      temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC &&
                      !chartViewerLayout.filters.includes(timeDim)
                        ? [timeDim]
                        : null
                    }
                  />
                ) : (
                  <CustomEmpty text={t("scenes.dataViewer.errors.viewerNotAvailable")} />
                )}
              </Call>
            )}
          </Box>
        </Box>
      </Card>

      <Dialog open={isTableSettingVisible} maxWidth="md" fullWidth onClose={handleTableSettingsClose}>
        <CustomDialogTitle onClose={handleTableSettingsClose}>
          {t("scenes.dataViewer.singleViewer.dialogs.table.title")}
        </CustomDialogTitle>
        <DialogContent>
          <TableSettings
            commonSettings={commonSettings}
            setCommonSettings={setCommonSettings}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTableSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button color="primary" onClick={handleTableSettingsSubmit}>
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isChartSettingVisible} maxWidth="md" fullWidth onClose={handleChartSettingsClose}>
        <CustomDialogTitle onClose={handleChartSettingsClose}>
          {t("scenes.dataViewer.singleViewerPlus.dialogs.chartSettings.title")}
        </CustomDialogTitle>
        <DialogContent sx={{height: "480px"}}>
          <Box sx={{height: "48px"}}>
            <Tabs
              value={chartSettingsTabId}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => setChartSettingsTabId(newValue)}
            >
              <Tab
                value={"general"}
                label={t("scenes.dataViewer.singleViewerPlus.dialogs.chartSettings.tabs.general")}
              />
              <Tab value={"colors"} label={t("scenes.dataViewer.singleViewerPlus.dialogs.chartSettings.tabs.colors")} />
            </Tabs>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 64px)",
              marginTop: "16px",
              paddingRight: "4px",
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            {chartSettingsTabId === "general" && (
              <ChartSettingsGeneral
                commonSettings={commonSettings}
                setCommonSettings={setCommonSettings}
                settings={tmpChartSettings}
                onSettingsSet={setTmpChartSettings}
              />
            )}
            {chartSettingsTabId === "colors" && (
              <ChartSettingsColors
                settings={tmpChartSettings}
                dimensions={dataset?.id || []}
                onSettingsSet={setTmpChartSettings}
                dataHasDimension={dim => dataset.id.includes(dim)}
                dataHasDimensionValue={(dim, dimVal) => dataset.dimension[dim].category.index.includes(dimVal)}
                getDimLabel={dimensionId => getDimensionLabel(dataset, null, dimensionId, t)}
                getDimValueLabel={(dimensionId, valueId) =>
                  getDimensionValueLabel(dataset, null, dimensionId, valueId, t)
                }
                getDimensionValues={dim => dataset.dimension[dim].category.index || []}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChartSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button autoFocus onClick={handleChartSettingsSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isCommonMapSettingVisible} maxWidth="md" fullWidth onClose={handleCommonMapSettingsClose}>
        <CustomDialogTitle onClose={handleCommonMapSettingsClose}>
          {t("scenes.dataViewer.singleViewer.dialogs.mapSettings.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Box sx={{marginBottom: "24px"}}>
            <MapGeneralSettings commonSettings={commonSettings} setCommonSettings={setCommonSettings} />
          </Box>
          <Paper variant="outlined" sx={paperStyle}>
            <CardHeader
              subheader={t("scenes.dataViewer.singleViewer.dialogs.mapSettings.title")}
              sx={cardHeaderStyle}
            />
            <MapSettingsSettings
              mapId={mapId}
              settings={tmpMapSettings}
              onSettingsSet={setTmpMapSettings}
              setSettingsValidity={setMapSettingsValidity}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCommonMapSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button color="primary" onClick={handleCommonMapSettingsSubmit} disabled={!isMapSettingsValid}>
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isMapSettingVisible} maxWidth="md" fullWidth onClose={handleMapSettingsClose}>
        <CustomDialogTitle onClose={handleMapSettingsClose}>
          {t("scenes.dataViewer.singleViewerPlus.dialogs.mapSettings.title")}
        </CustomDialogTitle>
        <DialogContent>
          <MapSettingsSettings
            mapId={mapId}
            settings={tmpMapSettings}
            onSettingsSet={setTmpMapSettings}
            setSettingsValidity={setMapSettingsValidity}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMapSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button color="primary" onClick={handleMapSettingsSubmit} disabled={!isMapSettingsValid}>
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <TableLayoutLight
        open={isTableLayoutLightVisible}
        onClose={() => setTableLayoutLightVisibility(false)}
        onSubmit={newLayout =>
          onTableLayoutSet(getSVPFilteredTableLayout(newLayout, codelistLists, timeDim, freqDim, territoryDim), true)
        }
        onLayoutOpen={onLayoutShow}
        layout={tableLayout}
        dimensionsInfo={dimensionsInfo}
        limit={Math.min(maxObservations, maxCells)}
      />
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleViewerPlusViewer);
