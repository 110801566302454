import {Grid} from "@mui/material";
import A11ySelect from "../a11y-select";

const A11yButton = ({getAdditionalA11yUrlParams, getCustomA11yPath}) => (
  <Grid item className="a11y-btn">
    <A11ySelect getAdditionalA11yUrlParams={getAdditionalA11yUrlParams} getCustomA11yPath={getCustomA11yPath} />
  </Grid>
);

export default A11yButton;
