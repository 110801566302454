import {useCallback, useEffect, useRef, useState} from "react";
import {createSelector} from "@reduxjs/toolkit";
import {AxiosHeaders} from "axios";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import rootSelector from "../rootSelector";
import {clearApi} from "./apiActions";
import {ApiResponseState, ApiState, DEFAULT_SLICE_KEY} from "./apiReducer";
import {initRequest} from "../../middlewares/request/requestActions";

const selectApi = <Data = any, Error = any>(label: string) =>
  createSelector(rootSelector, r => r.api[label] as ApiState<Data, Error>);

type RequestAction = ReturnType<typeof initRequest>;
type RequestPayload = Omit<RequestAction["payload"], "label"> & {clearCache?: boolean};

type UseApiOptions<D = any, E = any> = {
  /**
   * if true it immediately dispatches the request action
   * @default false
   */
  invokeImmediately?: boolean;
  /**
   * Callback invoked after the request is successful
   * @param data the successful response data
   * @param headers the successful response headers
   */
  onSuccess?: (data: D, headers: AxiosHeaders) => void;
  /**
   * Callback invoked if the request responds with an error
   * @param error the error response data
   * @returns
   */
  onError?: (error: E, statusCode: number, headers: AxiosHeaders) => void;
  /**
   * When set to true it enables the caching functionalities of the `useApi` hook.
   * If a cache key getter is not declared as an option (`getCacheKey`)
   * every request after the first one will not be dispatched
   * @default false
   */
  cache?: boolean;
  /**
   * function that calculates a (possibly) unique key given a request payload
   * The result of this fucntion is used as the key for a response stored in the state so that,
   * given the same request, the same response will be retrieved without dispatching again the request.
   * @param request
   * @returns the key for a given request
   */
  getCacheKey?: (request: RequestPayload) => string | symbol | number;
  /** clears the state for a request action, including any cached value */
  clearOnUnmount?: boolean;
  /**
   * when set to true it saves any response result under the request action label.
   * By doing so, any time useApi is used with the same action and this flag enabled,
   * the same data will be retrieved. Different cache keys may still be used by defining
   * `getCacheKey` function.
   * @default false
   */
  cacheAction?: boolean;
};

export type UseApiResult<Data = any, Error = any> = {
  data: Data;
  error: Error;
  loading: boolean;
  call: (v?: RequestPayload) => void;
  request: RequestPayload;
  clear: () => void;
  headers: AxiosHeaders;
  statusCode?: number;
};

const createResponseSubject = <Data = any, Error = any>() => ({
  next(v: ApiResponseState<Data, Error>) {
    this._subscription.next(v);
  },
  error(v: ApiResponseState<Data, Error>) {
    this._subscription.error(v);
  },
  subscribe(cb: {next: (d: ApiResponseState<Data, Error>) => void; error: (e: ApiResponseState<Data, Error>) => void}) {
    this._subscription = cb;
  },
  _subscription: {
    next: (d: ApiResponseState<Data, Error>) => {},
    error: (e: ApiResponseState<Data, Error>) => {}
  },
  unsubscribe() {
    this._subscription = {
      next: d => {},
      error: e => {}
    };
  }
});

/** Utilty hook that helps handling a RequestAction by dispatching it with specific parameters and monitors its status.
 * It uses the redux state slice `api` for storing request/response data.
 * It provides useful optional functionalities such as caching and callbacks on success and error result.
 * @param initialRequestAction - an action created by the `initRequest` function. If the action is not immediately dispatched (see option invokeImmediately),
 * all the parameters of the action payload may be added later when dispatching the action by using the returned functions `call` or `setRequest`
 * @param opts set of optional fields
 * */
const useApi = <Data = any, Error = any>(
  initialRequestAction: RequestAction,
  opts: UseApiOptions<Data, Error> = {invokeImmediately: false, getCacheKey: () => DEFAULT_SLICE_KEY}
): UseApiResult<Data, Error> => {
  const isUsingCache = opts?.cache || opts?.cacheAction;
  const resposeSubject = useRef(createResponseSubject<Data, Error>());
  const requestKey = useRef(opts?.cacheAction ? initialRequestAction.payload.label : uuidv4());
  const renderCountAfterRequestSent = useRef(0);
  const [options] = useState(opts);
  const lastRequestRef = useRef<RequestPayload>(initialRequestAction.payload);
  const [cacheSlice, setCacheSlice] = useState<string | symbol | number>(DEFAULT_SLICE_KEY);
  const currentCacheSlice = useRef<string | symbol | number>(DEFAULT_SLICE_KEY);
  const dispatch = useDispatch();
  const apiState = useSelector(selectApi<Data, Error>(requestKey.current));
  const res = apiState?.[cacheSlice];
  const firstInvocationDone = useRef(false);

  const dispatchRequest = useCallback(
    (request: RequestPayload, cacheKey: string | symbol | number) => {
      dispatch({
        ...initialRequestAction,
        payload: {
          uuid: uuidv4(),
          ...request,
          extra: {
            ...(request?.extra || {}),
            cacheKey: isUsingCache ? cacheKey : DEFAULT_SLICE_KEY,
            requestKey: requestKey.current
          }
        }
      });
    },
    [dispatch, initialRequestAction, isUsingCache]
  );

  const getCacheKey = useCallback(
    (request: RequestPayload) => opts?.getCacheKey?.(request) || DEFAULT_SLICE_KEY,
    [opts]
  );

  const call = useCallback(
    (newRequest?: RequestPayload) => {
      const currentRequest = newRequest || lastRequestRef.current;
      const cacheKey = getCacheKey(currentRequest);
      setCacheSlice(cacheKey);
      if (newRequest) lastRequestRef.current = newRequest;
      if (
        isUsingCache &&
        apiState?.[cacheKey]?.state === "SUCCESS" &&
        !!apiState?.[cacheKey]?.data &&
        !currentRequest?.clearCache
      ) {
        return;
      } else dispatchRequest(currentRequest, cacheKey);
    },
    [apiState, dispatchRequest, getCacheKey, isUsingCache]
  );

  if (res?.state === "PENDING" || currentCacheSlice.current !== cacheSlice) {
    renderCountAfterRequestSent.current++;
    currentCacheSlice.current = cacheSlice;
  }

  if (renderCountAfterRequestSent.current >= 1) {
    if (res.state === "ERROR") {
      resposeSubject.current.error(res);
      renderCountAfterRequestSent.current = 0;
    }
    if (res.state === "SUCCESS") {
      resposeSubject.current.next(res);
      renderCountAfterRequestSent.current = 0;
    }
  }

  const clear = useCallback(() => dispatch(clearApi(requestKey.current)), [dispatch]);

  useEffect(() => {
    if (options?.invokeImmediately && !firstInvocationDone.current) {
      call();
      firstInvocationDone.current = true;
    }
  }, [options, call]);

  useEffect(() => {
    return () => {
      if (options?.clearOnUnmount) {
        clear();
      }
    };
  }, [clear, options]);

  useEffect(() => {
    const sub = resposeSubject.current;
    if (options?.onSuccess || options?.onError) {
      sub.subscribe({
        next: ({data, responsePayload}) => options?.onSuccess?.(data, responsePayload.extra.responseHeaders),
        error: ({error, responsePayload}) =>
          options?.onError?.(error, responsePayload.statusCode, responsePayload.extra.responseHeaders)
      });
    }
    return () => {
      sub.unsubscribe();
    };
  }, [options]);

  const result = {
    data: res?.data,
    error: res?.error,
    loading: res?.loading,
    call,
    get request() {
      return lastRequestRef.current;
    },
    clear,
    headers: res?.responsePayload?.extra?.responseHeaders,
    statusCode: res?.responsePayload?.statusCode
  };
  return result;
};

export default useApi;
