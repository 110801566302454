import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {NodeDataAccessibility, NodeVisibility} from "../../../../model/IHubMinimalNode.d.ts";
import CustomDialogTitle from "../../../custom-dialog-title/index.jsx";
import FileInput from "../../../file-input";
import FormLabelWithTooltip from "../../../form-label-with-tooltip";
import I18nHtmlEditor from "../../../i18n-html-editor";
import I18nTextField from "../../../i18n-text-field";
import MaterialTable from "../../../material-table";
import TabPanel from "../../../tab-panel";
import {
  clearNodesMergedNodeConfig,
  createNodesMergedNodeConfig,
  fetchNodesMergedNodeConfig,
  saveNodesMergedNodeConfig,
  setNodesConfigMergedNodeAddNodeDialogVisibility,
  setNodesConfigMergedNodeEditNodeDialogVisibility
} from "../../../../state/nodesConfig/nodesConfigActions";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../../utils/i18n.js";

const fieldStyle = {
  margin: "8px 0",
  "& > .MuiFormControlLabel-root": {
    marginTop: "0px"
  }
};

const CATALOG_NAVIGATION_MODE_LIST = "List";

const Form = forwardRef(
  (
    {
      languages,
      language,
      nodeId,
      mergedNodeConfig,
      availableNodes,
      onSubmit,
      onCancel,
      isAddNodeDialogOpen,
      setAddNodeDialogVisibility,
      isEditNodeDialogOpen,
      idxMergeEditNode,
      setEditNodeDialogVisibility,
      mergeNodes,
      editingMergeNode,
      handleEditingMergeNodeAliasChange,
      handleEditNodeSave,
      handleDeleteMergeNode,
      handleSortMergedNodes,
      handleAddMergeNode
    },
    ref
  ) => {
    const {t} = useTranslation();
    const {
      register,
      handleSubmit,
      watch,
      getValues,
      setValue,
      formState: {errors}
    } = useForm({
      defaultValues: {
        active: false,
        default: false,
        ...mergedNodeConfig,
        visible: NodeVisibility.Yes,
        dataAccess: NodeDataAccessibility.All,
        showCategoryLevels: 1,
        catalogNavigationMode: CATALOG_NAVIGATION_MODE_LIST
      }
    });

    useImperativeHandle(ref, () => ({
      submit(f) {
        handleSubmit(val => {
          const data = {
            ...val,
            type: "MERGE"
          };

          data.extras = data.extras || [];
          data.extras.push({
            key: "HideLabelOnCategoryWithImage",
            value: "true",
            isPublic: true
          });
          data.extras.push({
            key: "QueryInfo",
            value: "false",
            isPublic: true
          });

          data.mergeNodes = mergeNodes || [];

          onSubmit(data);
          f(data);
        })();

        const firstFieldWithErrors = Object.keys(errors)[0];
        if (firstFieldWithErrors) {
          switch (firstFieldWithErrors) {
            case "code":
            case "agency":
            case "title":
            case "slogan": {
              setTab("general");
              break;
            }
            default: {
              setTab("general");
            }
          }
        }
      },
      cancel(f) {
        onCancel();
        f();
      }
    }));

    /* custom register */
    useEffect(() => {
      register("code", {
        required: t("commons.validation.required")
      });
      register("title");
      register("agency", {
        required: t("commons.validation.required")
      });
      register("active");
      register("visible");
      register("dataAccess");
      register("default");
      register("slogan");
      register("backgroundMediaURL");
      register("logo");
      register("description");
    }, [register, getValues, t]);

    const [tab, setTab] = useState("general");

    const getNodeTitle = mergeNode => {
      let title = "";
      if (mergeNode) {
        const node = availableNodes.find(n => n.nodeId === mergeNode.nodeId);
        if (node) {
          const localizedTitle = localizeI18nObj(node.title, language, languages);
          title = (localizedTitle || "").length > 0 ? localizedTitle : node.code;
        }
      }
      return title;
    };

    return (
      <Fragment>
        <Box
          sx={{
            height: "100%"
          }}
        >
          <Tabs value={tab} onChange={(_, tab) => setTab(tab)} variant="scrollable" scrollButtons="auto">
            <Tab value="general" label={t("scenes.mergeNodeSettings.tabs.general.label")} />
            <Tab value="infos" label={t("scenes.mergeNodeSettings.tabs.information.label")} />
            <Tab value="nodes" label={t("scenes.mergeNodeSettings.tabs.nodes.label")} />
          </Tabs>
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100% - 56px)",
              marginTop: "8px",
              padding: "0 4px"
            }}
          >
            <TabPanel value="general" selected={tab}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormControl fullWidth sx={fieldStyle}>
                    <TextField
                      disabled={!!mergedNodeConfig}
                      name="code"
                      variant="outlined"
                      label={
                        <FormLabelWithTooltip tooltip={t("scenes.mergeNodeSettings.fields.code.tooltip")}>
                          {t("scenes.mergeNodeSettings.fields.code.label")}
                        </FormLabelWithTooltip>
                      }
                      required
                      error={!!errors.code}
                      helperText={errors.code?.message}
                      value={watch("code") || ""}
                      onChange={({target}) => setValue("code", (target.value || "").toUpperCase())}
                      inputProps={{
                        style: {textTransform: "uppercase"}
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth sx={fieldStyle}>
                    <I18nTextField
                      name="title"
                      label={t("scenes.mergeNodeSettings.fields.title.label")}
                      error={!!errors.title}
                      helperText={errors.title?.message}
                      variant="outlined"
                      value={watch("title") || {}}
                      onChange={value => setValue("title", value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth sx={fieldStyle}>
                    <TextField
                      name="agency"
                      label={t("scenes.mergeNodeSettings.fields.agency.label")}
                      required
                      error={!!errors.agency}
                      helperText={errors.agency?.message}
                      variant="outlined"
                      value={watch("agency") || ""}
                      onChange={({target}) => setValue("agency", target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={
                    <FormLabelWithTooltip tooltip={t("scenes.mergeNodeSettings.fields.active.tooltip")} tooltipOnRight>
                      {t("scenes.mergeNodeSettings.fields.active.label")}
                    </FormLabelWithTooltip>
                  }
                  control={
                    <Checkbox
                      name="active"
                      checked={watch("active")}
                      onChange={(e, value) => setValue("active", value)}
                    />
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <TextField
                  select
                  label={t("scenes.mergeNodeSettings.fields.nodeAndDataVisibilitySelect.label")}
                  value={`${watch("visible")}+${watch("dataAccess")}`}
                  required
                  variant="outlined"
                  onChange={({target}) => {
                    const [visibleStr, dataAccessStr] = target.value.split("+");
                    const visible = Number(visibleStr);
                    const dataAccess = Number(dataAccessStr);
                    setValue("visible", visible);
                    setValue("dataAccess", dataAccess);
                    if (visible !== NodeVisibility.Yes) {
                      setValue("default", false);
                    }
                  }}
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value={`${NodeVisibility.Yes}+${NodeDataAccessibility.All}`}>
                    <Tooltip
                      title={t("scenes.mergeNodeSettings.fields.nodeAndDataVisibilitySelect.values.public.tooltip")}
                      placement="top"
                    >
                      <span style={{width: "100%"}}>
                        {t("scenes.mergeNodeSettings.fields.nodeAndDataVisibilitySelect.values.public.label")}
                      </span>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem value={`${NodeVisibility.No}+${NodeDataAccessibility.All}`}>
                    <Tooltip
                      title={t("scenes.mergeNodeSettings.fields.nodeAndDataVisibilitySelect.values.hidden.tooltip")}
                      placement="top"
                    >
                      <span style={{width: "100%"}}>
                        {t("scenes.mergeNodeSettings.fields.nodeAndDataVisibilitySelect.values.hidden.label")}
                      </span>
                    </Tooltip>
                  </MenuItem>
                </TextField>
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FormControlLabel
                  label={t("scenes.mergeNodeSettings.fields.default.label")}
                  control={
                    <Checkbox
                      name="default"
                      checked={watch("default")}
                      onChange={(e, value) => setValue("default", value)}
                      disabled={watch("visible") !== NodeVisibility.Yes}
                    />
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <I18nTextField
                  name="slogan"
                  label={t("scenes.mergeNodeSettings.fields.slogan.label")}
                  variant="outlined"
                  value={watch("slogan") || {}}
                  onChange={value => setValue("slogan", value)}
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FileInput
                  label={t("scenes.mergeNodeSettings.fields.backgroundMediaURL.label")}
                  value={watch("backgroundMediaURL")}
                  onChange={value => setValue("backgroundMediaURL", value)}
                  enableDownload
                />
              </FormControl>
              <FormControl fullWidth sx={fieldStyle}>
                <FileInput
                  label={t("scenes.mergeNodeSettings.fields.logo.label")}
                  value={watch("logo")}
                  onChange={value => setValue("logo", value)}
                  enableDownload
                />
              </FormControl>
            </TabPanel>
            <TabPanel value="infos" selected={tab}>
              <I18nHtmlEditor value={watch("description")} onChange={val => setValue("description", val)} />
            </TabPanel>
            <TabPanel value="nodes" selected={tab}>
              <Paper
                variant="outlined"
                sx={{
                  height: "480px",
                  marginTop: "16px",
                  padding: "16px"
                }}
              >
                <MaterialTable
                  data={mergeNodes || []}
                  rightActions={[
                    {
                      label: t("scenes.mergeNodeSettings.nodes.table.rightActions.addNode"),
                      startIcon: <AddIcon />,
                      onClick: () => setAddNodeDialogVisibility(true)
                    }
                  ]}
                  columns={[
                    {
                      field: "title",
                      title: t("scenes.mergeNodeSettings.nodes.table.columns.nodeTitle"),
                      render: rowData => getNodeTitle(rowData)
                    },
                    {
                      field: "alias",
                      title: t("scenes.mergeNodeSettings.nodes.table.columns.nodeAlias"),
                      render: ({alias}) => localizeI18nObj(alias, language, languages),
                      customFilterAndSearch: getI18nObjCustomFilterAndSearch(language, languages)
                    }
                  ]}
                  actions={[
                    rowData => ({
                      icon: <ArrowDropUpIcon />,
                      tooltip: t("scenes.mergeNodeSettings.nodes.table.actions.nodeOrderMoveUp"),
                      onClick: (_, row) => handleSortMergedNodes(row.position, "up"), // row.position is the rowIndex
                      disabled: rowData.position === 0
                    }),
                    rowData => ({
                      icon: <ArrowDropDownIcon />,
                      tooltip: t("scenes.mergeNodeSettings.nodes.table.actions.nodeOrderMoveDown"),
                      onClick: (_, row) => handleSortMergedNodes(row.position, "down"), // row.position is the rowIndex
                      disabled: rowData.position === (mergeNodes || []).length - 1
                    }),
                    () => ({
                      icon: <EditIcon />,
                      tooltip: t("scenes.mergeNodeSettings.nodes.table.actions.editNode"),
                      onClick: (_, row) => setEditNodeDialogVisibility(true, row.position)
                    }),
                    () => ({
                      icon: <DeleteIcon />,
                      tooltip: t("scenes.mergeNodeSettings.nodes.table.actions.deleteNode"),
                      onClick: (_, row) => handleDeleteMergeNode(row.nodeId)
                    })
                  ]}
                />
              </Paper>
            </TabPanel>
          </Box>
        </Box>

        <Dialog open={isAddNodeDialogOpen} maxWidth="md" fullWidth onClose={() => setAddNodeDialogVisibility(false)}>
          <CustomDialogTitle onClose={() => setAddNodeDialogVisibility(false)}>
            {t("scenes.mergeNodeSettings.nodes.modals.addNode.title")}
          </CustomDialogTitle>
          <DialogContent sx={{height: "480px"}}>
            <MaterialTable
              data={availableNodes}
              columns={[
                {
                  field: "code",
                  title: t("scenes.mergeNodeSettings.nodes.modals.addNode.table.columns.code")
                },
                {
                  field: "title",
                  title: t("scenes.mergeNodeSettings.nodes.modals.addNode.table.columns.title"),
                  render: ({title}) => localizeI18nObj(title, language, languages),
                  customFilterAndSearch: getI18nObjCustomFilterAndSearch(language, languages)
                }
              ]}
              actions={[
                rowData => {
                  const assigned = !!(mergeNodes || []).find(node => node.nodeId === rowData.nodeId);
                  return {
                    icon: <AddIcon />,
                    tooltip: assigned
                      ? t("scenes.mergeNodeSettings.nodes.modals.addNode.table.actions.addNode.tooltip.assigned")
                      : t("scenes.mergeNodeSettings.nodes.modals.addNode.table.actions.addNode.tooltip.notAssigned"),
                    onClick: (_, rowData) => handleAddMergeNode(rowData),
                    disabled: assigned
                  };
                }
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddNodeDialogVisibility(false)}>{t("commons.confirm.close")}</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isEditNodeDialogOpen}
          maxWidth="sm"
          fullWidth
          onClose={() => setEditNodeDialogVisibility(false, null)}
        >
          <CustomDialogTitle onClose={() => setEditNodeDialogVisibility(false, null)}>
            {t("scenes.mergeNodeSettings.nodes.modals.editNode.title", {title: getNodeTitle(editingMergeNode)})}
          </CustomDialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <I18nTextField
                    label={t("scenes.mergeNodeSettings.nodes.modals.editNode.form.alias.label")}
                    variant="outlined"
                    value={editingMergeNode?.alias}
                    onChange={value => handleEditingMergeNodeAliasChange(value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditNodeDialogVisibility(false, null)}>{t("commons.confirm.cancel")}</Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => handleEditNodeSave()}
              disabled={editingMergeNode === null || idxMergeEditNode === null}
            >
              {t("commons.confirm.save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
);

const mapStateToProps = state => ({
  mergedNodeConfig: state.nodesConfig.mergedNode,
  nodes: state.nodesConfig.nodes,
  languages: state.app.languages,
  language: state.app.language,
  isAddNodeDialogOpen: state.nodesConfig.isAddNodeDialogOpen,
  isEditNodeDialogOpen: state.nodesConfig.isEditNodeDialogOpen,
  idxMergeEditNode: state.nodesConfig.idxMergeEditNode
});

const mapDispatchToProps = dispatch => ({
  fetchMergedNodeConfig: nodeId => dispatch(fetchNodesMergedNodeConfig(nodeId)),
  createMergedNodeConfig: data => dispatch(createNodesMergedNodeConfig(data)),
  saveMergedNodeConfig: data => dispatch(saveNodesMergedNodeConfig(data)),
  clearMergedNodeConfig: () => dispatch(clearNodesMergedNodeConfig()),
  setAddNodeDialogVisibility: visible => dispatch(setNodesConfigMergedNodeAddNodeDialogVisibility(visible)),
  setEditNodeDialogVisibility: (visible, idx) =>
    dispatch(setNodesConfigMergedNodeEditNodeDialogVisibility(visible, idx))
});

const MergedNodeSettingsForm = (
  {
    nodeId,
    mergedNodeConfig,
    nodes,
    languages,
    language,
    fetchMergedNodeConfig,
    createMergedNodeConfig,
    saveMergedNodeConfig,
    clearMergedNodeConfig,
    isAddNodeDialogOpen,
    setAddNodeDialogVisibility,
    setEditNodeDialogVisibility,
    isEditNodeDialogOpen,
    idxMergeEditNode
  },
  ref
) => {
  const availableNodes = useMemo(() => {
    const availableNodes = [];
    (nodes || []).forEach(node => {
      if (
        node.type === "SDMX-REST" &&
        node.active &&
        node.dataAccess === NodeDataAccessibility.All &&
        node.visible !== NodeVisibility.Profiled
      ) {
        availableNodes.push(node);
      }
    });
    return availableNodes;
  }, [nodes]);

  const [needConfig, setNeedConfig] = useState(nodeId !== null);
  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchMergedNodeConfig(nodeId);
    }
  }, [mergedNodeConfig, needConfig, setNeedConfig, fetchMergedNodeConfig, nodeId]);

  const [mergeNodes, setMergeNodes] = useState([]);
  useEffect(
    () => {
      if (nodeId !== null) {
        setMergeNodes(mergedNodeConfig?.mergeNodes || []);
      } else {
        setMergeNodes([]);
      }
    },
    [mergedNodeConfig, nodeId],
    setMergeNodes
  );

  const [editingMergeNode, setEditingMergeNode] = useState(null);
  useEffect(() => {
    if (idxMergeEditNode !== null && (mergeNodes || []).length > idxMergeEditNode) {
      setEditingMergeNode(mergeNodes[idxMergeEditNode]);
    } else {
      setEditingMergeNode(null);
    }
  }, [idxMergeEditNode, mergeNodes]);

  const handleEditNodeSave = () => {
    let currentMergeNodes = _.cloneDeep(mergeNodes) || [];
    currentMergeNodes[idxMergeEditNode] = editingMergeNode;
    setMergeNodes(currentMergeNodes);
    setEditingMergeNode(null);
    setEditNodeDialogVisibility(false, null);
  };

  const handleEditingMergeNodeAliasChange = alias => {
    let toModifyMergeNode = _.cloneDeep(editingMergeNode);
    toModifyMergeNode.alias = alias;
    setEditingMergeNode(toModifyMergeNode);
  };

  const handleAddMergeNode = node => {
    let currentMergeNodes = _.cloneDeep(mergeNodes) || [];
    let mergeNodeDto = {
      title: node.code,
      nodeId: node.nodeId,
      alias: null,
      position: currentMergeNodes.length
    };
    currentMergeNodes.push(mergeNodeDto);
    setMergeNodes(currentMergeNodes);
  };

  const handleDeleteMergeNode = nodeId => {
    let currentMergeNodes = _.cloneDeep(mergeNodes) || [];
    const newMergeNodes = currentMergeNodes.filter(n => n.nodeId !== nodeId);
    newMergeNodes.sort((a, b) => a.position - b.position);
    for (let i = 0; i < newMergeNodes.length; i++) {
      newMergeNodes[i].position = i;
    }
    setMergeNodes(newMergeNodes);
  };

  const handleSortMergedNodes = (position, direction) => {
    let currentMergeNodes = _.cloneDeep(mergeNodes) || [];
    if (direction === "up" && position > 0) {
      currentMergeNodes[position].position = position - 1;
      currentMergeNodes[position - 1].position = position;
    } else if (direction === "down" && position < currentMergeNodes.length) {
      currentMergeNodes[position].position = position + 1;
      currentMergeNodes[position + 1].position = position;
    }
    currentMergeNodes.sort((a, b) => a.position - b.position);
    setMergeNodes(currentMergeNodes);
  };

  return (
    (nodeId === null || mergedNodeConfig) && (
      <Form
        nodeId={nodeId}
        mergedNodeConfig={nodeId === null ? undefined : mergedNodeConfig}
        availableNodes={availableNodes}
        languages={languages}
        language={language}
        ref={ref}
        isAddNodeDialogOpen={isAddNodeDialogOpen}
        setAddNodeDialogVisibility={setAddNodeDialogVisibility}
        mergeNodes={mergeNodes}
        editingMergeNode={editingMergeNode}
        handleEditingMergeNodeAliasChange={handleEditingMergeNodeAliasChange}
        onSubmit={nodeId === null ? createMergedNodeConfig : saveMergedNodeConfig}
        onCancel={clearMergedNodeConfig}
        handleEditNodeSave={handleEditNodeSave}
        handleDeleteMergeNode={handleDeleteMergeNode}
        handleSortMergedNodes={handleSortMergedNodes}
        handleAddMergeNode={handleAddMergeNode}
        setEditNodeDialogVisibility={setEditNodeDialogVisibility}
        isEditNodeDialogOpen={isEditNodeDialogOpen}
        idxMergeEditNode={idxMergeEditNode}
      />
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(MergedNodeSettingsForm);
