import React, {Fragment} from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {localizeI18nObj} from "../../utils/i18n";
import "./style.css";

const Hero = ({
  defaultLanguage,
  languages,
  homeExtraLogos,
  title,
  slogan,
  logo,
  background,
  showExtraLogos = false,
  children
}) => (
  <Fragment>
    <Helmet>
      <style type="text/css">
        {`
          html, body, #root {
            height: 100%
          }
        `}
      </style>
    </Helmet>
    <Box
      id="hero"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        position: "relative"
      }}
      className={"hero"}
    >
      <Box
        id="hero__extra-logos"
        sx={{
          top: "24px",
          right: "24px",
          position: "absolute",
          "& img": {
            maxHeight: "80px"
          }
        }}
      >
        {showExtraLogos &&
          (homeExtraLogos || []).map(({src, href, alt}, idx) => (
            <Tooltip key={idx} title={localizeI18nObj(alt, defaultLanguage, languages) || ""}>
              <a href={href} target="_blank" rel="noopener noreferrer">
                <img src={src} alt={localizeI18nObj(alt, defaultLanguage, languages)} />
              </a>
            </Tooltip>
          ))}
      </Box>
      {(title?.length > 0 || slogan?.length > 0) && (
        <Box
          id="hero__title"
          sx={{
            color: "white",
            textAlign: "center",
            padding: "16px",
            background: "rgba(0,0,0,0.5)"
          }}
        >
          {title?.length > 0 && (
            <Typography
              variant="h1"
              sx={{
                fontSize: "60px",
                padding: "8px",
                letterSpacing: "0px"
              }}
            >
              {title}
            </Typography>
          )}
          {slogan?.length > 0 && (
            <Typography
              id="hero__slogan"
              variant="h2"
              sx={{
                fontSize: "24px",
                fontWeight: "400px",
                padding: "8px",
                letterSpacing: "0px"
              }}
            >
              {slogan}
            </Typography>
          )}
        </Box>
      )}
      <Box id="hero__buttons" sx={{marginTop: "24px"}}>
        {children}
      </Box>
      <Box
        id="hero__logo"
        sx={{
          position: "absolute",
          top: "80%",
          width: "80%",
          textAlign: "center",
          "& > *": {
            maxWidth: "340px",
            maxHeight: "64px"
          }
        }}
      >
        {logo}
      </Box>
      <Box
        id="hero__background"
        sx={{
          "&, & > *": {
            position: "fixed",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -9999
          }
        }}
        className={" hero__background"}
      >
        {background}
      </Box>
    </Box>
  </Fragment>
);

export default connect(state => ({
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  homeExtraLogos: state.appConfig.homeExtraLogos
}))(Hero);
