import React from "react";
import {Alert, Button, DialogActions, FormControl, FormGroup, useTheme} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {getImportErrorsTranslations} from "../../../../constants/getImportErrorsTranslations";
import {DownloadNodeDto} from "../../../../model/wshub-models/downloadNodeDto";
import {getNodeImportUrl} from "../../../../serverApi/urls";
import FileInputTs from "../../../file-input-ts";
import PasswordInput from "../../../password-input-ts";
import useApi from "../../../../state/api/useApi";
import {external} from "../../../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../../../middlewares/request/requestActions";

interface ValidationForm {
  file: File[];
  password: string;
}

export interface FileValidationFormProps {
  onSuccessfulValidation: (d: {data: DownloadNodeDto; password: string; file: File}) => void;
  onClose: () => void;
}

function FileValidationForm({onSuccessfulValidation, onClose}: FileValidationFormProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    getValues
  } = useForm<ValidationForm>({
    defaultValues: {
      password: "",
      file: null
    }
  });

  const validateApi = useApi<DownloadNodeDto>(
    external(
      initRequest("validateFile", getNodeImportUrl(), RequestMethod.POST, undefined, t => ({
        onStart: t("components.nodeImportForm.wizard.firstStep.validatingMessage")
      })),
      "dashboard"
    ),
    {
      clearOnUnmount: true,
      onSuccess: data =>
        onSuccessfulValidation({
          data,
          file: getValues("file")[0],
          password: getValues("password")
        })
    }
  );

  const submitHandler: SubmitHandler<ValidationForm> = formFields => {
    const file = formFields.file[0];
    const ValidationFormData = new FormData();
    ValidationFormData.append("file", file);
    ValidationFormData.append("password", formFields.password);
    validateApi.call({
      ...validateApi.request,
      data: ValidationFormData
    });
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)} noValidate>
      <FormGroup>
        <h3>{t("components.nodeImportForm.wizard.firstStep.uploadText")}</h3>
        {validateApi.error && (
          <Alert
            style={{
              marginBottom: 16
            }}
            severity="error"
          >
            {getImportErrorsTranslations(t)[validateApi.error] || t("errors.importNode.generic")}
          </Alert>
        )}
        <FormControl sx={{marginBottom: theme.spacing(1)}}>
          <FileInputTs
            inputProps={{
              ...register("file", {
                required: true
              }),
              disabled: !!validateApi.data
            }}
            textFieldProps={{
              disabled: !!validateApi.data,
              error: !!errors.file,
              helperText: !!errors.file && t("commons.validation.required")
            }}
          />
        </FormControl>
        <h3>{t("components.nodeImportForm.wizard.firstStep.passwordText")}</h3>
        <FormControl sx={{marginBottom: theme.spacing(1)}}>
          <Controller
            render={({field}) => (
              <PasswordInput
                {...field}
                disabled={!!validateApi.data}
                label="Password"
                variant="outlined"
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            )}
            name="password"
            control={control}
          />
        </FormControl>
      </FormGroup>
      <DialogActions>
        <Button onClick={onClose} type="button">
          {t("commons.confirm.close")}
        </Button>
        <Button type="submit">{t("components.nodeImportForm.wizard.firstStep.nextButton")}</Button>
      </DialogActions>
    </form>
  );
}

export default FileValidationForm;
