import React from "react";
import Box from "@mui/material/Box";
import {sanitize} from "dompurify";

const SanitizedHTML = ({html, allowTarget = false, sx = {}, className = "", ...props}) => (
  <Box
    {...props}
    sx={{
      color: theme => theme.palette.text.primary,
      ...sx
    }}
    className={"sanitized-html" + (className ? ` ${className}` : "")}
    html={undefined}
    dangerouslySetInnerHTML={{
      __html: sanitize(html, {
        ADD_ATTR: [allowTarget ? "target" : ""]
      })
    }}
  />
);

export default SanitizedHTML;
