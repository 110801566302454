import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../label-format-selector/constants";
import DatasetMergedFilters from "./DatasetMergedFilters";
import {
  getDimensionAttributeMap,
  getFiltersValueInfo,
  getFormattedDimensionValueLabel,
  isFilterValid
} from "../../utils/dataset";
import {getCombinationArrays} from "../../utils/other";

const DatasetMergedFiltersJsonStatWrapper = ({
  jsonStat,
  layout,
  filterTree,
  timeDim,
  labelFormat = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
  onChange
}) => {
  const {t} = useTranslation();

  const dimensions = useMemo(() => {
    if ((layout?.filters || []).length === 0) {
      return [];
    } else if (layout.filters.includes(timeDim)) {
      return [timeDim, ...layout.filters.filter(dim => dim !== timeDim)];
    } else {
      return layout.filters;
    }
  }, [layout, timeDim]);

  const combinations = useMemo(() => {
    const newCombinations = [];
    if (dimensions) {
      const dimensionsValues = dimensions.map(filter => {
        const dimValues = [...jsonStat.dimension[filter].category.index];
        return filter !== timeDim ? dimValues : dimValues.reverse();
      });
      getCombinationArrays(dimensionsValues).forEach(combination => {
        const orderedCombination = (layout?.filters || []).map(dim => combination[dimensions.indexOf(dim)]);
        if (isFilterValid(orderedCombination, filterTree)) {
          const filtersValue = dimensions.reduce((o, dim, dimIdx) => ({...o, [dim]: combination[dimIdx]}), {});
          const {datasetId, values} = getFiltersValueInfo(dimensions, filtersValue, jsonStat, labelFormat, t);

          newCombinations.push({
            datasetId: datasetId,
            values: values,
            filtersValue: filtersValue
          });
        }
      });
    }
    return newCombinations;
  }, [dimensions, jsonStat, layout.filters, filterTree, timeDim, labelFormat, t]);

  const dimAttributeMap = useMemo(() => getDimensionAttributeMap(jsonStat, t), [jsonStat, t]);

  const {datasetId: currentFilterDatasetId, values: combination} = getFiltersValueInfo(
    dimensions,
    layout.filtersValue,
    jsonStat,
    labelFormat,
    t
  );

  const getAttributeIcon = useCallback(
    (dim, value, disabledTooltip, row) => {
      const datasetId = row?.datasetId || currentFilterDatasetId;
      return (
        <DatasetFilterAttributeIcon
          dimension={dim}
          dimensionValues={[value]}
          datasetDimAttributeMap={dimAttributeMap?.[datasetId]}
          labelFormat={labelFormat}
          disabled
          disabledTooltip={disabledTooltip}
          getDimValueLabel={dimVal => getFormattedDimensionValueLabel(jsonStat, datasetId, dim, dimVal, labelFormat)}
        />
      );
    },
    [currentFilterDatasetId, jsonStat, dimAttributeMap, labelFormat]
  );

  return (
    layout && (
      <DatasetMergedFilters
        layout={layout}
        dimensions={dimensions}
        combination={combination}
        combinations={combinations}
        getAttributeIcon={getAttributeIcon}
        onChange={onChange}
      />
    )
  );
};

export default DatasetMergedFiltersJsonStatWrapper;
