import React, {Fragment, useState} from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {withTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import MetadataIcon from "../custom-icons/MetadataIcon";
import FullscreenDialog from "../fullscreen-dialog";

const CatalogMetadataButton = ({t, metadataUrl, label, iconSize}) => {
  const [isVisible, setVisibility] = useState(false);

  const onOpen = () => {
    setVisibility(true);
  };

  const onClose = ev => {
    ev.stopPropagation();
    setVisibility(false);
  };

  if (!metadataUrl) {
    return <span />;
  }

  return (
    <Fragment>
      <Tooltip title={label || t("components.catalogMetadataButton.label")}>
        <IconButton
          id="catalog-metadata-btn"
          size={iconSize}
          onClick={ev => {
            ev.stopPropagation();
            onOpen();
          }}
          aria-label={label || t("components.catalogMetadataButton.label")}
        >
          <MetadataIcon />
        </IconButton>
      </Tooltip>

      <FullscreenDialog open={isVisible} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>{label || t("components.catalogMetadataButton.label")}</CustomDialogTitle>
        <DialogContent>
          <iframe
            title={"title"}
            src={metadataUrl}
            style={{
              border: 0,
              width: "100%",
              height: "calc(100% - 6px)"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </FullscreenDialog>
    </Fragment>
  );
};

export default withTranslation()(CatalogMetadataButton);
